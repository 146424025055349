import { DefaultApi } from '../../../utils/defaultApi'
import api from '../../api'
import { IMovimentoContaCorrente, ISaldoContaCorrente } from './types'

const endpoint = '/conta-corrente/movimentos'
const resourceId = 'id_movimento_conta_corrente'

class ServiceMovimentoContaCorrente extends DefaultApi<IMovimentoContaCorrente> {
  getSaldoConta = async (id_conta_corrente: string) => {
    const { data } = await api.get<ISaldoContaCorrente>(
      `${endpoint}/saldo/${id_conta_corrente}`
    )
    return data
  }
}

const apiMovimentoContaCorrente = new ServiceMovimentoContaCorrente(
  endpoint,
  resourceId
)

export const ApiMovimentoContaCorrente = {
  ...apiMovimentoContaCorrente,
  getSaldoConta: apiMovimentoContaCorrente.getSaldoConta
}
