import { useQuery } from 'react-query'

import { ApiEstadoAtividade } from '../../services/estado-atividade'

const QUERY_KEY = 'qkEstadoAtividade'

const ListAll = () => {
  return useQuery([QUERY_KEY], () => ApiEstadoAtividade.listAll())
}

const FindOne = (id: string) => {
  return useQuery([QUERY_KEY, id], () => ApiEstadoAtividade.findOne(id))
}

export const useEstadoAtividade = {
  ListAll,
  FindOne
}
