import api from '../../services/api'
import { IResult, TAtivo } from './types'

export class DefaultApi<T> {
  constructor(protected endpoint: string, protected resourceId: string) {}

  findAll = async (
    page: number,
    q?: string,
    ativo: TAtivo = true
  ): Promise<IResult<T>> => {
    const pageString = page ? `page=${page}` : ''
    const qString = q ? `q=${q}` : ''
    const ativoString = ativo !== undefined ? `ativo=${ativo}` : ''
    const params = [pageString, qString, ativoString]
      .filter((value) => value !== '')
      .join('&')

    const { data } = await api.get<IResult<T>>(`${this.endpoint}?${params}`)
    return data
  }

  listAll = async (): Promise<T[]> => {
    const { data } = await api.get<T[]>(`${this.endpoint}/lista/`)
    return data
  }

  listAllCliente = async (): Promise<T[]> => {
    const { data } = await api.get<T[]>(`${this.endpoint}/lista/cliente`)
    return data
  }

  listAllFornecedor = async (): Promise<T[]> => {
    const { data } = await api.get<T[]>(`${this.endpoint}/lista/fornecedor`)
    return data
  }

  findOne = async (id: string): Promise<T> => {
    if (id === '0' || id === '') {
      const A = {} as T
      return A
    }
    const { data } = await api.get<T>(`${this.endpoint}/${id}`)
    return data
  }

  create = async (formData: T): Promise<T> => {
    const { data } = await api.post<T>(`${this.endpoint}`, formData)
    return data
  }

  update = async (formData: T): Promise<T> => {
    const id = (formData as any)[this.resourceId]
    const { data } = await api.put<T>(`${this.endpoint}/${id}`, formData)
    return data
  }

  remove = async (id: string): Promise<T> => {
    const { data } = await api.put<T>(`${this.endpoint}/${id}`, {
      ativo: false
    })
    return data
  }

  reactivate = async (id: string): Promise<T> => {
    const { data } = await api.put<T>(`${this.endpoint}/${id}`, {
      ativo: true
    })
    return data
  }
}
