import pdfMake from 'pdfmake/build/pdfmake'
import { TDocumentDefinitions } from 'pdfmake/interfaces'
import { format } from 'date-fns'
// import logo from '../../../../../../assets/images/logo_xsolution_beta.png'
import logoReportImg from '../../../../../../assets/images/logo-report.png'
import { vfs } from '../../../../../../utils/report/fonts/pdfFonts'
import { IProtocoloRequisicaoData } from '../../../../../../utils/loteToProcoloRequisicao'
import { toDataURL } from '../../../../../../utils/toDataURL'
import { getCodigo } from '../../../../../../utils/getCodigo'
import { ApiUsuario } from '../../../../../../services/usuario'
import { ApiEmpresa } from '../../../../../../services/empresa'

pdfMake.vfs = vfs
pdfMake.fonts = {
  Montserrat: {
    normal: 'Montserrat-Regular.ttf',
    bold: 'Montserrat-SemiBold.ttf',
    italics: 'Montserrat-Italic.ttf',
    bolditalics: 'Montserrat-SemiBoldItalic.ttf'
  }
}

type Props = {
  data: IProtocoloRequisicaoData
  id_usuario_logado?: string
  mode?: 'open' | 'save'
}

export async function LoteReport({
  id_usuario_logado,
  data: {
    caixas,
    usuario,
    requisicao,
    documentos,
    id_lote,
    id_usuario_validou,
    validado_em,
    tokenRequisicao
  },
  mode = 'open'
}: Props) {
  // const reportLogo = String(await toDataURL(logo))
  const logoReport = String(await toDataURL(logoReportImg))

  const usuarioQueValidou = await ApiUsuario.findById(id_usuario_validou ?? '')
  const usuarioLogado = await ApiUsuario.findById(id_usuario_logado ?? '')
  const id_empresa = usuarioLogado?.pessoa.setor.empresa?.id_empresa
    ? usuarioLogado?.pessoa.setor.empresa?.id_empresa
    : ''

  const empresaData = await ApiEmpresa.findOne(id_empresa)

  let empresaHeaderText = ''
  if (empresaData) {
    const { cnpj, endereco, razao_social, email, telefone1 } = empresaData
    empresaHeaderText = `${razao_social} \nCNPJ: ${cnpj} \nEmail: ${email} \n${endereco.logradouro}, nº ${endereco.numero}, Bairro: ${endereco.bairro} \n Cidade/UF: ${endereco.municipio?.nome}/${endereco.municipio?.estado?.sigla} - CEP: ${endereco.cep} \n Telefone: ${telefone1}`
  }

  const tomadorDataHora = validado_em
    ? // ? `Natal/RN ${format(
      `${format(
        new Date(Date.parse(validado_em ?? '0')),
        " dd/MM/yyyy 'às' HH:mm"
      )}`
    : '**********'

  const prestadorTexto = `Eu, ${
    usuario.nome.toLocaleUpperCase() ?? '**********'
  }, atesto via token ${tokenRequisicao ?? '**********'} 
  a efetiva prestação do serviço descrito nesta OS, ${tomadorDataHora}`

  const tomadorTexto = `Eu, ${
    usuarioQueValidou?.pessoa?.nome.toLocaleUpperCase() ?? '**********'
  }, atesto via token ${tokenRequisicao ?? '**********'} que o serviço
  descrito nesta OS foi efetivamente realizado, ${tomadorDataHora}`

  const pageHeader = () => [
    {
      columns: [
        {
          image: logoReport,
          // width: 192,
          // height: 108,
          // width: 208,
          // height: 60,
          width: 118,
          height: 86,
          style: 'logo'
        },
        {
          // text: `X-SOLUTION Tecnologia da Informação \n Email: atendimento@xsolutiondoc.com.br \n Av. Amintas Barros,1880 \n Lagoa Nova - 59.056-265 \n Fone: (84)3213 1069 / (84)99632 8840`,
          //text: `X-SOLUTION DOC BUREAU LTDA \nCNPJ: 04.280.584/0005-80 \n Email: atendimento.pb@xsolutiondoc.com.br \n Rua Bolero de Ravel, nº 106, Bairro: Jacaré \n Cidade/UF: Cabedelo/PB - CEP: 58.105-775 \n Fone: (83) 2182-5133`,
          text: empresaHeaderText,
          style: 'headerText'
        }
      ]
    }
  ]

  const boxesTable = () => {
    let rows: Array<any> = []
    if (caixas && caixas.length > 0) {
      rows = [
        [
          { text: 'Tipo Cx', style: 'columnTitle' },
          { text: 'Identificação da caixa', style: 'columnTitle' },
          { text: 'Endereço', style: 'columnTitle' },
          { text: 'Qtde Docs', style: 'columnTitle' },
          { text: 'Privacidade', style: 'columnTitle' }
        ]
      ]
      caixas.forEach((item) => {
        const row = [
          { text: item.tipoCaixa, style: 'row' },
          { text: item.identificacao, style: 'row' },
          {
            text: item.endereco ? item.endereco : 'Não endereçado',
            style: 'row'
          },
          { text: item.quantidadeDocumentos, style: 'quantityDoc' },
          { text: item.privacidade, style: 'quantityDoc' }
        ]

        rows.push(row as any)
      })
      return [
        {
          table: {
            widths: [80, '*', 60, 60, 65],
            headerRows: 1,
            body: rows
          }
        },
        {
          text: `Total de caixas: ${caixas.length}`,
          style: 'totalBoxes'
        }
      ]
    } else {
      return [{ text: '\n' }]
    }
  }

  const documentsTable = () => {
    let rows: Array<any> = []

    if (documentos && documentos.length) {
      rows = [
        [
          { text: 'Tipo Doc', style: 'columnTitle' },
          { text: 'Descrição', style: 'columnTitle' },
          { text: 'Endereço', style: 'columnTitle' },
          { text: 'Privacidade', style: 'columnTitle' }
        ]
      ]
      documentos.forEach((item) => {
        const row = [
          { text: item.tipoDocumento, style: 'row' },
          { text: item.descricao, style: 'row' },
          {
            text: item.endereco ? item.endereco : 'Não endereçado',
            style: 'row'
          },
          { text: item.privacidade, style: 'quantityDoc' }
        ]

        rows.push(row as any)
      })
      return [
        {
          table: {
            widths: [80, '*', 60, 65],
            headerRows: 1,
            body: rows
          }
        },
        {
          text: `Total de documentos: ${documentos?.length}`,
          style: 'totalBoxes'
        }
      ]
    } else {
      return [{ text: '\n' }]
    }
  }

  const docDefinitions: TDocumentDefinitions = {
    defaultStyle: { font: 'Montserrat' },
    pageSize: 'A4',
    pageMargins: [30, 30, 30, 40],
    footer: function (currentPage, pageCount) {
      return [
        {
          text: 'O tomador e o prestador do serviço concordam em validar a operação descrita na Ordem de Serviço por meio de um token exclusivo, garantindo assim a autenticidade, legitimidade e rastreabilidade da referida operação.',
          style: 'footer'
        },
        {
          text: currentPage.toString() + ' de ' + pageCount,
          style: 'footerRight'
        }
      ]
    },
    content: [
      pageHeader(),

      { text: '\n' },
      {
        table: {
          widths: ['*'],
          body: [
            [
              {
                text: 'Ordem de Serviço',
                style: 'subtitle'
              }
            ]
          ]
        }
      },
      {
        text: `Impresso pelo usuário: ${usuarioLogado.pessoa.nome} em: ${format(
          new Date(),
          " dd/MM/yyyy 'às' HH:mm"
        )}`,
        style: 'printedOn'
      },
      {
        columns: [
          {
            qr: `https://easydoc.xsolutionti.com.br`,
            fit: 120,
            width: '22%'
          },
          [
            {
              text: `Nº O.S.: ${getCodigo(id_lote)}`
            },
            {
              text: `Requisição: ${getCodigo(requisicao.id_requisicao ?? '')}`
            },
            { text: `Serviço: ${requisicao.item_contrato?.servico.nome}` },
            { text: `Empresa: ${usuario.setor.empresa.nome}` },
            { text: `Setor Requisitante: ${usuario.setor.nome}` },
            { text: `Funcionário: ${usuario.nome}` },
            {
              text: `Data: ${format(
                new Date(requisicao.created_at?.split('T').shift()!),
                'dd/MM/yyy'
              )}`
            }
          ]
        ],
        style: 'qrcode'
      },
      { text: '\n' },
      [...boxesTable()],
      [...documentsTable()],

      { text: 'Legenda Privacidade', style: ['text', { bold: true }] },
      { text: 'P: Contém dados pessoais (nome, cpf, endereço)', style: 'text' },
      {
        text: 'S: Contém dados pessoais sensíveis (ficha médica, biometria)',
        style: 'text'
      },
      { text: 'C: Contém dados de crianças e/ou adolescentes', style: 'text' },

      {
        text: '__________________________________________________________________________',
        style: 'line'
      },
      {
        text: tomadorTexto,
        style: 'tomador'
      },
      {
        text: '__________________________________________________________________________',
        style: 'line'
      },
      {
        text: prestadorTexto,
        style: 'prestador'
      },
      [
        {
          text:
            requisicao?.observacoes && requisicao?.observacoes.trim() !== ''
              ? [
                  { text: '\n Observação: ', style: ['text', 'bold'] },
                  {
                    text: requisicao.observacoes,
                    style: ['text']
                  }
                ]
              : [],
          style: 'text'
        }
      ]
    ],

    styles: {
      table: {},
      title: {
        fontSize: 13,
        alignment: 'center',
        margin: [0, 0, 20, 15]
      },
      prestador: {
        alignment: 'center',
        fontSize: 10
      },
      tomador: {
        alignment: 'center',
        fontSize: 10
      },
      columnTitle: {
        fontSize: 10,
        bold: true,
        alignment: 'center'
      },
      row: {
        fontSize: 9
      },
      quantityDoc: {
        fontSize: 9,
        alignment: 'right'
      },
      totalBoxes: {
        fontSize: 9,
        bold: true,
        margin: [5, 2, 5, 10]
      },
      line: {
        alignment: 'center',
        margin: [0, 30, 0, 0]
      },
      subtitle: {
        fontSize: 12,
        bold: true,
        alignment: 'center'
      },
      headerText: {
        fontSize: 10,
        margin: [16, 0, 0, 0]
      },
      logo: {
        margin: [12, -5, 0, 7]
      },
      sideColumn: {
        alignment: 'justify',
        columnGap: 1
      },
      id: {
        fontSize: 8,
        alignment: 'center',
        margin: [0, 0, 0, 2]
      },
      printedOn: {
        fontSize: 8,
        alignment: 'right'
      },
      label: {
        fontSize: 16,
        bold: true,
        italics: true,
        background: '#e6e6e6',
        margin: [16, 32, 0, 0]
      },
      text: {
        fontSize: 10
      },
      instrucao: {
        fontSize: 10,
        italics: true,
        alignment: 'center'
      },
      qrcode: {
        lineHeight: 1.2
      },
      footer: {
        fontSize: 9,
        italics: true,
        margin: [30, 0, 30, 0]
      },
      footerRight: {
        fontSize: 9,
        italics: true,
        margin: [30, 5, 30, 5],
        alignment: 'right'
      },
      bold: {
        bold: true
      }
    }
  }

  if (mode === 'open') {
    const win = window.open('', '_blank', 'popup=yes')
    pdfMake.createPdf(docDefinitions).open({}, win)
  } else if (mode === 'save') {
    pdfMake.createPdf(docDefinitions).download(`etiqueta.pdf`)
  }
}
