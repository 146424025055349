import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { useTrackRoute } from '../hooks/trackroute'
import { Dashboard } from '../pages/Dashboard'
import { PageNotFound } from '../pages/PageNotFound'
import { Servicos } from '../pages/Cadastros/Servicos'
import { TipoCaixas } from '../pages/Cadastros/TipoCaixas'
import { TipoDocumentos } from '../pages/Cadastros/TipoDocumentos'
import { Caixas } from '../pages/Cadastros/Caixas'
import { Empresas } from '../pages/Cadastros/Empresas'
import { Requisicao } from '../pages/Cadastros/Requisicao'
import { Mensagens } from '../pages/Cadastros/Mensagens'
import { TipoTemporalidade } from '../pages/Cadastros/TipoTemporalidade'
import { FluxoServico } from '../pages/Cadastros/FluxoServico'
import { Setores } from '../pages/Cadastros/Setores'
import { TipoAtividade } from '../pages/Cadastros/TipoAtividades'
import { Permissions as pms } from '../types/permissions'
import { useUsuario } from '../hooks/usuario/useUsuario'
import { CentroDocumentacao } from '../pages/Cadastros/CentroDocumentacao'
import { Contratos } from '../pages/Cadastros/Contrato'
import { LogsUsuarios } from '../pages/Auditoria/LogsUsuarios'
import { ConfiguracaoArmazenamento } from '../pages/Auditoria/ConfiguracaoArmazenamento'
import { Usuarios } from '../pages/Cadastros/Usuarios'
import { Permissoes } from '../pages/Cadastros/Permissoes'
import { ResetPassword } from '../pages/Cadastros/Usuarios/ResetPassword'
import { Documentos } from '../pages/Cadastros/Documentos'
import { ArvoreDocumental } from '../pages/Cadastros/ArvoreDocumental'
import { ImportarDocumentos } from '../pages/Importar/Documentos'
import { ImportarVolumes } from '../pages/Importar/Volumes'
import { DetalharRequisicoes } from '../pages/Cadastros/DetalharRequisicoes'
import { DocumentosEstatisticaPorEmpresaRelatorio } from '../pages/Relatorios/Documentos/Estatistica'
import { CaixasArmazenadasPorCDRelatorio } from '../pages/Relatorios/Movimentacao/Caixas/Armazenadas/CD'
import { CaixasArmazenadasPorEmpresaESetorRelatorio } from '../pages/Relatorios/Movimentacao/Caixas/Armazenadas/Empresa'
import { SetoresPorEmpresaRelatorio } from '../pages/Relatorios/Empresas/Setores'
import { UsuariosPorEmpresaRelatorio } from '../pages/Relatorios/Empresas/Usuarios'
import { CaixasMovimentadasPorServicoRelatorio } from '../pages/Relatorios/Movimentacao/Caixas/Servico'
import { LotesNaoDevolvidosRelatorio } from '../pages/Relatorios/Movimentacao/Lotes/Nao-Devolvidos'
import { PrazoGuardaRelatorio } from '../pages/Relatorios/Movimentacao/Caixas/PrazoGuarda'
import { DocumentosInventarioRelatorio } from '../pages/Relatorios/Documentos/Inventario'
import { ArvoreDocumentalRelatorio } from '../pages/Relatorios/ArvoreDocumental/Empresa'
import Inventarios from '../pages/Cadastros/Inventarios'
import { SetoresPermitidos } from '../pages/Cadastros/SetoresPermitidos'
import { ContratosPermitidos } from '../pages/Cadastros/ContratosPermitidos'
import { InventarioCaixasRelatorio } from '../pages/Relatorios/Movimentacao/Caixas/Inventario'
import { DadosImportacaoRelatorio } from '../pages/Relatorios/Documentos/Importacao'
import { TipoDocumentosPorPaginaRelatorio } from '../pages/Relatorios/Documentos/TipoDocumento'
import { FormaPagamento } from '../pages/Financeiro/FormaPagamento'
import { Recorrencia } from '../pages/Financeiro/Recorrencia'
import { CategoriaCliente } from '../pages/Financeiro/CategoriaCliente'
import { CategoriaFornecedor } from '../pages/Financeiro/CategoriaFornecedor'
import { CentroCusto } from '../pages/Financeiro/CentroCusto'
import { Cliente } from '../pages/Financeiro/Cliente'
import { Fornecedor } from '../pages/Financeiro/Fornecedor'
import { ContasPagar } from '../pages/Financeiro/ContasPagar'
import { ContasReceber } from '../pages/Financeiro/ContasReceber'
import { BaixaLancamentosPagarRelatorioForm } from '../pages/Financeiro/Relatorios/Lancamento/Pagar/Baixa'
import { BaixaLancamentosReceberRelatorioForm } from '../pages/Financeiro/Relatorios/Lancamento/Receber/Baixa'
import { PrevisaoLancamentosPagarRelatorioForm } from '../pages/Financeiro/Relatorios/Lancamento/Pagar/Previsao'
import { PrevisaoLancamentosReceberRelatorioForm } from '../pages/Financeiro/Relatorios/Lancamento/Receber/Previsao'
import { LotesArquivadosRelatorio } from '../pages/Relatorios/Movimentacao/Lotes/Arquivados'
import { PrevisaoLancamentosReceberPagarRelatorioForm } from '../pages/Financeiro/Relatorios/Lancamento/Consolidado/Previsao'
import { BaixaLancamentosReceberPagarRelatorioForm } from '../pages/Financeiro/Relatorios/Lancamento/Consolidado/Baixa'
import { Banco } from '../pages/ContaCorrente/Banco'
import { Conta } from '../pages/ContaCorrente/Conta'
import { EventoContaCorrente } from '../pages/ContaCorrente/Evento'
import { MovimentoContaCorrente } from '../pages/ContaCorrente/Movimento'
import { TipoDocumentosRelatorio } from '../pages/Relatorios/TiposDocumento'
import { ConfiguracaoLogin } from '../pages/Cadastros/ConfiguracaoLogin'
import { Despesa } from '../pages/Financeiro/Receita'
import { Receita } from '../pages/Financeiro/Despesa'
import { DocumentosComaprtilhados } from '../pages/DocumentosCompartilhados'
import { GerenciarCompartilhamentos } from '../pages/Auditoria/GerenciarCompartilhamentos'
import { ConsultaImagensSGD } from '../pages/EasyView/ConsultaImagensSGD'

type PrivateRoutesProps = {
  isFirstAccess: boolean
}

export function PrivateRoutes({ isFirstAccess }: PrivateRoutesProps) {
  const location = useLocation()
  const { setTrackRoute } = useTrackRoute()
  const { data: usuario } = useUsuario.FindByUsername()

  const userPermission = usuario?.permissoes ?? []

  const hasAccess = (path: string, permission: number) =>
    userPermission.includes(permission) ? path : 'acesso-nao-autorizado'

  useEffect(() => {
    setTrackRoute({
      path: location.pathname
    })
  }, [setTrackRoute, location.pathname])

  return (
    <Routes>
      {isFirstAccess ? (
        <Route path="*" element={<ResetPassword />} />
      ) : (
        <>
          <Route path="reset-password" element={<ResetPassword />} />
          <Route
            path={hasAccess('/', pms.MENU_DASHBOARD)}
            element={<Navigate to="/dashboard" />}
          />
          <Route path="/login" element={<Navigate to="/dashboard" />} />
          <Route
            path="/documentos-compartilhados/:id_compartilhamento/:id_documento"
            element={<DocumentosComaprtilhados />}
          />
          <Route path={hasAccess('/importar', pms.MENU_IMPORTACAO)}>
            <Route
              path={hasAccess('documentos', pms.IMPORTAR_DOCUMENTOS)}
              element={<ImportarDocumentos />}
            />
            <Route
              path={hasAccess('volumes', pms.IMPORTAR_VOLUMES)}
              element={<ImportarVolumes />}
            />
          </Route>
          <Route
            path={hasAccess('/dashboard', pms.MENU_DASHBOARD)}
            element={<Dashboard />}
          />
          {/* Gerenciar Solicitações */}
          <Route
            path={hasAccess(
              'gerenciar-solicitacoes',
              pms.MENU_GERENCIAR_REQUISICOES
            )}
          >
            <Route
              path={hasAccess('caixas', pms.CAIXAS)}
              element={<Caixas />}
            />
            <Route
              path={hasAccess('inventario', pms.CAIXAS)}
              element={<Inventarios />}
            />
            <Route
              path={hasAccess('requisicao', pms.REQUISICOES)}
              element={<Requisicao />}
            />
            <Route
              path={hasAccess('status-solicitacoes', pms.DETALHAR_REQUISICOES)}
              element={<DetalharRequisicoes />}
            />
            <Route
              path={hasAccess('mensagens', pms.DETALHAR_REQUISICOES)}
              element={<Mensagens />}
            />
          </Route>
          {/* Easy View */}
          <Route path={hasAccess('easy-view', pms.MENU_EASY_VIEW)}>
            <Route
              path={hasAccess('documentos', pms.DOCUMENTOS)}
              element={<Documentos />}
            />
            <Route
              path={hasAccess('consulta-imagens-sgd', pms.CONSULTA_IMAGEM_SGD)}
              element={<ConsultaImagensSGD />}
            />
          </Route>
          {/* Workflow */}
          <Route path={hasAccess('workflow', pms.MENU_WORKFLOW)}>
            <Route
              path={hasAccess('servicos', pms.SERVICOS)}
              element={<Servicos />}
            />
            <Route
              path={hasAccess('fluxo-servico', pms.FLUXO_SERVICOS)}
              element={<FluxoServico />}
            />
            <Route
              path={hasAccess('tipo-atividade', pms.TIPOS_ATIVIDADES)}
              element={<TipoAtividade />}
            />
          </Route>
          {/* Cadastros */}
          <Route path={hasAccess('cadastros', pms.MENU_CADASTROS)}>
            <Route
              path={hasAccess('centro-documentacao', pms.CENTRO_DOCUMENTACAO)}
              element={<CentroDocumentacao />}
            />
            <Route
              path={hasAccess('tipo-caixas', pms.TIPO_CAIXAS)}
              element={<TipoCaixas />}
            />
            <Route
              path={hasAccess('tipo-documentos', pms.TIPO_DOCUMENTOS)}
              element={<TipoDocumentos />}
            />
            <Route
              path={hasAccess('empresas', pms.EMPRESAS)}
              element={<Empresas />}
            />
            <Route
              path={hasAccess('setores', pms.SETORES)}
              element={<Setores />}
            />

            <Route
              path={hasAccess('drive-virtual', pms.DRIVE_VIRTUAL)}
              element={<Setores isDrive={true} />}
            />
            <Route
              path={hasAccess('contratos', pms.CONTRATOS)}
              element={<Contratos />}
            />
            <Route
              path={hasAccess('tipo-temporalidade', pms.TIPO_TEMPORALIDADE)}
              element={<TipoTemporalidade />}
            />
            <Route
              path={hasAccess('arvores-documentais', pms.ARVORE_DOCUMENTAL)}
              element={<ArvoreDocumental />}
            />
          </Route>
          <Route path={hasAccess('relatorios', pms.MENU_RELATORIOS)}>
            <Route
              path={hasAccess(
                'documentos/estatistica',
                pms.REL_ESTATISTICAS_ARMAZENAMENTO_EMPRESA
              )}
              element={<DocumentosEstatisticaPorEmpresaRelatorio />}
            />

            <Route
              path={hasAccess(
                'caixas/servico',
                pms.REL_CAIXAS_MOVIMENTADAS_SERVICO
              )}
              element={<CaixasMovimentadasPorServicoRelatorio />}
            />

            <Route
              path={hasAccess(
                'caixas/armazenadas/cd',
                pms.REL_CAIXAS_ARMAZENADAS_CD
              )}
              element={<CaixasArmazenadasPorCDRelatorio />}
            />

            <Route
              path={hasAccess(
                'caixas/armazenadas/empresa',
                pms.REL_CAIXAS_ARMAZENADAS_EMPRESA
              )}
              element={<CaixasArmazenadasPorEmpresaESetorRelatorio />}
            />

            <Route
              path={hasAccess('empresa/setores/', pms.REL_EMPRESA_SETORES)}
              element={<SetoresPorEmpresaRelatorio />}
            />

            <Route
              path={hasAccess('empresa/usuarios/', pms.REL_EMPRESA_USUARIOS)}
              element={<UsuariosPorEmpresaRelatorio />}
            />

            <Route
              path={hasAccess(
                'lotes/nao-devolvidos/',
                pms.REL_LOTES_NAO_DEVOLVIDOS
              )}
              element={<LotesNaoDevolvidosRelatorio />}
            />

            <Route
              path={hasAccess('lotes/arquivados/', pms.REL_LOTES_ARQUIVADOS)}
              element={<LotesArquivadosRelatorio />}
            />

            <Route
              path={hasAccess('tipos-documento/', pms.REL_TIPOS_DOCUMENTOS)}
              element={<TipoDocumentosRelatorio />}
            />

            <Route
              path={hasAccess('caixas/prazo-guarda/', pms.REL_PRAZO_GUARDA)}
              element={<PrazoGuardaRelatorio />}
            />

            <Route
              path={hasAccess('caixas/inventario/', pms.REL_INVENTARIO_CAIXAS)}
              element={<InventarioCaixasRelatorio />}
            />

            <Route
              path={hasAccess(
                'documentos/inventario/',
                pms.REL_INVENTARIO_DOCUMENTOS
              )}
              element={<DocumentosInventarioRelatorio />}
            />

            <Route
              path={hasAccess(
                'documentos/importacao/',
                pms.REL_DADOS_IMPORTACAO
              )}
              element={<DadosImportacaoRelatorio />}
            />

            <Route
              path={hasAccess(
                'documentos/tipo-documento/',
                pms.REL_TIPO_DOCUMENTO_PAGINA
              )}
              element={<TipoDocumentosPorPaginaRelatorio />}
            />

            <Route
              path={hasAccess(
                'arvores-documentais/empresa/',
                pms.REL_ARVORE_DOCUMENTAL
              )}
              element={<ArvoreDocumentalRelatorio />}
            />
          </Route>
          {/* CONTA CORRENTE */}
          <Route path={hasAccess('conta-corrente', pms.MENU_CONTA_CORRENTE)}>
            <Route
              path={hasAccess('banco', pms.CC_BANCO)}
              element={<Banco />}
            />
            <Route
              path={hasAccess('conta/', pms.CC_CONTA)}
              element={<Conta />}
            />

            <Route
              path={hasAccess('evento-conta-corrente/', pms.CC_EVENTO)}
              element={<EventoContaCorrente />}
            />

            <Route
              path={hasAccess('movimento-conta-corrente/', pms.CC_MOVIMENTACAO)}
              element={<MovimentoContaCorrente />}
            />
          </Route>
          {/* FINANCEIRO */}
          <Route path={hasAccess('financeiro', pms.MENU_FATURAMENTO)}>
            <Route
              path={hasAccess('forma-pagamento', pms.FAT_FORMA_PAGAMENTO)}
              element={<FormaPagamento />}
            />

            <Route
              path={hasAccess('recorrencia', pms.FAT_RECORRENCIA_LANCAMENTO)}
              element={<Recorrencia />}
            />

            <Route
              path={hasAccess('categoria/cliente', pms.FAT_CATEGORIA_CLIENTE)}
              element={<CategoriaCliente />}
            />

            <Route
              path={hasAccess('cadastro/cliente', pms.FAT_CLIENTE)}
              element={<Cliente />}
            />

            <Route
              path={hasAccess('lancamento/receber', pms.FAT_CONTAS_A_RECEBER)}
              element={<ContasReceber />}
            />

            <Route
              path={hasAccess(
                'categoria/fornecedor',
                pms.FAT_CATEGORIA_FORNECEDOR
              )}
              element={<CategoriaFornecedor />}
            />

            <Route
              path={hasAccess('cadastro/fornecedor', pms.FAT_FORNECEDOR)}
              element={<Fornecedor />}
            />

            <Route
              path={hasAccess('lancamento/pagar', pms.FAT_CONTAS_A_PAGAR)}
              element={<ContasPagar />}
            />

            <Route
              path={hasAccess('centro-custo', pms.FAT_CENTRO_CUSTO)}
              element={<CentroCusto />}
            />

            <Route
              path={hasAccess('receita', pms.FAT_RECEITA)}
              element={<Receita />}
            />

            <Route
              path={hasAccess('despesa', pms.FAT_DESPESA)}
              element={<Despesa />}
            />

            <Route
              path={hasAccess(
                'baixa-lancamento-pagar',
                pms.REL_FAT_BAIXA_FINANCEIRO_PAGAR
              )}
              element={<BaixaLancamentosPagarRelatorioForm />}
            />

            <Route
              path={hasAccess(
                'previsao-lancamento-pagar',
                pms.REL_FAT_PREVISAO_FINANCEIRO_PAGAR
              )}
              element={<PrevisaoLancamentosPagarRelatorioForm />}
            />

            <Route
              path={hasAccess(
                'baixa-lancamento-receber',
                pms.REL_FAT_BAIXA_FINANCEIRO_RECEBER
              )}
              element={<BaixaLancamentosReceberRelatorioForm />}
            />

            <Route
              path={hasAccess(
                'previsao-lancamento-receber',
                pms.REL_FAT_PREVISAO_FINANCEIRO_RECEBER
              )}
              element={<PrevisaoLancamentosReceberRelatorioForm />}
            />

            <Route
              path={hasAccess(
                'previsao-lancamento-receber-pagar',
                pms.REL_FAT_PREVISAO_FINANCEIRO_RECEBER_PAGAR
              )}
              element={<PrevisaoLancamentosReceberPagarRelatorioForm />}
            />

            <Route
              path={hasAccess(
                'baixa-lancamento-receber-pagar',
                pms.REL_FAT_BAIXA_FINANCEIRO_RECEBER_PAGAR
              )}
              element={<BaixaLancamentosReceberPagarRelatorioForm />}
            />
          </Route>

          {/* Auditoria */}
          <Route path={hasAccess('Auditoria', pms.MENU_AUDITORIA)}>
            <Route
              path={hasAccess('logs-usuarios', pms.LOGS_USUARIOS)}
              element={<LogsUsuarios />}
            />
            <Route
              path={hasAccess(
                'configuracao-de-armazenamento',
                pms.MENU_AUDITORIA
              )}
              element={<ConfiguracaoArmazenamento />}
            />
            <Route
              path={hasAccess(
                'gerenciar-compartilhamentos',
                pms.COMPARTILHAR_DOCUMENTO
              )}
              element={<GerenciarCompartilhamentos />}
            />
          </Route>

          {/* Administração de Usuários */}
          <Route
            path={hasAccess(
              'administracao-usuarios',
              pms.MENU_ADMINISTRACAO_USUARIOS
            )}
          >
            <Route
              path={hasAccess('usuarios', pms.USUARIOS)}
              element={<Usuarios />}
            />
            <Route
              path={hasAccess('permissoes', pms.PERMISSOES)}
              element={<Permissoes />}
            />
            <Route
              path={hasAccess('setores-permitidos', pms.SETOR_PERMITIDO)}
              element={<SetoresPermitidos />}
            />
            <Route
              path={hasAccess('contratos-permitidos', pms.CONTRATO_PERMITIDO)}
              element={<ContratosPermitidos />}
            />
            <Route
              path={hasAccess('controle-de-bloqueios', pms.CONTROLE_BLOQUEIOS)}
              element={<p>Controle de Bloqueios</p>}
            />
          </Route>

          {/* Parametros */}
          <Route path={hasAccess('parametros', pms.PARAMETRO_CADASTRO)}>
            <Route
              path={hasAccess('configuracao-de-login', pms.PARAMETRO_CADASTRO)}
              element={<ConfiguracaoLogin />}
            />
          </Route>

          <Route path="/*" element={<PageNotFound />} />
        </>
      )}
    </Routes>
  )
}
