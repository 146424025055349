import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ApiEventoContaCorrente } from '../../../services/conta-corrente/evento'

const QUERY_KEY = 'qkEventoContaCorrente'

const FindAll = (page: number, q = '') => {
  return useQuery([QUERY_KEY, q, page], () =>
    ApiEventoContaCorrente.findAll(page, q)
  )
}

const ListAll = () => {
  return useQuery([QUERY_KEY], ApiEventoContaCorrente.listAll)
}

const ListAllByTipo = (tipo = '') => {
  return useQuery([QUERY_KEY, tipo], () =>
    ApiEventoContaCorrente.listAllByTipo(tipo)
  )
}

const Create = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiEventoContaCorrente.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Update = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiEventoContaCorrente.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Remove = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiEventoContaCorrente.remove, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

export const useEventoContaCorrente = {
  Create,
  Update,
  FindAll,
  ListAll,
  ListAllByTipo,
  Remove
}
