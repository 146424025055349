import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ApiUsuario } from '../../services/usuario'
import { TAtivo } from '../../utils/defaultApi/types'
import { useAuth } from '../auth'

const QUERY_KEY = 'qkUsuario'

const FindAll = (page: number, q = '', ativo: TAtivo = true) => {
  return useQuery([QUERY_KEY, q, page, ativo], () =>
    ApiUsuario.findAll(page, q, ativo)
  )
}

const FindAllUsersByContract = (
  page: number,
  contracts?: string,
  userEmpresa?: string,
  selectedOrder?: string,
  q = '',
  ativo: TAtivo = true
) => {
  return useQuery([QUERY_KEY, 'contracts', q, page, selectedOrder, ativo], () =>
    ApiUsuario.findAllUsersByContract(
      page,
      contracts,
      userEmpresa,
      selectedOrder,
      q,
      ativo
    )
  )
}

const ListAll = () => {
  return useQuery([QUERY_KEY], ApiUsuario.listAll)
}

const FindByUsername = () => {
  const { user } = useAuth()
  return useQuery(
    [QUERY_KEY, user],
    () => ApiUsuario.findByUsername(user.username),
    {
      refetchOnWindowFocus: false
    }
  )
}

const FindById = (id_usuario: string) => {
  return useQuery([QUERY_KEY, 'usuario', id_usuario], () =>
    ApiUsuario.findById(id_usuario)
  )
}

const Create = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiUsuario.createUsuarioPessoa, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Update = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiUsuario.updatePessoaUsuario, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Remove = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiUsuario.remove, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const ResetPassword = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiUsuario.resetPassword, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const sendEmail = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiUsuario.sendEmail, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const changeStatus = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiUsuario.changeStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const ListAllXSolution = (id_empresa?: string) => {
  return useQuery([QUERY_KEY, 'X-SOLUTION', id_empresa], () =>
    ApiUsuario.listAllXSolution(id_empresa)
  )
}

export const useUsuario = {
  FindAll,
  FindAllUsersByContract,
  ListAll,
  ListAllXSolution,
  Create,
  FindByUsername,
  FindById,
  Update,
  Remove,
  ResetPassword,
  sendEmail,
  changeStatus
}
