import { DefaultApi } from '../../utils/defaultApi'
import { IResult } from '../../utils/defaultApi/types'
import api from '../api'
import { IDadosImportacao } from '../dados-importacao/types'
import {
  AdvancedFindAllProps,
  ICaixa,
  IImportacaoVolume,
  IPosicaoPrateleira
} from './types'
import { v4 as uuid } from 'uuid'
const endpoint = '/caixa'
const resourceId = 'id_caixa'

class CaixaService extends DefaultApi<ICaixa> {
  public import = async ({ formData, progressCallback }: IImportacaoVolume) => {
    const frmData = formData.get('formData')
    formData.delete('formData')
    const newJSON = new Blob([JSON.stringify(frmData)])
    const jsonFilename = uuid() + '.dat'
    const jsonFile = new File([newJSON], jsonFilename)
    formData.append('files', jsonFile)
    const mockData = jsonFilename
    formData.append('formData', mockData)

    const { data } = await api.post<IDadosImportacao>(
      `${this.endpoint}/importar`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: ({ loaded, total }) => {
          if (progressCallback) {
            progressCallback(Math.floor((loaded / total) * 100))
          }
        }
      }
    )
    return data
  }

  public findAllByIdContrato = async (
    id: string,
    page: number,
    q?: string,
    r?: string,
    limit?: number
  ): Promise<IResult<ICaixa>> => {
    if (id === '0' || id === '')
      return {
        pagination: {
          totalItems: 0,
          itemsPerPage: 0,
          currentPage: 1,
          totalPages: 1
        },
        data: []
      }
    const pageString = page ? `page=${page}` : ''
    const qString = q ? `q=${q}` : ''
    const rString = r ? `r=${r}` : ''
    const limitString = limit ? `limit=${limit}` : ''
    const params = [pageString, qString, rString, limitString]
      .filter((value) => value !== '')
      .join('&')
    const { data } = await api.get<IResult<ICaixa>>(
      `${this.endpoint}/contrato/${id}?${params}`
    )
    return data
  }

  listAllCaixas = async (): Promise<ICaixa[]> => {
    const { data } = await api.get<ICaixa[]>(`${this.endpoint}/lista/`)
    return data
  }

  public listAllByIdContrato = async (id: string): Promise<ICaixa[]> => {
    if (id === '0') return []
    const { data } = await api.get<ICaixa[]>(
      `${this.endpoint}/lista-contrato/${id}`
    )
    return data
  }

  public listAllId = async (id: string): Promise<ICaixa[]> => {
    if (id === '0') return []
    const { data } = await api.get<ICaixa[]>(`${this.endpoint}/lista/${id}`)
    return data
  }

  public listAllByIdCaixaContainer = async (id: string): Promise<ICaixa[]> => {
    if (id === '0' || id === '') return []

    const { data } = await api.get<ICaixa[]>(
      `${this.endpoint}/lista-container/${id}`
    )
    return data
  }

  public allocateBox = async ({
    id_caixa,
    id_posicao_prateleira
  }: {
    id_caixa: string
    id_posicao_prateleira: string
  }) => {
    const { data } = await api.put<IPosicaoPrateleira>(
      `${this.endpoint}/alocar/${id_caixa}`,
      {
        id_posicao_prateleira
      }
    )
    return data
  }

  public advancedFindAll = async (
    advancedFindAllProps: AdvancedFindAllProps
  ): Promise<IResult<ICaixa>> => {
    const params = new URLSearchParams()
    for (const [key, value] of Object.entries(advancedFindAllProps)) {
      if (typeof value !== 'undefined') {
        params.append(key, value)
      }
    }

    const { data } = await api.get<IResult<ICaixa>>(
      `${this.endpoint}/avancado`,
      {
        params
      }
    )
    return data
  }

  public findByEndereco = async (
    endereco: string
  ): Promise<ICaixa | undefined> => {
    if (endereco.trim() === '' || endereco.trim().split('.').length < 5) {
      return undefined
    }
    const { data } = await api.get<ICaixa>(
      `${this.endpoint}/localizacao/${endereco}`
    )
    return data
  }

  public findByCodigo = async (codigo: string): Promise<ICaixa | undefined> => {
    if (codigo.trim() === '') {
      return undefined
    }
    const { data } = await api.get<ICaixa>(`${this.endpoint}/codigo/${codigo}`)
    return data
  }

  public homologate = async (boxesIds: string[]): Promise<void> => {
    await api.patch<ICaixa>(`${this.endpoint}/homologar/`, {
      boxesIds
    })
  }

  public homologateAdvanced = async (
    advancedFindAllProps: AdvancedFindAllProps
  ): Promise<void> => {
    await api.patch<ICaixa>(`${this.endpoint}/homologar/avancado`, {
      ...advancedFindAllProps
    })
  }
}

export const ApiCaixa = new CaixaService(endpoint, resourceId)
