import { DefaultApi } from '../../utils/defaultApi'
import api from '../api'
import { IPosicaoPrateleira } from './types'

const endpoint = '/posicao-prateleira'
const resourceId = 'id_posicao_prateleira'

class ServicePosicaoPrateleira extends DefaultApi<IPosicaoPrateleira> {
  findByPrateleira = async (id_prateleira: string) => {
    if (id_prateleira === '' || id_prateleira === '0') {
      return []
    }
    const { data } = await api.get<IPosicaoPrateleira[]>(
      `${this.endpoint}/prateleira/${id_prateleira}`
    )
    return data
  }
}

const apiPosicaoPrateleira = new ServicePosicaoPrateleira(endpoint, resourceId)

export const ApiPosicaoPrateleira = {
  listAll: apiPosicaoPrateleira.listAll,
  findByPrateleira: apiPosicaoPrateleira.findByPrateleira
}
