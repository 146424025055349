import {
  RadioGroup as RadioGroupMUI,
  RadioGroupProps as RadioGroupMUIProps
} from '@mui/material'
import { forwardRef, ForwardRefRenderFunction } from 'react'
import { ComponentGroup } from '../../../../components/ComponentGroup'

export type RadioGroupProps = {
  title: string
  message?: string
} & RadioGroupMUIProps

const RadioGroupBase: ForwardRefRenderFunction<
  HTMLSelectElement,
  RadioGroupProps
> = ({ title, message, ...rest }, ref) => {
  return (
    <ComponentGroup title={title} message={message}>
      <RadioGroupMUI ref={ref} {...rest}></RadioGroupMUI>
    </ComponentGroup>
  )
}

export const RadioGroup = forwardRef(RadioGroupBase)
