import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/DeleteForever'
import CloseIcon from '@mui/icons-material/Close'
import useToast from '../../hooks/toast/useToast'
import { useDocumento } from '../../hooks/documento/useDocumento'
import PreviewIcon from '@mui/icons-material/DocumentScannerOutlined'
import { IDocumento } from '../../services/documento/types'

export interface ModalDocumentsBoxProps {
  idCaixa: string
  handleClose: () => void
  open: boolean
  handleDigitalFile: (
    id_contrato: string,
    filePath: string,
    id_documento: string,
    action: 'download' | 'preview'
  ) => void
}

export function ModalDocumentsBox({
  idCaixa,
  open,
  handleClose,
  handleDigitalFile
}: ModalDocumentsBoxProps) {
  const { Toast } = useToast()
  const { data: documentos, isLoading } = useDocumento.FindByIdCaixa(idCaixa)
  const { mutateAsync: removeDocumento } = useDocumento.Remove()

  const handleRemoveDocumento = async (id: string) => {
    await Toast(
      removeDocumento(id),
      'REMOVED',
      'Documento excluído com sucesso!'
    )
  }

  const canDeleteDocument = (doc: IDocumento) => {
    const validStates = ['INVENTARIANDO', 'CRIADO_CLIENTE']
    return validStates.includes(doc.estado ?? '')
  }

  const documentosDigitais = Array.isArray(documentos)
    ? documentos.filter((doc) => !!doc.path)
    : []

  if (isLoading) return <></>

  return (
    <Modal
      open={open}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        top: '20%'
      }}
    >
      <Box
        borderRadius="4px"
        borderColor="lightgray"
        sx={{
          bgcolor: 'background.paper',
          boxShadow: 24,
          width: {
            sm: '780px',
            md: '880px'
          }
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          bgcolor="#2835ad"
          px={2}
          sx={{
            borderTopRightRadius: '4px',
            borderTopLeftRadius: '4px'
          }}
        >
          <Typography color="#FFF" py={1.5} variant="subtitle1">
            Documentos Digitais da Caixa
          </Typography>

          <Tooltip title="Fechar" arrow>
            <IconButton onClick={() => handleClose()}>
              <CloseIcon sx={{ color: '#fff' }} />
            </IconButton>
          </Tooltip>
        </Box>
        <Box px={2} py={1}>
          {!!documentosDigitais?.length && (
            <>
              <Stack
                gap={2}
                direction="row"
                alignItems="center"
                sx={{ marginTop: 2, padding: 0 }}
              >
                <Typography sx={{ width: '460px' }} fontWeight="600">
                  Descrição
                </Typography>
                <Typography sx={{ width: '190px' }} fontWeight="600">
                  Tipo de Documento
                </Typography>
                <Typography fontWeight="600" sx={{ width: '100px' }}>
                  Privacidade
                </Typography>
                <Typography fontWeight="600" sx={{ width: '120px' }}>
                  Ações
                </Typography>
              </Stack>

              <Divider sx={{ margin: 0, padding: 0 }} />
            </>
          )}

          <Box
            sx={{
              overflow: 'auto',
              maxHeight: 320
            }}
          >
            {documentosDigitais?.map((doc) => (
              <Stack
                key={doc.id_documento}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography sx={{ width: '450px' }}>{doc.descricao}</Typography>
                <Typography sx={{ width: '190px' }}>
                  {doc.tipo_documento?.descricao}
                </Typography>
                <Typography sx={{ width: '100px' }}>
                  {doc.dados_pessoais ? (
                    <span>
                      [<strong>{' P '}</strong>]
                    </span>
                  ) : null}
                  {doc.dados_pessoais_sensiveis ? (
                    <span>
                      [<strong>{' S '}</strong>]
                    </span>
                  ) : null}
                  {doc.dados_criancas_adolescentes ? (
                    <span>
                      [<strong>{' C '}</strong>]
                    </span>
                  ) : null}
                </Typography>
                <Stack
                  key={doc.id_documento}
                  direction="row"
                  alignItems="center"
                  gap={0}
                  sx={{ width: '120px' }}
                >
                  <Tooltip title="Visualizar documento" arrow>
                    <IconButton
                      onClick={() =>
                        handleDigitalFile(
                          doc.id_contrato ?? '',
                          doc.path ?? '',
                          doc.id_documento!,
                          'preview'
                        )
                      }
                      disabled={!doc.path}
                    >
                      <PreviewIcon color="primary" fontSize="large" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Excluir documento" arrow>
                    <IconButton
                      onClick={() =>
                        handleRemoveDocumento(doc.id_documento ?? '')
                      }
                      disabled={!canDeleteDocument(doc)}
                    >
                      <DeleteIcon
                        color={`${
                          canDeleteDocument(doc) ? 'primary' : 'disabled'
                        }`}
                        fontSize="large"
                      />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>
            ))}
          </Box>
          <Stack direction="row" gap={2} p={2} justifyContent="flex-end">
            <Button
              variant="contained"
              type="submit"
              sx={{
                fontWeight: 600
              }}
              onClick={() => handleClose()}
            >
              Fechar
            </Button>
          </Stack>
        </Box>
        <Divider />
      </Box>
    </Modal>
  )
}
