import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  Menu,
  MenuItem,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material'
import { ReactNode, useEffect, useState } from 'react'

import LibraryAddIcon from '@mui/icons-material/LibraryAdd'
import DeleteIcon from '@mui/icons-material/Delete'
import InfoIcon from '@mui/icons-material/Info'
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges'
import BlockIcon from '@mui/icons-material/Block'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'

import { IEstante } from '../../../../services/estante/types'
import { Descriptor } from './Descriptor'
import { getEstanteSpace, getPrateleiraSpace } from '../lib/func'
import { IPrateleira } from '../../../../services/prateleira/types'
import { PrateleiraButton } from './PrateleiraButton'
import { IPosicaoPrateleira } from '../../../../services/posicaoPrateleira/types'
import { PrateleiraDraw } from './PrateleiraDraw'
import { PositionDescriptor } from './PositionDescriptor'
import { useTipoCaixa } from '../../../../hooks/tipoCaixa/useTipoCaixa'

interface EstanteDrawProps {
  estante: IEstante
  enderecoCorredor: string
  handleExcluirPosicaoClick: (
    posicao: IPosicaoPrateleira,
    enderecoPrateleira: string
  ) => void
  handleAdicionarPosicaoClick: (
    prateleira: IPrateleira,
    enderecoEstante: string
  ) => void
  handleExcluirPrateleiraClick: (
    prateleira: IPrateleira,
    enderecoEstante: string
  ) => void
  handleAdicionarPrateleiraClick: (
    estante: IEstante,
    enderecoEstante: string
  ) => void
  handleMudarPrateleiraTipoCaixaClick: (
    prateleira: IPrateleira,
    enderecoEstante: string
  ) => void
  handleExcluirEstanteClick: (
    estante: IEstante,
    enderecoCorredor: string
  ) => void
  handleAdicionarEstanteClick: (
    id_corredor: string,
    enderecoCentro: string
  ) => void
  handlePrateleiraSelect: (prateleira: IPrateleira) => void
  handleAlternarPosicaoClick: (posicaoPrateleira: IPosicaoPrateleira) => void
}
export function EstanteDraw({
  estante,
  enderecoCorredor,
  handleExcluirPosicaoClick,
  handleAdicionarPosicaoClick,
  handleExcluirPrateleiraClick,
  handleAdicionarPrateleiraClick,
  handleMudarPrateleiraTipoCaixaClick,
  handleExcluirEstanteClick,
  handleAdicionarEstanteClick,
  handlePrateleiraSelect,
  handleAlternarPosicaoClick
}: EstanteDrawProps) {
  // Menus de edição (INÍCO) --------------------------------------------------
  interface PopupProps {
    show: boolean
    close: () => void
    title: string
    data: ReactNode
  }
  const [popupData, setPopupData] = useState<PopupProps>({
    show: false,
    close: () => {},
    title: '',
    data: <></>
  })
  /**
   * Exibe uma janela de popup
   * @param close Mostrar caixa de diálogo com alguma ação a fazer
   * @param data Elementos a serem exibidos
   * @param title Título da janela
   * @returns O popup a ser exbibido
   */
  const popup = (close: () => void, data: ReactNode, title: string) => {
    const newPopupData = {
      show: true,
      close,
      data,
      title
    }
    setPopupData(newPopupData)
  }
  // Prateleira (INÍCIO)
  const [prateleiraMenuElement, setPrateleiraMenuElement] =
    useState<HTMLElement | null>(null)
  const [openMenuPrateleira, setOpenMenuPrateleira] = useState<boolean>(false)
  const [idPrateleiraMenuElement, setIdPrateleiraMenuElement] =
    useState<string>('')
  const [currentPrateleira, setCurrentPrateleira] = useState<IPrateleira>()

  const tpCaxia =
    !!currentPrateleira && !!currentPrateleira.id_tipo_caixa
      ? currentPrateleira.id_tipo_caixa
      : ''
  const { data: tipoCaixa } = useTipoCaixa.FindById(tpCaxia)
  useEffect(() => {
    setOpenMenuPrateleira(!!prateleiraMenuElement)
  }, [prateleiraMenuElement])

  // Prateleira (FIM)

  // Posição de Prateleira (INÍCIO)
  const [posicaoMenuElement, setPosicaoMenuElement] =
    useState<HTMLElement | null>(null)
  const [openMenuPosicao, setOpenMenuPosicao] = useState<boolean>(false)
  const [idPosicaoMenuElement, setIdPosicaoMenuElement] = useState<string>('')
  const [currentPosicao, setCurrentPosicao] = useState<IPosicaoPrateleira>()

  useEffect(() => {
    setOpenMenuPosicao(!!posicaoMenuElement)
  }, [posicaoMenuElement])
  // Posição de Prateleira (FIM)
  const handlePrateleiraMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    prateleira: IPrateleira
  ) => {
    handlePrateleiraSelect(prateleira)
    setCurrentPrateleira(prateleira)
    setIdPrateleiraMenuElement(event.currentTarget.id)
    setPrateleiraMenuElement(event.currentTarget)
  }
  const handlePrateleiraMenuClose = () => {
    setCurrentPrateleira(undefined)
    setIdPrateleiraMenuElement('')
    setPrateleiraMenuElement(null)
  }

  const handlePosicaoMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    posicao: IPosicaoPrateleira,
    prateleira: IPrateleira
  ) => {
    setCurrentPosicao(posicao)
    setCurrentPrateleira(prateleira)
    setIdPosicaoMenuElement(event.currentTarget.id)
    setPosicaoMenuElement(event.currentTarget)
  }
  const handlePosicaoMenuClose = () => {
    setCurrentPosicao(undefined)
    setIdPosicaoMenuElement('')
    setPosicaoMenuElement(null)
  }

  const handlePrateleiraMenuInfo = () => {
    const tipoCaixaInfo = (
      <TableRow>
        <TableCell>
          <Typography fontWeight={'bold'}>{`Tipo de Caixa`}</Typography>
        </TableCell>

        <TableCell>{tipoCaixa?.descricao}</TableCell>
      </TableRow>
    )
    if (currentPrateleira) {
      const prateleiraDescriptor = (
        <Descriptor
          title={`Prateleira: ${getAddressEstante()}.${
            currentPrateleira.identificacao
          }`}
          data={{
            ...getPrateleiraSpace(currentPrateleira),
            other: tipoCaixaInfo
          }}
          showButton1={false}
          showButton2={false}
        />
      )

      popup(handlePopupSimplesClose, prateleiraDescriptor, 'Prateleira')
    }
    setCurrentPrateleira(undefined)
    setIdPrateleiraMenuElement('')
    setPrateleiraMenuElement(null)
  }

  const handleApagarPosicoes = () => {
    handleExcluirPosicaoClick(
      currentPosicao!,
      `${getAddressEstante()}.${currentPrateleira?.identificacao}`
    )
    setCurrentPosicao(undefined)
    handlePrateleiraMenuClose()
  }

  const handleToggleState = () => {
    handleAlternarPosicaoClick(currentPosicao!)
    setCurrentPosicao(undefined)
    handlePosicaoMenuClose()
  }

  const handleAdicionarPosicoes = () => {
    handleAdicionarPosicaoClick(currentPrateleira!, getAddressEstante())
    setCurrentPosicao(undefined)
    handlePrateleiraMenuClose()
  }

  const handleApagarPratelerias = () => {
    handleExcluirPrateleiraClick(currentPrateleira!, getAddressEstante())
    handlePrateleiraMenuClose()
  }

  const handleChangePrateleiraTipoCaixa = () => {
    handleMudarPrateleiraTipoCaixaClick(currentPrateleira!, getAddressEstante())
    setCurrentPosicao(undefined)
    handlePrateleiraMenuClose()
  }

  const handleAdicionarPrateleiras = () => {
    handleAdicionarPrateleiraClick(estante, getAddressEstante())
    handlePrateleiraMenuClose()
  }

  const handlePosicaoMenuInfoClick = () => {
    if (currentPosicao) {
      popup(
        handlePopupSimplesClose,
        <PositionDescriptor
          posicao={currentPosicao}
          enderecoPrateleira={`${getAddressEstante()}.${
            currentPrateleira?.identificacao ?? ''
          }`}
        />,
        'Posição de Prateleira'
      )
    }
    setCurrentPosicao(undefined)
    setIdPosicaoMenuElement('')
    setPosicaoMenuElement(null)
  }

  const handlePopupSimplesClose = () => {
    const newPopupData = {
      ...popupData,
      show: false
    }
    setPopupData(newPopupData)
  }

  // Menus de edição (FIM) ----------------------------------------------------

  const getAddressEstante = () => {
    return enderecoCorredor + '.' + estante.identificacao
  }

  const getPrateleiras = (estante: IEstante): IPrateleira[] => {
    const { prateleira } = estante
    return prateleira ?? ([] as IPrateleira[])
  }

  return (
    <Stack
      key={`ID-${getAddressEstante()}`}
      id={`ID-${getAddressEstante()}`}
      gap={2}
      direction={'row'}
      sx={{
        border: '1px solid gray',
        backgroundColor: 'white',
        padding: '12px',
        borderRadius: '4px',
        width: `calc( 100% - ${250}px )`
      }}
    >
      <Descriptor
        title={`Estante: ${estante.identificacao} (${getAddressEstante()})`}
        data={getEstanteSpace(estante)}
        showButton1={true}
        showButton2={true}
        tipButton1={'Adicionar estantes ao corredor atual'}
        tipButton2={'Excluir a estante atual e as subsequentes'}
        actionButton1={() => {
          handleAdicionarEstanteClick(estante.id_corredor, enderecoCorredor)
        }}
        actionButton2={() => {
          handleExcluirEstanteClick(estante, getAddressEstante())
        }}
      />
      {estante.prateleira && estante.prateleira.length > 0 ? (
        <>
          <table
            style={{
              height: `calc( 43px * ${estante.prateleira?.length} )`
            }}
          >
            <tbody>
              {getPrateleiras(estante)
                .sort((a, b) => {
                  if (Number(a.identificacao) < Number(b.identificacao)) {
                    return 1
                  }
                  if (Number(a.identificacao) > Number(b.identificacao)) {
                    return -1
                  }
                  return 0
                })
                .map((prateleira) => (
                  <PrateleiraButton
                    key={`ID-${getAddressEstante()}.${
                      prateleira.identificacao
                    }`}
                    prateleira={prateleira}
                    openMenu={openMenuPrateleira}
                    handleMouseClick={(event) =>
                      handlePrateleiraMenuClick(event, prateleira)
                    }
                    enderecoEstante={getAddressEstante()}
                  />
                ))}
            </tbody>
          </table>
          <Menu
            id="MENU-PRATELEIRA"
            elevation={0}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            MenuListProps={{
              'aria-labelledby': idPrateleiraMenuElement
            }}
            anchorEl={prateleiraMenuElement}
            open={openMenuPrateleira}
            onClose={handlePrateleiraMenuClose}
            PaperProps={{
              sx: {
                backgroundColor: '#EBEBEB',
                fontWeight: 'bold',
                boxShadow: '3px 3px 8px gray'
              }
            }}
          >
            <MenuItem
              onClick={() => {
                handlePrateleiraMenuInfo()
              }}
            >
              <InfoIcon />{' '}
              <span style={{ marginLeft: '12px' }}>
                Informações da Prateleira
              </span>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleAdicionarPrateleiras}>
              <LibraryAddIcon />{' '}
              <span style={{ marginLeft: '12px' }}>Expandir Estante</span>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleChangePrateleiraTipoCaixa}>
              <PublishedWithChangesIcon />{' '}
              <span style={{ marginLeft: '12px' }}>
                Mudar Tipo de Caixa Padrão
              </span>
            </MenuItem>
            <Divider />
            <MenuItem color="red" onClick={handleApagarPratelerias}>
              <DeleteIcon /> <span style={{ marginLeft: '12px' }}>Excluir</span>
            </MenuItem>
          </Menu>
          <Box overflow={'auto'} width={`calc( 100% - ${300}px )`}>
            <table>
              <tbody>
                {getPrateleiras(estante)
                  .sort((a, b) => {
                    if (Number(a.identificacao) < Number(b.identificacao)) {
                      return 1
                    }
                    if (Number(a.identificacao) > Number(b.identificacao)) {
                      return -1
                    }
                    return 0
                  })
                  .map((prateleira) => (
                    <PrateleiraDraw
                      key={`ID-${getAddressEstante()}.${
                        prateleira.identificacao
                      }-POSICOES`}
                      enderecoEstante={getAddressEstante()}
                      prateleira={prateleira}
                      openPosicaoMenu={openMenuPosicao}
                      handlePosicaoPrateleiraClick={handlePosicaoMenuClick}
                      handleAdicionarPosicaoClick={handleAdicionarPosicaoClick}
                    />
                  ))}
              </tbody>
            </table>
            <Menu
              id="MENU-POSICAO"
              elevation={0}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              MenuListProps={{
                'aria-labelledby': idPosicaoMenuElement
              }}
              anchorEl={posicaoMenuElement}
              open={openMenuPosicao}
              onClose={handlePosicaoMenuClose}
              PaperProps={{
                sx: {
                  backgroundColor: '#EBEBEB',
                  fontWeight: 'bold',
                  boxShadow: '3px 3px 8px gray'
                }
              }}
            >
              <MenuItem
                onClick={() => {
                  handlePosicaoMenuInfoClick()
                }}
              >
                <InfoIcon />
                <span style={{ marginLeft: '12px' }}>
                  Informações da Posição de Prateleira
                </span>
              </MenuItem>

              <Divider />
              <MenuItem onClick={handleAdicionarPosicoes}>
                <LibraryAddIcon />{' '}
                <span style={{ marginLeft: '12px' }}>Expandir Prateleira</span>
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleToggleState}>
                {currentPosicao?.habilitada ? (
                  <BlockIcon />
                ) : (
                  <RadioButtonUncheckedIcon />
                )}{' '}
                <span style={{ marginLeft: '12px' }}>
                  {currentPosicao?.habilitada ? 'Desabilitar' : 'Habilitar'}
                </span>
              </MenuItem>
              <Divider />
              <MenuItem color="red" onClick={handleApagarPosicoes}>
                <DeleteIcon />{' '}
                <span style={{ marginLeft: '12px' }}>Excluir</span>
              </MenuItem>
            </Menu>
          </Box>
          <Dialog open={popupData.show} fullWidth maxWidth={'xs'}>
            <DialogTitle
              sx={{
                backgroundColor: 'lightgray'
              }}
            >
              {popupData.title}
            </DialogTitle>
            <Stack
              display={'flex'}
              justifyContent={'space-between'}
              margin={'12px'}
            >
              {popupData.data}

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  width: '100%'
                }}
                gap={2}
              >
                <Button onClick={() => popupData.close()} variant="outlined">
                  Fechar
                </Button>
              </Box>
            </Stack>
          </Dialog>
        </>
      ) : (
        <>
          <Box>
            <table>
              <tbody>
                <tr>
                  <td>
                    <Tooltip title={'Adiconar prateleiras a estante atual'}>
                      <Button
                        onClick={() => {
                          handleAdicionarPrateleiras()
                        }}
                        variant="contained"
                        color="success"
                      >
                        <LibraryAddIcon />
                      </Button>
                    </Tooltip>
                  </td>
                </tr>
              </tbody>
            </table>
          </Box>
        </>
      )}
    </Stack>
  )
}
