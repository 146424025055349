import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  AppBar,
  Box,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
import SearchIcon from '@mui/icons-material/Search'
import MessageIcon from '@mui/icons-material/Message'
import { useAuth } from '../../../hooks/auth'
import { Permissions } from '../../../types/permissions'
import { useMensagem } from '../../../hooks/mensagem/useMensagem'
import { useUsuario } from '../../../hooks/usuario/useUsuario'
import logo from '../../../assets/images/logo-xsolution.jpeg'

import { useQueryClient } from 'react-query'

export function Header() {
  const { signOut } = useAuth()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const queryClient = useQueryClient()
  const [hasUnreadMessages, setHasUnreadMessages] = useState<boolean>(false)
  const { data: usuario } = useUsuario.FindByUsername()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMessages = () => {
    localStorage.setItem('@easydoc:onlyIncomplete', 'true')
  }

  const localData = localStorage.getItem('@easydoc:user')
  let idData: any
  if (localData) {
    idData = JSON.parse(localData)
  }

  const { data: recordsReceiver } = useMensagem.findByReceiver(
    idData.id_usuario
  )
  const { data: recordsAdmin } = useMensagem.findAll()

  useEffect(() => {
    if (usuario?.pessoa.setor.empresa?.nome === 'X-SOLUTION') {
      if (recordsAdmin) {
        const hasUnread = recordsAdmin.some(
          (msg) => !msg.lida && msg.id_remetente !== usuario.id_usuario
        )
        setHasUnreadMessages(hasUnread)
      }
    } else {
      if (recordsReceiver) {
        const hasUnread = recordsReceiver.some(
          (msg) => !msg.lida && msg.id_remetente !== usuario?.id_usuario
        )
        setHasUnreadMessages(hasUnread)
      }
    }
  }, [recordsReceiver, recordsAdmin])

  window.addEventListener('beforeunload', function () {
    if (usuario?.pessoa.setor.empresa?.nome === 'X-SOLUTION') {
      if (recordsAdmin) {
        const hasUnread = recordsAdmin.some((msg) => !msg.lida)
        setHasUnreadMessages(hasUnread)
      }
    } else {
      if (recordsReceiver) {
        const hasUnread = recordsReceiver.some((msg) => !msg.lida)
        setHasUnreadMessages(hasUnread)
      }
    }
  })

  const handleSignOut = () => {
    queryClient.removeQueries()
    signOut()
  }

  return (
    <AppBar
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: '#2835ad'
      }}
    >
      <Toolbar>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          minWidth="100%"
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <Box display="flex" alignItems="center">
              <Link to="/dashboard" style={{ textDecoration: 'none' }}>
                <Stack direction="row" alignItems="center" width="172px">
                  <img
                    src={logo}
                    alt="logo X-Solution"
                    width="40px"
                    height="40px"
                    style={{ borderRadius: '4px', marginRight: '8px' }}
                  />
                  <Typography variant="h6" fontWeight="300" color="#fff">
                    <strong>
                      <i>Easy</i>
                    </strong>
                    Doc
                  </Typography>
                </Stack>
              </Link>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '92px',
                  paddingRight: '16px',
                  backgroundColor: '#eef3f8',
                  borderRadius: '4px',
                  width: 600
                }}
              >
                <IconButton type="submit">
                  <SearchIcon />
                </IconButton>
                <InputBase placeholder="Pesquisar na plataforma" fullWidth />
              </Box>
            </Box>
          </Stack>

          <Box>
            <Link
              to="/gerenciar-solicitacoes/status-solicitacoes"
              hidden={
                !usuario?.permissoes.includes(Permissions.TROCA_MENSAGENS)
              }
            >
              <Tooltip title="Mensagens">
                <IconButton onClick={handleMessages} sx={{ width: '64px' }}>
                  <MessageIcon fontSize="large" sx={{ color: '#eee' }} />
                  {hasUnreadMessages && (
                    <Box
                      sx={{
                        width: '8px',
                        height: '8px',
                        bgcolor: 'red',
                        borderRadius: '8px',
                        position: 'absolute',
                        marginLeft: '35px',
                        marginBottom: '35px'
                      }}
                    ></Box>
                  )}
                </IconButton>
              </Tooltip>
            </Link>
            <Tooltip title="Opções">
              <IconButton onClick={handleClick} sx={{ width: '64px' }}>
                <SettingsIcon fontSize="large" sx={{ color: '#eee' }} />
              </IconButton>
            </Tooltip>
          </Box>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            {/* <MenuItem onClick={handleClose}>Perfil do usuário</MenuItem> */}
            {/* <MenuItem onClick={handleClose}>Configurações</MenuItem> */}
            <MenuItem
              component={Link}
              to={require('../../../assets/files/politica-de-privacidade.pdf')}
              target="_blank"
            >
              Política de Privacidade
            </MenuItem>
            <MenuItem
              component={Link}
              to={require('../../../assets/files/manual-do-usuario.pdf')}
              target="_blank"
            >
              Manual do Usuário
            </MenuItem>
            <MenuItem
              onClick={() =>
                window.open(
                  'https://helpdesk.xsolutionti.com.br',
                  '_blank',
                  'noopener,noreferrer'
                )
              }
            >
              Helpdesk
            </MenuItem>

            <MenuItem onClick={handleSignOut}>Logout</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  )
}
