import { Box } from '@mui/material'
import { ReactNode } from 'react'

interface DashboardWidgetSummaryHeaderRootProps {
  children: ReactNode
}

export function DashboardWidgetSummaryHeaderRoot({
  children
}: DashboardWidgetSummaryHeaderRootProps) {
  return (
    <Box
      sx={{
        margin: 0,
        padding: 0,
        paddingLeft: '1rem',
        paddingRight: '1rem',
        paddingTop: '1rem',
        borderBottom: '1px solid #ececec',
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {children}
    </Box>
  )
}
