import { format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import { IUsuariosPorEmpresaData } from '../../../../../services/relatorio/types'
import { groupByField, iterateGroupedData } from '../../../../../utils/report'
import { TemplateReport } from '../../../../../components/Report'
import { ApiEmpresa } from '../../../../../services/empresa'
import { ApiSetor } from '../../../../../services/setor'

type Props = {
  id_usuario: string
  data: IUsuariosPorEmpresaData[]
  filtros: {
    id_empresa?: string
    id_setor?: string
    data_inicio?: Date
    data_fim?: Date
  }
  mode?: 'open' | 'save'
}

export async function UsuariosPorEmpresaReport({
  id_usuario,
  data,
  filtros,
  mode
}: Props) {
  const filterReportText = async () => {
    const { id_empresa, data_fim, data_inicio, id_setor } = filtros
    const filtersReport = []
    filtersReport.push({ text: 'Empresa: ', style: ['filterText', 'bold'] })
    if (id_empresa) {
      const empresa = await ApiEmpresa.findOne(id_empresa)
      if (empresa) {
        filtersReport.push({
          text: empresa.razao_social,
          style: ['filterText']
        })
      }
    } else filtersReport.push({ text: '<todas> ', style: ['filterText'] })

    filtersReport.push({ text: '\n' })
    filtersReport.push({ text: 'Setor: ', style: ['filterText', 'bold'] })
    if (id_setor) {
      const setor = await ApiSetor.findOne(id_setor)
      if (setor) {
        filtersReport.push({
          text: setor.nome,
          style: ['filterText']
        })
      }
    } else filtersReport.push({ text: '<todos> ', style: ['filterText'] })
    filtersReport.push({ text: '\n' })
    filtersReport.push({ text: 'Período: ', style: ['filterText', 'bold'] })
    if (data_inicio && data_fim) {
      filtersReport.push({
        text: `${format(new Date(data_inicio), 'dd/MM/yyyy', {
          locale: ptBR
        })} até ${format(new Date(data_fim), 'dd/MM/yyyy', { locale: ptBR })}`,
        style: ['filterText']
      })
    } else filtersReport.push({ text: '<global> ', style: ['filterText'] })

    return filtersReport
  }

  const setoresPorEmpresaGroupTable = () => {
    const dadosAgrupados = groupByField(data, 'nome_empresa')
    const tables: Array<any> = []
    let rows: Array<any> = []
    if (dadosAgrupados) {
      iterateGroupedData(dadosAgrupados, (empresa, usuarios) => {
        rows = [
          [
            { text: 'Usuário', style: 'columnTitle' },
            { text: 'Nome', style: 'columnTitle' },
            { text: 'Setor', style: 'columnTitle' },
            { text: 'Cargo', style: 'columnTitle' },
            { text: 'E-mail', style: 'columnTitle' },
            { text: 'Cadastro ', style: 'columnTitle' },
            { text: 'Ativo', style: 'columnTitle' }
          ]
        ]

        usuarios.forEach((usuario) => {
          const row = [
            { text: usuario.nome_usuario, style: 'row' },
            { text: usuario.nome_pessoa, style: 'row' },
            { text: usuario.nome_setor, style: 'row' },
            { text: usuario.descricao_cargo, style: 'row' },
            { text: usuario.email, style: 'row' },
            {
              text: format(new Date(usuario.data_cadastro), 'dd/MM/yyyy'),
              style: 'row'
            },
            { text: usuario.ativo ? 'Sim' : 'Não', style: 'row' }
          ]

          rows.push(row as any)
        })

        tables.push([
          {
            text: `Empresa: ${empresa} \nRazão Social: ${usuarios[0].razao_social_empresa}`,
            style: 'subtitle'
          },
          {
            table: {
              widths: [80, '*', 100, 80, 125, 55, 40],
              headerRows: 1,
              body: rows
            }
          },
          {
            text: `Total de Usuários: ${usuarios.length}`,
            style: 'total'
          }
        ])
      })
      return tables
    } else {
      return [{ text: '\n' }]
    }
  }

  await TemplateReport({
    id_usuario,
    content: setoresPorEmpresaGroupTable(),
    filterText: await filterReportText(),
    reportTitle: 'Usuários por Empresa',
    pageOrientation: 'landscape',
    mode
  })
}
