import { IFinanceiroReportFilter } from '../../../components/Financeiro/FinanceiroReportForm/types'
import api from '../../api'
import { ILancamento } from '../lancamento/types'

const endpoint = '/financeiro/relatorios'

export class ServiceRelatorioFinanceiro {
  constructor(protected endpoint: string) {}

  public async getPrevisaoLancamento({
    data_fim,
    data_inicio,
    id_centro_custo,
    id_forma_pagamento,
    id_parceiro,
    id_parceiro_2,
    id_receita_despesa,
    id_usuario,
    tipo_relacionamento,
    order_by
  }: IFinanceiroReportFilter) {
    const params = {
      data_inicio,
      data_fim,
      id_centro_custo,
      id_forma_pagamento,
      id_receita_despesa,
      id_parceiro,
      id_parceiro_2,
      id_usuario,
      tipo_relacionamento,
      order_by
    }
    const { data } = await api.get<ILancamento[]>(
      `${this.endpoint}/previsao-lancamento`,
      {
        params
      }
    )

    return data
  }

  public async getBaixaLancamento({
    data_fim,
    data_inicio,
    id_centro_custo,
    id_forma_pagamento,
    id_receita_despesa,
    id_parceiro,
    id_usuario,
    tipo_relacionamento,
    id_parceiro_2,
    order_by
  }: IFinanceiroReportFilter) {
    const params = {
      data_inicio,
      data_fim,
      id_centro_custo,
      id_forma_pagamento,
      id_receita_despesa,
      id_parceiro,
      id_usuario,
      tipo_relacionamento,
      id_parceiro_2,
      order_by
    }
    const { data } = await api.get<ILancamento[]>(
      `${this.endpoint}/baixa-lancamento`,
      {
        params
      }
    )

    return data
  }
}

export const ApiRelatorioFinanceiro = new ServiceRelatorioFinanceiro(endpoint)
