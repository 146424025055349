import { Box, Button, Stack } from '@mui/material'
import SaveIcon from '@mui/icons-material/CheckCircleOutlined'
import CancelIcon from '@mui/icons-material/DoDisturbAltOutlined'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import msg from '../../../utils/validationMessages'
import useToast from '../../../hooks/toast/useToast'
import { FormMode } from '../../../types/formMode'
import { Input } from '../../../components/Form/Input'
import { ICentroCusto } from '../../../services/financeiro/centroCusto/types'
import { useCentroCusto } from '../../../hooks/financeiro/centroCusto/useCentroCusto'
import { Select } from '../../../components/Form/Select'

const schema = yup
  .object({
    descricao: yup.string().required(msg.REQUIRED).min(4, msg.MIN(4)),
    id_centro_custo_pai: yup.string()
  })
  .required()

interface IForm {
  data: any
  formMode: FormMode
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

export function Form({ data, formMode, setFormMode }: IForm) {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ICentroCusto>({
    resolver: yupResolver(schema)
  })

  const { data: listaCentroCustos } = useCentroCusto.ListAll()

  const { Toast } = useToast()
  const { mutateAsync: create } = useCentroCusto.Create()
  const { mutateAsync: update } = useCentroCusto.Update()

  const onSubmit = async (formData: ICentroCusto) => {
    formMode === 'CREATE'
      ? await Toast(create(formData))
      : await Toast(
          update({
            id_centro_custo: data.id_centro_custo,
            ...formData
          }),
          'UPDATED'
        )

    setFormMode('LIST')
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} mt={4}>
      <Stack gap={2}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2
          }}
        >
          <Input
            label="Descrição*"
            {...register('descricao')}
            defaultValue={data?.descricao}
            message={errors.descricao?.message}
          />

          <Select
            label="Centro de Custo Superior"
            placeholder="Selecione o Centro de Custo Superior"
            data={listaCentroCustos}
            fields={['id_centro_custo', 'descricao']}
            {...register('id_centro_custo_pai')}
            message={errors.id_centro_custo_pai?.message}
          />
        </Box>
      </Stack>

      <Stack direction="row" gap={2} mt={3}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<CancelIcon />}
          onClick={() => setFormMode('LIST')}
        >
          Cancelar
        </Button>

        <Button
          type="submit"
          variant="contained"
          startIcon={<SaveIcon />}
          sx={{ width: '110px' }}
        >
          Salvar
        </Button>
      </Stack>
    </Box>
  )
}
