import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import SaveIcon from '@mui/icons-material/CheckCircleOutlined'
import CancelIcon from '@mui/icons-material/DoDisturbAltOutlined'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import msg from '../../../utils/validationMessages'
import useToast from '../../../hooks/toast/useToast'
import { FormMode } from '../../../types/formMode'
import { Input } from '../../Form/Input'
import { ILancamento } from '../../../services/financeiro/lancamento/types'
import { useLancamento } from '../../../hooks/financeiro/lancamento/useLancamento'
import { useEffect } from 'react'
import { useCentroCusto } from '../../../hooks/financeiro/centroCusto/useCentroCusto'
import { useParceiro } from '../../../hooks/financeiro/parceiro/useParceiro'
import { useFormaPagamento } from '../../../hooks/financeiro/formaPagamento/useFormaPagamento'
import { Select } from '../../Form/Select'
import { removeNumericMask } from '../../../utils/formataNumeric'
import { useReceitaDespesa } from '../../../hooks/financeiro/receitaDespesa/useReceitaDespesa'
import { ComponentGroup } from '../../ComponentGroup'
import { formatarComoMoeda } from '../../../utils/formataStrings'
import { formataSomenteData } from '../../../utils/formataData'

const schema = yup
  .object({
    tipo_lancamento: yup.string().required(msg.REQUIRED),
    id_forma_pagamento: yup.string(),
    id_centro_custo: yup.string(),
    id_receita_despesa: yup.string(),
    id_parceiro: yup.string().required(msg.REQUIRED),
    dt_emissao: yup
      .date()
      .transform((value, originalValue) => {
        return originalValue === '' ? null : value
      })
      .typeError('A data de emissão deve ser uma data válida')
      .required(msg.REQUIRED),
    dt_vencimento: yup
      .date()
      .transform((value, originalValue) => {
        return originalValue === '' ? null : value
      })
      .typeError('A data de vencimento deve ser uma data válida')
      .required(msg.REQUIRED),
    valor: yup
      .number()
      .transform((_value, originalValue) => removeNumericMask(originalValue))
      .required(msg.REQUIRED)
      .typeError('O valor do lançamento deve ser preenchido corretamente'),
    num_documento: yup.string().required(msg.REQUIRED),
    historico: yup.string(),
    juros: yup
      .number()
      .nullable()
      .transform((_value, originalValue) => removeNumericMask(originalValue)),
    mora: yup
      .number()
      .nullable()
      .transform((_value, originalValue) => removeNumericMask(originalValue)),
    chave_pix: yup.string(),
    codigo_barras: yup.string()
  })
  .required()

interface IForm {
  tipo_lancamento: string
  data: ILancamento
  formMode: FormMode
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

export function LancamentoForm({
  tipo_lancamento,
  data,
  formMode,
  setFormMode
}: IForm) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm<ILancamento>({
    resolver: yupResolver(schema)
  })

  const isContasAReceber = tipo_lancamento === 'R'

  const { Toast } = useToast()
  const { mutateAsync: create } = useLancamento.Create()
  const { mutateAsync: update } = useLancamento.Update()

  const { data: listaCentroCustos } = useCentroCusto.ListAll()
  const { data: listaFormaPagamento } = useFormaPagamento.ListAll()
  const { data: listaParceiros } = isContasAReceber
    ? useParceiro.ListAllCliente()
    : useParceiro.ListAllFornecedor()

  const { data: listReceitasDespesas } = useReceitaDespesa.ListAll(
    tipo_lancamento == 'R' ? 'R' : 'D'
  )

  useEffect(() => {
    // Atualize o estado no início da renderização
    setValue('tipo_lancamento', tipo_lancamento)
  }, [])

  const onSubmit = async (formData: ILancamento) => {
    formMode === 'CREATE'
      ? await Toast(create(formData))
      : await Toast(
          update({
            id_lancamento: data.id_lancamento,
            ...formData
          }),
          'UPDATED'
        )

    setFormMode('LIST')
  }

  const dt_vencimento = (data?.dt_vencimento ?? '')
    .toLocaleString()
    .slice(0, 10)
  const dt_emissao = (data?.dt_emissao ?? '').toLocaleString().slice(0, 10)
  const disabledFields = formMode === 'VIEW'
  const baixas = data?.baixaLancamento
    ? data.baixaLancamento.filter((baixa) => baixa.ativo === true)
    : []

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} mt={4}>
      <Stack gap={2}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2
          }}
        >
          <Input
            label="Número Documento*"
            {...register('num_documento')}
            defaultValue={data?.num_documento}
            message={errors.num_documento?.message}
            disabled={disabledFields}
          />

          <Input
            type="date"
            label="Emissão*"
            {...register('dt_emissao')}
            InputLabelProps={{
              shrink: true
            }}
            defaultValue={dt_emissao}
            message={errors.dt_emissao?.message}
            disabled={disabledFields}
          />

          <Input
            type="date"
            label="Vencimento*"
            {...register('dt_vencimento')}
            InputLabelProps={{
              shrink: true
            }}
            defaultValue={dt_vencimento}
            message={errors.dt_vencimento?.message}
            disabled={disabledFields}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2
          }}
        >
          <Select
            label={`${isContasAReceber ? 'Cliente' : 'Fornecedor'}*`}
            placeholder={`Selecione o ${
              isContasAReceber ? 'Cliente' : 'Fornecedor'
            }*`}
            data={listaParceiros}
            fields={['id_parceiro', 'nome']}
            {...register('id_parceiro')}
            defaultValue={data?.id_parceiro}
            message={errors.id_parceiro?.message}
            disabled={disabledFields}
          />

          <Select
            label="Forma de Pagamento"
            placeholder="Selecione a Forma de Pagamento"
            data={listaFormaPagamento}
            fields={['id_forma_pagamento', 'descricao']}
            {...register('id_forma_pagamento')}
            defaultValue={data?.id_forma_pagamento}
            message={errors.id_forma_pagamento?.message}
            disabled={disabledFields}
          />

          <Select
            label="Centro de Custo"
            placeholder="Selecione o Centro de Custo"
            data={listaCentroCustos}
            fields={['id_centro_custo', 'descricao']}
            {...register('id_centro_custo')}
            defaultValue={data?.id_centro_custo}
            message={errors.id_centro_custo?.message}
            disabled={disabledFields}
          />

          <Select
            label={`${tipo_lancamento === 'R' ? 'Receita' : 'Despesa'}`}
            placeholder={`Selecione a ${
              tipo_lancamento === 'R' ? 'Receita' : 'Despesa'
            }`}
            data={listReceitasDespesas}
            fields={['id_receita_despesa', 'descricao']}
            {...register('id_receita_despesa')}
            defaultValue={data?.id_receita_despesa}
            message={errors.id_receita_despesa?.message}
            disabled={disabledFields}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2
          }}
        >
          <Input
            label="Valor R$*"
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*[,]?[0-9]{0,2}',
              inputProps: { min: 0, step: '0.01' }
            }}
            {...register('valor')}
            defaultValue={data?.valor}
            message={errors.valor?.message}
            disabled={disabledFields}
          />

          <Input
            label="Juros %"
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*[,]?[0-9]{0,2}',
              inputProps: { min: 0, step: '0.01' }
            }}
            {...register('juros')}
            defaultValue={data?.juros}
            message={errors.juros?.message}
            disabled={disabledFields}
          />

          <Input
            label="Mora R$"
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*[,]?[0-9]{0,2}',
              inputProps: { min: 0, step: '0.01' }
            }}
            {...register('mora')}
            defaultValue={data?.mora}
            message={errors.mora?.message}
            disabled={disabledFields}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2
          }}
        >
          <Input
            label="Código de Barras"
            {...register('codigo_barras')}
            defaultValue={data?.codigo_barras}
            message={errors.codigo_barras?.message}
            disabled={disabledFields}
          />
          <Input
            label="Chave Pix"
            {...register('chave_pix')}
            defaultValue={data?.chave_pix}
            message={errors.chave_pix?.message}
            disabled={disabledFields}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2
          }}
        >
          <Input
            label="Histórico"
            {...register('historico')}
            defaultValue={data?.historico}
            message={errors.historico?.message}
            multiline={true}
            minRows={3}
            disabled={disabledFields}
          />
        </Box>
      </Stack>

      <Stack gap={2} mt={5}>
        {(baixas.length ?? 0) > 0 && (
          <ComponentGroup title="Baixas Efetuadas">
            <TableContainer sx={{ maxHeight: 180 }}>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                        backgroundColor: '#f2f2f2'
                      }}
                    >
                      Tipo
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                        backgroundColor: '#f2f2f2'
                      }}
                    >
                      Valor
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                        backgroundColor: '#f2f2f2'
                      }}
                    >
                      Desconto
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                        backgroundColor: '#f2f2f2'
                      }}
                    >
                      Juros
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                        backgroundColor: '#f2f2f2'
                      }}
                    >
                      Data Baixa
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {baixas.map((baixaLancamento) => {
                    return (
                      <TableRow key={baixaLancamento.id_baixa_lancamento}>
                        <TableCell>{baixaLancamento.tipo_pagamento}</TableCell>
                        <TableCell>
                          {formatarComoMoeda(baixaLancamento.valor)}
                        </TableCell>
                        <TableCell>
                          {formatarComoMoeda(baixaLancamento.desconto ?? 0)}
                        </TableCell>
                        <TableCell>
                          {formatarComoMoeda(
                            baixaLancamento.juros_mora_pagos ?? 0
                          )}
                        </TableCell>
                        <TableCell>
                          {formataSomenteData(baixaLancamento.dt_baixa)}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </ComponentGroup>
        )}
      </Stack>

      <Stack direction="row" gap={2} mt={3}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<CancelIcon />}
          onClick={() => setFormMode('LIST')}
        >
          Cancelar
        </Button>

        {formMode !== 'VIEW' && (
          <Button
            type="submit"
            variant="contained"
            startIcon={<SaveIcon />}
            sx={{ width: '110px' }}
          >
            Salvar
          </Button>
        )}
      </Stack>
    </Box>
  )
}
