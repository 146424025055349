import { ApiRelatorio } from '../../../../services/relatorio'
import { SetoresPorEmpresaReport } from './report/SetoresPorEmpresaReport'
import {
  IHandleReportSubmitProps,
  ReportForm
} from '../../../../components/ReportForm'

export function SetoresPorEmpresaRelatorio() {
  async function handleSubmitForm({
    mode,
    format,
    id_usuario,
    filtros
  }: IHandleReportSubmitProps) {
    const { data_fim, data_inicio, id_empresa } = filtros

    const setoresPorEmpresaData = await ApiRelatorio.getSectorByCompany({
      id_empresa,
      data_fim,
      data_inicio
    })

    if (format === 'pdf') {
      SetoresPorEmpresaReport({
        data: setoresPorEmpresaData,
        mode,
        id_usuario: id_usuario,
        filtros: {
          id_empresa,
          data_fim,
          data_inicio
        }
      })
    }

    return setoresPorEmpresaData
  }
  return (
    <ReportForm
      title="Setores por Empresa"
      handleReportSubmit={handleSubmitForm}
      mostrarPeriodo={true}
      isEmpresaObrigatorio={false}
      mostrarSetor={false}
    />
  )
}
