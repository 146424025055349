import { Typography } from '@mui/material'

interface DashboadWidgetSummaryHeaderToolbarTypeProps {
  type: string
}

export function DashboadWidgetSummaryHeaderToolbarType({
  type
}: DashboadWidgetSummaryHeaderToolbarTypeProps) {
  return (
    <Typography
      sx={{
        fontWeight: 'bold'
      }}
    >
      {type}
    </Typography>
  )
}
