import { ReactNode } from 'react'

interface DashboardWidgetListHeaderActionsProps {
  children: ReactNode
}

export function DashboardWidgetListHeaderActions({
  children
}: DashboardWidgetListHeaderActionsProps) {
  return <>{children}</>
}
