import api from '../api'
import { DefaultApi } from '../../utils/defaultApi'
import { IContrato } from './types'

const endpoint = '/contrato'
const resourceId = 'id_contrato'

class ServiceContrato extends DefaultApi<IContrato> {
  activate = async (id_contrato: string) => {
    const { data } = await api.put(`${endpoint}/activate/${id_contrato}`)
    return data
  }

  listAllWithoutUserFilter = async (): Promise<IContrato[]> => {
    const { data } = await api.get(`${endpoint}/lista-semfiltro-usuario/`)
    return data
  }

  public findByCode = async (code: string): Promise<IContrato | undefined> => {
    if (code.trim() === '') {
      return undefined
    }
    const { data } = await api.get<IContrato>(`${this.endpoint}/codigo/${code}`)
    return data
  }
}

const apiContrato = new ServiceContrato(endpoint, resourceId)

export const ApiContrato = {
  ...apiContrato
}
