import {
  Box,
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@mui/material'
import { useEffect, useState } from 'react'

import { IRequisicaoNaoAtendida } from '../../../services/requisicao/types'
import { IItemLote, ILote } from '../../../services/lote/types'
import { Input } from '../../../components/Form/Input'
import { formata } from '../../../utils/formataData'
import { BoxTitle } from '../../../components/BoxTitle'
import { ComponentGroup } from '../../../components/ComponentGroup'
import { corTextoLinha, corTituloColunaTabela } from '../cores'
import { corFundoLinha } from '../../../pages/Dashboard/Widgets/lib/consts'

interface DashboardWidgetFormRequisicaoNaoAtendidaProps {
  data: IRequisicaoNaoAtendida
  setData: (formData: ILote) => void
}

export function DashboardWidgetFormRequisicaoNaoAtendida({
  data,
  setData
}: DashboardWidgetFormRequisicaoNaoAtendidaProps) {
  const [lote, setLote] = useState<IItemLote[]>([])
  const [previsao, setPrevisao] = useState<Date>(
    data.previsao_inicio && new Date(data.previsao_inicio) > new Date()
      ? new Date(data.previsao_inicio)
      : new Date()
  )
  const [observacao, setObservacao] = useState<string>('')

  const getMarcados = (): number => {
    return lote.length === data.itens_requisicao.length
      ? 2
      : lote.length === 0
      ? 0
      : 1
  }

  const marcarDescamarcarTodos = () => {
    const lista = [...data.itens_requisicao]
    if (getMarcados() < 2) {
      const newLote = lista.map((item) => ({
        id_movimentacao: item.movimentacoes[0].id_movimentacao ?? ''
      }))
      setLote(newLote)
      return
    }
    setLote([])
  }

  const toggleSelectItem = (item: string) => {
    let newLote: Array<IItemLote> = []
    if (lote.some((itemLote) => itemLote.id_movimentacao === item)) {
      newLote = lote.filter((itemLote) => itemLote.id_movimentacao !== item)
    } else {
      newLote = [...lote, { id_movimentacao: item }]
    }
    setLote(newLote)
  }

  const handlePrevisaoChange = (data: string) => {
    setPrevisao(new Date(data))
  }

  const handleObservacaoChange = (observacaoValue: string) => {
    setObservacao(observacaoValue)
  }

  useEffect(() => {
    const newLote = {
      ...data,
      previsao,
      id_requisicao: data.id_requisicao,
      observacao,
      itens_lote: [...lote]
    }
    setData(newLote)
  }, [previsao, observacao, lote])

  const FormatacaoTituloColuna = {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '24px',
    color: corTituloColunaTabela,
    borderBottom: 0,
    paddingBottom: 0,
    paddingTop: 0,
    margin: 0,
    textAlign: 'left'
  }

  const CellStyle = {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    color: corTextoLinha,
    paddingBottom: 0,
    paddingTop: 0,
    borderBottom: 0,
    height: '40px',
    textAlign: 'left'
  }

  return (
    <Box mt={4} margin={'1rem'}>
      <BoxTitle title={'Requisição'}>
        <Input
          label={'Requisitante'}
          value={
            `${data.usuario.pessoa.nome} ` +
            `(${data.usuario.pessoa.setor.nome} - ` +
            `${data.usuario.pessoa.setor.empresa?.nome})`
          }
          inputProps={{ disabled: 'disabled' }}
        />
        <Input
          label={'Observações'}
          value={`${data.observacoes}`}
          inputProps={{ disabled: 'disabled' }}
        />
        <Box gap={2} display={'flex'} flexDirection={'row'}>
          <Input
            label={'Disponibilizado para execução em'}
            value={formata(
              new Date(data.itens_requisicao[0].movimentacoes[0].data_inicio),
              "dd/MM/yyyy 'às' HH:mm"
            )}
            inputProps={{ disabled: 'disabled' }}
          />
          <TextField
            label="Executar em *"
            type="date"
            value={
              (previsao !== new Date()
                ? previsao
                : new Date(data.previsao_inicio)
              )
                .toISOString()
                .split('T')[0]
            }
            size="small"
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            onChange={(event) => {
              handlePrevisaoChange(event.target.value)
            }}
          />
        </Box>
        <TextField
          label="Observações"
          value={observacao ?? ''}
          size="small"
          fullWidth
          onChange={(event) => {
            handleObservacaoChange(event.target.value)
          }}
        />
        <ComponentGroup title="Itens Disponíveis">
          <TableContainer sx={{ maxHeight: '450px', overflow: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      ...FormatacaoTituloColuna,
                      width: '80px'
                    }}
                  >
                    Tipo
                  </TableCell>
                  <TableCell
                    sx={{
                      ...FormatacaoTituloColuna,
                      width: '250px'
                    }}
                  >
                    Descrição/Identificação
                  </TableCell>
                  <TableCell
                    sx={{
                      ...FormatacaoTituloColuna
                    }}
                  >
                    Setor
                  </TableCell>
                  <TableCell
                    sx={{
                      ...FormatacaoTituloColuna
                    }}
                  >
                    Endereço
                  </TableCell>
                  <TableCell sx={{ ...FormatacaoTituloColuna }}>
                    Observações
                  </TableCell>
                  <TableCell sx={{ ...FormatacaoTituloColuna, width: '36px' }}>
                    <FormControlLabel
                      sx={{
                        fontWeight: 500,
                        fontSize: '12px',
                        color: '#969696'
                      }}
                      label="Selecionar"
                      control={
                        <Checkbox
                          onChange={() => {
                            marcarDescamarcarTodos()
                          }}
                          checked={getMarcados() === 2}
                          indeterminate={getMarcados() === 1}
                        />
                      }
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.itens_requisicao.map((itemRequisicao, idx) => (
                  <TableRow
                    key={itemRequisicao.id_item_requisicao}
                    sx={{
                      backgroundColor: idx % 2 === 0 ? corFundoLinha : ''
                    }}
                  >
                    <TableCell sx={{ ...CellStyle }}>
                      {itemRequisicao.id_caixa ? 'Caixa' : 'Documento'}
                    </TableCell>
                    <TableCell sx={{ ...CellStyle }}>
                      {itemRequisicao.id_caixa
                        ? itemRequisicao?.caixa?.identificacao
                        : itemRequisicao?.documento?.descricao}
                    </TableCell>
                    <TableCell sx={{ ...CellStyle }}>
                      {itemRequisicao.id_caixa
                        ? itemRequisicao?.caixa?.setor?.nome
                        : itemRequisicao?.id_documento
                        ? itemRequisicao?.documento?.setor?.nome
                        : ''}
                    </TableCell>
                    <TableCell sx={{ ...CellStyle }}>
                      {itemRequisicao.endereco}
                    </TableCell>
                    <TableCell sx={{ ...CellStyle }}>
                      {itemRequisicao.observacoes}
                    </TableCell>
                    <TableCell sx={{ ...CellStyle, paddingLeft: '0.3rem' }}>
                      <Checkbox
                        checked={lote.some(
                          (item) =>
                            item.id_movimentacao ===
                            itemRequisicao.movimentacoes[0].id_movimentacao
                        )}
                        onClick={() =>
                          toggleSelectItem(
                            itemRequisicao.movimentacoes[0].id_movimentacao!
                          )
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ComponentGroup>
      </BoxTitle>
    </Box>
  )
}
