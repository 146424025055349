import {
  IArvoreDocumentalSetor,
  IArvoreDocumentalTipoDocumento
} from '../services/arvore-documental/types'

export const getTextoArvoreDocumentalTipoDocumento = (
  tipoDocumento: IArvoreDocumentalTipoDocumento
): string => {
  return (
    tipoDocumento.tipo_documento.codigo +
    '(' +
    tipoDocumento.tipo_documento.descricao +
    ')'
  )
}

export const getTextoArvoreDocumentalSetor = (
  setor: IArvoreDocumentalSetor
): string => {
  const complemento = setor.codigo ? `${setor.codigo} - ` : ''
  return complemento + setor.setor.nome
}
