import { DefaultApi } from '../../../utils/defaultApi'
import { IFormaPagamento } from './types'

const endpoint = '/financeiro/formas-pagamento'
const resourceId = 'id_forma_pagamento'

export const ApiFormaPagamento = new DefaultApi<IFormaPagamento>(
  endpoint,
  resourceId
)
