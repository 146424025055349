import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ApiCaixa } from '../../services/caixa'
import {
  AdvancedFindAllParams,
  AdvancedFindAllProps
} from '../../services/caixa/types'

const QUERY_KEY = 'qkCaixa'

const FindAll = (page: number, searchParam: AdvancedFindAllParams) => {
  return useQuery([QUERY_KEY, [searchParam, page]], () =>
    ApiCaixa.advancedFindAll({ ...searchParam, page })
  )
}

const FindAllByIdContrato = (
  id: string,
  page: number,
  q = '',
  r = '',
  limit?: number
) => {
  return useQuery([QUERY_KEY, id, r, q, limit, page], () =>
    ApiCaixa.findAllByIdContrato(id, page, q, r, limit)
  )
}

const ListAllByIdContrato = (idContrato: string) => {
  return useQuery([QUERY_KEY, idContrato], () =>
    ApiCaixa.listAllByIdContrato(idContrato)
  )
}

const ListAllId = (idContratoSelecionado: string) => {
  return useQuery([QUERY_KEY, idContratoSelecionado], () =>
    ApiCaixa.listAllId(idContratoSelecionado)
  )
}

// const ListAllByRequisitosIdContrato = (
//   requisitos: string,
//   idContrato: string
// ) => {
//   return useQuery([QUERY_KEY, idContrato, requisitos], () =>
//     ApiCaixa.listAllByRequisitosIdContrato(requisitos, idContrato)
//   )
// }

const Create = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiCaixa.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Import = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiCaixa.import, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Update = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiCaixa.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const AllocateBox = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiCaixa.allocateBox, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Remove = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiCaixa.remove, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const AdvancedFindAll = (advancedFindAllProps: AdvancedFindAllProps) => {
  return useQuery([QUERY_KEY, advancedFindAllProps], () =>
    ApiCaixa.advancedFindAll(advancedFindAllProps)
  )
}

const FindByEndereco = (endereco: string) => {
  return useQuery([QUERY_KEY, endereco], () =>
    ApiCaixa.findByEndereco(endereco)
  )
}

const FindByCodigo = (codigo: string) => {
  return useQuery([QUERY_KEY, codigo], () => ApiCaixa.findByCodigo(codigo))
}

const ListByCaixaContainer = (id: string) => {
  return useQuery([QUERY_KEY, id], () => ApiCaixa.listAllByIdCaixaContainer(id))
}

const Homologate = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiCaixa.homologate, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const HomologateAdvanced = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiCaixa.homologateAdvanced, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

export const useCaixa = {
  AdvancedFindAll,
  AllocateBox,
  Create,
  FindAll,
  //FindAllOrder,
  FindAllByIdContrato,
  FindByEndereco,
  Import,
  ListAllId,
  ListAllByIdContrato,
  ListByCaixaContainer,
  Remove,
  Update,
  Homologate,
  HomologateAdvanced,
  FindByCodigo
}
