/**
 * Calcula o mód11 de uma string.
 * Esta função é usada para validar tanto CPF, quanto CNPJ
 * @param clearValue String contendo apenas caracteres numéricos
 * @param limite Base de cálculo do mod11
 * @returns Dígito verificador da string
 */
const mod11 = (clearValue: string, limite: number): number => {
  const valor = String(clearValue).replace(/\D/g, '')
  let sum = 0
  let mult = 2

  for (let i = valor.length - 1; i >= 0; i--) {
    sum += mult * +valor[i]
    if (++mult > limite) {
      mult = 2
    }
  }
  const dv = ((sum * 10) % 11) % 10
  return dv
}

/**
 * Avalia se todos os caracteres de uma string são iguais
 * @param value String a ser avaliada
 * @returns Flag indicando se todos os caracteres são repetidos
 */
const isRepeated = (value: string): boolean => {
  return value.replace(new RegExp(value[0], 'g'), '').trim().length === 0
}

/**
 * Remove todos os caracteres não numéricos de uma string
 * @param value String a ser "limpa"
 * @returns String "limpa"
 */
const clearValue = (value: string): string => {
  return String(value).replace(/[^\d]+/g, '')
}

/**
 * Valida se uma string é um CNPJ válido
 * @param value String a ser avaliada
 * @returns Flag indicando se é um CNPJ válido
 */
const isCNPJ = (value: string): boolean => {
  //Remoção de caracteres inúteis a validação
  const newValue = clearValue(value)

  //Verificação se a string tem 14 caracteres e se são todos repetidos
  if (newValue.length !== 14 || isRepeated(newValue)) return false

  //Cálculo dos dígitos verificadores (INÍCIO)
  const valWithoutDvs = newValue.substring(0, newValue.length - 2)
  const dv1 = mod11(valWithoutDvs, 9)
  const dv2 = mod11(valWithoutDvs + dv1, 9)
  //Cálculo dos dígitos verificadores (FIM)

  return valWithoutDvs + dv1 + dv2 === newValue
}

/**
 * Valida se uma string é um CPF válido
 * @param value String a ser avaliada
 * @returns Flag indicando se é um CNPJ válido
 */
const isCPF = (value: string): boolean => {
  //Remoção de caracteres inúteis a validação
  const newValue = clearValue(value)

  //Verificação se a strign tem 11 caracteres e se eles não são repetidos
  if (newValue.length !== 11 || isRepeated(newValue)) {
    return false
  }

  //Cálculo dos dígitos verificadores(INÍCIO)
  const valWithoutDvs = newValue.substring(0, newValue.length - 2)
  const dv1 = mod11(valWithoutDvs, 12)
  const dv2 = mod11(valWithoutDvs + dv1, 12)
  //Cálculo dos dígitos verificadores(FIM)

  return valWithoutDvs + dv1 + dv2 === newValue
}

/**
 * Valida se uma string é um CPF válido
 * @param value String a ser avaliada
 * @param mandatoryDDD Flag indicando se a existência do DDD é obrigatória
 * @returns Flag indicando se é um CNPJ válido
 */
const isPhone = (value: string, mandatoryDDD = true): boolean => {
  const newValue = clearValue(value)

  //Verificação se todos os caracteres são iguais
  if (isRepeated(newValue)) {
    return false
  }

  if (!mandatoryDDD) {
    if (!(newValue.length >= 8 && newValue.length <= 11)) {
      return false
    }
  } else {
    if (newValue.length !== 10 && newValue.length !== 11) {
      return false
    }
    //Nenhum dos dígitos do DDD pode ser 0
    if (newValue.length > 9 && (newValue[0] === '0' || newValue[1] === '0'))
      return false
  }

  //Verificação do primeiro dígito do prefixo (telefone fixo)
  if (
    (newValue.length === 8 && [2, 3, 4, 5, 7].indexOf(+newValue[0]) === -1) ||
    (newValue.length === 10 && [2, 3, 4, 5, 7].indexOf(+newValue[2]) === -1)
  ) {
    return false
  }

  //Verificação do primeiro dígito do prefixo (telefone móvel)
  if (
    (newValue.length === 9 && newValue[0] !== '9') ||
    (newValue.length === 11 && newValue[2] !== '9')
  )
    return false

  return true
}

function validateFloat(str: string): boolean {
  if (typeof str !== 'string') return false
  const floatRegex = /[-]?([0-9]+[,]?)([0-9]+)?/
  const matchString = (str.match(floatRegex) ?? [''])[0]
  return matchString === str
}

export const validation = {
  cnpj: isCNPJ,
  cpf: isCPF,
  phone: isPhone,
  clearValue,
  validateFloat
}

export function isValidDate(d: Date) {
  return d instanceof Date && isFinite(Number(d))
}
