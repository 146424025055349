import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Stack } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { Select } from '../../Form/Select'
import { FormTitle } from '../../Form/FormTitle'
import { useState } from 'react'
import { CSVDownload } from 'react-csv'
import { ReportViewModeFormat } from '../../../services/relatorio/types'
import { useAuth } from '../../../hooks/auth'
import { Input } from '../../Form/Input'
import { isValidDate } from '../../../utils/validations'
import msg from '../../../utils/validationMessages'
import { useUsuario } from '../../../hooks/usuario/useUsuario'
import { ReportButtons } from '../../ReportForm/ReportButtons'
import { IFinanceiroReportFilter } from './types'
import { useCentroCusto } from '../../../hooks/financeiro/centroCusto/useCentroCusto'
import { useFormaPagamento } from '../../../hooks/financeiro/formaPagamento/useFormaPagamento'
import { useParceiro } from '../../../hooks/financeiro/parceiro/useParceiro'
import { useReceitaDespesa } from '../../../hooks/financeiro/receitaDespesa/useReceitaDespesa'

type ReportFormat = 'pdf' | 'csv'

interface FinanceiroReportProps {
  temParceiro?: boolean
  temCentroCusto?: boolean
  temFormaPagamento?: boolean
  temReceitaDespesa?: boolean
  isParceiroObrigatorio?: boolean
  tipo_relacionamento?: string
  tipo_lancamento?: string
}
function generateReportSchema({
  temParceiro = true,
  temCentroCusto = true,
  temFormaPagamento = true,
  temReceitaDespesa = true,
  isParceiroObrigatorio = false
}: FinanceiroReportProps) {
  const parceiroSchemaItens = temParceiro
    ? {
        id_parceiro: isParceiroObrigatorio
          ? yup.string().required(msg.REQUIRED)
          : yup.string()
      }
    : undefined

  const centroCustoSchemaItens = temCentroCusto
    ? { id_centro_custo: yup.string() }
    : undefined
  const formaPagamentoSchemaItens = temFormaPagamento
    ? { id_forma_pagamento: yup.string() }
    : undefined
  const receitaDespesaSchemaItens = temReceitaDespesa
    ? { id_receita_despesa: yup.string() }
    : undefined
  const schema = yup
    .object({
      ...parceiroSchemaItens,
      ...centroCustoSchemaItens,
      ...formaPagamentoSchemaItens,
      ...receitaDespesaSchemaItens,
      id_parceiro_2: yup.string(),
      order_by: yup.string(),
      data_inicio: yup.date().transform((value) => {
        return isValidDate(value) ? value : undefined
      }),
      data_fim: yup
        .date()
        .transform((value) => {
          return isValidDate(value) ? value : undefined
        })
        .when(
          'data_inicio',
          (inicio, yup) => inicio && yup.min(inicio, msg.DATEINTERVAL)
        )
    })
    .required()

  return schema
}

export interface IHandleFinanceiroReportSubmitProps {
  format: ReportFormat
  mode: 'open' | 'save'
  id_usuario: string
  filtros: IFinanceiroReportFilter
}

export interface IReportFormProps {
  title: string
  handleReportSubmit: (reportProps: IHandleFinanceiroReportSubmitProps) => any
  temParceiro?: boolean
  temParceiro2?: boolean
  temCentroCusto?: boolean
  temFormaPagamento?: boolean
  temUsuario?: boolean
  temPeriodo?: boolean
  isOrdernarPrevisao?: boolean
  isParceiroObrigatorio?: boolean
  tipo_relacionamento?: string
}

const reportOrderOptionsBaixas = [
  { value: 'dt_pagamento', label: 'Pagamento' },
  { value: 'dt_vencimento', label: 'Vencimento' }
]

const reportOrderOptionsPrevisao = [
  { value: 'dt_emissao', label: 'Emissão' },
  { value: 'dt_vencimento', label: 'Vencimento' }
]
export function FinanceiroReportForm({
  title,
  handleReportSubmit,
  temParceiro = true,
  temParceiro2 = false,
  temCentroCusto = true,
  temFormaPagamento = true,
  isParceiroObrigatorio = false,
  temPeriodo = true,
  temUsuario = false,
  tipo_relacionamento = '',
  isOrdernarPrevisao = true
}: IReportFormProps) {
  const [format, setFormat] = useState<'pdf' | 'csv'>('pdf')
  const [mode, setMode] = useState<'open' | 'save'>('open')
  const [isSubmitting, setIsSubmmiting] = useState(false)
  const [csvData, setCSVData] = useState<IFinanceiroReportFilter[]>([])

  const isCliente = tipo_relacionamento === 'C'
  const tipoReceitaDespesa = temParceiro2 ? '' : isCliente ? 'R' : 'D'

  const { data: listaUsuarios } = useUsuario.ListAllXSolution()
  const { data: centrosCusto } = useCentroCusto.ListAll()
  const { data: formasPagamento } = useFormaPagamento.ListAll()
  const { data: listaReceitaDespesa } =
    useReceitaDespesa.ListAll(tipoReceitaDespesa)
  const { data: parceiros } = temParceiro
    ? isCliente || temParceiro2
      ? useParceiro.ListAllCliente()
      : useParceiro.ListAllFornecedor()
    : { data: [] }
  const { data: fornecedores } = temParceiro2
    ? useParceiro.ListAllFornecedor()
    : { data: [] }

  function handleReportFormatAndMode({ format, mode }: ReportViewModeFormat) {
    setFormat(format)
    setMode(mode)
  }

  const formSchema = generateReportSchema({
    temCentroCusto,
    temFormaPagamento,
    temParceiro,
    isParceiroObrigatorio
  })

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm<IFinanceiroReportFilter>({
    resolver: yupResolver(formSchema)
  })

  const { user } = useAuth()

  const onSubmit = async ({
    data_fim,
    data_inicio,
    id_centro_custo,
    id_receita_despesa,
    id_forma_pagamento,
    id_parceiro,
    id_parceiro_2,
    order_by = 'dt_vencimento',
    id_usuario
  }: IFinanceiroReportFilter) => {
    try {
      setCSVData([])
      setIsSubmmiting(true)

      const reportData = await handleReportSubmit({
        format,
        mode,
        id_usuario: user.id_usuario,
        filtros: {
          order_by: order_by ? order_by : undefined,
          id_parceiro: id_parceiro ? id_parceiro : undefined,
          id_parceiro_2: id_parceiro_2 ? id_parceiro_2 : undefined,
          id_forma_pagamento: id_forma_pagamento
            ? id_forma_pagamento
            : undefined,
          id_centro_custo: id_centro_custo ? id_centro_custo : undefined,
          id_receita_despesa: id_receita_despesa
            ? id_receita_despesa
            : undefined,
          data_fim,
          data_inicio,
          id_usuario: id_usuario && id_usuario !== '' ? id_usuario : undefined
        }
      })
      if (format === 'csv') {
        setCSVData(reportData)
      }
    } catch (error) {
      setIsSubmmiting(false)
    } finally {
      setIsSubmmiting(false)
    }
  }

  const handleResetFields = () => {
    reset()
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <FormTitle title={title} />
      {(temParceiro || temParceiro2) && (
        <Stack
          sx={{
            flexDirection: {
              sm: 'column',
              md: 'row'
            }
          }}
          mt={2}
          gap={3}
        >
          {temParceiro && (
            <Controller
              name="id_parceiro"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  label={`${
                    isCliente || temParceiro2 ? 'Cliente' : 'Fornecedor'
                  }`}
                  placeholder={`Selecione o ${
                    isCliente || temParceiro2 ? 'Cliente' : 'Fornecedor'
                  }`}
                  data={parceiros}
                  fields={['id_parceiro', 'nome']}
                  message={errors.id_parceiro?.message}
                  {...field}
                  value={field.value || ''}
                  onChange={(event) => {
                    field.onChange(event.target.value)
                  }}
                />
              )}
            />
          )}

          {temParceiro2 && (
            <Controller
              name="id_parceiro_2"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  label={'Fornecedor'}
                  placeholder={'Selecione o Fornecedor'}
                  data={fornecedores}
                  fields={['id_parceiro', 'nome']}
                  message={errors.id_parceiro_2?.message}
                  {...field}
                  value={field.value || ''}
                  onChange={(event) => {
                    field.onChange(event.target.value)
                  }}
                />
              )}
            />
          )}
        </Stack>
      )}
      {temCentroCusto && (
        <Stack direction="row" mt={2} gap={3}>
          <Controller
            name="id_centro_custo"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Select
                label="Centro de custo"
                placeholder="Selecione o Centro de Custo"
                data={centrosCusto}
                fields={['id_centro_custo', 'descricao']}
                message={errors.id_centro_custo?.message}
                {...field}
                value={field.value || ''}
                onChange={(event) => {
                  field.onChange(event.target.value)
                }}
              />
            )}
          />
        </Stack>
      )}
      {temFormaPagamento && (
        <Stack
          sx={{
            flexDirection: {
              sm: 'column',
              md: 'row'
            }
          }}
          mt={2}
          gap={3}
        >
          <Controller
            name="id_forma_pagamento"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Select
                label={'Forma de Pagamento'}
                placeholder="Selecione a Forma de Pagamento"
                data={formasPagamento}
                fields={['id_forma_pagamento', 'descricao']}
                message={errors.id_forma_pagamento?.message}
                {...field}
                value={field.value || ''}
                onChange={(event) => {
                  field.onChange(event.target.value)
                }}
              />
            )}
          />
        </Stack>
      )}

      {temFormaPagamento && (
        <Stack
          sx={{
            flexDirection: {
              sm: 'column',
              md: 'row'
            }
          }}
          mt={2}
          gap={3}
        >
          <Controller
            name="id_receita_despesa"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Select
                label={`${
                  temParceiro2
                    ? 'Receita / Despesa'
                    : isCliente
                    ? 'Receita'
                    : 'Despesa'
                }`}
                placeholder={`Selecione a ${
                  temParceiro2
                    ? 'Receita / Despesa'
                    : isCliente
                    ? 'Receita'
                    : 'Despesa'
                }`}
                showField2={true}
                data={listaReceitaDespesa}
                fields={['id_receita_despesa', 'descricao', 'tipo']}
                message={errors.id_receita_despesa?.message}
                {...field}
                value={field.value || ''}
                onChange={(event) => {
                  field.onChange(event.target.value)
                }}
              />
            )}
          />
        </Stack>
      )}

      {temPeriodo && (
        <Stack
          sx={{
            flexDirection: {
              sm: 'column',
              md: 'row'
            }
          }}
          mt={2}
          gap={3}
        >
          <Controller
            name="data_inicio"
            control={control}
            render={({ field }) => (
              <Input
                label="Data Início"
                type="date"
                {...field}
                onChange={(event) => {
                  field.onChange(event.target.value)
                }}
                InputLabelProps={{
                  shrink: true
                }}
                value={field.value || ''}
                message={errors.data_inicio?.message}
              />
            )}
          />

          <Controller
            name="data_fim"
            control={control}
            render={({ field }) => (
              <Input
                label="Data Fim"
                type="date"
                {...field}
                onChange={(event) => {
                  field.onChange(event.target.value)
                }}
                InputLabelProps={{
                  shrink: true
                }}
                value={field.value || ''}
                message={errors.data_fim?.message}
              />
            )}
          />
          <Controller
            name="order_by"
            control={control}
            defaultValue={isOrdernarPrevisao ? 'dt_vencimento' : 'dt_pagamento'}
            render={({ field }) => (
              <Select
                label={'Ordenar por'}
                placeholder="Selecione a Ordernação"
                data={
                  isOrdernarPrevisao
                    ? reportOrderOptionsPrevisao
                    : reportOrderOptionsBaixas
                }
                fields={['value', 'label']}
                message={errors.order_by?.message}
                {...field}
                value={field.value || ''}
                onChange={(event) => {
                  field.onChange(event.target.value)
                }}
              />
            )}
          />
        </Stack>
      )}

      {temUsuario && (
        <Stack
          sx={{
            flexDirection: {
              sm: 'column',
              md: 'row'
            }
          }}
          py={3}
          gap={3}
        >
          <Controller
            name="id_usuario"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Select
                label={'Usuário'}
                placeholder="Selecione o Usuário"
                data={listaUsuarios}
                fields={['id_usuario', 'username']}
                message={errors.id_usuario?.message}
                {...field}
                value={field.value || ''}
                onChange={(event) => {
                  field.onChange(event.target.value)
                }}
              />
            )}
          />
        </Stack>
      )}

      <ReportButtons
        mode={mode}
        format={format}
        isSubmitting={isSubmitting}
        handleReportFormatAndMode={handleReportFormatAndMode}
        handleResetFields={handleResetFields}
      />
      {csvData.length > 0 && <CSVDownload data={csvData} target="_self" />}
    </Box>
  )
}
