import { useMemo, useState } from 'react'
import {
  Box,
  Button,
  Chip,
  Divider,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/AddCircle'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import { useServico } from '../../../hooks/servico/useServico'
import useToast from '../../../hooks/toast/useToast'
import { FormMode } from '../../../types/formMode'
import { Loading } from '../../../components/Loading'
import React from 'react'
import { ManageSearch } from '@mui/icons-material'
import { IServico } from '../../../services/servico/types'

interface IForm {
  setData: (value: React.SetStateAction<any>) => void
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

export function List({ setData, setFormMode }: IForm) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [q, setQ] = useState<string>('')
  const { Toast } = useToast()
  const searchInput = React.useRef<HTMLInputElement>(null)

  const handleSearchClick = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setSearchParams({ ...searchParams, q: searchTerm })
    setQ(searchTerm)
    searchInput.current?.focus()
  }

  const page = useMemo(() => {
    return Number(searchParams.get('page') ?? 1)
  }, [searchParams])

  const { mutateAsync: removeFluxoAtividades } =
    useServico.RemoveFluxoAtividades()
  const { data: records, isLoading } = useServico.FindAllWithFluxo(page, q)

  const handleNewForm = () => {
    setFormMode('CREATE')
  }

  const handleRemove = async (id: string) => {
    await Toast(removeFluxoAtividades(id), 'REMOVED')
    setSearchParams({ page: '1' })
  }

  const handleEditForm = (data: IServico) => {
    setData(data)
    setFormMode('EDIT')
  }

  const handleViewForm = (data: IServico) => {
    setData(data)
    setFormMode('VIEW')
  }

  if (isLoading) return <Loading />

  return (
    <Box>
      <Stack
        component="form"
        onSubmit={handleSearchClick}
        direction="row"
        py={3}
        gap={3}
        mb={2}
      >
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          placeholder="Serviço"
          inputRef={searchInput}
          autoFocus
          value={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value)
          }}
        />
        <Button variant="outlined" startIcon={<SearchIcon />} type="submit">
          Pesquisar
        </Button>
      </Stack>

      <Divider />

      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleNewForm}
        sx={{ float: 'right', my: '12px' }}
      >
        Novo registro
      </Button>

      <TableContainer
        component={Box}
        border={1}
        borderRadius={2}
        borderColor="#f2f2f2"
      >
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Nome
              </TableCell>
              <TableCell width="12px" align="center" />
              <TableCell width="12px" align="center" />
              <TableCell width="12px" align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {records?.data?.map((item) => (
              <TableRow key={item.id_servico} hover>
                <TableCell>{item.nome}</TableCell>

                <TableCell>
                  {!item.em_uso ? (
                    <Tooltip title="Editar" arrow>
                      <Button
                        variant="outlined"
                        sx={{ minWidth: '24px', padding: '6px' }}
                        onClick={() => handleEditForm(item)}
                      >
                        <EditIcon />
                      </Button>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title="Esta serviço está em uso. Não pode ser editado."
                      arrow
                    >
                      <Chip
                        label="Em uso"
                        color="primary"
                        size="small"
                        variant="outlined"
                      />
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell>
                  {!item.em_uso ? (
                    <Tooltip title="Excluir" arrow>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleRemove(item.id_servico!)}
                        sx={{ minWidth: '24px', padding: '6px' }}
                      >
                        <DeleteIcon />
                      </Button>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title="Esta serviço está em uso. Não pode ser excluído."
                      arrow
                    >
                      <Chip
                        label="Em uso"
                        color="primary"
                        size="small"
                        variant="outlined"
                      />
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell>
                  <Tooltip title="Visualizar" arrow>
                    <Button
                      variant="outlined"
                      sx={{ minWidth: '24px', padding: '6px' }}
                      onClick={() => handleViewForm(item)}
                    >
                      <ManageSearch />
                    </Button>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {records?.pagination &&
        records.pagination.totalItems > records.pagination.itemsPerPage && (
          <Pagination
            count={records?.pagination.totalPages}
            sx={{ marginTop: '12px' }}
            onChange={(_, newPage) =>
              setSearchParams({ page: String(newPage) })
            }
            page={records.pagination.currentPage}
          />
        )}
    </Box>
  )
}
