import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import InventoryIcon from '@mui/icons-material/Inventory'
import ListIcon from '@mui/icons-material/List'

interface VisaoCaixasProps {
  isVisaoCaixas: boolean
  setIsVisaoCaixas: (value: boolean) => void
}

export default function VisaoCaixas({
  isVisaoCaixas,
  setIsVisaoCaixas
}: VisaoCaixasProps) {
  const handleView = (
    event: React.MouseEvent<HTMLElement>,
    novaVisao: boolean
  ) => {
    setIsVisaoCaixas(novaVisao)
  }

  return (
    <ToggleButtonGroup
      color="primary"
      value={isVisaoCaixas}
      exclusive
      onChange={handleView}
      aria-label="text alignment"
    >
      <ToggleButton value={true} aria-label="Visão de Caixas">
        <InventoryIcon />
      </ToggleButton>
      <ToggleButton value={false} aria-label="Visão de Listas">
        <ListIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
