import pdfMake from 'pdfmake/build/pdfmake'
import { TDocumentDefinitions } from 'pdfmake/interfaces'
import { IEtiqueta } from './types'
import { format } from 'date-fns'
// import logo from '../../../../assets/images/logo_xsolution_beta.png'
import logoReportImg from '../../../../assets/images/logo-report.png'
import { toDataURL } from '../../../../utils/toDataURL'
import { vfs } from '../../../../utils/report/fonts/pdfFonts'

pdfMake.vfs = vfs
pdfMake.fonts = {
  Montserrat: {
    normal: 'Montserrat-Regular.ttf',
    bold: 'Montserrat-SemiBold.ttf',
    italics: 'Montserrat-Italic.ttf',
    bolditalics: 'Montserrat-SemiBoldItalic.ttf'
  }
}

type EtiquetaProps = {
  data: IEtiqueta
  mode?: 'open' | 'save'
}

export async function QRCode({ data, mode = 'open' }: EtiquetaProps) {
  // const reportLogo = String(await toDataURL(logo))
  const logoReport = String(await toDataURL(logoReportImg))

  const pageHeader = () => [
    {
      image: logoReport,
      width: 129.6,
      height: 72.9,
      style: 'logo'
    }
  ]

  /*const regex = /NOME: (.*?) - CAIXA:/
  const result = data.nome.match(regex)
  const nomeDoc = result ? result[1] : null*/

  const gerarQRCodeDocumento = () => [
    {
      qr: `https://easydoc.xsolutionti.com.br/easy-view/documentos?busca=${data.id_documento}`,
      fit: 140,
      style: 'qrcode'
    },
    {
      text: `ID: ${data.id_documento}`,
      style: 'subtitle'
    },
    /*{
      text: `NOME: ${nomeDoc}`,
      style: 'subtitle'
    },*/
    {
      text: 'Impresso em:' + format(new Date(), " dd/MM/yyyy 'às' HH:mm"),
      style: 'subtitle'
    }
  ]

  const docDefinitions: TDocumentDefinitions = {
    defaultStyle: { font: 'Montserrat' },
    pageSize: 'A4',
    pageMargins: [30, 5, 30, 0],
    content: [...pageHeader(), ...gerarQRCodeDocumento()],
    styles: {
      qrcode: {
        margin: [8, 8, 8, 8]
      },
      subtitle: {
        margin: [8, 0, 0, 0]
      },
      logo: {
        margin: [0, 16, 0, 8]
      }
    }
  }

  if (mode === 'open') {
    const win = window.open('', '_blank', 'popup=yes')
    pdfMake.createPdf(docDefinitions).open({}, win)
  } else if (mode === 'save') {
    pdfMake.createPdf(docDefinitions).download(`etiqueta.pdf`)
  }
}
