import { DefaultApi } from '../../utils/defaultApi'
import api from '../api'
import { IMovimentacao, IAlocacao, IAlocacaoCaixa } from './types'

const endpoint = '/movimentacao'
const resourceId = 'id_movimentacao'

class MovimentacaoService extends DefaultApi<IMovimentacao> {
  allocate = async ({
    tipo,
    posicoesCaixas
  }: IAlocacao): Promise<IAlocacaoCaixa[]> => {
    const { data } = await api.put<IAlocacaoCaixa[]>(
      `${this.endpoint}/alocacao/`,
      {
        tipo,
        posicoesCaixas
      }
    )
    return data
  }

  updateMany = async (formData: IMovimentacao[]): Promise<IMovimentacao[]> => {
    const movData = {
      movimentacoes: formData
    }
    const { data } = await api.put<IMovimentacao[]>(
      `${this.endpoint}/varias`,
      movData
    )
    return data
  }
}

export const ApiMovimentacao = new MovimentacaoService(endpoint, resourceId)
