import { DashboardWidgetListBodyAction } from './DashboardWidgetListBodyAction'
import { DashboardWidgetListBodyActions } from './DashboardWidgetListBodyActions'
import { DashboardWidgetListBodyPagination } from './DashboardWidgetListBodyPagination'
import { DashboardWidgetListBodyRoot } from './DashboardWidgetListBodyRoot'
import { DashboardWidgetListBodyRow } from './DashboardWidgetListBodyRow'
import { DashboardWidgetListBodyTable } from './DashboardWidgetListBodyTable'

export const DashboardWidgetListBody = {
  Action: DashboardWidgetListBodyAction,
  Actions: DashboardWidgetListBodyActions,
  Root: DashboardWidgetListBodyRoot,
  Row: DashboardWidgetListBodyRow,
  Table: DashboardWidgetListBodyTable,
  Pagination: DashboardWidgetListBodyPagination
}
