import { useQuery } from 'react-query'
import { ApiMensagem } from '../../services/mensagem'

const QUERY_KEY = 'qkMensagem'

const findAll = () => {
  return useQuery([QUERY_KEY, 'all'], () => ApiMensagem.findAllMessages())
}

const findBySender = (id_usuario: string) => {
  return useQuery([QUERY_KEY, 'sender', id_usuario], () =>
    ApiMensagem.findBySender(id_usuario)
  )
}

const findByReceiver = (id_usuario: string) => {
  return useQuery([QUERY_KEY, 'receiver', id_usuario], () =>
    ApiMensagem.findByReceiver(id_usuario)
  )
}

const findByRequisicao = (id_requisicao: string) => {
  return useQuery([QUERY_KEY, 'request', id_requisicao], () =>
    ApiMensagem.findByRequisicao(id_requisicao)
  )
}

export const useMensagem = {
  findAll,
  findBySender,
  findByReceiver,
  findByRequisicao
}
