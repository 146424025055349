import { toast } from 'react-toastify'

export function hasPermission(
  permissoes: number[] | undefined,
  permission: number
): boolean {
  if (!(permissoes && permissoes.includes(permission))) {
    toast.error('Usuário sem permissão para acessar essa funcionalidade', {
      hideProgressBar: true
    })
    return false
  } else return true
}
