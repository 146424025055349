import { useEffect, useState } from 'react'
import { Box, Button, Stack } from '@mui/material'
import SaveIcon from '@mui/icons-material/CheckCircleOutlined'
import CancelIcon from '@mui/icons-material/DoDisturbAltOutlined'
import {
  ICentroDocumentacao,
  centroDocumentacaoBlank
} from '../../../services/centroDocumentacao/types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { ContentCopy } from '@mui/icons-material'

import useToast from '../../../hooks/toast/useToast'
import { useCentroDocumentacao } from '../../../hooks/centroDocumentacao/useCentroDocumentacao'
import { Input } from '../../../components/Form/Input'
import msg from '../../../utils/validationMessages'
import { FormMode } from '../../../types/formMode'
import { Select } from '../../../components/Form/Select'
import { useEmpresa } from '../../../hooks/empresa/useEmpresa'
import { CDDraw } from './components/CDDraw'
import { ComponentGroup } from '../../../components/ComponentGroup'
import { IEndereco } from '../../../services/endereco/types'
import { useUf } from '../../../hooks/uf/useUf'
import { useMunicipio } from '../../../hooks/municipio/useMunicipio'
import { Loading } from '../../../components/Loading'
import { masks } from '../../../utils/masks'
import { getCDSpace } from './lib/func'
import { useTipoCaixa } from '../../../hooks/tipoCaixa/useTipoCaixa'

const schema = yup.object({
  nome: yup.string().required(msg.REQUIRED).min(4, msg.MIN(4)),
  prefixo: yup.string().required(msg.REQUIRED).min(1, msg.MIN(1)),

  nCorredor: yup
    .number()
    .typeError('Informe um número')
    .min(1, 'Informe um número maior ou igual a 1'),

  nestante: yup
    .number()
    .typeError('Informe um número')
    .min(1, 'Informe um número maior ou igual a 1'),

  nprateleira: yup
    .number()
    .typeError('Informe um número')
    .min(1, 'Informe um número maior ou igual a 1'),

  posicao_prateleira: yup
    .number()
    .typeError('Informe um número')
    .min(1, 'Informe um número maior ou igual a 1'),

  id_tipo_caixa: yup.string().required(msg.REQUIRED),

  empresa: yup.object({
    id_empresa: yup.string().required(msg.REQUIRED)
  }),
  endereco: yup.object({
    logradouro: yup.string().required(msg.REQUIRED).min(4, msg.MIN(4)),
    id_municipio: yup.string().required(msg.REQUIRED),
    numero: yup.string().required(msg.REQUIRED),
    bairro: yup.string().required(msg.REQUIRED).min(3, msg.MIN(3)),
    cep: yup.string().required(msg.REQUIRED).min(8, msg.MIN(8)),
    latitude: yup.string().real({
      message: msg.REAL({ min: -90, max: 90 }),
      minValue: -90,
      maxValue: 90
    }),
    longitude: yup.string().real({
      message: msg.REAL({ min: -180, max: 180 }),
      minValue: -180,
      maxValue: 180
    }),
    referencia: yup.string(),
    complemento: yup.string()
  })
})

const blankEndereco: IEndereco = {
  bairro: '',
  cep: '',
  id_municipio: '',
  logradouro: '',
  numero: '',
  ativo: false
}

interface IForm {
  data: ICentroDocumentacao
  formMode: FormMode
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

export function Form({ data, formMode, setFormMode }: IForm) {
  const { data: centro, isLoading } = useCentroDocumentacao.FindById(
    data.id_centrodocumentacao ?? ''
  )

  const [centroView, setCentroView] = useState<ICentroDocumentacao>(
    centro ?? centroDocumentacaoBlank
  )

  const { data: tipoCaixa, isLoading: isLoadingTipoCaixa } =
    useTipoCaixa.ListAll()

  useEffect(() => {
    if (centro && !isLoading) {
      setCentroView(centro)
    }
  }, [centro, isLoading])

  const [uf, setUf] = useState<string>(
    data.endereco?.municipio?.estado?.sigla ?? 'RN'
  )

  const [id_municipio, setIdMunicipio] = useState<string>(
    data.endereco?.id_municipio ?? ''
  )

  const [id_empresa, setIdEmpresa] = useState<string>(
    data.empresa?.id_empresa ?? ''
  )
  const [endereco, setEndereco] = useState<IEndereco>(
    data.endereco ?? blankEndereco
  )

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    setValue,
    resetField
  } = useForm<any>({ resolver: yupResolver(schema) })

  const { Toast } = useToast()
  const { mutateAsync: create } = useCentroDocumentacao.Create()
  const { mutateAsync: update } = useCentroDocumentacao.Update()
  const { mutateAsync: deletePosicaoPrateleira } =
    useCentroDocumentacao.DeletePosicaoPrateleira()
  const { mutateAsync: includePosicaoPrateleira } =
    useCentroDocumentacao.AddPosicaoPrateleira()
  const { mutateAsync: deletePrateleira } =
    useCentroDocumentacao.DeletePrateleira()
  const { mutateAsync: includePrateleira } =
    useCentroDocumentacao.AddPrateleira()
  const { mutateAsync: deleteEstante } = useCentroDocumentacao.DeleteEstante()
  const { mutateAsync: includeEstante } = useCentroDocumentacao.AddEstante()
  const { mutateAsync: deleteCorredor } = useCentroDocumentacao.DeleteCorredor()
  const { mutateAsync: includeCorredor } = useCentroDocumentacao.AddCorredor()

  const [idTipoCaixa, setIdTipoCaixa] = useState<string>('')

  const { mutateAsync: changePrateleira } =
    useCentroDocumentacao.ChangePrateleira()

  const { mutateAsync: togglePosicaoPrateleira } =
    useCentroDocumentacao.ToggleStatePosicaoPrateleira()

  const { data: empresas } = useEmpresa.ListAll()
  const { data: ufs } = useUf.ListAll()
  const { data: municipios, isLoading: isLoadingMunicipios } =
    useMunicipio.FindByUf(uf)
  const { data: empresaEndereco, isLoading: isLoadingEndereco } =
    useEmpresa.FindOne(id_empresa)

  setValue('empresa.id_empresa', id_empresa)
  setValue('endereco.id_municipio', id_municipio)

  function handleCepChange(value: string): string {
    const newValue = masks.cep({ value })
    return newValue
  }

  const handleTipoCaixaChange = (id_tipo_caixa: string) => {
    setIdTipoCaixa(id_tipo_caixa)
  }

  function handleEmpresaChange(id_empresa: string) {
    setValue('empresa.id_empresa', id_empresa)
    setIdEmpresa(id_empresa)
  }

  function handleMunicipioChange(id: string) {
    setIdMunicipio(id)
    setValue('endereco.id_municipio', id)
  }

  const handleTextFieldChange = (fieldName: string, value: any) => {
    const newEndereco = {
      ...endereco,
      [fieldName]: value
    }
    setEndereco(newEndereco)
  }

  const handleCopyButtonClick = () => {
    if (!empresaEndereco) return
    const endereco = empresaEndereco.endereco
    /**
     *Endereco pode vir em branco, caso id_empresa não esteja definido.
     *Isso ocorre porque o back recebe um get sem uma string com id de empresa.
     *Get em empresa, sem id da empresa resulta em recuperação de lista
     *paginada de empresas, que não é o mesmo resultado de findOne
     */
    if (!endereco) return
    setUf(endereco?.municipio?.estado?.sigla ?? '')
    setIdMunicipio(endereco.id_municipio)
    setValue('endereco.id_municipio', endereco.id_municipio)
    setValue('endereco.bairro', endereco.bairro)
    setValue('endereco.cep', endereco.cep)
    setValue('endereco.complemento', endereco.complemento)
    setValue('endereco.latitude', endereco.latitude)
    setValue('endereco.logradouro', endereco.logradouro)
    setValue('endereco.longitude', endereco.longitude)
    setValue('endereco.numero', endereco.numero)
    setValue('endereco.referencia', endereco.referencia)
    setEndereco(endereco)
  }

  const onSubmit = async (formData: ICentroDocumentacao) => {
    formMode === 'CREATE'
      ? await Toast(create(formData))
      : await Toast(
          update({
            id_centrodocumentacao: data.id_centrodocumentacao,
            ...formData
          }),
          'UPDATED'
        )

    setFormMode('LIST')
  }

  const excluirPosicaoPrateleira = async (id_posicao_prateleira: string) => {
    await Toast(deletePosicaoPrateleira(id_posicao_prateleira))
  }

  const incluirPosicaoPrateleira = async (
    id_prateleira: string,
    quantidade: number
  ) => {
    await Toast(includePosicaoPrateleira({ id_prateleira, quantidade }))
  }

  const excluirPrateleira = async (id_prateleira: string) => {
    await Toast(deletePrateleira(id_prateleira))
  }

  const incluirPrateleira = async (
    id_estante: string,
    quantidadePrateleiras: number,
    quantidadePosicoes: number,
    idTipoCaixa: string
  ) => {
    await Toast(
      includePrateleira({
        id_estante,
        quantidadePrateleiras,
        quantidadePosicoes,
        id_tipo_caixa: idTipoCaixa
      })
    )
  }

  const mudarPrateleira = async (
    id_prateleira: string,
    id_tipo_caixa: string
  ) => {
    setIdTipoCaixa(id_tipo_caixa)
    await Toast(changePrateleira({ id_prateleira, id_tipo_caixa }), 'UPDATED')
  }

  const excluirEstante = async (id_estante: string) => {
    await Toast(deleteEstante(id_estante))
  }

  const incluirEstante = async (
    id_corredor: string,
    quantidadeEstantes: number,
    quantidadePrateleiras: number,
    quantidadePosicoes: number,
    idTipoCaixa: string
  ) => {
    await Toast(
      includeEstante({
        id_corredor,
        quantidadeEstantes,
        quantidadePrateleiras,
        quantidadePosicoes,
        id_tipo_caixa: idTipoCaixa
      })
    )
  }

  const excluirCorredor = async (id_corredor: string) => {
    await Toast(deleteCorredor(id_corredor))
  }

  const alternarPosicaoPrateleira = async (id_posicao_prateleira: string) => {
    await Toast(togglePosicaoPrateleira({ id_posicao_prateleira }), 'UPDATED')
  }

  const incluirCorredor = async (
    id_centrodocumentacao: string,
    quantidadeCorredores: number,
    quantidadeEstantes: number,
    quantidadePrateleiras: number,
    quantidadePosicoes: number,
    idTipoCaixa: string
  ) => {
    await Toast(
      includeCorredor({
        id_centrodocumentacao,
        quantidadeCorredores,
        quantidadeEstantes,
        quantidadePrateleiras,
        quantidadePosicoes,
        id_tipo_caixa: idTipoCaixa
      })
    )
  }

  if (isLoadingMunicipios || isLoadingEndereco || isLoadingTipoCaixa)
    return <Loading />
  return (
    <>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} mt={4}>
        <Stack gap={2}>
          <Input
            label="Nome*"
            {...register('nome')}
            value={data?.nome}
            message={errors.nome?.message}
          />
          <Box
            gap={2}
            sx={{
              display: 'flex',
              flexDirection: 'col'
            }}
          >
            <Select
              label="Empresa"
              placeholder="Selecione a empresa"
              data={empresas}
              fields={['id_empresa', 'nome']}
              {...register('empresa.id_empresa')}
              value={data?.id_empresa}
              onChange={(event) => {
                handleEmpresaChange(String(event.target.value))
              }}
              message={errors.empresa?.id_empresa?.message}
            />
            {formMode !== 'CREATE' && (
              <Input
                label="Capacidade"
                disabled={true}
                value={getCDSpace(centroView).total}
                fullWidth={false}
              />
            )}
          </Box>
          <Input
            label="Identificação (prefixo)*"
            {...register('prefixo')}
            value={data?.prefixo}
            message={errors.prefixo?.message}
          />
          {formMode === 'CREATE' && (
            <Stack
              gap={2}
              direction={'row'}
              sx={{
                width: '100%'
              }}
              justifyContent={'space-between'}
            >
              <Select
                label="Tipo de Caixa Padrão*"
                placeholder="Selecione o Tipo de Caixa"
                data={tipoCaixa}
                fields={['id_tipo_caixa', 'descricao']}
                {...register('id_tipo_caixa')}
                value={idTipoCaixa}
                onChange={(event) => {
                  handleTipoCaixaChange(String(event.target.value))
                }}
                message={errors.empresa?.id_empresa?.message}
              />
              <Input
                label="Quantidade de Corredores*"
                {...register('nCorredor')}
                value={data?.nCorredor}
                fullWidth={true}
                message={errors.nCorredor?.message}
              />
              <Input
                label="Quantidade de Estantes por Corredor*"
                {...register('nestante')}
                value={data?.nestante}
                fullWidth={true}
                message={errors.nestante?.message}
              />
              <Input
                label="Quantidade Prateleiras por Estantes*"
                {...register('nprateleira')}
                value={data?.nprateleira}
                fullWidth={true}
                message={errors.nprateleira?.message}
              />
              <Input
                label="Quantidade de Posições de Prateleiras por Prateleira*"
                {...register('posicao_prateleira')}
                value={data?.posicao_prateleira}
                fullWidth={true}
                message={errors.posicao_prateleira?.message}
              />
            </Stack>
          )}
          <ComponentGroup title="Endereço">
            <Stack direction="row">
              <Button
                type="button"
                variant="outlined"
                startIcon={<ContentCopy />}
                sx={{ width: '320px' }}
                onClick={handleCopyButtonClick}
              >
                Copiar Endereço da Empresa
              </Button>
            </Stack>
            <Stack gap={2} direction="row">
              <Select
                label="Estado"
                placeholder="Selecione o Estado"
                data={ufs}
                fields={['sigla', 'nome']}
                value={uf ?? ''}
                message={isSubmitted && !uf ? msg.REQUIRED : ''}
                onChange={(e) => {
                  setUf(String(e.target.value))
                  setIdMunicipio('')
                }}
                onBlur={() => resetField('endereco.municipio.id_municipio')}
              />

              <Select
                label="Município"
                placeholder="Selecione o município"
                data={municipios}
                fields={['id_municipio', 'nome']}
                value={id_municipio ?? ''}
                onChange={(event) =>
                  handleMunicipioChange(String(event.target.value))
                }
                message={errors.endereco?.id_municipio?.message}
              />
            </Stack>

            <Input
              label="Logradouro*"
              {...register('endereco.logradouro')}
              value={data?.endereco?.logradouro}
              message={errors.endereco?.logradouro?.message}
              InputLabelProps={{ shrink: !!(endereco && endereco.logradouro) }}
            />

            <Stack gap={2} direction="row">
              <Input
                label="Número*"
                {...register('endereco.numero')}
                value={data?.endereco?.numero}
                message={errors.endereco?.numero?.message}
                InputLabelProps={{ shrink: !!(endereco && endereco.numero) }}
              />

              <Input
                label="Bairro*"
                {...register('endereco.bairro')}
                value={data?.endereco?.bairro}
                message={errors.endereco?.bairro?.message}
                InputLabelProps={{ shrink: !!(endereco && endereco.bairro) }}
              />

              <Input
                label="Cep*"
                {...register('endereco.cep', {
                  onChange: (event) => {
                    const { value } = event.target
                    event.target.value = handleCepChange(value)
                  }
                })}
                value={data?.endereco?.cep}
                message={errors.endereco?.cep?.message}
                InputLabelProps={{ shrink: !!(endereco && endereco.cep) }}
              />
            </Stack>

            <Input
              label="Complemento"
              {...register('endereco.complemento', {
                onChange: (event) => {
                  handleTextFieldChange('complemento', event?.target.value)
                }
              })}
              value={data?.endereco?.complemento}
              InputLabelProps={{ shrink: !!(endereco && endereco.complemento) }}
            />

            <Input
              label="Referencia"
              {...register('endereco.referencia', {
                onChange: (event) => {
                  handleTextFieldChange('referencia', event?.target.value)
                }
              })}
              value={data?.endereco?.referencia}
              InputLabelProps={{ shrink: !!(endereco && endereco.referencia) }}
            />

            <Stack gap={2} direction="row">
              <Input
                label="Latitude"
                {...register('endereco.latitude', {
                  onChange: (event) => {
                    handleTextFieldChange('latitude', event?.target.value)
                  }
                })}
                value={data?.endereco?.latitude}
                InputLabelProps={{ shrink: !!(endereco && endereco.latitude) }}
                message={errors.endereco?.latitude?.message}
              />

              <Input
                label="Longitude"
                {...register('endereco.longitude', {
                  onChange: (event) => {
                    handleTextFieldChange('longitude', event?.target.value)
                  }
                })}
                value={data?.endereco?.longitude}
                InputLabelProps={{ shrink: !!(endereco && endereco.longitude) }}
                message={errors.endereco?.longitude?.message}
              />
            </Stack>
          </ComponentGroup>
        </Stack>

        <Stack direction="row" gap={2} mt={3}>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<CancelIcon />}
            onClick={() => setFormMode('LIST')}
          >
            Cancelar
          </Button>

          <Button
            type="submit"
            variant="contained"
            startIcon={<SaveIcon />}
            sx={{ width: '110px' }}
          >
            Salvar
          </Button>
        </Stack>
      </Box>
      {formMode === 'EDIT' &&
        !isLoading &&
        centroView !== centroDocumentacaoBlank && (
          <CDDraw
            centroDocumentacao={centroView}
            excluirPosicaoPrateleira={excluirPosicaoPrateleira}
            incluirPosicaoPrateleira={incluirPosicaoPrateleira}
            excluirPrateleira={excluirPrateleira}
            incluirPrateleira={incluirPrateleira}
            excluirEstante={excluirEstante}
            incluirEstante={incluirEstante}
            excluirCorredor={excluirCorredor}
            incluirCorredor={incluirCorredor}
            mudarPrateleira={mudarPrateleira}
            alternarPosicaoPrateleira={alternarPosicaoPrateleira}
          />
        )}
    </>
  )
}
