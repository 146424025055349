import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ApiMovimentacao } from '../../services/movimentacao'

const QUERY_KEY = 'qkMovimentacao'
const QUERY_KEY_DASHBOARD = 'qkDashboard'

const FindAll = (page: number, q = '') => {
  return useQuery([QUERY_KEY, q, page], () => ApiMovimentacao.findAll(page, q))
}

const ListAll = () => {
  return useQuery([QUERY_KEY], () => ApiMovimentacao.listAll())
}

const Create = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiMovimentacao.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
      queryClient.invalidateQueries([QUERY_KEY_DASHBOARD])
    }
  })
}

const Update = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiMovimentacao.update, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEY])
      queryClient.invalidateQueries([QUERY_KEY_DASHBOARD])
    }
  })
}

const UpdateMany = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiMovimentacao.updateMany, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEY])
      queryClient.invalidateQueries([QUERY_KEY_DASHBOARD])
    }
  })
}

const Remove = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiMovimentacao.remove, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
      queryClient.invalidateQueries([QUERY_KEY_DASHBOARD])
    }
  })
}

const Allocate = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiMovimentacao.allocate, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEY])
      queryClient.invalidateQueries([QUERY_KEY_DASHBOARD])
    }
  })
}

export const useMovimentacao = {
  Create,
  Update,
  UpdateMany,
  FindAll,
  Remove,
  ListAll,
  Allocate
}
