import { useEffect, useState } from 'react'
import {
  Box,
  Divider,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material'
import AddIcon from '@mui/icons-material/PostAdd'
import DeleteIcon from '@mui/icons-material/DeleteForever'
import CloseIcon from '@mui/icons-material/Close'
import useToast from '../../../hooks/toast/useToast'
import { Dialog } from '../../../components/Dialog'
import { useArvoreDocumental } from '../../../hooks/arvoreDcoumental/useArvoreDocumental'
import { useCaixa } from '../../../hooks/caixa/useCaixa'
import { SearchBox } from '../../../components/SearchBox'
import { ITipoCaixa } from '../../../services/tipoCaixa/types'
import { AdvancedFindAllParams, ICaixa } from '../../../services/caixa/types'
import { useUsuario } from '../../../hooks/usuario/useUsuario'
import { Permissions } from '../../../types/permissions'
import { getCodigo } from '../../../utils/getCodigo'
import { ComponentGroup } from '../../../components/ComponentGroup'
import { exclude } from '../../../utils/exclude'

type FormCaixaProps = {
  open: boolean
  handleClose: () => void
  idCaixa: string
  tiposCaixas: ITipoCaixa[]
  tamanho: number
  caixas: ICaixa[]
  isInventario?: boolean
}

export function FormCaixa({
  open,
  handleClose,
  tiposCaixas,
  caixas,
  idCaixa,
  tamanho,
  isInventario = false
}: FormCaixaProps) {
  const { Toast } = useToast()
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [showDismiss, setShowDismiss] = useState<boolean>(false)
  const [listaVolumes, setListaVolumes] = useState<Array<ICaixa>>([])
  const [listaCaixas, setListaCaixas] = useState<Array<ICaixa>>(
    [...caixas] ?? []
  )
  const [currentBox, setCurrentBox] = useState<ICaixa>()
  const { data: tiposDocumentos } = useArvoreDocumental.FindTiposDocumentos()
  const { mutateAsync: update } = useCaixa.Update()
  const { data: usuario } = useUsuario.FindByUsername()
  const hasAccess = (permission: number) =>
    usuario?.permissoes.includes(permission) ?? false

  const searchParams2AdvanceSearchParams = (
    search: URLSearchParams
  ): AdvancedFindAllParams => {
    let newAdv: AdvancedFindAllParams = {}
    if (search.get('busca') !== null) {
      newAdv = {
        ...newAdv,
        busca: search.get('busca') ?? undefined
      }
    }
    if (search.get('data') !== null) {
      newAdv = {
        ...newAdv,
        data: search.get('data') ?? undefined
      }
    }
    if (search.get('sequencia') !== null) {
      newAdv = {
        ...newAdv,
        sequencia: search.get('sequencia') ?? undefined
      }
    }
    if (search.get('id_tipo_caixa') !== null) {
      newAdv = {
        ...newAdv,
        id_tipo_caixa: search.get('id_tipo_caixa') ?? undefined
      }
    }
    if (search.get('id_tipo_documento') !== null) {
      newAdv = {
        ...newAdv,
        id_tipo_documento: search.get('id_tipo_documento') ?? undefined
      }
    }
    if (search.get('dados_pessoais') !== null) {
      newAdv = {
        ...newAdv,
        dados_pessoais: search.get('dados_pessoais') ?? undefined
      }
    }
    if (search.get('dados_pessoais_sensiveis') !== null) {
      newAdv = {
        ...newAdv,
        dados_pessoais_sensiveis:
          search.get('dados_pessoais_sensiveis') ?? undefined
      }
    }
    if (search.get('dados_criancas_adolescentes') !== null) {
      newAdv = {
        ...newAdv,
        dados_criancas_adolescentes:
          search.get('dados_criancas_adolescentes') ?? undefined
      }
    }

    newAdv = {
      ...newAdv,
      requisitos: 'CRIADO_CLIENTE OU DEVOLVIDO',
      limit: 800
    }

    return newAdv
  }

  const [advancedSearchParams, setAdvancedSearchParam] =
    useState<AdvancedFindAllParams>(
      searchParams2AdvanceSearchParams(new URLSearchParams())
    )

  const { data: records, isLoading: isLoadingCaixas } = useCaixa.FindAll(1, {
    ...advancedSearchParams,
    is_inventario: isInventario
  })

  const setSearchCriteria = (newSearchParams: AdvancedFindAllParams) => {
    newSearchParams = {
      ...newSearchParams,
      limit: 800,
      requisitos: 'CRIADO_CLIENTE OU DEVOLVIDO'
    }
    setAdvancedSearchParam(newSearchParams)
  }

  const handleSubmit = async () => {
    setShowConfirm(false)
    const tmpCaixa = exclude(currentBox as any, [
      'id_setor',
      'created_at',
      'updated_at',
      'estadoAtividade',
      'contrato',
      'documentos',
      'tipoCaixa',
      'endereco',
      'posicaoPrateleira',
      'caixaMae',
      'caixaFilha',
      'setor'
    ]) as ICaixa
    const formData = {
      ...tmpCaixa,
      id_caixa_container: idCaixa
    }
    await Toast(update(formData), 'UPDATED', 'Caixa acondicionada com sucesso!')
    setCurrentBox(undefined)
  }

  const handleSubmitRemove = async () => {
    setShowDismiss(false)
    const tmpCaixa = exclude(currentBox as any, [
      'id_setor',
      'created_at',
      'updated_at',
      'estadoAtividade',
      'contrato',
      'documentos',
      'tipoCaixa',
      'endereco',
      'posicaoPrateleira'
    ]) as ICaixa
    const formData = {
      ...tmpCaixa,
      id_caixa_container: ''
    }
    await Toast(update(formData), 'UPDATED', 'Caixa removida com sucesso!')
    setCurrentBox(undefined)
  }

  useEffect(() => {
    if (!isLoadingCaixas) {
      if (records) {
        const { data } = records
        const newLista = data
          .filter(
            (cx) =>
              !cx.id_caixa_container &&
              !listaCaixas.find((cxf) => cxf.id_caixa === cx.id_caixa)
          )
          .filter((cx) => (cx.tipoCaixa?.tamanho ?? 100) < tamanho)
        setListaVolumes(
          newLista.sort((a, b) => {
            if (a.estado === b.estado) {
              if (a.identificacao > b.identificacao) {
                return 1
              }
              if (a.identificacao < b.identificacao) {
                return -1
              }
              return 0
            }
            if (a.estado === 'CRIADO_CLIENTE') {
              return -1
            }
            return 1
          })
        )
      }
    }
  }, [records, listaCaixas])

  useEffect(() => {
    setListaCaixas(caixas)
  }, [caixas])

  const handleAddCaixa = (newCaixa: ICaixa) => {
    setCurrentBox(newCaixa)
    setShowConfirm(true)
  }

  const handleRemoveCaixa = (newCaixa: ICaixa) => {
    setCurrentBox(newCaixa)
    setShowDismiss(true)
  }

  const handleCloseButton = () => {
    setAdvancedSearchParam(
      searchParams2AdvanceSearchParams(new URLSearchParams())
    )
    handleClose()
  }

  return (
    <Modal
      open={open}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        top: '5%'
      }}
    >
      <Box
        borderRadius="4px"
        borderColor="lightgray"
        display={'flex'}
        flexDirection={'column'}
        sx={{
          bgcolor: 'background.paper',
          boxShadow: 24,
          width: 800,
          height: 500
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          bgcolor="#2835ad"
          px={2}
          sx={{
            borderTopRightRadius: '4px',
            borderTopLeftRadius: '4px'
          }}
        >
          <Typography color="#FFF" py={1.5} variant="subtitle1">
            Inclusão de Caixas à Caixa
          </Typography>

          <Tooltip title="Fechar" arrow>
            <IconButton onClick={() => handleCloseButton()}>
              <CloseIcon sx={{ color: '#fff' }} />
            </IconButton>
          </Tooltip>
        </Box>

        <Box
          sx={{
            height: 500,
            overflowY: 'scroll'
          }}
        >
          <Box sx={{ margin: '1rem' }}>
            <ComponentGroup title="Caixas acondicionadas">
              <TableContainer component={Box} border={1} borderRadius={2}>
                <Table size={'small'} stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '10px',
                          minWidth: 200
                        }}
                      >
                        Contrato
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '10px',
                          minWidth: 200
                        }}
                      >
                        Identificação
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '10px' }}>
                        Tipo de Caixa
                      </TableCell>

                      <TableCell
                        sx={{ fontWeight: 'bold', fontSize: '10px' }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listaCaixas.length > 0 &&
                      listaCaixas.map((item) => (
                        <TableRow key={item.id_caixa} hover>
                          <TableCell>{item.contrato?.descricao}</TableCell>
                          <TableCell>
                            {item.identificacao}
                            {hasAccess(Permissions.EXIBIR_ID_CAIXA) ? (
                              <Typography
                                sx={{
                                  fontSize: '10px',
                                  fontWeight: 'bold',
                                  color: 'GrayText'
                                }}
                              >
                                ({getCodigo(item.id_caixa ?? '')})
                              </Typography>
                            ) : (
                              ''
                            )}
                          </TableCell>
                          <TableCell>
                            {item.tipoCaixa?.descricao ?? ''}
                          </TableCell>
                          <TableCell>
                            <Tooltip title="Incluir caixa" arrow>
                              <IconButton
                                onClick={() => handleRemoveCaixa(item)}
                              >
                                <DeleteIcon color="primary" fontSize="large" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </ComponentGroup>
          </Box>
          <SearchBox
            setAdvancedSearchParams={setSearchCriteria}
            descricaoBusca={'Caixa/Identificação/Tipo de Caixa/Estado Atual'}
            tipoCaixaExibir={true}
            tiposCaixas={tiposCaixas}
            dadosCriancasAdolescentesExibir={true}
            dadosCriancasAdolescentes={
              advancedSearchParams.dados_criancas_adolescentes
            }
            dadosPessoaisSensiveisExibir={true}
            dadosPessoaisSensiveis={
              advancedSearchParams.dados_pessoais_sensiveis
            }
            dadosPessoaisExibir={true}
            dadosPessoais={advancedSearchParams.dados_pessoais}
            dataExibir={true}
            data={advancedSearchParams.data}
            sequenciaExibir={true}
            sequencia={advancedSearchParams.sequencia}
            tipoDocumentoExibir={true}
            tipoDocumentos={tiposDocumentos}
            busca={advancedSearchParams.busca}
          />

          <TableContainer
            component={Box}
            border={1}
            borderRadius={2}
            borderColor="#f2f2f2"
            maxHeight={'10rem'}
          >
            <Table size={'small'} stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
                  <TableCell
                    sx={{ fontWeight: 'bold', fontSize: '14px', minWidth: 200 }}
                  >
                    Contrato
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: 'bold', fontSize: '14px', minWidth: 200 }}
                  >
                    Identificação
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                    Tipo de Caixa
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: 'bold', fontSize: '14px' }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listaVolumes.length > 0 &&
                  listaVolumes.map((item) => (
                    <TableRow key={item.id_caixa} hover>
                      <TableCell>{item.contrato?.descricao}</TableCell>
                      <TableCell>
                        {item.identificacao}
                        {hasAccess(Permissions.EXIBIR_ID_CAIXA) ? (
                          <Typography
                            sx={{
                              fontSize: '10px',
                              fontWeight: 'bold',
                              color: 'GrayText'
                            }}
                          >
                            ({getCodigo(item.id_caixa ?? '')})
                          </Typography>
                        ) : (
                          ''
                        )}
                      </TableCell>
                      <TableCell>{item.tipoCaixa?.descricao}</TableCell>
                      <TableCell>
                        <Tooltip title="Incluir caixa" arrow>
                          <IconButton
                            onClick={() => handleAddCaixa(item)}
                            disabled={
                              tamanho <=
                              listaCaixas.reduce(
                                (acc, cur) =>
                                  acc + (cur.tipoCaixa?.tamanho ?? 1),
                                0
                              )
                            }
                          >
                            <AddIcon
                              color="primary"
                              fontSize="large"
                              sx={{
                                color:
                                  tamanho <= listaCaixas.length
                                    ? 'gray'
                                    : 'primary'
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Divider />

          <Dialog
            handleAnswerNo={() => {
              setShowConfirm(false)
            }}
            handleAnswerYes={() => {
              handleSubmit()
            }}
            messageText="Confirma o acondicionamento da caixa?"
            title="Atenção"
            openDialog={showConfirm}
          />
          <Dialog
            handleAnswerNo={() => {
              setShowDismiss(false)
            }}
            handleAnswerYes={() => {
              handleSubmitRemove()
            }}
            messageText="Confirma a remoção da caixa?"
            title="Atenção"
            openDialog={showDismiss}
          />
        </Box>
      </Box>
    </Modal>
  )
}
