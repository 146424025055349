import { IDescricaoTipoDocumento } from '../@types/IDescricaoTipoDocumento'

/**
 * Retorna a descrição de tipo de documento associado a um dos tipos de
 * documentos disponível
 * @param idTipoDocumento ID to tipo de documento
 * @returns Descrição do tipo de documento
 */
export const getTipoDocumento = (
  idTipoDocumento: string,
  listaDescricoesDocumentos: IDescricaoTipoDocumento[]
): string => {
  const tipoDocumento = listaDescricoesDocumentos.find(
    (tipoDocumento) =>
      tipoDocumento.tipoDocumento.id_tipo_documento === idTipoDocumento
  )

  return (
    `${tipoDocumento?.tipoDocumento.codigo} (${tipoDocumento?.tipoDocumento.descricao})` ??
    ''
  )
}
