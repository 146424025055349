import { useState } from 'react'
import { Box, Button, Stack } from '@mui/material'
import SaveIcon from '@mui/icons-material/CheckCircleOutlined'
import CancelIcon from '@mui/icons-material/DoDisturbAltOutlined'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import useToast from '../../../hooks/toast/useToast'
import { useEmpresa } from '../../../hooks/empresa/useEmpresa'
import { useUf } from '../../../hooks/uf/useUf'
import { useMunicipio } from '../../../hooks/municipio/useMunicipio'
import { useTipoPapelEmpresa } from '../../../hooks/tipoPapelEmpresa/useTipoPapelEmpresa'
import { IEmpresa } from '../../../services/empresa/types'
import { Input } from '../../../components/Form/Input'
import msg from '../../../utils/validationMessages'
import { Select } from '../../../components/Form/Select'
import { FormMode } from '../../../types/formMode'
import yup from '../../../types/yup.string.d'
import { masks } from '../../../utils/masks'
import { validation } from '../../../utils/validations'
import { ComponentGroup } from '../../../components/ComponentGroup'
import { Loading } from '../../../components/Loading'

const schema = yup
  .object({
    nome: yup.string().required(msg.REQUIRED).min(3, msg.MIN(3)),
    razao_social: yup.string().required(msg.REQUIRED),
    cnpj: yup.string().required(msg.REQUIRED).cnpj(msg.CNPJ),
    email: yup.string().required(msg.REQUIRED).email(msg.EMAIL),
    telefone1: yup.string().phone(msg.PHONE),
    telefone2: yup.string().phone(msg.PHONE),
    papel: yup.object({
      id_papel: yup.string().required(msg.REQUIRED)
    }),
    endereco: yup.object({
      logradouro: yup.string().required(msg.REQUIRED).min(4, msg.MIN(4)),
      id_municipio: yup.string().required(msg.REQUIRED),
      numero: yup.string().required(msg.REQUIRED),
      bairro: yup.string().required(msg.REQUIRED).min(3, msg.MIN(3)),
      cep: yup.string().required(msg.REQUIRED).min(8, msg.MIN(8)),
      latitude: yup.string().real({
        message: msg.REAL({ min: -90, max: 90 }),
        minValue: -90,
        maxValue: 90
      }),
      longitude: yup.string().real({
        message: msg.REAL({ min: -180, max: 180 }),
        minValue: -180,
        maxValue: 180
      }),
      referencia: yup.string(),
      complemento: yup.string()
    })
  })
  .required()

interface IForm {
  data: any
  formMode: FormMode
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

export function Form({ data, formMode, setFormMode }: IForm) {
  const [uf, setUf] = useState<string>(data?.endereco?.municipio?.estado?.sigla)
  const [id_municipio, setIdMunicipio] = useState<string>(
    data?.endereco?.id_municipio ?? ''
  )
  const [id_papel, setIdPapel] = useState<string>(data?.papel?.id_papel ?? '')
  const {
    register,
    handleSubmit,
    resetField,
    setValue,
    formState: { errors, isSubmitted }
  } = useForm<IEmpresa>({
    resolver: yupResolver(schema)
  })
  const { Toast } = useToast()
  const { mutateAsync: create } = useEmpresa.Create()
  const { mutateAsync: update } = useEmpresa.Update()
  const { data: ufs, isLoading } = useUf.ListAll()
  const { data: municipios } = useMunicipio.FindByUf(uf)
  const { data: papeis } = useTipoPapelEmpresa.ListAll()

  const onSubmit = async (formData: IEmpresa) => {
    formMode === 'CREATE'
      ? await Toast(create(formData))
      : await Toast(
          update({
            id_empresa: data.id_empresa,
            ...formData
          }),
          'UPDATED'
        )

    setFormMode('LIST')
  }

  function handlePhoneChange(value: string): string {
    const newValue = masks.phone({ value, mandatoryDDD: true })
    return newValue
  }

  function handlePhoneBlur(value: string): string {
    const newValue = validation.clearValue(value)
    if (newValue.length === 0) {
      return ''
    }
    return value
  }

  function handleCnpjChange(value: string): string {
    const newValue = masks.cnpj({ value })
    return newValue
  }

  function handleCepChange(value: string): string {
    const newValue = masks.cep({ value })
    return newValue
  }

  function handleMunicipioChange(id: string) {
    setIdMunicipio(id)
    setValue('endereco.id_municipio', id)
  }

  function handlePapelChange(id: string) {
    setIdPapel(id)
    setValue('papel.id_papel', id)
  }

  if (isLoading) return <Loading />

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} mt={4}>
      <Stack gap={2}>
        <Input
          label="Nome*"
          {...register('nome')}
          defaultValue={data?.nome}
          message={errors.nome?.message}
        />

        <Input
          label="Razão social*"
          {...register('razao_social')}
          defaultValue={data?.razao_social}
          message={errors.razao_social?.message}
        />

        <Stack gap={2} direction="row">
          <Input
            label="CNPJ*"
            {...register('cnpj', {
              onChange: (event) => {
                const { value } = event.target

                event.target.value = handleCnpjChange(value)
              }
            })}
            defaultValue={data?.cnpj}
            message={errors.cnpj?.message}
          />

          <Input label="Site" {...register('site')} defaultValue={data?.site} />
        </Stack>

        <Stack gap={2} direction="row">
          <Input
            label="E-mail*"
            {...register('email')}
            defaultValue={data?.email}
            message={errors.email?.message}
          />

          <Select
            label="Papel"
            placeholder="Selecione o papel"
            data={papeis}
            fields={['id_papel', 'descricao']}
            {...register('papel.id_papel')}
            value={id_papel}
            defaultValue={data?.id_papel}
            onChange={(event) => {
              handlePapelChange(String(event.target.value))
            }}
            message={errors.papel?.id_papel?.message}
          />
        </Stack>

        <Stack gap={2} direction="row">
          <Input
            label="Telefone 1"
            {...register('telefone1', {
              onChange: (event) => {
                const { value } = event.target
                event.target.value = handlePhoneChange(value)
              },
              onBlur: (event) => {
                const { value } = event.target
                event.target.value = handlePhoneBlur(value)
              }
            })}
            defaultValue={data?.telefone1}
            message={errors.telefone1?.message}
          />
          <Input
            label="Telefone 2"
            {...register('telefone2', {
              onChange: (event) => {
                const { value } = event.target
                event.target.value = handlePhoneChange(value)
              },
              onBlur: (event) => {
                const { value } = event.target
                event.target.value = handlePhoneBlur(value)
              }
            })}
            defaultValue={data?.telefone2}
            message={errors.telefone2?.message}
          />
        </Stack>

        <ComponentGroup title={'Endereço'}>
          <Stack gap={2} direction="row">
            <Select
              label="Estado"
              placeholder="Selecione o Estado"
              data={ufs}
              fields={['sigla', 'nome']}
              value={uf ?? ''}
              defaultValue={data?.endereco?.municipio?.estado?.sigla}
              message={isSubmitted && !uf ? msg.REQUIRED : ''}
              onChange={(e) => {
                setUf(String(e.target.value))
                setIdMunicipio('')
              }}
              onBlur={() => resetField('endereco.id_municipio')}
            />

            <Select
              label="Município"
              placeholder="Selecione o município"
              data={municipios}
              fields={['id_municipio', 'nome']}
              value={id_municipio ?? ''}
              {...register('endereco.id_municipio')}
              onChange={(event) =>
                handleMunicipioChange(String(event.target.value))
              }
              defaultValue={data?.endereco?.municipio?.id_municipio}
              message={errors.endereco?.id_municipio?.message}
            />
          </Stack>

          <Input
            label="Logradouro*"
            {...register('endereco.logradouro')}
            defaultValue={data?.endereco?.logradouro}
            message={errors.endereco?.logradouro?.message}
          />

          <Stack gap={2} direction="row">
            <Input
              label="Número*"
              {...register('endereco.numero')}
              defaultValue={data?.endereco?.numero}
              message={errors.endereco?.numero?.message}
            />

            <Input
              label="Bairro*"
              {...register('endereco.bairro')}
              defaultValue={data?.endereco?.bairro}
              message={errors.endereco?.bairro?.message}
            />

            <Input
              label="Cep*"
              {...register('endereco.cep', {
                onChange: (event) => {
                  const { value } = event.target
                  event.target.value = handleCepChange(value)
                }
              })}
              defaultValue={data?.endereco?.cep}
              message={errors.endereco?.cep?.message}
            />
          </Stack>

          <Input
            label="Complemento"
            {...register('endereco.complemento')}
            defaultValue={data?.endereco?.complemento}
          />

          <Input
            label="Referencia"
            {...register('endereco.referencia')}
            defaultValue={data?.endereco?.referencia}
          />

          <Stack gap={2} direction="row">
            <Input
              label="Latitude"
              {...register('endereco.latitude')}
              defaultValue={data?.endereco?.latitude}
              message={errors.endereco?.latitude?.message}
            />

            <Input
              label="Longitude"
              {...register('endereco.longitude')}
              defaultValue={data?.endereco?.longitude}
              message={errors.endereco?.longitude?.message}
            />
          </Stack>
        </ComponentGroup>
      </Stack>

      <Stack direction="row" gap={2} mt={3}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<CancelIcon />}
          onClick={() => setFormMode('LIST')}
        >
          Cancelar
        </Button>

        <Button
          type="submit"
          variant="contained"
          startIcon={<SaveIcon />}
          sx={{ width: '110px' }}
        >
          Salvar
        </Button>
      </Stack>
    </Box>
  )
}
