import { Box } from '@mui/material'
import { ReactNode } from 'react'

interface DashboardWidgetCardListRootProps {
  children: ReactNode
}

export function DashboardWidgetCardListRoot({
  children
}: DashboardWidgetCardListRootProps) {
  return (
    <Box
      sx={{
        display: 'grid',
        // gridTemplateColumns: '1fr 1fr 1fr',
        gridTemplateColumns: {
          sm: '1fr',
          md: '1fr 1fr',
          lg: '1fr 1fr 1fr',
          xl: '1fr 1fr 1fr 1fr'
          // md: '1fr',
          // lg: '1fr 1fr',
          // xl: '1fr 1fr 1fr'
        },
        padding: '1rem',
        gap: '1rem'
      }}
    >
      {children}
    </Box>
  )
}
