import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import { ICaixa } from '../../../../services/caixa/types'

interface TabelaCaixasProps {
  caixas: ICaixa[]
  handleRemoveClick: (id: string) => void
}

export function TabelaCaixas({ caixas, handleRemoveClick }: TabelaCaixasProps) {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: '#969696', fontWeight: 'bold' }}>
              Identificação
            </TableCell>
            <TableCell sx={{ color: '#969696', fontWeight: 'bold' }}>
              Tipo
            </TableCell>
            <TableCell sx={{ color: '#969696', fontWeight: 'bold' }}>
              Itens
            </TableCell>
            <TableCell sx={{ color: '#969696', fontWeight: 'bold' }}>
              Ações
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {caixas.map((caixa) => (
            <TableRow key={caixa.id_caixa}>
              <TableCell>{caixa.identificacao}</TableCell>
              <TableCell>{caixa.tipoCaixa?.descricao}</TableCell>
              <TableCell>
                {(caixa.documentos?.length ?? 0) +
                  (caixa.caixaFilha?.length ?? 0)}
              </TableCell>
              <TableCell>
                <Tooltip title="Excluir" arrow>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleRemoveClick(caixa.id_caixa!)}
                    sx={{ minWidth: '24px', padding: '6px' }}
                  >
                    <DeleteIcon />
                  </Button>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
