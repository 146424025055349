interface IObjeto {
  [key: string]: string
}

export function construirObjetoAPartirDeString(str: string) {
  const objeto: IObjeto = {}
  const pares = str.split(' - ') // Passo 1

  pares.forEach((par) => {
    const [chave, valor] = par.split(':').map((s) => s.trim()) // Passos 2, 3 e 4
    if (chave && valor) {
      // Verifica se ambos, chave e valor, existem
      objeto[chave] = valor // Passo 5
    }
  })

  return objeto // Passo 6
}

export function AnonimizarDado(campo: string, objeto: IObjeto) {
  // Verifica se a propriedade "NOME REQUERENTE" existe no objeto
  if (Object.prototype.hasOwnProperty.call(objeto, campo)) {
    // Substitui o valor da propriedade por "*******"
    objeto[campo] = '*******'
  }
}

export function DocumentoAnonimizado(objeto: IObjeto): string {
  AnonimizarDados(objeto)
  // Passo 1: Iterar sobre as chaves do objeto
  const pares = Object.keys(objeto).map((chave) => {
    // Passo 2: Mapear cada chave para uma string formatada "chave:valor"
    const valor = objeto[chave]
    return `${chave}:${valor}`
  })

  // Passo 3: Juntar todas as strings formatadas com "-"
  return pares.join('-')
}

export function AnonimizarDados(objeto: IObjeto) {
  // Anonimiza o valor da propriedade "NOME REQUERENTE"
  //AnonimizarDado('NOME REQUERENTE', objeto)
  //AnonimizarDado('NOME', objeto)
  AnonimizarDado('CPF', objeto)
  //AnonimizarDado('RG', objeto)
  //AnonimizarDado('NOME SERVIDOR', objeto)
  AnonimizarDado('CNPJ', objeto)
  //AnonimizarDado('NomeDoRequerente', objeto)
  //AnonimizarDado('NOME RAZAO SOCIAL', objeto)
}
