import { useCallback, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography
} from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import logo_easydoc from '../../assets/images/logo_easydoc.png'
import { useAuth } from '../../hooks/auth'
import { ShowMessage } from '../../components/ShowMessage'
import api from '../../services/api'
import { LOCAL_STORAGE_EMAIL } from '../../hooks/auth'
import { toast } from 'react-toastify'
import { GoogleLogin } from '@react-oauth/google'
import { ICPSignInButton } from '../../components/ICP/ICPSignInButton'

type Inputs = {
  username: string
  password: string
}

export function Login() {
  const { signIn, signInWithGoogle } = useAuth()
  const { signInNoAuth } = useAuth()
  const navigate = useNavigate()
  const { register, handleSubmit, getValues } = useForm<Inputs>()
  const [showPassword, setShowPassword] = useState(false)
  const [message, setMessage] = useState<string>()
  const [isDisable, setIsDisable] = useState(false)
  const [isDisableCode, setIsDisableCode] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [authCode, setAuthCode] = useState('')

  const responseMessage = async (response: any) => {
    await signInWithGoogle(response.credential)
  }
  const errorMessage = () => {
    //console.log(error)
    setMessage('Erro ao tentar logar com o Google')
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleNavigateToEmail = () => navigate('/email-recover')

  const onSubmit: SubmitHandler<Inputs> = useCallback(
    async ({ username, password }) => {
      setIsDisable(true)
      try {
        const { data: userHas2fa } = await api.get(
          `usuario/userHas2fa/${username}`
        )
        if (userHas2fa) {
          await signInNoAuth({ username, password })
          setIsModalOpen(true)
          await sendAuthEmail()
        } else {
          await signIn({ username, password })
        }
        setIsDisable(false)
      } catch (err: any) {
        setIsDisable(false)
        setMessage(err?.response?.data?.message)
      }
    },
    [signIn, signInNoAuth]
  )

  /*const onSubmit: SubmitHandler<Inputs> = useCallback(
    async ({ username, password }) => {
      setIsDisable(true)
      try {
        await signIn({ username, password })
        setIsDisable(false)
      } catch (err: any) {
        setIsDisable(false)
        setMessage(err?.response?.data?.message)
      }
    },
    [signIn]
  )*/

  const finalSignIn = async () => {
    const usr = getValues('username')
    const pwd = getValues('password')
    try {
      setIsDisableCode(true)
      await signIn({ username: usr, password: pwd })
      setIsDisableCode(false)
    } catch (err: any) {
      setIsDisableCode(false)
      setMessage(err?.response?.data?.message)
    }
  }

  async function sendAuthEmail() {
    setIsDisable(true)
    const email = localStorage.getItem(LOCAL_STORAGE_EMAIL)
    try {
      await api.post('/auth/2fa', { email: email })
      setIsDisable(false)
    } catch (err: any) {
      setMessage(err?.message)
      setIsDisable(false)
    }
  }

  async function submitAuthCode(event: React.FormEvent<HTMLInputElement>) {
    event.preventDefault()
    setIsDisableCode(true)
    const email = localStorage.getItem(LOCAL_STORAGE_EMAIL)
    try {
      await api.post('/auth/verify2FA', {
        email: email,
        code: authCode
      })
      finalSignIn()
      setIsDisableCode(false)
    } catch (err: any) {
      setMessage(err?.message)
      setIsDisableCode(false)
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 16,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <img src={logo_easydoc} width="60%" alt="logo X-Solution" />

        {!isModalOpen ? (
          <Typography variant="h4" mt={4}>
            Faça seu login
          </Typography>
        ) : (
          ''
        )}
        {isModalOpen ? (
          <Box
            sx={{
              height: '350px',
              width: '450px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Typography variant="h4" mt={4}>
              Autenticação de conta
            </Typography>
            <Typography variant="h6">
              Um código será enviado para o seu email
            </Typography>
            <Typography variant="h6">cadastrado no sistema</Typography>
            <Box
              component="form"
              onSubmit={submitAuthCode}
              sx={{ mt: 1, display: 'flex', flexDirection: 'column' }}
            >
              <TextField
                label="código"
                required
                fullWidth
                margin="normal"
                autoFocus
                onChange={(e) => setAuthCode(e.target.value)}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={isDisableCode}
                sx={{ mt: 3, mb: 2, fontSize: '14px' }}
              >
                Continuar
              </Button>

              <Button
                variant="contained"
                disabled={isDisableCode}
                sx={{
                  mt: 1,
                  mb: 2,
                  fontSize: '14px'
                }}
                onClick={() => {
                  sendAuthEmail()
                  toast.success('Código reenviado com sucesso!')
                }}
              >
                Reenviar Código
              </Button>

              <Grid container>
                <Grid item xs>
                  <Link
                    variant="body2"
                    onClick={() => {
                      setIsModalOpen(false)
                      setIsDisable(false)
                    }}
                    sx={{ cursor: 'pointer' }}
                  >
                    {'Voltar para o login'}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        ) : (
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ mt: 1 }}
          >
            <TextField
              {...register('username')}
              label="Username"
              required
              fullWidth
              margin="normal"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="Senha"
              type={showPassword ? 'text' : 'password'}
              autoComplete="current-password"
              {...register('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isDisable}
              sx={{ mt: 3, mb: 2, fontSize: '14px' }}
            >
              Entrar
            </Button>

            <ICPSignInButton />
            <GoogleLogin
              onSuccess={responseMessage}
              onError={errorMessage}
              width={450}
            />

            <Grid container>
              <Grid item xs>
                <Link
                  variant="body2"
                  onClick={handleNavigateToEmail}
                  sx={{ cursor: 'pointer' }}
                >
                  {'Esqueci minha senha'}
                </Link>
              </Grid>
              {/*<Grid item>
            <Link href="#" variant="body2">
              {'Não tem conta? cadastra-se'}
            </Link>
          </Grid>*/}
            </Grid>
          </Box>
        )}
      </Box>

      <ShowMessage
        hasError={!!message}
        message={message}
        onClose={() => setMessage(undefined)}
      />
    </Container>
  )
}
