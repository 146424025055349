import { MoreHoriz } from '@mui/icons-material'
import { useState } from 'react'

import {
  IAlocacoesData,
  ITipoAtividade
} from '../../../../services/dashboard/types'
import { defaultWidgetSummaryStyle } from '../lib/consts'

import { DashboardWidgetSummary } from '../../../../types/DashboardWidget/DashboardWidgetSummary'
import { DashboardWidgetSummaryBody } from '../../../../types/DashboardWidget/DashboardWidgetSummary/DashboardWidgetSummaryBody'
import { DashboardWidgetSummaryHeader } from '../../../../types/DashboardWidget/DashboardWidgetSummary/DashboardWidgetSummaryHeader'
import { DashboadWidgetSummaryHeaderToolbar } from '../../../../types/DashboardWidget/DashboardWidgetSummary/DashboardWidgetSummaryHeader/DashboadWidgetSummaryHeaderToolbar/index.tsx'
import { DashboardWidgetSummaryHeaderDatabar } from '../../../../types/DashboardWidget/DashboardWidgetSummary/DashboardWidgetSummaryHeader/DashboardWidgetSummaryHeaderDatabar'
import { TWidgetState } from '../../../../types/DashboardWidget/WidgetState'
import { DashboardWidget } from '../../../../types/DashboardWidget'
import { DashboardWidgetListHeader } from '../../../../types/DashboardWidget/DashboardWidgetList/DashboardWidgetListHeader'
import { ReactComponent as CloseButton } from '../../../../assets/images/botaoFechar.svg'
import { DashboardWidgetForm } from '../../../../types/DashboardWidget/DashboardWidgetForm'
import { TWidgetAtividade } from '../lib/@types/TWidgetAtividade'

interface WidgetAlocacaoProps {
  tipoAtividade: ITipoAtividade
  data: IAlocacoesData
  onStateChange: (tipoAtividade: TWidgetAtividade) => void
}

export function WidgetAlocacao({
  tipoAtividade,
  data,
  onStateChange
}: WidgetAlocacaoProps) {
  const widgetSummaryHeight = '400px'
  const widgetSummaryWidth = '400px'
  const widgetOtherWidth = '100%'
  const widgetOtherHeight = '840px'

  const processedData = data

  const [widgetState, setWidgetState] = useState<TWidgetState>('SUMMARY')
  const [maxWidgetHeight, setMaxWidgetHeight] =
    useState<string>(widgetSummaryHeight)
  const [maxWidgetWidth, setMaxWidgetWidth] =
    useState<string>(widgetSummaryWidth)

  const handleMoreClick = (): void => {
    setWidgetState('FORM')
    setMaxWidgetHeight(widgetOtherHeight)
    setMaxWidgetWidth(widgetOtherWidth)
    onStateChange(tipoAtividade)
  }

  const handleCloseFormClick = (): void => {
    setWidgetState('SUMMARY')
    setMaxWidgetHeight(widgetSummaryHeight)
    setMaxWidgetWidth(widgetSummaryWidth)
    onStateChange(undefined)
  }

  const chartData = {
    labels: processedData
      ? processedData.summaryData.chartData.map((dt) => dt.descricao)
      : [],
    datasets: [
      {
        label: 'Total de Itens',
        data: processedData
          ? processedData.summaryData.chartData.map((dt) => dt.quantidade)
          : [],
        backgroundColor: ['rgb(233, 44, 44)', '#219653'],
        hoverOffset: 4
      }
    ]
  }

  return (
    <>
      {widgetState === 'SUMMARY' && (
        <DashboardWidget.Root
          sx={{
            borderRadius: '20px',
            boxShadow:
              '0px 8px 16px -4px rgba(24, 39, 75, 0.08), 0px 6px 0px -6px rgba(0, 0, 0, 0.25)',
            backgroundColor: '#fff',
            margin: '2rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1rem',
            maxHeight: maxWidgetHeight,
            maxWidth: maxWidgetWidth,
            height: maxWidgetHeight,
            width: maxWidgetWidth
          }}
        >
          <DashboardWidgetSummary.Root sx={{ ...defaultWidgetSummaryStyle }}>
            <DashboardWidgetSummaryHeader.Root>
              <DashboadWidgetSummaryHeaderToolbar.Root>
                <DashboadWidgetSummaryHeaderToolbar.Type
                  type={tipoAtividade.identificacao}
                />
                <DashboadWidgetSummaryHeaderToolbar.Action
                  icon={MoreHoriz}
                  onClick={handleMoreClick}
                />
              </DashboadWidgetSummaryHeaderToolbar.Root>
              <DashboardWidgetSummaryHeader.Title
                title={processedData?.summaryData.totalItens.toString() ?? ''}
              />
              <DashboardWidgetSummaryHeaderDatabar.Root>
                {processedData?.summaryData.headerData.map((dt, index) => (
                  <DashboardWidgetSummaryHeaderDatabar.Data
                    key={`wRequisicoesData${index}`}
                    data={Math.floor(dt.quantidade).toString()}
                    title={dt.descricao}
                  />
                ))}
              </DashboardWidgetSummaryHeaderDatabar.Root>
            </DashboardWidgetSummaryHeader.Root>
            <DashboardWidgetSummaryBody.Root>
              <DashboardWidgetSummaryBody.Pie data={chartData} />
            </DashboardWidgetSummaryBody.Root>
          </DashboardWidgetSummary.Root>
        </DashboardWidget.Root>
      )}

      {widgetState === 'FORM' && (
        <DashboardWidget.Root
          sx={{
            borderRadius: '20px',
            boxShadow:
              '0px 8px 16px -4px rgba(24, 39, 75, 0.08), 0px 6px 0px -6px rgba(0, 0, 0, 0.25)',
            backgroundColor: '#fff',
            margin: '2rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1rem',
            maxHeight: maxWidgetHeight,
            maxWidth: maxWidgetWidth
          }}
        >
          <DashboardWidgetForm.Root
            sx={{ width: maxWidgetWidth, height: maxWidgetHeight }}
          >
            <DashboardWidgetForm.Header title={tipoAtividade.identificacao}>
              <DashboardWidgetForm.Actions>
                <DashboardWidgetListHeader.IconAction
                  icon={CloseButton}
                  onClick={() => handleCloseFormClick()}
                  tip={'Voltar para Dashboard'}
                  style={{
                    width: '40px',
                    height: '40px',
                    padding: 0,
                    margin: 0,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    borderRadius: '20px',
                    border: 0
                  }}
                />
              </DashboardWidgetForm.Actions>
            </DashboardWidgetForm.Header>
            <DashboardWidgetForm.Allocation
              listaCaixaNaoAlocadas={processedData?.tableData ?? []}
            />
          </DashboardWidgetForm.Root>
        </DashboardWidget.Root>
      )}
    </>
  )
}
