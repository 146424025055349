import { DefaultApi } from '../../utils/defaultApi'
import api from '../api'
import { IMunicipio } from './types'

const endpoint = '/municipio'
const resourceId = 'id_municipio'

class ServiceMunicipio extends DefaultApi<IMunicipio> {
  findByUf = async (uf?: string) => {
    const { data } = await api.get<IMunicipio[]>(`${this.endpoint}/uf/${uf}`)
    return data
  }
}

export const ApiMunicipio = new ServiceMunicipio(endpoint, resourceId)
