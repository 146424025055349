import {
  TableBody,
  TableRow,
  Table,
  TableCell,
  TableContainer,
  Stack,
  Typography,
  Box,
  Button,
  Tooltip
} from '@mui/material'

import LibraryAddIcon from '@mui/icons-material/LibraryAdd'
import DeleteIcon from '@mui/icons-material/Delete'
import { ReactNode } from 'react'

import { Space } from '../@types/Space'

interface DescriptorProps {
  title: string
  data: Space
  actionButton1?: () => void
  actionButton2?: () => void
  tipButton1?: string
  tipButton2?: string
  showButton1?: boolean
  showButton2?: boolean
}

export function Descriptor({
  title,
  data,
  showButton1 = false,
  showButton2 = false,
  actionButton1 = () => {},
  actionButton2 = () => {},
  tipButton1,
  tipButton2
}: DescriptorProps) {
  const getButton1 = () => {
    const btn: ReactNode = (
      <Button
        variant={'contained'}
        color={'success'}
        sx={{ minWidth: '24px', padding: '6px' }}
        onClick={actionButton1}
      >
        <LibraryAddIcon />
      </Button>
    )
    const fbtn: ReactNode = tipButton1 ? (
      <Tooltip title={tipButton1}>{btn}</Tooltip>
    ) : (
      btn
    )

    if (showButton1) {
      return fbtn
    }
    return <></>
  }

  const getButton2 = () => {
    const btn: ReactNode = (
      <Button
        variant={'contained'}
        color={'error'}
        sx={{ minWidth: '24px', padding: '6px' }}
        onClick={actionButton2}
      >
        <DeleteIcon />
      </Button>
    )
    const fbtn: ReactNode = tipButton2 ? (
      <Tooltip title={tipButton2}>{btn}</Tooltip>
    ) : (
      btn
    )
    if (showButton2) {
      return fbtn
    }
    return <></>
  }

  return (
    <Stack gap={2} padding={'12px'}>
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: 'bold'
        }}
      >
        {title}
      </Typography>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography fontWeight={'bold'}>{`Espaço Total`}</Typography>
              </TableCell>

              <TableCell>{data.total}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography
                  fontWeight={'bold'}
                >{`Espaços Ocupados`}</Typography>
              </TableCell>
              <TableCell>{data.busy}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography fontWeight={'bold'}>{`Espaços Livres`}</Typography>
              </TableCell>

              <TableCell>{data.free}</TableCell>
            </TableRow>
            {!!data.other && data.other}
          </TableBody>
        </Table>
      </TableContainer>
      {(showButton1 || showButton2) && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            width: '100%'
          }}
          gap={2}
        >
          {getButton1()}
          {getButton2()}
        </Box>
      )}
    </Stack>
  )
}
