import {
  Dialog,
  DialogTitle,
  Stack,
  Box,
  Button,
  Select,
  InputLabel,
  FormControl,
  MenuItem
} from '@mui/material'
import { ReactNode, useEffect, useState } from 'react'
import { useTipoCaixa } from '../../../../hooks/tipoCaixa/useTipoCaixa'
import { ITipoCaixa } from '../../../../services/tipoCaixa/types'
import { Loading } from '../../../../components/Loading'

interface PrateleiraChangePopupProps {
  title: string
  show: boolean
  data: ReactNode
  id_tipo_caixa: string
  close: () => void
  action: (id: string) => void
}

export function PrateleiraChangePopup({
  title,
  show,
  data,
  id_tipo_caixa,
  close,
  action
}: PrateleiraChangePopupProps) {
  const [idTipoCaixa, setIdTipoCaixa] = useState<string>(id_tipo_caixa)
  const { data: tiposCaixas, isLoading } = useTipoCaixa.ListAll()
  const [tiposCaixaFiltradas, setTiposCaixasFiltradas] = useState<ITipoCaixa[]>(
    []
  )

  useEffect(() => {
    if (tiposCaixas) {
      setTiposCaixasFiltradas(
        tiposCaixas.filter(
          (tpCaixa) => tpCaixa.id_tipo_caixa !== id_tipo_caixa
        ) ?? []
      )
    }
  }, [id_tipo_caixa, tiposCaixas])

  const handleCloseClick = () => {
    setIdTipoCaixa('')
    close()
  }

  const handleConfirmClick = () => {
    const tpCaixa = idTipoCaixa
    setIdTipoCaixa('')
    action(tpCaixa)
  }

  const handleTipoCaixaChange = (id: string) => {
    setIdTipoCaixa(id)
  }

  if (isLoading) <Loading />

  return (
    <Dialog open={show} fullWidth maxWidth={'xs'}>
      <DialogTitle
        sx={{
          backgroundColor: 'lightgray'
        }}
      >
        {title}
      </DialogTitle>
      <Stack
        display={'flex'}
        justifyContent={'space-between'}
        margin={'12px'}
        gap={2}
      >
        {data}
        <FormControl fullWidth>
          <InputLabel id="tipo-caixa-label">Tipo de Caixa</InputLabel>
          <Select
            labelId="tipo-caixa-label"
            id="tipo-caixa-select"
            value={idTipoCaixa}
            label="Tipo de Caixa"
            onChange={(event) =>
              handleTipoCaixaChange(String(event.target.value))
            }
          >
            {tiposCaixaFiltradas.length > 0 &&
              tiposCaixaFiltradas.map((tipoCaixa) => (
                <MenuItem
                  key={tipoCaixa.id_tipo_caixa}
                  value={tipoCaixa.id_tipo_caixa}
                  // selected={tipoCaixa.id_tipo_caixa === id_tipo_caixa}
                >
                  {tipoCaixa.descricao} ({tipoCaixa.tamanho}U)
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            width: '100%',
            marginTop: '12px'
          }}
          gap={2}
        >
          <Button
            onClick={() => {
              handleConfirmClick()
            }}
            variant="contained"
            color="primary"
            disabled={id_tipo_caixa === idTipoCaixa || idTipoCaixa === ''}
          >
            Confirmar Expansão
          </Button>
          <Button
            onClick={() => handleCloseClick()}
            variant="outlined"
            color="error"
          >
            Cancelar Expansão
          </Button>
        </Box>
      </Stack>
    </Dialog>
  )
}
