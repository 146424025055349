import { ApiRelatorio } from '../../../../services/relatorio'

import {
  IHandleReportSubmitProps,
  ReportForm
} from '../../../../components/ReportForm'
import { DocumentosEstatisticaRelatorio } from './report/DocumentosEstatisticaReport'

export function DocumentosEstatisticaPorEmpresaRelatorio() {
  async function handleSubmitForm({
    mode,
    format,
    id_usuario,
    filtros
  }: IHandleReportSubmitProps) {
    const { id_empresa } = filtros
    const estatisticasDocumentosData = await ApiRelatorio.getResume(id_empresa)

    if (format === 'pdf') {
      await DocumentosEstatisticaRelatorio({
        id_usuario: id_usuario,
        data: estatisticasDocumentosData,
        mode,
        filtros: {
          id_empresa
        }
      })
    }

    return estatisticasDocumentosData
  }
  return (
    <ReportForm
      title="Análise de Armazenamento Digital"
      handleReportSubmit={handleSubmitForm}
      mostrarSetor={false}
      mostrarEndereco={false}
      mostrarPeriodo={false}
      isEmpresaObrigatorio={false}
    />
  )
}
