import { Box, Button, Checkbox, FormControlLabel, Stack } from '@mui/material'
import SaveIcon from '@mui/icons-material/CheckCircleOutlined'
import CancelIcon from '@mui/icons-material/DoDisturbAltOutlined'
import { ITipoCaixa } from '../../../services/tipoCaixa/types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import useToast from '../../../hooks/toast/useToast'
import { useTipoCaixa } from '../../../hooks/tipoCaixa/useTipoCaixa'
import { Input } from '../../../components/Form/Input'
import msg from '../../../utils/validationMessages'
import { FormMode } from '../../../types/formMode'

const schema = yup.object({
  descricao: yup.string().required(msg.REQUIRED).min(4, msg.MIN(4)),
  tamanho: yup.number().typeError('Informe um número').min(0, msg.MIN(0)),
  acondiciona_caixas: yup.boolean().default(false),
  acondiciona_documentos: yup.boolean().default(true)
})

interface IForm {
  data: any
  formMode: FormMode
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

export function Form({ data, formMode, setFormMode }: IForm) {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ITipoCaixa>({ resolver: yupResolver(schema) })

  const { Toast } = useToast()
  const { mutateAsync: create } = useTipoCaixa.Create()
  const { mutateAsync: update } = useTipoCaixa.Update()

  const onSubmit = async (formData: ITipoCaixa) => {
    formMode === 'CREATE'
      ? await Toast(create(formData))
      : await Toast(
          update({
            id_tipo_caixa: data.id_tipo_caixa,
            ...formData
          }),
          'UPDATED'
        )

    setFormMode('LIST')
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} mt={4}>
      <Stack gap={2}>
        <Input
          label="Descrição*"
          {...register('descricao')}
          defaultValue={data?.descricao}
          message={errors.descricao?.message}
        />

        <Stack justifyContent={'space-between'} direction="row">
          <Input
            label="Tamanho (U)*"
            {...register('tamanho')}
            defaultValue={data?.tamanho}
            message={errors.tamanho?.message}
            fullWidth={false}
          />
          <FormControlLabel
            {...register('acondiciona_caixas')}
            control={<Checkbox defaultChecked={data?.acondiciona_caixas} />}
            label="Acondiciona Caixas"
          />

          <FormControlLabel
            {...register('acondiciona_documentos')}
            control={<Checkbox defaultChecked={data?.acondiciona_documentos} />}
            label="Acondiciona Documentos"
          />
        </Stack>
      </Stack>

      <Stack direction="row" gap={2} mt={3}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<CancelIcon />}
          onClick={() => setFormMode('LIST')}
        >
          Cancelar
        </Button>

        <Button
          type="submit"
          variant="contained"
          startIcon={<SaveIcon />}
          sx={{ width: '110px' }}
        >
          Salvar
        </Button>
      </Stack>
    </Box>
  )
}
