export interface ITipoCaixa {
  id_tipo_caixa?: string
  descricao: string
  tamanho: number
  acondiciona_caixas: boolean
  acondiciona_documentos: boolean
}

export const tipoCaixaVazia = {
  descricao: '',
  tamanho: 0,
  acondiciona_caixas: false,
  acondiciona_documentos: false
}
