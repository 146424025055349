import { Box, Button, Stack } from '@mui/material'
import SaveIcon from '@mui/icons-material/CheckCircleOutlined'
import CancelIcon from '@mui/icons-material/DoDisturbAltOutlined'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import msg from '../../../utils/validationMessages'
import useToast from '../../../hooks/toast/useToast'
import { FormMode } from '../../../types/formMode'
import { useEffect, useState } from 'react'
import { useCentroCusto } from '../../../hooks/financeiro/centroCusto/useCentroCusto'
import { useParceiro } from '../../../hooks/financeiro/parceiro/useParceiro'
import { useFormaPagamento } from '../../../hooks/financeiro/formaPagamento/useFormaPagamento'
import { removeNumericMask } from '../../../utils/formataNumeric'
import { IRecorrencia } from '../../../services/financeiro/recorrencia/types'
import { useRecorrencia } from '../../../hooks/financeiro/recorrencia/useRecorrencia'
import { Input } from '../../../components/Form/Input'
import { Select } from '../../../components/Form/Select'

const schema = yup
  .object({
    tipo_lancamento: yup.string().required(msg.REQUIRED),
    id_forma_pagamento: yup.string(),
    id_centro_custo: yup.string(),
    id_parceiro: yup.string().required(msg.REQUIRED),
    frequencia: yup.string().required(msg.REQUIRED),
    dt_inicio: yup
      .date()
      .transform((value, originalValue) => {
        return originalValue === '' ? null : value
      })
      .typeError('A data de início deve ser uma data válida')
      .required(msg.REQUIRED),
    dt_termino: yup
      .date()
      .transform((value, originalValue) => {
        return originalValue === '' ? null : value
      })
      .typeError('A data de término deve ser uma data válida')
      .required(msg.REQUIRED)
      .when(
        'dt_inicio',
        (inicio, yup) => inicio && yup.min(inicio, msg.DATEINTERVAL)
      ),
    valor: yup
      .number()
      .transform((_value, originalValue) => removeNumericMask(originalValue))
      .required(msg.REQUIRED)
      .typeError('O valor do lançamento deve ser preenchido corretamente'),
    num_documento: yup.string().required(msg.REQUIRED),
    historico: yup.string(),
    juros: yup
      .number()
      .nullable()
      .transform((_value, originalValue) => removeNumericMask(originalValue)),
    mora: yup
      .number()
      .nullable()
      .transform((_value, originalValue) => removeNumericMask(originalValue)),
    chave_pix: yup.string(),
    codigo_barras: yup.string()
  })
  .required()

interface IForm {
  data: IRecorrencia
  formMode: FormMode
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

const tiposLancamento = [
  { id: 'R', descricao: 'Receber' },
  { id: 'P', descricao: 'Pagar' }
]

const tiposFrequencia = [
  { id: 'MENSAL', descricao: 'Mensal' },
  { id: 'TRIMESTRAL', descricao: 'Trimestral' },
  { id: 'SEMESTRAL', descricao: 'Semestral' },
  { id: 'ANUAL', descricao: 'Anual' }
]

export function Form({ data, formMode, setFormMode }: IForm) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm<IRecorrencia>({
    resolver: yupResolver(schema)
  })

  const { Toast } = useToast()
  const { mutateAsync: create } = useRecorrencia.Create()
  const { mutateAsync: update } = useRecorrencia.Update()
  const [tipo_lancamento, setTipoLancamento] = useState<string>(
    data?.tipo_lancamento
  )
  const [isContaAReceber, setIsContaReceber] = useState<boolean>(false)

  useEffect(() => {
    setIsContaReceber(tipo_lancamento === 'R')
  }, [tipo_lancamento])

  const { data: listaCentroCustos } = useCentroCusto.ListAll()
  const { data: listaFormaPagamento } = useFormaPagamento.ListAll()
  const { data: listaParceiros } = isContaAReceber
    ? useParceiro.ListAllCliente()
    : useParceiro.ListAllFornecedor()

  const canEditFields = formMode === 'CREATE' || data?.lancamentos?.length === 0

  const onSubmit = async (formData: IRecorrencia) => {
    formMode === 'CREATE'
      ? await Toast(create(formData))
      : await Toast(
          update({
            id_recorrencia: data.id_recorrencia,
            ...formData
          }),
          'UPDATED'
        )

    setFormMode('LIST')
  }

  const handleTipoLancamento = (value: string) => {
    setTipoLancamento(value)
    setValue('tipo_lancamento', value)
    setValue('id_parceiro', '')
  }

  const dt_inicio = (data?.dt_inicio ?? '').toLocaleString().slice(0, 10)
  const dt_termino = (data?.dt_termino ?? '').toLocaleString().slice(0, 10)

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} mt={4}>
      <Stack gap={2}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2
          }}
        >
          <Select
            label={`Tipo Lançamento*`}
            placeholder={`Selecionte o tipo de Lançamento*`}
            data={tiposLancamento}
            fields={['id', 'descricao']}
            {...register('tipo_lancamento', {
              onChange: (event) => {
                const { value } = event.target

                event.target.value = handleTipoLancamento(value)
              }
            })}
            defaultValue={data?.tipo_lancamento}
            message={errors.tipo_lancamento?.message}
            readOnly={!canEditFields}
          />

          <Select
            label={`Frequência*`}
            placeholder={`Selecionte a Frequência*`}
            data={tiposFrequencia}
            fields={['id', 'descricao']}
            {...register('frequencia')}
            defaultValue={data?.frequencia}
            message={errors.frequencia?.message}
            readOnly={!canEditFields}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2
          }}
        >
          <Input
            label="Número Documento*"
            {...register('num_documento')}
            defaultValue={data?.num_documento}
            message={errors.num_documento?.message}
            readOnly={!canEditFields}
          />

          <Input
            type="date"
            label="Início*"
            {...register('dt_inicio')}
            InputLabelProps={{
              shrink: true
            }}
            defaultValue={dt_inicio}
            message={errors.dt_inicio?.message}
            readOnly={!canEditFields}
          />

          <Input
            type="date"
            label="Término*"
            {...register('dt_termino')}
            InputLabelProps={{
              shrink: true
            }}
            defaultValue={dt_termino}
            message={errors.dt_termino?.message}
            readOnly={!canEditFields}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2
          }}
        >
          <Select
            label={`${isContaAReceber ? 'Cliente' : 'Fornecedor'}*`}
            placeholder={`Selecione o ${
              isContaAReceber ? 'Cliente' : 'Fornecedor'
            }*`}
            data={listaParceiros}
            fields={['id_parceiro', 'nome']}
            {...register('id_parceiro')}
            defaultValue={data?.id_parceiro}
            message={errors.id_parceiro?.message}
            readOnly={!canEditFields}
          />

          <Select
            label="Forma de Pagamento"
            placeholder="Selecione a Forma de Pagamento"
            data={listaFormaPagamento}
            fields={['id_forma_pagamento', 'descricao']}
            {...register('id_forma_pagamento')}
            defaultValue={data?.id_forma_pagamento}
            message={errors.id_forma_pagamento?.message}
            readOnly={!canEditFields}
          />

          <Select
            label="Centro de Custo"
            placeholder="Selecione o Centro de Custo"
            data={listaCentroCustos}
            fields={['id_centro_custo', 'descricao']}
            {...register('id_centro_custo')}
            defaultValue={data?.id_centro_custo}
            message={errors.id_centro_custo?.message}
            readOnly={!canEditFields}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2
          }}
        >
          <Input
            label="Valor R$*"
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*[,]?[0-9]{0,2}',
              inputProps: { min: 0, step: '0.01' }
            }}
            {...register('valor')}
            defaultValue={data?.valor}
            message={errors.valor?.message}
          />

          <Input
            label="Juros %"
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*[,]?[0-9]{0,2}',
              inputProps: { min: 0, step: '0.01' }
            }}
            {...register('juros')}
            defaultValue={data?.juros}
            message={errors.juros?.message}
            readOnly={!canEditFields}
          />

          <Input
            label="Mora R$"
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*[,]?[0-9]{0,2}',
              inputProps: { min: 0, step: '0.01' }
            }}
            {...register('mora')}
            defaultValue={data?.mora}
            message={errors.mora?.message}
            readOnly={!canEditFields}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2
          }}
        >
          <Input
            label="Histórico"
            {...register('historico')}
            defaultValue={data?.historico}
            message={errors.historico?.message}
            multiline={true}
            minRows={3}
            readOnly={!canEditFields}
          />
        </Box>
      </Stack>

      <Stack direction="row" gap={2} mt={3}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<CancelIcon />}
          onClick={() => setFormMode('LIST')}
        >
          Cancelar
        </Button>

        <Button
          type="submit"
          variant="contained"
          startIcon={<SaveIcon />}
          sx={{ width: '110px' }}
        >
          Salvar
        </Button>
      </Stack>
    </Box>
  )
}
