import { IDocumento } from '../services/documento/types'

/**
 * Transforma as flags de privacidade em uma string do tipo P-S-C
 * @param documento Documento com as flags
 * @returns Representação das flags
 */
export function privacidadeToText(documento: IDocumento): string {
  let ret = ''
  const P = documento.dados_pessoais ? 'P' : ''
  const C = documento.dados_criancas_adolescentes ? 'C' : ''
  const S = documento.dados_pessoais_sensiveis ? 'S' : ''
  ret = P
  if (C !== '') {
    ret += ret !== '' ? '-' : ''
    ret += C
  }
  if (S !== '') {
    ret += ret !== '' ? '-' : ''
    ret += S
  }
  return ret
}
