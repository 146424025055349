import ChartIcon from '@mui/icons-material/AssessmentOutlined'
import DashboardIcon from '@mui/icons-material/Dashboard'
import ReportIcon from '@mui/icons-material/DocumentScanner'
import SettingsIcon from '@mui/icons-material/Settings'
import AddIcon from '@mui/icons-material/AddToDrive'
import MoveIcon from '@mui/icons-material/MoveUp'
import MoneyIcon from '@mui/icons-material/AttachMoney'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import TagIcon from '@mui/icons-material/LocalOfferOutlined'
import ImportIcon from '@mui/icons-material/CachedOutlined'
import ProcessIcon from '@mui/icons-material/AccountTreeOutlined'
import EasyIcon from '@mui/icons-material/ManageSearchOutlined'
import UserIcon from '@mui/icons-material/SupervisorAccount'
import { Permissions as pms } from '../../../types/permissions'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { SvgIconTypeMap } from '@mui/material'

interface IDefaultMenu {
  title: string
  path?: string
  permission?: pms
  // eslint-disable-next-line @typescript-eslint/ban-types
  icon?: OverridableComponent<SvgIconTypeMap<any, 'svg'>> & {
    muiName: string
  }

  // (props: any) => JSX.Element
}

export interface IMenu extends IDefaultMenu {
  // submenus?: IDefaultMenu[]
  submenus?: IMenu[]
}

export const menuList: IMenu[] = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    permission: pms.MENU_DASHBOARD,
    icon: DashboardIcon
  },
  {
    title: 'Gerenciar Requisições',
    permission: pms.MENU_GERENCIAR_REQUISICOES,
    icon: MoveIcon,
    submenus: [
      {
        title: 'Caixas',
        path: '/gerenciar-solicitacoes/caixas',
        permission: pms.CAIXAS
      },
      {
        title: 'Inventário',
        path: '/gerenciar-solicitacoes/inventario',
        permission: pms.INVENTARIO
      },
      {
        title: 'Requisições',
        path: '/gerenciar-solicitacoes/requisicao',
        permission: pms.REQUISICOES
      },
      {
        title: 'Detalhar Requisições',
        path: '/gerenciar-solicitacoes/status-solicitacoes',
        permission: pms.STATUS_REQUISICOES
      }
      // {
      //   title: 'Protocolos (pdf´s)',
      //   path: '/gerenciar-solicitacoes/protocolos-pdf',
      //   permission: pms.PROTOCOLOS_PDF
      // }
    ]
  },
  {
    title: 'Easy View',
    permission: pms.MENU_EASY_VIEW,
    icon: EasyIcon,
    submenus: [
      {
        title: 'Documentos',
        path: '/easy-view/documentos',
        permission: pms.DOCUMENTOS
      },
      {
        title: 'Drive Virtual',
        path: '/cadastros/drive-virtual',
        permission: pms.DRIVE_VIRTUAL
      },
      {
        title: 'Consulta de Imagens',
        path: '/easy-view/consulta-imagens-sgd',
        permission: pms.CONSULTA_IMAGEM_SGD
      }

      // {
      //   title: 'Pesquisar Documento',
      //   path: '/easy-view/pesquisar-documento',
      //   permission: pms.PESQUISAR_DOCUMENTO
      // }
    ]
  },
  {
    title: 'Workflow',
    permission: pms.MENU_WORKFLOW,
    icon: ProcessIcon,
    submenus: [
      // {
      //   title: 'Novos Processos',
      //   path: '/workflow/novos-processos',
      //   permission: pms.NOVOS_PROCESSOS
      // },
      // {
      //   title: 'Meus Processos',
      //   path: '/workflow/meus-processos',
      //   permission: pms.MEUS_PROCESSOS
      // },
      // {
      //   title: 'Gerenciar Processos',
      //   path: '/workflow/gerenciar-processos',
      //   permission: pms.GERENCIAR_PROCESSOS
      // },
      {
        title: 'Serviços',
        path: '/workflow/servicos',
        permission: pms.SERVICOS
      },
      {
        title: 'Tipos de Atividades',
        path: '/workflow/tipo-atividade',
        permission: pms.TIPOS_ATIVIDADES
      },
      {
        title: 'Fluxo de Serviços',
        path: '/workflow/fluxo-servico',
        permission: pms.FLUXO_SERVICOS
      }
    ]
  },
  {
    title: 'Relatórios',
    permission: pms.MENU_RELATORIOS,
    icon: ReportIcon,
    submenus: [
      {
        title: 'Análise de Armazenamento Digital',
        path: '/relatorios/documentos/estatistica',
        permission: pms.REL_ESTATISTICAS_ARMAZENAMENTO_EMPRESA
      },
      {
        title: 'Caixas / Documentos Movimentados por Serviço',
        path: '/relatorios/caixas/servico',
        permission: pms.REL_CAIXAS_MOVIMENTADAS_SERVICO
      },
      {
        title: 'Caixas Armazenadas por CD',
        path: '/relatorios/caixas/armazenadas/cd',
        permission: pms.REL_CAIXAS_ARMAZENADAS_CD
      },
      {
        title: 'Caixas/Documentos Armazenados por Empresa/Setor',
        path: '/relatorios/caixas/armazenadas/empresa',
        permission: pms.REL_CAIXAS_ARMAZENADAS_EMPRESA
      },
      {
        title: 'Setores',
        path: '/relatorios/empresa/setores/',
        permission: pms.REL_EMPRESA_SETORES
      },
      {
        title: 'Usuários',
        path: '/relatorios/empresa/usuarios/',
        permission: pms.REL_EMPRESA_USUARIOS
      },
      {
        title: 'Lotes não Devolvidos',
        path: '/relatorios/lotes/nao-devolvidos/',
        permission: pms.REL_LOTES_NAO_DEVOLVIDOS
      },
      {
        title: 'Lotes Arquivados',
        path: '/relatorios/lotes/arquivados/',
        permission: pms.REL_LOTES_ARQUIVADOS
      },
      {
        title: 'Prazo de Guarda',
        path: '/relatorios/caixas/prazo-guarda/',
        permission: pms.REL_PRAZO_GUARDA
      },
      {
        title: 'Inventário Caixas',
        path: '/relatorios/caixas/inventario/',
        permission: pms.REL_INVENTARIO_CAIXAS
      },
      {
        title: 'Inventário Documentos',
        path: '/relatorios/documentos/inventario/',
        permission: pms.REL_INVENTARIO_DOCUMENTOS
      },
      {
        title: 'Tipos Documentos / Total Imagens',
        path: '/relatorios/documentos/tipo-documento/',
        permission: pms.REL_TIPO_DOCUMENTO_PAGINA
      },
      {
        title: 'Tipos de Documentos',
        path: '/relatorios/tipos-documento/',
        permission: pms.REL_LOTES_ARQUIVADOS
      },
      {
        title: 'Árvores Documentais',
        path: '/relatorios/arvores-documentais/empresa/',
        permission: pms.REL_ARVORE_DOCUMENTAL
      },
      {
        title: 'Importação de Documentos',
        path: '/relatorios/documentos/importacao',
        permission: pms.REL_DADOS_IMPORTACAO
      },
      {
        title: 'Solicitações',
        path: '/relatorios/solicitacoes',
        permission: pms.REL_SOLICITACOES
      },
      {
        title: 'Centro de Documentação',
        path: '/relatorios/centro-documentacao',
        permission: pms.REL_CENTRO_DOCUMENTACAO
      },
      {
        title: 'Índices',
        path: '/relatorios/indices',
        permission: pms.REL_INDICES
      },
      {
        title: 'Descarte (TTD)',
        path: '/relatorios/descarte',
        permission: pms.REL_DESCARTE_TTD
      },
      {
        title: 'Protocolos',
        path: '/relatorios/protocolos',
        permission: pms.REL_PROTOCOLOS
      }
    ]
  },
  {
    title: 'Cadastros',
    permission: pms.MENU_CADASTROS,
    icon: AddIcon,
    submenus: [
      // { title: 'Função', path: '/cadastros/funcao', permission: pms.FUNCAO },
      // {
      //   title: 'Grupo de Acesso',
      //   path: '/cadastros/grupo-de-acesso',
      //   permission: pms.GRUPO_ACESSO
      // },
      {
        title: 'Centro de Documentação',
        path: '/cadastros/centro-documentacao',
        permission: pms.CENTRO_DOCUMENTACAO
      },
      // {
      //   title: 'Endereçamento',
      //   path: '/cadastros/enderecamento',
      //   permission: pms.ENDERECAMENTO
      // },
      {
        title: 'Empresas',
        path: '/cadastros/empresas',
        permission: pms.EMPRESAS
      },
      { title: 'Setores', path: '/cadastros/setores', permission: pms.SETORES },
      {
        title: 'Árvores Documentais',
        path: '/cadastros/arvores-documentais',
        permission: pms.ARVORE_DOCUMENTAL
      },
      {
        title: 'Contratos',
        path: '/cadastros/contratos',
        permission: pms.CONTRATOS
      },
      {
        title: 'Tipo Documentos',
        path: '/cadastros/tipo-documentos',
        permission: pms.TIPO_DOCUMENTOS
      },
      {
        title: 'Tipo de Temporalidade',
        path: '/cadastros/tipo-temporalidade',
        permission: pms.TIPO_TEMPORALIDADE
      },
      {
        title: 'Tipo Caixas',
        path: '/cadastros/tipo-caixas',
        permission: pms.TIPO_CAIXAS
      },
      { title: 'Índices', path: '/cadastros/indices', permission: pms.INDICES },
      {
        title: 'Prazo Envio',
        path: '/cadastros/prazo-envio',
        permission: pms.PRAZO_ENVIO
      },
      {
        title: 'Veículos',
        path: '/cadastros/veiculos',
        permission: pms.VEICULOS
      },
      { title: 'Bancos', path: '/cadastros/bancos', permission: pms.BANCOS }
    ]
  },
  {
    title: 'Importação',
    icon: ImportIcon,
    permission: pms.MENU_IMPORTACAO,
    submenus: [
      {
        title: 'Documentos',
        path: '/importar/documentos',
        permission: pms.IMPORTAR_DOCUMENTOS
      },
      {
        title: 'Volumes',
        path: '/importar/volumes',
        permission: pms.IMPORTAR_VOLUMES
      }
    ]
  },
  {
    title: 'Gerador de Etiquetas',
    icon: TagIcon,
    path: '/gerador-etiquetas',
    permission: pms.MENU_GERENCIADOR_ETIQUETAS
  },
  {
    title: 'Conta Corrente',
    icon: PointOfSaleIcon,
    permission: pms.MENU_CONTA_CORRENTE,
    submenus: [
      {
        title: 'Bancos',
        path: '/conta-corrente/banco',
        permission: pms.CC_BANCO
      },
      {
        title: 'Contas',
        path: '/conta-corrente/conta',
        permission: pms.CC_CONTA
      },
      {
        title: 'Eventos',
        path: '/conta-corrente/evento-conta-corrente',
        permission: pms.CC_EVENTO
      },
      {
        title: 'Movimentos',
        path: '/conta-corrente/movimento-conta-corrente',
        permission: pms.CC_MOVIMENTACAO
      }
    ]
  },

  {
    title: 'Faturamento',
    icon: MoneyIcon,
    permission: pms.MENU_FATURAMENTO,
    submenus: [
      {
        title: 'Forma de Pagamento',
        path: '/financeiro/forma-pagamento',
        permission: pms.FAT_FORMA_PAGAMENTO
      },
      {
        title: 'Categorias de Clientes',
        path: '/financeiro/categoria/cliente',
        permission: pms.FAT_CATEGORIA_CLIENTE
      },
      {
        title: 'Clientes',
        path: '/financeiro/cadastro/cliente',
        permission: pms.FAT_CLIENTE
      },
      {
        title: 'Receitas',
        path: '/financeiro/receita',
        permission: pms.FAT_RECEITA
      },
      {
        title: 'Contas à Receber',
        path: '/financeiro/lancamento/receber',
        permission: pms.FAT_CONTAS_A_RECEBER
      },
      {
        title: 'Categorias de Fornecedores',
        path: '/financeiro/categoria/fornecedor',
        permission: pms.FAT_CATEGORIA_FORNECEDOR
      },
      {
        title: 'Fornecedores',
        path: '/financeiro/cadastro/fornecedor',
        permission: pms.FAT_FORNECEDOR
      },
      {
        title: 'Despesas',
        path: '/financeiro/despesa',
        permission: pms.FAT_DESPESA
      },
      {
        title: 'Contas à Pagar',
        path: '/financeiro/lancamento/pagar',
        permission: pms.FAT_CONTAS_A_PAGAR
      },
      {
        title: 'Centros de Custo',
        path: '/financeiro/centro-custo',
        permission: pms.FAT_CENTRO_CUSTO
      },
      {
        title: 'Recorrência',
        path: '/financeiro/recorrencia',
        permission: pms.FAT_RECORRENCIA_LANCAMENTO
      },
      {
        title: 'Rel. Baixa de Lançamentos à Pagar',
        path: '/financeiro/baixa-lancamento-pagar',
        permission: pms.REL_FAT_BAIXA_FINANCEIRO_PAGAR
      },
      {
        title: 'Rel. Baixa de Lançamentos à Receber',
        path: '/financeiro/baixa-lancamento-receber',
        permission: pms.REL_FAT_BAIXA_FINANCEIRO_RECEBER
      },
      {
        title: 'Rel. Baixa de Lançamentos Receber x Pagar',
        path: '/financeiro/baixa-lancamento-receber-pagar',
        permission: pms.REL_FAT_BAIXA_FINANCEIRO_RECEBER_PAGAR
      },
      {
        title: 'Rel. Lançamentos à Pagar',
        path: '/financeiro/previsao-lancamento-pagar',
        permission: pms.REL_FAT_PREVISAO_FINANCEIRO_PAGAR
      },
      {
        title: 'Rel. Lançamentos à Receber',
        path: '/financeiro/previsao-lancamento-receber',
        permission: pms.REL_FAT_PREVISAO_FINANCEIRO_RECEBER
      },
      {
        title: 'Rel. Lançamentos à Receber x Pagar',
        path: '/financeiro/previsao-lancamento-receber-pagar',
        permission: pms.REL_FAT_PREVISAO_FINANCEIRO_RECEBER_PAGAR
      }
      /*{
        title: 'Relatórios Financeiros',
        path: '/faturamento/relatorios',
        permission: pms.FAT_RELATORIOS_FINANCEIROS
      }
           {
        title: 'Borderôs',
        path: '/faturamento/borderos',
        permission: pms.FAT_BORDEROS
      },
      {
        title: 'Caixa',
        path: '/faturamento/caixa',
        permission: pms.FAT_CAIXA
      },
      {
        title: 'Contas à Pagar',
        path: '/faturamento/contas-a-pagar',
        permission: pms.FAT_CONTAS_A_PAGAR
      },
      {
        title: 'Contas à Receber',
        path: '/faturamento/contas-a-receber',
        permission: pms.FAT_CONTAS_A_RECEBER
      },
      {
        title: 'Bancos',
        path: '/faturamento/bancos',
        permission: pms.FAT_BANCOS
      },
      {
        title: 'Tesouraria',
        path: '/faturamento/tesouraria',
        permission: pms.FAT_TESOURARIA
      },
      {
        title: 'Fechamento',
        path: '/faturamento/fechamento',
        permission: pms.FAT_FECHAMENTO
      }*/
    ]
  },

  {
    title: 'Auditoria',
    icon: ChartIcon,
    permission: pms.MENU_AUDITORIA,
    submenus: [
      {
        title: 'Logs de Usuários',
        path: '/auditoria/logs-usuarios',
        permission: pms.LOGS_USUARIOS
      },
      {
        title: 'Gerenciar Compartilhamentos',
        path: '/auditoria/gerenciar-compartilhamentos',
        permission: pms.COMPARTILHAR_DOCUMENTO
      },
      {
        title: 'Configuração de Armazenamento',
        path: '/auditoria/configuracao-de-armazenamento',
        permission: pms.MENU_AUDITORIA
      },
      {
        title: 'Consentimentos (lgpd)',
        path: '/auditoria/consentimentos',
        permission: pms.CONSENTIMENTOS_LGPD
      },
      {
        title: 'Movimentação Documentos (lgpd)',
        path: '/auditoria/movimentacao-documentos',
        permission: pms.MOVIMENTACAO_DOCUMENTOS_LGPD
      }
    ]
  },
  {
    title: 'Administração de Usuários',
    permission: pms.MENU_ADMINISTRACAO_USUARIOS,
    icon: UserIcon,
    submenus: [
      {
        title: 'Usuários',
        path: '/administracao-usuarios/usuarios',
        permission: pms.USUARIOS
      },
      {
        title: 'Contratos Permitidos',
        path: '/administracao-usuarios/contratos-permitidos',
        permission: pms.CONTRATO_PERMITIDO
      },
      {
        title: 'Setores Permitidos',
        path: '/administracao-usuarios/setores-permitidos',
        permission: pms.SETOR_PERMITIDO
      },
      {
        title: 'Permissões',
        path: '/administracao-usuarios/permissoes',
        permission: pms.PERMISSOES
      },
      {
        title: 'Controle de Bloqueios',
        path: '/administracao-usuarios/controle-de-bloqueios',
        permission: pms.CONTROLE_BLOQUEIOS
      }
    ]
  },
  {
    title: 'Parâmetros',
    icon: SettingsIcon,
    permission: pms.MENU_PARAMETROS,
    submenus: [
      {
        title: 'Configuração de Estratégia de Login',
        path: '/parametros/configuracao-de-login',
        permission: pms.MENU_PARAMETROS
      },
      {
        title: 'Usuário',
        path: '/parametros/usuario',
        permission: pms.PARAMETRO_USUARIO
      },
      {
        title: 'Centro Documentação',
        path: '/parametros/centro',
        permission: pms.PARAMETRO_CENTRO_DOCUMENTACAO
      },
      {
        title: 'Endereçamento',
        path: '/parametros/enderecamento',
        permission: pms.PARAMETRO_ENDERECAMENTO
      },
      {
        title: 'Solicitações',
        path: '/parametros/solicitacoes',
        permission: pms.PARAMETRO_SOLICITACOES
      },
      {
        title: 'Workflow',
        path: '/parametros/Workflow',
        permission: pms.PARAMETRO_WORKFLOW
      },
      {
        title: 'Cadastro',
        path: '/parametros/cadastro',
        permission: pms.PARAMETRO_CADASTRO
      },
      {
        title: 'Importar Lotes',
        path: '/parametros/importar',
        permission: pms.PARAMETRO_IMPORTAR_LOTES
      },
      {
        title: 'Gerador de Etiquetas',
        path: '/parametros/gerador',
        permission: pms.PARAMETRO_GERADOR_ETIQUETAS
      },
      {
        title: 'Relatórios',
        path: '/parametros/relatorios',
        permission: pms.PARAMETRO_RELATORIOS
      },
      {
        title: 'Easy View',
        path: '/parametros/easy',
        permission: pms.PARAMETRO_EASY_VIEW
      },
      {
        title: 'Faturamento',
        path: '/parametros/faturamento',
        permission: pms.PARAMETRO_FATURAMENTO
      },
      {
        title: 'Dashboard',
        path: '/parametros/dashboard',
        permission: pms.PARAMETRO_DASHBOARD
      },
      {
        title: 'Auditoria',
        path: '/parametros/auditoria',
        permission: pms.PARAMETRO_AUDITORIA
      }
    ]
  }
]
