export type ColorType = 'success' | 'info' | 'warning' | 'secondary'
export const color = (estado: string, addMain = true) => {
  let newColor = 'warning'
  switch (estado) {
    case 'COMPLETO':
      newColor = 'success'
      break
    case 'EXECUTANDO':
      newColor = 'info'
      break
    case 'PENDENTE':
      newColor = 'warning'
      break
    case 'CANCELADO':
      newColor = 'secondary'
      break
  }

  if (addMain) {
    newColor = `${newColor}.main`
  }

  return newColor
}

export function capitalizeFirstLetter(str: string): string {
  if (!str) return str
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}
