import { IPrateleira } from '../../../../services/prateleira/types'

interface PrateleiraButtonProps {
  prateleira: IPrateleira
  handleMouseClick: (
    event: React.MouseEvent<HTMLElement>,
    posicao: IPrateleira
  ) => void
  openMenu: boolean
  enderecoEstante: string
}

export function PrateleiraButton({
  prateleira,
  handleMouseClick,
  openMenu,
  enderecoEstante
}: PrateleiraButtonProps) {
  const handleMouseOver = (event: React.MouseEvent<HTMLElement>) => {
    event.currentTarget.style.filter = 'brightness(80%)'
  }
  const handleMouseLeave = (event: React.MouseEvent<HTMLElement>) => {
    event.currentTarget.style.filter = 'brightness(100%)'
  }
  return (
    <tr id={`ID-${enderecoEstante}.${prateleira.identificacao}`}>
      <td>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#F1F3F4',
            margin: '2px 0 ',
            width: '36px',
            height: '36px',
            cursor: 'pointer'
          }}
          aria-controls={openMenu ? 'MENU-PRATELEIRA' : undefined}
          aria-haspopup="true"
          aria-expanded={openMenu ? 'true' : undefined}
          onClick={(event) => {
            handleMouseClick(event, prateleira)
          }}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseLeave}
        >
          {prateleira.identificacao}
        </div>
      </td>
    </tr>
  )
}
