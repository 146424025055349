import { DefaultApi } from '../../utils/defaultApi'
import api from '../api'
import {
  IDashboard,
  IRequisicoesDashboard,
  ISummary,
  ITipoAtividadeDashboard
} from './types'

const endpoint = '/dashboard'
const resourceId = ''

class ServiceDashboard extends DefaultApi<IDashboard> {
  public getSumarioCaixas = async (): Promise<ISummary> => {
    const { data } = await api.get<ISummary>(`${this.endpoint}/sumario-caixas/`)
    return data
  }

  public getRequisicoes = async (): Promise<IRequisicoesDashboard> => {
    const { data } = await api.get<IRequisicoesDashboard>(
      `${this.endpoint}/requisicoes/`
    )
    return data
  }

  public getTiposAtividade = async (): Promise<ITipoAtividadeDashboard> => {
    const { data } = await api.get<ITipoAtividadeDashboard>(
      `${this.endpoint}/tipo-atividade/`
    )
    return data
  }
}

const apiDashboard = new ServiceDashboard(endpoint, resourceId)

export const ApiDashboard = {
  ...apiDashboard,
  getSumarioCaixas: apiDashboard.getSumarioCaixas,
  getRequisicoes: apiDashboard.getRequisicoes,
  getTiposAtividades: apiDashboard.getTiposAtividade
}
