import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useMensagem } from '../../../../hooks/mensagem/useMensagem'
import { useUsuario } from '../../../../hooks/usuario/useUsuario'

interface unreadMessagesProps {
  reqId: string
}

const UnreadMessagesDisplay = ({ reqId }: unreadMessagesProps) => {
  const { data: usuario } = useUsuario.FindByUsername()
  const { data: mensagens } = useMensagem.findByRequisicao(reqId)
  const [hasUnreadMessages, setHasUnreadMessages] = useState<boolean>(false)

  useEffect(() => {
    const localData = localStorage.getItem('@easydoc:user')
    let idData: any
    if (localData) {
      idData = JSON.parse(localData)
    }
    if (mensagens) {
      const hasUnread = mensagens.some(
        (msg) => !msg.lida && msg.id_remetente !== usuario?.id_usuario
      )
      mensagens.map((msg) => {
        if (
          msg.id_destinatario === idData.id_usuario ||
          usuario?.pessoa.setor.empresa?.nome === 'X-SOLUTION'
        ) {
          setHasUnreadMessages(hasUnread)
        }
      })
    }
  }, [mensagens])

  return (
    <>
      {hasUnreadMessages && (
        <Box
          sx={{
            width: '8px',
            height: '8px',
            bgcolor: 'red',
            borderRadius: '8px',
            position: 'absolute',
            left: 35,
            bottom: 30
          }}
        ></Box>
      )}
    </>
  )
}

export default UnreadMessagesDisplay
