import { useMemo, useState } from 'react'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import {
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  Divider,
  Pagination,
  //Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Modal,
  Typography,
  FormControl,
  InputLabel,
  Select,
  Menu,
  MenuItem
} from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
// import AddIcon from '@mui/icons-material/AddCircle'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import DownloadIcon from '@mui/icons-material/FileDownloadOutlined'
import PreviewIcon from '@mui/icons-material/DocumentScannerOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import QrCode2Icon from '@mui/icons-material/QrCode2'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import CardMembershipIcon from '@mui/icons-material/CardMembership'
import ShareIcon from '@mui/icons-material/Share'
import TaskIcon from '@mui/icons-material/Task'
import UndoIcon from '@mui/icons-material/Undo'
import useToast from '../../../hooks/toast/useToast'
import { QRCode } from './QRCode'
import { FormMode } from '../../../types/formMode'
import { Loading } from '../../../components/Loading'
import { useDocumento } from '../../../hooks/documento/useDocumento'
import { ApiDocumento } from '../../../services/documento'
import { Permissions } from '../../../types/permissions'
import { hasPermission } from '../../../utils/hasPermission'
//import { formata } from '../../../utils/formataData'
import { useUsuario } from '../../../hooks/usuario/useUsuario'
import { AdvancedFindAllParams } from '../../../services/caixa/types'
import { SearchBox } from '../../../components/SearchBox'
import { useArvoreDocumental } from '../../../hooks/arvoreDcoumental/useArvoreDocumental'
import { truncateString } from '../../../utils/truncateString'
import { Clear, Merge, Refresh } from '@mui/icons-material'
import { IDocumento } from '../../../services/documento/types'
import { getCodigo } from '../../../utils/getCodigo'
import { IContrato } from '../../../services/contrato/types'
import { ISetor } from '../../../services/setor/types'
import { Dialog } from '../../../components/Dialog'
import { DocumentTree } from './components/DocumentTree'
import { hasAccess } from '../../../utils/hasAccess'
import { Input } from '../../../components/Form/Input'
import { Tag } from '../../../components/Tag'

interface IForm {
  setData: (value: React.SetStateAction<any>) => void
  setFormMode: (value: React.SetStateAction<FormMode>) => void
  setShowEditMetadata: (value: React.SetStateAction<boolean>) => void
  contratos?: IContrato[]
  setores?: ISetor[]
}

export function List({
  setFormMode,
  setData,
  setShowEditMetadata,
  contratos = [],
  setores = []
}: IForm) {
  const [isLoadingFile, setIsLoadingFile] = useState(false)
  const [showDismiss, setShowDismiss] = useState<boolean>(false)
  const [showDismissUpdate, setShowDismissUpdate] = useState<boolean>(false)
  const [showDismissReactivate, setShowDismissReactivate] =
    useState<boolean>(false)
  const [showDigitalSign, setShowDigitalSign] = useState<boolean>(false)
  const [currentDocId, setCurrentDocId] = useState<string>()
  const [itensSelecionados, setItensSelecionados] = useState<string[]>([])
  const [mergedDocumentPath, setMergedDocumentPath] = useState('')
  const [selectedOrder, setSelectedOrder] = useState<string>('Data')
  const [email, setEmail] = useState<string>('')
  const [requirePassword, setRequirePassword] = useState<string>('')
  const [validUntil, setValidUntil] = useState<string>('')
  const [selectedSharedDoc, setSelectedSharedDoc] = useState<string>()
  const [disabledShareButton, setDisabledShareButton] = useState<boolean>(false)
  const [menuType, setMenuType] = useState<'menuTag' | 'menuItem' | null>(null)
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedItem, setSelectedItem] = useState<IDocumento | null>(null)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()
  /*const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null)
  const [inputFieldValue, setInputFieldValue] = useState('')*/
  const [showTree, setShowTree] = useState<boolean>(false)
  const [showShare, setShowShare] = useState<boolean>(false)
  const searchParams2AdvanceSearchParams = (
    search: URLSearchParams
  ): AdvancedFindAllParams => {
    let newAdv: AdvancedFindAllParams = {}
    if (search.get('busca') !== null) {
      newAdv = {
        ...newAdv,
        busca: search.get('busca') ?? undefined
      }
    }

    if (search.get('id_tipo_documento') !== null) {
      newAdv = {
        ...newAdv,
        id_tipo_documento: search.get('id_tipo_documento') ?? undefined
      }
    }
    if (search.get('dados_pessoais') !== null) {
      newAdv = {
        ...newAdv,
        dados_pessoais: search.get('dados_pessoais') ?? undefined
      }
    }
    if (search.get('dados_pessoais_sensiveis') !== null) {
      newAdv = {
        ...newAdv,
        dados_pessoais_sensiveis:
          search.get('dados_pessoais_sensiveis') ?? undefined
      }
    }
    if (search.get('dados_criancas_adolescentes') !== null) {
      newAdv = {
        ...newAdv,
        dados_criancas_adolescentes:
          search.get('dados_criancas_adolescentes') ?? undefined
      }
    }

    if (search.get('endereco') !== null) {
      newAdv = {
        ...newAdv,
        endereco: search.get('endereco') ?? undefined
      }
    }

    return newAdv
  }

  const [searchParams, setSearchParams] = useSearchParams()

  const [advancedSearchParams, setAdvancedSearchParam] =
    useState<AdvancedFindAllParams>(
      searchParams2AdvanceSearchParams(searchParams)
    )

  const { Toast, ToastError, ToastSuccess, ToastInfo } = useToast()
  const { data: usuario } = useUsuario.FindByUsername()

  const setSearchCriteria = (newSearchParams: AdvancedFindAllParams) => {
    setSearchParams({ ...searchParams, ...newSearchParams })
    setAdvancedSearchParam(newSearchParams)
  }

  const page = useMemo(() => {
    return Number(searchParams.get('page') ?? 1)
  }, [searchParams])

  const { mutateAsync: remove } = useDocumento.Remove()
  const { mutateAsync: reactivate } = useDocumento.Reactivate()
  const { mutateAsync: sign } = useDocumento.Sign()
  const { mutateAsync: updateTag } = useDocumento.UpdateTag()
  const { mutateAsync: updateStatistics } = useDocumento.UpdateStatistics()
  const { data: records, isLoading } = useDocumento.AdvancedFindAll(
    page,
    advancedSearchParams,
    selectedOrder
  )

  const { data: tiposDocumentos, isLoading: isLoadingTiposDocumentos } =
    useArvoreDocumental.FindTiposDocumentos()

  // const handleNewForm = () => {
  //   setFormMode('CREATE')
  // }

  const canSelectDocument = (item: IDocumento) => {
    return item?.path && item.path.endsWith('.pdf')
  }

  const handleRemove = async (id: string) => {
    if (hasPermission(usuario?.permissoes, Permissions.EXCLUIR_DOCUMENTO)) {
      await Toast(remove(id), 'REMOVED')
      setSearchParams({ page: '1' })
    }

    setCurrentDocId(undefined)
    setShowDismiss(false)
  }

  const handleReactivate = async (id: string) => {
    if (hasPermission(usuario?.permissoes, Permissions.EXCLUIR_DOCUMENTO)) {
      await Toast(reactivate(id), 'UPDATED')
      setSearchParams({ page: '1' })
    }

    setCurrentDocId(undefined)
    setShowDismissReactivate(false)
  }

  const canUpdateStatistics = usuario?.permissoes.includes(
    Permissions.ATUALIZAR_ESTATISTICA_DOCUMENTO
  )

  const handleUpdateStatitics = async () => {
    if (canUpdateStatistics) {
      await Toast(
        updateStatistics(),
        'UPDATED',
        'Atualização de Documentos em Processamento'
      )
    }
    setShowDismissUpdate(false)
    navigate('/dashboard')
  }

  const handleMergedDocument = async () => {
    try {
      setIsLoadingFile(true)
      if (mergedDocumentPath) {
        await ApiDocumento.deleteMergedDocument(mergedDocumentPath)
      }
      if (
        hasPermission(
          usuario?.permissoes,
          Permissions.DOWNLOAD_DOCUMENTO_DIGITAL
        )
      ) {
        const { mergedFile } = await ApiDocumento.mergeDocuments(
          itensSelecionados
        )

        if (mergedFile) {
          setMergedDocumentPath(mergedFile)
        }
      }
    } catch (error) {
      ToastError(
        `Erro ao tentar combinar documentos: ${(error as any).message}`
      )
    }
    setIsLoadingFile(false)
  }

  const handleClearSelectedItems = async () => {
    if (mergedDocumentPath) {
      await ApiDocumento.deleteMergedDocument(mergedDocumentPath)
      setMergedDocumentPath('')
    }
    setItensSelecionados([])
  }

  const handleDownloadMergedDocument = async () => {
    try {
      setIsLoadingFile(true)
      const url = await ApiDocumento.downloadMergedDocument(mergedDocumentPath)
      if (
        hasPermission(
          usuario?.permissoes,
          Permissions.DOWNLOAD_DOCUMENTO_DIGITAL
        )
      ) {
        const fileName = url.split('/').pop()
        const anchor = document.createElement('a')
        anchor.href = url
        anchor.setAttribute('download', fileName!)
        document.body.appendChild(anchor)
        anchor.click()
        document.body.removeChild(anchor)
        anchor.remove()
        setMergedDocumentPath('')
      }
    } catch (error) {
      ToastError(`Erro ao tentar efetuar download`)
      console.error(error)
    }
    setIsLoadingFile(false)
  }

  const handleDigitalFile = async (
    id_contrato: string,
    filePath: string,
    action: 'download' | 'preview',
    id_documento: string
  ) => {
    try {
      setIsLoadingFile(true)
      const url = await ApiDocumento.digitalFile(
        id_contrato,
        filePath,
        id_documento
      )

      if (action === 'preview') {
        if (
          hasPermission(usuario?.permissoes, Permissions.VISUALIZAR_DOCUMENTO)
        )
          window.open(url, '_blank', 'popup=yes')
      } else {
        if (
          hasPermission(
            usuario?.permissoes,
            Permissions.DOWNLOAD_DOCUMENTO_DIGITAL
          )
        ) {
          const fileName = url.split('/').pop()
          const anchor = document.createElement('a')
          anchor.href = url
          anchor.setAttribute('download', fileName!)
          document.body.appendChild(anchor)
          anchor.click()
          document.body.removeChild(anchor)
          anchor.remove()
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoadingFile(false)
    }
  }

  {
    /*const handleFindQRCodeFile = async (
    id_documento: string,
    action: 'download' | 'preview'
  ) => {
    try {
      setIsLoadingFile(true)
      const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9'
      const url = await ApiDocumento.QRCodeDigitalFile(id_documento, token)

      if (action === 'preview') {
        if (
          hasPermission(usuario?.permissoes, Permissions.VISUALIZAR_DOCUMENTO)
        )
          window.open(url)
      } else {
        if (
          hasPermission(
            usuario?.permissoes,
            Permissions.DOWNLOAD_DOCUMENTO_DIGITAL
          )
        ) {
          const fileName = url.split('/').pop()
          const anchor = document.createElement('a')
          anchor.href = url
          anchor.setAttribute('download', fileName!)
          document.body.appendChild(anchor)
          anchor.click()
          document.body.removeChild(anchor)
          anchor.remove()
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoadingFile(false)
    }
  }*/
  }

  const tagOptions = [
    '-',
    'ANDAMENTO',
    'CONCLUÍDO',
    'REVISÃO',
    'APROVADO',
    'REJEITADO',
    'CONFIDENCIAL',
    'INTERNO',
    'PÚBLICO',
    'AUDITORIA',
    'TREINAMENTO',
    'PROCEDIMENTO',
    'ANÁLISE',
    'AÇÃO REQUERIDA',
    'ASSINADO',
    'ASSINAR',
    'ARQUIVADO',
    'ARQUIVAR',
    'URGENTE',
    'PRIORIDADE ALTA',
    'PRIORIDADE MÉDIA',
    'PRIORIDADE BAIXA'
  ]

  const handleUpdateTag = async (formData: {
    id_documento: string
    tag: string
  }) => {
    await Toast(updateTag(formData), 'UPDATED', 'Tag atualizada com sucesso!')
    handleCloseMenu()
  }

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLElement>,
    type: 'menuTag' | 'menuItem',
    itemOrIndex?: any
  ) => {
    setAnchorEl(event.currentTarget)
    setMenuType(type)
    setSelectedItem(itemOrIndex || null)
    setSelectedIndex(null)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
    setMenuType(null)
    setSelectedItem(null)
    setSelectedIndex(null)
  }

  const handleUploadDigitalFile = (data: any) => {
    setData(data)
    setFormMode('EDIT')
    setShowEditMetadata(false)
  }

  const handleEdit = (data: any) => {
    data.edit = true
    setData(data)
    setFormMode('EDIT')
    setShowEditMetadata(true)
  }

  const handleItemSelection = (item: string) => {
    // Verifica se o item já está na lista de selecionados
    if (itensSelecionados.includes(item)) {
      // Se estiver, remove-o da lista
      const novosItensSelecionados = itensSelecionados.filter(
        (selectedItem) => selectedItem !== item
      )
      setItensSelecionados(novosItensSelecionados)
    } else {
      // Se não estiver, adiciona-o à lista
      setItensSelecionados([...itensSelecionados, item])
    }
  }

  const handleShowShareClick = (documento: string) => {
    setSelectedSharedDoc(documento)
    setShowShare(true)
  }

  const handleModalShareClose = () => {
    setEmail('')
    setRequirePassword('')
    setValidUntil('')
    setSelectedSharedDoc('')
    setShowShare(false)
  }

  const handleShowTreeClick = (document: IDocumento) => {
    setCurrentDocId(document.id_documento)
    setShowTree(true)
  }

  const handleModalClose = () => {
    setCurrentDocId('')
    setShowTree(false)
  }

  const handleDigitalSign = (document: IDocumento) => {
    setCurrentDocId(document.id_documento)
    setShowDigitalSign(true)
  }

  const handleSignDocument = async () => {
    try {
      await sign(currentDocId ?? '')
      ToastSuccess(
        'Documento foi encaminhado para adição de assinatura digital'
      )
    } catch (e) {
      ToastError(`Erro ao tentar enviar arquivo para ser assinado`)
      console.error(e)
    }
    setCurrentDocId('')
    setShowDigitalSign(false)
  }

  const handleCheckSignature = async (document: IDocumento) => {
    try {
      const result = await ApiDocumento.getSignatureStatus(
        document.id_documento ?? ''
      )
      ToastInfo(result)
    } catch (e) {
      ToastError(`Erro ao verificar estado da assinatura digital`)
      console.error(e)
    }
  }

  const handleShareFile = async () => {
    setDisabledShareButton(true)
    const data = {
      email,
      requirePassword,
      expireAt: validUntil ?? '',
      selectedSharedDoc,
      usuario: usuario?.id_usuario
    }

    const url = window.location.href ?? ''

    try {
      await ApiDocumento.shareDocuments(data, url)
      ToastSuccess(
        'Um link para acessar o documento foi encaminhado para o Email descrito'
      )
    } catch (e: any) {
      ToastError(e.message)
      console.error(e)
    }
    setEmail('')
    setRequirePassword('')
    setValidUntil('')
    setSelectedSharedDoc('')
    setShowShare(false)
    setDisabledShareButton(false)
  }

  const isEmailValid = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const isDateValid = (date: any) => {
    let validation = false
    if (
      new Date(date).setUTCHours(23, 59, 59) - Date.now() < 7776000000 &&
      new Date(date).setUTCHours(23, 59, 59) >= Date.now()
    ) {
      validation = true
    }
    return validation
  }

  /*const handleOpenQRCodeModal = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget)
  }*/

  /*const handleReadQRCode = () => {
    const position = records?.data.findIndex((obj) => {
      const splited = obj?.id_documento?.split('-')
      const lastDigits = splited?.[splited.length - 1]

      return lastDigits?.toUpperCase() === inputFieldValue
    })

    if (position !== undefined && records?.data && records.data[position]) {
      const foundRecord = records.data[position]
      const id_documento = foundRecord.id_documento || ''
      handleFindQRCodeFile(id_documento, 'preview')
      // eslint-disable-next-line no-empty
    } else {
    }

    setAnchorEl(null)
  }*/

  if (isLoading || isLoadingTiposDocumentos) return <Loading />

  return (
    <Box>
      {isLoadingFile && (
        <Loading message="Aguarde... arquivo sendo processado..." />
      )}
      <SearchBox
        setAdvancedSearchParams={setSearchCriteria}
        descricaoBusca={
          'Descrição/Estado atual/Metadados de documentos/Código de Requisição/ID do Documento/ID da Caixa/Tag'
        }
        tipoDocumentoExibir={true}
        tipoDocumentos={tiposDocumentos}
        idTipoDocumento={advancedSearchParams.id_tipo_documento}
        busca={advancedSearchParams.busca}
        dadosPessoaisExibir={true}
        dadosPessoais={advancedSearchParams.dados_pessoais}
        dadosCriancasAdolescentesExibir={true}
        dadosCriancasAdolescentes={
          advancedSearchParams.dados_criancas_adolescentes
        }
        dadosPessoaisSensiveisExibir={true}
        dadosPessoaisSensiveis={advancedSearchParams.dados_pessoais_sensiveis}
        dadosAtivosExibir={true}
        dadosAtivos={advancedSearchParams.dados_ativos}
        pesquisarPdfOcr={advancedSearchParams.pesquisar_pdf_ocr}
        estadoAssinaturaExibir={true}
        estadoAssinatura={advancedSearchParams.estado_assinatura}
        enderecoExibir={true}
        endereco={advancedSearchParams.endereco}
        contratos={contratos}
        idContrato={advancedSearchParams.id_contrato}
        dadosContratoExibir={true}
        setores={setores}
        idSetor={advancedSearchParams.id_setor}
        dadosSetorExibir={true}
      />

      <Divider />

      {(itensSelecionados.length > 0 ||
        mergedDocumentPath ||
        canUpdateStatistics) && (
        <Stack direction={'row'} gap={3}>
          {canUpdateStatistics && (
            <Button
              variant="contained"
              color="success"
              startIcon={<Refresh />}
              onClick={() => setShowDismissUpdate(true)}
              sx={{ float: 'right', my: '12px' }}
            >
              Atualizar Estatísticas
            </Button>
          )}
          {itensSelecionados.length > 0 && (
            <>
              <Button
                variant="contained"
                startIcon={<Merge />}
                onClick={handleMergedDocument}
                sx={{ float: 'right', my: '12px' }}
              >
                Combinar
              </Button>

              <Button
                onClick={handleClearSelectedItems}
                color="secondary"
                variant="outlined"
                startIcon={<Clear />}
                style={{ fontWeight: 'bold' }}
                sx={{ float: 'right', my: '12px' }}
              >
                Limpar
              </Button>
            </>
          )}

          {mergedDocumentPath && (
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              onClick={handleDownloadMergedDocument}
              sx={{ float: 'right', my: '12px' }}
            >
              Download
            </Button>
          )}
        </Stack>
      )}
      {/*<Button
        variant="contained"
        startIcon={<QrCode2Icon />}
        onClick={handleOpenQRCodeModal}
        sx={{ float: 'right', my: '12px', mr: '16px' }}
      >
        Ler QR Code
          </Button>*/}

      {/*<Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <TextField
          label="ID do documento"
          variant="outlined"
          fullWidth
          value={inputFieldValue}
          onChange={(e) => setInputFieldValue(e.target.value)}
        />
        <Button onClick={handleReadQRCode} variant="contained" color="primary">
          Pesquisar
        </Button>
      </Popover>*/}

      <TableContainer
        component={Box}
        border={1}
        borderRadius={2}
        borderColor="#f2f2f2"
      >
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
              <TableCell></TableCell>
              <TableCell
                sx={{ fontWeight: 'bold', fontSize: '14px', cursor: 'pointer' }}
                onClick={() => {
                  selectedOrder === 'Ordem Alfabética'
                    ? setSelectedOrder('Ordem AlfabéticaInv')
                    : setSelectedOrder('Ordem Alfabética')
                }}
              >
                Descrição
                {selectedOrder === 'Ordem Alfabética' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>
              <TableCell
                sx={{ fontWeight: 'bold', fontSize: '14px', cursor: 'pointer' }}
                onClick={() => {
                  selectedOrder === 'Setor'
                    ? setSelectedOrder('SetorInv')
                    : setSelectedOrder('Setor')
                }}
              >
                Setor
                {selectedOrder === 'Setor' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>
              <TableCell
                sx={{ fontWeight: 'bold', fontSize: '14px', cursor: 'pointer' }}
                onClick={() => {
                  selectedOrder === 'Estado do Documento'
                    ? setSelectedOrder('Estado do DocumentoInv')
                    : setSelectedOrder('Estado do Documento')
                }}
              >
                Estado
                {selectedOrder === 'Estado do Documento' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>
              {/*<TableCell
                sx={{ fontWeight: 'bold', fontSize: '14px', cursor: 'pointer' }}
                onClick={() => {
                  selectedOrder === 'Data'
                    ? setSelectedOrder('DataInv')
                    : setSelectedOrder('Data')
                }}
              >
                Data do Dcumento
                {selectedOrder === 'Data' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>*/}
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Endereço / Caixa
              </TableCell>
              <TableCell width="12px" />
              <TableCell width="12px" />
              <TableCell width="12px" />
              <TableCell width="12px" />
              <TableCell width="12px" />
              {/* <TableCell width="12px" /> */}
              {/* <TableCell width="12px" /> */}
              {/* <TableCell width="12px" /> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {records?.data?.map((item) => (
              <TableRow
                key={item.id_documento}
                hover
                sx={{
                  bgcolor: item.caixa?.id_caixa_container ? '#eef' : '#fff'
                }}
              >
                <TableCell sx={{ width: '10px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => {
                          handleItemSelection(
                            item.id_documento ? item.id_documento : ''
                          )
                        }}
                        checked={
                          item.id_documento
                            ? itensSelecionados.includes(item.id_documento)
                            : false
                        }
                      />
                    }
                    disabled={!canSelectDocument(item)}
                    label=""
                  />
                </TableCell>
                <TableCell
                  sx={{
                    minHeight: '86px',
                    minWidth: '240px'
                  }}
                >
                  <span>{truncateString(item.descricao, 120)}</span>
                  <br></br>
                  {hasAccess(
                    usuario?.permissoes,
                    Permissions.EXIBIR_ID_CAIXA
                  ) && (
                    <span style={{ color: '#999' }}>{item?.id_documento}</span>
                  )}
                </TableCell>
                <TableCell sx={{ width: '120px' }}>
                  {truncateString(item.caixa?.setor?.nome, 25)}
                </TableCell>
                <TableCell sx={{ width: '120px' }}>{item.estado}</TableCell>
                {/*<TableCell sx={{ width: '140px' }}>
                  {formata(
                    item.created_at?.toString() ?? '',
                    'dd/MM/yyyy HH:mm'
                  )}
                </TableCell>*/}
                <TableCell>
                  {item.caixa?.endereco ? (
                    <>
                      {item.caixa?.endereco} {' / '}
                      {getCodigo(item.caixa.id_caixa!).substring(0, 9)}
                      <strong style={{ fontSize: 13 }}>
                        {getCodigo(item.caixa.id_caixa!).substring(9)}
                      </strong>
                    </>
                  ) : (
                    '-'
                  )}
                </TableCell>
                <TableCell>
                  {item?.tag && item.tag.toString().length > 1 ? (
                    <Tag
                      label={item.tag}
                      onClick={(event) =>
                        handleOpenMenu(event, 'menuTag', item)
                      }
                    />
                  ) : (
                    <Tooltip title="Atribuir tag" arrow>
                      <Button
                        variant="outlined"
                        color="primary"
                        sx={{ minWidth: '24px', padding: '6px' }}
                        onClick={(event) =>
                          handleOpenMenu(event, 'menuTag', item)
                        }
                      >
                        <LocalOfferIcon />
                      </Button>
                    </Tooltip>
                  )}

                  <Menu
                    anchorEl={anchorEl}
                    open={
                      open && menuType === 'menuTag' && selectedItem !== null
                    }
                    onClose={handleCloseMenu}
                    sx={{
                      padding: '8px',
                      maxHeight: '300px'
                    }}
                  >
                    {tagOptions.map((option, index) => (
                      <MenuItem
                        key={index}
                        selected={selectedIndex === index}
                        onClick={() => {
                          if (
                            selectedItem &&
                            typeof selectedItem.id_documento === 'string'
                          ) {
                            handleUpdateTag({
                              id_documento: selectedItem.id_documento,
                              tag: option
                            })
                          }
                        }}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </TableCell>
                {/* <TableCell>
                  <Tooltip title="Upload Documento Digital" arrow>
                    <Button
                      variant="outlined"
                      sx={{ minWidth: '24px', padding: '6px' }}
                      disabled={
                        isLoadingFile ||
                        !usuario?.permissoes.includes(
                          Permissions.UPLOAD_DOCUMENTO_DIGITAL
                        )
                      }
                      onClick={() => handleUploadDigitalFile(item)}
                    >
                      <FileUploadOutlinedIcon />
                    </Button>
                  </Tooltip>
                </TableCell> */}

                <TableCell>
                  <Tooltip title="Exibir em árvore" arrow>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        handleShowTreeClick(item)
                      }}
                      sx={{ minWidth: '24px', padding: '6px' }}
                    >
                      <AccountTreeIcon />
                    </Button>
                  </Tooltip>
                </TableCell>

                <TableCell>
                  {!item.path ? (
                    <Button
                      variant="outlined"
                      disabled={!item.path || isLoadingFile}
                      sx={{ minWidth: '24px', padding: '6px' }}
                      onClick={() =>
                        handleDigitalFile(
                          item.id_contrato,
                          item.path!,
                          'preview',
                          item.id_documento ? item.id_documento : ''
                        )
                      }
                    >
                      <PreviewIcon />
                    </Button>
                  ) : (
                    <Tooltip title="Visualizar" arrow>
                      <Button
                        variant="outlined"
                        sx={{ minWidth: '24px', padding: '6px' }}
                        disabled={isLoadingFile}
                        onClick={() =>
                          handleDigitalFile(
                            item.id_contrato,
                            item.path!,
                            'preview',
                            item.id_documento ? item.id_documento : ''
                          )
                        }
                      >
                        <PreviewIcon />
                      </Button>
                    </Tooltip>
                  )}
                </TableCell>

                <TableCell>
                  {!item.path ? (
                    <Button
                      variant="outlined"
                      disabled={!item.path || isLoadingFile}
                      sx={{ minWidth: '24px', padding: '6px' }}
                      onClick={() =>
                        handleDigitalFile(
                          item.id_contrato,
                          item.path!,
                          'download',
                          item.id_documento ? item.id_documento : ''
                        )
                      }
                    >
                      <DownloadIcon />
                    </Button>
                  ) : (
                    <Tooltip title="Download" arrow>
                      <Button
                        variant="outlined"
                        disabled={isLoadingFile}
                        sx={{ minWidth: '24px', padding: '6px' }}
                        onClick={() =>
                          handleDigitalFile(
                            item.id_contrato,
                            item.path!,
                            'download',
                            item.id_documento ? item.id_documento : ''
                          )
                        }
                      >
                        <DownloadIcon />
                      </Button>
                    </Tooltip>
                  )}
                </TableCell>
                {/* {item.estado_assinatura && (
                  <TableCell>
                    {!item.path ? (
                      <Button
                        variant="outlined"
                        disabled={!item.path || isLoadingFile}
                        sx={{ minWidth: '24px', padding: '6px' }}
                      >
                        <TaskIcon />
                      </Button>
                    ) : (
                      <Tooltip title="Verificar estado de assinatura" arrow>
                        <Button
                          variant="outlined"
                          disabled={
                            isLoadingFile ||
                            !usuario?.permissoes.includes(
                              Permissions.ASSINAR_DOCUMENTO_DIGITAL
                            )
                          }
                          sx={{ minWidth: '24px', padding: '6px' }}
                          onClick={() => handleCheckSignature(item)}
                        >
                          <TaskIcon />
                        </Button>
                      </Tooltip>
                    )}
                  </TableCell>
                )}
                {!item.estado_assinatura && (
                  <TableCell>
                    {!item.path ? (
                      <Button
                        variant="outlined"
                        disabled={!item.path || isLoadingFile}
                        sx={{ minWidth: '24px', padding: '6px' }}
                        onClick={() =>
                          handleDigitalFile(
                            item.id_contrato,
                            item.path!,
                            'download',
                            item.id_documento ? item.id_documento : ''
                          )
                        }
                      >
                        <CardMembershipIcon />
                      </Button>
                    ) : (
                      <Tooltip title="Assinatura Digital" arrow>
                        <Button
                          variant="outlined"
                          disabled={
                            isLoadingFile ||
                            !usuario?.permissoes.includes(
                              Permissions.ASSINAR_DOCUMENTO_DIGITAL
                            )
                          }
                          sx={{ minWidth: '24px', padding: '6px' }}
                          onClick={() => handleDigitalSign(item)}
                        >
                          <CardMembershipIcon />
                        </Button>
                      </Tooltip>
                    )}
                  </TableCell>
                )} */}

                {/* <TableCell>
                  {!item.path ? (
                    <Button
                      variant="outlined"
                      disabled={!item.path || isLoadingFile}
                      sx={{ minWidth: '24px', padding: '6px' }}
                      onClick={() => {
                        QRCode({
                          data: {
                            nome: item.descricao,
                            id_documento: getCodigo(item.id_documento ?? '')
                          }
                        })
                      }}
                    >
                      <QrCode2Icon />
                    </Button>
                  ) : (
                    <Tooltip title="Gerar QR Code" arrow>
                      <Button
                        variant="outlined"
                        disabled={isLoadingFile}
                        sx={{ minWidth: '24px', padding: '6px' }}
                        onClick={() => {
                          QRCode({
                            data: {
                              nome: item.descricao,
                              id_documento: getCodigo(item.id_documento ?? '')
                            }
                          })
                        }}
                      >
                        <QrCode2Icon />
                      </Button>
                    </Tooltip>
                  )}
                </TableCell> */}
                {/* {usuario?.permissoes.includes(Permissions.EDITAR_DOCUMENTO)} */}

                {/* <TableCell>
                  {usuario?.permissoes.includes(
                    Permissions.EDITAR_DOCUMENTO
                  ) ? (
                    <Tooltip title="Editar" arrow>
                      <Button
                        variant="outlined"
                        color="primary"
                        disabled={isLoadingFile}
                        onClick={() => {
                          handleEdit(item)
                        }}
                        sx={{ minWidth: '24px', padding: '6px' }}
                      >
                        <EditIcon />
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      variant="outlined"
                      color="success"
                      disabled={true}
                      sx={{ minWidth: '24px', padding: '6px' }}
                    >
                      <EditIcon />
                    </Button>
                  )}
                </TableCell> */}

                {/* <TableCell>
                  <Tooltip title="Compartilhar Documento" arrow>
                    <Button
                      variant="outlined"
                      sx={{ minWidth: '24px', padding: '6px' }}
                      disabled={
                        isLoadingFile ||
                        !usuario?.permissoes.includes(
                          Permissions.COMPARTILHAR_DOCUMENTO
                        )
                      }
                      onClick={() =>
                        handleShowShareClick(
                          item.id_documento ? item.id_documento : ''
                        )
                      }
                    >
                      <ShareIcon />
                    </Button>
                  </Tooltip>
                </TableCell> */}

                <TableCell>
                  <Tooltip title="Mais Opções">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={(event) =>
                        handleOpenMenu(event, 'menuItem', item)
                      }
                      sx={{ minWidth: '24px', padding: '6px' }}
                    >
                      <MoreVertIcon />
                    </Button>
                  </Tooltip>
                  <Menu
                    anchorEl={anchorEl}
                    open={
                      open && menuType === 'menuItem' && selectedItem === item
                    }
                    onClose={handleCloseMenu}
                  >
                    <Tooltip title="Upload Documento Digital" arrow>
                      <MenuItem
                        onClick={() => handleUploadDigitalFile(selectedItem!)}
                        disabled={
                          isLoadingFile ||
                          !usuario?.permissoes.includes(
                            Permissions.UPLOAD_DOCUMENTO_DIGITAL
                          )
                        }
                      >
                        <FileUploadOutlinedIcon
                          color="primary"
                          sx={{ marginRight: '8px' }}
                        />
                        Upload Documento Digital
                      </MenuItem>
                    </Tooltip>
                    {selectedItem?.estado_assinatura ? (
                      !selectedItem?.path ? (
                        <MenuItem
                          disabled={!selectedItem?.path || isLoadingFile}
                        >
                          <TaskIcon sx={{ marginRight: '8px' }} />
                          Verificar estado de assinatura
                        </MenuItem>
                      ) : (
                        <Tooltip title="Verificar estado de assinatura" arrow>
                          <MenuItem
                            onClick={() => handleCheckSignature(selectedItem)}
                            disabled={
                              isLoadingFile ||
                              !usuario?.permissoes.includes(
                                Permissions.ASSINAR_DOCUMENTO_DIGITAL
                              )
                            }
                          >
                            <TaskIcon sx={{ marginRight: '8px' }} />
                            Verificar estado de assinatura
                          </MenuItem>
                        </Tooltip>
                      )
                    ) : !selectedItem?.path ? (
                      <MenuItem
                        disabled={!selectedItem?.path || isLoadingFile}
                        onClick={() =>
                          handleDigitalFile(
                            selectedItem!.id_contrato,
                            selectedItem!.path!,
                            'download',
                            selectedItem?.id_documento ?? ''
                          )
                        }
                      >
                        <CardMembershipIcon
                          color="primary"
                          sx={{ marginRight: '8px' }}
                        />
                        Assinatura Digital
                      </MenuItem>
                    ) : (
                      <Tooltip title="Assinatura Digital" arrow>
                        <MenuItem
                          disabled={
                            isLoadingFile ||
                            !usuario?.permissoes.includes(
                              Permissions.ASSINAR_DOCUMENTO_DIGITAL
                            )
                          }
                          onClick={() => handleDigitalSign(selectedItem)}
                        >
                          <CardMembershipIcon
                            color="primary"
                            sx={{ marginRight: '8px' }}
                          />
                          Assinatura Digital
                        </MenuItem>
                      </Tooltip>
                    )}
                    {!selectedItem?.path ? (
                      <MenuItem disabled={isLoadingFile || !selectedItem?.path}>
                        <QrCode2Icon
                          color="disabled"
                          sx={{ marginRight: '8px' }}
                        />
                        Gerar QR Code
                      </MenuItem>
                    ) : (
                      <Tooltip title="Gerar QR Code" arrow>
                        <MenuItem
                          disabled={isLoadingFile}
                          onClick={() => {
                            QRCode({
                              data: {
                                nome: selectedItem?.descricao ?? '',
                                id_documento: getCodigo(
                                  selectedItem?.id_documento ?? ''
                                )
                              }
                            })
                          }}
                        >
                          <QrCode2Icon
                            color="primary"
                            sx={{ marginRight: '8px' }}
                          />
                          Gerar QR Code
                        </MenuItem>
                      </Tooltip>
                    )}

                    {usuario?.permissoes.includes(
                      Permissions.COMPARTILHAR_DOCUMENTO
                    ) && (
                      <Tooltip title="Compartilhar Documento" arrow>
                        <MenuItem
                          disabled={isLoadingFile}
                          onClick={() =>
                            handleShowShareClick(
                              item.id_documento ? item.id_documento : ''
                            )
                          }
                        >
                          <ShareIcon
                            color="primary"
                            sx={{ marginRight: '8px' }}
                          />
                          Compartilhar Documento
                        </MenuItem>
                      </Tooltip>
                    )}

                    {usuario?.permissoes.includes(
                      Permissions.EDITAR_DOCUMENTO
                    ) ? (
                      <MenuItem
                        disabled={isLoadingFile}
                        onClick={() => handleEdit(selectedItem!)}
                      >
                        <EditIcon color="primary" sx={{ marginRight: '8px' }} />
                        Editar
                      </MenuItem>
                    ) : (
                      <MenuItem color="success" disabled>
                        <EditIcon sx={{ marginRight: '8px' }} />
                        Editar
                      </MenuItem>
                    )}
                    {selectedItem?.ativo ? (
                      <MenuItem
                        onClick={() => {
                          setCurrentDocId(selectedItem.id_documento)
                          setShowDismiss(true)
                        }}
                        disabled={
                          isLoadingFile ||
                          !usuario?.permissoes.includes(
                            Permissions.EXCLUIR_DOCUMENTO
                          )
                        }
                      >
                        <DeleteIcon
                          color="secondary"
                          sx={{ marginRight: '8px' }}
                        />
                        Excluir
                      </MenuItem>
                    ) : (
                      <MenuItem
                        onClick={() => {
                          setCurrentDocId(item.id_documento)
                          setShowDismissReactivate(true)
                        }}
                        disabled={isLoadingFile}
                      >
                        <UndoIcon color="success" sx={{ marginRight: '8px' }} />
                        Recuperar
                      </MenuItem>
                    )}
                  </Menu>
                </TableCell>

                {/* <TableCell>
                  {item.ativo ? (
                    <Tooltip title="Excluir" arrow>
                      <Button
                        variant="outlined"
                        color="secondary"
                        disabled={
                          isLoadingFile ||
                          !usuario?.permissoes.includes(
                            Permissions.EXCLUIR_DOCUMENTO
                          )
                        }
                        onClick={() => {
                          setCurrentDocId(item.id_documento)
                          setShowDismiss(true)
                        }}
                        sx={{ minWidth: '24px', padding: '6px' }}
                      >
                        <DeleteIcon />
                      </Button>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Recuperar" arrow>
                      <Button
                        variant="outlined"
                        color="success"
                        disabled={
                          isLoadingFile ||
                          !usuario?.permissoes.includes(
                            Permissions.EXCLUIR_DOCUMENTO
                          )
                        }
                        onClick={() => {
                          setCurrentDocId(item.id_documento)
                          setShowDismissReactivate(true)
                        }}
                        sx={{ minWidth: '24px', padding: '6px' }}
                      >
                        <UndoIcon />
                      </Button>
                    </Tooltip>
                  )}
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {records?.pagination &&
        records.pagination.totalItems > records.pagination.itemsPerPage && (
          <Pagination
            count={records?.pagination.totalPages}
            sx={{ marginTop: '12px' }}
            onChange={(_, newPage) => {
              const newSearchParams = {
                ...advancedSearchParams,
                page: String(newPage)
              }
              setSearchParams({ ...searchParams, ...newSearchParams })
            }}
            page={records.pagination.currentPage}
          />
        )}

      {/* <Stack
        direction={'row'}
        gap={1}
        pt={2}
        bgcolor={'bisque'}
        px={2}
        py={1.5}
        mt={2}
        mb={3}
        borderRadius={2}
        fontSize={13}
      >
        <strong>ATENÇÃO:</strong>A imagem que deseja visualizar será aberta em
        uma nova aba. Certifique-se de desbloquear pop-ups no seu navegador para
        acessá-la com sucesso.
      </Stack> */}

      <Stack
        direction={'row'}
        gap={1}
        pt={2}
        bgcolor={'lightsteelblue'}
        px={2}
        py={1.5}
        mt={2}
        mb={3}
        borderRadius={2}
        fontSize={13}
      >
        <strong>DICA:</strong>Para buscar dados de forma precisa, insira o
        caractere # entre as palavras desejadas no campo de pesquisa.
        <br />
        Alternativamente, substitua # por " para encontrar dados em qualquer
        parte dos campos.
      </Stack>
      <Dialog
        handleAnswerNo={() => {
          setShowDismissUpdate(false)
        }}
        handleAnswerYes={() => {
          //handleRemove(currentDocId!)
          handleUpdateStatitics()
        }}
        messageText={
          <>
            <p>
              Tem certeza que deseja <strong>ATUALIZAR</strong> o número de
              páginas e tamanho do arquivo dos documentos digitais?
            </p>
            <p>Em caso afirmativo, o processo será executado em background. </p>
            <p>
              <strong>IMPORTANTE: </strong>Esta ação não poderá ser
              interrompida.
            </p>
          </>
        }
        title="Atenção"
        openDialog={showDismissUpdate}
      />

      <Dialog
        handleAnswerNo={() => {
          setShowDismiss(false)
        }}
        handleAnswerYes={() => {
          handleRemove(currentDocId!)
        }}
        messageText={
          <>
            <p>
              Tem certeza que deseja <strong>EXCLUIR</strong> este Documento?
            </p>
            <p>
              Em caso afirmativo, os metadados e o arquivo vinculado também
              serão excluídos.
            </p>
          </>
        }
        title="Atenção"
        openDialog={showDismiss}
      />

      <Dialog
        handleAnswerNo={() => {
          setShowDismissReactivate(false)
        }}
        handleAnswerYes={() => {
          handleReactivate(currentDocId!)
        }}
        messageText={
          <>
            <p>
              Tem certeza que deseja <strong>Recuperar</strong> este Documento?
            </p>
            <p>
              Em caso afirmativo, os metadados e o arquivo vinculado também
              serão Recuperados.
            </p>
          </>
        }
        title="Atenção"
        openDialog={showDismissReactivate}
      />
      <Modal
        open={showTree}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          top: '20%'
        }}
      >
        <DocumentTree
          close={handleModalClose}
          idDocumento={currentDocId ?? ''}
        />
      </Modal>
      <Modal
        open={showShare}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          top: '20%'
        }}
      >
        <Box
          sx={{
            height: '400px',
            width: '500px',
            bgcolor: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 1
          }}
        >
          <Typography
            sx={{
              fontSize: '1.5rem',
              fontWeight: 'bold'
            }}
          >
            Insira o E-mail para compartilhar
          </Typography>
          <TextField
            label="E-mail"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            sx={{ width: '80%' }}
            error={email !== '' && !isEmailValid(email)}
            helperText={
              email !== '' && !isEmailValid(email)
                ? 'Insira um email válido'
                : ''
            }
          />
          <FormControl sx={{ width: '80%', marginTop: 2 }}>
            <InputLabel>Exigir senha</InputLabel>
            <Select
              value={requirePassword}
              onChange={(event) => setRequirePassword(event.target.value)}
              label="Exigir senha"
            >
              <MenuItem value="true">Sim</MenuItem>
              <MenuItem value="false">Não</MenuItem>
            </Select>
          </FormControl>
          <Input
            label="Válido até"
            type="date"
            fullWidth
            value={validUntil}
            onChange={(event) => setValidUntil(String(event.target.value))}
            sx={{ width: '80%', marginTop: 2 }}
            error={validUntil !== '' && !isDateValid(validUntil)}
            helperText={
              validUntil !== '' && !isDateValid(validUntil)
                ? 'Insira uma data válida'
                : ''
            }
            InputLabelProps={{
              shrink: true
            }}
          />
          <Typography
            sx={{
              fontSize: '0.8rem'
            }}
          >
            <b>Nota:</b> Escolha uma data que não ultrapasse 90 dias.
          </Typography>
          <Box
            sx={{
              height: '10%',
              display: 'flex',
              alignContent: 'space-between',
              justifyContent: 'space-between',
              marginBottom: 1,
              marginTop: 2,
              gap: 2
            }}
          >
            <Button
              variant="contained"
              onClick={() => handleShareFile()}
              disabled={
                !email ||
                !requirePassword ||
                !validUntil ||
                !isEmailValid(email) ||
                !isDateValid(validUntil) ||
                disabledShareButton
              }
            >
              Compartilhar
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => handleModalShareClose()}
            >
              Fechar
            </Button>
          </Box>
        </Box>
      </Modal>
      <Dialog
        handleAnswerNo={() => {
          setShowDigitalSign(false)
        }}
        handleAnswerYes={() => {
          //handleRemove(currentDocId!)
          handleSignDocument()
        }}
        messageText={
          <>
            Confirma que deseja enviar este documento para ser digitalmente
            assinado?
            <br />
            <br />
            <strong>IMPORTANTE: </strong>Esta ação não poderá ser interrompida.
          </>
        }
        title="Atenção"
        openDialog={showDigitalSign}
      />
    </Box>
  )
}
