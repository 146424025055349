import { useState } from 'react'
import { Box, Divider } from '@mui/material'
import { Form } from './Form'
import { List } from './List'
import { FormMode } from '../../../types/formMode'
import { FormTitle } from '../../../components/Form/FormTitle'
import { useTipoCaixa } from '../../../hooks/tipoCaixa/useTipoCaixa'
import { Loading } from '../../../components/Loading'
import { useContrato } from '../../../hooks/contrato/useContrato'
import { useArvoreDocumental } from '../../../hooks/arvoreDcoumental/useArvoreDocumental'
import { useSetorPermitido } from '../../../hooks/setorPermitido/useSetorPermitido'

interface CaixasListFormProps {
  isInventario?: boolean
}

export function Caixas({ isInventario = false }: CaixasListFormProps) {
  const [formMode, setFormMode] = useState<FormMode>('LIST')
  const [data, setData] = useState<any>({})
  const { data: tiposCaixas, isLoading } = useTipoCaixa.ListAll()
  const { data: tiposDocumentos, isLoading: isLoadingTiposDocumentos } =
    useArvoreDocumental.FindTiposDocumentos()
  const { data: contratos, isLoading: isLoadingContratos } =
    useContrato.ListAll()
  const { data: setoresPermitidos, isLoading: isLoadingSetoresPermitidos } =
    useSetorPermitido.FindByUsuarioLogado()
  const [caixasLacradas, setCaixasLacradas] = useState<any>({})

  const title = isInventario ? 'Inventário Caixas' : 'Caixas'

  if (
    isLoading ||
    isLoadingTiposDocumentos ||
    isLoadingContratos ||
    isLoadingSetoresPermitidos
  )
    <Loading />

  return (
    <Box bgcolor="#fff" p={4} borderRadius={2}>
      <FormTitle title={title} subtitle={formMode} />
      <Divider />

      {formMode === 'LIST' ? (
        <List
          setData={setData}
          setFormMode={setFormMode}
          isInventario={isInventario}
          contratos={contratos ?? []}
          setores={setoresPermitidos ?? []}
        />
      ) : (
        <Form
          data={formMode === 'EDIT' ? data : {}}
          tiposCaixas={tiposCaixas ?? []}
          setData={setData}
          formMode={formMode}
          setFormMode={setFormMode}
          tiposDocumentos={tiposDocumentos ?? []}
          setoresPermitidos={setoresPermitidos ?? []}
          contratos={contratos ?? []}
          caixasLacradas={caixasLacradas}
          setCaixasLacradas={setCaixasLacradas ?? []}
          isInventario={isInventario}
        />
      )}
    </Box>
  )
}
