import { TemplateReport } from '../../../../../../../components/Report'
import { ILancamento } from '../../../../../../../services/financeiro/lancamento/types'
import { IFinanceiroReportFilter } from '../../../../../../../components/Financeiro/FinanceiroReportForm/types'
import { filterReportText } from '../../Filter'
import {
  groupByField,
  iterateGroupedData
} from '../../../../../../../utils/report'
import { formataSomenteData } from '../../../../../../../utils/formataData'

type Props = {
  data: ILancamento[]
  mode?: 'open' | 'save'
  id_usuario: string
  filtros: IFinanceiroReportFilter
}

export async function PrevisaoLancamentoReport({
  data,
  mode,
  id_usuario,
  filtros
}: Props) {
  let total_previsto = 0

  const isCliente = filtros.tipo_relacionamento === 'C'
  const isTodos = filtros.tipo_relacionamento ? false : true

  const summary = () => {
    return [
      {
        columns: [
          {
            text: [
              {
                text: `Total à ${
                  !isTodos
                    ? isCliente
                      ? 'Receber'
                      : 'Pagar'
                    : 'Receber x Pagar'
                }: `,
                style: ['summaryText', 'bold']
              },
              {
                text: `${Math.abs(total_previsto).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                })}\n`,
                style: [
                  'summaryText',
                  `${total_previsto >= 0 ? 'blue' : 'red'}`
                ]
              }
            ],
            style: 'filterText',
            width: '*'
          }
        ]
      }
    ]
  }

  const dadosAgrupados = groupByField(data, 'id_parceiro')

  const previsaoLancamentoPorParceiroTable = () => {
    const tables: Array<any> = []
    let rows: Array<any> = []
    if (dadosAgrupados) {
      iterateGroupedData(dadosAgrupados, (parceiro, lancamentos) => {
        const isTipoParceiro =
          lancamentos[0].parceiro?.tipo_relacionamento === 'C'
        rows = [
          [
            { text: 'Núm Documento', style: 'columnTitle' },
            { text: 'Histórico', style: 'columnTitle' },
            { text: 'Centro de Custo', style: 'columnTitle' },
            { text: 'Forma de Pagamento', style: 'columnTitle' },
            {
              text: `${isTipoParceiro ? 'Receita' : 'Despesa '}`,
              style: 'columnTitle'
            },
            { text: 'Vencimento', style: 'columnTitle' },
            { text: 'Valor', style: 'columnTitle' }
          ]
        ]

        let total_previsto_parceiro = 0

        lancamentos.forEach((lancamento) => {
          const row = [
            { text: lancamento.num_documento, style: 'row' },
            { text: lancamento.historico, style: 'row' },
            {
              text: `${
                lancamento.centroCusto ? lancamento.centroCusto.descricao : ''
              }`,
              style: 'row'
            },
            {
              text: `${
                lancamento.formaPagamento
                  ? lancamento.formaPagamento.descricao
                  : ''
              }`,
              style: 'row'
            },
            {
              text: `${
                lancamento.receitaDespesa
                  ? lancamento.receitaDespesa.descricao
                  : ''
              }`,
              style: 'row'
            },
            {
              text: formataSomenteData(
                lancamento.dt_vencimento?.toString() ?? ''
              ),
              style: 'rowCenter'
            },
            {
              text: lancamento.valor.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }),
              style: `${
                isTipoParceiro ? 'quantityDocPositive' : 'quantityDocNegative'
              }`
            }
          ]
          if (isTipoParceiro) {
            total_previsto_parceiro += lancamento.valor
          } else {
            total_previsto_parceiro -= lancamento.valor
          }
          rows.push(row as any)
        })
        total_previsto += total_previsto_parceiro

        tables.push([
          {
            text: `${isTipoParceiro ? 'Cliente: ' : 'Fornecedor: '} ${
              lancamentos[0].parceiro?.nome
            }`,
            style: 'subtitle'
          },
          {
            table: {
              widths: [80, 260, 80, 80, 80, 70, 70],
              headerRows: 1,
              body: rows
            }
          },
          {
            text: [
              {
                text: `Total à ${
                  isTipoParceiro ? 'Receber Cliente' : 'Pagar Fornecedor'
                }: `,
                style: ['total']
              },
              {
                text: `${Math.abs(total_previsto_parceiro).toLocaleString(
                  'pt-BR',
                  {
                    style: 'currency',
                    currency: 'BRL'
                  }
                )}`,
                style: [`${isTipoParceiro ? 'totalPositive' : 'totalNegative'}`]
              }
            ]
          },
          {
            text: '\n'
          }
        ])
      })
      tables.push(summary())
      return tables
    } else {
      return [{ text: '\n' }]
    }
  }

  const filterText = await filterReportText(filtros)

  await TemplateReport({
    id_usuario,
    content: previsaoLancamentoPorParceiroTable(),
    filterText,
    pageOrientation: 'landscape',
    reportTitle: `Lançamentos à ${
      !isTodos ? (isCliente ? 'Receber' : 'Pagar') : 'Receber x Pagar'
    }`,
    mode
  })
}
