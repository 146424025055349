import { useMutation, useQueryClient } from 'react-query'
import { ApiPermissao as ApiPermissaoAtribuida } from '../../services/permissao'

const QUERY_KEY = 'qkPermissaoAtribuida'

const CopyPermissions = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiPermissaoAtribuida.copyPermissions, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

export const usePermissaoAtribuida = {
  CopyPermissions
}
