import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as SelectMUI,
  SelectProps as SelectMUIProps
} from '@mui/material'
import { forwardRef, ForwardRefRenderFunction } from 'react'

export type SelectProps = {
  label: string
  placeholder?: string
  message?: string
  data: any
  fields: string[]
  showField2?: boolean
} & SelectMUIProps

const SelectBase: ForwardRefRenderFunction<HTMLSelectElement, SelectProps> = (
  {
    label,
    placeholder,
    message,
    data,
    fields,
    // showField2 = false,
    ...rest
  },
  ref
) => {
  const getText = (data: any): string => {
    if (fields.length > 2) {
      let text = data[fields[1]]
      for (let i = 2; i < fields.length; i++) {
        text += ' - ' + data[fields[i]]
      }
      return text
    }
    return data[fields[1]]
  }
  return (
    <FormControl fullWidth error={!!message} size="small">
      <InputLabel>{label}</InputLabel>
      <SelectMUI label={label} ref={ref} {...rest}>
        <MenuItem disabled value="">
          <em>{placeholder}</em>
        </MenuItem>

        {fields &&
          data &&
          data.map((item: any) => (
            <MenuItem key={item[fields[0]]} value={item[fields[0]]}>
              {getText(item)}
            </MenuItem>
          ))}
      </SelectMUI>

      <FormHelperText>{message}</FormHelperText>
    </FormControl>
  )
}

export const Select = forwardRef(SelectBase)
