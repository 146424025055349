import { Box, Button, Stack } from '@mui/material'
import { useState } from 'react'
import SaveIcon from '@mui/icons-material/CheckCircleOutlined'
import CancelIcon from '@mui/icons-material/DoDisturbAltOutlined'
import CardMembershipIcon from '@mui/icons-material/CardMembership'
import EditIcon from '@mui/icons-material/Edit'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import useToast from '../../../hooks/toast/useToast'
import { Input } from '../../../components/Form/Input'
import msg from '../../../utils/validationMessages'
import { FormMode } from '../../../types/formMode'
import { IDocumentoDigital } from '../../../services/documento/types'
import { useDocumento } from '../../../hooks/documento/useDocumento'
import { Select } from '../../../components/Form/Select'
import { useContrato } from '../../../hooks/contrato/useContrato'
import { useArvoreDocumental } from '../../../hooks/arvoreDcoumental/useArvoreDocumental'
import { formata } from '../../../utils/formataData'

const schema = yup.object({
  id_contrato: yup.string().required(msg.REQUIRED),
  id_tipo_documento: yup.string().required(msg.REQUIRED),
  descricao: yup.string().required(msg.REQUIRED).min(4, msg.MIN(4)),
  // assunto: yup.string().min(4, msg.MIN(4)),
  // observacao: yup.string().min(4, msg.MIN(4)),
  // data_final: yup.date(),
  assunto: yup.string(),
  observacao: yup.string(),
  data_final: yup
    .date()
    .nullable()
    .notRequired()
    .transform((value, originalValue) => {
      return originalValue === '' ? null : value
    })
    .test('is-date', 'Data inválida', (value) => {
      return value === null || yup.date().isValidSync(value)
    }),
  file: yup
    .mixed()
    .test('file', 'Selecione um arquivo', (value) =>
      value === undefined || value.length > 0 ? true : false
    )
})

interface IForm {
  data: any
  formMode: FormMode
  showEditMetadata: boolean
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

export function Form({ data, formMode, showEditMetadata, setFormMode }: IForm) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<IDocumentoDigital>({ resolver: yupResolver(schema) })

  const { Toast } = useToast()
  const { mutateAsync: create } = useDocumento.CreateDocumentoDigital()
  const { mutateAsync: upload } = useDocumento.UploadDocumentoDigital()
  const { mutateAsync: update } = useDocumento.Update()
  const { data: contratos } = useContrato.ListAll()
  const { data: tiposDocumentos } =
    useArvoreDocumental.FindTiposDocumentosByContrato()

  const [editar, setEditar] = useState(true)

  const [dataGuarda, setDataGuarda] = useState<Date | undefined>(
    data.data_final
  )

  const handleTipoDocumentoChange = (idTipoDocumento: string) => {
    const tipoDocumento = tiposDocumentos?.find(
      (tp) => tp.id_tipo_documento === idTipoDocumento
    )
    if (!!tipoDocumento && !!tipoDocumento.tipo_temporalidade) {
      const tmp: Date = new Date()
      switch (tipoDocumento.tipo_temporalidade.unidade) {
        case 'ANO': {
          tmp.setFullYear(
            tmp.getFullYear() + tipoDocumento.tipo_temporalidade.tempo
          )
          break
        }
        case 'MES': {
          tmp.setMonth(tmp.getMonth() + tipoDocumento.tipo_temporalidade.tempo)
          break
        }
        case 'DIA': {
          tmp.setDate(tmp.getDate() + tipoDocumento.tipo_temporalidade.tempo)
          break
        }
      }
      setDataGuarda(tmp)
      setValue('data_final', tmp)
      return
    }
    setDataGuarda(undefined)
  }

  const onSubmit = async (form: IDocumentoDigital) => {
    const formData = new FormData()

    formData.append('id_documento', data.id_documento)

    if (formMode === 'CREATE' || (formMode === 'EDIT' && !data.edit)) {
      formData.append('file', form.file[0])
      formData.append('formData', JSON.stringify(form))
      formMode === 'CREATE'
        ? await Toast(create(formData))
        : await Toast(upload(formData), 'UPDATED')
      setFormMode('LIST')
      return
    }
    const documento: any = {
      id_documento: data.id_documento,
      descricao: form.descricao,
      assunto: form.assunto,
      observacao: form.observacao,
      // data_final: form.data_final,
      data_final: form.data_final ? new Date(form.data_final) : null,
      id_tipo_documento: form.id_tipo_documento
    }

    formData.append('formData', JSON.stringify(form))
    await Toast(update(documento), 'UPDATED')
    setFormMode('LIST')
    // if (formMode === 'CREATE') await Toast(create(formData))
  }

  const onSingUpClick = () => {
    window.open('https://plataforma.astenassinatura.com.br/', '_blank')
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} mt={4}>
      <Stack gap={2}>
        <Select
          label="Contrato*"
          placeholder="Selecione o Contrato"
          data={contratos}
          fields={['id_contrato', 'descricao']}
          {...register('id_contrato')}
          defaultValue={data?.id_contrato ?? ''}
          message={errors.id_contrato?.message}
          readOnly={formMode === 'EDIT'}
          sx={{ backgroundColor: '#eee' }}
        />
        <Select
          label="Tipo de documento*"
          placeholder="Selecione o Tipo de documento"
          data={tiposDocumentos}
          fields={['id_tipo_documento', 'codigo', 'descricao']}
          {...register('id_tipo_documento')}
          defaultValue={data?.id_tipo_documento ?? ''}
          message={errors.id_tipo_documento?.message}
          readOnly={
            editar ? formMode === 'EDIT' : formMode === 'EDIT' && !data.edit
          }
          sx={{ backgroundColor: editar ? '#eee' : 'white' }}
          onChange={(event) =>
            handleTipoDocumentoChange(String(event.target.value))
          }
        />
        <Input
          label="Descrição*"
          {...register('descricao')}
          defaultValue={data?.descricao}
          message={errors.descricao?.message}
          readOnly={editar}
          sx={{ backgroundColor: editar ? '#eee' : 'white' }}
        />

        <Stack gap={2} flexDirection={'row'}>
          <Input
            label="Assunto"
            {...register('assunto')}
            defaultValue={data?.assunto ?? ''}
            // message={errors.assunto?.message}
            readOnly={editar}
            sx={{
              backgroundColor: editar ? '#eee' : 'white',
              height: editar ? '38px' : ''
            }}
            InputProps={{
              readOnly: formMode === 'EDIT' && !data.edit
            }}
          />
          <Input
            label="Data final de guarda"
            type="date"
            {...register('data_final')}
            defaultValue={dataGuarda ? formata(dataGuarda, 'yyyy-MM-dd') : ''}
            // message={errors.data_final?.message ?? ''}
            InputLabelProps={{
              shrink: true
            }}
            readOnly={editar}
            sx={{
              backgroundColor: editar ? '#eee' : 'white',
              height: editar ? '38px' : ''
            }}
            InputProps={{
              readOnly: formMode === 'EDIT' && !data.edit
            }}
          />

          <Input
            label="Observações"
            {...register('observacao')}
            defaultValue={data?.observacao ?? ''}
            // message={errors.observacao?.message}
            readOnly={editar}
            sx={{
              backgroundColor: editar ? '#eee' : 'white',
              height: editar ? '38px' : ''
            }}
            InputProps={{
              readOnly: formMode === 'EDIT' && !data.edit
            }}
          />
          {!data.edit && (
            <Input
              label=""
              type="file"
              {...register('file')}
              defaultValue={data?.file}
              message={errors.file?.message}
            />
          )}
        </Stack>

        <Stack direction="row" spacing={2}>
          <Input
            label="Criado em"
            value={new Date(data?.created_at).toLocaleString()}
            readOnly={true}
            sx={{ backgroundColor: '#eee', width: '200px' }}
          />
          <Input
            label="Atualizado em"
            value={new Date(data?.updated_at).toLocaleString()}
            readOnly={true}
            sx={{ backgroundColor: '#eee', width: '200px' }}
          />
        </Stack>

        <Stack direction="row" gap={2} mt={3}>
          {showEditMetadata === false ? (
            <Button
              variant="outlined"
              color="info"
              startIcon={<CardMembershipIcon />}
              onClick={() => onSingUpClick()}
            >
              Assinar Digitalmente Antes de Fazer Upload
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="info"
              startIcon={<EditIcon />}
              onClick={() => setEditar(!editar)}
            >
              {editar
                ? 'Habilitar Edição de Metadados'
                : 'Desabilitar Edição de Metadados'}
            </Button>
          )}

          <Button
            variant="outlined"
            color="secondary"
            startIcon={<CancelIcon />}
            onClick={() => setFormMode('LIST')}
          >
            Cancelar
          </Button>

          <Button
            type="submit"
            variant="contained"
            startIcon={<SaveIcon />}
            sx={{ width: '110px' }}
          >
            Salvar
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}
