import { DefaultApi } from '../../utils/defaultApi'
import { IResult } from '../../utils/defaultApi/types'
import api from '../api'
import { IServico } from './types'

const endpoint = '/servico'
const resourceId = 'id_servico'

class ServicoService extends DefaultApi<IServico> {
  public findAllWithFluxo = async (page: number, q?: string) => {
    const pageString = page ? `page=${page}` : ''
    const qString = q ? `q=${q}` : ''
    const params = [pageString, qString]
      .filter((value) => value !== '')
      .join('&')
    const { data } = await api.get<IResult<IServico>>(
      `${this.endpoint}/fluxo/?${params}`
    )
    return data
  }

  public findOneWithFluxo = async (id: string) => {
    if (id === '' || id === '0') {
      return undefined
    }
    const { data } = await api.get<IServico>(`${this.endpoint}/fluxo/${id}`)
    return data
  }

  public async removeFluxoAtividades(id: string) {
    const { data } = await api.put<IServico>(
      `${this.endpoint}/fluxo-remove/${id}`,
      {}
    )
    return data
  }

  public setFluxoAtividades = async (formData: IServico) => {
    const { data } = await api.put<IServico>(
      `${this.endpoint}/fluxo/${formData[resourceId]}`,
      {
        modelagem_fluxo: formData.modelagem_fluxo
      }
    )

    return data
  }
}

export const ApiServico = new ServicoService(endpoint, resourceId)
