import { DefaultApi } from '../../utils/defaultApi'
import api from '../api'
import { ILog } from './types'
import { TAtivo, IResult } from '../../utils/defaultApi/types'

const endpoint = '/log-sistema'
const resourceId = 'id_log'

export class ServiceLog extends DefaultApi<ILog> {
  findAllLogs = async (
    page: number,
    selectedOrder: string,
    q?: string,
    contrato?: string,
    ativo: TAtivo = true
  ): Promise<IResult<ILog>> => {
    const pageString = `page=${page}`
    const qString = q ? `q=${q}` : ''
    const contratoString = contrato ? `contrato=${contrato}` : ''
    const ativoString = `ativo=${ativo}`
    const ordemString = selectedOrder ? `ordem=${selectedOrder}` : ''
    const params = [
      pageString,
      qString,
      contratoString,
      ativoString,
      ordemString
    ]
      .filter((value) => value)
      .join('&')

    const { data } = await api.get<IResult<ILog>>(`${this.endpoint}?${params}`)
    return data
  }

  findAllDate = async (
    page: number,
    date: string,
    selectedOrder: string,
    q?: string,
    contrato?: string,
    ativo: TAtivo = true
  ): Promise<IResult<ILog>> => {
    const pageString = `page=${page}`
    const qString = q ? `q=${q}` : ''
    const contratoString = contrato ? `id_contrato=${contrato}` : ''
    const ativoString = `ativo=${ativo}`
    const ordemString = selectedOrder ? `ordem=${selectedOrder}` : ''
    const params = [
      pageString,
      qString,
      contratoString,
      ativoString,
      ordemString
    ]
      .filter((value) => value)
      .join('&')

    const { data } = await api.get<IResult<ILog>>(
      `${this.endpoint}/datados/${date}?${params}`
    )
    return data
  }
}
const apiLog = new ServiceLog(endpoint, resourceId)
export const ApiLog = {
  ...apiLog
}
