import { Box, BoxProps } from '@mui/material'
import { ReactNode } from 'react'

interface DashboardWidgetRootProps extends BoxProps {
  children: ReactNode
}

export function DashboardWidgetRoot({
  children,
  ...rest
}: DashboardWidgetRootProps) {
  return <Box {...rest}>{children}</Box>
}
