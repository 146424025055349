import { Box, Button, Stack } from '@mui/material'
import SaveIcon from '@mui/icons-material/CheckCircleOutlined'
import CancelIcon from '@mui/icons-material/DoDisturbAltOutlined'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import msg from '../../../utils/validationMessages'
import useToast from '../../../hooks/toast/useToast'
import { FormMode } from '../../../types/formMode'
import { Input } from '../../Form/Input'
import { IReceitaDespesa } from '../../../services/financeiro/receitaDespesa/types'
import { useReceitaDespesa } from '../../../hooks/financeiro/receitaDespesa/useReceitaDespesa'

const schema = yup
  .object({
    descricao: yup.string().required(msg.REQUIRED).min(4, msg.MIN(4)),
    tipo: yup.string().required(msg.REQUIRED)
  })
  .required()

interface IForm {
  tipo: string
  data: any
  formMode: FormMode
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

export function ReceitaDespesaForm({
  tipo,
  data,
  formMode,
  setFormMode
}: IForm) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm<IReceitaDespesa>({
    resolver: yupResolver(schema)
  })

  const { Toast } = useToast()
  const { mutateAsync: create } = useReceitaDespesa.Create()
  const { mutateAsync: update } = useReceitaDespesa.Update()
  setValue('tipo', tipo)

  const onSubmit = async (formData: IReceitaDespesa) => {
    formMode === 'CREATE'
      ? await Toast(create(formData))
      : await Toast(
          update({
            id_receita_despesa: data.id_receita_despesa,
            ...formData
          }),
          'UPDATED'
        )

    setFormMode('LIST')
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} mt={4}>
      <Stack gap={2}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2
          }}
        >
          <Input
            label="Descrição*"
            {...register('descricao')}
            defaultValue={data?.descricao}
            message={errors.descricao?.message}
          />
        </Box>
      </Stack>

      <Stack direction="row" gap={2} mt={3}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<CancelIcon />}
          onClick={() => setFormMode('LIST')}
        >
          Cancelar
        </Button>

        <Button
          type="submit"
          variant="contained"
          startIcon={<SaveIcon />}
          sx={{ width: '110px' }}
        >
          Salvar
        </Button>
      </Stack>
    </Box>
  )
}
