import { Breadcrumbs, Typography } from '@mui/material'

export interface IDrivePathProps {
  path: string[]
}
export function DrivePath({ path }: IDrivePathProps) {
  return (
    <Breadcrumbs
      separator={'››'}
      sx={{
        paddingTop: '32px'
      }}
    >
      {path.map((item) => (
        <Typography>{item}</Typography>
      ))}
    </Breadcrumbs>
  )
}
