import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Divider,
  Dialog,
  FormControlLabel,
  List,
  ListItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import Processando from '../../../assets/images/procesando.gif'
import { ReactElement, useEffect, useState } from 'react'

import SaveIcon from '@mui/icons-material/CheckCircleOutlined'
import BackIcon from '@mui/icons-material/ArrowBack'
import ForwardIcon from '@mui/icons-material/ArrowForward'
import {
  Cancel,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  Warning
} from '@mui/icons-material'
import { ArrowUpward, Block } from '@mui/icons-material'
import { ExpandMore } from '@mui/icons-material'

import { FormTitle } from '../../../components/Form/FormTitle'
import { Timeline } from './components/Timeline'

import AddFileImg from '../../../assets/images/addFile.svg'
import { useDocumento } from '../../../hooks/documento/useDocumento'
import { ApiCaixa } from '../../../services/caixa'
import { IDocumento } from '../../../services/documento/types'
import { ComponentGroup } from '../../../components/ComponentGroup'
import { StatusBox } from '../../../components/StatusBox'
import { LogDialog } from './components/LogDialog'
import { Loading } from '../../../components/Loading'
import { getTempoDecorrido } from '../../../utils/getTempoDecorrido'
import { IMenssagemValidacao } from './@types/IMensagemValidacao'
import {
  blankDescricaoTipoDocumento,
  IDescricaoTipoDocumento
} from './@types/IDescricaoTipoDocumento'
import { ICaixaImportar } from './@types/ICaixaImportar'
import { IDocumentoImportar } from './@types/IDocumentosImportar'
import { readDocumentsXML } from './utils/readDocumentsXML'
import { ApiTipoDocumento } from '../../../services/tipoDocumento'
import { ApiContrato } from '../../../services/contrato'
import { getFileName } from './utils/getFileName'
import { getTipoDocumento } from './utils/getTipoDocumento'
import { IResultadoXML } from './@types/IResultadoXML'

export const ImportSteps = [
  'Seleção de Arquivo XML',
  'Seleção de Descrição de Documentos',
  'Seleção de Arquivos PDF',
  'Confirmação de Dados'
]

export function ImportarDocumentos() {
  // Uso hook para salvar dados importação (INÍCIO) ------------------------------------------------
  // const { mutateAsync: createDadosImportacao } = useDadosImportacao.Create()
  // const { mutateAsync: updateDadosImportacao } = useDadosImportacao.Update()
  // Uso direto de api (INÍCIO) ------------------------------------------------
  const { findByCodigo: caixaByCodigo } = ApiCaixa
  const { findByCode: tipoDocumentoByCodigo } = ApiTipoDocumento
  const { findByCode: contratoByCodigo } = ApiContrato
  // Uso direto de api (FIM) ---------------------------------------------------

  // Estados (INÍCIO) ----------------------------------------------------------

  // Dados de navegação e compartilhados (INÍCIO)
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [stepForward, setStepForward] = useState<boolean>(false)
  const [caixasImportar, setCaixasImportar] = useState<ICaixaImportar[]>([])
  const [progress, setProgress] = useState<number>(0)
  const [inicioExecucao, setInicioExecucao] = useState<Date>(new Date())
  const [tempo, setTempo] = useState<Date>(new Date())
  const [tempoUpload, setTempoUpload] = useState<Date>(new Date())
  const [tempoDecorrido, setTempoDecorrido] = useState<string>('00:00:00')

  // Dados de navegação e compartilhados (FIM)

  // Leitura do XML (INÍCIO)
  const [arquivoXML, setArquivoXML] = useState<string>('')
  const [errosLeituraXML, setErroLeituraXML] = useState<IMenssagemValidacao[]>(
    []
  )
  const [alertasLeituraXML, setAlertasLeituraXML] = useState<
    IMenssagemValidacao[]
  >([])
  const [processamentoXML, setProcessamentoXML] = useState<string[]>([])

  const [listaDescricoesDocumentos, setListaDescricoesDocumentos] = useState<
    IDescricaoTipoDocumento[]
  >([])

  // Leitura do XML (FIM)

  // Montagem da descrição dos documento (INÍCIO)
  const [descricaoTipoDocumentoSelecionada, setDescricaoTipoDocumento] =
    useState<IDescricaoTipoDocumento>(blankDescricaoTipoDocumento)
  const [metadadoSelecionado, setMetadadoSelecionado] = useState<string>('')
  const [descricaoSelecionada, setDescricaoSelecionada] = useState<string>('')
  // Montagem da descrição dos documento (FIM)

  // Leitura de PDFs (INÍCIO)
  const [erroLeituraPDF, setErroLeituraPDF] = useState<string>('')
  const [importMessagePDF, setImportMessagePDF] = useState<string>('')

  // Leitura de PDFs (FIM)

  // Documentos para importação (INÍCIO)
  const [listaDocumentosImportar, setListaDocumentosImportar] = useState<
    IDocumentoImportar[]
  >([])
  // Documentos para importação (FIM)

  // Importação (INÍCIO)
  const [listaMensagensImportacao, setListaMensagensImportacao] = useState<
    IMenssagemValidacao[]
  >([])
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [showWaitDialog, setShowWaitDialog] = useState<boolean>(false)
  const [importando, setImportando] = useState<boolean>(false)
  // Importação (FIM)

  // Estados (FIM) -------------------------------------------------------------

  // Formatação (INÍCIO) -------------------------------------------------------
  /**
   * Converte um array em uma lista não numerada HTML
   * @param lista Array de dados a ser convertido
   * @param prefix prefixo a ser adicionado ao dados para tentar garantir não
   * repetição de chaves React
   * @returns Lista não-numerada HTML
   */
  const arrayToList = (lista: Array<string>, prefix: string): ReactElement => {
    return (
      <ul>
        {lista.map((item, index) => {
          return <li key={prefix + String(index)}>{item}</li>
        })}
      </ul>
    )
  }

  /**
   * Converte as flags de "dados pessoais", "dados sensíveis" e "dados de
   * criaça ou adolescente" em uma string que as representa
   * @param documento Documento com informação de tipo de documento
   * @returns String representando as flags
   */
  const flagsToString = (documento: IDocumento): string => {
    let ret = documento.dados_criancas_adolescentes ? 'C' : ''
    if (documento.dados_pessoais) {
      ret += (ret.length > 0 ? '-' : '') + 'P'
    }
    if (documento.dados_pessoais_sensiveis) {
      ret += (ret.length > 0 ? '-' : '') + 'S'
    }
    return ret
  }

  /**
   * Transformar a lista de metadados do documento em lista não numerada do HTML
   * @param documento Documento com metadados
   * @returns Lista não numerada HTML
   */
  const getListaMetadados = (documento: IDocumento): any => {
    const { metadados } = documento
    return (
      <ul>
        {metadados?.map((metadado, index) => (
          <li
            key={documento.id_documento + documento.id_tipo_documento + index}
          >
            {metadado.chave}: {metadado.valor}
          </li>
        ))}
      </ul>
    )
  }

  // Formatação (FIM) ----------------------------------------------------------

  // Hooks de dados auxiliares (INÍCIO) ----------------------------------------
  const { mutateAsync: create } = useDocumento.ImportData()

  // Hooks de dados auxiliares (FIM) -------------------------------------------

  // Marcação de documentos para importação (INÍCIO) ---------------------------

  const getTotalMarcados = (): number => {
    const lista = [...listaDocumentosImportar]
    const marcados = lista
      .map((documento) => (documento.importar === 0 ? 0 : 1) as number)
      .reduce((acc, cur) => {
        return acc + cur
      }, 0)
    return marcados
  }

  /**
   * Verifica o status de marcação do documentos para importação
   * @returns Status da marcação (0: nenhum, 1: vários, 2: todos)
   */
  const getMarcados = (): number => {
    const marcados = getTotalMarcados()
    return marcados === 0
      ? 0
      : marcados === listaDocumentosImportar.length
      ? 2
      : 1
  }

  const marcarDesmarcarTodos = () => {
    const lista = [...listaDocumentosImportar]
    if (getMarcados() < 2) {
      lista.forEach((documento) => marcarImportacao(documento.numero, 1))
      setStepForward(getMarcados() > 0)
      return
    }
    lista.forEach((documento) => marcarImportacao(documento.numero, 0))
    setStepForward(false)
  }

  /**
   * Definir se um documento será ou não importado e o tipo de importação
   * @param numero Identificação do documento no XML
   * @param tipo Tipo de importação (0: nenhuma, 1: novo documento, 2:
   * atualização)
   */
  const marcarImportacao = (numero: string, tipo: number): void => {
    const newLista = [...listaDocumentosImportar]
    newLista.forEach((documento) => {
      if (documento.numero === numero) {
        documento.importar = tipo
      }
    })
    setListaDocumentosImportar(newLista)
  }
  // Marcação de documentos para importação (FIM) ------------------------------

  // Handlers (INÍCIO) ---------------------------------------------------------
  /**
   * Ir para o passo anterior
   */
  const handlePreviousButtonClick = () => {
    setCurrentStep(currentStep - 1)
    setStepForward(true)
  }

  /**
   * Ir para o próximo passo
   */
  const handleNextButtonClick = () => {
    setCurrentStep(currentStep + 1)
    if (currentStep + 1 !== 2) setStepForward(false)
  }

  /**
   * Cancela a importação
   */
  const handleCancelButtonClick = () => {
    setCurrentStep(0)
    setStepForward(false)
    setCaixasImportar([])

    setArquivoXML('')
    setErroLeituraXML([])
    setAlertasLeituraXML([])
    setProcessamentoXML([])
    setListaDescricoesDocumentos([])

    setDescricaoTipoDocumento(blankDescricaoTipoDocumento)
    setMetadadoSelecionado('')
    setDescricaoSelecionada('')

    setErroLeituraPDF('')
    setImportMessagePDF('')

    setListaDocumentosImportar([])

    setListaMensagensImportacao([])
  }

  /**
   * Ler arquivo XML relativo a um lote.
   *
   * Após a leitura do arquivo o arquivo é validado quanto ao formato, se as
   * caixas estão corretamente identificadas e prepara os dados dos documentos
   * para importação.
   * @param event Evento de seleção do arquivo
   * @returns
   */
  const handleXMLSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentStep(0)
    setStepForward(false)
    setCaixasImportar([])

    setArquivoXML('')
    setErroLeituraXML([])
    setAlertasLeituraXML([])
    setProcessamentoXML([])
    setListaDescricoesDocumentos([])

    setDescricaoTipoDocumento(blankDescricaoTipoDocumento)
    setMetadadoSelecionado('')
    setDescricaoSelecionada('')

    setErroLeituraPDF('')
    setImportMessagePDF('')

    setListaDocumentosImportar([])

    setListaMensagensImportacao([])

    const xmlFile = event.target.files ? event.target.files[0] : new Blob()
    const textType = 'text/xml'
    let results: IResultadoXML = {
      alertas: [],
      erros: [],
      listaDescricoes: [],
      processamento: [],
      totalDocumentos: 0,
      totalCaixas: 0,
      fileName: '',
      documentos: [],
      caixas: []
    }

    // Validação do tipo de arquivo
    if (!xmlFile.type.match(textType)) {
      results.erros = [
        {
          descricao: 'Arquivo selecionado não é um arquivo XML',
          lista: []
        }
      ]
      return
    }

    // Criação do leitor de arquivo
    const xmlReader = new FileReader()

    const fileName = !event.target.files ? '' : event.target.files[0].name

    xmlReader.onload = async (fileEvent) => {
      if (!fileEvent.target) {
        return
      }
      results = await readDocumentsXML({
        content: (fileEvent.target.result ?? '') as string,
        fileName,
        caixaByCodigo,
        tipoDocumentoByCodigo,
        contratoByCodigo
      })

      setProcessamentoXML(results.processamento)
      setAlertasLeituraXML(results.alertas)
      setErroLeituraXML(results.erros)
      setListaDescricoesDocumentos(results.listaDescricoes)
      if (results.erros.length === 0) {
        setStepForward(true)
        setCaixasImportar(results.caixas)
      }
      // Exibição dos logs (FIM)
      // Definição do nome do arquivo (para exibição em tela)
      setArquivoXML(results.fileName)
    }

    // Chamado do processo de leitura do XML
    xmlReader.readAsText(xmlFile)
  }

  /**
   * Lida com o click em um item da tabela de Tipos de Documentos para definição
   * da descrição de documentos
   * @param tipoDocumento Tipo de Documento/Metadados/Descrição selecionados
   */
  const handleTipoDocumentoSelect = (
    tipoDocumento: IDescricaoTipoDocumento
  ): void => {
    setDescricaoTipoDocumento(tipoDocumento)
  }

  /**
   * Seleciona ou desseleciona um metadado da lista de disponíveis
   * @param metadado
   * @returns
   */
  const handleMetadadoClick = (metadado: string) => {
    if (metadadoSelecionado === metadado) {
      setMetadadoSelecionado('')
      return
    }

    setMetadadoSelecionado(metadado)
  }

  /**
   * Seleciona ou desseleciona um metadado da lista de metadados para compor
   * descrição dos documentos
   * @param descricao
   * @returns
   */
  const handleDescricaoClick = (descricao: string) => {
    if (descricaoSelecionada === descricao) {
      setDescricaoSelecionada('')
      return
    }

    setDescricaoSelecionada(descricao)
  }

  /**
   * Adicionar um metadado à lista de metadados para composição da descrição
   * de documentos
   */
  const handleDescricaoAddClick = () => {
    const newDescricao = [
      ...descricaoTipoDocumentoSelecionada.descricao,
      metadadoSelecionado
    ]
    setMetadadoSelecionado('')
    const newDescricaoTipoDocumento: IDescricaoTipoDocumento = {
      ...descricaoTipoDocumentoSelecionada,
      descricao: [...newDescricao]
    }
    setDescricaoTipoDocumento(newDescricaoTipoDocumento)
  }

  const handleDescricaoRemoveClick = () => {
    if (descricaoSelecionada !== '') {
      const newDescricao = [
        ...descricaoTipoDocumentoSelecionada.descricao.filter(
          (item) => item !== descricaoSelecionada
        )
      ]
      setDescricaoSelecionada('')
      const newDescricaoTipoDocumento: IDescricaoTipoDocumento = {
        ...descricaoTipoDocumentoSelecionada,
        descricao: [...newDescricao]
      }
      setDescricaoTipoDocumento(newDescricaoTipoDocumento)
    }
  }

  /**
   * Fazer o tratamento dos dados dos arquivos selecionados para importação
   * @param event EventHandle do seletor de arquivos
   */
  const handleFilesSelectChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setImportMessagePDF('')
    setErroLeituraPDF('')

    listaDocumentosImportar.forEach((documentoImportar) => {
      documentoImportar.dadosArquivo = new Blob([])
    })

    try {
      const { files } = event.target
      if (files) {
        for (let index = 0; index < files.length; index++) {
          const file = files[index]
          const { name } = file

          const listaDocumentosCandidatos = listaDocumentosImportar.filter(
            (doc) => {
              const fileName = getFileName(doc.caminho)
              return fileName === name
            }
          )

          if (listaDocumentosCandidatos.length === 0) {
            setErroLeituraPDF(
              `Arquivo PDF sem documento correspondente (${name})`
            )
            return
          }
          if (listaDocumentosCandidatos.length > 1) {
            setErroLeituraPDF(
              `Arquivo PDF referenciado por mais de um documento (${name})`
            )
            return
          }
          listaDocumentosCandidatos[0].dadosArquivo = file
        }
        const documentosSelecionados = listaDocumentosImportar
          .map(
            (documento) => (documento.dadosArquivo.size > 0 ? 1 : 0) as number
          )
          .reduce((acc, cur) => acc + cur, 0)
        setImportMessagePDF(
          `${documentosSelecionados} arquivos PDF foram ` +
            `identificados para importação.`
        )
      }
    } catch (e) {
      setErroLeituraPDF('Um ou mais arquivos não puderam ser lidos')
    }
  }

  /**
   * Incluir/remover um documento novo para importação
   * @param documento Documento a ser incluso/excluído da lista de importação
   */
  const handleImportarNovoChange = (
    documentoImportar: IDocumentoImportar
  ): void => {
    if (documentoImportar.importar === 0) {
      marcarImportacao(documentoImportar.numero, 1)
      setStepForward(getMarcados() > 0)
      return
    }
    marcarImportacao(documentoImportar.numero, 0)
    setStepForward(getMarcados() > 0)
  }

  /**
   * Enviar os dados dos documentos para o back
   */
  const handleSubmitClick = async () => {
    const newTime = new Date()
    setInicioExecucao(newTime)
    setTempoUpload(newTime)
    setTempo(newTime)
    setTempoDecorrido('00:00:00')
    setShowWaitDialog(true)
    setImportando(true)

    const docCadastrar = listaDocumentosImportar.filter(
      (documento) => documento.importar === 1
    )

    const documentsToSend = docCadastrar.map((doc) => {
      const documentToSend = {
        ...doc.documento,
        id_documento: undefined,
        id_contrato: undefined,
        path: doc.caminho.split('\\')!.pop()!.split('/').pop() ?? ''
      }
      return documentToSend
    })

    const strData = JSON.stringify(documentsToSend)
    const formData = new FormData()
    formData.append('formData', strData)

    docCadastrar
      .filter((doc) => doc.dadosArquivo.size !== 0)
      .forEach((doc) => {
        formData.append(`files`, doc.dadosArquivo)
      })
    setImportando(true)

    let mensagensImportacao: IMenssagemValidacao[] = []
    try {
      const logData = await create({
        formData,
        progressCallback: (progress) => {
          setProgress(progress)
        }
      })
      mensagensImportacao = [
        {
          descricao: 'Processo de importação concluído',
          lista: <span>&nbsp;</span>
        },
        {
          descricao: 'Total de Documentos para Importação',
          lista: <span>{documentsToSend.length}</span>
        },
        {
          descricao: 'Total de Documentos Marcados para Importação',
          lista: <span>{logData.total_marcados}</span>
        },
        {
          descricao: 'PDFs selecionados para upload',
          lista: <span>{logData.total_pdf}</span>
        },
        {
          descricao: 'Total de Documentos Importados',
          lista: <span>{logData.total_efetivados}</span>
        },
        ...((logData.erros_importacao ?? '')
          .split('\n')
          .filter((erro) => erro.trim() !== '')
          .map((erro) => {
            return {
              descricao: 'Mensagem de Erro',
              lista: <span>{erro}</span>
            }
          }) ?? []),
        {
          descricao: 'Erros de importação',
          lista: <span>{logData.total_erros}</span>
        }
      ]
    } catch (e: any) {
      mensagensImportacao = [
        {
          descricao: 'Erro inesperado',
          lista: <span>{e.message}</span>
        }
      ]
    }

    setListaMensagensImportacao(mensagensImportacao)
    setShowWaitDialog(false)
    setShowDialog(true)
    setImportando(false)
    setProgress(0)
    setTempoDecorrido('00:00:00')
  }

  useEffect(() => {
    if (progress === 0) {
      return
    }
    if (progress >= 0) {
      if (
        progress >= 100 &&
        tempoUpload.getTime() === inicioExecucao.getTime()
      ) {
        const newProcessamento = new Date()
        setTempoUpload(newProcessamento)
        setTimeout(() => {
          setTempo(newProcessamento)
          setTempoDecorrido(getTempoDecorrido(inicioExecucao, newProcessamento))
        }, 5000)
        return
      }
      if (progress >= 0)
        setTimeout(() => {
          const newTempo = new Date()
          setTempo(newTempo)
          setTempoDecorrido(getTempoDecorrido(inicioExecucao, newTempo))
        }, 5000)
    }
  }, [progress, tempo])

  /**
   * Fechar a caixa de diálogo de log e limpar os dados para uma nova importação
   */
  const handleLogDialogClose = () => {
    setShowDialog(false)
    handleCancelButtonClick()
  }

  // Handlers (FIM) ------------------------------------------------------------

  // Efeitos (INÍCIO) ----------------------------------------------------------
  useEffect(() => {
    if (descricaoTipoDocumentoSelecionada !== blankDescricaoTipoDocumento) {
      const newLista = [
        ...listaDescricoesDocumentos.filter(
          (item) =>
            item.tipoDocumento.id_tipo_documento !==
            descricaoTipoDocumentoSelecionada.tipoDocumento.id_tipo_documento
        ),
        descricaoTipoDocumentoSelecionada
      ]

      caixasImportar.forEach((caixaImporta) => {
        caixaImporta.documentos.forEach((documentoImportar) => {
          if (
            documentoImportar.documento.id_tipo_documento ===
            descricaoTipoDocumentoSelecionada.tipoDocumento.id_tipo_documento
          ) {
            const { metadados } = documentoImportar
            const meta = metadados
              .filter((metadado) =>
                descricaoTipoDocumentoSelecionada.descricao.some(
                  (descricao) => descricao === metadado.chave
                )
              )
              .map((meta) => {
                return `${meta.chave}:${meta.valor}`
              })
              .reduce((acc, cur) => {
                return acc === '' ? cur : `${acc} - ${cur}`
              }, '')
            documentoImportar.documento.descricao = meta
          }
        })
      })
      setListaDescricoesDocumentos(newLista)
      const forward = caixasImportar.every((caixa) => {
        return caixa.documentos.every((documento) => {
          return documento.documento.descricao !== ''
        })
      })
      if (forward) {
        let newListaDocumentosImportar: IDocumentoImportar[] = []
        caixasImportar.forEach((caixaImportar) => {
          newListaDocumentosImportar = [
            ...newListaDocumentosImportar,
            ...caixaImportar.documentos
          ]
        })
        setListaDocumentosImportar(newListaDocumentosImportar)
      } else {
        setListaDocumentosImportar([])
      }

      setStepForward(forward)
    }
  }, [descricaoTipoDocumentoSelecionada])
  // Efeitos (FIM) -------------------------------------------------------------

  if (importando) <Loading />

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '6px',
        background: '#FFFFFF',
        padding: '16px',
        gap: 2
      }}
    >
      {/* Cabeçalho 00000000000000000000000000000000000000000000000000000000 */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignContent: 'center',
          alignItems: 'center'
        }}
      >
        <FormTitle title="Importação de Documentos" />

        <Stack gap={2} flexDirection={'row'}>
          <Button
            variant="outlined"
            disabled={!(currentStep > 0)}
            startIcon={<BackIcon />}
            sx={{ fontWeight: 600 }}
            onClick={handlePreviousButtonClick}
          >
            Voltar
          </Button>

          <Button
            variant={currentStep === 4 ? 'contained' : 'outlined'}
            endIcon={
              currentStep === 3 ? (
                <SaveIcon sx={{ width: 24, height: 24 }} />
              ) : (
                <ForwardIcon />
              )
            }
            disabled={!stepForward}
            sx={{ fontWeight: 600 }}
            onClick={() =>
              currentStep < 3 ? handleNextButtonClick() : handleSubmitClick()
            }
          >
            {currentStep === 3 ? 'Confirmar' : 'Avançar'}
          </Button>
          <Button
            sx={{
              marginLeft: '16px',
              fontWeight: 600
            }}
            color="error"
            variant="outlined"
            startIcon={<Cancel />}
            onClick={handleCancelButtonClick}
          >
            Cancelar
          </Button>
        </Stack>
      </Box>
      <Timeline step={currentStep} steps={ImportSteps} />
      <Divider
        sx={{
          marginBottom: 2,
          marginTop: 2
        }}
      />
      {/* Passo 1 ########################################################## */}
      {currentStep === 0 ? (
        <Stack gap={2}>
          <Box
            sx={{
              border: '1px dashed  #E8E8E8',
              borderRadius: '4px',
              boxSizing: 'border-box',
              padding: '12px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              alignContent: 'center',
              verticalAlign: 'center'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
                alignItems: 'center',
                alignContent: 'center',
                verticalAlign: 'center',
                margin: '12px'
              }}
            >
              <img src={AddFileImg} alt={arquivoXML} />
              <Typography>{arquivoXML}</Typography>
            </Box>
            <Button
              startIcon={<ArrowUpward />}
              color="success"
              variant="outlined"
              component="label"
              sx={{
                height: '40px'
              }}
            >
              Procurar
              <input
                accept="text/xml"
                type="file"
                hidden
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleXMLSelect(event)
                }}
              />
            </Button>
          </Box>
          {processamentoXML.length > 0 ? (
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {processamentoXML.map((item, index) => {
                    return (
                      <TableRow key={`processamento${index}`}>
                        <TableCell>{item}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            ''
          )}

          {errosLeituraXML.length > 0 ? (
            <Box
              sx={{
                gap: 2
              }}
            >
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  sx={{
                    backgroundColor: '#F8F8F8'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 2,
                      alignItems: 'center',
                      alignContent: 'center',
                      verticalAlign: 'center',
                      margin: '12px'
                    }}
                  >
                    <Block
                      sx={{
                        color: 'red',
                        width: '24px',
                        height: '24px'
                      }}
                    />
                    <Typography>Erros</Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        {errosLeituraXML.map((item, index) => {
                          return (
                            <TableRow key={`erros${index}`}>
                              <TableCell>{item.descricao}</TableCell>
                              <TableCell>{item.lista}</TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            </Box>
          ) : (
            ''
          )}

          {alertasLeituraXML.length > 0 ? (
            <Box
              sx={{
                gap: 2
              }}
            >
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  sx={{
                    backgroundColor: '#F8F8F8'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 2,
                      alignItems: 'center',
                      alignContent: 'center',
                      verticalAlign: 'center',
                      margin: '12px'
                    }}
                  >
                    <Warning
                      sx={{
                        color: 'orange',
                        width: '32px',
                        height: '32px'
                      }}
                    />
                    <Typography>Alertas</Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        {alertasLeituraXML.map((item, index) => {
                          return (
                            <TableRow key={`alerta${index}`}>
                              <TableCell>{item.descricao}</TableCell>
                              <TableCell>{item.lista}</TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            </Box>
          ) : (
            ''
          )}
        </Stack>
      ) : (
        ''
      )}
      {/* Passo 2 @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */}
      {currentStep === 1 ? (
        <Stack gap={2}>
          <TableContainer sx={{ height: '200px' }}>
            <Table size="small" stickyHeader={true}>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      backgroundColor: '#F8F8F8'
                    }}
                  >
                    <Typography sx={{ fontWeight: 'bold', color: '#969696' }}>
                      Tipo de Documento
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: '#F8F8F8'
                    }}
                  >
                    <Typography sx={{ fontWeight: 'bold', color: '#969696' }}>
                      Metadados
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: '#F8F8F8'
                    }}
                  >
                    <Typography sx={{ fontWeight: 'bold', color: '#969696' }}>
                      Descrição Selecionada
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listaDescricoesDocumentos.map((item) => {
                  return (
                    <TableRow
                      key={item.tipoDocumento.id_tipo_documento}
                      onClick={() => {
                        handleTipoDocumentoSelect(item)
                      }}
                      selected={
                        item.tipoDocumento.id_tipo_documento ===
                        descricaoTipoDocumentoSelecionada.tipoDocumento
                          .id_tipo_documento
                      }
                    >
                      <TableCell>
                        {item.tipoDocumento.codigo} (
                        {item.tipoDocumento.descricao})
                      </TableCell>
                      <TableCell>
                        {arrayToList(item.chaves, 'chaves')}
                      </TableCell>
                      <TableCell>
                        {arrayToList(item.descricao, 'descricao')}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <ComponentGroup title="Metadados Disponíveis">
              <List
                sx={{
                  minWidth: '300px',
                  minHeight: '150px',
                  cursor: 'pointer'
                }}
              >
                {descricaoTipoDocumentoSelecionada.chaves.map((item) => {
                  return (
                    <ListItem
                      key={item}
                      sx={
                        item === metadadoSelecionado
                          ? { backgroundColor: '#EDF4FB' }
                          : {}
                      }
                      onClick={() => {
                        handleMetadadoClick(item)
                      }}
                    >
                      {item}
                    </ListItem>
                  )
                })}
              </List>
            </ComponentGroup>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'center',
                alignItems: 'center',
                gap: 2,
                justifyContent: 'space-around',
                width: '30%'
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  width: '100px'
                }}
                disabled={metadadoSelecionado === ''}
                onClick={() => {
                  handleDescricaoAddClick()
                }}
              >
                <KeyboardDoubleArrowRight />
              </Button>
              <Button
                variant="outlined"
                sx={{
                  width: '100px'
                }}
                disabled={descricaoSelecionada === ''}
                onClick={() => {
                  handleDescricaoRemoveClick()
                }}
              >
                <KeyboardDoubleArrowLeft />
              </Button>
            </Box>
            <ComponentGroup title="Metadados Selecionados">
              <List
                sx={{
                  minWidth: '300px',
                  minHeight: '150px',
                  cursor: 'pointer'
                }}
              >
                {descricaoTipoDocumentoSelecionada.descricao.map((item) => {
                  return (
                    <ListItem
                      key={item}
                      sx={
                        item === descricaoSelecionada
                          ? { backgroundColor: '#EDF4FB' }
                          : {}
                      }
                      onClick={() => {
                        handleDescricaoClick(item)
                      }}
                    >
                      {item}
                    </ListItem>
                  )
                })}
              </List>
            </ComponentGroup>
          </Box>
        </Stack>
      ) : (
        ''
      )}
      {/* Passo 3 ########################################################## */}
      {currentStep === 2 ? (
        <Box
          sx={{
            border: '1px dashed  #E8E8E8',
            borderRadius: '4px',
            boxSizing: 'border-box',
            padding: '12px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignContent: 'center',
            verticalAlign: 'center'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
              alignItems: 'center',
              alignContent: 'center',
              verticalAlign: 'center',
              margin: '12px'
            }}
            visibility={
              importMessagePDF.length === 0 || erroLeituraPDF.length > 0
                ? 'hidden'
                : 'visible'
            }
          >
            <img src={AddFileImg} alt={importMessagePDF} />
            <Typography>{importMessagePDF}</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
              alignItems: 'center',
              alignContent: 'center',
              verticalAlign: 'center',
              margin: '12px'
            }}
            visibility={erroLeituraPDF.length === 0 ? 'hidden' : 'visible'}
          >
            <Block
              sx={{
                color: 'red',
                width: '40px',
                height: '40px'
              }}
            />
            <Typography>{erroLeituraPDF}</Typography>
          </Box>
          <Button
            startIcon={<ArrowUpward />}
            color="success"
            variant="outlined"
            component="label"
            sx={{
              height: '40px'
            }}
          >
            Procurar
            <input
              accept="application/pdf"
              type="file"
              hidden
              multiple
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleFilesSelectChange(e)
              }}
            />
          </Button>
        </Box>
      ) : (
        ''
      )}
      {/* Passo 4 $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */}
      {currentStep === 3 ? (
        <>
          <Stack gap={2}>
            <TableContainer sx={{ height: '450px' }}>
              <Table stickyHeader={true} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        backgroundColor: '#F8F8F8'
                      }}
                    >
                      <Typography sx={{ fontWeight: 'bold', color: '#969696' }}>
                        {' '}
                        Descrição
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: '#F8F8F8'
                      }}
                    >
                      <Typography sx={{ fontWeight: 'bold', color: '#969696' }}>
                        Privacidade
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: '#F8F8F8'
                      }}
                    >
                      <Typography sx={{ fontWeight: 'bold', color: '#969696' }}>
                        Tipo de Documento
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: '#F8F8F8'
                      }}
                    >
                      <Typography sx={{ fontWeight: 'bold', color: '#969696' }}>
                        Metadados
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: '#F8F8F8'
                      }}
                    >
                      <Typography sx={{ fontWeight: 'bold', color: '#969696' }}>
                        Páginas
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: '#F8F8F8'
                      }}
                    >
                      <Typography sx={{ fontWeight: 'bold', color: '#969696' }}>
                        Importar PDF
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: '#F8F8F8'
                      }}
                    >
                      <FormControlLabel
                        sx={{ fontWeight: 'bold', color: '#969696' }}
                        label="Importar"
                        control={
                          <Checkbox
                            onChange={() => {
                              marcarDesmarcarTodos()
                            }}
                            checked={getMarcados() === 2}
                            indeterminate={getMarcados() === 1}
                          />
                        }
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listaDocumentosImportar.map((documentoImportar) => (
                    <TableRow
                      key={`${documentoImportar.numero}${documentoImportar.documento.id_caixa}`}
                    >
                      <TableCell>
                        {documentoImportar.documento.descricao}
                      </TableCell>
                      <TableCell>
                        {flagsToString(documentoImportar.documento)}
                      </TableCell>
                      <TableCell>
                        {getTipoDocumento(
                          documentoImportar.documento.id_tipo_documento,
                          listaDescricoesDocumentos
                        )}
                      </TableCell>
                      <TableCell>
                        {getListaMetadados(documentoImportar.documento)}
                      </TableCell>
                      <TableCell>
                        {documentoImportar.documento.nr_paginas}
                      </TableCell>
                      <TableCell>
                        {documentoImportar.caminho.trim() !== '' &&
                        documentoImportar.dadosArquivo.size > 0 ? (
                          <StatusBox caption="Sim" status="Ok" />
                        ) : (
                          <StatusBox caption="Não" status="Error" />
                        )}
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          onChange={() => {
                            handleImportarNovoChange(documentoImportar)
                          }}
                          checked={documentoImportar.importar !== 0}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
          <LogDialog
            showDialog={showDialog}
            closeHandler={handleLogDialogClose}
            log={listaMensagensImportacao}
            informacoesAdicionais={[
              {
                descricao: 'Tempo de upload',
                lista: (
                  <span>{getTempoDecorrido(inicioExecucao, tempoUpload)}</span>
                )
              },
              {
                descricao: 'Tempo de processamento',
                lista: <span>{getTempoDecorrido(tempoUpload, tempo)}</span>
              },
              {
                descricao: 'Tempo total decorrido',
                lista: <span>{getTempoDecorrido(inicioExecucao, tempo)}</span>
              }
            ]}
          />
          <Dialog open={showWaitDialog} fullWidth>
            <Box
              display={'flex'}
              flexDirection={'column'}
              width={'100%'}
              height={300}
              bgcolor={'#fff'}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                alignContent: 'center',
                alignItems: 'center',
                padding: '2rem'
              }}
            >
              <img
                src={Processando}
                alt={'Processando'}
                width={'35%'}
                height={'50%'}
              />
              <Box
                sx={{
                  width: '100%',
                  margin: '3rem',
                  padding: '1rem',
                  display: 'flex',
                  flexDirection: 'column',
                  alignContent: 'center',
                  alignItems: 'center'
                }}
              >
                {progress < 100 && (
                  <Box
                    width={'100%'}
                    bgcolor={'#555'}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      height: '0.5rem',
                      margin: 0,
                      padding: 0
                    }}
                  >
                    <Box
                      bgcolor={'#ddd'}
                      sx={{
                        width: `${progress}%`,
                        height: '0.5rem',
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    ></Box>
                  </Box>
                )}
                {progress < 100 && (
                  <span>
                    Enviado ({progress}% - {tempoDecorrido}) ...
                  </span>
                )}
                {progress >= 100 && (
                  <span>
                    Aguardando resposta de processamento ({tempoDecorrido})...{' '}
                  </span>
                )}
              </Box>
            </Box>
          </Dialog>
        </>
      ) : (
        ''
      )}
    </Box>
  )
}
