import {
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Typography
} from '@mui/material'
import { IPosicaoPrateleira } from '../../../../services/posicaoPrateleira/types'

interface PositionDescriptorProps {
  posicao: IPosicaoPrateleira
  enderecoPrateleira: string
}

export function PositionDescriptor({
  posicao,
  enderecoPrateleira
}: PositionDescriptorProps) {
  return (
    <TableContainer key={`POS-DESCR-${posicao.id_posicao_prateleira}`}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography fontWeight={'bold'}>Endereço</Typography>
            </TableCell>
            <TableCell>
              <Typography fontWeight={'bold'}>
                {`${enderecoPrateleira}.${posicao.identificacao}`}
              </Typography>
            </TableCell>
          </TableRow>
          {posicao.caixa ? (
            <>
              <TableRow>
                <TableCell>
                  <Typography fontWeight={'bold'}>{`Estado`}</Typography>
                </TableCell>

                <TableCell>Ocupada</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography fontWeight={'bold'}>{`Caixa`}</Typography>
                </TableCell>
                <TableCell>{`${posicao.caixa.identificacao} (${
                  posicao.caixa.tipoCaixa?.descricao ?? 'CAIXA'
                })`}</TableCell>
              </TableRow>
            </>
          ) : (
            <TableRow>
              <TableCell>
                <Typography fontWeight={'bold'}>{`Estado`}</Typography>
              </TableCell>

              <TableCell>Vazia</TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>
              <Typography fontWeight={'bold'}>Estado</Typography>
            </TableCell>
            <TableCell>
              {`${posicao.habilitada ? 'Habilitada' : 'Desabilitada'}`}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
