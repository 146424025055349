import { DefaultApi } from '../../utils/defaultApi'
import { IDestinacaoDocumento } from './types'

const endpoint = '/destinacao-documento'
const resourceId = 'id_municipio'

export const ApiDestinacaoDocumento = new DefaultApi<IDestinacaoDocumento>(
  endpoint,
  resourceId
)
