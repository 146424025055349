import { ApiRelatorio } from '../../../../../services/relatorio'
import { PrazoGuardaReport } from './report/PrazoGuardaReport'
import {
  IHandleReportSubmitProps,
  ReportForm
} from '../../../../../components/ReportForm'

export function PrazoGuardaRelatorio() {
  async function handleSubmitForm({
    mode,
    format,
    id_usuario,
    filtros
  }: IHandleReportSubmitProps) {
    const {
      data_fim,
      data_inicio,
      id_empresa,
      id_setor,
      tipoConteudo,
      id_tipo_temporalidade,
      tipoPrazo
    } = filtros

    const prazoGuardaData = await ApiRelatorio.getPrazoGuarda({
      id_empresa,
      id_setor,
      data_fim,
      data_inicio,
      tipoConteudo,
      id_tipo_temporalidade,
      tipoPrazo
    })

    if (format === 'pdf') {
      PrazoGuardaReport({
        data: prazoGuardaData,
        mode,
        id_usuario: id_usuario,
        filtros: {
          id_empresa,
          id_setor,
          data_fim,
          data_inicio,
          tipoConteudo,
          id_tipo_temporalidade,
          tipoPrazo
        }
      })
    }

    return prazoGuardaData
  }
  return (
    <ReportForm
      title="Prazo de Guarda"
      handleReportSubmit={handleSubmitForm}
      mostrarPeriodo={true}
      isSetorObrigatorio={true}
      mostrarTipoConteudo={true}
      mostrarTemporalidade={true}
    />
  )
}
