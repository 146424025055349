import { useState } from 'react'
import { useQueryClient } from 'react-query'
import {
  Box,
  Button,
  Stack,
  InputAdornment,
  IconButton,
  List,
  ListItem
} from '@mui/material'
import SaveIcon from '@mui/icons-material/CheckCircleOutlined'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import PasswordIcon from '@mui/icons-material/Key'

import { FormTitle } from '../../../components/Form/FormTitle'
import { Input } from '../../../components/Form/Input'
import { useUsuario } from '../../../hooks/usuario/useUsuario'
import useToast from '../../../hooks/toast/useToast'
import { useAuth } from '../../../hooks/auth'
import {
  containsLowercase,
  containsMinMaxLength,
  containsNumber,
  containsSpecialChars,
  containsUppercase
} from '../../../utils/stringValidation'

export function ResetPassword() {
  const [showPassword, setShowPassword] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const { mutateAsync: resetPassword } = useUsuario.ResetPassword()
  const queryClient = useQueryClient()
  const { Toast } = useToast()
  const { signOut } = useAuth()

  const validationItems = [
    {
      condition: containsUppercase(newPassword),
      label: '1 letra maiúsculas. Ex.: A B C D E F G H'
    },
    {
      condition: containsLowercase(newPassword),
      label: '1 letra minúscula. Ex.: a b c d e f g h'
    },
    {
      condition: containsNumber(newPassword),
      label: '1 número. Ex.: 0 1 2 3 4 5 6 7 8 9'
    },
    {
      condition: containsSpecialChars(newPassword),
      label: '1 símbolos. Ex.: @ # $ ! *'
    },
    {
      condition: containsMinMaxLength(newPassword, 6, 20),
      label: 'e no mínimo 6 e no máximo 20 caracteres'
    }
  ]

  async function handleResetPassword() {
    await Toast(resetPassword(newPassword), 'UPDATED')
    queryClient.removeQueries()
    signOut()
  }

  function validadePassword(str: string) {
    return (
      containsUppercase(str) &&
      containsLowercase(str) &&
      containsNumber(str) &&
      containsSpecialChars(str) &&
      containsMinMaxLength(str, 6, 20)
    )
  }

  return (
    <Box bgcolor="#fff" p={4} borderRadius={2}>
      <FormTitle title="Alterar senha do usuário" />

      <Stack direction="row" alignItems="center" gap={1}>
        Digite uma <strong>nova senha</strong>
        <PasswordIcon fontSize="large" /> que contenha pelo menos:
      </Stack>

      <List sx={{ fontSize: 13, lineHeight: 1 }}>
        {validationItems.map((item, index) => (
          <ListItem key={index}>
            {item.condition ? '🟢' : '🔴'} {item.label}
          </ListItem>
        ))}
      </List>

      <Input
        label="Nova Senha"
        placeholder="Nova Senha"
        type={showPassword ? 'text' : 'password'}
        sx={{ mt: 4 }}
        onChange={(e) => setNewPassword(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />

      <Stack direction="row" gap={2} mt={3}>
        <Button
          variant="contained"
          startIcon={<SaveIcon />}
          sx={{ width: '110px' }}
          disabled={!validadePassword(newPassword)}
          onClick={handleResetPassword}
        >
          Salvar
        </Button>
      </Stack>
    </Box>
  )
}
