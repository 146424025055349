import { Inventory2Outlined, InventoryOutlined } from '@mui/icons-material'
import { Box, Typography, alpha } from '@mui/material'
import { getCodigo } from '../../../utils/getCodigo'
import { formata } from '../../../utils/formataData'
import { IMovimentacaoExtendida } from '../../../services/movimentacao/types'

const corPendente = 'rgba(0, 186, 52, 0.1)'
const corPendenteTexto = 'rgb(0, 186, 52)'
const corExecutando = 'rgba(0, 132, 255, 0.3)'
const corExecutandoTexto = 'rgb(0, 133, 255)'
const corAtrasoPendente = 'rgba(233, 44, 44, 0.15)'
const corAtrasoPendenteTexto = 'rgb(233, 44, 44)'
const corAtrasoExecucao = 'rgba(249, 134, 0, 0.15)'
const corAtrasoExecucaoTexto = 'rgb(249, 134, 0)'

type StatusAtividade =
  | 'EXECUTANDO'
  | 'EXECUTANDO-ATRASADO'
  | 'PENDENTE'
  | 'PENDENTE-ATRASADO'

interface DashboardWidgetListCardProps {
  movimentacao: IMovimentacaoExtendida
  onClickWidget: (movimentacao: IMovimentacaoExtendida) => void
}

export function DashboardWidgetListCard({
  onClickWidget,
  movimentacao
}: DashboardWidgetListCardProps) {
  const colorPalete = {
    EXECUTANDO: {
      light: corExecutando,
      standard: 'rgba(0, 132, 255, 0.5)',
      dark: corExecutandoTexto
    },
    'EXECUTANDO-ATRASADO': {
      light: corAtrasoExecucao,
      standard: 'rgb(248, 210, 126)',
      dark: corAtrasoPendenteTexto
    },
    PENDENTE: {
      light: corPendente,
      standard: '#ddd',
      dark: corPendenteTexto
    },
    'PENDENTE-ATRASADO': {
      light: corAtrasoPendente,
      standard: '#fbb',
      dark: corAtrasoExecucaoTexto
    }
  } as const

  const status: StatusAtividade =
    movimentacao.atraso > 0
      ? movimentacao.id_usuario
        ? 'EXECUTANDO-ATRASADO'
        : 'PENDENTE-ATRASADO'
      : movimentacao.id_usuario
      ? 'EXECUTANDO'
      : 'PENDENTE'

  const styleHover = {
    borderRadius: '8px',
    maxWidth: '382px',
    backgroundColor: alpha(colorPalete[status].light, 0.5),
    '&:hover': {
      boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.25)',
      backgroundColor: alpha(colorPalete[status].light, 1)
    }
  }

  const titulo = movimentacao.item_requisicao?.caixa
    ? `${movimentacao.item_requisicao.caixa.identificacao} (${getCodigo(
        movimentacao.item_requisicao.id_caixa
      )})`
    : `${movimentacao.item_requisicao?.documento?.descricao} (${getCodigo(
        movimentacao.item_requisicao?.id_documento ?? ''
      )})`
  return (
    <Box
      justifyContent="space-between"
      bgcolor={colorPalete[status].light}
      px="1rem"
      sx={styleHover}
      onClick={() => onClickWidget(movimentacao)}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          pt: '0.5rem'
        }}
      >
        <Typography fontWeight="700">{titulo}</Typography>
        <Typography color="white">
          {movimentacao.tipoItem === 'Caixa' ? (
            <Inventory2Outlined
              sx={{
                fontSize: '2.5rem'
              }}
            />
          ) : (
            <InventoryOutlined
              sx={{
                fontSize: '2.5rem'
              }}
            />
          )}
        </Typography>
      </Box>
      <Box
        bgcolor={colorPalete[status].standard}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1',
          justifyContent: 'space-between',
          px: '0.5rem',
          borderRadius: '8px',
          mb: '1rem'
        }}
      >
        <Box sx={{ lineHeight: '0.8rem' }}>
          <h4>
            {
              movimentacao?.item_requisicao?.requisicao?.item_contrato?.contrato
                ?.empresa_contratante?.nome
            }
          </h4>
          <p>
            Requisição:{' '}
            {getCodigo(
              movimentacao?.item_requisicao?.requisicao?.id_requisicao ?? ''
            )}
          </p>
          <hr />

          <h4>Prazos</h4>
          <p>
            Iniciada em:
            {formata(
              new Date(movimentacao.data_inicio),
              "dd/MM/yyyy 'às' hh:mm"
            )}
          </p>
          <p>SLA (em horas): {movimentacao.preco_atividade?.sla}</p>
          <p>Atraso (em horas): {movimentacao.atraso}</p>
        </Box>
      </Box>
    </Box>
  )
}
