import { DefaultApi } from '../../utils/defaultApi'
import { IResult, TAtivo } from '../../utils/defaultApi/types'
import api from '../api'
import { ISetor } from './types'

const endpoint = '/setor'
const resourceId = 'id_setor'

class SetorService extends DefaultApi<ISetor> {
  public findByEmpresa = async (id: string): Promise<ISetor[]> => {
    if (!id || id === '') {
      return []
    }
    const { data } = await api.get<ISetor[]>(`${this.endpoint}/empresa/${id}`)
    return data
  }

  public inactivate = async (id_setor: string): Promise<ISetor> => {
    const { data } = await api.put<ISetor>(
      `${this.endpoint}/inactivate/${id_setor}`
    )
    return data
  }

  findAllByDrive = async (
    page: number,
    q?: string,
    ativo: TAtivo = true
  ): Promise<IResult<ISetor>> => {
    const pageString = page ? `page=${page}` : ''
    const qString = q ? `q=${q}` : ''
    const ativoString = ativo !== undefined ? `ativo=${ativo}` : ''
    const params = [pageString, qString, ativoString]
      .filter((value) => value !== '')
      .join('&')

    const { data } = await api.get<IResult<ISetor>>(
      `${this.endpoint}/drive/?${params}`
    )
    return data
  }
}

export const ApiSetor = new SetorService(endpoint, resourceId)
