import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ApiMovimentoContaCorrente } from '../../../services/conta-corrente/movimento'

const QUERY_KEY = 'qkMovimentoContaCorrente'

const FindAll = (page: number, q = '') => {
  return useQuery([QUERY_KEY, q, page], () =>
    ApiMovimentoContaCorrente.findAll(page, q)
  )
}

const ListAll = () => {
  return useQuery([QUERY_KEY], ApiMovimentoContaCorrente.listAll)
}

const Create = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiMovimentoContaCorrente.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Update = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiMovimentoContaCorrente.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Remove = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiMovimentoContaCorrente.remove, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Saldo = (id_conta_corrente: string) => {
  return useQuery([QUERY_KEY, id_conta_corrente], () =>
    ApiMovimentoContaCorrente.getSaldoConta(id_conta_corrente)
  )
}

export const useMovimentoContaCorrente = {
  Create,
  Update,
  FindAll,
  ListAll,
  Remove,
  Saldo
}
