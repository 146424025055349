import { Box, Typography } from '@mui/material'
import page404Img from '../../assets/images/page404.svg'

export function PageNotFound() {
  return (
    <Box display="flex" alignItems="center" flexDirection="column" gap={4}>
      <Typography variant="h4">Desculpe, página não encontrada!</Typography>
      <Typography variant="h6">
        Por favor, certifique-se que digitou a URL corretamente.
      </Typography>
      <img src={page404Img} alt="página não encontrada" />
    </Box>
  )
}
