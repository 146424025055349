import { Box } from '@mui/material'
import { ReactNode } from 'react'

interface DashboardWidgetHeaderSummaryDatabarRootProps {
  children: ReactNode
}

export function DashboardWidgetHeaderSummaryDatabarRoot({
  children
}: DashboardWidgetHeaderSummaryDatabarRootProps) {
  return (
    <Box
      sx={{
        width: '100%',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '10px',
        lineHeight: '12px',
        color: '#969696',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '0.5rem',
        alignItems: 'top'
      }}
    >
      {children}
    </Box>
  )
}
