import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ApiArvoreDocumental } from '../../services/arvore-documental'
import { useAuth } from '../auth'

const QUERY_KEY = 'qkArvoreDocumental'

const FindByIdEmpresa = (id_empresa: string) => {
  return useQuery([QUERY_KEY, id_empresa], () =>
    ApiArvoreDocumental.findByEmpresa(id_empresa)
  )
}

const FindTiposDocumentos = () => {
  const { user } = useAuth()
  return useQuery([QUERY_KEY, user.username], () =>
    ApiArvoreDocumental.findTiposDocumentosByUsuario()
  )
}

const FindTiposDocumentosByContrato = () => {
  const { user } = useAuth()
  return useQuery([QUERY_KEY, user.username, 'contrato'], () =>
    ApiArvoreDocumental.findTiposDocumentosByUsuarioContrato()
  )
}

const FindTiposDocumentosBySetor = (id_setor: string) => {
  return useQuery([QUERY_KEY, id_setor], () =>
    ApiArvoreDocumental.findTiposDocumentosBySetor(id_setor)
  )
}

const Update = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiArvoreDocumental.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

export const useArvoreDocumental = {
  FindByIdEmpresa,
  FindTiposDocumentos,
  FindTiposDocumentosBySetor,
  FindTiposDocumentosByContrato,
  Update
}
