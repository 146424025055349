import {
  CallMadeRounded,
  DeleteOutline,
  PanToolRounded
} from '@mui/icons-material'
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material'
import { useEffect, useState } from 'react'

import { IServico } from '../../../../../services/servico/types'
import { ITipoAtividade } from '../../../../../services/tipoAtividade/types'
import { FormMode } from '../../../../../types/formMode'
import { TTipoAcao } from '../../Form'
import { ItemSelector } from '../ItemSelector'
import { useServico } from '../../../../../hooks/servico/useServico'
import { Loading } from '../../../../../components/Loading'

interface FormHeaderProps {
  formMode: FormMode
  data: IServico
  acao: TTipoAcao
  changeAcao: (acao: TTipoAcao) => void
  setTipoAtividade: (tipoAtividade: ITipoAtividade) => void
  listaTipoAtividade: ITipoAtividade[]
  servico: IServico
  changeServico: (servico: IServico) => void
  disableControls: boolean
}

export function FormHeader({
  formMode,
  data,
  acao,
  changeAcao,
  setTipoAtividade,
  listaTipoAtividade,
  servico,
  changeServico,
  disableControls
}: FormHeaderProps) {
  const [listServicos, setLisServicos] = useState<IServico[]>([])
  const { data: fetchedListServicos, isLoading } = useServico.ListAll()

  useEffect(() => {
    const fetchServicos = () => {
      if ((!data || !data.id_servico) && !isLoading) {
        const listServicosValidos = fetchedListServicos!.filter(
          (servico) => !servico.modelagem_fluxo
        )
        setLisServicos(listServicosValidos)
        if (data.id_servico) {
          handleSelectServico(data.id_servico)
        }
      }
    }

    fetchServicos()
  }, [data, isLoading])

  function handleSelectServico(id_servico: string) {
    const newServico = listServicos.find(
      (itemServico) => itemServico.id_servico === id_servico
    )
    changeServico(newServico ?? data)
  }

  function handleChangeAcao(
    event: React.MouseEvent<HTMLElement>,
    newAcao: string | null
  ) {
    if (newAcao) changeAcao(newAcao as TTipoAcao)
  }

  if (isLoading) <Loading />

  return (
    <Box
      sx={{
        width: '1000px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '500px',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        {formMode === 'EDIT' || formMode === 'VIEW' ? (
          <Typography fontSize={'1.2rem'}>{data.nome}</Typography>
        ) : (
          <FormControl size="small">
            <InputLabel id="label-tipo-atividade">Serviço</InputLabel>
            <Select
              labelId="label-tipo-atividade"
              value={servico.id_servico}
              label="Serviço"
              sx={{
                width: '200px',
                height: '2.5rem'
              }}
              onChange={(event) => handleSelectServico(event.target.value)}
              disabled={disableControls}
            >
              {listServicos.map((itemServico) => {
                return (
                  <MenuItem
                    value={itemServico.id_servico}
                    key={itemServico.id_servico}
                  >
                    {itemServico.nome}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        )}

        <ItemSelector
          handleSelectTipoAtividade={setTipoAtividade}
          listaTiposAtividades={listaTipoAtividade}
          disableControls={disableControls}
        />
      </Box>

      <ToggleButtonGroup
        value={acao}
        exclusive
        onChange={handleChangeAcao}
        aria-label="Ferramentas"
        disabled={disableControls}
      >
        <ToggleButton
          value="SELECIONA"
          aria-label="Mover Atividade"
          sx={{
            gap: '6px',
            display: 'flex',
            flexDirection: 'column',
            width: '120px',
            height: '60px'
          }}
        >
          <PanToolRounded sx={{ fontSize: '2rem' }} />
          <Typography fontSize="0.5rem">Seleção</Typography>
        </ToggleButton>
        v
        <ToggleButton
          value="PADRAO"
          aria-label="Fluxo Padrão"
          color="primary"
          sx={{
            gap: '6px',
            display: 'flex',
            flexDirection: 'column',
            width: '120px',
            height: '60px'
          }}
        >
          <CallMadeRounded sx={{ fontSize: '2rem' }} />
          <Typography fontSize="0.5rem">Fluxo Padrão</Typography>
        </ToggleButton>
        <ToggleButton
          value="ALTERNATIVO"
          aria-label="Fluxo Alternativo"
          color="warning"
          sx={{
            gap: '6px',
            display: 'flex',
            flexDirection: 'column',
            width: '120px',
            height: '60px'
          }}
        >
          <CallMadeRounded sx={{ fontSize: '2rem' }} />
          <Typography fontSize="0.5rem">Fluxo Alternativo</Typography>
        </ToggleButton>
        <ToggleButton
          value="APAGA"
          aria-label="Apagar atividade ou sequência"
          color="error"
          sx={{
            gap: '6px',
            display: 'flex',
            flexDirection: 'column',
            width: '120px',
            height: '60px'
          }}
        >
          <DeleteOutline sx={{ fontSize: '2rem' }} />
          <Typography fontSize="0.5rem">Apagar</Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  )
}
