import { useState } from 'react'
import { Box } from '@mui/material'
import { Form } from './Form'
import { List } from './List'
import { FormMode } from '../../../types/formMode'
import { FormTitle } from '../../../components/Form/FormTitle'
import { useSetorPermitido } from '../../../hooks/setorPermitido/useSetorPermitido'
import { useContrato } from '../../../hooks/contrato/useContrato'
import { Loading } from '../../../components/Loading'

export function Documentos() {
  const [formMode, setFormMode] = useState<FormMode>('LIST')
  const [data, setData] = useState<any>({})
  const [showEditMetadata, setShowEditMetadata] = useState(false)
  const { data: contratos, isLoading: isLoadingContratos } =
    useContrato.ListAll()
  const { data: setoresPermitidos } = useSetorPermitido.FindByUsuarioLogado()

  if (isLoadingContratos) <Loading />

  return (
    <Box bgcolor="#fff" p={4} borderRadius={2}>
      <FormTitle title="Documentos" subtitle={formMode} />

      {formMode === 'LIST' ? (
        <List
          setData={setData}
          setFormMode={setFormMode}
          contratos={contratos}
          setores={setoresPermitidos}
          setShowEditMetadata={setShowEditMetadata}
        />
      ) : (
        <Form
          data={formMode === 'EDIT' ? data : {}}
          formMode={formMode}
          setFormMode={setFormMode}
          showEditMetadata={showEditMetadata}
        />
      )}
    </Box>
  )
}
