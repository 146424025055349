import { useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  Chip,
  Divider,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  FormControlLabel,
  Checkbox,
  Stack
} from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import AddIcon from '@mui/icons-material/AddCircle'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import TagIcon from '@mui/icons-material/BallotOutlined'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { useCaixa } from '../../../hooks/caixa/useCaixa'
import useToast from '../../../hooks/toast/useToast'
import { FormMode } from '../../../types/formMode'
import { Loading } from '../../../components/Loading'
import { Etiqueta } from './Etiqueta'
import { useUsuario } from '../../../hooks/usuario/useUsuario'
import { Permissions } from '../../../types/permissions'
import { hasPermission } from '../../../utils/hasPermission'
import { format, parseISO } from 'date-fns'
import { getCodigo } from '../../../utils/getCodigo'
import { SearchBox } from '../../../components/SearchBox'
import { AdvancedFindAllParams, ICaixa } from '../../../services/caixa/types'
import { useTipoCaixa } from '../../../hooks/tipoCaixa/useTipoCaixa'
import { useArvoreDocumental } from '../../../hooks/arvoreDcoumental/useArvoreDocumental'
import { PlayArrow } from '@mui/icons-material'
import Clear from '@mui/icons-material/Clear'
import { formata } from '../../../utils/formataData'
import { useTipoDocumento } from '../../../hooks/tipoDocumento/useTipoDocumento'
import { IContrato } from '../../../services/contrato/types'
import { ISetor } from '../../../services/setor/types'
import { Dialog } from '../../../components/Dialog'
import { truncateString } from '../../../utils/truncateString'
import { hasAccess } from '../../../utils/hasAccess'

interface IForm {
  setData: (value: React.SetStateAction<any>) => void
  setFormMode: (value: React.SetStateAction<FormMode>) => void
  isInventario?: boolean
  contratos?: IContrato[]
  setores?: ISetor[]
}

export function List({
  setData,
  setFormMode,
  isInventario = false,
  contratos = [],
  setores = []
}: IForm) {
  const searchParams2AdvanceSearchParams = (
    search: URLSearchParams
  ): AdvancedFindAllParams => {
    let newAdv: AdvancedFindAllParams = {}
    if (search.get('busca') !== null) {
      newAdv = {
        ...newAdv,
        busca: search.get('busca') ?? undefined
      }
    }
    if (search.get('data') !== null) {
      newAdv = {
        ...newAdv,
        data: search.get('data') ?? undefined
      }
    }
    if (search.get('sequencia') !== null) {
      newAdv = {
        ...newAdv,
        sequencia: search.get('sequencia') ?? undefined
      }
    }
    if (search.get('id_tipo_caixa') !== null) {
      newAdv = {
        ...newAdv,
        id_tipo_caixa: search.get('id_tipo_caixa') ?? undefined
      }
    }
    if (search.get('id_tipo_documento') !== null) {
      newAdv = {
        ...newAdv,
        id_tipo_documento: search.get('id_tipo_documento') ?? undefined
      }
    }
    if (search.get('dados_pessoais') !== null) {
      newAdv = {
        ...newAdv,
        dados_pessoais: search.get('dados_pessoais') ?? undefined
      }
    }
    if (search.get('dados_pessoais_sensiveis') !== null) {
      newAdv = {
        ...newAdv,
        dados_pessoais_sensiveis:
          search.get('dados_pessoais_sensiveis') ?? undefined
      }
    }
    if (search.get('dados_criancas_adolescentes') !== null) {
      newAdv = {
        ...newAdv,
        dados_criancas_adolescentes:
          search.get('dados_criancas_adolescentes') ?? undefined
      }
    }

    if (search.get('endereco') !== null) {
      newAdv = {
        ...newAdv,
        endereco: search.get('endereco') ?? undefined
      }
    }

    return newAdv
  }

  const [showDismiss, setShowDismiss] = useState<boolean>(false)
  const [currentBoxId, setCurrentBoxId] = useState<string>('')

  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedOrder, setSelectedOrder] = useState<string>('Contrato')

  const [advancedSearchParams, setAdvancedSearchParam] =
    useState<AdvancedFindAllParams>(
      searchParams2AdvanceSearchParams(searchParams)
    )

  const { Toast } = useToast()
  const { data: usuario } = useUsuario.FindByUsername()

  const page = useMemo(() => {
    return Number(searchParams.get('page') ?? 1)
  }, [searchParams])

  const { mutateAsync: remove } = useCaixa.Remove()
  const { data: tiposCaixas, isLoading: isLoadingTiposCaixas } =
    useTipoCaixa.ListAll()
  const { data: tiposDocumentos, isLoading: isLoadingTiposDocumentos } =
    isInventario
      ? useTipoDocumento.ListAll()
      : useArvoreDocumental.FindTiposDocumentos()

  const { data: records, isLoading: isLoadingCaixas } = useCaixa.FindAll(page, {
    ...advancedSearchParams,
    is_inventario: isInventario,
    ordem: selectedOrder
  })

  const [listaVolumes, setListaVolumes] = useState<Array<ICaixa>>([])
  const [itensSelecionados, setItensSelecionados] = useState<string[]>([])
  const [todasSelecionadas, setTodasSelecionadas] = useState<boolean>(false)

  const estadoInventariando = 'INVENTARIANDO'
  const estadoCriadoCliente = 'CRIADO_CLIENTE'

  const estadosCriacao = [estadoCriadoCliente, estadoInventariando]

  const { mutateAsync: homologate } = useCaixa.Homologate()
  const { mutateAsync: homologateAdvanced } = useCaixa.HomologateAdvanced()

  useEffect(() => {
    if (!isLoadingCaixas) {
      if (records) {
        const { data } = records
        setListaVolumes(
          data.sort((a, b) => {
            if (a.estado === b.estado) {
              if (a.identificacao > b.identificacao) {
                return 1
              }
              if (a.identificacao < b.identificacao) {
                return -1
              }
              return 0
            }
            if (a.estado === estadoCriadoCliente) {
              return -1
            }
            return 1
          })
        )
      }
    }
  }, [records])

  const handleNewForm = () => {
    setFormMode('CREATE')
  }

  const handleRemove = async () => {
    if (hasPermission(usuario?.permissoes, Permissions.EXCLUIR_CAIXA)) {
      await Toast(remove(currentBoxId), 'REMOVED')
      setSearchParams({ page: '1' })
    }
    setCurrentBoxId('')
    setShowDismiss(false)
  }

  const handleEditForm = (data: any) => {
    if (hasPermission(usuario?.permissoes, Permissions.EDITAR_CAIXA)) {
      setData(data)
      setFormMode('EDIT')
    }
  }

  const setSearchCriteria = (newSearchParams: AdvancedFindAllParams) => {
    setSearchParams({ ...searchParams, ...newSearchParams })
    setAdvancedSearchParam(newSearchParams)
  }

  const handleItemSelection = (item: string) => {
    // Verifica se o item já está na lista de selecionados
    if (itensSelecionados.includes(item)) {
      // Se estiver, remove-o da lista
      const novosItensSelecionados = itensSelecionados.filter(
        (selectedItem) => selectedItem !== item
      )
      setTodasSelecionadas(false)
      setItensSelecionados(novosItensSelecionados)
    } else {
      // Se não estiver, adiciona-o à lista
      setItensSelecionados([...itensSelecionados, item])
    }
  }

  const handleClearSelection = () => {
    setItensSelecionados([])
    setTodasSelecionadas(false)
  }

  const handleHomologateBoxes = async () => {
    if (todasSelecionadas) {
      await Toast(
        homologateAdvanced(advancedSearchParams),
        undefined,
        'Caixas Homologadas com sucesso!'
      )
    } else
      await Toast(
        homologate(itensSelecionados),
        undefined,
        'Caixas Homologadas com sucesso!'
      )
    setItensSelecionados([])
    setTodasSelecionadas(false)
  }

  const checkIfDisabled = (item: ICaixa) => {
    const achouSetor = setores?.find(
      (setor) => setor.id_setor === item.id_setor
    )
      ? true
      : false
    return !achouSetor
  }

  const descricaoBusca =
    'Código/Identificação/Estado atual/Descrição de documento/Metadados de documentos/Descrição de contrato/Requisição/Conteúdo'

  if (isLoadingCaixas || isLoadingTiposCaixas || isLoadingTiposDocumentos)
    return <Loading />
  return (
    <Box>
      <SearchBox
        setAdvancedSearchParams={setSearchCriteria}
        descricaoBusca={
          isInventario ? descricaoBusca + '/Nome Setor' : descricaoBusca
        }
        sequenciaExibir={true}
        sequencia={advancedSearchParams.sequencia}
        dataExibir={true}
        data={advancedSearchParams.data}
        tipoCaixaExibir={true}
        tiposCaixas={tiposCaixas}
        idTipoCaixa={advancedSearchParams.id_tipo_caixa}
        tipoDocumentoExibir={true}
        tipoDocumentos={tiposDocumentos}
        idTipoDocumento={advancedSearchParams.id_tipo_documento}
        busca={advancedSearchParams.busca}
        dadosPessoaisExibir={true}
        dadosPessoais={advancedSearchParams.dados_pessoais}
        dadosCriancasAdolescentesExibir={true}
        dadosCriancasAdolescentes={
          advancedSearchParams.dados_criancas_adolescentes
        }
        dadosPessoaisSensiveisExibir={true}
        dadosPessoaisSensiveis={advancedSearchParams.dados_pessoais_sensiveis}
        enderecoExibir={!isInventario}
        endereco={advancedSearchParams.endereco}
        contratos={contratos}
        idContrato={advancedSearchParams.id_contrato}
        dadosContratoExibir={true}
        setores={setores}
        idSetor={advancedSearchParams.id_setor}
        dadosSetorExibir={true}
      />
      <Divider />
      {isInventario &&
        listaVolumes.length > 0 &&
        (itensSelecionados.length > 0 || todasSelecionadas) && (
          <Stack direction={'row'} gap={2}>
            {hasAccess(
              usuario?.permissoes,
              Permissions.HOMOLOGAR_INVENTARIO
            ) && (
              <Button
                variant="contained"
                startIcon={<PlayArrow />}
                onClick={handleHomologateBoxes}
                sx={{ float: 'right', my: '12px' }}
                color={'success'}
              >
                Homologar
              </Button>
            )}

            <Button
              onClick={handleClearSelection}
              color="secondary"
              variant="outlined"
              startIcon={<Clear />}
              style={{ fontWeight: 'bold' }}
              sx={{ float: 'right', my: '12px' }}
            >
              Limpar
            </Button>
          </Stack>
        )}

      {(isInventario ||
        hasAccess(usuario?.permissoes, Permissions.NOVO_REGISTRO_CAIXA)) && (
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleNewForm}
          sx={{ float: 'right', my: '12px' }}
        >
          Novo registro
        </Button>
      )}

      <TableContainer
        component={Box}
        border={1}
        borderRadius={2}
        borderColor="#f2f2f2"
      >
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
              {isInventario && (
                <TableCell>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => {
                          if (todasSelecionadas) {
                            setItensSelecionados([])
                          }
                          setTodasSelecionadas(!todasSelecionadas)
                        }}
                        checked={todasSelecionadas}
                      />
                    }
                    label=""
                  />
                </TableCell>
              )}
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  minWidth: 150,
                  cursor: 'pointer'
                }}
                onClick={() => {
                  selectedOrder === 'Contrato'
                    ? setSelectedOrder('ContratoInv')
                    : setSelectedOrder('Contrato')
                }}
              >
                Contrato
                {selectedOrder === 'Contrato' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  maxWidth: 150,
                  cursor: 'pointer'
                }}
                onClick={() => {
                  selectedOrder === 'Identificacao'
                    ? setSelectedOrder('IdentificacaoInv')
                    : setSelectedOrder('Identificacao')
                }}
              >
                Identificação
                {selectedOrder === 'Identificacao' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>
              {isInventario && (
                <TableCell
                  sx={{ fontWeight: 'bold', fontSize: '14px', minWidth: 150 }}
                >
                  Setor
                </TableCell>
              )}
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  minWidth: 150,
                  cursor: 'pointer'
                }}
                onClick={() => {
                  selectedOrder === 'TipoCaixa'
                    ? setSelectedOrder('TipoCaixaInv')
                    : setSelectedOrder('TipoCaixa')
                }}
              >
                Tipo de Caixa
                {selectedOrder === 'TipoCaixa' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>
              {!isInventario && (
                <>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '14px',
                      minWidth: 150,
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      selectedOrder === 'EstadoAtual'
                        ? setSelectedOrder('EstadoAtualInv')
                        : setSelectedOrder('EstadoAtual')
                    }}
                  >
                    Estado atual
                    {selectedOrder === 'EstadoAtual' ? (
                      <ArrowDownwardIcon
                        fontSize="small"
                        sx={{ marginLeft: '5px', marginTop: '5px' }}
                      />
                    ) : (
                      <ArrowUpwardIcon
                        fontSize="small"
                        sx={{ marginLeft: '5px', marginTop: '5px' }}
                      />
                    )}
                  </TableCell>

                  <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                    Endereço / Caixa
                  </TableCell>
                </>
              )}

              {isInventario && (
                <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                  Data Criação
                </TableCell>
              )}
              <TableCell width="12px" />
              <TableCell width="12px" />
              <TableCell width="12px" />
            </TableRow>
          </TableHead>
          <TableBody>
            {listaVolumes.map((item) => (
              <TableRow
                key={item.id_caixa}
                hover
                sx={{
                  bgcolor: item.id_caixa_container ? '#eef' : '#fff'
                }}
              >
                {isInventario && (
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() => {
                            handleItemSelection(
                              item.id_caixa ? item.id_caixa : ''
                            )
                          }}
                          checked={
                            item.id_caixa
                              ? itensSelecionados.includes(item.id_caixa) ||
                                todasSelecionadas
                              : false
                          }
                        />
                      }
                      label=""
                    />
                  </TableCell>
                )}
                <TableCell>{item.contrato?.descricao}</TableCell>
                <TableCell>
                  {item.identificacao}
                  {hasAccess(
                    usuario?.permissoes,
                    Permissions.EXIBIR_ID_CAIXA
                  ) ? (
                    <Typography
                      sx={{
                        fontSize: '10px',
                        fontWeight: 'bold',
                        color: 'GrayText'
                      }}
                    >
                      ({getCodigo(item.id_caixa ?? '')})
                    </Typography>
                  ) : (
                    ''
                  )}
                </TableCell>
                {isInventario && <TableCell>{item.setor?.nome}</TableCell>}
                <TableCell>{item.tipoCaixa?.descricao}</TableCell>
                {!isInventario && (
                  <>
                    <TableCell>{item.estadoAtividade.descricao}</TableCell>
                    <TableCell>
                      {item.id_caixa_container ? (
                        <>
                          {item.endereco} {' / '}
                          {getCodigo(item.id_caixa_container).substring(0, 9)}
                          <strong style={{ fontSize: 13 }}>
                            {getCodigo(item.id_caixa_container).substring(9)}
                          </strong>
                        </>
                      ) : (
                        item.endereco
                      )}
                    </TableCell>
                  </>
                )}
                {isInventario && (
                  <TableCell>
                    {formata(
                      item.created_at?.toString() ?? '',
                      'dd/MM/yyyy HH:mm'
                    )}
                  </TableCell>
                )}
                <TableCell>
                  <Tooltip title="Editar" arrow>
                    <Button
                      variant="outlined"
                      sx={{ minWidth: '24px', padding: '6px' }}
                      onClick={() => handleEditForm(item)}
                      disabled={checkIfDisabled(item)}
                    >
                      <EditIcon />
                    </Button>
                  </Tooltip>
                </TableCell>
                <TableCell align={'center'}>
                  {estadosCriacao.includes(item.estado) ? (
                    <Tooltip title="Excluir" arrow>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                          setCurrentBoxId(item.id_caixa!)
                          setShowDismiss(true)
                          //handleRemove(item.id_caixa!)
                        }}
                        sx={{ minWidth: '24px', padding: '6px' }}
                      >
                        <DeleteIcon />
                      </Button>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title="Esta atividade está em uso. Não pode ser excluída."
                      arrow
                    >
                      <Chip
                        label="Em uso"
                        color="primary"
                        size="small"
                        variant="outlined"
                      />
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell>
                  <Tooltip title="Gerar Etiqueta" arrow>
                    <Button
                      variant="outlined"
                      onClick={() =>
                        Etiqueta({
                          data: {
                            cliente: truncateString(
                              item.contrato?.empresa_contratante.nome,
                              25
                            ),
                            setor: truncateString(item.setor?.nome, 27),
                            identificacao: truncateString(
                              item.identificacao,
                              50
                            ).padEnd(55, ' ˙ '),

                            conteudo: truncateString(item.conteudo, 45).padEnd(
                              50,
                              ' ˙ '
                            ),

                            periodo: `${format(
                              parseISO(
                                new Date(item?.data_inicio!)
                                  .toISOString()
                                  .substring(0, 10)
                              ),
                              'dd/MM/yyyy'
                            )} - ${format(
                              parseISO(
                                new Date(item?.data_fim!)
                                  .toISOString()
                                  .substring(0, 10)
                              ),
                              'dd/MM/yyyy'
                            )}`,

                            sequencia: `${item.sequencia_inicio ?? ''} - ${
                              item.sequencia_fim ?? ''
                            }`,
                            id_caixa: getCodigo(item.id_caixa ?? ''),
                            lacrado: item.contrato?.lacrado ?? false
                          }
                        })
                      }
                      sx={{ minWidth: '24px', padding: '6px' }}
                    >
                      <TagIcon sx={{ width: 24, height: 24 }} />
                    </Button>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {records?.pagination &&
        records.pagination.totalItems > records.pagination.itemsPerPage && (
          <Pagination
            count={records?.pagination.totalPages}
            sx={{ marginTop: '12px' }}
            onChange={(_, newPage) => {
              const newSearchParams = {
                ...advancedSearchParams,
                page: String(newPage)
              }
              setSearchParams({ ...searchParams, ...newSearchParams })
            }}
            page={records.pagination.currentPage}
          />
        )}
      <Dialog
        handleAnswerNo={() => {
          setShowDismiss(false)
        }}
        handleAnswerYes={() => {
          handleRemove()
        }}
        messageText={
          <>
            <p>
              Tem certeza que deseja <strong>EXCLUIR</strong> esta caixa?
            </p>
            <p>
              Em caso afirmativo, todos os documentos e caixas vinculados também
              serão excluídos.
            </p>
            <p>
              <strong>IMPORTANTE: </strong>Esta ação não poderá ser desfeita.
            </p>
          </>
        }
        title="Atenção"
        openDialog={showDismiss}
      />
    </Box>
  )
}
