import { Box, Breadcrumbs, Typography } from '@mui/material'
import { Sidebar } from '../Sidebar'
import { Header } from '../Header'
import { useTrackRoute } from '../../../hooks/trackroute'
import { useUsuario } from '../../../hooks/usuario/useUsuario'

interface ContainerProps {
  children: React.ReactNode
}

const drawerWidth = 290

export function Container({ children }: ContainerProps) {
  const { tracking } = useTrackRoute()
  const { data: usuario, isLoading } = useUsuario.FindByUsername()

  if (isLoading) return <span style={{ color: '#777' }}>Carregando...</span>

  return (
    <Box display="flex" flexDirection="row" minHeight="100vh" bgcolor="#f2f5fa">
      <Sidebar usuario={usuario} drawerWidth={drawerWidth} />

      <Box
        width={`calc( 100% - ${drawerWidth}px )`}
        minHeight="100vh"
        bgcolor="#f2f5fa"
        marginTop="-1px"
      >
        <Box minHeight="65px" bgcolor="#2835ad" />

        <Header />

        <Breadcrumbs
          separator={tracking.submenu ? '››' : ''}
          sx={{
            paddingTop: '32px',
            paddingLeft: '32px'
          }}
        >
          <Typography>{tracking.menu}</Typography>
          <Typography>{tracking.submenu}</Typography>
        </Breadcrumbs>

        <Box px={4} py={2}>
          {children}
        </Box>
      </Box>
    </Box>
  )
}
