import { Box, BoxProps } from '@mui/material'
import { ReactNode } from 'react'

interface DashboardWidgetListRootProps extends BoxProps {
  children: ReactNode
}

export function DashboardWidgetListRoot({
  children,
  ...rest
}: DashboardWidgetListRootProps) {
  return <Box {...rest}>{children}</Box>
}
