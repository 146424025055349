import { Box, Step, StepLabel, Stepper, Typography } from '@mui/material'

type TimelineProps = {
  step: number
  steps: string[]
}

export function Timeline({ step, steps }: TimelineProps) {
  return (
    <Box pt={4}>
      <Stepper
        activeStep={step}
        alternativeLabel
        sx={{
          '.MuiStepIcon-root': {
            fontSize: 24
          },
          '.MuiStepIcon-root.Mui-active': {
            fontSize: 30
          }
        }}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>
              <Typography variant="h6">{label}</Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}
