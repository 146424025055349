import { ApiRelatorio } from '../../../../../services/relatorio'
import { InventarioCaixasReport } from './report/InventarioReport'
import {
  IHandleReportSubmitProps,
  ReportForm
} from '../../../../../components/ReportForm'

export function InventarioCaixasRelatorio() {
  async function handleSubmitForm({
    mode,
    format,
    id_usuario: id_usuario_relatorio,
    filtros
  }: IHandleReportSubmitProps) {
    const {
      data_fim,
      data_inicio,
      id_empresa,
      id_setor,
      estadoInventario = 'TODOS',
      id_tipo_documento,
      id_usuario
    } = filtros

    const inventarioCaixasData = await ApiRelatorio.getInventarioCaixas({
      id_empresa,
      id_setor,
      data_fim,
      data_inicio,
      estadoInventario,
      id_tipo_documento,
      id_usuario
    })

    if (format === 'pdf') {
      InventarioCaixasReport({
        data: inventarioCaixasData,
        mode,
        id_usuario: id_usuario_relatorio,
        filtros: {
          id_empresa,
          id_setor,
          data_fim,
          data_inicio,
          estadoInventario,
          id_tipo_documento,
          id_usuario
        }
      })
    }

    return inventarioCaixasData
  }
  return (
    <ReportForm
      title="Inventário de Caixas"
      handleReportSubmit={handleSubmitForm}
      mostrarPeriodo={true}
      mostrarEstadoInventario={true}
      mostrarTipoDocumento={true}
      mostrarUsuario={true}
      isSetorObrigatorio={false}
      isEmpresaObrigatorio={false}
    />
  )
}
