import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ApiLancamento } from '../../../services/financeiro/lancamento'

const QUERY_KEY = 'qkLancamento'

const FindOne = (id_lancamento: string) => {
  return useQuery([QUERY_KEY, id_lancamento], () =>
    ApiLancamento.findOne(id_lancamento)
  )
}
const FindAll = (page: number, q = '') => {
  return useQuery([QUERY_KEY, q, page], () =>
    ApiLancamento.findAll(page, q, true)
  )
}

const FindAllReceber = (page: number, selectedOrder: string, q = 'R') => {
  return useQuery([QUERY_KEY, q, page, '', 'R', selectedOrder], () =>
    ApiLancamento.findAllByTipo(page, selectedOrder, q, true, 'R')
  )
}

const FindAllPagar = (page: number, selectedOrder: string, q = 'P') => {
  return useQuery([QUERY_KEY, q, page, '', 'P', selectedOrder], () =>
    ApiLancamento.findAllByTipo(page, selectedOrder, q, true, 'P')
  )
}

const ListAll = () => {
  return useQuery([QUERY_KEY], ApiLancamento.listAll)
}

const Create = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiLancamento.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Update = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiLancamento.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Remove = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiLancamento.remove, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Cancel = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiLancamento.cancel, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Pay = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiLancamento.pay, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const ReversePayment = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiLancamento.reversePayment, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

export const useLancamento = {
  Create,
  Update,
  FindOne,
  FindAll,
  FindAllReceber,
  FindAllPagar,
  ListAll,
  Pay,
  Remove,
  ReversePayment,
  Cancel
}
