import { Button, ButtonProps, Tooltip } from '@mui/material'
import { ElementType } from 'react'

interface DashboardWidgetListBodyActionProps extends ButtonProps {
  tip: string
  icon: ElementType
}

export function DashboardWidgetListBodyAction({
  tip,
  icon: Icon,
  ...rest
}: DashboardWidgetListBodyActionProps) {
  const { disabled } = rest
  return disabled ? (
    <Button {...rest}>
      <Icon sx={{ width: '20px', height: '20px' }} />
    </Button>
  ) : (
    <Tooltip title={tip}>
      <Button {...rest}>
        <Icon sx={{ width: '20px', height: '20px' }} />
      </Button>
    </Tooltip>
  )
}
