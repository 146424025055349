import {
  IHandleReportSubmitProps,
  ReportForm
} from '../../../../components/ReportForm'
import { ApiRelatorio } from '../../../../services/relatorio'
import { ArvoreDocumentalReport } from './report/ArvoreDocumentalReport'

export function ArvoreDocumentalRelatorio() {
  async function handleSubmitForm({
    mode,
    format,
    id_usuario,
    filtros
  }: IHandleReportSubmitProps) {
    const documentosInventarioData = await ApiRelatorio.getArvoreDocumental({
      id_empresa: filtros.id_empresa
    })

    if (format === 'pdf') {
      await ArvoreDocumentalReport({
        data: documentosInventarioData,
        mode,
        id_usuario,
        filtros
      })
    }

    return documentosInventarioData
  }
  return (
    <ReportForm
      title="Árvore Documental"
      handleReportSubmit={handleSubmitForm}
      mostrarSetor={false}
      mostrarEndereco={false}
      mostrarPeriodo={false}
    />
  )
}
