import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import CancelIcon from '@mui/icons-material/DoDisturbAltOutlined'
import PrintIcon from '@mui/icons-material/Print'
import { DetalhesRequisicao } from './components/DetalhesRequisicao'
import { FormMode } from '../../../types/formMode'
import {
  IItemRequisicao,
  IRequisicaoEstado
} from '../../../services/requisicao/types'
import { useLote } from '../../../hooks/lote/useLote'
import { Loading } from '../../../components/Loading'
import { getCodigo } from '../../../utils/getCodigo'
import { loteToProcoloRequisicao } from '../../../utils/loteToProcoloRequisicao'
import { LoteReport } from '../../Dashboard/lib/widgets/lotesWidget/components/LoteReport'
import { useAuth } from '../../../hooks/auth'

interface IForm {
  onClickDetails?: (itemRequisicao: IItemRequisicao) => void
  data: IRequisicaoEstado
  formMode: FormMode
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

export function Form({ data: requisicao, setFormMode, onClickDetails }: IForm) {
  const { data: lotes, isLoading } = useLote.LotesByIdRequisicao(
    requisicao.id_requisicao
  )

  const { user } = useAuth()

  if (isLoading) {
    return <Loading />
  }
  return (
    <Box
      mt={4}
      sx={{
        maxHeight: '800px',
        overflow: 'hidden',
        overflowY: 'scroll'
      }}
    >
      <DetalhesRequisicao
        statusRequisicao={requisicao}
        onClickDetails={onClickDetails}
      />
      {!!lotes && lotes.length > 0 && (
        <Box marginTop={'24px'}>
          <Accordion>
            <AccordionSummary>Ordens de Serviço</AccordionSummary>
            <AccordionDetails>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{
                        backgroundColor: '#F2F2F2'
                      }}
                    >
                      <TableCell width={'100px'}>Código</TableCell>
                      <TableCell width={'100px'}>Itens</TableCell>
                      <TableCell>Observações</TableCell>
                      <TableCell width={'24px'} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lotes.map((lote) => {
                      return (
                        <TableRow key={lote.id_lote}>
                          <TableCell>{getCodigo(lote.id_lote ?? '')}</TableCell>
                          <TableCell>{lote.itens_lote.length}</TableCell>
                          <TableCell>{lote.observacao}</TableCell>
                          <TableCell>
                            <Button
                              color="primary"
                              variant="outlined"
                              sx={{ minWidth: '24px', padding: '6px' }}
                              onClick={async () => {
                                const {
                                  caixas,
                                  usuario,
                                  requisicao,
                                  documentos
                                } = await loteToProcoloRequisicao(lote)

                                LoteReport({
                                  id_usuario_logado: user.id_usuario,
                                  data: {
                                    usuario,
                                    caixas,
                                    requisicao,
                                    documentos,
                                    id_lote: lote.id_lote ?? '',
                                    observacao: lote.observacao,
                                    id_usuario_validou: lote.id_usuario_validou,
                                    validado_em: lote.validado_em,
                                    tokenRequisicao: (
                                      lote.requisicao?.token ?? ''
                                    )
                                      .slice(-15)
                                      .replace('.', '')
                                      .toUpperCase()
                                  }
                                })
                              }}
                            >
                              <PrintIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
      <Stack direction="row" gap={2} mt={3} marginBottom={'1rem'}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<CancelIcon />}
          onClick={() => setFormMode('LIST')}
        >
          Cancelar
        </Button>
      </Stack>
    </Box>
  )
}
