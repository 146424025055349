import { useEffect, useState } from 'react'
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tooltip
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import BackspaceIcon from '@mui/icons-material/Backspace'
import { Select } from '../../../components/Form/Select'
import { Loading } from '../../../components/Loading'
import { useUsuario } from '../../../hooks/usuario/useUsuario'
import { Permissions as pms } from '../../../types/permissions'
import { ComponentGroup } from '../../../components/ComponentGroup'
import { ApiPermissao } from '../../../services/permissao'
import { ApiUsuario } from '../../../services/usuario'
import { useEmpresa } from '../../../hooks/empresa/useEmpresa'
import { useSetor } from '../../../hooks/setor/useSetor'
import { IUsuario } from '../../../services/usuario/types'
import { ISetor } from '../../../services/setor/types'
import { hasAccess } from '../../../utils/hasAccess'
import useToast from '../../../hooks/toast/useToast'

interface IPar {
  key: number
  value: string
}

export function Form({
  usuarioLogado
}: {
  usuarioLogado: IUsuario | undefined
}) {
  const [idEmpresa, setIdEmpresa] = useState<string>('')
  const [idSetor, setIdSetor] = useState<string>('')
  const [listaSetoresFiltrados, setListaSetoresFiltrados] = useState<ISetor[]>(
    []
  )
  const [listaUsuariosFiltrados, setListaUsuariosFiltrados] = useState<
    IUsuario[]
  >([])
  const [idUsuario, setIdUsuario] = useState('')
  const [permissoesDisponiveis, setPermissoesDisponiveis] = useState<IPar[]>([])
  const [permissoesConcedidas, setPermissoesConcedidas] = useState<IPar[]>([])
  const { data: listaEmpresas, isLoading: isLoadingEmpresas } =
    useEmpresa.ListAll()
  const { data: listaSetores, isLoading: isLoadingSetores } = useSetor.ListAll()
  const { data: usuarios, isLoading } = useUsuario.ListAll()
  const { ToastError } = useToast()

  const permissoes =
    Object.entries(pms)
      .map(([key, value]) => ({
        key: Number(key),
        value: value.toString()
      }))
      .filter((o) => !!o.key) ?? []

  useEffect(() => {
    if (idUsuario !== '0') {
      const fetchUsuario = async (id_usuario: string) => {
        const data = await ApiUsuario.listAll()

        const userFound = data?.find((u: any) => {
          return u.id_usuario === id_usuario
        })

        const concedidas = userFound?.permissoes ?? []

        setPermissoesConcedidas(
          concedidas.map((item) => ({ key: item, value: pms[item] }))
        )

        setPermissoesDisponiveis(
          permissoes.filter((d) => !concedidas?.includes(d.key))
        )
      }

      fetchUsuario(idUsuario)
    }
  }, [idUsuario])

  const handleEmpresaChange = (id_empresa: string) => {
    setIdEmpresa(id_empresa)
    if (id_empresa === '') {
      return
    }
    const newListaSetores =
      listaSetores?.filter(
        (setor) => setor.empresa.id_empresa === id_empresa
      ) ?? []
    setIdSetor('')
    setIdUsuario('')
    setListaUsuariosFiltrados([])
    setListaSetoresFiltrados(newListaSetores)
  }

  const handleSetorChange = (id_setor: string) => {
    setIdSetor(id_setor)
    if (id_setor === '') {
      return
    }
    const newListaUsuarios =
      usuarios?.filter(
        (usuario) => usuario.pessoa.setor.id_setor === id_setor
      ) ?? []
    setIdUsuario('')
    setListaUsuariosFiltrados(newListaUsuarios)
  }

  const handleUsuarioChange = (id_usuario: string) => {
    setIdUsuario(id_usuario)
  }

  const handleAddPermission = async ({
    id_usuario,
    codigo_permissao
  }: {
    id_usuario: string
    codigo_permissao: string
  }) => {
    if (
      codigo_permissao == String(pms.PAPEL_ADMIN) &&
      hasAccess(usuarioLogado?.permissoes, pms.PAPEL_ADMIN) === false
    ) {
      ToastError(
        'Somente usuário com permissão de PAPEL_ADMIN poderá aplicar esta permissão!'
      )
      return
    }

    await ApiPermissao.create({
      codigo_permissao,
      id_usuario
    })

    const novasPermissoesDisponiveis = permissoesDisponiveis.filter(
      (state: any) => state.key !== Number(codigo_permissao)
    )

    setPermissoesDisponiveis(novasPermissoesDisponiveis)

    setPermissoesConcedidas([
      ...permissoesConcedidas,
      { key: Number(codigo_permissao), value: pms[Number(codigo_permissao)] }
    ])
  }

  const handleRemovePermission = async ({
    id_usuario,
    codigo_permissao
  }: {
    id_usuario: string
    codigo_permissao: string
  }) => {
    if (
      codigo_permissao == String(pms.PAPEL_ADMIN) &&
      hasAccess(usuarioLogado?.permissoes, pms.PAPEL_ADMIN) === false
    ) {
      ToastError(
        'Somente usuário com permissão de PAPEL_ADMIN poderá remover esta permissão!'
      )
      return
    }

    await ApiPermissao.remove({
      id_usuario,
      codigo_permissao
    })

    setPermissoesDisponiveis((state: any) => [
      ...state,
      { key: Number(codigo_permissao), value: pms[Number(codigo_permissao)] }
    ])

    setPermissoesConcedidas((state: any) =>
      state.filter((d: any) => d.key !== Number(codigo_permissao))
    )
  }

  if (isLoading || isLoadingEmpresas || isLoadingSetores) <Loading />

  return (
    <Box component="form" mt={4}>
      <Stack gap={2}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2
          }}
        >
          <Select
            label="Empresa*"
            placeholder="Selecione a Empresa"
            data={
              hasAccess(usuarioLogado?.permissoes, pms.PAPEL_ADMIN)
                ? listaEmpresas
                : listaEmpresas?.filter(
                    (e) =>
                      e.id_empresa ===
                      usuarioLogado?.pessoa.setor.empresa?.id_empresa
                  )
            }
            fields={['id_empresa', 'nome']}
            onChange={(e) => handleEmpresaChange(String(e.target.value))}
            value={idEmpresa}
          />
          <Select
            label="Setor*"
            placeholder="Selecione o Setor"
            data={listaSetoresFiltrados}
            fields={['id_setor', 'nome']}
            onChange={(e) => handleSetorChange(String(e.target.value))}
            value={idSetor}
          />
        </Box>
        <Select
          label="Usuário*"
          placeholder="Selecione o Usuario"
          data={listaUsuariosFiltrados}
          fields={['id_usuario', 'descricao']}
          onChange={(e) => handleUsuarioChange(String(e.target.value))}
          value={idUsuario}
        />

        <Stack direction="row" gap={2}>
          <ComponentGroup title="Permissões Disponíveis" width={'50%'}>
            <List sx={{ height: '360px', overflow: 'auto' }}>
              {permissoesDisponiveis
                .sort((a, b) => {
                  if (a.value > b.value) return 1
                  if (a.value < b.value) return -1
                  return 0
                })
                .map((p: any, index: number) => (
                  <ListItem
                    // key={p.key}
                    key={index}
                    disableGutters
                    secondaryAction={
                      <Tooltip title="Aplicar permissão" arrow>
                        <IconButton
                          color="info"
                          onClick={() => {
                            handleAddPermission({
                              id_usuario: idUsuario,
                              codigo_permissao: p.key
                            })
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                    }
                  >
                    <ListItemText primary={p.value} />
                  </ListItem>
                ))}
            </List>
          </ComponentGroup>

          <ComponentGroup title="Permissões Concedidas" width={'50%'}>
            <List sx={{ height: '360px', overflow: 'auto' }}>
              {permissoesConcedidas
                .sort((a, b) => {
                  if (a.value > b.value) return 1
                  if (a.value < b.value) return -1
                  return 0
                })
                .map((p: any, index: number) => (
                  <ListItem
                    // key={p}
                    key={index}
                    disableGutters
                    secondaryAction={
                      <Tooltip title="Remover permissão" arrow>
                        <IconButton
                          color="secondary"
                          onClick={() =>
                            handleRemovePermission({
                              id_usuario: idUsuario,
                              codigo_permissao: p.key
                            })
                          }
                        >
                          <BackspaceIcon />
                        </IconButton>
                      </Tooltip>
                    }
                  >
                    <ListItemText primary={p.value} />
                  </ListItem>
                ))}
            </List>
          </ComponentGroup>
        </Stack>
      </Stack>
    </Box>
  )
}
