import { useState } from 'react'
import { Box } from '@mui/material'
import { Form } from './Form'
import { List } from './List'
import { FormMode } from '../../../types/formMode'
import { FormTitle } from '../../../components/Form/FormTitle'
import { IItemRequisicao } from '../../../services/requisicao/types'
import { DetalhesItemRequisicao } from './components/DetalhesItemRequisicao'

export function DetalharRequisicoes() {
  const [formMode, setFormMode] = useState<FormMode>('LIST')
  const [oldformMode, setOldFormMode] = useState<FormMode>('LIST')
  const [data, setData] = useState<any>({})
  const [itemRequisicao, setItemRequisicao] = useState<IItemRequisicao>()

  const handleClickDetails = (itemRequisicao: IItemRequisicao) => {
    setItemRequisicao(itemRequisicao)
    setOldFormMode(formMode)
    setFormMode('DETAIL')
  }

  const handleCloseDetails = () => {
    setItemRequisicao(undefined)
    setFormMode(oldformMode)
  }

  return (
    <Box bgcolor="#fff" p={4} borderRadius={2}>
      <FormTitle
        title="Requisições"
        subtitle={formMode}
        OnClose={handleCloseDetails}
      />

      {formMode === 'LIST' && (
        <List setData={setData} setFormMode={setFormMode} />
      )}
      {['VIEW'].includes(formMode) && (
        <Form
          data={formMode === 'VIEW' ? data : {}}
          formMode={formMode}
          setFormMode={setFormMode}
          onClickDetails={handleClickDetails}
        />
      )}
      {formMode === 'DETAIL' && itemRequisicao && (
        <DetalhesItemRequisicao itemRequisicao={itemRequisicao} />
      )}
    </Box>
  )
}
