import { DefaultApi } from '../../utils/defaultApi'
import api from '../api'
import { ISetor } from '../setor/types'
import {
  FindSetoresByUsuario,
  ICopiaPermissoes,
  ISetorPermitido,
  ISetorPermitidoRequest
} from './types'

const endpoint = '/setor-permitido'
const resourceId = 'id_setor_permitido'

class ServiceSetorPermitido extends DefaultApi<ISetorPermitido> {
  createSetorPermitido = async ({
    id_usuario,
    id_setor
  }: ISetorPermitidoRequest) => {
    const formData = {
      id_usuario,
      id_setor
    }

    const { data } = await api.post(`${this.endpoint}`, formData)

    return data
  }

  removePermissaoAtribuida = async ({
    id_usuario,
    id_setor
  }: ISetorPermitidoRequest) => {
    const { data } = await api.delete(
      `${this.endpoint}/${id_usuario}/${id_setor}`
    )
    return data
  }

  copyPermissions = async ({
    id_usuario_origem,
    id_usuario_destino
  }: ICopiaPermissoes) => {
    const formData = {
      id_usuario_origem,
      id_usuario_destino
    }
    const data = await api.post(`${this.endpoint}/copy-permissions/`, formData)

    return data
  }

  findByUserId = async (id_usuario: string): Promise<FindSetoresByUsuario> => {
    const { data } = await api.get(`${this.endpoint}/lista/${id_usuario}`)
    return data
  }

  getByLoggedUser = async (): Promise<ISetor[]> => {
    const { data } = await api.get(`${this.endpoint}/usuario/`)

    return data
  }
}

const apiSetorPermitido = new ServiceSetorPermitido(endpoint, resourceId)

export const ApiSetorPermitido = {
  create: apiSetorPermitido.createSetorPermitido,
  remove: apiSetorPermitido.removePermissaoAtribuida,
  copyPermissions: apiSetorPermitido.copyPermissions,
  findByUserId: apiSetorPermitido.findByUserId,
  getByLoggedUser: apiSetorPermitido.getByLoggedUser
}
