import { useEffect, useState } from 'react'
import {
  Box,
  Button,
  List,
  ListItemButton,
  ListItemText,
  Stack
} from '@mui/material'
import SaveIcon from '@mui/icons-material/CheckCircleOutlined'
import CancelIcon from '@mui/icons-material/DoDisturbAltOutlined'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { Select } from '../../../components/Form/Select'
import useToast from '../../../hooks/toast/useToast'
import { ITipoAtividade } from '../../../services/tipoAtividade/types'
import { Input } from '../../../components/Form/Input'
import msg from '../../../utils/validationMessages'
import { FormMode } from '../../../types/formMode'
import { useTipoAtividade } from '../../../hooks/tipoAtividade/useTipoAtividade'
import { useEstadoAtividade } from '../../../hooks/estadoAtividade/useEstadoAtividade'
import { ComponentGroup } from '../../../components/ComponentGroup'
import { Add, Forward, Remove, Reply } from '@mui/icons-material'
import { expressaoToLista } from '../../../utils/requirementsParser'
import { Loading } from '../../../components/Loading'
import { usePermissaoDisponivel } from '../../../hooks/permissaoDisponivel/usePermissaoDisponivel'
import { IPermissaoDisponivel } from '../../../services/permissaoDisponivel/types'

const schema = yup
  .object({
    descricao: yup.string().required(msg.REQUIRED).min(3, msg.MIN(3)),
    identificacao: yup.string().required(msg.REQUIRED).min(3, msg.MIN(3)),
    prerequisito: yup.string(),
    estado_execucao: yup.string().required(msg.REQUIRED),
    estado_final: yup.string().required(msg.REQUIRED),
    estado_alternativo: yup.string(),
    permissao: yup.object().shape({
      id_permissao_disponivel: yup.string().required(msg.REQUIRED)
    }),
    permissao_movimentacao: yup.object().shape({
      id_permissao_disponivel: yup.string().required(msg.REQUIRED)
    })
  })
  .required()

interface IForm {
  data: ITipoAtividade
  formMode: FormMode
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

export function Form({ data, formMode, setFormMode }: IForm) {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors }
  } = useForm<ITipoAtividade>({
    resolver: yupResolver(schema)
  })

  const { Toast } = useToast()
  const { mutateAsync: create } = useTipoAtividade.Create()
  const { mutateAsync: update } = useTipoAtividade.Update()
  const { data: listaPermissoes, isLoading: isLoadingPermissoes } =
    usePermissaoDisponivel.ListAll()
  const [itemSelected, setItemSelected] = useState<string>('')
  const [idPermissaoDisponivel, setIdPermissaoDisponivel] = useState<string>(
    data?.permissao?.id_permissao_disponivel ?? ''
  )
  const [idPermissaoMovimentacao, setIdPermissaoMovimentacao] =
    useState<string>(
      data?.permissao_movimentacao?.id_permissao_disponivel ?? ''
    )
  const [permissoesDisponiveis, setPermissoesDisponiveis] = useState<
    IPermissaoDisponivel[]
  >([])
  const [permissoesFiltradas, setPermissoesFiltradas] = useState<
    IPermissaoDisponivel[]
  >([])
  const [listPrerequisitos, setListPrerequisitos] = useState<string[]>(
    data?.prerequisito ? expressaoToLista(data?.prerequisito) : []
  )
  const [itemPrerequisitoSelected, setItemPrerequisitoSelected] =
    useState<string>('')
  const { data: estados, isLoading } = useEstadoAtividade.ListAll()
  const [listEstados, setListEstados] = useState<string[]>([])
  const [estadoExecucao, setEstadoExecucao] = useState<string | null>(
    data?.estado_execucao ?? null
  )
  const [estadoFinal, setEstadoFinal] = useState<string | null>(
    data?.estado_final ?? null
  )
  const [estadoAlternativo, setEstadoAlternativo] = useState<string | null>(
    data?.estado_alternativo ?? null
  )

  function sortList(list: Array<string>): Array<string> {
    return list.sort((a, b) => {
      if (a < b) return -1
      if (a === b) return 0
      return 1
    })
  }

  useEffect(() => {
    if (estados) {
      const sortedEstados = sortList(estados)
      setListEstados(sortedEstados)
    }
    setValue('estado_final', data?.estado_final ?? '')
    setValue('estado_alternativo', data?.estado_alternativo ?? '')
    setValue('estado_execucao', data?.estado_execucao ?? '')
  }, [estados])

  useEffect(() => {
    if (!isLoadingPermissoes) {
      const newPermissoesDisponiveis = listaPermissoes?.sort((a, b) => {
        if (a.descricao > b.descricao) {
          return 1
        }
        if (a.descricao < b.descricao) {
          return -1
        }
        return 0
      })
      setPermissoesDisponiveis(newPermissoesDisponiveis || [])
      const newPermissoesFiltradas = newPermissoesDisponiveis?.filter(
        (permissao) =>
          permissao.codigo_permissao > 100 && permissao.codigo_permissao < 200
      )
      setPermissoesFiltradas(newPermissoesFiltradas || [])
    }
  }, [isLoadingPermissoes])

  const onSubmit = async (formData: ITipoAtividade) => {
    formMode === 'CREATE'
      ? await Toast(create(formData))
      : await Toast(
          update({
            id_tipo_atividade: data.id_tipo_atividade,
            ...formData
          }),
          'UPDATED'
        )

    setFormMode('LIST')
  }

  function handlePermissaoDisponivelSelect(id_permissao_disponivel: string) {
    const permissaoMovimentacao =
      'permissao_movimentacao.id_permissao_disponivel'
    if (
      getValues(permissaoMovimentacao) === null ||
      getValues(permissaoMovimentacao) === '' ||
      getValues(permissaoMovimentacao) === idPermissaoDisponivel
    ) {
      setValue(permissaoMovimentacao, id_permissao_disponivel)
      setIdPermissaoMovimentacao(id_permissao_disponivel)
    }
    setValue('permissao.id_permissao_disponivel', id_permissao_disponivel)
    setIdPermissaoDisponivel(id_permissao_disponivel)
  }

  function handlePermissaoMovimentacaolSelect(id_permissao_disponivel: string) {
    setValue(
      'permissao_movimentacao.id_permissao_disponivel',
      id_permissao_disponivel
    )
    setIdPermissaoMovimentacao(id_permissao_disponivel)
  }

  function handleListItemSelect(item: string) {
    setItemSelected(item)
  }

  function handleListPrerequisitoSelect(item: string) {
    setItemPrerequisitoSelected(item)
  }

  function handleRemoveFromList(
    fieldName: 'final' | 'alternativo' | 'prerequisito' | 'executando'
  ) {
    const value = itemSelected ?? ''
    const newSortedList = listEstados.filter((item) => item !== value)
    setListEstados(newSortedList)
    setItemSelected('')
    switch (fieldName) {
      case 'final': {
        setValue('estado_final', value)
        setEstadoFinal(value)
        break
      }
      case 'alternativo': {
        setValue('estado_alternativo', value)
        setEstadoAlternativo(value)
        break
      }
      case 'executando': {
        setValue('estado_execucao', value)
        setEstadoExecucao(value)
        break
      }
      default: {
        const newListPrerequisitos = [...listPrerequisitos, value]
        setListPrerequisitos(newListPrerequisitos)
        setValue('prerequisito', newListPrerequisitos.join(' OU '))
      }
    }
  }

  function handleAddToList(
    fieldName: 'final' | 'alternativo' | 'prerequisito' | 'executando',
    value: string
  ) {
    const newSortedList = sortList([...listEstados, value])
    setListEstados(newSortedList)
    setItemSelected('')
    switch (fieldName) {
      case 'final': {
        setValue('estado_final', '')
        setEstadoFinal(null)
        break
      }
      case 'alternativo': {
        setValue('estado_alternativo', '')
        setEstadoAlternativo(null)
        break
      }
      case 'executando': {
        setValue('estado_execucao', '')
        setEstadoExecucao(null)
        break
      }
      default: {
        const newListPrerequisitos = listPrerequisitos.filter(
          (item) => item !== value
        )
        setListPrerequisitos(newListPrerequisitos)
        setValue('prerequisito', newListPrerequisitos.join(' OU '))
      }
    }
  }

  if (isLoading || isLoadingPermissoes) return <Loading />

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} mt={4}>
      <Stack gap={2}>
        <Input
          label="Descrição*"
          {...register('descricao')}
          defaultValue={data?.descricao}
          message={errors.descricao?.message}
        />
        <Stack gap={2} direction="row">
          <Input
            label="Identificação*"
            {...register('identificacao')}
            defaultValue={data?.identificacao}
            message={errors.identificacao?.message}
          />
          <Select
            label="Tipo de Dashboard *"
            placeholder="Selecione uma tipo de dashboard"
            data={permissoesFiltradas}
            fields={['id_permissao_disponivel', 'descricao']}
            {...register('permissao.id_permissao_disponivel')}
            value={idPermissaoDisponivel}
            onChange={(event) => {
              handlePermissaoDisponivelSelect(String(event.target.value))
            }}
            message={errors.permissao?.id_permissao_disponivel?.message}
          />
          <Select
            label="Permissão para alteração de movimentação  *"
            placeholder="Selecione uma permissão disponível"
            data={permissoesDisponiveis}
            fields={['id_permissao_disponivel', 'descricao']}
            {...register('permissao_movimentacao.id_permissao_disponivel')}
            value={idPermissaoMovimentacao}
            onChange={(event) => {
              handlePermissaoMovimentacaolSelect(String(event.target.value))
            }}
            message={errors.permissao?.id_permissao_disponivel?.message}
          />
        </Stack>

        <Stack direction="row" gap={2}>
          <ComponentGroup title="Estados Disponíveis" width={'520px'}>
            <Stack direction={'row'} gap={2}>
              <List
                sx={{
                  width: '300px',
                  minWidth: '280px',
                  maxHeight: '450px',
                  overflow: 'auto',
                  border: '1px solid lightgray',
                  borderRadius: '6px'
                }}
              >
                {listEstados?.map((estado) => (
                  <ListItemButton
                    key={estado}
                    selected={estado === itemSelected}
                  >
                    <ListItemText
                      primary={estado}
                      onClick={() => handleListItemSelect(estado)}
                    ></ListItemText>
                  </ListItemButton>
                ))}
              </List>
              <Stack gap={3} justifyContent={'center'}>
                <Button
                  type="button"
                  variant="outlined"
                  startIcon={<Forward />}
                  disabled={
                    data?.em_uso || itemSelected === '' || !!estadoExecucao
                  }
                  onClick={() => handleRemoveFromList('executando')}
                  sx={{
                    width: '190px'
                  }}
                >
                  Estado em Execução
                </Button>
                <Button
                  type="button"
                  variant="outlined"
                  startIcon={<Forward />}
                  onClick={() => handleRemoveFromList('final')}
                  disabled={
                    data?.em_uso || itemSelected === '' || !!estadoFinal
                  }
                  sx={{
                    width: '190px'
                  }}
                >
                  Estado Final
                </Button>
                <Button
                  type="button"
                  variant="outlined"
                  startIcon={<Forward />}
                  disabled={
                    data?.em_uso || itemSelected === '' || !!estadoAlternativo
                  }
                  onClick={() => handleRemoveFromList('alternativo')}
                  sx={{
                    width: '190px'
                  }}
                >
                  Estado Alternativo
                </Button>

                <Button
                  type="button"
                  variant="outlined"
                  startIcon={<Add />}
                  disabled={data?.em_uso || itemSelected === ''}
                  onClick={() => handleRemoveFromList('prerequisito')}
                  sx={{
                    width: '190px'
                  }}
                >
                  Pré-Requisitos
                </Button>
              </Stack>
            </Stack>
          </ComponentGroup>
          <Stack gap={3} width={'100%'}>
            <ComponentGroup title="Estado em Execução*">
              <Stack direction={'row'} gap={2} alignItems={'center'}>
                <Button
                  type="button"
                  variant="outlined"
                  disabled={data?.em_uso || !estadoExecucao}
                  onClick={() =>
                    handleAddToList('executando', estadoExecucao ?? '')
                  }
                  sx={{
                    maxWidth: '4rem',
                    height: '2rem',
                    justifyContent: 'center',
                    alignContent: 'center'
                  }}
                >
                  <Reply />
                </Button>
                <Input
                  label=""
                  {...register('estado_execucao')}
                  defaultValue={data?.estado_execucao ?? ''}
                  disabled
                  message={errors.estado_execucao?.message}
                  InputLabelProps={{ shrink: !!estadoExecucao }}
                />
              </Stack>
            </ComponentGroup>
            <ComponentGroup title="Estado Final*">
              <Stack direction={'row'} gap={2} alignItems={'center'}>
                <Button
                  type="button"
                  variant="outlined"
                  disabled={data?.em_uso || !estadoFinal}
                  onClick={() => handleAddToList('final', estadoFinal ?? '')}
                  sx={{
                    maxWidth: '4rem',
                    height: '2rem',
                    justifyContent: 'center',
                    alignContent: 'center'
                  }}
                >
                  <Reply />
                </Button>
                <Input
                  label=""
                  {...register('estado_final')}
                  defaultValue={data?.estado_final ?? ''}
                  disabled
                  message={errors.estado_final?.message}
                  InputLabelProps={{ shrink: !!estadoFinal }}
                />
              </Stack>
            </ComponentGroup>
            <ComponentGroup title="Estado Alternativo*">
              <Stack direction={'row'} gap={2} alignItems={'center'}>
                <Button
                  type="button"
                  variant="outlined"
                  disabled={data?.em_uso || !estadoAlternativo}
                  onClick={() =>
                    handleAddToList('alternativo', estadoAlternativo ?? '')
                  }
                  sx={{
                    maxWidth: '4rem',
                    height: '2rem',
                    justifyContent: 'center',
                    alignContent: 'center'
                  }}
                >
                  <Reply />
                </Button>

                <Input
                  label=""
                  {...register('estado_alternativo')}
                  defaultValue={data?.estado_alternativo ?? ''}
                  disabled
                  message={errors.estado_alternativo?.message}
                  InputLabelProps={{ shrink: !!estadoAlternativo }}
                />
              </Stack>
            </ComponentGroup>
            <ComponentGroup title="Pré-Requisitos">
              <Stack direction={'row'} gap={2} alignItems={'center'}>
                <Button
                  type="button"
                  variant="outlined"
                  disabled={data?.em_uso || itemPrerequisitoSelected === ''}
                  onClick={() =>
                    handleAddToList('prerequisito', itemPrerequisitoSelected)
                  }
                  sx={{
                    maxWidth: '4rem',
                    height: '2rem',
                    justifyContent: 'center',
                    alignContent: 'center'
                  }}
                >
                  <Remove />
                </Button>
                <List
                  sx={{
                    width: '100%',
                    height: '170px',
                    overflow: 'auto',
                    border: '1px solid lightgray',
                    borderRadius: '6px'
                  }}
                >
                  {listPrerequisitos?.map((estado) => (
                    <ListItemButton
                      key={estado}
                      selected={estado === itemPrerequisitoSelected}
                    >
                      <ListItemText
                        primary={estado}
                        onClick={() => handleListPrerequisitoSelect(estado)}
                      ></ListItemText>
                    </ListItemButton>
                  ))}
                </List>
              </Stack>
            </ComponentGroup>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="row" gap={2} mt={3}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<CancelIcon />}
          onClick={() => setFormMode('LIST')}
        >
          Cancelar
        </Button>

        <Button
          type="submit"
          variant="contained"
          startIcon={<SaveIcon />}
          sx={{ width: '110px' }}
        >
          Salvar
        </Button>
      </Stack>
    </Box>
  )
}
