import { useEffect, useState } from 'react'
import useToast from '../../../hooks/toast/useToast'
import { useLancamento } from '../../../hooks/financeiro/lancamento/useLancamento'
//import { useUsuario } from '../../../hooks/usuario/useUsuario'
import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Input } from '../../Form/Input'
import { Select } from '../../Form/Select'
import { useFormaPagamento } from '../../../hooks/financeiro/formaPagamento/useFormaPagamento'
import { Dialog } from '../../Dialog'
import { useConta } from '../../../hooks/conta-corrente/conta/useConta'
import { useEventoContaCorrente } from '../../../hooks/conta-corrente/evento/useEvento'
import { Loading } from '../../Loading'
import { formatarComoMoeda } from '../../../utils/formataStrings'

type LancamentoBaixaFormsProps = {
  title?: string
  open: boolean
  handleClose: () => void
  id_lancamento: string
}

const tiposBaixas = [
  { value: 'TOTAL', label: 'Total' },
  { value: 'PARCIAL', label: 'Parcial' }
]

export function LancamentoBaixaForm({
  open,
  handleClose,
  id_lancamento,
  title
}: LancamentoBaixaFormsProps) {
  const { data: lancamento, isLoading: isLoadingLancamento } =
    useLancamento.FindOne(id_lancamento)
  const tipoEvento = lancamento?.tipo_lancamento === 'R' ? 'CREDITO' : 'DEBITO'
  const { data: listaFormaPagamento } = useFormaPagamento.ListAll()
  const { data: listaContas } = useConta.ListAll()
  const { data: listaEventos, isLoading: isLoadingEventos } =
    useEventoContaCorrente.ListAllByTipo(tipoEvento)

  const { Toast, ToastError } = useToast()
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [dt_baixa, setDtBaixa] = useState<Date>(new Date())
  const [id_forma_pagamento, setIdFormaPagamento] = useState<string>('')
  const [tipo_pagamento, setTipoPagamento] = useState<string>('TOTAL')
  const [id_conta, setIdConta] = useState<string>('')
  const [id_evento_conta_corrente, setIdEventoContaCorrente] =
    useState<string>('')
  const [valor, setValor] = useState<number>()

  const { mutateAsync: pay } = useLancamento.Pay()
  /*const { data: usuario } = useUsuario.FindByUsername() */

  useEffect(() => {
    setIdConta('')
    setIdEventoContaCorrente('')
  }, [])

  const onSubmit = async () => {
    setShowConfirm(false)
    if (
      dt_baixa &&
      valor &&
      id_forma_pagamento !== '' &&
      id_evento_conta_corrente !== '' &&
      id_conta !== ''
    ) {
      const formData = {
        id_lancamento,
        id_forma_pagamento,
        id_evento_conta_corrente,
        id_conta,
        dt_baixa,
        valor,
        tipo_pagamento
      }
      await Toast(pay(formData), 'UPDATED', 'Baixa realizada com sucesso!')
      handleClose()
    } else {
      ToastError('Preencha os campos obrigatórios!')
    }
  }

  const valorEmAberto = lancamento
    ? lancamento.valor - lancamento.valor_pago
    : 0

  if (isLoadingLancamento || isLoadingEventos) return <Loading />

  return (
    <Modal
      open={open}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        top: '5%'
      }}
    >
      <Box
        borderRadius="4px"
        borderColor="lightgray"
        display={'flex'}
        flexDirection={'column'}
        sx={{
          bgcolor: 'background.paper',
          boxShadow: 24,
          width: 400,
          height: 520
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          bgcolor="#2835ad"
          px={2}
          sx={{
            borderTopRightRadius: '4px',
            borderTopLeftRadius: '4px'
          }}
        >
          <Typography color="#FFF" py={1.5} variant="subtitle1">
            {title ?? 'Baixa de Lançamento'}
          </Typography>

          <Tooltip title="Fechar" arrow>
            <IconButton onClick={() => handleClose()}>
              <CloseIcon sx={{ color: '#fff' }} />
            </IconButton>
          </Tooltip>
        </Box>

        <Box component="form" mt={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2
            }}
            mx={2}
          >
            <Input
              label="Em aberto"
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*[,]?[0-9]{0,2}',
                inputProps: { min: 0, step: '0.01' }
              }}
              required={false}
              disabled={true}
              value={formatarComoMoeda(valorEmAberto)}
            />
            <Select
              label="Tipo da Baixa*"
              placeholder="Selecione o Tipo da Baixa"
              data={tiposBaixas}
              fields={['value', 'label']}
              onChange={(event) => setTipoPagamento(String(event.target.value))}
              value={tipo_pagamento}
            />
            <Input
              type="date"
              label="Pagamento"
              InputLabelProps={{
                shrink: true
              }}
              required={true}
              onChange={(event) => setDtBaixa(new Date(event.target.value))}
            />

            <Select
              label="Forma de Pagamento*"
              placeholder="Selecione a Forma de Pagamento"
              data={listaFormaPagamento}
              fields={['id_forma_pagamento', 'descricao']}
              onChange={(event) =>
                setIdFormaPagamento(String(event.target.value))
              }
            />

            <Input
              label="Valor R$"
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*[,]?[0-9]{0,2}',
                inputProps: { min: 0, step: '0.01' }
              }}
              required={true}
              onChange={(event) =>
                setValor(Number(event.target.value.replace(',', '.')))
              }
            />

            <Select
              label="Conta*"
              placeholder="Selecione a Conta"
              data={listaContas}
              fields={['id_conta', 'descricao']}
              onChange={(event) => {
                setIdConta(String(event.target.value))
              }}
              value={id_conta}
            />
            <Select
              label="Evento*"
              placeholder="Selecione o Evento"
              data={listaEventos}
              fields={['id_evento_conta_corrente', 'descricao']}
              value={id_evento_conta_corrente}
              onChange={(event) => {
                setIdEventoContaCorrente(String(event.target.value))
              }}
            />
          </Box>

          <Divider />

          <Stack direction="row" gap={2} p={2} justifyContent="flex-end">
            <Button
              variant="contained"
              onClick={() => setShowConfirm(true)}
              sx={{
                fontWeight: 600
              }}
            >
              Confirmar Baixa
            </Button>
          </Stack>

          <Dialog
            handleAnswerNo={() => {
              setShowConfirm(false)
            }}
            handleAnswerYes={() => {
              onSubmit()
            }}
            messageText="Confirma a baixa do lançamento?"
            title="Atenção"
            openDialog={showConfirm}
          />
        </Box>
      </Box>
    </Modal>
  )
}
