import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ApiServico } from '../../services/servico'

const QUERY_KEY = 'qkServico'

const FindAll = (page: number, q = '') => {
  return useQuery([QUERY_KEY, q, page], () => ApiServico.findAll(page, q))
}

const FindAllWithFluxo = (page: number, q = '') => {
  return useQuery([QUERY_KEY, q, page], () =>
    ApiServico.findAllWithFluxo(page, q)
  )
}

const FindOneWithFluxo = (id: string) => {
  return useQuery([QUERY_KEY, 'fluxo', id], () =>
    ApiServico.findOneWithFluxo(id)
  )
}

const SetFluxoAtividades = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiServico.setFluxoAtividades, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const RemoveFluxoAtividades = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiServico.removeFluxoAtividades, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const ListAll = () => {
  return useQuery(QUERY_KEY, ApiServico.listAll)
}

const Create = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiServico.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Update = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiServico.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Remove = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiServico.remove, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const FindOne = (id: string) => {
  return useQuery([QUERY_KEY, id], () => ApiServico.findOne(id))
}

export const useServico = {
  Create,
  FindAll,
  FindAllWithFluxo,
  FindOneWithFluxo,
  SetFluxoAtividades,
  RemoveFluxoAtividades,
  ListAll,
  Update,
  Remove,
  FindOne
}
