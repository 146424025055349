import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography
} from '@mui/material'

import logo from '../../assets/images/logo_easydoc.png'
import { ShowMessage } from '../../components/ShowMessage'
import api from '../../services/api'

export function SendRecoverEmail() {
  const navigate = useNavigate()
  const [message, setMessage] = useState<string>()
  const [email, setEmail] = useState('')
  const [isDisable, setIsDisable] = useState(false)

  const handleNavigateToLogin = () => navigate('/login')
  const handleNavigateToRecover = () => navigate('/password-recover')

  async function submitRecoverEmail(event: React.FormEvent<HTMLInputElement>) {
    event.preventDefault()
    setIsDisable(true)

    try {
      await api.post('/auth/email', { email: email })
      localStorage.setItem('email', email)
      handleNavigateToRecover()
      setIsDisable(false)
    } catch (err: any) {
      setMessage(err?.message)
      setIsDisable(false)
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 16,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <img src={logo} width="60%" alt="logo X-Solution" />

        <Typography variant="h4" mt={4}>
          Recuperação de senha
        </Typography>
        <Typography variant="h6">
          Um código será enviado para o seu email
        </Typography>
        <Typography variant="h6">cadastrado no sistema</Typography>
        <Box component="form" onSubmit={submitRecoverEmail} sx={{ mt: 1 }}>
          <TextField
            label="email"
            required
            fullWidth
            margin="normal"
            autoFocus
            onChange={(e) => setEmail(e.target.value)}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={isDisable}
            sx={{ mt: 3, mb: 2, fontSize: '14px' }}
          >
            Continuar
          </Button>

          <Grid container>
            <Grid item xs>
              <Link
                variant="body2"
                onClick={handleNavigateToLogin}
                sx={{ cursor: 'pointer' }}
              >
                {'Voltar para o login'}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <ShowMessage
        hasError={!!message}
        message={message}
        onClose={() => setMessage(undefined)}
      />
    </Container>
  )
}
