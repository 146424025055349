import { toast } from 'react-toastify'

enum DataAction {
  CREATED = 'Incluídos',
  UPDATED = 'Atualizados',
  REMOVED = 'Excluídos'
}

const defaultMessage = (typeAction: keyof typeof DataAction) =>
  `Dados ${DataAction[typeAction]} com sucesso!`

//-- Toast para ser utilizados nos Hooks de Promise de Dados
const Toast = (
  promise: Promise<unknown>,
  typeAction?: keyof typeof DataAction,
  message?: string,
  actionSuccess?: () => void,
  actionError?: () => void
) => {
  return toast.promise(promise, {
    pending: 'Aguarde...',
    success: {
      render() {
        return message ?? defaultMessage(typeAction ?? 'CREATED')
      },
      onClose: () => {
        actionSuccess && actionSuccess()
      }
    },
    error: {
      onClose: () => {
        actionError && actionError()
      },
      render({ data }) {
        if (data.isArray) {
          return (
            <>
              <h4>Ops! ocorreu algum problema 😧</h4>
              {data?.message.map((msg: string, i: number) => (
                <div key={i}>✘ {msg}</div>
              ))}
            </>
          )
        } else {
          return (
            <>
              <h4>Ops! ocorreu algum problema 😧</h4>
              {data?.message}
            </>
          )
        }
      }
    }
  })
}

const ToastLogout = (promise: Promise<unknown>, message: string) => {
  return toast.promise(promise, {
    pending: 'Aguarde...',
    success: message,
    error: {
      render({ data }) {
        return (
          <>
            <h4>Ops! ocorreu algum problema 😧</h4>
            <p>{data?.response?.data?.message}</p>
          </>
        )
      }
    }
  })
}
//-- Toast com mensagens abertas
const ToastSuccess = (message?: string) => toast.success(message)
const ToastError = (message?: string) => toast.error(message)
const ToastInfo = (message?: string) => toast.info(message)
const ToastWarning = (message?: string) => toast.warning(message)

//-- Toast com mensagens padronizadas
const ToastCreatedData = () => toast.success(defaultMessage('CREATED'))
const ToastUpdatedData = () => toast.success(defaultMessage('UPDATED'))
const ToastRemovedData = () => toast.success(defaultMessage('REMOVED'))

const useToast = () => {
  return {
    Toast,
    ToastSuccess,
    ToastError,
    ToastInfo,
    ToastWarning,
    ToastCreatedData,
    ToastUpdatedData,
    ToastRemovedData,
    ToastLogout
  }
}

export default useToast
