import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ApiContratoPermitido } from '../../services/contratoPermitido'

const QUERY_KEY = 'qkContratoPermitido'

const FindByUsuarioLogado = () => {
  return useQuery([QUERY_KEY], () => ApiContratoPermitido.getByLoggedUser())
}

const CopyPermissions = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiContratoPermitido.copyPermissions, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

export const useContratoPermitido = {
  FindByUsuarioLogado,
  CopyPermissions
}
