import { format } from 'date-fns'
import { ICaixasArmazenadasPorCDData } from '../../../../../../../services/relatorio/types'
import { TemplateReport } from '../../../../../../../components/Report'
import { ApiCentroDocumentacao } from '../../../../../../../services/centroDocumentacao'
import { ApiEmpresa } from '../../../../../../../services/empresa'

type Props = {
  data: ICaixasArmazenadasPorCDData[]
  mode?: 'open' | 'save'
  id_usuario: string
  filtros: {
    id_centrodocumentacao?: string
    data_inicio?: Date
    data_fim?: Date
    id_empresa?: string
  }
}

export async function CaixasArmazenadasPorCDReport({
  data,
  mode,
  id_usuario,
  filtros
}: Props) {
  let espacos_ocupados = 0
  let espacos_totais = 0

  const summary = () => {
    return [
      {
        columns: [
          {
            text: [
              {
                text: `Total: `,
                style: ['summaryText', 'bold']
              },
              {
                text: `${espacos_totais}\n`,
                style: ['summaryText']
              },
              {
                text: `Ocupados: `,
                style: ['summaryText', 'bold']
              },
              {
                text: `${espacos_ocupados}\n`,
                style: ['summaryText']
              },
              {
                text: `Livres: `,
                style: ['summaryText', 'bold']
              },
              {
                text: `${espacos_totais - espacos_ocupados}\n`,
                style: ['summaryText']
              }
            ],
            style: 'filterText',
            width: '*'
          }
        ]
      }
    ]
  }
  const filterReportText = async () => {
    const { id_centrodocumentacao, data_fim, data_inicio, id_empresa } = filtros
    const filtersReport = []
    filtersReport.push({
      text: 'Empresa: ',
      style: ['filterText', 'bold']
    })
    if (id_empresa) {
      const empresa = await ApiEmpresa.findOne(id_empresa)

      if (empresa) {
        filtersReport.push({
          text: empresa.nome,
          style: ['filterText']
        })
      }
    } else filtersReport.push({ text: '<todas> ', style: ['filterText'] })
    filtersReport.push({ text: '\n' })
    filtersReport.push({
      text: 'Centro de Documentação: ',
      style: ['filterText', 'bold']
    })
    if (id_centrodocumentacao) {
      const centroDocumentacao =
        await ApiCentroDocumentacao.findOneWithoutPositions(
          id_centrodocumentacao
        )

      if (centroDocumentacao) {
        filtersReport.push({
          text: centroDocumentacao.nome,
          style: ['filterText']
        })
      }
    } else filtersReport.push({ text: '<todos> ', style: ['filterText'] })
    filtersReport.push({ text: '\n' })
    filtersReport.push({ text: 'Período: ', style: ['filterText', 'bold'] })
    if (data_inicio && data_fim) {
      filtersReport.push({
        text: `${format(new Date(data_inicio), 'dd/MM/yyyy')} até ${format(
          new Date(data_fim),
          'dd/MM/yyyy'
        )}`,
        style: ['filterText']
      })
    } else filtersReport.push({ text: '<global> ', style: ['filterText'] })

    return filtersReport
  }

  const caixasMovimentadasPorCDTable = () => {
    let rows: Array<any> = []
    if (data && data.length > 0) {
      rows = [
        [
          { text: 'Empresa', style: 'columnTitle' },
          { text: 'Centro de Documentação', style: 'columnTitle' },
          { text: 'ID', style: 'columnTitle' },
          { text: 'Espaço Total', style: 'columnTitle' },
          { text: 'Espaço Ocupado', style: 'columnTitle' },
          { text: 'Espaço Livre', style: 'columnTitle' }
        ]
      ]
      data.forEach((centroDocumentacao) => {
        espacos_ocupados =
          espacos_ocupados + centroDocumentacao.qtd_caixas_armazenadas
        espacos_totais = espacos_totais + centroDocumentacao.espaco_total
        const row = [
          { text: centroDocumentacao.nome_empresa, style: 'row' },
          { text: centroDocumentacao.nome, style: 'row' },
          { text: centroDocumentacao.prefixo, style: 'rowCenter' },
          { text: centroDocumentacao.espaco_total, style: 'rowCenter' },
          {
            text: centroDocumentacao.qtd_caixas_armazenadas,
            style: 'rowCenter'
          },
          {
            text:
              centroDocumentacao.espaco_total -
              centroDocumentacao.qtd_caixas_armazenadas,
            style: 'rowCenter'
          }
        ]

        rows.push(row as any)
      })

      return [
        {
          table: {
            widths: [80, '*', 40, 60, 60, 60],
            headerRows: 1,
            body: rows
          }
        },
        {
          text: `Total de Centros de Documentação Analisados: ${data.length}`,
          style: 'total'
        },
        summary()
      ]
    } else {
      return [{ text: '\n' }]
    }
  }

  const filterText = await filterReportText()

  await TemplateReport({
    id_usuario,
    content: caixasMovimentadasPorCDTable(),
    filterText,
    reportTitle: 'Caixas Armazenadas por Centro de Documentação',
    mode
  })
}
