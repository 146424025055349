import { useQuery } from 'react-query'
import { ApiUf } from '../../services/uf'

const QUERY_KEY = 'qkUf'

const FindAll = (page = 1, q = '') => {
  return useQuery([QUERY_KEY, q, page], () => ApiUf.findAll(page, q))
}

const ListAll = () => {
  return useQuery(QUERY_KEY, ApiUf.listAll)
}

export const useUf = {
  FindAll,
  ListAll
}
