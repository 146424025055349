import { Box, Stack, Typography } from '@mui/material'
import { FallbackProps } from 'react-error-boundary'
import bugImg from '../../assets/images/bug.png'

export function ErrorFallback({ error }: FallbackProps) {
  return (
    <Box p={3} maxWidth="40%">
      <Box display="flex" alignItems="center" mb={2}>
        <img src={bugImg} alt="Caixas" style={{ objectFit: 'none' }} />
        <Box ml={1}>
          <Typography color="#333" variant="h4">
            Oops...
          </Typography>
          <Typography color="#333" variant="h5">
            Algo deu errado!
          </Typography>
        </Box>
      </Box>
      <Stack gap={1}>
        <Typography color="#333" variant="body1">
          Por favor, atualize a página e tente novamente.
        </Typography>
        <Typography color="#333" variant="body1">
          Se este problema persistir, entre em contato com nossa equipe de
          suporte.
        </Typography>
      </Stack>

      <Box p={2} my={3} bgcolor="#066fac" borderRadius={2}>
        <Typography color="#fff" mb={2}>
          Descrição do erro:
        </Typography>
        <code style={{ color: '#fff' }}>{error.message}</code>
      </Box>
    </Box>
  )
}
