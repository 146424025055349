import {
  FinanceiroReportForm,
  IHandleFinanceiroReportSubmitProps
} from '../../../../../../components/Financeiro/FinanceiroReportForm'
import { ApiRelatorioFinanceiro } from '../../../../../../services/financeiro/relatorio'
import { BaixaLancamentoReport } from './report/BaixaLancamentoReport'

export interface IBaixaLancamentoRelatorioFormProps {
  tipo_relacionamento?: string
}

export function BaixaLancamentoRelatorioForm({
  tipo_relacionamento
}: IBaixaLancamentoRelatorioFormProps) {
  async function handleSubmitForm({
    mode,
    format,
    id_usuario,
    filtros
  }: IHandleFinanceiroReportSubmitProps) {
    const {
      data_fim,
      data_inicio,
      id_centro_custo,
      id_forma_pagamento,
      id_parceiro,
      id_parceiro_2,
      id_receita_despesa,
      order_by
    } = filtros

    const baixaLancamento = await ApiRelatorioFinanceiro.getBaixaLancamento({
      data_fim,
      data_inicio,
      id_centro_custo,
      id_forma_pagamento,
      id_parceiro,
      id_usuario,
      tipo_relacionamento,
      id_parceiro_2,
      id_receita_despesa,
      order_by
    })

    if (format === 'pdf') {
      await BaixaLancamentoReport({
        data: baixaLancamento,
        id_usuario: id_usuario,
        filtros: {
          data_fim,
          data_inicio,
          id_centro_custo,
          id_forma_pagamento,
          id_parceiro,
          tipo_relacionamento,
          id_parceiro_2,
          id_receita_despesa,
          order_by
        },
        mode
      })
    }

    return baixaLancamento
  }

  const isCliente = tipo_relacionamento === 'C'
  const isBaixa = !tipo_relacionamento ? true : false
  return (
    <FinanceiroReportForm
      title={`Baixa de Lançamentos à ${
        !isBaixa ? (isCliente ? 'Receber' : 'Pagar') : 'Receber x Pagar'
      }`}
      handleReportSubmit={handleSubmitForm}
      temParceiro2={tipo_relacionamento ? false : true}
      tipo_relacionamento={tipo_relacionamento}
      isOrdernarPrevisao={false}
    />
  )
}
