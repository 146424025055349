import { DefaultApi } from '../../utils/defaultApi'
import api from '../api'
import { IEstante } from './types'

const endpoint = '/estante'
const resourceId = 'id_estante'

class ServiceEstante extends DefaultApi<IEstante> {
  listAll = async () => {
    const { data } = await api.get<IEstante[]>(`${endpoint}/lista/`)
    return data
  }

  findByCorredor = async (id_corredor: string) => {
    if (id_corredor === '' || id_corredor === '0') {
      return []
    }
    const { data } = await api.get<IEstante[]>(
      `${endpoint}/corredor/${id_corredor}`
    )
    return data
  }
}

const apiEstante = new ServiceEstante(endpoint, resourceId)

export const ApiEstante = {
  listAll: apiEstante.listAll,
  findByCorredor: apiEstante.findByCorredor
}
