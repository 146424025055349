import { ApiRelatorio } from '../../../../../../services/relatorio'
import { CaixasArmazenadasPorCDReport } from './report/CaixasArmazenadasPorCDReport'

import {
  IHandleReportSubmitProps,
  ReportForm
} from '../../../../../../components/ReportForm'

export function CaixasArmazenadasPorCDRelatorio() {
  async function handleSubmitForm({
    mode,
    format,
    id_usuario,
    filtros
  }: IHandleReportSubmitProps) {
    const { data_fim, data_inicio, id_centrodocumentacao, id_empresa } = filtros

    const caixasArmazenadasPorCD = await ApiRelatorio.getStoredBoxesByDC({
      data_fim,
      data_inicio,
      id_centrodocumentacao,
      id_empresa
    })

    if (format === 'pdf') {
      await CaixasArmazenadasPorCDReport({
        data: caixasArmazenadasPorCD,
        id_usuario: id_usuario,
        filtros: {
          data_fim,
          data_inicio,
          id_centrodocumentacao,
          id_empresa
        },
        mode
      })
    }

    return caixasArmazenadasPorCD
  }
  return (
    <ReportForm
      title="Caixas Armazenadas por Centro de Documentação"
      handleReportSubmit={handleSubmitForm}
      mostrarSetor={false}
      mostrarPeriodo={true}
      mostrarContrato={false}
      mostrarEmpresa={true}
      mostrarCentroDocumentacao={true}
      isEmpresasXSolution={true}
      isEmpresaObrigatorio={false}
    />
  )
}
