import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ApiConta } from '../../../services/conta-corrente/conta'

const QUERY_KEY = 'qkConta'

const FindAll = (page: number, q = '') => {
  return useQuery([QUERY_KEY, q, page], () => ApiConta.findAll(page, q))
}

const ListAll = () => {
  return useQuery([QUERY_KEY], ApiConta.listAll)
}

const Create = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiConta.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Update = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiConta.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Remove = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiConta.remove, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

export const useConta = {
  Create,
  Update,
  FindAll,
  ListAll,
  Remove
}
