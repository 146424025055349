import { DefaultApi } from '../../utils/defaultApi'
import api from '../api'
import { IUsuario } from './types'
import { IResult, TAtivo } from '../../utils/defaultApi/types'

const endpoint = '/usuario'
const resourceId = 'id_usuario'

class ServiceUsuario extends DefaultApi<IUsuario> {
  findByUsername = async (username: string) => {
    const { data } = await api.get<IUsuario>(
      `${this.endpoint}/username/${username}`
    )
    return data
  }

  findAllUsersByContract = async (
    page: number,
    contracts?: string,
    userEmpresa?: string,
    selectedOrder?: string,
    q?: string,
    ativo: TAtivo = true
  ): Promise<IResult<IUsuario>> => {
    const pageString = page ? `page=${page}` : ''
    const qString = q ? `q=${q}` : ''
    const ativoString = ativo !== undefined ? `ativo=${ativo}` : ''
    const ordemString = selectedOrder ? `ordem=${selectedOrder}` : ''
    const params = [pageString, qString, ativoString, ordemString]
      .filter((value) => value !== '')
      .join('&')

    const { data } = await api.get<IResult<IUsuario>>(
      `${this.endpoint}/lista/contrato/${contracts}/${userEmpresa}?${params}`
    )
    return data
  }

  findById = async (id_usuario: string) => {
    const { data } = await api.get<IUsuario>(`${this.endpoint}/${id_usuario}`)
    return data
  }

  createUsuarioPessoa = async (formData: IUsuario) => {
    const { data } = await api.post<IUsuario>(
      `${this.endpoint}/pessoa`,
      formData
    )
    return data
  }

  updatePessoaUsuario = async (updateFormData: IUsuario) => {
    const { data } = await api.put(
      `${this.endpoint}/${updateFormData.pessoa.id_pessoa}`,
      updateFormData
    )
    return data
  }

  resetPassword = async (newPassword: string) => {
    await api.put(`${this.endpoint}/reset-password/`, {
      newPassword
    })
    return { message: 'Senha alterada com sucesso!' }
  }

  sendEmail = async (email: string) => {
    await api.post(`/auth/email/`, {
      email
    })
    return
  }

  changeStatus = async (id_usuario: string) => {
    const { data } = await api.put(`${endpoint}/status/${id_usuario}`)
    return data
  }

  listAllXSolution = async (id_empresa?: string) => {
    const id_empresaString =
      id_empresa !== undefined ? `id_empresa=${id_empresa}` : ''
    const params = [id_empresaString].filter((value) => value !== '').join('&')

    const { data } = await api.get(`${endpoint}/lista/x-solution/?${params}`)
    return data
  }
}

const apiUsuario = new ServiceUsuario(endpoint, resourceId)

export const ApiUsuario = {
  findAll: apiUsuario.findAll,
  findAllUsersByContract: apiUsuario.findAllUsersByContract,
  listAll: apiUsuario.listAll,
  listAllXSolution: apiUsuario.listAllXSolution,
  findByUsername: apiUsuario.findByUsername,
  findById: apiUsuario.findById,
  remove: apiUsuario.remove,
  createUsuarioPessoa: apiUsuario.createUsuarioPessoa,
  updatePessoaUsuario: apiUsuario.updatePessoaUsuario,
  resetPassword: apiUsuario.resetPassword,
  sendEmail: apiUsuario.sendEmail,
  changeStatus: apiUsuario.changeStatus
}
