import { DashboardWidgetSummary } from '../../../../types/DashboardWidget/DashboardWidgetSummary'
import { DashboardWidgetSummaryBody } from '../../../../types/DashboardWidget/DashboardWidgetSummary/DashboardWidgetSummaryBody'
import { DashboardWidgetSummaryHeader } from '../../../../types/DashboardWidget/DashboardWidgetSummary/DashboardWidgetSummaryHeader'
import { DashboadWidgetSummaryHeaderToolbar } from '../../../../types/DashboardWidget/DashboardWidgetSummary/DashboardWidgetSummaryHeader/DashboadWidgetSummaryHeaderToolbar/index.tsx'
import { ICardData, ITipoAtividade } from '../../../../services/dashboard/types'
import { DashboardWidget } from '../../../../types/DashboardWidget'
import { TWidgetState } from '../../../../types/DashboardWidget/WidgetState'
import { defaultWidgetSummaryStyle } from '../lib/consts'
import { DashboardWidgetSummaryHeaderDatabar } from '../../../../types/DashboardWidget/DashboardWidgetSummary/DashboardWidgetSummaryHeader/DashboardWidgetSummaryHeaderDatabar'
import {
  MoreHoriz,
  BuildCircle,
  CheckCircle,
  Close,
  DoneAll,
  History,
  DoNotDisturbAltOutlined
} from '@mui/icons-material'
import { ReactComponent as CloseButton } from '../../../../assets/images/botaoFechar.svg'
import { useState } from 'react'
import { TWidgetAtividade } from '../lib/@types/TWidgetAtividade'
import { DashboardWidgetListCard } from '../../../../types/DashboardWidget/DashboardWidgetList/DashboardWidgetListCard'
import { IMovimentacaoExtendida } from '../../../../services/movimentacao/types'
import { DashboardWidgetForm } from '../../../../types/DashboardWidget/DashboardWidgetForm'
import { isEqual } from '../../../../utils/isEqual'
import useToast from '../../../../hooks/toast/useToast'
import { useMovimentacao } from '../../../../hooks/movimentacao/useMovimentacao'
import { simplifyMovimentacao } from '../../../../utils/simplifyMovimentacao'
import { DashboardWidgetListHeader } from '../../../../types/DashboardWidget/DashboardWidgetList/DashboardWidgetListHeader'
import { DashboardWidgetList } from '../../../../types/DashboardWidget/DashboardWidgetList'
import { useUsuario } from '../../../../hooks/usuario/useUsuario'
import { Loading } from '../../../../components/Loading'

interface WidgetCardProps {
  data: ICardData
  tipoAtividade: ITipoAtividade
  onStateChange: (tipoAtividade: TWidgetAtividade) => void
}

export function WidgetCard({
  data,
  tipoAtividade,
  onStateChange
}: WidgetCardProps) {
  const { data: usuario, isLoading } = useUsuario.FindByUsername()
  const { Toast } = useToast()
  const permissoes = usuario?.permissoes as Array<number>
  const { mutateAsync: updateMovimentacao } = useMovimentacao.Update()
  //const processedData = process(data, tipoAtividade)
  const processedData = data
  const [widgetState, setWidgetState] = useState<TWidgetState>('SUMMARY')
  const [movimentacaoAtual, setMovimentacaoAtual] = useState<
    IMovimentacaoExtendida | undefined
  >(undefined)
  const [movimentacaoInicial, setMovimentacaoInicial] = useState<
    IMovimentacaoExtendida | undefined
  >(undefined)
  const [movimentacaoModificada, setMovimentacaoModificada] =
    useState<boolean>(false)

  const hasAccess = (permission: number) => permissoes.includes(permission)

  const handleMoreClick = (): void => {
    setWidgetState('LIST')
    onStateChange(tipoAtividade)
  }

  const handleCardClick = (movimentacao: IMovimentacaoExtendida) => {
    setWidgetState('FORM')
    setMovimentacaoAtual(movimentacao)
    setMovimentacaoInicial(movimentacao)
    onStateChange(tipoAtividade)
  }

  const handleFormDataChange = (movimentacao: IMovimentacaoExtendida): void => {
    setMovimentacaoAtual(movimentacao)
    setMovimentacaoModificada(!isEqual(movimentacao, movimentacaoInicial))
  }

  const handleSaveClick = async () => {
    if (movimentacaoModificada) {
      await Toast(
        updateMovimentacao(simplifyMovimentacao(movimentacaoAtual!)),
        'UPDATED'
      )
      setMovimentacaoInicial(movimentacaoAtual)
      setMovimentacaoModificada(false)
    }
  }

  const handleCloseFormClick = (): void => {
    setWidgetState('LIST')
    setMovimentacaoAtual(undefined)
    setMovimentacaoInicial(undefined)
    setMovimentacaoModificada(false)
  }

  const handleMovimentacaoUpdateState = async (
    novoEstado: 'EXECUTANDO' | 'COMPLETO' | 'CANCELADO'
  ) => {
    await Toast(
      updateMovimentacao(
        simplifyMovimentacao({ ...movimentacaoAtual!, estado: novoEstado })
      ),
      'UPDATED'
    )
    setWidgetState('LIST')
  }

  const chartData = {
    labels: processedData.summaryData.chartData.map((dt) => dt.descricao),
    datasets: [
      {
        label: 'Total de Itens',
        data: processedData.summaryData.chartData.map((dt) => dt.quantidade),
        backgroundColor: ['rgb(233, 44, 44)', '#219653'],
        hoverOffset: 4
      }
    ]
  }

  const handleCloseListClick = (): void => {
    setWidgetState('SUMMARY')
    onStateChange(undefined)
  }

  if (isLoading) <Loading />

  return (
    <>
      {widgetState === 'SUMMARY' && (
        <DashboardWidget.Root
          sx={{
            borderRadius: '20px',
            boxShadow:
              '0px 8px 16px -4px rgba(24, 39, 75, 0.08), 0px 6px 0px -6px rgba(0, 0, 0, 0.25)',
            backgroundColor: '#fff',
            padding: 0,
            margin: '2rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '400px',
            width: '400px'
          }}
        >
          <DashboardWidgetSummary.Root sx={{ ...defaultWidgetSummaryStyle }}>
            <DashboardWidgetSummaryHeader.Root>
              <DashboadWidgetSummaryHeaderToolbar.Root>
                <DashboadWidgetSummaryHeaderToolbar.Type
                  type={tipoAtividade.identificacao}
                />
                <DashboadWidgetSummaryHeaderToolbar.Action
                  icon={MoreHoriz}
                  onClick={handleMoreClick}
                />
              </DashboadWidgetSummaryHeaderToolbar.Root>

              <DashboardWidgetSummaryHeader.Title
                title={processedData.summaryData.totalItens.toString()}
              />
              <DashboardWidgetSummaryHeaderDatabar.Root>
                {processedData.summaryData.headerData.map((dt, index) => (
                  <DashboardWidgetSummaryHeaderDatabar.Data
                    key={`wRequisicoesData${index}`}
                    data={Math.floor(dt.quantidade).toString()}
                    title={dt.descricao}
                  />
                ))}
              </DashboardWidgetSummaryHeaderDatabar.Root>
            </DashboardWidgetSummaryHeader.Root>

            <DashboardWidgetSummaryBody.Root>
              <DashboardWidgetSummaryBody.Pie data={chartData} />
            </DashboardWidgetSummaryBody.Root>
          </DashboardWidgetSummary.Root>
        </DashboardWidget.Root>
      )}
      {widgetState === 'LIST' && (
        <DashboardWidget.Root
          sx={{
            borderRadius: '20px',
            boxShadow:
              '0px 8px 16px -4px rgba(24, 39, 75, 0.08), 0px 6px 0px -6px rgba(0, 0, 0, 0.25)',
            backgroundColor: '#fff',
            mt: '1rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem'
          }}
        >
          <DashboardWidgetList.Root>
            <DashboardWidgetListHeader.Root title={tipoAtividade.identificacao}>
              <DashboardWidgetListHeader.Data
                total={processedData.summaryData.totalItens}
                icon={History}
              />
              <DashboardWidgetListHeader.Actions>
                <DashboardWidgetListHeader.IconAction
                  icon={CloseButton}
                  onClick={handleCloseListClick}
                  tip={'Voltar para Dashboard'}
                  style={{
                    width: '40px',
                    height: '40px',
                    padding: 0,
                    margin: 0,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    borderRadius: '20px',
                    border: 0
                  }}
                />
              </DashboardWidgetListHeader.Actions>
            </DashboardWidgetListHeader.Root>

            <DashboardWidget.CardListRoot>
              {processedData.tableData.map((card) => {
                return (
                  <DashboardWidgetListCard
                    movimentacao={card}
                    onClickWidget={handleCardClick}
                  />
                )
              })}
            </DashboardWidget.CardListRoot>
          </DashboardWidgetList.Root>
        </DashboardWidget.Root>
      )}
      {widgetState === 'FORM' && (
        <DashboardWidget.Root
          sx={{
            borderRadius: '20px',
            boxShadow:
              '0px 8px 16px -4px rgba(24, 39, 75, 0.08), 0px 6px 0px -6px rgba(0, 0, 0, 0.25)',
            backgroundColor: '#fff',
            margin: '2rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1rem',
            height: '780px',
            width: '100%'
          }}
        >
          <DashboardWidgetForm.Root sx={{ width: '100%', height: '780px' }}>
            <DashboardWidgetForm.Header title={tipoAtividade.identificacao}>
              <DashboardWidgetForm.Actions>
                {hasAccess(
                  tipoAtividade.permissao_movimentacao.codigo_permissao
                ) &&
                  (tipoAtividade.identificacao ===
                    'Cancelada Realocação (Interno)' ||
                  tipoAtividade.identificacao ===
                    'Cancelar Pedido de Requisição' ? (
                    ''
                  ) : (
                    <DashboardWidgetForm.Action
                      icon={DoNotDisturbAltOutlined}
                      title="Cancelar Movimentação"
                      tip="Passar a Movimentação para o estado de CANCELADA"
                      color="error"
                      variant="contained"
                      onClick={() => handleMovimentacaoUpdateState('CANCELADO')}
                    />
                  ))}
                {hasAccess(
                  tipoAtividade.permissao_movimentacao.codigo_permissao
                ) && (
                  <DashboardWidgetForm.Action
                    tip={
                      movimentacaoAtual?.estado === 'PENDENTE'
                        ? 'Executar Atividade'
                        : 'Finalizar Execução de Atividade'
                    }
                    title={
                      movimentacaoAtual?.estado === 'PENDENTE'
                        ? 'Executar'
                        : 'Finalizar'
                    }
                    icon={
                      movimentacaoAtual?.estado === 'PENDENTE'
                        ? BuildCircle
                        : DoneAll
                    }
                    onClick={
                      movimentacaoAtual?.estado === 'PENDENTE'
                        ? () => handleMovimentacaoUpdateState('EXECUTANDO')
                        : () => handleMovimentacaoUpdateState('COMPLETO')
                    }
                    variant="contained"
                    color="info"
                  />
                )}
                {hasAccess(
                  tipoAtividade.permissao_movimentacao.codigo_permissao
                ) && (
                  <DashboardWidgetForm.Action
                    tip="Salvar as modificações feitas à atividade"
                    title="Salvar"
                    variant="contained"
                    color="success"
                    icon={CheckCircle}
                    onClick={() => handleSaveClick()}
                    disabled={!movimentacaoModificada}
                  />
                )}
                <DashboardWidgetForm.Action
                  tip="Voltar para tela anterior sem salvar modificações"
                  title="Cancelar"
                  icon={Close}
                  variant="outlined"
                  color="secondary"
                  sx={{ gap: 1, color: '#1C1C1C', fontWeight: 'bold' }}
                  onClick={() => handleCloseFormClick()}
                />
              </DashboardWidgetForm.Actions>
            </DashboardWidgetForm.Header>
            {!!movimentacaoAtual && (
              <DashboardWidgetForm.Movimentacao
                data={movimentacaoAtual}
                onDataChange={handleFormDataChange}
              />
            )}
          </DashboardWidgetForm.Root>
        </DashboardWidget.Root>
      )}
    </>
  )
}
