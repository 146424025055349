import { Box, Button, Stack } from '@mui/material'
import SaveIcon from '@mui/icons-material/CheckCircleOutlined'
import CancelIcon from '@mui/icons-material/DoDisturbAltOutlined'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import msg from '../../../utils/validationMessages'
import useToast from '../../../hooks/toast/useToast'
import { FormMode } from '../../../types/formMode'
import { Input } from '../../../components/Form/Input'
import { useEventoContaCorrente } from '../../../hooks/conta-corrente/evento/useEvento'
import { IEventoContaCorrente } from '../../../services/conta-corrente/evento/types'
import { Select } from '../../../components/Form/Select'

const tiposEvento = [
  { id: 'CREDITO', descricao: 'Crédito' },
  { id: 'DEBITO', descricao: 'Débito' }
]

const schema = yup
  .object({
    descricao: yup.string().required(msg.REQUIRED).min(3, msg.MIN(3)),
    tipo: yup.string().required(msg.REQUIRED)
  })
  .required()

interface IForm {
  data: any
  formMode: FormMode
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

export function Form({ data, formMode, setFormMode }: IForm) {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IEventoContaCorrente>({
    resolver: yupResolver(schema)
  })

  const { Toast } = useToast()
  const { mutateAsync: create } = useEventoContaCorrente.Create()
  const { mutateAsync: update } = useEventoContaCorrente.Update()

  const onSubmit = async (formData: IEventoContaCorrente) => {
    formMode === 'CREATE'
      ? await Toast(create(formData))
      : await Toast(
          update({
            ...formData,
            id_evento_conta_corrente: data.id_evento_conta_corrente
          }),
          'UPDATED'
        )

    setFormMode('LIST')
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} mt={4}>
      <Stack gap={2} direction="row">
        <Input
          label="Descrição*"
          {...register('descricao')}
          defaultValue={data?.descricao}
          message={errors.descricao?.message}
        />
        <Select
          label="Tipo*"
          placeholder="Selecione o Tipo do Evento"
          data={tiposEvento}
          fields={['id', 'descricao']}
          {...register('tipo')}
          defaultValue={data?.tipo}
          message={errors.tipo?.message}
        />
      </Stack>

      <Stack direction="row" gap={2} mt={3}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<CancelIcon />}
          onClick={() => setFormMode('LIST')}
        >
          Cancelar
        </Button>

        <Button
          type="submit"
          variant="contained"
          startIcon={<SaveIcon />}
          sx={{ width: '110px' }}
        >
          Salvar
        </Button>
      </Stack>
    </Box>
  )
}
