import { Box, Chip, Divider, Typography, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

enum FORM_MODE {
  CREATE = 'INCLUIR',
  EDIT = 'EDITAR',
  LIST = 'LISTAR',
  CARD = 'LISTAR',
  VIEW = 'VISUALIZAR',
  DRIVE = 'DRIVE',
  DETAIL = 'DETALHES'
}

interface FormTitleProps {
  title: string
  subtitle?: string
  OnClose?: () => void
}

export function FormTitle({ title, subtitle, OnClose }: FormTitleProps) {
  return (
    <>
      <Box display="flex" alignItems="center" justifyContent={'space-between'}>
        <Box display="flex">
          <Typography variant="h5" mb={2} color="#333">
            {title}
            {subtitle && (
              <Chip
                label={FORM_MODE[subtitle as keyof typeof FORM_MODE]}
                color="info"
                sx={{
                  fontSize: 10,
                  height: 16,
                  ml: 2,
                  fontWeight: 600
                }}
              />
            )}
          </Typography>
        </Box>

        {subtitle === 'DETAIL' && (
          <IconButton
            aria-label="close"
            size="small"
            sx={{ ml: 2 }}
            onClick={OnClose}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>
      <Divider />
    </>
  )
}
