import { useQuery } from 'react-query'
import { ApiItemContrato } from '../../services/item-contrato'

const QUERY_KEY = 'qkItemContrato'

const ListByIdContrato = (idContrato: string) => {
  return useQuery([QUERY_KEY, idContrato], () =>
    ApiItemContrato.listByIdContrato(idContrato)
  )
}

const ListByIdContratoAll = (idContrato: string) => {
  return useQuery([QUERY_KEY, idContrato], () =>
    ApiItemContrato.listByIdContratoAll(idContrato)
  )
}

export const useItemContrato = {
  ListByIdContrato,
  ListByIdContratoAll
}
