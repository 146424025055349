import { DefaultApi } from '../../../utils/defaultApi'
import api from '../../api'
import { IEventoContaCorrente } from './types'

const endpoint = '/conta-corrente/eventos'
const resourceId = 'id_evento_conta_corrente'

class ServiceEventoContacorrente extends DefaultApi<IEventoContaCorrente> {
  listAllByTipo = async (tipo: string) => {
    const { data } = await api.get<IEventoContaCorrente[]>(
      `${endpoint}/lista/${tipo}`
    )
    return data
  }
}

const apiEventoContaCorrente = new ServiceEventoContacorrente(
  endpoint,
  resourceId
)

export const ApiEventoContaCorrente = {
  ...apiEventoContaCorrente,
  listAllByTipo: apiEventoContaCorrente.listAllByTipo
}
