import {
  Dialog,
  DialogTitle,
  Stack,
  TextField,
  Box,
  Button
} from '@mui/material'
import { ReactNode, useState } from 'react'

interface PosicaoPrateleiraIncludePopupProps {
  title: string
  show: boolean
  data: ReactNode
  close: () => void
  action: (quantidade: number) => void
}

export function PosicaoPrateleiraIncludePopup({
  title,
  show,
  data,
  close,
  action
}: PosicaoPrateleiraIncludePopupProps) {
  const [quantidade, setQuantidade] = useState<number>(1)
  return (
    <Dialog open={show} fullWidth maxWidth={'xs'}>
      <DialogTitle
        sx={{
          backgroundColor: 'lightgray'
        }}
      >
        {title}
      </DialogTitle>
      <Stack display={'flex'} justifyContent={'space-between'} margin={'12px'}>
        {data}
        <TextField
          variant="outlined"
          size="small"
          type={'number'}
          value={quantidade}
          InputProps={{ inputProps: { min: 1 } }}
          label="Quantidade de Posições"
          onChange={(event) => {
            setQuantidade(Number(String(event?.target.value)))
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            width: '100%',
            marginTop: '12px'
          }}
          gap={2}
        >
          <Button
            onClick={() => {
              action(quantidade)
            }}
            variant="contained"
            color="primary"
          >
            Confirmar Expansão
          </Button>
          <Button onClick={() => close()} variant="outlined" color="error">
            Cancelar Expansão
          </Button>
        </Box>
      </Stack>
    </Dialog>
  )
}
