import { Button, ButtonProps, Tooltip } from '@mui/material'
import { ElementType } from 'react'

interface DashboardWidgetFormActionProps extends ButtonProps {
  tip: string
  icon: ElementType
  title: string
}

export function DashboardWidgetFormAction({
  title,
  tip,
  icon: Icon,
  ...rest
}: DashboardWidgetFormActionProps) {
  const { disabled } = rest
  const IconStyle = {
    width: { xs: '15px', md: '20px' },
    height: { xs: '15px', md: '20px' },
    margin: 0,
    padding: 0
  }
  return disabled ? (
    <Button {...rest}>
      <Icon sx={{ ...IconStyle }} />
      {title}
    </Button>
  ) : (
    <Tooltip title={tip}>
      <Button {...rest}>
        <Icon sx={{ ...IconStyle }} />
        {title}
      </Button>
    </Tooltip>
  )
}
