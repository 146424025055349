import { DefaultApi } from '../../utils/defaultApi'
import api from '../api'
import { ILote } from './types'

const endpoint = '/lote'
const resourceId = 'id_lote'

class ServiceLote extends DefaultApi<ILote> {
  lotesByIdRequisicao = async (id_requisicao: string): Promise<ILote[]> => {
    const { data } = await api.get<ILote[]>(
      `${endpoint}/requisicao/${id_requisicao}`
    )
    return data
  }
}

const apiLote = new ServiceLote(endpoint, resourceId)

export const ApiLote = {
  ...apiLote
}
