import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ApiRecorrencia } from '../../../services/financeiro/recorrencia'

const QUERY_KEY = 'qkRecorrencia'

const FindAll = (page: number, selectedOrder: string, q = '') => {
  return useQuery([QUERY_KEY, q, page, selectedOrder], () =>
    ApiRecorrencia.findAllRecorrencia(page, selectedOrder, q)
  )
}

const ListAll = () => {
  return useQuery([QUERY_KEY], ApiRecorrencia.listAll)
}

const Create = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiRecorrencia.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Generate = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiRecorrencia.gerarLancamentos, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Cancel = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiRecorrencia.cancelar, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Update = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiRecorrencia.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Remove = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiRecorrencia.remove, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

export const useRecorrencia = {
  Create,
  Update,
  FindAll,
  Generate,
  ListAll,
  Remove,
  Cancel
}
