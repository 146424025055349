import { DefaultApi } from '../../utils/defaultApi'
import api from '../api'
import { IItemContrato } from './types'

const endpoint = '/item-contrato'
const resourceId = 'id_item_contrato'

class ItemContratoService extends DefaultApi<IItemContrato> {
  listByIdContrato = async (id_contrato: string): Promise<IItemContrato[]> => {
    if (id_contrato === '0' || id_contrato === '') return []
    const { data } = await api.get<IItemContrato[]>(
      `${endpoint}/lista/contrato/${id_contrato}`
    )
    return data
  }

  listByIdContratoAll = async (
    id_contrato: string
  ): Promise<IItemContrato[]> => {
    if (id_contrato === '0' || id_contrato === '') return []
    const { data } = await api.get<IItemContrato[]>(
      `${endpoint}/lista/contrato/all/${id_contrato}`
    )
    return data
  }
}

export const ApiItemContrato = new ItemContratoService(endpoint, resourceId)
