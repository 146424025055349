import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ApiParceiro } from '../../../services/financeiro/parceiro'

const QUERY_KEY = 'qkParceiro'

const FindAll = (
  page: number,
  selectedOrder: string,
  q = '',
  tipo_relacionamento = ''
) => {
  return useQuery(
    [QUERY_KEY, q, page, tipo_relacionamento, selectedOrder],
    () =>
      ApiParceiro.findAllByTipo(
        page,
        selectedOrder,
        q,
        true,
        tipo_relacionamento
      )
  )
}

const FindOne = (id: string) => {
  return useQuery([QUERY_KEY, id], () => ApiParceiro.findOne(id))
}

const Create = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiParceiro.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const ListAll = () => {
  return useQuery([QUERY_KEY], () => ApiParceiro.listAll())
}

const ListAllCliente = () => {
  return useQuery([QUERY_KEY, 'C'], () => ApiParceiro.listAllCliente())
}

const ListAllFornecedor = () => {
  return useQuery([QUERY_KEY, 'F'], () => ApiParceiro.listAllFornecedor())
}

const Update = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiParceiro.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Remove = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiParceiro.remove, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

export const useParceiro = {
  Create,
  Update,
  FindAll,
  FindOne,
  ListAll,
  Remove,
  ListAllCliente,
  ListAllFornecedor
}
