import { ApiRelatorio } from '../../../../../../services/relatorio'
import { CaixasArmazenadasPorEmpresaESetorReport } from './report/CaixasArmazenadasPorEmpresaESetorReport'
import {
  IHandleReportSubmitProps,
  ReportForm
} from '../../../../../../components/ReportForm'

export function CaixasArmazenadasPorEmpresaESetorRelatorio() {
  async function handleSubmitForm({
    mode,
    format,
    id_usuario,
    filtros
  }: IHandleReportSubmitProps) {
    const { data_fim, data_inicio, id_empresa, id_setor, tipoConteudo } =
      filtros

    const caixasArmazenadasPorEmpresaESetor =
      await ApiRelatorio.getStoredBoxesByCompanyAndSector({
        id_empresa,
        id_setor,
        data_fim,
        data_inicio,
        tipoConteudo
      })

    if (format === 'pdf') {
      CaixasArmazenadasPorEmpresaESetorReport({
        data: caixasArmazenadasPorEmpresaESetor,
        id_usuario: id_usuario,
        filtros: {
          id_empresa,
          id_setor,
          data_fim,
          data_inicio,
          tipoConteudo
        },
        mode
      })
    }

    return caixasArmazenadasPorEmpresaESetor
  }
  return (
    <ReportForm
      title="Caixas/Documentos Armazenados por Empresa / Setor"
      handleReportSubmit={handleSubmitForm}
      mostrarPeriodo={true}
      isEmpresaObrigatorio={false}
      mostrarTipoConteudo={true}
    />
  )
}
