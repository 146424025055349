import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@mui/material'
import { useState } from 'react'

import { ILote } from '../../../services/lote/types'
import { BoxTitle } from '../../../components/BoxTitle'
import { ComponentGroup } from '../../../components/ComponentGroup'
import { Input } from '../../../components/Form/Input'
import { formata } from '../../../utils/formataData'
import { IMovimentacao } from '../../../services/movimentacao/types'
import { IRequisicaoDashboard } from '../../../services/dashboard/types'
import { getCodigo } from '../../../utils/getCodigo'

interface DashboardWidgetFormLoteProps {
  data: ILote
  requisicao: IRequisicaoDashboard
  setData: (formData: ILote) => void
}

export function DashboardWidgetFormLote({
  data,
  requisicao,
  setData
}: DashboardWidgetFormLoteProps) {
  const [previsao, setPrevisao] = useState<Date>(new Date(data.previsao))
  const [observacao, setObservacao] = useState<string>(data.observacao ?? '')

  const handlePrevisaoChange = (newData: string) => {
    setPrevisao(new Date(newData))
    data.previsao = new Date(newData)
    setData(data)
  }

  const handleObservacaoChange = (observacaoValue: string) => {
    setObservacao(observacaoValue)
    data.observacao = observacaoValue
    setData(data)
  }

  const getTipoItem = (movimentacao: IMovimentacao): string => {
    const itemRequisicao = requisicao.itens_requisicao
      .filter(
        (itemReq) =>
          itemReq.id_item_requisicao === movimentacao.id_item_requisicao
      )
      .pop()
    const tipoItem = itemRequisicao?.documento
      ? 'Documento'
      : itemRequisicao?.caixa?.tipoCaixa.descricao
    return tipoItem ?? 'Item'
  }

  const getDescricao = (movimentacao: IMovimentacao): string => {
    const itemRequisicao = requisicao.itens_requisicao
      .filter(
        (itemReq) =>
          itemReq.id_item_requisicao === movimentacao.id_item_requisicao
      )
      .pop()
    const descricao = itemRequisicao?.caixa
      ? itemRequisicao.caixa.identificacao
      : itemRequisicao?.documento?.descricao

    return descricao ?? 'item'
  }

  const getSetor = (movimentacao: IMovimentacao): string => {
    const itemRequisicao = requisicao.itens_requisicao
      .filter(
        (itemReq) =>
          itemReq.id_item_requisicao === movimentacao.id_item_requisicao
      )
      .pop()
    const descricao = itemRequisicao?.caixa
      ? itemRequisicao.caixa.setor?.nome
      : itemRequisicao?.documento?.setor?.nome

    return descricao ?? 'item'
  }

  const getEndereco = (movimentacao: IMovimentacao): string => {
    const itemRequisicao = requisicao.itens_requisicao
      .filter(
        (itemReq) =>
          itemReq.id_item_requisicao === movimentacao.id_item_requisicao
      )
      .pop()
    return itemRequisicao?.endereco ?? ''
  }

  // const formData = {
  //   id_lote: data.id_lote,
  //   observacao,
  //   previsao,
  //   id_requisicao: data.id_requisicao,
  //   itens_lote: [...data.itens_lote],
  //   executado: true
  // }

  // const newItensLote = data.itens_lote.map((itemLote) => ({
  //   id_movimentacao: itemLote.id_movimentacao
  // }))
  // data.itens_lote = [...newItensLote]
  // // setValue('itens_lote', data.itens_lote)
  // // setValue('id_lote', data.id_lote)
  // // setValue('id_requisicao', data.id_requisicao)
  // // setValue('observacao', observacao)
  // // setValue('previsao', previsao)

  return (
    <Box>
      <Box mt={4}>
        <BoxTitle title={'Lote'}>
          <Box gap={2} display={'flex'} flexDirection={'row'}>
            <Input
              label={'Código'}
              value={getCodigo(data.id_lote ?? '')}
              inputProps={{ disabled: 'disabled' }}
            />
            <Input
              label={'Disponibilizado para execução em'}
              value={formata(
                data.itens_lote[0].movimentacao?.data_inicio
                  ? new Date(data.itens_lote[0].movimentacao?.data_inicio)
                  : new Date(),
                "dd/MM/yyyy 'às' HH:mm"
              )}
              inputProps={{ disabled: 'disabled' }}
            />
            <TextField
              label="Executar em *"
              type="date"
              value={previsao.toISOString().split('T')[0]}
              size="small"
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
              onChange={(event) => {
                handlePrevisaoChange(event.target.value)
              }}
            />
          </Box>
          <Input
            label={'Observações'}
            value={observacao ?? ''}
            onChange={(event) => {
              handleObservacaoChange(event.target.value)
            }}
          />
          <BoxTitle title={'Requisição'}>
            <ComponentGroup title={'Origem'}>
              <Input
                label={''}
                value={requisicao.usuario.pessoa.setor.empresa.razao_social}
                inputProps={{ disabled: 'disabled' }}
              />
            </ComponentGroup>
            <ComponentGroup title={'Destino'}>
              <Input
                label={''}
                value={
                  requisicao.item_contrato.servico.interno
                    ? requisicao.item_contrato.contrato.empresa_contratante
                        .razao_social
                    : requisicao.item_contrato.contrato.empresa_contratada
                        .razao_social
                }
                inputProps={{ disabled: 'disabled' }}
              />
            </ComponentGroup>
          </BoxTitle>
          <BoxTitle title={'Itens'}>
            <TableContainer sx={{ maxHeight: '450px', overflow: 'auto' }}>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: '#f2f2f2'
                    }}
                  >
                    <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                      Tipo
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                      Descrição/Identificação
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                      Setor
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                      Endereço
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.itens_lote.map((itemLote) => (
                    <TableRow key={itemLote.id_movimentacao}>
                      <TableCell>
                        {getTipoItem(itemLote.movimentacao!)}
                      </TableCell>
                      <TableCell>
                        {getDescricao(itemLote.movimentacao!)}
                      </TableCell>
                      <TableCell>{getSetor(itemLote.movimentacao!)}</TableCell>
                      <TableCell>
                        {getEndereco(itemLote.movimentacao!)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </BoxTitle>
        </BoxTitle>
      </Box>
    </Box>
  )
}
