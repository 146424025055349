import { Box } from '@mui/material'
import { List } from './list'
import { FormTitle } from '../../../components/Form/FormTitle'

export function LogsUsuarios() {
  return (
    <Box bgcolor="#fff" p={4} borderRadius={2}>
      <FormTitle title="Logs de Usuários" subtitle="LIST" />

      <List />
    </Box>
  )
}
