import api from '../../services/api'
import { DefaultApi } from '../../utils/defaultApi'
import { IRequisicao, IRequisicaoEstado, IRequisicaoNaoAtendida } from './types'
import { IResult, TAtivo } from '../../utils/defaultApi/types'

const endpoint = '/requisicao'
const resourceId = 'id_requisicao'

interface IValidacaoToken {
  id_requisicao: string
  id_lote: string
  token: string
}

class TApiRequisicao extends DefaultApi<IRequisicao> {
  listUnattended = async (): Promise<IRequisicaoNaoAtendida[]> => {
    const { data } = await api.get<IRequisicaoNaoAtendida[]>(
      `${this.endpoint}/lista-nao-atendida/`
    )
    return data
  }

  findStatusById = async (
    id: string
  ): Promise<IRequisicaoEstado | undefined> => {
    if (!id || id === '') return
    const { data } = await api.get<IRequisicaoEstado>(
      `${this.endpoint}/estado/${id}`
    )
    return data
  }

  findAllAdmin = async (
    page: number,
    isAdmin: boolean,
    selectedOrder: string,
    q?: string,
    ativo: TAtivo = true
  ): Promise<IResult<IRequisicao>> => {
    const pageString = page ? `page=${page}` : ''
    const qString = q ? `q=${q}` : ''
    const ativoString = ativo !== undefined ? `ativo=${ativo}` : ''
    const adminString = isAdmin ? `admin=${isAdmin}` : ''
    const ordemString = selectedOrder ? `ordem=${selectedOrder}` : ''
    const params = [pageString, qString, ativoString, adminString, ordemString]
      .filter((value) => value !== '')
      .join('&')

    const { data } = await api.get<IResult<IRequisicao>>(
      `${this.endpoint}/internos/${isAdmin}?${params}`
    )
    return data
  }

  findAllIncomplete = async (
    page: number,
    isAdmin: boolean,
    selectedOrder: string,
    q?: string,
    ativo: TAtivo = true
  ): Promise<IResult<IRequisicao>> => {
    const pageString = page ? `page=${page}` : ''
    const adminString = isAdmin ? `admin=${isAdmin}` : ''
    const ordemString = selectedOrder ? `ordem=${selectedOrder}` : ''
    const qString = q ? `q=${q}` : ''
    const ativoString = ativo !== undefined ? `ativo=${ativo}` : ''
    const params = [pageString, qString, ativoString, adminString, ordemString]
      .filter((value) => value !== '')
      .join('&')

    const { data } = await api.get<IResult<IRequisicao>>(
      `${this.endpoint}/nao-completos-paginados/?${params}`
    )
    return data
  }

  findAllDate = async (
    page: number,
    date: string,
    isAdmin: boolean,
    selectedOrder: string,
    q?: string,
    ativo: TAtivo = true
  ): Promise<IResult<IRequisicao>> => {
    const pageString = page ? `page=${page}` : ''
    const adminString = isAdmin ? `admin=${isAdmin}` : ''
    const ordemString = selectedOrder ? `ordem=${selectedOrder}` : ''
    const qString = q ? `q=${q}` : ''
    const ativoString = ativo !== undefined ? `ativo=${ativo}` : ''
    const params = [pageString, qString, ativoString, adminString, ordemString]
      .filter((value) => value !== '')
      .join('&')

    const { data } = await api.get<IResult<IRequisicao>>(
      `${this.endpoint}/datados-paginados/${date}?${params}`
    )
    return data
  }

  findAllIncompleteByDate = async (
    page: number,
    date: string,
    isAdmin: boolean,
    selectedOrder: string,
    q?: string,
    ativo: TAtivo = true
  ): Promise<IResult<IRequisicao>> => {
    const pageString = page ? `page=${page}` : ''
    const adminString = isAdmin ? `admin=${isAdmin}` : ''
    const ordemString = selectedOrder ? `ordem=${selectedOrder}` : ''
    const qString = q ? `q=${q}` : ''
    const ativoString = ativo !== undefined ? `ativo=${ativo}` : ''
    const params = [pageString, qString, ativoString, adminString, ordemString]
      .filter((value) => value !== '')
      .join('&')

    const { data } = await api.get<IResult<IRequisicao>>(
      `${this.endpoint}/nao-completos-datados-paginados/${date}?${params}`
    )
    return data
  }

  validaToken = async ({
    id_requisicao,
    id_lote,
    token
  }: IValidacaoToken): Promise<IRequisicaoEstado> => {
    const { data } = await api.put<IRequisicaoEstado>(
      `${this.endpoint}/validatoken`,
      {
        id_requisicao,
        id_lote,
        token
      }
    )
    return data
  }
}

export const ApiRequisicao = new TApiRequisicao(endpoint, resourceId)
