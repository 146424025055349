import { useQuery } from 'react-query'
import { ApiDestinacaoDocumento } from '../../services/destinacaoDocumento'

const QUERY_KEY = 'qkDestinacaoDocumento'

const ListAll = () => {
  return useQuery([QUERY_KEY], ApiDestinacaoDocumento.listAll)
}

const FindById = (id_destinacao_documento: string) => {
  return useQuery([QUERY_KEY, id_destinacao_documento], () =>
    ApiDestinacaoDocumento.findOne(id_destinacao_documento)
  )
}

export const useDestinacaoDocumento = {
  ListAll,
  FindById
}
