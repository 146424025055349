import {
  Dialog,
  DialogTitle,
  Stack,
  TextField,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material'
import { ReactNode, useEffect, useState } from 'react'
import { useTipoCaixa } from '../../../../hooks/tipoCaixa/useTipoCaixa'
import { Loading } from '../../../../components/Loading'

interface EstanteIncludePopupProps {
  title: string
  show: boolean
  data: ReactNode
  close: () => void
  action: (
    quantidadeEstantes: number,
    quantidadePrateleiras: number,
    quantidadePosicoes: number,
    idTipoCaixa: string
  ) => void
}

export function EstanteIncludePopup({
  title,
  show,
  data,
  close,
  action
}: EstanteIncludePopupProps) {
  const [quantidadeEstantes, setQuantidadeEstantes] = useState<number>(1)
  const [quantidadePrateleiras, setQuantidadePrateleiras] = useState<number>(1)
  const [quantidadePosicoes, setQuantidadePosicoes] = useState<number>(1)
  const { data: tiposCaixas, isLoading } = useTipoCaixa.ListAll()
  const [idTipoCaixa, setIdTipoCaixa] = useState<string>('')
  const [valid, setValid] = useState<boolean>(false)

  const handleTipoCaixaChange = (id: string) => {
    setIdTipoCaixa(id)
  }

  useEffect(() => {
    const newValid =
      quantidadePosicoes > 0 &&
      quantidadePrateleiras > 0 &&
      !!idTipoCaixa &&
      idTipoCaixa !== ''
    setValid(newValid)
  }, [quantidadePosicoes, quantidadePrateleiras, idTipoCaixa])

  const reset = () => {
    setQuantidadePosicoes(1)
    setQuantidadePrateleiras(1)
    setIdTipoCaixa('')
  }

  const handleConfirmClick = () => {
    const tpCaixa = idTipoCaixa
    const qdtPrateleiras = quantidadePrateleiras
    const qtdPosicoes = quantidadePosicoes
    const qtdEstantes = quantidadeEstantes
    reset()
    action(qtdEstantes, qdtPrateleiras, qtdPosicoes, tpCaixa)
  }

  const handleCancelClick = () => {
    reset()
    close()
  }

  if (isLoading) <Loading />
  return (
    <Dialog open={show} fullWidth maxWidth={'xs'}>
      <DialogTitle
        sx={{
          backgroundColor: 'lightgray'
        }}
      >
        {title}
      </DialogTitle>
      <Stack
        display={'flex'}
        justifyContent={'space-between'}
        margin={'12px'}
        gap={2}
      >
        {data}
        <TextField
          variant="outlined"
          size="small"
          type={'number'}
          value={quantidadeEstantes}
          InputProps={{ inputProps: { min: 1 } }}
          label="Quantidade de Estantes"
          onChange={(event) => {
            setQuantidadeEstantes(Number(String(event?.target.value)))
          }}
        />
        <TextField
          variant="outlined"
          size="small"
          type={'number'}
          value={quantidadePrateleiras}
          InputProps={{ inputProps: { min: 1 } }}
          label="Quantidade de Prateleiras"
          onChange={(event) => {
            setQuantidadePrateleiras(Number(String(event?.target.value)))
          }}
        />
        <FormControl fullWidth>
          <InputLabel id="tipo-caixa-label">Tipo de Caixa</InputLabel>
          <Select
            labelId="tipo-caixa-label"
            id="tipo-caixa-select"
            value={idTipoCaixa}
            label="Tipo de Caixa"
            onChange={(event) =>
              handleTipoCaixaChange(String(event.target.value))
            }
          >
            {!!tiposCaixas &&
              tiposCaixas.length > 0 &&
              tiposCaixas.map((tipoCaixa) => (
                <MenuItem
                  key={tipoCaixa.id_tipo_caixa}
                  value={tipoCaixa.id_tipo_caixa}
                  // selected={tipoCaixa.id_tipo_caixa === id_tipo_caixa}
                >
                  {tipoCaixa.descricao} ({tipoCaixa.tamanho}U)
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          size="small"
          type={'number'}
          value={quantidadePosicoes}
          InputProps={{ inputProps: { min: 1 } }}
          label="Quantidade de Posições por Prateleiras"
          onChange={(event) => {
            setQuantidadePosicoes(Number(String(event?.target.value)))
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            width: '100%',
            marginTop: '12px'
          }}
          gap={2}
        >
          <Button
            onClick={() => {
              handleConfirmClick()
            }}
            variant="contained"
            color="primary"
            disabled={!valid}
          >
            Confirmar Expansão
          </Button>
          <Button
            onClick={() => handleCancelClick()}
            variant="outlined"
            color="error"
          >
            Cancelar Expansão
          </Button>
        </Box>
      </Stack>
    </Dialog>
  )
}
