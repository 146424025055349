import {
  IMovimentacao,
  IMovimentacaoExtendida
} from '../services/movimentacao/types'

export function simplifyMovimentacao(
  formData: IMovimentacaoExtendida
): IMovimentacao {
  return {
    id_movimentacao: formData.id_movimentacao,
    data_inicio: formData.data_inicio,
    data_fim: formData.data_fim,
    estado: formData.estado,
    id_item_requisicao: formData.id_item_requisicao,
    id_preco_atividade: formData.id_preco_atividade,
    id_usuario: formData.id_usuario,
    observacoes: formData.observacoes,
    previsao: formData.previsao
  }
}
