import { v4 as uuid } from 'uuid'
import { RemoveCircle } from '@mui/icons-material'
import ExpandMore from '@mui/icons-material/ExpandMore'
import {
  Typography,
  Accordion,
  AccordionSummary,
  Button,
  Box,
  AccordionDetails,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell
} from '@mui/material'
import { useEffect, useState } from 'react'

import { useServico } from '../../../../hooks/servico/useServico'
import { useSetor } from '../../../../hooks/setor/useSetor'
import {
  IItemContrato,
  IPrecoAtividade
} from '../../../../services/contrato/types'
import { PrecoAtividadeRow } from './PrecoAtividadeRow'

interface ItemContratoProps {
  itemContrato: IItemContrato
  dataVigencia: Date
  removeItemPrecoCallBack: (id: string) => void
  updateDataCallback: (ItemContrato: IItemContrato) => void
  disableButtons?: boolean
}

export interface ListaSetores {
  id_setor: string
  nome: string
  empresa: string
}

export function ItemContratoAccordion({
  itemContrato,
  dataVigencia,
  removeItemPrecoCallBack,
  updateDataCallback,
  disableButtons = false
}: ItemContratoProps) {
  const { data: servicoCompleto, isLoading } = useServico.FindOneWithFluxo(
    itemContrato.servico.id_servico
  )
  const [listaSetores, setListaSetores] = useState<Array<ListaSetores>>([])

  const { data: setores } = useSetor.ListAll()

  useEffect(() => {
    const newListaSetores =
      setores?.map((setor) => ({
        id_setor: setor.id_setor ?? '',
        nome: setor.nome ?? '',
        empresa: setor.empresa.nome ?? ''
      })) ?? []
    setListaSetores(newListaSetores)
  }, [setores])

  useEffect(() => {
    if (
      (!itemContrato.precos_atividades ||
        itemContrato.precos_atividades.length === 0) &&
      !isLoading
    ) {
      const newPrecos = servicoCompleto?.atividades?.map((itemServico) => ({
        atividade: {
          id_atividade: itemServico.id_atividade,
          tipo_atividade: itemServico.tipo_atividade,
          ordem: itemServico.ordem
        },
        id_preco_atividade: uuid(),
        valor: 0,
        data_inicio: dataVigencia,
        sla: 0,
        setor: {
          id_setor: '',
          nome: '',
          empresa: {
            id_empresa: '',
            nome: ''
          }
        }
      }))
      itemContrato.precos_atividades = newPrecos ?? []
    }
  }, [isLoading, itemContrato])

  function updatePreco(newPrecoAtividade: IPrecoAtividade) {
    const newItemContrato = itemContrato
    const { precos_atividades } = newItemContrato
    const oldPrecoAtividade = precos_atividades.find(
      (precoAtividade) =>
        precoAtividade.id_preco_atividade ===
        newPrecoAtividade.id_preco_atividade
    )
    if (oldPrecoAtividade) {
      const index = precos_atividades.indexOf(oldPrecoAtividade)
      precos_atividades[index] = newPrecoAtividade
      newItemContrato.precos_atividades = precos_atividades
      updateDataCallback(newItemContrato)
    }
  }

  return (
    <Box>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <Typography>{itemContrato.servico.nome}</Typography>
            <Button
              disabled={disableButtons}
              onClick={() => {
                removeItemPrecoCallBack(itemContrato.servico.id_servico)
              }}
            >
              <RemoveCircle />
            </Button>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
                  <TableCell>Atividade</TableCell>
                  <TableCell>Setor de Execução</TableCell>
                  <TableCell>Valor</TableCell>
                  <TableCell>SLA (horas)</TableCell>
                  <TableCell>Início de Vigência</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {itemContrato.precos_atividades
                  ?.sort((precoAtividadeA, precoAtividadeB) => {
                    if (
                      precoAtividadeA.atividade.ordem >
                      precoAtividadeB.atividade.ordem
                    )
                      return 1
                    if (
                      precoAtividadeA.atividade.ordem <
                      precoAtividadeB.atividade.ordem
                    )
                      return -1
                    return 0
                  })
                  .map((precoAtividade) => (
                    <PrecoAtividadeRow
                      precoAtividade={precoAtividade}
                      setores={listaSetores}
                      updatePreco={updatePreco}
                      key={
                        precoAtividade.atividade.id_atividade +
                        itemContrato.servico.id_servico
                      }
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}
