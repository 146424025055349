import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ApiEmpresa } from '../../services/empresa'
import { IContratoPermitidoUsuario } from '../../services/contratoPermitido/types'

const QUERY_KEY = 'qkEmpresa'

const FindAll = (page: number, q = '') => {
  return useQuery([QUERY_KEY, q, page], () => ApiEmpresa.findAll(page, q))
}

const FindAllByUserContratos = (
  page: number,
  userContratos: IContratoPermitidoUsuario[],
  q = ''
) => {
  return useQuery([QUERY_KEY, q, page, 'userContratos'], () =>
    ApiEmpresa.findAllByUserContratos(page, userContratos, q)
  )
}

const FindOne = (id: string) => {
  return useQuery([QUERY_KEY, id], () => ApiEmpresa.findOne(id))
}

const Create = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiEmpresa.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const ListAll = () => {
  return useQuery([QUERY_KEY], () => ApiEmpresa.listAll())
}

const FindAllByContratoPermitidos = () => {
  return useQuery([QUERY_KEY], () => ApiEmpresa.findAllByContratoPermitidos())
}

const ListAllXSolution = () => {
  return useQuery([QUERY_KEY, 'X-SOLUTION'], () =>
    ApiEmpresa.listAllXSolution()
  )
}

const Update = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiEmpresa.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Remove = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiEmpresa.remove, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

export const useEmpresa = {
  Create,
  Update,
  FindAll,
  FindOne,
  ListAll,
  ListAllXSolution,
  FindAllByUserContratos,
  FindAllByContratoPermitidos,
  Remove
}
