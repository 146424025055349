import { DefaultApi } from '../../utils/defaultApi'
import { ITipoPapelEmpresa } from './types'

const endpoint = '/tipo-papel-empresa'
const resourceId = 'id_tipo_papel_empresa'

const apiTipoPapelEmpresa = new DefaultApi<ITipoPapelEmpresa>(
  endpoint,
  resourceId
)

export const ApiTipoPapelEmpresa = {
  findAll: apiTipoPapelEmpresa.findAll,
  listAll: apiTipoPapelEmpresa.listAll
}
