/**
 * Exclui propriedades de um objeto
 * @param obj Objeto que terá propriedades removidas
 * @param keys Lista de nomes (strings) de propriedades a serem removidas
 * @returns Objeto com propriedades excluidas
 */
export function exclude<T, Key extends keyof T>(
  obj: T,
  keys: Key[]
): Omit<T, Key> {
  for (const key of keys) {
    delete obj[key]
  }
  return obj
}
