import { useState } from 'react'
import { Box } from '@mui/material'
import { FormMode } from '../../../types/formMode'
import { FormTitle } from '../../../components/Form/FormTitle'
import { LancamentoList } from './LancamentoList'
import { LancamentoForm } from './LancamentoForm'

export interface ILancamentoProps {
  tipo_lancamento: string
}

export function Lancamento({ tipo_lancamento }: ILancamentoProps) {
  const [formMode, setFormMode] = useState<FormMode>('LIST')
  const [data, setData] = useState<any>({})

  return (
    <Box bgcolor="#fff" p={4} borderRadius={2}>
      <FormTitle
        title={`Contas à ${tipo_lancamento === 'R' ? 'Receber' : 'Pagar'}`}
        subtitle={formMode}
      />

      {formMode === 'LIST' ? (
        <LancamentoList
          setData={setData}
          setFormMode={setFormMode}
          tipo_lancamento={tipo_lancamento}
        />
      ) : (
        <LancamentoForm
          data={['EDIT', 'VIEW'].includes(formMode) ? data : {}}
          formMode={formMode}
          setFormMode={setFormMode}
          tipo_lancamento={tipo_lancamento}
        />
      )}
    </Box>
  )
}
