import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material'
import { ICaixa } from '../../../services/caixa/types'
import { DashboardWidget } from '../../../types/DashboardWidget'
import { DashboardWidgetListBody } from '../../../types/DashboardWidget/DashboardWidgetList/DashboardWidgetListBody'
import { useState } from 'react'
import { corFundoLinha } from '../../../pages/Dashboard/Widgets/lib/consts'
import { corTextoLinha } from '../../../types/DashboardWidget/cores'
import ArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import ArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'
import CancelIcon from '@mui/icons-material/DoNotDisturbAlt'
import DocIcon from '@mui/icons-material/ArticleOutlined'
import { Archive } from '@mui/icons-material'
import { DocumentoAnonimizado, construirObjetoAPartirDeString } from './utils'
import { getCodigo } from '../../../utils/getCodigo'
import { Permissions } from '../../../types/permissions'
import { hasAccess } from '../../../utils/hasAccess'
import { useUsuario } from '../../../hooks/usuario/useUsuario'
import { IUsuario } from '../../../services/usuario/types'

export interface ITableCaixaProps {
  listaCaixas: ICaixa[]
  listaCaixasAdicionadas?: ICaixa[]
  caixasSelecionadas: ICaixa[]
  setCaixasSelecionadas?: () => void
  caixaOrderField?: string
  caixaOrder?: string
  setCaixaOrderField?: (field: string) => void
  setCaixaOrder?: (order: string) => void
  handleBoxClick?: (caixa: ICaixa) => void
}

export interface ICaixaTableRowProps {
  cx: ICaixa
  listaCaixasAdicionadas?: ICaixa[]
  listaCaixasSelecionadas: ICaixa[]
  handleAddBox: (cx: ICaixa) => void
  marcarTodos: boolean
  index: number
  usuario?: IUsuario
}

const getBoxContent = (cx: ICaixa) => {
  if (
    (!cx.documentos || cx.documentos.length < 1) &&
    (!cx.caixaFilha || cx.caixaFilha.length < 1)
  )
    return (
      <Stack direction="row" gap={0.5}>
        <CancelIcon fontSize="small" color="warning" />
        <Typography variant="subtitle2" color="GrayText">
          Conteúdo não informado
        </Typography>
      </Stack>
    )
  const docs =
    !!cx.documentos && cx.documentos.length > 0 ? (
      <Stack gap={0.5}>
        {cx.documentos?.map((d) => {
          const conteudoObj = construirObjetoAPartirDeString(d.descricao)
          const descricaoAnonimizada = DocumentoAnonimizado(conteudoObj)

          return (
            <Stack key={d.id_documento} direction="row" gap={0.5}>
              <DocIcon fontSize="small" color="info" />
              <Typography variant="subtitle2">
                {descricaoAnonimizada} - {d.tipo_documento.descricao}
              </Typography>
            </Stack>
          )
        })}
      </Stack>
    ) : (
      <></>
    )
  const boxes =
    !!cx.caixaFilha && cx.caixaFilha.length > 0 ? (
      <Stack gap={0.5}>
        {cx.caixaFilha?.map((box) => {
          const conteudoObj = construirObjetoAPartirDeString(box.identificacao)
          const descricaoAnonimizada = DocumentoAnonimizado(conteudoObj)
          return (
            <Stack key={box.id_caixa} direction="row" gap={0.5}>
              <Archive fontSize="small" color="info" />
              <Typography variant="subtitle2">
                {descricaoAnonimizada} - {box.tipoCaixa?.descricao ?? ''}
              </Typography>
            </Stack>
          )
        })}
      </Stack>
    ) : (
      <></>
    )
  return (
    <>
      {docs}
      {boxes}
    </>
  )
}

export function CaixaTableRow({
  cx,
  handleAddBox,
  marcarTodos,
  listaCaixasAdicionadas,
  listaCaixasSelecionadas,
  usuario,
  index
}: ICaixaTableRowProps) {
  const [seeMore, setSeeMore] = useState(false)
  const isCaixaAdd = listaCaixasAdicionadas?.some(
    (box) => box.id_caixa === cx.id_caixa
  )

  const conteudo = !seeMore ? (
    cx.conteudo
  ) : (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span>{cx.conteudo}</span>
      {getBoxContent(cx)}
    </div>
  )
  const values = [
    <FormControlLabel
      control={
        <Checkbox
          onClick={() => {
            handleAddBox(cx)
          }}
          checked={
            !isCaixaAdd &&
            (marcarTodos ||
              listaCaixasSelecionadas.some(
                (item) => item.id_caixa === cx.id_caixa
              ))
          }
          sx={{
            paddingLeft: 2
          }}
          disabled={isCaixaAdd}
        />
      }
      label=""
    />,
    <>
      {cx.identificacao}
      {hasAccess(usuario?.permissoes, Permissions.EXIBIR_ID_CAIXA) ? (
        <Typography
          sx={{
            fontSize: '10px',
            fontWeight: 'bold',
            color: 'GrayText'
          }}
        >
          ({getCodigo(cx.id_caixa ?? '')})
        </Typography>
      ) : (
        ''
      )}
    </>,
    conteudo,
    cx.setor?.nome,
    cx.tipo_documento?.descricao,
    `${cx.tipoCaixa?.descricao} (${cx.tipoCaixa?.tamanho.toFixed(0)}U)`,
    seeMore ? (
      <ArrowUpIcon
        style={{ cursor: 'pointer' }}
        onClick={() => setSeeMore(!seeMore)}
      />
    ) : (
      <ArrowDownIcon
        style={{ cursor: 'pointer' }}
        onClick={() => setSeeMore(!seeMore)}
      />
    )
  ]
  const corTextoLinhaChecado = isCaixaAdd ? '#047857' : corTextoLinha
  return (
    <DashboardWidgetListBody.Row
      sx={{
        backgroundColor: index % 2 === 0 ? corFundoLinha : ''
      }}
      cellStyle={{
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '0.75rem',
        color: corTextoLinhaChecado,
        paddingBottom: 0,
        paddingTop: 0,
        paddingLeft: 1,
        borderBottom: 0,
        height: '40px'
      }}
      values={values}
      key={cx.id_caixa}
    >
      <></>
    </DashboardWidgetListBody.Row>
  )
}

export function TableCaixas({
  listaCaixas,
  caixasSelecionadas,
  setCaixaOrder,
  setCaixaOrderField,
  caixaOrder,
  caixaOrderField,
  listaCaixasAdicionadas,
  handleBoxClick
}: ITableCaixaProps) {
  const [marcarTodos, setMarcarTodos] = useState(false)
  const { data: usuario } = useUsuario.FindByUsername()

  const handleAddAllBox = () => {
    for (const cx of listaCaixas) {
      const isCaixaAdd = listaCaixasAdicionadas?.some(
        (box) => box.id_caixa === cx.id_caixa
      )
      const isCaixaSelecionada = caixasSelecionadas.some(
        (item) => item.id_caixa === cx.id_caixa
      )
      if (!isCaixaAdd && !isCaixaSelecionada) {
        handleAddBox(cx)
      }
    }
    setMarcarTodos(!marcarTodos)
  }
  const handleAddBox = (cx: ICaixa) => {
    if (!marcarTodos) {
      handleBoxClick && handleBoxClick(cx)
    }
  }
  const colunasLista = [
    {
      title: '',
      width: 20,
      component: (
        <Checkbox
          onClick={handleAddAllBox}
          checked={marcarTodos}
          disabled={false}
        />
      )
    },
    {
      title: 'Identificação',
      field: 'identificacao',
      width: 100,
      canOrder: true
    },
    {
      title: 'Conteúdo',
      field: 'conteudo',
      width: '100%',
      canOrder: true
    },
    {
      title: 'Setor',
      field: 'setor.nome',
      canOrder: true
    },
    {
      title: 'Tipo Documento',
      field: 'tipo_documento.descricao',
      canOrder: true,
      width: 150
    },
    {
      title: 'Tipo Caixa',
      field: 'tipoCaixa.descricao',
      canOrder: true,
      width: 120
    },
    {
      title: '',
      width: 12
    }
  ]
  return (
    <DashboardWidget.Root
      sx={{
        borderRadius: '20px',
        boxShadow:
          '0px 8px 16px -4px rgba(24, 39, 75, 0.08), 0px 6px 0px -6px rgba(0, 0, 0, 0.25)',
        backgroundColor: '#fff',
        margin: '0rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '1rem',
        maxHeight: '840px',
        width: '100%'
      }}
    >
      <DashboardWidgetListBody.Root>
        <DashboardWidgetListBody.Table
          // title={tipoAtividade.descricao}
          columnsDefs={colunasLista}
          canOrder={true}
          setOrderField={setCaixaOrderField}
          setOrder={setCaixaOrder}
          orderDirection={caixaOrder}
          orderField={caixaOrderField}
        >
          {listaCaixas?.map((cx, idx) => {
            return (
              <CaixaTableRow
                listaCaixasSelecionadas={caixasSelecionadas}
                cx={cx}
                handleAddBox={handleAddBox}
                marcarTodos={marcarTodos}
                index={idx}
                key={cx.id_caixa}
                listaCaixasAdicionadas={listaCaixasAdicionadas}
                usuario={usuario}
              />
            )
          })}
        </DashboardWidgetListBody.Table>
      </DashboardWidgetListBody.Root>
    </DashboardWidget.Root>
  )
}
