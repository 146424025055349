import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Pagination,
  Paper,
  Radio,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import SaveIcon from '@mui/icons-material/CheckCircleOutlined'
import DocIcon from '@mui/icons-material/ArticleOutlined'
import CancelIcon from '@mui/icons-material/DoNotDisturbAlt'
import BackIcon from '@mui/icons-material/ArrowBack'
import ForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { Add, Archive, Cancel } from '@mui/icons-material'
import PreviewIcon from '@mui/icons-material/DocumentScannerOutlined'
import format from 'date-fns/format'
import boxImg from '../../../assets/images/box.png'
import ArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import ArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'
import { useEffect, useMemo } from 'react'
import { useState } from 'react'

import { Fluxo } from './Fluxo'
import { Select } from '../../../components/Form/Select'
import { useContrato } from '../../../hooks/contrato/useContrato'
import { Input } from '../../../components/Form/Input'
import { useCaixa } from '../../../hooks/caixa/useCaixa'
import { useItemContrato } from '../../../hooks/itemContrato/useItemContrato'
import { useAuth } from '../../../hooks/auth'
import { useRequisicao } from '../../../hooks/requisicao/useRequisicao'
import useToast from '../../../hooks/toast/useToast'
import { useUsuario } from '../../../hooks/usuario/useUsuario'
import { Loading } from '../../../components/Loading'
import { AdvancedFindAllParams } from '../../../services/caixa/types'
import { ICaixa } from '../../../services/caixa/types'
import { TabelaCaixas } from './components/TabelaCaixas'
import { ComponentGroup } from '../../../components/ComponentGroup'
import { RadioGroup } from './components/RadioGroup'
import { useDocumento } from '../../../hooks/documento/useDocumento'
import { privacidadeToText } from '../../../utils/privacedToText'
import { useServico } from '../../../hooks/servico/useServico'
import { IDocumento as IDocumentoDocumento } from '../../../services/documento/types'
import { useTipoCaixa } from '../../../hooks/tipoCaixa/useTipoCaixa'
import { SearchBox } from '../../../components/SearchBox'
import { useArvoreDocumental } from '../../../hooks/arvoreDcoumental/useArvoreDocumental'
import { useSetorPermitido } from '../../../hooks/setorPermitido/useSetorPermitido'
import { Permissions } from '../../../types/permissions'
import { hasPermission } from '../../../utils/hasPermission'
import { ApiDocumento } from '../../../services/documento'
import VisaoCaixas from '../../../components/Caixa/VisaoCaixas'
import { TableCaixas } from '../../../components/Caixa/TableCaixas'

enum Steps {
  CAIXA = 0,
  ETIQUETAS = 1,
  REQUISICAO = 2,
  CONFERENCIA = 3,
  CONCLUSAO = 4
}

export function Requisicao() {
  // Estados (INÍCIO) ----------------------------------------------------------

  // Todas as telas (INÍCIO)
  const [step, setStep] = useState<Steps>(Steps.CAIXA)
  // Todas as telas (FIM)

  // Dados da tela 1 (INÍCIO)
  const [idContrato, setIdContrato] = useState<string>('')
  const [idItemContrato, setIdItemContrato] = useState<string>('')
  const [idServico, setIdServico] = useState<string>('')
  const [prerequisitos, setPrerequisitos] = useState<string>('')
  const [observacao, setObservacao] = useState<string>('')
  const [urgente, setUrgente] = useState<boolean>(false)
  const [tipoConteudo, setTipoConteudo] = useState<number>(0)
  const [isVisaoCaixas, setIsVisaoCaixas] = useState<boolean>(true) // true- Visão em caixas false-lista
  const [validationMessageContract, setValidationMessageContract] =
    useState<string>()
  const [validationMessageService, setValidationMessageService] =
    useState<string>()
  const [validationMessageTipoConteudo, setValidationMessageTipoConteudo] =
    useState<string>()
  const [selectedOrder, setSelectedOrder] = useState<string>()
  const [caixaOrderField, setCaixaOrderField] =
    useState<string>('identificacao')
  const [caixaOrderDirection, setCaixaOrderDirection] = useState<string>('asc')

  const { data: listAllContrato } = useContrato.ListAll()
  const { data: dataFindByContrato } = useItemContrato.ListByIdContrato(
    idContrato ?? '0'
  )
  const { data: servico } = useServico.FindOneWithFluxo(idServico)
  // Dados da tela 1 (FIM)

  // Dados da tela 2 - Caixas (INÍCIO)

  const { data: setoresPermitidos } = useSetorPermitido.FindByUsuarioLogado()

  const formatOrdemConsulta = () => {
    const orderDirection = caixaOrderDirection === 'asc' ? '' : 'Inv'
    switch (caixaOrderField) {
      case 'identificacao':
        return `Identificacao${orderDirection}`
      case 'conteudo':
        return `Conteudo${orderDirection}`
      case 'setor.nome':
        return `Setor${orderDirection}`
      case 'tipo_documento.descricao':
        return `TipoDocumento${orderDirection}`
      case 'tipoCaixa.descricao':
        return `TipoCaixa${orderDirection}`
      default:
        break
    }
  }

  useEffect(() => {
    setSearchCriteriaCaixas({ ordem: formatOrdemConsulta() ?? 'Identificacao' })
  }, [caixaOrderField, caixaOrderDirection])

  const searchParams2AdvanceSearchParams = (
    search: URLSearchParams
  ): AdvancedFindAllParams => {
    let newAdv: AdvancedFindAllParams = {}
    if (search.get('busca') !== null) {
      newAdv = {
        ...newAdv,
        busca: search.get('busca') ?? undefined
      }
    }
    if (search.get('data') !== null) {
      newAdv = {
        ...newAdv,
        data: search.get('data') ?? undefined
      }
    }
    if (search.get('sequencia') !== null) {
      newAdv = {
        ...newAdv,
        sequencia: search.get('sequencia') ?? undefined
      }
    }
    if (search.get('id_tipo_caixa') !== null) {
      newAdv = {
        ...newAdv,
        id_tipo_caixa: search.get('id_tipo_caixa') ?? undefined
      }
    }
    if (search.get('id_tipo_documento') !== null) {
      newAdv = {
        ...newAdv,
        id_tipo_documento: search.get('id_tipo_documento') ?? undefined
      }
    }

    if (search.get('id_setor') !== null) {
      newAdv = {
        ...newAdv,
        id_setor: search.get('id_setor') ?? undefined
      }
    }
    if (search.get('dados_pessoais') !== null) {
      newAdv = {
        ...newAdv,
        dados_pessoais: search.get('dados_pessoais') ?? undefined
      }
    }
    if (search.get('dados_pessoais_sensiveis') !== null) {
      newAdv = {
        ...newAdv,
        dados_pessoais_sensiveis:
          search.get('dados_pessoais_sensiveis') ?? undefined
      }
    }
    if (search.get('dados_criancas_adolescentes') !== null) {
      newAdv = {
        ...newAdv,
        dados_criancas_adolescentes:
          search.get('dados_criancas_adolescentes') ?? undefined
      }
    }

    return newAdv
  }

  const [searchParamsCaixas, setSearchParamsCaixas] = useSearchParams()

  const [advancedSearchParamsCaixas, setAdvancedSearchParamCaixas] =
    useState<AdvancedFindAllParams>({
      ...searchParams2AdvanceSearchParams(searchParamsCaixas)
    })

  const { data: tiposCaixas, isLoading: isLoadingTiposCaixas } =
    useTipoCaixa.ListAll()
  const { data: tiposDocumentos, isLoading: isLoadingTiposDocumentos } =
    useArvoreDocumental.FindTiposDocumentos()

  const setSearchCriteriaCaixas = (newSearchParams: AdvancedFindAllParams) => {
    setSearchParamsCaixas({
      ...searchParamsCaixas,
      ...newSearchParams
    })
    setAdvancedSearchParamCaixas(newSearchParams)
  }

  const pageCaixa = useMemo(() => {
    return Number(searchParamsCaixas.get('page') ?? 1)
  }, [searchParamsCaixas])

  const [listaCaixas, setListaCaixas] = useState<ICaixa[]>([])
  const [listaCaixasSelecionadas, setListaCaixasSelecionadas] = useState<
    ICaixa[]
  >([])
  const [tempLimit, setTempLimit] = useState<number>(10)

  const { data: caixas, isLoading: isLoadingCaixas } = useCaixa.FindAll(
    pageCaixa,
    {
      ...advancedSearchParamsCaixas,
      requisitos: prerequisitos,
      limit: tempLimit
    }
  )

  const [caixasDisponiveis, setCaixasDisponiveis] = useState<ICaixa[]>([])

  const [validationMessageBoxes, setValidationMessageBoxes] = useState<string>()
  const [seeMore, setSeeMore] = useState<string>()
  // Dados da tela 2 - Caixas (FIM)

  // Dados da tela 2 - Documentos (INÍCIO)
  const [searchParamsDocumentos, setSearchParamsDocumentos] = useSearchParams()

  const [advancedSearchParamsDocumentos, setAdvancedSearchParamDocumentos] =
    useState<AdvancedFindAllParams>({
      ...searchParams2AdvanceSearchParams(searchParamsCaixas)
    })

  const pageDocumento = useMemo(() => {
    return Number(searchParamsDocumentos.get('page') ?? 1)
  }, [searchParamsDocumentos])

  const { data: documentos, isLoading: isLoadingDocumentos } =
    useDocumento.AdvancedFindAll(pageDocumento, {
      ...advancedSearchParamsDocumentos,
      requisitos: prerequisitos,
      limit: tempLimit,
      ordem: selectedOrder
    })

  const [listaDocumentos, setListaDocumentos] = useState<IDocumentoDocumento[]>(
    []
  )
  const [validationMessageDocuments, setValidationMessageDocuments] =
    useState<string>()
  // Dados da tela 2 - Documentos (FIM)

  const [currentTimeout, setCurrentTimeout] = useState<NodeJS.Timeout>()

  // Estados (FIM) -------------------------------------------------------------

  const getServico = (idItemContrato: string): string => {
    const itemContratoExists = dataFindByContrato?.find(
      (item) => item.id_item_contrato === idItemContrato
    )

    return itemContratoExists?.servico.id_servico ?? ''
  }

  const navigate = useNavigate()

  const { user } = useAuth()
  const { Toast } = useToast()
  const { mutateAsync: createRequisicao, isLoading: isLoadingRequisicao } =
    useRequisicao.Create()

  const { data: usuario } = useUsuario.FindByUsername()

  const listServico = dataFindByContrato?.map(
    ({ id_item_contrato, servico: { nome } }) => ({
      id_item_contrato,
      nome
    })
  )

  const Row = ({ text, value }: { text: string; value?: string | number }) => {
    return (
      <TableRow>
        <TableCell
          sx={{ border: 0, fontWeight: '600', fontSize: 14, width: '160px' }}
        >
          {text}:
        </TableCell>
        <TableCell sx={{ border: 0, fontSize: 14 }}>{value}</TableCell>
      </TableRow>
    )
  }

  const getBoxContent = (cx: ICaixa) => {
    if (
      (!cx.documentos || cx.documentos.length < 1) &&
      (!cx.caixaFilha || cx.caixaFilha.length < 1)
    )
      return (
        <Stack direction="row" gap={0.5}>
          <CancelIcon fontSize="small" color="warning" />
          <Typography variant="subtitle2" color="GrayText">
            Conteúdo não informado
          </Typography>
        </Stack>
      )
    const docs =
      !!cx.documentos && cx.documentos.length > 0 ? (
        <Stack gap={0.5}>
          {seeMore === cx.id_caixa
            ? cx.documentos?.map((d) => (
                <Stack key={d.id_documento} direction="row" gap={0.5}>
                  <DocIcon fontSize="small" color="info" />
                  <Typography variant="subtitle2">
                    {d.descricao} - {d.tipo_documento.descricao}
                  </Typography>
                </Stack>
              ))
            : cx.documentos?.map(
                (d, index) =>
                  index < 3 && (
                    <Stack key={d.id_documento} direction="row" gap={0.5}>
                      <DocIcon fontSize="small" color="info" />
                      <Typography variant="subtitle2">
                        {`${d.descricao} - ${d.tipo_documento.descricao}`
                          .length > 24
                          ? `${d.descricao} - ${d.tipo_documento.descricao}`.substring(
                              0,
                              24
                            ) + '...'
                          : `${d.descricao} - ${d.tipo_documento.descricao}`}
                      </Typography>
                    </Stack>
                  )
              )}
        </Stack>
      ) : (
        <></>
      )
    const boxes =
      !!cx.caixaFilha && cx.caixaFilha.length > 0 ? (
        <Stack gap={0.5}>
          {seeMore === cx.id_caixa
            ? cx.caixaFilha?.map((box) => (
                <Stack key={box.id_caixa} direction="row" gap={0.5}>
                  <Archive fontSize="small" color="info" />
                  <Typography variant="subtitle2">
                    {box.identificacao} - {box.tipoCaixa?.descricao ?? ''}
                  </Typography>
                </Stack>
              ))
            : cx.caixaFilha?.map(
                (box, index) =>
                  index < 3 && (
                    <Stack key={box.id_caixa} direction="row" gap={0.5}>
                      <Archive fontSize="small" color="info" />
                      <Typography variant="subtitle2">
                        {`${box.identificacao} - ${
                          box.tipoCaixa?.descricao ?? ''
                        }`.length > 24
                          ? `${box.identificacao} - ${
                              box.tipoCaixa?.descricao ?? ''
                            }`.substring(0, 24) + '...'
                          : `${box.identificacao} - ${
                              box.tipoCaixa?.descricao ?? ''
                            }`}
                      </Typography>
                    </Stack>
                  )
              )}
        </Stack>
      ) : (
        <></>
      )
    return (
      <>
        {docs}
        {boxes}
      </>
    )
  }

  // const isLongTextDocs = (docs: IDocumentoCaixa[]): boolean =>
  //   docs.some(
  //     (d: IDocumentoCaixa) =>
  //       `${d.descricao} - ${d.tipo_documento?.descricao}`.length > 24
  //   )

  // Handlers (INÍCIO) ---------------------------------------------------------

  /**
   * Valida os dados da tela atual e, se tudo estiver correto, vai para próxima
   * tela
   */
  const nextStep = () => {
    if (step === 0) {
      if (idContrato === undefined || idContrato.trim() === '')
        setValidationMessageContract('Informe o Contrato')
      if (idItemContrato === undefined || idItemContrato.trim() === '')
        setValidationMessageService('Informe o Serviço')
      if (tipoConteudo < 1 || tipoConteudo > 2) {
        setValidationMessageTipoConteudo(
          'Selecione o tipo de conteúdo da requisição'
        )
      }
      if (
        !!idContrato &&
        idContrato.trim() !== '' &&
        !!idItemContrato &&
        idItemContrato.trim() !== '' &&
        [1, 2].includes(tipoConteudo)
      ) {
        setStep(step + 1)
      }
    }

    if (step === 1 && tipoConteudo === 1) {
      if (listaCaixas.length === 0)
        setValidationMessageBoxes('Selecione pelo menos uma caixa')
      else setStep(step + 1)
    }

    if (step === 1 && tipoConteudo === 2) {
      if (listaDocumentos.length === 0)
        setValidationMessageDocuments('Selecione pelo menos um documento')
      else setStep(step + 1)
    }
  }

  /**
   * Cancela a criação da requisição, limpando todos os dados
   */
  const handleCancelButtonClick = () => {
    setStep(0)

    // Dados da tela 1 (INÍCIO)
    setIdContrato('')
    setIdItemContrato('')
    setObservacao('')
    setTipoConteudo(0)
    setValidationMessageContract(undefined)
    setValidationMessageService(undefined)
    setValidationMessageTipoConteudo(undefined)
    // Dados da tela 1 (FIM)

    // Dados da tela 2 - Documentos (INÍCIO)
    setAdvancedSearchParamCaixas({})
    const newSearchParams = {}
    setSearchParamsCaixas({ ...newSearchParams })

    setListaCaixas([])
    setValidationMessageBoxes(undefined)
    // Dados da tela 2 - Documentos (INÍCIO)

    // Dados da tela 2 - Documentos (INÍCIO)
    setAdvancedSearchParamDocumentos({})
    setSearchParamsDocumentos({ ...newSearchParams })
    setListaDocumentos([])
    setValidationMessageDocuments(undefined)
    // Dados da tela 2 - Documentos (INÍCIO)
  }

  /**
   * Tomar ações de acordo com o contrato selecionado
   * @param idContratoSelected ID do contrato selecionado
   */
  function handleSelectContratoChange(idContratoSelected: string) {
    setIdContrato(idContratoSelected)
    setIdItemContrato('')
    setValidationMessageContract(undefined)
    setTipoConteudo(0)
    setValidationMessageTipoConteudo(undefined)

    setListaCaixas([])
    setValidationMessageBoxes(undefined)

    setListaDocumentos([])
    setValidationMessageDocuments(undefined)
    setSearchCriteriaCaixas({
      id_contrato: idContratoSelected,
      ordem: formatOrdemConsulta() ?? 'Identificacao'
    })
  }

  /**
   * Tomar ações de acordo com serviço selecionado
   * @param newIdItemContrato ID do serviço selecionado
   */
  function handleSelectServicoChange(newIdItemContrato: string) {
    setIdItemContrato(newIdItemContrato)

    setIdServico(getServico(newIdItemContrato))

    setValidationMessageService(undefined)
    setTipoConteudo(0)
    setValidationMessageTipoConteudo(undefined)

    setListaCaixas([])
    setValidationMessageBoxes(undefined)

    setListaDocumentos([])
    setValidationMessageDocuments(undefined)
  }

  /**
   * Muda o tipo de conteúdo da requisição
   * @param value 1 se for Caixas, 2 se for documentos
   */
  const handleTipoConteudoChange = (value: number) => {
    setTipoConteudo(value)
    setValidationMessageTipoConteudo(undefined)
    setListaCaixas([])
    setValidationMessageBoxes(undefined)

    setListaDocumentos([])
    setValidationMessageDocuments(undefined)
  }

  /**
   * Faz a busca das caixas especificadas pelos parâmetros de busca
   * @param event Evento de click do botão
   */

  const handleBoxClick = (box: ICaixa): void => {
    //se a caixa estiver na lista de caixas adicionadas não pode ser selecionada
    if (!listaCaixas.includes(box)) {
      if (!listaCaixasSelecionadas.includes(box)) {
        setListaCaixasSelecionadas((state) => [...state, box])
        setValidationMessageBoxes(undefined)
      } else {
        setListaCaixasSelecionadas(
          listaCaixasSelecionadas.filter(
            (addedBox) => addedBox.id_caixa !== box.id_caixa
          )
        )
      }
      setSeeMore(undefined)
    }
  }

  /**
   * Adiciona as caixas selecionadas à lista de caixas da requisição.
   */
  const handleAddClick = () => {
    const newSelectedBoxes = [
      ...listaCaixas,
      ...listaCaixasSelecionadas.filter((box) => !listaCaixas.includes(box))
    ]
    setListaCaixasSelecionadas([])
    setListaCaixas(newSelectedBoxes)
  }

  /**
   * Remove a caixa da lista de caixas da requisição.
   */
  const handleRemoveClick = (id: string) => {
    const newListaCaixas = listaCaixas.filter((caixa) => caixa.id_caixa !== id)
    setListaCaixas(newListaCaixas)
  }

  /**
   * Define o limite de itens buscados do back a serem exibidos em tela.
   * Essa informação é aplicada tanto a busca de caixas, quanto a busca de
   * documentos.
   * @param valor Novo limite
   */
  const handleLimitChange = (valor: number) => {
    const newValor = valor > 100 ? 100 : valor
    setTempLimit(newValor)
    if (currentTimeout) {
      clearTimeout(currentTimeout)
      setCurrentTimeout(undefined)
    }
    const newTimeout = setTimeout(() => {
      const newSearchParamsCaixas = {
        ...searchParamsCaixas,
        limit: valor
      }
      setSearchParamsCaixas(newSearchParamsCaixas)
      const newSearchParamsDocumentos = {
        ...searchParamsDocumentos,
        limit: valor
      }
      setSearchParamsDocumentos(newSearchParamsDocumentos)
    }, 1000)
    setCurrentTimeout(newTimeout)
  }

  /**
   * Faz a busca dos documentos especificados pelos parâmetros de busca
   * @param event Evento de click do botão
   */
  const setSearchCriteriaDocumentos = (
    newSearchParams: AdvancedFindAllParams
  ) => {
    setSearchParamsDocumentos({
      ...searchParamsDocumentos,
      ...newSearchParams
    })
    setAdvancedSearchParamDocumentos(newSearchParams)
  }

  /**
   * Adiciona um documento à lista de documentos da requisição
   * @param documento Documento a ser adicionado
   */
  const handleAddDocumentClick = (documento: IDocumentoDocumento): void => {
    const existsDocumento = listaDocumentos.find(
      (item) => item.id_documento === documento.id_documento
    )
    if (!existsDocumento) setListaDocumentos([...listaDocumentos, documento])
    setValidationMessageDocuments(undefined)
  }

  /**
   * Remove um documento da lista de documentos da requisição
   * @param documento Documento a ser removido
   */
  const handleRemoveDocumentClick = (documento: IDocumentoDocumento): void => {
    const listaFiltrada = listaDocumentos.filter(
      (item) => item.id_documento !== documento.id_documento
    )

    setListaDocumentos(listaFiltrada)
  }

  const handleDigitalFile = async (
    id_contrato: string,
    filePath: string,
    id_documento: string
  ) => {
    try {
      const url = await ApiDocumento.digitalFile(
        id_contrato,
        filePath,
        id_documento
      )

      if (hasPermission(usuario?.permissoes, Permissions.VISUALIZAR_DOCUMENTO))
        window.open(url, '_blank', 'popup=yes')
    } catch (error) {
      console.error(error)
    }
  }

  /**
   * Enviar os dados do front para o back.
   *
   * OBSERVAÇÃO: os dados a serem enviados depende do tipo de conteúdo,
   * selecionado na primeira tela
   */
  const handleSubmit = async () => {
    let formData: any
    if (tipoConteudo === 1) {
      formData = {
        id_item_contrato: String(idItemContrato),
        observacoes: observacao ? String(observacao) : '',
        id_usuario: String(user.id_usuario),
        caixas: listaCaixas.map((box) => box.id_caixa ?? ''),
        urgente
      }
    } else {
      formData = {
        id_item_contrato: String(idItemContrato),
        observacoes: observacao ? String(observacao) : '',
        id_usuario: String(user.id_usuario),
        urgente,
        documentos: listaDocumentos.map((doc) => doc.id_documento ?? '')
      }
    }

    await Toast(createRequisicao(formData))

    navigate('/dashboard', { replace: true })
  }
  // Handlers (FIM) ------------------------------------------------------------

  // Efeitos (INÍCIO) ----------------------------------------------------------
  useEffect(() => {
    if (servico) {
      let atividade
      for (const tmpAtividade of servico?.atividades ?? []) {
        if (
          (!atividade || atividade.ordem > tmpAtividade.ordem) &&
          tmpAtividade.ordem >= 0
        ) {
          atividade = { ...tmpAtividade }
        }
      }
      setPrerequisitos(atividade?.tipo_atividade.prerequisito ?? '')
    }
  }, [servico])

  useEffect(() => {
    /*const newCaixasDisponiveis = caixas?.data.filter(
      (cx) =>
        !listaCaixasSelecionadas.includes(cx) &&
        (cx.estado !== 'CRIADO_CLIENTE' || !cx.id_caixa_container)
    )*/
    const newCaixasDisponiveis = caixas?.data.filter(
      (cx) => cx.estado !== 'CRIADO_CLIENTE' || !cx.id_caixa_container
    )
    setCaixasDisponiveis(newCaixasDisponiveis ?? [])
  }, [caixas, listaCaixas])
  // Efeitos (FIM) -------------------------------------------------------------

  if (
    isLoadingCaixas ||
    isLoadingDocumentos ||
    isLoadingTiposCaixas ||
    isLoadingTiposDocumentos
  )
    return <Loading />

  return (
    // Cabeçalho )))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Typography variant="h4" mb={2} color="#333">
            Requisição
          </Typography>
        </Box>

        <Box
          sx={{
            gap: 2,
            display: 'flex',
            flexDirection: 'row',
            height: '32px'
          }}
        >
          {step === 1 && tipoConteudo === 1 && (
            <>
              <VisaoCaixas
                isVisaoCaixas={isVisaoCaixas}
                setIsVisaoCaixas={setIsVisaoCaixas}
              />
            </>
          )}
          <Button
            variant="outlined"
            disabled={!(step > 0)}
            startIcon={<BackIcon />}
            sx={{ fontWeight: 600 }}
            onClick={() => setStep(step - 1)}
          >
            Voltar
          </Button>

          <Button
            variant={step === 2 ? 'contained' : 'outlined'}
            endIcon={
              step === 2 ? (
                <SaveIcon sx={{ width: 24, height: 24 }} />
              ) : (
                <ForwardIcon />
              )
            }
            sx={{ fontWeight: 600 }}
            onClick={() => (step < 2 ? nextStep() : handleSubmit())}
            disabled={isLoadingRequisicao}
          >
            {step === 2 ? 'Confirmar' : 'Avançar'}
          </Button>
          <Button
            sx={{
              marginLeft: '16px',
              fontWeight: 600
            }}
            color="error"
            variant="outlined"
            startIcon={<Cancel />}
            onClick={handleCancelButtonClick}
          >
            Cancelar
          </Button>
        </Box>
      </Box>
      <Divider />
      <Fluxo step={step} />

      {/* Tela 1 !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}
      {step === 0 && (
        <Stack gap={2}>
          <Typography variant="h5" sx={{ pt: 6, pb: 2 }}>
            Preencha o formulário
          </Typography>

          <Select
            label="Contrato*"
            placeholder="Selecione o Contrato"
            data={listAllContrato}
            fields={['id_contrato', 'descricao']}
            value={idContrato ?? ''}
            message={validationMessageContract}
            onChange={(e) => handleSelectContratoChange(String(e.target.value))}
          />

          <Select
            label="Serviço*"
            placeholder="Selecione o Serviço"
            data={listServico}
            fields={['id_item_contrato', 'nome']}
            value={idItemContrato}
            message={validationMessageService}
            onChange={(e) => handleSelectServicoChange(String(e.target.value))}
          />
          <Input
            label="Observação"
            multiline
            rows={3}
            value={observacao}
            onChange={(e) => setObservacao(e.target.value)}
          />
          <Stack
            sx={{
              flexDirection: {
                sm: 'column',
                md: 'row'
              }
            }}
            gap={2}
          >
            <RadioGroup
              title="Tipo de Conteúdo"
              aria-labelledby="label-tipo-conteudo"
              name="tipo-conteudo"
              value={tipoConteudo}
              message={validationMessageTipoConteudo}
              onChange={(event) => {
                handleTipoConteudoChange(Number(event.target.value))
              }}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
                justifyContent: 'center'
              }}
            >
              <FormControlLabel value="1" control={<Radio />} label="Caixa" />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label="Documento"
              />
            </RadioGroup>

            <ComponentGroup
              title="Classificação"
              aria-labelledby="label-classificacao"
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: 2
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={urgente}
                    onChange={() => setUrgente(!urgente)}
                  />
                }
                label="Urgente"
              />
            </ComponentGroup>
          </Stack>
        </Stack>
      )}

      {/* Tela 2 (Caixas) @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

      {step === 1 && tipoConteudo === 1 && (
        <>
          <SearchBox
            setAdvancedSearchParams={setSearchCriteriaCaixas}
            descricaoBusca={
              'Código/Identificação/Descrição de documento/Metadados de documentos/Conteúdo'
            }
            sequenciaExibir={true}
            sequencia={advancedSearchParamsCaixas.sequencia}
            dataExibir={true}
            data={advancedSearchParamsCaixas.data}
            tipoCaixaExibir={true}
            tiposCaixas={tiposCaixas}
            idTipoCaixa={advancedSearchParamsCaixas.id_tipo_caixa}
            tipoDocumentoExibir={true}
            tipoDocumentos={tiposDocumentos}
            idTipoDocumento={advancedSearchParamsCaixas.id_tipo_documento}
            busca={advancedSearchParamsCaixas.busca}
            dadosPessoaisExibir={true}
            dadosPessoais={advancedSearchParamsCaixas.dados_pessoais}
            dadosCriancasAdolescentesExibir={true}
            dadosCriancasAdolescentes={
              advancedSearchParamsCaixas.dados_criancas_adolescentes
            }
            dadosPessoaisSensiveisExibir={true}
            dadosPessoaisSensiveis={
              advancedSearchParamsCaixas.dados_pessoais_sensiveis
            }
            idContrato={idContrato}
            contratos={listAllContrato}
            idSetor={advancedSearchParamsCaixas.id_setor}
            setores={setoresPermitidos}
            dadosSetorExibir={true}
          />

          <ComponentGroup title="Caixas disponíveis para requisição" mt={3}>
            <Stack direction="row" flexWrap="wrap">
              {isVisaoCaixas &&
                caixasDisponiveis.map((cx) => (
                  <Box
                    key={cx.id_caixa}
                    m={1}
                    py={1}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    width={240}
                    borderRadius={1}
                    bgcolor={
                      listaCaixasSelecionadas.some(
                        (box) => box.id_caixa === cx.id_caixa
                      )
                        ? '#eef4f7'
                        : undefined
                    }
                    border={
                      listaCaixasSelecionadas.some(
                        (box) => box.id_caixa === cx.id_caixa
                      )
                        ? '1px solid #95b8c9'
                        : listaCaixas.some(
                            (box) => box.id_caixa === cx.id_caixa
                          )
                        ? '2px solid #047857'
                        : undefined
                    }
                  >
                    <Box
                      display="flex"
                      width="100%"
                      justifyContent="space-between"
                      px={1}
                    >
                      <span />

                      <img
                        src={boxImg}
                        alt="Caixas"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          handleBoxClick(cx)
                        }}
                      />

                      {caixasDisponiveis.some(
                        (box) => box.id_caixa === cx.id_caixa
                      ) ? (
                        seeMore === cx.id_caixa ? (
                          <ArrowUpIcon
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              setSeeMore((state) =>
                                state === undefined ? cx?.id_caixa : undefined
                              )
                            }
                          />
                        ) : (
                          <ArrowDownIcon
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              setSeeMore((state) =>
                                state === undefined ? cx?.id_caixa : undefined
                              )
                            }
                          />
                        )
                      ) : (
                        <ArrowUpIcon
                          style={{
                            color: listaCaixasSelecionadas.some(
                              (box) => box.id_caixa === cx.id_caixa
                            )
                              ? '#eef4f7'
                              : '#fff'
                          }}
                        />
                      )}
                    </Box>

                    <Typography fontWeight={700} px={2} textAlign="center">
                      {seeMore === cx.id_caixa
                        ? cx.identificacao + ' CONTEÚDO: ' + cx.conteudo
                        : `${cx.identificacao}`.length > 24
                        ? `${cx.identificacao}`.substring(0, 24) + '...'
                        : cx.identificacao}
                    </Typography>

                    <Typography variant="caption">
                      {cx.tipoCaixa?.descricao}
                    </Typography>

                    {caixasDisponiveis.some(
                      (box) => box.id_caixa === cx.id_caixa
                    ) ? (
                      <Box mt={1} px={2}>
                        {getBoxContent(cx)}
                      </Box>
                    ) : (
                      <Box pb={6} />
                    )}
                  </Box>
                ))}
              {!isVisaoCaixas && (
                <TableCaixas
                  caixaOrderField={caixaOrderField}
                  setCaixaOrderField={setCaixaOrderField}
                  caixaOrder={caixaOrderDirection}
                  setCaixaOrder={setCaixaOrderDirection}
                  listaCaixas={caixasDisponiveis}
                  listaCaixasAdicionadas={listaCaixas}
                  caixasSelecionadas={listaCaixasSelecionadas}
                  handleBoxClick={handleBoxClick}
                />
              )}
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <TextField
                type="number"
                name="page-limit"
                label="Itens por Página"
                variant="outlined"
                value={tempLimit}
                size="small"
                sx={{
                  width: '120px'
                }}
                onChange={(event) =>
                  handleLimitChange(Number(event.target.value))
                }
                inputProps={{
                  max: 100 // Substitua 100 pelo valor máximo que você deseja.
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'baseline',
                  gap: '8px'
                }}
              >
                {caixas?.pagination &&
                  caixas.pagination.totalItems >
                    caixas.pagination.itemsPerPage && (
                    <Pagination
                      count={caixas?.pagination.totalPages}
                      sx={{ marginTop: '12px' }}
                      onChange={(_, newPage) =>
                        setSearchParamsCaixas({ page: String(newPage) })
                      }
                      page={caixas.pagination.currentPage}
                    />
                  )}
              </Box>
              <Button
                variant="contained"
                color="success"
                sx={{ height: 36 }}
                onClick={() => handleAddClick()}
              >
                <Add />
                Adicionar
              </Button>
            </Stack>
          </ComponentGroup>
          <Box
            sx={{
              marginTop: '32px'
            }}
          >
            <ComponentGroup
              title="Itens da Requisição"
              message={validationMessageBoxes}
              sx={{
                marginTop: '320px'
              }}
            >
              <TabelaCaixas
                caixas={listaCaixas}
                handleRemoveClick={handleRemoveClick}
              />
            </ComponentGroup>
          </Box>
        </>
      )}

      {/* Tela 2 (Documentos) @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@*/}
      {step === 1 && tipoConteudo === 2 && (
        <>
          <Box>
            <SearchBox
              setAdvancedSearchParams={setSearchCriteriaDocumentos}
              descricaoBusca={'Descrição/Metadados de documentos'}
              tipoDocumentoExibir={true}
              tipoDocumentos={tiposDocumentos}
              idTipoDocumento={advancedSearchParamsDocumentos.id_tipo_documento}
              busca={advancedSearchParamsDocumentos.busca}
              dadosPessoaisExibir={true}
              dadosPessoais={advancedSearchParamsDocumentos.dados_pessoais}
              dadosCriancasAdolescentesExibir={true}
              dadosCriancasAdolescentes={
                advancedSearchParamsDocumentos.dados_criancas_adolescentes
              }
              dadosPessoaisSensiveisExibir={true}
              dadosPessoaisSensiveis={
                advancedSearchParamsDocumentos.dados_pessoais_sensiveis
              }
              idContrato={idContrato}
              contratos={listAllContrato}
              idSetor={advancedSearchParamsDocumentos.id_setor}
              setores={setoresPermitidos}
              dadosSetorExibir={true}
            />
            <Divider />
            <ComponentGroup title="Documentos disponíveis para requisição">
              <TableContainer
                component={Box}
                border={1}
                borderRadius={2}
                borderColor="#f2f2f2"
              >
                <Table>
                  <TableHead>
                    <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '14px',
                          width: '60%',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          selectedOrder === 'Descricao'
                            ? setSelectedOrder('DescricaoInv')
                            : setSelectedOrder('Descricao')
                        }}
                      >
                        Descrição
                        {selectedOrder === 'Descricao' ? (
                          <ArrowDownwardIcon
                            fontSize="small"
                            sx={{ marginLeft: '5px', marginTop: '5px' }}
                          />
                        ) : (
                          <ArrowUpwardIcon
                            fontSize="small"
                            sx={{ marginLeft: '5px', marginTop: '5px' }}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '14px',
                          width: '15%',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          selectedOrder === 'TipoDocumento'
                            ? setSelectedOrder('TipoDocumentoInv')
                            : setSelectedOrder('TipoDocumento')
                        }}
                      >
                        Tipo de Documento
                        {selectedOrder === 'TipoDocumento' ? (
                          <ArrowDownwardIcon
                            fontSize="small"
                            sx={{ marginLeft: '5px', marginTop: '5px' }}
                          />
                        ) : (
                          <ArrowUpwardIcon
                            fontSize="small"
                            sx={{ marginLeft: '5px', marginTop: '5px' }}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '14px',
                          width: '10%'
                        }}
                      >
                        Privacidade
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '14px',
                          width: '15%',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          selectedOrder === 'Estado'
                            ? setSelectedOrder('EstadoInv')
                            : setSelectedOrder('Estado')
                        }}
                      >
                        Estado
                        {selectedOrder === 'Estado' ? (
                          <ArrowDownwardIcon
                            fontSize="small"
                            sx={{ marginLeft: '5px', marginTop: '5px' }}
                          />
                        ) : (
                          <ArrowUpwardIcon
                            fontSize="small"
                            sx={{ marginLeft: '5px', marginTop: '5px' }}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '14px',
                          width: '5%'
                        }}
                      />
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '14px',
                          width: '5%'
                        }}
                      />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {documentos?.data?.map((item) => (
                      <TableRow key={item.id_documento} hover>
                        <TableCell>{item.descricao}</TableCell>
                        <TableCell>{item.tipo_documento?.descricao}</TableCell>
                        <TableCell>{privacidadeToText(item)}</TableCell>
                        <TableCell>{item.estado}</TableCell>
                        <TableCell>
                          <Tooltip title="Adicionar" arrow>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => handleAddDocumentClick(item)}
                              sx={{ minWidth: '24px', padding: '6px' }}
                            >
                              <Add />
                            </Button>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Visualizar" arrow>
                            <Button
                              variant="outlined"
                              sx={{ minWidth: '24px', padding: '6px' }}
                              disabled={
                                !item?.path ||
                                !usuario?.permissoes.includes(
                                  Permissions.VISUALIZAR_DOCUMENTO
                                )
                              }
                              onClick={() =>
                                handleDigitalFile(
                                  item.id_contrato,
                                  item.path!,
                                  item.id_documento!
                                )
                              }
                            >
                              <PreviewIcon />
                            </Button>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <TextField
                  type="number"
                  name="page-limit"
                  label="Itens por Página"
                  variant="outlined"
                  value={tempLimit}
                  size="small"
                  sx={{
                    width: '120px'
                  }}
                  onChange={(event) =>
                    handleLimitChange(Number(event.target.value))
                  }
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'baseline',
                    gap: '8px'
                  }}
                >
                  {documentos?.pagination &&
                    documentos.pagination.totalItems >
                      documentos.pagination.itemsPerPage && (
                      <Pagination
                        count={documentos?.pagination.totalPages}
                        sx={{ marginTop: '12px' }}
                        onChange={(_, newPage) =>
                          setSearchParamsDocumentos({ page: String(newPage) })
                        }
                        page={documentos.pagination.currentPage}
                      />
                    )}
                </Box>
                <Box></Box>
              </Stack>
            </ComponentGroup>
            <Box
              sx={{
                marginTop: '32px'
              }}
            >
              <ComponentGroup
                title={'Itens da Requisição'}
                message={validationMessageDocuments}
              >
                <TableContainer
                  component={Box}
                  border={1}
                  borderRadius={2}
                  borderColor="#f2f2f2"
                >
                  <Table>
                    <TableHead>
                      <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            fontSize: '14px',
                            width: '60%'
                          }}
                        >
                          Descrição
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            fontSize: '14px',
                            width: '15%'
                          }}
                        >
                          Tipo de Documento
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            fontSize: '14px',
                            width: '10%'
                          }}
                        >
                          Privacidade
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            fontSize: '14px',
                            width: '15%'
                          }}
                        >
                          Estado
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            fontSize: '14px',
                            width: '5%'
                          }}
                        />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listaDocumentos.map((item) => (
                        <TableRow key={item.id_documento} hover>
                          <TableCell>{item.descricao}</TableCell>
                          <TableCell>
                            {item.tipo_documento?.descricao}
                          </TableCell>
                          <TableCell>{privacidadeToText(item)}</TableCell>
                          <TableCell>{item.estado}</TableCell>
                          <TableCell>
                            <Tooltip title="Excluir" arrow>
                              <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => handleRemoveDocumentClick(item)}
                                sx={{ minWidth: '24px', padding: '6px' }}
                              >
                                <Cancel />
                              </Button>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </ComponentGroup>
            </Box>
          </Box>
        </>
      )}

      {/* Tela 3 ############################################################*/}
      {step === 2 && (
        <Box>
          <Typography variant="h5" sx={{ pt: 6 }}>
            Resumo
          </Typography>
          <Divider />

          <Table size="small" sx={{ marginY: 2 }}>
            <TableBody>
              {Row({
                text: 'Empresa',
                value: usuario?.pessoa.setor.empresa?.nome
              })}

              {Row({
                text: 'Setor',
                value: usuario?.pessoa.setor.nome
              })}

              {Row({
                text: 'Contrato',
                value: listAllContrato?.find(
                  (c) => c.id_contrato === idContrato
                )?.descricao
              })}

              {Row({
                text: 'Serviço',
                value: listServico?.find(
                  (s) => s.id_item_contrato === idItemContrato
                )?.nome
              })}

              {Row({
                text: 'Urgente',
                value: urgente ? 'Sim' : 'Não'
              })}

              {Row({
                text: 'Usuário',
                value: usuario?.pessoa.nome
              })}

              {Row({
                text: 'Data/hora',
                value: format(new Date(), "dd/MM/yyyy 'às' HH:mm")
              })}

              {Row({
                text: 'Observação',
                value: observacao
              })}

              {Row({
                text:
                  tipoConteudo === 1
                    ? 'Total de caixa(s)'
                    : 'Total de documento(s)',
                value:
                  tipoConteudo === 1
                    ? listaCaixas.length
                    : listaDocumentos.length
              })}
            </TableBody>
          </Table>

          <Box
            sx={{
              marginX: 2,
              visibility: tipoConteudo === 1 ? 'visible' : 'hidden'
            }}
          >
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow sx={{ background: '#eef4f7' }}>
                    <TableCell sx={{ fontWeight: '600' }}>
                      Tipo de caixa
                    </TableCell>
                    <TableCell sx={{ fontWeight: '600', minWidth: '240px' }}>
                      Identificação da caixa
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: '600' }}>
                      Quantidade de itens
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listaCaixas.map((cx) => (
                    <TableRow
                      key={`${cx.id_caixa}-itemAdicionado`}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>{cx?.tipoCaixa?.descricao}</TableCell>
                      <TableCell component="th" scope="row">
                        {cx?.identificacao}
                      </TableCell>
                      <TableCell align="right">
                        {(cx?.documentos?.length ?? 0) +
                          (cx?.caixaFilha?.length ?? 0)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box
            sx={{
              marginX: 2,
              visibility: tipoConteudo === 2 ? 'visible' : 'hidden'
            }}
          >
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow sx={{ background: '#eef4f7' }}>
                    <TableCell sx={{ fontWeight: '600' }}>
                      Descrição do Documento
                    </TableCell>
                    <TableCell sx={{ fontWeight: '600', minWidth: '240px' }}>
                      Tipo de Documento
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: '600' }}>
                      Privacidade
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listaDocumentos.map((doc) => (
                    <TableRow
                      key={`${doc.id_documento}-itemAdicionado`}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>{doc.descricao}</TableCell>
                      <TableCell component="th" scope="row">
                        {doc.tipo_documento?.descricao}
                      </TableCell>
                      <TableCell align="right">
                        {privacidadeToText(doc)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      )}
    </Box>
  )
}
