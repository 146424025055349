import { DefaultApi } from '../../utils/defaultApi'
import api from '../api'
import { annonymousData } from '../documento'
import { IDiretorio, IDiretorioSetorArvore } from './types'

const endpoint = '/diretorio'
const resourceId = 'id_diretorio'

class ServiceDiretorio extends DefaultApi<IDiretorio> {
  listAll = async () => {
    const { data } = await api.get<IDiretorio[]>(`${endpoint}/lista/`)
    return data
  }

  findBySetor = async (id_setor: string) => {
    if (id_setor === '' || id_setor === '0') {
      return []
    }
    const { data } = await api.get<IDiretorio[]>(
      `${endpoint}/setor/${id_setor}`
    )
    return data
  }

  delete = async (id: string): Promise<IDiretorio> => {
    const { data } = await api.delete<IDiretorio>(`${this.endpoint}/${id}`)
    return data
  }

  findById = async (id: string): Promise<IDiretorio> => {
    if (id === '' || id === '0') {
      return {} as IDiretorio
    }
    const { data } = await api.get<IDiretorio>(`${this.endpoint}/${id}`)
    return {
      ...data,
      documentos: data.documentos?.map((doc) => annonymousData(doc))
    }
  }

  directoryTreeBySetor = async (id_setor: string) => {
    if (id_setor === '' || id_setor === '0') {
      return []
    }
    const { data } = await api.get<IDiretorioSetorArvore>(
      `${endpoint}/setor/${id_setor}/arvore`
    )
    return data
  }
}

const apiDiretorio = new ServiceDiretorio(endpoint, resourceId)

export const ApiDiretorio = {
  create: apiDiretorio.create,
  update: apiDiretorio.update,
  delete: apiDiretorio.delete,
  findOne: apiDiretorio.findById,
  findBySetor: apiDiretorio.findBySetor,
  directoryTreeBySetor: apiDiretorio.directoryTreeBySetor
}
