import { Button } from '@mui/material'
import { useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import CertificateIcon from '@mui/icons-material/VpnKeySharp'
import { useAuth } from '../../../hooks/auth'
import useToast from '../../../hooks/toast/useToast'

const ICPSignInButton = () => {
  const navigate = useNavigate()
  const { signInWithCertificate } = useAuth()
  const { ToastError } = useToast()

  const loginWithDigitalCertificate = () => {
    window.location.replace(`${process.env.REACT_APP_BASE_URL}/icp-brazil`)
  }

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const token = queryParams.get('token')
  const error = queryParams.get('error')

  useEffect(() => {
    if (token) {
      signInWithCertificate(token)
    }
  }, [token])

  const previousErrorRef = useRef<string | null>()

  useEffect(() => {
    if (error && previousErrorRef.current !== error) {
      ToastError(`Erro ao logar com Certificado Digital: ${error}`)
      navigate('/login')
    }

    previousErrorRef.current = error
  }, [error])

  return (
    <Button
      fullWidth
      variant="outlined"
      onClick={loginWithDigitalCertificate}
      sx={{ mb: 2, fontSize: '14px' }}
      startIcon={<CertificateIcon sx={{ width: 24, height: 24 }} />}
    >
      Entrar com Certificado Digital
    </Button>
  )
}

export { ICPSignInButton }
