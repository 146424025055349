import { useQuery } from 'react-query'
import { ApiLog } from '../../services/log'

const QUERY_KEY = 'qkLog'

const FindAll = (
  page: number,
  selectedOrder: string,
  q = '',
  contrato: string
) => {
  return useQuery([QUERY_KEY, q, page, contrato, selectedOrder], () =>
    ApiLog.findAllLogs(page, selectedOrder, q, contrato)
  )
}

const FindAllByData = (
  page: number,
  date: string,
  selectedOrder: string,
  q = '',
  contrato: string
) => {
  return useQuery([QUERY_KEY, q, page, date, contrato, selectedOrder], () =>
    ApiLog.findAllDate(page, date, selectedOrder, q, contrato)
  )
}

/*const FindOne = (id: string) => {
  return useQuery([QUERY_KEY, id], () => ApiLog.findOne(id))
}*/

export const useLog = {
  FindAll,
  FindAllByData
  //FindOne
}
