import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Button, Container, TextField, Typography } from '@mui/material'
import logo from '../../assets/images/logo_easydoc.png'
import { Loading } from '../../components/Loading'
import { useDocumento } from '../../hooks/documento/useDocumento'
import { ApiDocumento } from '../../services/documento'
import { ShowMessage } from '../../components/ShowMessage'
import api from '../../services/api'

export function DocumentosComaprtilhados() {
  const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false)
  const [isDisable, setIsDisable] = useState<boolean>(false)
  const [wasOpened, setWasOpened] = useState<boolean>(false)
  const [code, setCode] = useState<string>('')
  const [message, setMessage] = useState<string>()
  const { id_compartilhamento } = useParams()
  const { id_documento } = useParams()
  const id_comp = id_compartilhamento || ''
  const id_doc = id_documento || ''

  const { data: compartilhamentoRecord } =
    useDocumento.FindCompartilhamentoById(id_comp)
  const { data: record, isLoading } = useDocumento.FindSharedById(id_doc)

  const handleDigitalFile = async (
    id_contrato: string,
    filePath: string,
    action: 'download' | 'preview',
    id_documento: string
  ) => {
    try {
      setIsLoadingFile(true)
      const url = await ApiDocumento.digitalSharedFile(
        id_contrato,
        filePath,
        id_documento
      )

      if (action === 'preview') {
        window.open(url)
      } else {
        const fileName = url.split('/').pop()
        const anchor = document.createElement('a')
        anchor.href = url
        anchor.setAttribute('download', fileName!)
        document.body.appendChild(anchor)
        anchor.click()
        document.body.removeChild(anchor)
        anchor.remove()
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoadingFile(false)
    }
  }

  async function submitToken(event: React.FormEvent<HTMLInputElement>) {
    event.preventDefault()
    setIsDisable(true)
    setWasOpened(false)
    try {
      const response = await api.post('/documento/verifyCode', {
        id_compartilhamento,
        token: code
      })
      if (response.data && record && !wasOpened) {
        handleDigitalFile(
          record.id_contrato,
          record.path!,
          'preview',
          record.id_documento ? record.id_documento : ''
        )
        setWasOpened(true)
      }
      setIsDisable(false)
    } catch (err: any) {
      setMessage(err?.message)
      setIsDisable(false)
    }
  }

  async function verifyDate() {
    try {
      const response = await api.post('/documento/verifyDate', {
        id_compartilhamento
      })
      if (response.data && record) {
        handleDigitalFile(
          record.id_contrato,
          record.path!,
          'preview',
          record.id_documento ? record.id_documento : ''
        )
      }
    } catch (err: any) {
      setMessage(err?.message)
    }
  }

  if (isLoading) return <Loading />

  return (
    <Box>
      {isLoadingFile && (
        <Loading message="Aguarde... arquivo sendo processado..." />
      )}

      {compartilhamentoRecord?.token ? (
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 16,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <img src={logo} width="60%" alt="logo X-Solution" />

            <Typography variant="h4" mt={4}>
              Insira o token de acesso
            </Typography>
            <Box component="form" onSubmit={submitToken} sx={{ mt: 1 }}>
              <TextField
                label="Código"
                required
                fullWidth
                margin="normal"
                autoFocus
                onChange={(e) => setCode(e.target.value)}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={isDisable || isLoadingFile}
                sx={{ mt: 3, mb: 2, fontSize: '14px' }}
              >
                Continuar
              </Button>
            </Box>
          </Box>
        </Container>
      ) : (
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              mt: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Button
              fullWidth
              variant="contained"
              disabled={isDisable || isLoadingFile}
              sx={{ mt: 3, mb: 2, fontSize: '14px' }}
              onClick={() => verifyDate()}
            >
              Continuar para documento
            </Button>
          </Box>
        </Container>
      )}
      <ShowMessage
        hasError={!!message}
        message={message}
        onClose={() => setMessage(undefined)}
      />
    </Box>
  )
}
