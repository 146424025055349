import { Box } from '@mui/material'
import { ItemRequisicaoTimeLine } from '../../../../components/Requisicao/ItemRequisicao/TimeLine'
import { IItemRequisicao } from '../../../../services/requisicao/types'

export interface IDetalhesItemRequisicaoProps {
  itemRequisicao: IItemRequisicao
}
export function DetalhesItemRequisicao({
  itemRequisicao
}: IDetalhesItemRequisicaoProps) {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <ItemRequisicaoTimeLine itemRequisicao={itemRequisicao} />
    </Box>
  )
}
