const REQUIRED = 'Preenchimento obrigatório'
const EMAIL = 'E-mail inválido'
const MIN = (num: number) => `Informe no Mínimo ${num} caracteres`
const MAX = (num: number) => `Informe no Máximo ${num} caracteres`
const PHONE = 'Telefone inválido'
const CNPJ = 'CNPJ inválido'
const CPF = 'CPF inválido'
const DATEINTERVAL = 'A data final não pode ser menor que a data inicial!'

interface NumberMessageProps {
  min?: number
  max?: number
}
const REAL = ({ min, max }: NumberMessageProps) => {
  if (!(min || max)) return 'O valor deve ser um número real.'
  if (min && !max) return `Informe um número real e de valor mínimo de ${min}`

  if (!min && max) return `Informe um número real e de valor máximo de ${max}`
  return `Informe um número real e de valor entre ${min} e ${max}`
}

const NUMBER = ({ min, max }: NumberMessageProps) => {
  if (!(min || max)) return 'O valor deve ser um número inteiro.'
  if (min && !max)
    return `Informe um número inteiro e de valor mínimo de ${min}`

  if (!min && max)
    return `Informe um número inteiro e de valor máximo de ${max}`
  return `Informe um número inteiro e de valor entre ${min} e ${max}`
}

const validationMessages = {
  REQUIRED,
  EMAIL,
  MIN,
  MAX,
  PHONE,
  CNPJ,
  CPF,
  REAL,
  NUMBER,
  DATEINTERVAL
}

export default validationMessages
