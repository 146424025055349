import { SxProps, TableCell, TableRow, TableRowProps } from '@mui/material'
import { ReactNode } from 'react'
import { MD5 } from 'crypto-js'
// import { corTextoLinha } from '../../cores'

interface DashboardWidgetListBodyRowProps extends TableRowProps {
  values: Array<string | ReactNode>
  cellStyle?: SxProps
  children: ReactNode
}

export function DashboardWidgetListBodyRow({
  values,
  children,
  cellStyle,
  ...refs
}: DashboardWidgetListBodyRowProps) {
  return (
    <TableRow {...refs}>
      {values.map((value, index) => {
        const key = MD5((index + Math.random()).toString())
        return (
          <TableCell
            key={`${key}`}
            sx={{
              ...cellStyle
            }}
          >
            {value}
          </TableCell>
        )
      })}
      <TableCell
        sx={{
          ...cellStyle
        }}
      >
        {children || ''}
      </TableCell>
    </TableRow>
  )
}
