import { useMemo, useState } from 'react'
import {
  Box,
  Button,
  Chip,
  Divider,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/AddCircle'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import useToast from '../../../hooks/toast/useToast'
import { FormMode } from '../../../types/formMode'
import { Loading } from '../../../components/Loading'
import React from 'react'
import { useMovimentoContaCorrente } from '../../../hooks/conta-corrente/movimento/useMovimentoContaCorrente'
import { formataSomenteData } from '../../../utils/formataData'
import { formatarComoMoeda } from '../../../utils/formataStrings'
import { Dialog } from '../../../components/Dialog'
import { IMovimentoContaCorrente } from '../../../services/conta-corrente/movimento/types'

interface IForm {
  setData: (value: React.SetStateAction<IMovimentoContaCorrente[]>) => void
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

export function List({ setData, setFormMode }: IForm) {
  const [showDismiss, setShowDismiss] = useState(false)
  const [currentRecord, setCurrentRecord] = useState<any>({})
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [q, setQ] = useState<string>('')
  const { Toast } = useToast()
  const searchInput = React.useRef<HTMLInputElement>(null)

  const handleSearchClick = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setSearchParams({ ...searchParams, q: searchTerm })
    setQ(searchTerm)
    searchInput.current?.focus()
  }

  const page = useMemo(() => {
    return Number(searchParams.get('page') ?? 1)
  }, [searchParams])

  const { mutateAsync: remove } = useMovimentoContaCorrente.Remove()
  const { data: records, isLoading } = useMovimentoContaCorrente.FindAll(
    page,
    q
  )

  const handleNewForm = () => {
    setFormMode('CREATE')
  }

  const handleRemove = async (id: string) => await Toast(remove(id), 'REMOVED')

  const handleEditForm = (selectedData: any) => {
    setData(selectedData)
    setFormMode('EDIT')
  }

  const handleCancel = () => {
    handleRemove(currentRecord)
    setShowDismiss(false)
  }

  const canChange = (movimentoCC: IMovimentoContaCorrente) => {
    return (
      movimentoCC.baixaLancamento?.length === 0 &&
      movimentoCC.baixaLancamentoEstorno?.length === 0
    )
  }

  if (isLoading) return <Loading />

  return (
    <Box>
      <Stack
        component="form"
        onSubmit={handleSearchClick}
        direction="row"
        py={3}
        gap={3}
        mb={2}
      >
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          placeholder="Descrição/Código/Evento/Conta"
          inputRef={searchInput}
          autoFocus
          value={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value)
          }}
        />
        <Button variant="outlined" startIcon={<SearchIcon />} type="submit">
          Pesquisar
        </Button>
      </Stack>

      <Divider />

      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleNewForm}
        sx={{ float: 'right', my: '12px' }}
      >
        Novo registro
      </Button>

      <TableContainer
        component={Box}
        border={1}
        borderRadius={2}
        borderColor="#f2f2f2"
      >
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Histórico
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Conta
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Evento
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Data
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Valor
              </TableCell>
              <TableCell width="12px" />
              <TableCell width="12px" />
            </TableRow>
          </TableHead>
          <TableBody>
            {records?.data?.map((record) => (
              <TableRow key={record.id_movimento_conta_corrente} hover>
                <TableCell>
                  {record.historico}
                  <Typography
                    sx={{
                      fontSize: '10px',
                      fontWeight: 'bold',
                      color: 'GrayText'
                    }}
                  >
                    ({record.id_movimento_conta_corrente})
                  </Typography>
                </TableCell>
                <TableCell>{record.conta?.descricao}</TableCell>
                <TableCell>{record.evento?.descricao}</TableCell>
                <TableCell>
                  {formataSomenteData(record.dt_movimento?.toString() ?? '')}
                </TableCell>
                <TableCell>
                  <Chip
                    label={formatarComoMoeda(record.valor)}
                    color={record.acao === -1 ? 'error' : 'success'}
                    size="small"
                    variant="filled"
                  />
                </TableCell>
                <TableCell>
                  {canChange(record) && (
                    <Tooltip title="Editar" arrow>
                      <Button
                        variant="outlined"
                        sx={{ minWidth: '24px', padding: '6px' }}
                        onClick={() => handleEditForm(record)}
                      >
                        <EditIcon />
                      </Button>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell>
                  {canChange(record) && (
                    <Tooltip title="Excluir" arrow>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                          setCurrentRecord(record.id_movimento_conta_corrente)
                          setShowDismiss(true)
                        }}
                        sx={{ minWidth: '24px', padding: '6px' }}
                      >
                        <DeleteIcon />
                      </Button>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {records?.pagination &&
        records.pagination.totalItems > records.pagination.itemsPerPage && (
          <Pagination
            count={records?.pagination.totalPages}
            sx={{ marginTop: '12px' }}
            onChange={(_, newPage) =>
              setSearchParams({ page: String(newPage) })
            }
            page={records.pagination.currentPage}
          />
        )}

      <Dialog
        handleAnswerNo={() => {
          setShowDismiss(false)
        }}
        handleAnswerYes={() => {
          handleCancel()
        }}
        messageText={
          <>
            <p>
              Tem certeza que deseja <strong>EXCLUIR</strong> este Movimento?
            </p>
            <p>
              Em caso afirmativo, o mesmo ficará cancelado e indisponível para
              movimentação
            </p>
            <p>
              <strong>IMPORTANTE: </strong>Esta ação não poderá ser desfeita.
            </p>
          </>
        }
        title="Atenção"
        openDialog={showDismiss}
      />
    </Box>
  )
}
