import { DefaultApi } from '../../utils/defaultApi'
import api from '../api'
import { IPrateleira } from './types'

const endpoint = '/prateleira'
const resourceId = 'id_prateleira'

class ServiceEstante extends DefaultApi<IPrateleira> {
  findByEstante = async (id_estante: string) => {
    if (id_estante === '' || id_estante === '0') {
      return []
    }
    const { data } = await api.get<IPrateleira[]>(
      `${this.endpoint}/estante/${id_estante}`
    )
    return data
  }
}

const apiPrateleira = new ServiceEstante(endpoint, resourceId)

export const ApiPrateleira = {
  listAll: apiPrateleira.listAll,
  findByEstante: apiPrateleira.findByEstante
}
