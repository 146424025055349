import { v4 as uuid } from 'uuid'
import { anonimizacao } from '../../utils/anonimizacao'
import { DefaultApi } from '../../utils/defaultApi'
import { IResult, TAtivo } from '../../utils/defaultApi/types'
import api from '../api'
import { IDadosImportacao } from '../dados-importacao/types'
import {
  AdvancedFindAllDocumentosProps,
  ICompartilhamentos,
  IAddDocumentoDiretorioRequest,
  IDocumento,
  IDocumentoDigital,
  IImportacaoDocumentos
} from './types'
const endpoint = '/documento'
const resourceId = 'id_documento'

export const annonymousData = (doc: IDocumento): IDocumento => {
  const camposAnonimos = ['CPF', 'CNPJ']
  let returnValue = {
    ...doc
  }
  if (returnValue.metadados && returnValue.metadados.length > 0) {
    returnValue = {
      ...returnValue,
      metadados: [
        ...returnValue.metadados.map((md) => {
          if (
            camposAnonimos.some((field) =>
              md.chave.toUpperCase().includes(field)
            )
          ) {
            const returnDt = {
              ...md,
              chave: md.chave,
              valor: anonimizacao(md.valor, 3)
            }
            return returnDt
          }
          return md
        })
      ]
    }
  }
  if (
    camposAnonimos.some((field) =>
      returnValue.descricao.toUpperCase().includes(field)
    )
  ) {
    const descricao = returnValue.descricao.toUpperCase().split(' ')
    for (let i = 0; i <= descricao.length; i++) {
      if (camposAnonimos.some((field) => descricao.at(i)?.includes(field))) {
        const dt = anonimizacao(descricao?.at(i + 1) ?? '', 3)
        descricao[i + 1] = dt
      }
    }
    const newDescricao = descricao.join(' ')
    returnValue.descricao = newDescricao
  }
  return returnValue
}

class ServiceDocumento extends DefaultApi<IDocumento> {
  /**
   * Define o conjunto de dados que devem ser anonimizados.
   *
   * Esses dados são usados pela função this.annonymousData.
   */

  /**
   * Anonimizar os dados de um IDocumento.
   *
   * **OBSERVAÇÃO**: a anonimização ocorre sobre os dados contidos na descrição ou
   * nos metadados do documento.
   * @param doc Documento que terá os dados anonimizados
   * @returns Documento com dados anomizados
   */

  /**
   * Busca um documento pelo ID.
   *
   * **OBSERVAÇÃO**: esta função teve que ser criada para adicionar anonimização
   * dos dados do documento.
   * @param id ID do documento a ser buscado.
   * @returns Dados do documento.
   */
  findById = async (id: string): Promise<IDocumento> => {
    const { data } = await api.get<IDocumento>(`${this.endpoint}/${id}`)
    return annonymousData(data)
  }

  findSharedById = async (id: string): Promise<IDocumento> => {
    const { data } = await api.get<IDocumento>(`${this.endpoint}/shared/${id}`)
    return annonymousData(data)
  }

  findCompartilhamentoById = async (
    id_compartilhamento: string
  ): Promise<ICompartilhamentos> => {
    const { data } = await api.get<ICompartilhamentos>(
      `${this.endpoint}/compartilhamento/${id_compartilhamento}`
    )
    return data
  }

  findAllCompartilhamentos = async (
    page: number,
    q?: string,
    ativo: TAtivo = true
  ): Promise<IResult<ICompartilhamentos>> => {
    const pageString = page ? `page=${page}` : ''
    const qString = q ? `q=${q}` : ''
    const ativoString = ativo !== undefined ? `ativo=${ativo}` : ''
    const params = [pageString, qString, ativoString]
      .filter((value) => value !== '')
      .join('&')
    const { data } = await api.get<IResult<ICompartilhamentos>>(
      `${this.endpoint}/compartilhamentos/all-compartilhamentos?${params}`
    )
    return data
  }

  createMany = async (formData: IDocumento[]) => {
    const { data } = await api.post<IDocumento[]>(endpoint, formData)
    return data
  }

  /**
   * Busca documentos pelo ID da caixa que os contém.
   *
   * **OBSERVAÇÃO**: os documentos têm seus dados anonimizados
   * @param id ID da caixa, cujos documentos serão buscados.
   * @returns Lista de documentos anonimizados.
   */
  findByIdCaixa = async (id: string) => {
    if (id === '' || id === '0') {
      return []
    }
    const { data } = await api.get<IDocumento[]>(
      `${this.endpoint}/lista-caixa/${id}`
    )
    return data.map((adoc) => annonymousData(adoc))
  }

  /**
   * Busca documentos pelo ID do contrato a que estão vinculados.
   *
   * **OBSERVAÇÃO**: os documentos têm seus dados anonimizados
   * @param id ID do contrato, cujos documentos serão buscados.
   * @returns Lista de documentos anonimizados com paginação.
   */
  findByIdContrato = async (
    id: string,
    page: number,
    q?: string,
    r?: string,
    limit?: number
  ): Promise<IResult<IDocumento>> => {
    if (['', '0'].includes(id)) {
      return {
        data: [],
        pagination: {
          currentPage: 0,
          itemsPerPage: 0,
          totalItems: 0,
          totalPages: 0
        }
      }
    }
    const pageString = page ? `page=${page}` : ''
    const qString = q ? `q=${q}` : ''
    const rString = r ? `r=${r}` : ''
    const limitString = limit ? `limit=${limit}` : ''
    const params = [pageString, qString, rString, limitString]
      .filter((value) => value !== '')
      .join('&')
    const { data } = await api.get<IResult<IDocumento>>(
      `${this.endpoint}/contrato/${id}/?${params}`
    )
    const returnValue = {
      ...data,
      data: data.data.map((doc) => annonymousData(doc))
    }
    return returnValue
  }

  findAllWithoutDriveByIdSetor = async (
    id: string,
    page: number,
    q?: string
  ): Promise<IResult<IDocumento>> => {
    if (['', '0'].includes(id)) {
      return {
        data: [],
        pagination: {
          currentPage: 0,
          itemsPerPage: 0,
          totalItems: 0,
          totalPages: 0
        }
      }
    }
    const pageString = page ? `page=${page}` : ''
    const qString = q ? `q=${q}` : ''
    const params = [pageString, qString]
      .filter((value) => value !== '')
      .join('&')
    const { data } = await api.get<IResult<IDocumento>>(
      `${this.endpoint}/setor/${id}/?${params}`
    )
    const returnValue = {
      ...data,
      data: data.data.map((doc) => annonymousData(doc))
    }
    return returnValue
  }

  createDocumentoDigital = async (formData: FormData) => {
    const { data } = await api.post<IDocumentoDigital>(
      `${this.endpoint}/digital-file`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
    return data
  }

  updateDocumentoDigital = async (formData: IDocumentoDigital) => {
    const id = formData[resourceId]
    delete formData[resourceId]

    const { data } = await api.put<IDocumentoDigital>(
      `${this.endpoint}/${id}`,
      formData
    )
    return data
  }

  uploadDigitalFile = async (formData: FormData) => {
    const id_documento = formData.get(resourceId)

    const { data } = await api.put<IDocumentoDigital>(
      `${this.endpoint}/digital-file/${id_documento}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
    return data
  }

  digitalFile = async (
    id_contrato: string,
    filePath: string,
    id_documento: string
  ) => {
    const filename = filePath.split('/').reverse()[0]
    const { data } = await api.get(
      `${this.endpoint}/digital-file/${id_contrato}/${filename}`,
      {
        responseType: 'blob',
        params: {
          id_documento
        }
      }
    )
    const blob = new Blob([data], { type: data.type })

    return window.URL.createObjectURL(blob)
  }

  digitalSharedFile = async (
    id_contrato: string,
    filePath: string,
    id_documento: string
  ) => {
    const filename = filePath.split('/').reverse()[0]
    const { data } = await api.get(
      `${this.endpoint}/digital-shared-file/${id_contrato}/${filename}`,
      {
        responseType: 'blob',
        params: {
          id_documento
        }
      }
    )
    const blob = new Blob([data], { type: data.type })
    return window.URL.createObjectURL(blob)
  }

  QRCodeDigitalFile = async (id_documento: string, token: string) => {
    const { data } = await api.get(
      `${this.endpoint}/download/${token}/${id_documento}`,
      {
        responseType: 'blob'
      }
    )
    const blob = new Blob([data], { type: data.type })

    return window.URL.createObjectURL(blob)
  }

  shareDocuments = async (info: object, url: string) => {
    const { data } = await api.post(
      `${this.endpoint}/compartilhar-documento/`,
      {
        info,
        url
      }
    )
    return data
  }

  mergeDocuments = async (documentsIds: string[]) => {
    const { data } = await api.post(`${this.endpoint}/combinar-documentos/`, {
      documentsIds
    })
    return data
  }

  downloadMergedDocument = async (file: string) => {
    const { data } = await api.get(`${this.endpoint}/download/combinado/`, {
      responseType: 'blob',
      params: {
        file
      }
    })
    const blob = new Blob([data], { type: data.type })

    return window.URL.createObjectURL(blob)
  }

  deleteMergedDocument = async (file: string) => {
    const { data } = await api.delete(`${this.endpoint}/combinado/`, {
      params: {
        file
      }
    })
    return data
  }

  importData = async ({
    formData,
    progressCallback
  }: IImportacaoDocumentos) => {
    const frmData = formData.get('formData')
    formData.delete('formData')
    const newJSON = new Blob([JSON.stringify(frmData)])
    const jsonFilename = uuid() + '.dat'
    const jsonFile = new File([newJSON], jsonFilename)
    formData.append('files', jsonFile)
    const mockData = jsonFilename
    formData.append('formData', mockData)

    const { data } = await api.post<IDadosImportacao>(
      `${this.endpoint}/importar-dados`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: ({ loaded, total }) => {
          if (progressCallback) {
            progressCallback(Math.floor((loaded / total) * 100))
          }
        }
      }
    )
    return data
  }

  updateTag = async (formData: { id_documento: string; tag: string }) => {
    const { data } = await api.put<IDocumento>(
      `${this.endpoint}/update-tag/${formData.id_documento}`,
      { tag: formData.tag }
    )
    return data
  }

  /**
   * Realiza busca de documento usando conjunto de parâmetros de busca.
   * @param advancedFindAllProps Conjunto de parâmetro de busca.
   * @returns EStrutura paginada contendo as informações da página de dados e
   * os documentos filtrados pelos parâmetros de busca.
   */
  public advancedFindAll = async (
    advancedFindAllProps: AdvancedFindAllDocumentosProps,
    filter: string | undefined
  ): Promise<IResult<IDocumento>> => {
    const params = new URLSearchParams()
    for (const [key, value] of Object.entries(advancedFindAllProps)) {
      if (typeof value !== 'undefined') {
        params.append(key, value)
      }
    }

    const { data } = await api.get<IResult<IDocumento>>(
      `${this.endpoint}/avancado/${filter}`,
      {
        params
      }
    )
    const returnData = {
      ...data,
      data: data.data.map((doc) => annonymousData(doc))
    }
    return returnData
  }

  updateStatistics = async () => {
    await api.patch(`${this.endpoint}/atualizar-estatisticas`)
  }

  sign = async (id_documento: string) => {
    const { data } = await api.put<IDocumento>(
      `${this.endpoint}/sign/${id_documento}`
    )
    return data
  }

  getSignatureStatus = async (id_documento: string) => {
    const { data } = await api.get<string>(
      `${this.endpoint}/sign/${id_documento}`
    )
    return data
  }

  addDocumentoDiretorio = async ({
    id_diretorio,
    id_documento
  }: IAddDocumentoDiretorioRequest) => {
    const { data } = await api.post<IDocumento>(`${this.endpoint}/diretorio/`, {
      id_diretorio,
      id_documento
    })
    return data
  }

  removeDocumentoDiretorio = async (id_documento: string) => {
    const { data } = await api.delete<IDocumento>(
      `${this.endpoint}/diretorio/${id_documento}`
    )
    return data
  }
}

const apiDocumento = new ServiceDocumento(endpoint, resourceId)

export const ApiDocumento = {
  ...apiDocumento,
  advancedFindAll: apiDocumento.advancedFindAll,
  create: apiDocumento.createMany,
  deleteMergedDocument: apiDocumento.deleteMergedDocument,
  downloadMergedDocument: apiDocumento.downloadMergedDocument,
  findByIdContrato: apiDocumento.findByIdContrato,
  findAllWithoutDriveByIdSetor: apiDocumento.findAllWithoutDriveByIdSetor,
  findOne: apiDocumento.findById,
  findSharedOne: apiDocumento.findSharedById,
  findOneCompartilhamento: apiDocumento.findCompartilhamentoById,
  findAllCompartilhamentos: apiDocumento.findAllCompartilhamentos,
  getSignatureStatus: apiDocumento.getSignatureStatus,
  shareDocuments: apiDocumento.shareDocuments,
  mergeDocuments: apiDocumento.mergeDocuments,
  sign: apiDocumento.sign,
  uploadDigitalFile: apiDocumento.uploadDigitalFile,
  updateStatistics: apiDocumento.updateStatistics,
  addDocumentoDiretorio: apiDocumento.addDocumentoDiretorio,
  removeDocumentoDiretorio: apiDocumento.removeDocumentoDiretorio,
  updateTag: apiDocumento.updateTag
}
