import { createTheme } from '@mui/material'

const theme = createTheme({
  palette: {
    //   primary: {
    //     main: '#0076ff',
    //     '100': '#194988',
    //     '200': '#fefe',
    //     '300': '#cacaba'
    //   },
    secondary: {
      main: '#EE065A'
    }
  },
  typography: {
    fontFamily: 'Roboto, Inter',
    fontSize: 12
  },
  components: {
    MuiButton: {
      styleOverrides: {
        outlinedPrimary: {
          textTransform: 'none'
        },
        outlined: {
          textTransform: 'none'
          // fontSize: '14px'
          // minWidth: '120px'
        },
        contained: {
          textTransform: 'none'
          // fontSize: '14px'
          // minWidth: '120px'
        }
      }
    }
  }
})

export default theme
