import { useState } from 'react'
import { Box } from '@mui/material'
import { Form } from './Form'
import { FormMode } from '../../../types/formMode'
import { FormTitle } from '../../../components/Form/FormTitle'

export function ConfiguracaoArmazenamento() {
  const [formMode, setFormMode] = useState<FormMode>('EDIT')
  const [data] = useState<any>({})

  return (
    <Box bgcolor="#fff" p={4} borderRadius={2}>
      <FormTitle title="Configuração de Armazenamento" subtitle={formMode} />
      {formMode === 'EDIT' ? (
        <Form
          data={formMode === 'EDIT' ? data : {}}
          formMode={formMode}
          setFormMode={setFormMode}
        />
      ) : (
        ''
      )}
    </Box>
  )
}
