import { Box, Button, Stack, Tooltip } from '@mui/material'

import LibraryAddIcon from '@mui/icons-material/LibraryAdd'

import { ICorredor } from '../../../../services/corredor/types'
import { Descriptor } from './Descriptor'
import { getCorredorSpace } from '../lib/func'
import { EstanteDraw } from './EstanteDraw'
import { IPosicaoPrateleira } from '../../../../services/posicaoPrateleira/types'
import { IPrateleira } from '../../../../services/prateleira/types'
import { IEstante } from '../../../../services/estante/types'

interface CorredorDrawProps {
  corredor: ICorredor
  prefixoCD: string
  indiceEstante: string
  handleExcluirPosicaoClick: (
    posicao: IPosicaoPrateleira,
    enderecoPrateleira: string
  ) => void
  handleAdicionarPosicaoClick: (
    prateleira: IPrateleira,
    enderecoEstante: string
  ) => void
  handleMudarPrateleiraTipoCaixaClick: (
    prateleira: IPrateleira,
    enderecoEstante: string
  ) => void
  handleExcluirPrateleiraClick: (
    prateleira: IPrateleira,
    enderecoEstante: string
  ) => void
  handleAdicionarPrateleiraClick: (
    estante: IEstante,
    enderecoCorredor: string
  ) => void
  handleExcluirEstanteClick: (
    estante: IEstante,
    enderecoCorredor: string
  ) => void
  handleAdicionarEstanteClick: (
    id_corredor: string,
    enderecoCentro: string
  ) => void
  handleExcluirCorredorClick: (
    corredor: ICorredor,
    enderecoCorredor: string
  ) => void
  handleIncluirCorredorClick: () => void
  handlePrateleiraSelect: (prateleira: IPrateleira) => void
  handleAlternarPosicaoClick: (posicaoPrateleira: IPosicaoPrateleira) => void
}

export function CorredorDraw({
  corredor,
  prefixoCD,
  indiceEstante,
  handleExcluirPosicaoClick,
  handleAdicionarPosicaoClick,
  handleExcluirPrateleiraClick,
  handleAdicionarPrateleiraClick,
  handleMudarPrateleiraTipoCaixaClick,
  handleAdicionarEstanteClick,
  handleExcluirEstanteClick,
  handleExcluirCorredorClick,
  handleIncluirCorredorClick,
  handlePrateleiraSelect,
  handleAlternarPosicaoClick
}: CorredorDrawProps) {
  const getAddressCorredor = (): string => {
    return prefixoCD + '.' + corredor.identificacao
  }

  const getEstante = (estantes: IEstante[]): IEstante => {
    if (indiceEstante !== '' && corredor.estante) {
      const estante = corredor.estante.find(
        (est) => est.id_estante === indiceEstante
      )
      if (estante) {
        return estante
      }
    }
    const posEstanteAtual = estantes.reduce((acc, cur) => {
      return acc > +cur.nestante ? +cur.nestante : acc
    }, 99999)
    return (
      estantes.find((est) => +est.nestante === posEstanteAtual) ?? estantes[0]
    )
  }

  return (
    <Stack gap={4} width={`calc( 100% - ${290}px )`}>
      {corredor && (
        <Stack
          key={`ID-${getAddressCorredor()}`}
          gap={2}
          direction={'row'}
          sx={{
            backgroundColor: '#F1F3F4',
            padding: '12px',
            borderRadius: '6px',
            border: '1px solid gray',
            width: `calc( 100% - ${0}px )`
          }}
          id={`ID-${getAddressCorredor()}`}
        >
          <Descriptor
            title={`Corredor: ${
              corredor.identificacao
            } (${getAddressCorredor()}) `}
            data={getCorredorSpace(corredor)}
            showButton1={true}
            showButton2={true}
            tipButton1={'Adiconar corredores ao CD'}
            tipButton2={'Excluir o corredor atual e os subsequentes'}
            actionButton1={() => {
              handleIncluirCorredorClick()
            }}
            actionButton2={() => {
              handleExcluirCorredorClick(corredor, getAddressCorredor())
            }}
          />
          {corredor.estante && corredor.estante.length > 0 ? (
            <EstanteDraw
              enderecoCorredor={getAddressCorredor()}
              estante={getEstante(corredor.estante)}
              handleExcluirPosicaoClick={handleExcluirPosicaoClick}
              handleAdicionarPosicaoClick={handleAdicionarPosicaoClick}
              handleExcluirPrateleiraClick={handleExcluirPrateleiraClick}
              handleAdicionarPrateleiraClick={handleAdicionarPrateleiraClick}
              handleExcluirEstanteClick={handleExcluirEstanteClick}
              handleAdicionarEstanteClick={handleAdicionarEstanteClick}
              handlePrateleiraSelect={handlePrateleiraSelect}
              handleMudarPrateleiraTipoCaixaClick={
                handleMudarPrateleiraTipoCaixaClick
              }
              handleAlternarPosicaoClick={handleAlternarPosicaoClick}
            />
          ) : (
            <>
              <Box>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <Tooltip title={'Adiciona corredores ao CD'}>
                          <Button
                            onClick={() => {
                              handleAdicionarEstanteClick(
                                corredor.id_corredor,
                                prefixoCD
                              )
                            }}
                            variant="contained"
                            color="success"
                          >
                            <LibraryAddIcon />
                          </Button>
                        </Tooltip>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Box>
            </>
          )}
        </Stack>
      )}
    </Stack>
  )
}
