import { format } from 'date-fns'
import { ITipoDocumentos } from '../../../../services/relatorio/types'
import { ApiEmpresa } from '../../../../services/empresa'
import { ApiTipoDocumento } from '../../../../services/tipoDocumento'
import { getCodigo } from '../../../../utils/getCodigo'
import { TemplateReport } from '../../../../components/Report'

export interface FiltrosProps {
  id_empresa: string
  id_tipo_documento?: string
}

type Props = {
  id_usuario: string
  data: ITipoDocumentos[]
  filtros: FiltrosProps
  mode?: 'open' | 'save'
}

export async function TipoDocumentosReport({
  data,
  mode,
  id_usuario,
  filtros
}: Props) {
  const filterReportText = async ({
    id_empresa,
    id_tipo_documento
  }: FiltrosProps) => {
    const filtersReport = []
    filtersReport.push({ text: 'Empresa: ', style: ['filterText', 'bold'] })
    if (id_empresa) {
      const empresa = await ApiEmpresa.findOne(id_empresa)
      if (empresa) {
        filtersReport.push({
          text: empresa.razao_social,
          style: ['filterText']
        })
      }
    } else filtersReport.push({ text: '<todas> ', style: ['filterText'] })

    filtersReport.push({ text: '\n' })
    filtersReport.push({
      text: 'Tipo de Documento: ',
      style: ['filterText', 'bold']
    })
    if (id_tipo_documento) {
      const tipoDocumento = await ApiTipoDocumento.findOne(id_tipo_documento)
      if (tipoDocumento) {
        filtersReport.push({
          text: tipoDocumento.descricao,
          style: ['filterText']
        })
      }
    } else filtersReport.push({ text: '<todos> ', style: ['filterText'] })
    filtersReport.push({ text: '\n' })

    return filtersReport
  }
  const tipoDocumentosTable = () => {
    let rows: Array<any> = []
    if (data && data.length > 0) {
      rows = [
        [
          { text: 'Id', style: 'columnTitle' },
          { text: 'Data Cadastro', style: 'columnTitle' },
          { text: 'Descrição', style: 'columnTitle' },
          { text: 'Assunto', style: 'columnTitle' },
          { text: 'Tipo Atividade', style: 'columnTitle' },
          { text: 'Cod. Class.', style: 'columnTitle' },
          { text: 'Tipo Guarda', style: 'columnTitle' },
          { text: 'Tempo Guarda', style: 'columnTitle' },
          { text: 'Temporalidade', style: 'columnTitle' },
          { text: 'Destinação Final', style: 'columnTitle' }
        ]
      ]
      data.forEach((tipoDocumento) => {
        let unidadeTemporalidade = ''
        switch (tipoDocumento.tipo_temporalidade?.unidade) {
          case 'ANO':
            unidadeTemporalidade = 'ano(s)'
            break
          case 'MES':
            unidadeTemporalidade = 'mes(es)'
            break
          case 'DIA':
            unidadeTemporalidade = 'dia(s)'
            break
          default:
            break
        }
        const row = [
          {
            text: getCodigo(tipoDocumento.id_tipo_documento ?? ''),
            style: 'row'
          },
          {
            text: format(
              new Date(tipoDocumento.created_at ?? new Date()),
              'dd/MM/yyyy'
            ),
            style: 'rowCenter'
          },
          { text: tipoDocumento.codigo, style: 'row' },
          { text: tipoDocumento.descricao, style: 'row' },
          { text: '', style: 'row' },
          { text: '', style: 'row' },
          {
            text: `${tipoDocumento.guarda_digital ? 'Digital' : ''} ${
              tipoDocumento.guarda_digital && tipoDocumento.guarda_fisica
                ? ' e '
                : ''
            } ${tipoDocumento.guarda_fisica ? 'Física' : ''}`,

            style: 'row'
          },
          { text: tipoDocumento.tempo_guarda, style: 'row' },
          {
            text: `${
              tipoDocumento.tipo_temporalidade?.tempo ?? ''
            } ${unidadeTemporalidade} `,
            style: 'row'
          },
          { text: tipoDocumento.destinacao_documento?.descricao, style: 'row' }
        ]
        rows.push(row as any)
      })
      return [
        {
          table: {
            widths: [75, 55, 95, 90, 70, 50, 48, 66, 79, 70],
            headerRows: 1,
            body: rows
          }
        },
        {
          text: `Total de Registros: ${data.length}`,
          style: 'total'
        }
      ]
    } else {
      return [{ text: '\n' }]
    }
  }

  const filterText = await filterReportText(filtros)
  const content = tipoDocumentosTable()

  await TemplateReport({
    id_usuario,
    content,
    filterText,
    reportTitle: 'Tipos de Documentos',
    pageOrientation: 'landscape',
    mode
  })
}
