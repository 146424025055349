import { Box } from '@mui/material'
import {
  BarElement,
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

interface DashboardWidgetSummaryBodyPie {
  data: ChartData<'bar', number[], string>
}

export function DashboardWidgetSummaryBodyBar({
  data
}: DashboardWidgetSummaryBodyPie) {
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    indexAxis: 'y' as const,
    plugins: {
      legend: {
        display: false
      },
      scales: {
        y: {
          beginAtZero: true
        }
      }
    }
  }
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  )
  return (
    <Box
      sx={{
        width: '100%',
        height: '350px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        padding: 2
      }}
    >
      <Bar data={data} options={options} width={'600'} />
    </Box>
  )
}
