import { AddRounded } from '@mui/icons-material'
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material'
import { useState } from 'react'
import { ITipoAtividade } from '../../../../../services/tipoAtividade/types'

interface IItemSelectorProps {
  handleSelectTipoAtividade: (tipoAtividade: ITipoAtividade) => void
  listaTiposAtividades: ITipoAtividade[]
  disableControls: boolean
}

const tipoAtividadeLimpo = {
  id_tipo_atividade: '',
  descricao: '',
  id_setor: '',
  identificacao: '',
  sla: 0,
  estado_execucao: '',
  estado_final: '',
  prerequisito: '',
  estado_alternativo: '',
  em_uso: false,
  permissao: {
    id_permissao_disponivel: '',
    codigo_permissao: 0,
    descricao: ''
  },
  permissao_movimentacao: {
    id_permissao_disponivel: '',
    codigo_permissao: 0,
    descricao: ''
  }
}

export function ItemSelector({
  handleSelectTipoAtividade,
  listaTiposAtividades,
  disableControls
}: IItemSelectorProps) {
  const [tipoAtividade, setTipoAtividade] =
    useState<ITipoAtividade>(tipoAtividadeLimpo)

  function handleSelectItem(id_tipo_atividade: string) {
    const currentTipoAtividade =
      listaTiposAtividades.find(
        (itemLista) => itemLista.id_tipo_atividade === id_tipo_atividade
      ) ?? tipoAtividadeLimpo
    setTipoAtividade(currentTipoAtividade)
  }

  function handleClickButton() {
    const newTipoAtividade = tipoAtividade
    setTipoAtividade(tipoAtividadeLimpo)

    handleSelectTipoAtividade(newTipoAtividade)
  }

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      <FormControl size="small">
        <InputLabel id="label-tipo-atividade">Tipo de Atividade</InputLabel>
        <Select
          labelId="label-tipo-atividade"
          value={tipoAtividade.id_tipo_atividade}
          label="Tipo de Atividade"
          onChange={(event) => handleSelectItem(event.target.value)}
          sx={{
            width: '200px',
            height: '2.5rem',
            borderRadius: '8px 0 0 8px'
          }}
          disabled={disableControls}
        >
          <MenuItem value="" key="0">
            {' '}
            --{' '}
          </MenuItem>
          {listaTiposAtividades.map((itemTipoAtividade) => {
            return (
              <MenuItem
                value={itemTipoAtividade.id_tipo_atividade}
                key={itemTipoAtividade.id_tipo_atividade}
              >
                {itemTipoAtividade.descricao}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
      <Button
        variant="contained"
        sx={{
          borderRadius: '0 32px 32px 0',
          height: '2.5rem'
        }}
        onClick={handleClickButton}
        disabled={disableControls || tipoAtividade.id_tipo_atividade === ''}
      >
        <AddRounded />
      </Button>
    </Box>
  )
}
