import { DefaultApi } from '../../utils/defaultApi'
import api from '../api'
import { ICopiaPermissoes, IPermissaoAtribuida } from './types'

const endpoint = '/permissao-atribuida'
const resourceId = 'id_permissao_atribuida'

class ServicePermissao extends DefaultApi<IPermissaoAtribuida> {
  createPermissaoAtribuida = async ({
    id_usuario,
    codigo_permissao
  }: {
    id_usuario: string
    codigo_permissao: string
  }) => {
    const {
      data: { id_permissao_disponivel }
    } = await api.get(`/permissao-disponivel/${codigo_permissao}`)

    const formData = {
      id_usuario,
      id_permissao_disponivel
    }

    const { data } = await api.post(`${this.endpoint}`, formData)

    return data
  }

  removePermissaoAtribuida = async ({
    id_usuario,
    codigo_permissao
  }: {
    id_usuario: string
    codigo_permissao: string
  }) => {
    const { data } = await api.delete(
      `${this.endpoint}/${id_usuario}/${codigo_permissao}`
    )
    return data
  }

  copyPermissions = async ({
    id_usuario_origem,
    id_usuario_destino
  }: ICopiaPermissoes) => {
    const formData = {
      id_usuario_origem,
      id_usuario_destino
    }
    const data = await api.post(`${this.endpoint}/copy-permissions/`, formData)

    return data
  }
}

const apiPermissao = new ServicePermissao(endpoint, resourceId)

export const ApiPermissao = {
  create: apiPermissao.createPermissaoAtribuida,
  remove: apiPermissao.removePermissaoAtribuida,
  copyPermissions: apiPermissao.copyPermissions
}
