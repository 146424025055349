import { Box, Typography } from '@mui/material'
import { ReactNode } from 'react'
import { corDivisoria, corTituloColunaTabela } from '../../cores'

interface DashboardWidgetListHeaderRootProps {
  title: string
  children: ReactNode
}

export function DashboardWidgetListHeaderRoot({
  children,
  title
}: DashboardWidgetListHeaderRootProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${corDivisoria}`,
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '2rem',
        paddingRight: '2rem',
        width: '100%',
        marginBottom: '1rem'
      }}
    >
      <Typography
        sx={{
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '14px',
          color: corTituloColunaTabela
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          alignItems: 'center',
          color: corTituloColunaTabela,
          gap: '1rem'
        }}
      >
        {children}
      </Box>
    </Box>
  )
}
