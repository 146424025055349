import { ButtonHTMLAttributes, ElementType } from 'react'
import { corTituloColunaTabela } from '../../cores'
import { Tooltip } from '@mui/material'

interface DashboardWidgetListHeaderIconActionProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: ElementType
  tip: string
}

export function DashboardWidgetListHeaderIconAction({
  icon: Icon,
  tip,
  ...rest
}: DashboardWidgetListHeaderIconActionProps) {
  const { disabled } = rest
  const IconStyle = {
    width: '42px',
    height: '42px',
    color: corTituloColunaTabela,
    borderRadius: '21px',
    margin: 0,
    padding: 0
  }
  return disabled ? (
    <button {...rest}>
      <Icon
        style={{
          ...IconStyle
        }}
      />
    </button>
  ) : (
    <Tooltip title={tip}>
      <button {...rest}>
        <Icon
          style={{
            ...IconStyle
          }}
        />
      </button>
    </Tooltip>
  )
}
