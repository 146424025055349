import pdfMake from 'pdfmake/build/pdfmake'
import { TDocumentDefinitions } from 'pdfmake/interfaces'
import { format } from 'date-fns'
import { IEtiqueta } from './types'
// import logo from '../../../../assets/images/logo_xsolution_beta.png'
import logoReportImg from '../../../../assets/images/logo-report.png'
import padlockImg from '../../../../assets/images/padlock.png'
import unlockImg from '../../../../assets/images/unlock.png'
import scissorsCutImg from '../../../../assets/images/scissors-cut.png'
import { toDataURL } from '../../../../utils/toDataURL'
import { vfs } from '../../../../utils/report/fonts/pdfFonts'

pdfMake.vfs = vfs
pdfMake.fonts = {
  Montserrat: {
    normal: 'Montserrat-Regular.ttf',
    bold: 'Montserrat-SemiBold.ttf',
    italics: 'Montserrat-Italic.ttf',
    bolditalics: 'Montserrat-SemiBoldItalic.ttf'
  }
}

type EtiquetaProps = {
  data: IEtiqueta
  mode?: 'open' | 'save'
}

export async function Etiqueta({ data, mode = 'open' }: EtiquetaProps) {
  // const reportLogo = String(await toDataURL(logo))
  const logoReport = String(await toDataURL(logoReportImg))
  const padlock = String(await toDataURL(padlockImg))
  const unlock = String(await toDataURL(unlockImg))
  const scissorsCut = String(await toDataURL(scissorsCutImg))

  const pageHeader = () => [
    {
      image: logoReport,
      // width: 192,
      // height: 108,
      // width: 129.6,
      // height: 72.9,
      width: 80,
      height: 58,
      style: 'logo'
    }
  ]

  const gerarEtiquetaCaixa = () => {
    //-- gerando um array com 3 etiquetas
    const etiquetas = Array.from(Array(3).keys()).map(() => [
      {
        image: scissorsCut,
        style: 'scissorsStyle',
        width: 33,
        height: 17
      },

      {
        table: {
          // widths: ['*'],
          widths: [335],
          body: [
            [
              {
                columns: [
                  // 1º COLUNA
                  {
                    width: 128,
                    table: {
                      widths: ['*'],
                      body: [
                        [
                          {
                            border: [false],
                            columns: [
                              [
                                pageHeader(),
                                {
                                  text: `Aponte a câmera do celular e \n veja mais detalhes sobre a caixa`,
                                  style: 'instrucao'
                                },
                                {
                                  qr: `https://easydoc.xsolutionti.com.br/gerenciar-solicitacoes/caixas?busca=${data.id_caixa}`,
                                  // fit: 180,
                                  fit: 140,
                                  style: 'qrcode'
                                }
                              ]
                            ]
                          }
                        ]
                      ]
                    }
                  },

                  // 2º COLUNA
                  {
                    style: 'tableContents',
                    table: {
                      widths: ['*'],
                      // heights: 290,
                      heights: 222,
                      body: [
                        [
                          [
                            {
                              text: `CLIENTE: ${data.cliente}`,
                              style: 'subtitle'
                            },
                            {
                              text: `SETOR: ${data.setor}`,
                              style: 'subtitle'
                            },

                            {
                              text: `IDENTIFICAÇÃO: ${data.identificacao}`,
                              style: 'subtitle'
                            },
                            {
                              text: `CONTEÚDO: ${data.conteudo}`,
                              style: 'subtitle'
                            },
                            {
                              text: `PERÍODO: ${data.periodo}`,
                              style: 'subtitle'
                            },
                            {
                              text: `SEQUÊNCIA: ${data.sequencia}`,
                              style: 'subtitle'
                            },

                            {
                              style: 'footer',
                              columns: [
                                [
                                  {
                                    text: `ID: ${data.id_caixa}`,
                                    style: 'id'
                                  },
                                  {
                                    text:
                                      'Impresso em:' +
                                      format(
                                        new Date(),
                                        " dd/MM/yyyy 'às' HH:mm"
                                      )
                                  }
                                ],
                                {
                                  image: data.lacrado ? padlock : unlock,
                                  width: 28,
                                  height: 28
                                }
                              ]
                            }
                          ]
                        ]
                      ]
                    }
                  }
                ]
              }
            ]
          ]
        }
      }
    ])

    return etiquetas
  }

  const docDefinitions: TDocumentDefinitions = {
    defaultStyle: { font: 'Montserrat' },
    pageSize: 'A4',
    pageMargins: [30, 5, 30, 0],
    content: [gerarEtiquetaCaixa()],

    styles: {
      subtitle: {
        fontSize: 8,
        margin: [5, 5, 0, 10]
      },
      id: {
        margin: [0, 8, 0, 0]
      },
      footer: {
        fontSize: 8,
        margin: [5, 20, 5, 0]
      },
      instrucao: {
        fontSize: 7,
        italics: true,
        alignment: 'center',
        margin: [0, 8, 0, 0]
      },
      qrcode: {
        margin: [8, 8, 0, 0]
      },
      logo: {
        margin: [22, 16, 20, 8]
      },
      tableContents: {
        margin: [10, 10, 10, 10]
      },
      scissorsStyle: {
        margin: [10, 0, 0, 2]
      }
    }
  }

  if (mode === 'open') {
    const win = window.open('', '_blank', 'popup=yes')
    pdfMake.createPdf(docDefinitions).open({}, win)
  } else if (mode === 'save') {
    pdfMake.createPdf(docDefinitions).download(`etiqueta.pdf`)
  }
}
