import { IItemRequisicao } from '../../../../services/requisicao/types'

import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import PendingActionsIcon from '@mui/icons-material/PendingActions'
import { MovimentacaoTimeLineCard } from './MovimentacaoTimeLineCard'
import { Box } from '@mui/material'
import { color } from './utils'

export interface ItemRequisicaoTimeLineProps {
  itemRequisicao: IItemRequisicao
}
export function ItemRequisicaoTimeLine({
  itemRequisicao
}: ItemRequisicaoTimeLineProps) {
  const sortedMovimentacoes = itemRequisicao.movimentacoes.sort((a, b) => {
    const dateA = new Date(a.created_at as string)
    const dateB = new Date(b.created_at as string)
    return dateB.getTime() - dateA.getTime()
  })

  return (
    <Box
      sx={{
        width: '950px' // Ajuste a largura do Box para 100%
      }}
    >
      <Timeline
        position="alternate"
        sx={{
          width: '950px'
        }}
      >
        {sortedMovimentacoes.map((movimentacao) => (
          <TimelineItem>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot
                sx={{
                  bgcolor: color(movimentacao.estado)
                }}
              >
                {movimentacao.estado === 'COMPLETO' && <DoneIcon />}
                {movimentacao.estado === 'EXECUTANDO' && <HourglassEmptyIcon />}
                {movimentacao.estado === 'PENDENTE' && <PendingActionsIcon />}
                {movimentacao.estado === 'CANCELADO' && <CloseIcon />}
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <MovimentacaoTimeLineCard movimentacao={movimentacao} />
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Box>
  )
}
