import axios from 'axios'
import { toast } from 'react-toastify'
import { LOCAL_STORAGE_TOKEN, LOCAL_STORAGE_USER } from '../hooks/auth'

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL ?? 'https://localhost:3333'
})

const sleep = (seconds: number) => {
  return new Promise((r) => {
    setTimeout(r, seconds)
  })
}

api.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem(LOCAL_STORAGE_TOKEN)
      localStorage.removeItem(LOCAL_STORAGE_USER)

      const errorMessage = error.response.data.error.message

      toast.error(
        errorMessage
          ? `${error.response.data.message} (${error.response.data.error.message})`
          : error.response.data.message
      )
      await sleep(3000)
      window.location.href = '/login'
    }

    return Promise.reject(error?.response?.data)
  }
)

export default api
