import { Box } from '@mui/material'
import { DetalhesRequisicao } from '../../../pages/Cadastros/DetalharRequisicoes/components/DetalhesRequisicao'
import {
  IItemRequisicao,
  IRequisicaoEstado
} from '../../../services/requisicao/types'

interface DashboardWidgetFormRequisicaoProps {
  data: IRequisicaoEstado
  onClickDetails?: (itemRequisicao: IItemRequisicao) => void
}

export function DashboardWidgetFormRequisicao({
  onClickDetails,
  data
}: DashboardWidgetFormRequisicaoProps) {
  return (
    <Box
      sx={{
        width: '100%',
        padding: 0,
        margin: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        maxHeight: '800px',
        overflow: 'hidden',
        overflowY: 'scroll',
        marginBottom: '1.5rem'
      }}
    >
      <DetalhesRequisicao
        statusRequisicao={data}
        onClickDetails={onClickDetails}
      />
    </Box>
  )
}
