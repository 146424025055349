import { useState } from 'react'
import { Box } from '@mui/material'
import { FormMode } from '../../../types/formMode'
import { FormTitle } from '../../../components/Form/FormTitle'
import { CategoriaParceiroList } from './CategoriaParceiroList'
import { CategoriaParceiroForm } from './CategoriaParceiroForm'

export interface ICategoriaParceiroProps {
  tipo_relacionamento: string
}

export function CategoriaParceiro({
  tipo_relacionamento
}: ICategoriaParceiroProps) {
  const [formMode, setFormMode] = useState<FormMode>('LIST')
  const [data, setData] = useState<any>({})

  return (
    <Box bgcolor="#fff" p={4} borderRadius={2}>
      <FormTitle
        title={`Categorias de ${
          tipo_relacionamento === 'C' ? 'Clientes' : 'Fornecedores'
        }`}
        subtitle={formMode}
      />

      {formMode === 'LIST' ? (
        <CategoriaParceiroList
          setData={setData}
          setFormMode={setFormMode}
          tipo_relacionamento={tipo_relacionamento}
        />
      ) : (
        <CategoriaParceiroForm
          data={formMode === 'EDIT' ? data : {}}
          formMode={formMode}
          setFormMode={setFormMode}
          tipo_relacionamento={tipo_relacionamento}
        />
      )}
    </Box>
  )
}
