import { createContext, useState, useContext, useCallback } from 'react'
import { menuList } from '../components/Layouts/Sidebar/menuList'
import { countInstances } from '../utils/countInstances'

interface RouteProps {
  path: string
}

interface TrackingProps {
  menu?: string
  submenu?: string
}
interface TrackRouteData {
  tracking: TrackingProps
  setTrackRoute(route: RouteProps): void
}

const TrackRouteContext = createContext<TrackRouteData>({} as TrackRouteData)

interface ITrackRouteProviderProps {
  children: React.ReactNode
}

function TrackRouteProvider({ children }: ITrackRouteProviderProps) {
  const [tracking, setTracking] = useState<TrackingProps>({} as TrackingProps)

  const setRoutePath = useCallback(({ path }: RouteProps) => {
    const countMenu = countInstances(path, '/')

    const menu = menuList.find((m) =>
      countMenu === 1
        ? m.path === path
        : m.submenus?.find((s) => s.path === path)
    )?.title

    const submenu = menuList
      .flatMap((m) => m.submenus)
      .find((s) => s?.path === path)?.title

    setTracking({
      menu,
      submenu
    })
  }, [])

  return (
    <TrackRouteContext.Provider
      value={{
        tracking,
        setTrackRoute: setRoutePath
      }}
    >
      {children}
    </TrackRouteContext.Provider>
  )
}

function useTrackRoute(): TrackRouteData {
  const context = useContext(TrackRouteContext)
  return context
}

export { TrackRouteProvider, useTrackRoute }
