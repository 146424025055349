import { Button, CircularProgress, Stack } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'

import { ReportViewModeFormat } from '../../../services/relatorio/types'

interface ReportButtonProps {
  isSubmitting: boolean
  format: 'pdf' | 'csv'
  mode: 'open' | 'save'
  handleReportFormatAndMode: ({ format, mode }: ReportViewModeFormat) => void
  handleResetFields?: () => void
}
export function ReportButtons({
  isSubmitting,
  format,
  mode,
  handleReportFormatAndMode,
  handleResetFields
}: ReportButtonProps) {
  return (
    <Stack direction="row" gap={2} mt={3}>
      <Button
        type="submit"
        variant="contained"
        color="success"
        startIcon={
          isSubmitting && format === 'pdf' && mode === 'open' ? undefined : (
            <SearchIcon sx={{ width: 24, height: 24 }} />
          )
        }
        sx={{ width: '115px', fontWeight: 600 }}
        onClick={() =>
          handleReportFormatAndMode({ format: 'pdf', mode: 'open' })
        }
        title="Visualizar Relatório"
        disabled={isSubmitting}
      >
        {isSubmitting && format === 'pdf' && mode === 'open' ? (
          <CircularProgress size={24} color={'inherit'} />
        ) : (
          'Visualizar'
        )}
      </Button>
      <Button
        type="submit"
        variant="contained"
        startIcon={
          isSubmitting && format === 'pdf' && mode === 'save' ? undefined : (
            <DownloadIcon sx={{ width: 24, height: 24 }} />
          )
        }
        sx={{ width: '115px', fontWeight: 600 }}
        onClick={() =>
          handleReportFormatAndMode({ format: 'pdf', mode: 'save' })
        }
        disabled={isSubmitting}
        title="Download do Relatório no formato PDF"
      >
        {isSubmitting && format === 'pdf' && mode === 'save' ? (
          <CircularProgress size={24} color={'inherit'} />
        ) : (
          'PDF'
        )}
      </Button>

      <Button
        type="submit"
        variant="contained"
        startIcon={
          isSubmitting && format === 'csv' ? undefined : (
            <DownloadIcon sx={{ width: 24, height: 24 }} />
          )
        }
        sx={{ width: '115px', fontWeight: 600 }}
        onClick={() =>
          handleReportFormatAndMode({ format: 'csv', mode: 'save' })
        }
        title="Download do Relatório no formato CSV"
        disabled={isSubmitting}
      >
        {isSubmitting && format === 'csv' ? (
          <CircularProgress size={24} color={'inherit'} />
        ) : (
          'CSV'
        )}
      </Button>

      <Button
        variant="outlined"
        startIcon={<ClearIcon sx={{ width: 24, height: 24 }} />}
        sx={{ width: '115px', fontWeight: 600 }}
        onClick={handleResetFields}
        title="Limpar Campos"
        disabled={isSubmitting}
      >
        Limpar
      </Button>
    </Stack>
  )
}
