import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  List,
  ListItem,
  IconButton,
  InputAdornment
} from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import logo from '../../assets/images/logo_easydoc.png'
import { ShowMessage } from '../../components/ShowMessage'
import api from '../../services/api'
import {
  containsLowercase,
  containsMinMaxLength,
  containsNumber,
  containsSpecialChars,
  containsUppercase
} from '../../utils/stringValidation'

export function ModifyPassword() {
  const navigate = useNavigate()
  const [message, setMessage] = useState<string>()
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const handleNavigateToLogin = () => navigate('/login')
  const handleNavigateToRecover = () => navigate('/password-recover')

  async function submitVerificationCode(
    event: React.FormEvent<HTMLInputElement>
  ) {
    event.preventDefault()
    const id = localStorage.getItem('id')

    try {
      await api.put('/auth/change-password', {
        id_usuario: id,
        password: password
      })
      handleNavigateToLogin()
    } catch (err: any) {
      setMessage(err?.message)
    }
  }

  const validationItems = [
    {
      condition: containsUppercase(password),
      label: '1 letra maiúsculas. Ex.: A B C D E F G H'
    },
    {
      condition: containsLowercase(password),
      label: '1 letra minúscula. Ex.: a b c d e f g h'
    },
    {
      condition: containsNumber(password),
      label: '1 número. Ex.: 0 1 2 3 4 5 6 7 8 9'
    },
    {
      condition: containsSpecialChars(password),
      label: '1 símbolos. Ex.: @ # $ ! *'
    },
    {
      condition: containsMinMaxLength(password, 6, 20),
      label: 'e no mínimo 6 e no máximo 20 caracteres'
    }
  ]

  function validadePassword(str: string) {
    return (
      containsUppercase(str) &&
      containsLowercase(str) &&
      containsNumber(str) &&
      containsSpecialChars(str) &&
      containsMinMaxLength(str, 6, 20)
    )
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 16,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <img src={logo} width="60%" alt="logo X-Solution" />

        <Typography variant="h4" mt={4}>
          Recuperação de senha
        </Typography>
        <Box component="form" onSubmit={submitVerificationCode} sx={{ mt: 1 }}>
          <Typography variant="h6" mt={4}>
            Insira a nova senha
          </Typography>
          <TextField
            type={showPassword ? 'text' : 'password'}
            label="senha"
            required
            fullWidth
            margin="normal"
            autoFocus
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <List sx={{ fontSize: 13, lineHeight: 1 }}>
            {validationItems.map((item, index) => (
              <ListItem key={index}>
                {item.condition ? '🟢' : '🔴'} {item.label}
              </ListItem>
            ))}
          </List>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={!validadePassword(password)}
            sx={{ mt: 3, mb: 2, fontSize: '14px' }}
          >
            Continuar
          </Button>

          <Grid container>
            <Grid item xs>
              <Link
                variant="body2"
                onClick={handleNavigateToRecover}
                sx={{ cursor: 'pointer' }}
              >
                {'Voltar'}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <ShowMessage
        hasError={!!message}
        message={message}
        onClose={() => setMessage(undefined)}
      />
    </Container>
  )
}
