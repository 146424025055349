import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ApiReceitaDespesa } from '../../../services/financeiro/receitaDespesa'

const QUERY_KEY = 'qkReceitaDespesa'

const FindAll = (page: number, q = '', tipo = '') => {
  return useQuery([QUERY_KEY, q, page], () =>
    ApiReceitaDespesa.findAllByTipo(page, q, true, tipo)
  )
}

const ListAll = (tipo = '') => {
  return useQuery([QUERY_KEY], () => ApiReceitaDespesa.listAllByTipo(tipo))
}

const Create = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiReceitaDespesa.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Update = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiReceitaDespesa.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Remove = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiReceitaDespesa.remove, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

export const useReceitaDespesa = {
  Create,
  Update,
  FindAll,
  ListAll,
  Remove
}
