import { Pagination } from '@mui/material'

interface IPaginationTableProps {
  itemsPerPage: number
  currentPage: number
  totalItems: number
  totalPages: number
  onPageChange: (page: number) => void
}

const DashboardWidgetListBodyPagination = ({
  currentPage,
  totalPages,
  onPageChange
}: IPaginationTableProps) => {
  return (
    <Pagination
      count={totalPages}
      sx={{ marginTop: '12px' }}
      onChange={(_, newPage) => onPageChange(newPage)}
      page={currentPage}
    />
  )
}

export { DashboardWidgetListBodyPagination }
