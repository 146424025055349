import { Alert, Snackbar } from '@mui/material'

interface ShowMessageProps {
  hasError: boolean
  message?: string
  onClose: () => void
}

export function ShowMessage({ hasError, message, onClose }: ShowMessageProps) {
  return (
    <Snackbar
      open={hasError}
      autoHideDuration={4000}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <Alert
        severity="error"
        onClose={onClose}
        sx={{ width: '100%', fontSize: '14px' }}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}
