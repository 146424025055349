interface RequirementsParserProps {
  expressao: string
  estado: string
  estados: string[]
}

/**
 * Limpa uma expressão que contenha "OU", "(", ")" e espaços repetidos,
 * convertendo para uma lista de palavras
 * @param expressao String a ser convertida
 * @returns Lista de palavras
 */
export function expressaoToLista(expressao: string): Array<string> {
  return expressao
    .replace(/[()]/g, ' ')
    .replace(/ OU /g, ' ')
    .replace(/ {2}/g, ' ')
    .split(' ')
    .filter((item) => item !== '')
}

/**
 * Avaliar a aplicação de um estado em uma expressão booleana de pré-requisitos.
 * @param estados  lista de estados válidos (tanto para o estado a comparado,
 * quanto para o conteúdo da expressão de pré-requisitos)
 * @param expressao  expressão booleana a ser avaliada
 * @param estado  estado a ser aplicado à expressão, para avaliação
 * @returns flag se a o estado atende aos pré-requisitos da expressão
 */
export function requirementsParser({
  estados,
  expressao,
  estado
}: RequirementsParserProps): boolean {
  //Se a expressão estiver em branco, não há pré-requisitos
  if (!expressao || expressao.trim() === '') {
    return true
  }

  //Se o estado não estiver dentro da lista de estados válidos, está errado
  if (!estados.some((itemEstado) => itemEstado === estado)) {
    return false
  }

  //Transformação da string em uma lista de tokens
  const splitedExpression = expressaoToLista(expressao)

  //Verificação se estado faz parte de expressao
  const evaluation = splitedExpression.some((item) => item === estado)

  return evaluation
}
