import {
  IHandleReportSubmitProps,
  ReportForm
} from '../../../../components/ReportForm'
import { ApiRelatorio } from '../../../../services/relatorio'
import { DadosImportacaoReport } from './report/DadosImportacao'

export function DadosImportacaoRelatorio() {
  async function handleSubmitForm({
    mode,
    format,
    id_usuario,
    filtros
  }: IHandleReportSubmitProps) {
    const documentosImportacaoData = await ApiRelatorio.getImportedData({
      data_fim: filtros.data_fim,
      data_inicio: filtros.data_inicio,
      id_contrato: filtros.id_contrato,
      id_usuario: filtros.id_usuario
    })

    if (format === 'pdf') {
      await DadosImportacaoReport({
        data: documentosImportacaoData,
        mode,
        orientation: 'landscape',
        id_usuario,
        filtros
      })
    }

    return documentosImportacaoData
  }

  return (
    <ReportForm
      title="Importação de Documentos"
      handleReportSubmit={handleSubmitForm}
      mostrarEmpresa={false}
      mostrarContrato={true}
      mostrarServico={false}
      mostrarSetor={false}
      mostrarUsuario={true}
    />
  )
}
