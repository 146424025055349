import { DashboardWidgetFormAction } from './DashboardWidgetFormAction'
import { DashboardWidgetFormActions } from './DashboardWidgetFormActions'
import { DashboardWidgetFormAlocacao } from './DashboardWidgetFormAlocacao'
import { DashboardWidgetFormHeader } from './DashboardWidgetFormHeader'
import { DashboardWidgetFormLote } from './DashboardWidgetFormLote'
import { DashboardWidgetFormMovimentacao } from './DashboardWidgetFormMovimentacao'
import { DashboardWidgetFormRequisicao } from './DashboardWidgetFormRequisicao'
import { DashboardWidgetFormRequisicaoNaoAtendida } from './DashboardWidgetFormRequisicaoNaoAtendida'
import { DashboardWidgetFormRoot } from './DashboardWidgetFormRoot'

export const DashboardWidgetForm = {
  Action: DashboardWidgetFormAction,
  Actions: DashboardWidgetFormActions,
  Allocation: DashboardWidgetFormAlocacao,
  Header: DashboardWidgetFormHeader,
  Lote: DashboardWidgetFormLote,
  Movimentacao: DashboardWidgetFormMovimentacao,
  Requisicao: DashboardWidgetFormRequisicao,
  RequisicaoNaoAtendida: DashboardWidgetFormRequisicaoNaoAtendida,
  Root: DashboardWidgetFormRoot
}
