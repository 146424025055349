import { format } from 'date-fns'
import { IFinanceiroReportFilter } from '../../../../../../components/Financeiro/FinanceiroReportForm/types'
import { ApiCentroCusto } from '../../../../../../services/financeiro/centroCusto'
import { ApiParceiro } from '../../../../../../services/financeiro/parceiro'
import { ApiFormaPagamento } from '../../../../../../services/financeiro/formaPagamento'
import { ApiReceitaDespesa } from '../../../../../../services/financeiro/receitaDespesa'

export const filterReportText = async (filtros: IFinanceiroReportFilter) => {
  const {
    id_centro_custo,
    id_forma_pagamento,
    id_parceiro,
    data_fim,
    data_inicio,
    tipo_relacionamento,
    id_parceiro_2,
    id_receita_despesa,
    order_by
  } = filtros
  const filtersReport = []

  const isCliente = tipo_relacionamento === 'C'
  const isTodos = !tipo_relacionamento ? true : false

  filtersReport.push({
    text: `${isCliente || isTodos ? 'Cliente: ' : 'Fornecedor: '} `,
    style: ['filterText', 'bold']
  })
  if (id_parceiro) {
    const parceiro = await ApiParceiro.findOne(id_parceiro)

    if (parceiro) {
      filtersReport.push({
        text: parceiro.nome,
        style: ['filterText']
      })
    }
  } else filtersReport.push({ text: '<todos> ', style: ['filterText'] })
  filtersReport.push({ text: '\n' })

  if (isTodos) {
    filtersReport.push({
      text: 'Fornecedor: ',
      style: ['filterText', 'bold']
    })
    if (id_parceiro_2) {
      const parceiro = await ApiParceiro.findOne(id_parceiro_2)

      if (parceiro) {
        filtersReport.push({
          text: parceiro.nome,
          style: ['filterText']
        })
      }
    } else filtersReport.push({ text: '<todos> ', style: ['filterText'] })
    filtersReport.push({ text: '\n' })
  }

  filtersReport.push({
    text: 'Forma de Pagamento: ',
    style: ['filterText', 'bold']
  })
  if (id_forma_pagamento) {
    const formaPagamento = await ApiFormaPagamento.findOne(id_forma_pagamento)

    if (formaPagamento) {
      filtersReport.push({
        text: formaPagamento.descricao,
        style: ['filterText']
      })
    }
  } else filtersReport.push({ text: '<todas> ', style: ['filterText'] })
  filtersReport.push({ text: '\n' })
  filtersReport.push({
    text: `${
      isTodos ? 'Receita / Despesa' : isCliente ? 'Receita' : 'Despesa'
    }: `,
    style: ['filterText', 'bold']
  })
  if (id_receita_despesa) {
    const receitaDespesa = await ApiReceitaDespesa.findOne(id_receita_despesa)

    if (receitaDespesa) {
      filtersReport.push({
        text: receitaDespesa.descricao,
        style: ['filterText']
      })
    }
  } else filtersReport.push({ text: '<todas> ', style: ['filterText'] })
  filtersReport.push({ text: '\n' })

  filtersReport.push({
    text: 'Centro de Custo: ',
    style: ['filterText', 'bold']
  })
  if (id_centro_custo) {
    const centroCusto = await ApiCentroCusto.findOne(id_centro_custo)

    if (centroCusto) {
      filtersReport.push({
        text: centroCusto.descricao,
        style: ['filterText']
      })
    }
  } else filtersReport.push({ text: '<todos> ', style: ['filterText'] })
  filtersReport.push({ text: '\n' })
  filtersReport.push({ text: 'Período: ', style: ['filterText', 'bold'] })
  if (data_inicio && data_fim) {
    filtersReport.push({
      text: `${format(new Date(data_inicio), 'dd/MM/yyyy')} até ${format(
        new Date(data_fim),
        'dd/MM/yyyy'
      )}`,
      style: ['filterText']
    })
  } else filtersReport.push({ text: '<global> ', style: ['filterText'] })

  filtersReport.push({ text: '\n' })
  filtersReport.push({
    text: 'Ordenação: ',
    style: ['filterText', 'bold']
  })

  let ordem = ''
  switch (order_by) {
    case 'dt_pagamento':
      ordem = 'Data de Pagamento'
      break
    case 'dt_vencimento':
      ordem = 'Data de Vencimento'
      break
    case 'dt_emissao':
      ordem = 'Data de Emissao'
      break
    default:
      break
  }
  filtersReport.push({ text: ordem, style: ['filterText'] })

  return filtersReport
}
