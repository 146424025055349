import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ApiTipoAtividade } from '../../services/tipoAtividade'

const QUERY_KEY = 'qkTipoAtividade'

const FindAll = (page: number, q = '') => {
  return useQuery([QUERY_KEY, q, page], () => ApiTipoAtividade.findAll(page, q))
}

const ListAll = () => {
  return useQuery([QUERY_KEY], ApiTipoAtividade.listAll)
}

const Create = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiTipoAtividade.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Update = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiTipoAtividade.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Remove = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiTipoAtividade.remove, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

export const useTipoAtividade = {
  Create,
  Update,
  FindAll,
  ListAll,
  Remove
}
