import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ApiSetorPermitido } from '../../services/setorPermitido'

const QUERY_KEY = 'qkSetorPermitido'

const FindByUsuarioLogado = () => {
  return useQuery([QUERY_KEY], () => ApiSetorPermitido.getByLoggedUser())
}

const CopyPermissions = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiSetorPermitido.copyPermissions, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

export const useSetorPermitido = {
  FindByUsuarioLogado,
  CopyPermissions
}
