import { Link } from 'react-router-dom'
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { useState } from 'react'
import { IMenu } from './menuList'

const COLORS = {
  selectedMenu: '#fff',
  hoverMenu: '#fff',
  text: '#fff',
  hoverText: '#000000',
  icon: '#c5d9e3',
  selectedIcon: '#0085FF'
}

type SidebarMenuProps = {
  menu: IMenu
  onClickMenu: (menuTitle: string) => void
  selectedMenu: string
  userPermission: number[]
}

export function SidebarMenu({
  menu: { title, icon: Icon, path, submenus, permission = 0 },
  onClickMenu,
  selectedMenu,
  userPermission
}: SidebarMenuProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const toggleMenu = (title: string, submenu?: typeof submenus) => {
    setIsMenuOpen(!isMenuOpen)
    if (!submenu) onClickMenu(title)
  }

  const isSelectedMenu = selectedMenu === title

  return (
    <Box
      bgcolor={isSelectedMenu ? COLORS.selectedMenu : 'transparent'}
      borderRadius={1}
      mx={2}
    >
      <Link
        to={path ?? '#'}
        hidden={!userPermission.includes(permission)}
        style={{ textDecoration: 'none' }}
      >
        <ListItemButton
          onClick={() => toggleMenu(title, submenus)}
          selected={submenus?.length ? false : isSelectedMenu}
          sx={{
            color: isSelectedMenu ? COLORS.hoverText : COLORS.text,
            height: '48px',
            '&:hover': {
              color: COLORS.hoverText,
              bgcolor: COLORS.hoverMenu,
              borderRadius: 1
            }
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: 2.5,
              justifyContent: 'center',
              color: isSelectedMenu ? COLORS.selectedIcon : COLORS.icon
            }}
          >
            {Icon && <Icon sx={{ fontSize: '24px' }} />}
          </ListItemIcon>
          <ListItemText primary={title} />
          {submenus ? isMenuOpen ? <ExpandLess /> : <ExpandMore /> : null}
        </ListItemButton>
      </Link>

      {submenus?.map(({ title, icon: Icon, path, permission = 0 }) => (
        <Box
          key={title}
          bgcolor={selectedMenu === title ? COLORS.selectedMenu : 'transparent'}
          borderRadius={1}
        >
          <Collapse in={isMenuOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to={path ?? '#'}
                hidden={!userPermission.includes(permission)}
                style={{ textDecoration: 'none' }}
              >
                <ListItemButton
                  onClick={() => onClickMenu(title)}
                  selected={selectedMenu === title}
                  sx={{
                    pl: 4,
                    color:
                      selectedMenu === title ? COLORS.hoverText : COLORS.text,
                    '&:hover': {
                      bgcolor: COLORS.hoverMenu,
                      borderRadius: 1,
                      color: COLORS.hoverText
                    }
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: 1.5,
                      justifyContent: 'center',
                      color: COLORS.icon
                    }}
                  >
                    {Icon ? <Icon fontSize="small" /> : '•'}
                  </ListItemIcon>
                  <ListItemText primary={title} />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>
        </Box>
      ))}
    </Box>
  )
}
