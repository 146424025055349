export const getTempoDecorrido = (start: Date, end: Date): string => {
  let diff = (end.getTime() - start.getTime()) / 1000
  const seconds =
    Math.floor(diff % 60) < 10
      ? `0${Math.floor(diff % 60)}`
      : `${Math.floor(diff % 60)}`
  diff = Math.floor(diff / 60)
  const minuts = diff % 60 < 10 ? `0${diff % 60}` : diff % 60
  diff = Math.floor(diff / 60)
  const hours = diff % 60 < 10 ? `0${diff % 60}` : diff % 60
  return `${hours}:${minuts}:${seconds}`
}
