import { PageOrientation } from 'pdfmake/interfaces'
import { IDadosImportacaoData } from '../../../../../services/relatorio/types'
import { IReportFilter } from '../../../../../components/ReportForm'
import { format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import { getCodigo } from '../../../../../utils/getCodigo'
import { TemplateReport } from '../../../../../components/Report'
import { ApiContrato } from '../../../../../services/contrato'
import { ApiUsuario } from '../../../../../services/usuario'

type Props = {
  id_usuario: string
  data: IDadosImportacaoData[]
  mode?: 'open' | 'save'
  orientation?: PageOrientation
  filtros: IReportFilter
}

export async function DadosImportacaoReport({
  id_usuario,
  data,
  mode,
  filtros,
  orientation
}: Props) {
  const filterReportText = async () => {
    const { data_fim, data_inicio, id_contrato, id_usuario } = filtros
    const filtersReport = []

    filtersReport.push({ text: 'Contrato: ', style: ['filterText', 'bold'] })
    if (id_contrato) {
      const contrato = await ApiContrato.findOne(id_contrato)
      if (contrato) {
        filtersReport.push({
          text: contrato.descricao,
          style: ['filterText']
        })
      }
    } else filtersReport.push({ text: '<todos> ', style: ['filterText'] })
    filtersReport.push({ text: '\n' })

    filtersReport.push({ text: 'Usuário: ', style: ['filterText', 'bold'] })
    if (id_usuario) {
      const usuario = await ApiUsuario.findById(id_usuario)
      if (usuario) {
        filtersReport.push({
          text: usuario.username,
          style: ['filterText']
        })
      }
    } else filtersReport.push({ text: '<todos> ', style: ['filterText'] })
    filtersReport.push({ text: '\n' })

    filtersReport.push({ text: 'Período: ', style: ['filterText', 'bold'] })
    if (data_inicio && data_fim) {
      filtersReport.push({
        text: `${format(new Date(data_inicio), 'dd/MM/yyyy', {
          locale: ptBR
        })} até ${format(new Date(data_fim), 'dd/MM/yyyy', { locale: ptBR })}`,
        style: ['filterText']
      })
    } else filtersReport.push({ text: '<global> ', style: ['filterText'] })

    return filtersReport
  }

  let totalPaginas = 0
  let totalPdf = 0
  const DadosImportacaoGroupTable = () => {
    const tables: Array<any> = []
    if (data && data.length > 0) {
      let rows: Array<any> = []
      rows = [
        [
          { text: 'Id', style: 'columnTitle' },
          { text: 'Data', style: 'columnTitle' },
          { text: 'Total Registros', style: 'columnTitle' },
          { text: 'Total Efetivados', style: 'columnTitle' },
          { text: 'Total Marcados', style: 'columnTitle' },
          { text: 'Total PDF', style: 'columnTitle' },
          { text: 'Total Imagens', style: 'columnTitle' },
          { text: 'Total Erros', style: 'columnTitle' },
          { text: 'Mensagem Erros', style: 'columnTitle' }
        ]
      ]

      data.forEach((importacao) => {
        const row = [
          { text: getCodigo(importacao.id_dados_importacao), style: 'row' },
          {
            text: format(new Date(importacao.created_at), 'dd/MM/yyyy HH:mm'),
            style: 'rowCenter'
          },
          { text: importacao.total_registros, style: 'rowCenter' },
          { text: importacao.total_efetivados, style: 'rowCenter' },
          { text: importacao.total_marcados, style: 'rowCenter' },
          { text: importacao.total_pdf, style: 'rowCenter' },
          { text: importacao.total_paginas, style: 'rowCenter' },
          { text: importacao.total_erros, style: 'rowCenter' },
          { text: importacao.erros_importacao, style: 'row' }
        ]

        totalPaginas += importacao.total_paginas
        totalPdf += importacao.total_pdf
        rows.push(row as any)
      })

      tables.push([
        {
          table: {
            widths: [75, 80, 50, 55, 55, 50, 50, 50, 250],
            headerRows: 1,
            body: rows
          }
        },
        {
          text: `Total de PDF: ${totalPdf}`,
          style: 'total'
        },
        {
          text: `Total de Imagens: ${totalPaginas}`,
          style: 'total'
        }
      ])

      return tables
    } else {
      return [{ text: '\n' }]
    }
  }

  await TemplateReport({
    id_usuario,
    reportTitle: 'Importação de Dados',
    mode,
    pageOrientation: orientation,
    content: DadosImportacaoGroupTable(),
    filterText: await filterReportText()
  })
}
