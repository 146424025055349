import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ApiRequisicao } from '../../services/requisicao'

const QUERY_KEY = 'qkRequisicao'

const FindAll = (page: number, q = '') => {
  return useQuery([QUERY_KEY, page, q], () => ApiRequisicao.findAll(page, q))
}

const FindAllAdmin = (
  page: number,
  q = '',
  isAdmin: boolean,
  selectedOrder: string
) => {
  return useQuery([QUERY_KEY, page, selectedOrder, q], () =>
    ApiRequisicao.findAllAdmin(page, isAdmin, selectedOrder, q)
  )
}

const FindAllIncomplete = (
  page: number,
  q = '',
  isAdmin: boolean,
  selectedOrder: string
) => {
  return useQuery([QUERY_KEY, 'incomplete', page, selectedOrder, q], () =>
    ApiRequisicao.findAllIncomplete(page, isAdmin, selectedOrder, q)
  )
}

const FindAllDate = (
  page: number,
  date: string,
  q = '',
  isAdmin: boolean,
  selectedOrder: string
) => {
  return useQuery([QUERY_KEY, 'date', page, date, selectedOrder, q], () =>
    ApiRequisicao.findAllDate(page, date, isAdmin, selectedOrder, q)
  )
}

const FindAllIncompleteByDate = (
  page: number,
  date: string,
  q = '',
  isAdmin: boolean,
  selectedOrder: string
) => {
  return useQuery(
    [QUERY_KEY, 'incompleteByDate', page, date, selectedOrder, q],
    () =>
      ApiRequisicao.findAllIncompleteByDate(
        page,
        date,
        isAdmin,
        selectedOrder,
        q
      )
  )
}

const ListUnattended = () => {
  return useQuery([QUERY_KEY, 'Unattended'], () =>
    ApiRequisicao.listUnattended()
  )
}

const Create = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiRequisicao.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const FindOne = (id_requisicao: string) => {
  return useQuery([QUERY_KEY, id_requisicao], () =>
    ApiRequisicao.findOne(id_requisicao)
  )
}

const FindStatusById = (id_requisicao: string) => {
  return useQuery([QUERY_KEY, 'Status', id_requisicao], () =>
    ApiRequisicao.findStatusById(id_requisicao)
  )
}

const ValidateToken = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiRequisicao.validaToken, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(QUERY_KEY)
      queryClient.invalidateQueries([QUERY_KEY, data.id_requisicao])
      queryClient.invalidateQueries('qkDashboard')
    }
  })
}

export const useRequisicao = {
  Create,
  FindAll,
  FindAllAdmin,
  FindAllIncomplete,
  FindAllDate,
  FindAllIncompleteByDate,
  FindOne,
  FindStatusById,
  ListUnattended,
  ValidateToken
}
