import { useEffect, useState } from 'react'
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  Typography
} from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import SaveIcon from '@mui/icons-material/CheckCircleOutlined'
import SaveIconAndContinue from '@mui/icons-material/DoneAll'
import CancelIcon from '@mui/icons-material/DoDisturbAltOutlined'
import UploadIcon from '@mui/icons-material/CloudUpload'
import AddIcon from '@mui/icons-material/PostAdd'
import DeleteIcon from '@mui/icons-material/DeleteForever'
import Clear from '@mui/icons-material/Clear'
import { ICaixa } from '../../../services/caixa/types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import useToast from '../../../hooks/toast/useToast'
import { useCaixa } from '../../../hooks/caixa/useCaixa'
import { Input } from '../../../components/Form/Input'
import msg from '../../../utils/validationMessages'
import { FormMode } from '../../../types/formMode'
import { Select } from '../../../components/Form/Select'
import { FormDocumentos } from './FormDocumento'
import { useDocumento } from '../../../hooks/documento/useDocumento'
// import { Etiqueta } from './Etiqueta'
import { ITipoCaixa, tipoCaixaVazia } from '../../../services/tipoCaixa/types'
import { ComponentGroup } from '../../../components/ComponentGroup'
import { exclude } from '../../../utils/exclude'
import { ITipoDocumento } from '../../../services/tipoDocumento/types'
import { IContrato } from '../../../services/contrato/types'
import { FormDocumentoDigital } from './FormDocumentoDigital'
import { hasAccess } from '../../../utils/hasAccess'
import { useUsuario } from '../../../hooks/usuario/useUsuario'
import { Permissions } from '../../../types/permissions'
import { FormCaixa } from './FormCaixa'
import { Dialog } from '../../../components/Dialog'
import { useArvoreDocumental } from '../../../hooks/arvoreDcoumental/useArvoreDocumental'
import { ISetor } from '../../../services/setor/types'

const PrivacyLegends = () => {
  const privacyLegend = [
    {
      id: 'P',
      description: 'Contém dados pessoais (nome, cpf, endereço)'
    },
    {
      id: 'S',
      description: 'Contém dados pessoais sensíveis (ficha médica, biometria)'
    },
    {
      id: 'C',
      description: 'Contém dados de crianças e/ou adolescentes'
    }
  ]

  return (
    <Stack gap={1}>
      {privacyLegend.map((legend) => (
        <Chip
          key={legend.id}
          avatar={
            <Avatar sx={{ bgcolor: '#BDCDEA' }}>
              <Typography fontWeight="bold" color="#6081BD">
                {legend.id}
              </Typography>
            </Avatar>
          }
          label={legend.description}
          sx={{
            justifyContent: 'left',
            backgroundColor: '#edf1f8'
          }}
        />
      ))}
    </Stack>
  )
}

const schema = yup
  .object({
    identificacao: yup.string().required(msg.REQUIRED).min(4, msg.MIN(4)),
    conteudo: yup.string().required(msg.REQUIRED).min(4, msg.MIN(4)),
    id_tipo_caixa: yup.string().required(msg.REQUIRED),
    id_contrato: yup.string().required(msg.REQUIRED),
    id_tipo_documento: yup.string().required(msg.REQUIRED),
    id_setor: yup.string().required(msg.REQUIRED)
  })
  .required()

interface IForm {
  data: ICaixa
  tiposCaixas: ITipoCaixa[]
  tiposDocumentos: ITipoDocumento[]
  contratos: IContrato[]
  setoresPermitidos: ISetor[]
  setData: (value: React.SetStateAction<any>) => void
  formMode: FormMode
  setFormMode: (value: React.SetStateAction<FormMode>) => void
  caixasLacradas: boolean
  setCaixasLacradas: (value: React.SetStateAction<any>) => void
  isInventario?: boolean
}

export function Form({
  data,
  setData,
  formMode,
  setFormMode,
  tiposCaixas,
  contratos,
  caixasLacradas,
  setCaixasLacradas,
  isInventario = false,
  setoresPermitidos = []
}: IForm) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<any>({ resolver: yupResolver(schema) })
  const [openModal, setOpenModal] = useState(false)
  const [openModalDocDigital, setOpenModalDocDigital] = useState(false)
  const [openModalCaixa, setOpenModalCaixa] = useState(false)
  const [showConfirmRepeated, setShowConfirmRepeated] = useState<boolean>(false)
  const [confirmDecision, setConfirmDecision] = useState<
    ((value: boolean | PromiseLike<boolean>) => void) | null
  >(null)
  const [showDismiss, setShowDismiss] = useState<boolean>(false)
  const [currentBox, setCurrentBox] = useState<ICaixa>()
  const [closeForm, setCloseForm] = useState(false)
  const [acceptLgpd, setAcceptLgpd] = useState(false)
  const [tipoCaixa, setTipoCaixa] = useState<ITipoCaixa>(
    data.tipoCaixa ?? tipoCaixaVazia
  )
  const [idTipoCaixa, setIdTipoCaixa] = useState<string>(
    data.id_tipo_caixa ?? ''
  )

  const [idContratoSelecionado, setIdContratoSelecionado] = useState<string>(
    data?.id_contrato ?? ''
  )
  const [idEmpresa, setIdEmpresa] = useState<string>('')
  const [idSetor, setIdSetor] = useState<string>('')

  const { Toast } = useToast()
  const { mutateAsync: create, isLoading: isLoadingCreate } = useCaixa.Create()
  const { mutateAsync: update, isLoading: isLoadingUpdate } = useCaixa.Update()
  const { data: usuario } = useUsuario.FindByUsername()

  const { data: recordsCaixasContratos } = useCaixa.ListAllId(
    idContratoSelecionado
  )
  const idsSetores = new Set(
    (recordsCaixasContratos ?? []).map((obj) => obj.id_setor)
  )
  const identificacoes = new Set(
    (recordsCaixasContratos ?? []).map((obj) => obj.identificacao)
  )

  const [tiposCaixasFiltradas, setTiposCaixasFiltradas] =
    useState<ITipoCaixa[]>(tiposCaixas)

  const [tiposCaixasBusca, setTiposCaixasBusca] = useState<ITipoCaixa[]>([])

  const [selectedBox, setSelectedBox] = useState({
    P: data?.dados_pessoais || false,
    S: data?.dados_pessoais_sensiveis || false,
    C: data?.dados_criancas_adolescentes || false
  })

  const { data: documentos } = useDocumento.FindByIdCaixa(data.id_caixa ?? '0')
  const { data: caixas } = useCaixa.ListByCaixaContainer(data.id_caixa ?? '')
  //const { data: setoresPermitidos } = useSetorPermitido.FindByUsuarioLogado()
  const { mutateAsync: removeDocumento } = useDocumento.Remove()

  const listaSetores = setoresPermitidos?.filter(
    (setor) => setor.id_empresa === idEmpresa
  )

  const estadoInventariando = 'INVENTARIANDO'
  const estadoCriadoCliente = 'CRIADO_CLIENTE'
  const estadoCriacaoCaixa = isInventario
    ? estadoInventariando
    : estadoCriadoCliente

  const { data: listaTiposDocumentos } =
    useArvoreDocumental.FindTiposDocumentosBySetor(idSetor)

  const editavel =
    formMode === 'CREATE' ||
    [estadoCriadoCliente, estadoInventariando].includes(data?.estado)

  const handleCloseModal = () => setOpenModal(false)
  const handleCloseModalDocDigital = () => setOpenModalDocDigital(false)
  const handleCloseModalCaixa = () => setOpenModalCaixa(false)

  useEffect(() => {
    if (idTipoCaixa !== '') {
      const tpCx =
        tiposCaixas.find((tp) => tp.id_tipo_caixa === idTipoCaixa) ??
        tipoCaixaVazia
      setTipoCaixa(tpCx)
      const tpCxBusca = tiposCaixasFiltradas.filter(
        (tpcx) => tpcx.tamanho < tpCx.tamanho
      )
      setTiposCaixasBusca(tpCxBusca ?? [])
    }
  }, [idTipoCaixa])

  useEffect(() => {
    setValue('dados_pessoais', selectedBox.P)
    setValue('dados_pessoais_sensiveis', selectedBox.S)
    setValue('dados_criancas_adolescentes', selectedBox.C)
  }, [selectedBox, setValue])

  const handleCheckboxChange =
    (key: 'P' | 'S' | 'C') => (e: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = e.target.checked
      setSelectedBox((prev) => ({
        ...prev,
        [key]: isChecked
      }))
    }

  const handleClearCheckbox = () => {
    const defaultValues = {
      identificacao: '',
      conteudo: '',
      data_inicio: '',
      data_fim: '',
      sequencia_inicio: '',
      sequencia_fim: ''
    }

    Object.entries(defaultValues).forEach(([key, value]) =>
      setValue(key, value)
    )

    setIdTipoCaixa('')
    setIdContratoSelecionado('')
    setIdSetor('')
    setIdEmpresa('')

    if (selectedBox) {
      setSelectedBox({
        P: false,
        S: false,
        C: false
      })
    }
  }

  const handleRemoveDocumento = async (id: string) => {
    await Toast(
      removeDocumento(id),
      'REMOVED',
      'Documento excluído com sucesso!'
    )
  }

  const handleSubmitRemove = async () => {
    setShowDismiss(false)
    const tmpCaixa = exclude(currentBox as any, [
      'id_setor',
      'created_at',
      'updated_at',
      'estadoAtividade',
      'contrato',
      'documentos',
      'tipoCaixa',
      'endereco',
      'posicaoPrateleira'
    ]) as ICaixa
    const formData = {
      ...tmpCaixa,
      id_caixa_container: ''
    }
    await Toast(update(formData), 'UPDATED', 'Caixa removida com sucesso!')
    setCurrentBox(undefined)
  }

  const handleRemoveCaixa = (newCaixa: ICaixa) => {
    setCurrentBox(newCaixa)
    setShowDismiss(true)
  }

  const data_inicio = (data?.data_inicio ?? '').toLocaleString().slice(0, 10)
  const data_fim = (data?.data_fim ?? '').toLocaleString().slice(0, 10)

  const handleContratoChange = (id_contrato: string) => {
    setIdContratoSelecionado(id_contrato)
    setValue('id_contrato', id_contrato)

    setIdSetor('')

    const contrato = contratos?.find(
      (curContrato) => curContrato.id_contrato === id_contrato
    )

    setIdEmpresa(
      contrato?.empresa_contratante.id_empresa
        ? contrato?.empresa_contratante.id_empresa
        : ''
    )
    setCaixasLacradas(contrato?.lacrado)
    const newTiposCaixas = tiposCaixas?.filter((tpCaixa) =>
      contrato?.tipos_caixas_contratos.some(
        (tpCaixaContrato) =>
          tpCaixaContrato.tipo_caixa.id_tipo_caixa === tpCaixa.id_tipo_caixa
      )
    )

    setTiposCaixasFiltradas(newTiposCaixas ?? [])

    setValue('id_tipo_caixa', data.id_tipo_caixa ?? '')
    setIdTipoCaixa(data.id_tipo_caixa ?? '')
  }

  const handleSetorChange = (id_setor: string) => {
    setValue('id_setor', id_setor)
    setValue('id_tipo_documento', '')
    setIdSetor(id_setor)
  }

  const handleTipoCaixaChange = (id_tipo_caixa: string) => {
    setValue('id_tipo_caixa', id_tipo_caixa)
    setIdTipoCaixa(id_tipo_caixa)
  }

  const showConfirmDialog = (): Promise<boolean> => {
    return new Promise((resolve) => {
      setConfirmDecision(() => resolve)
      setShowConfirmRepeated(true)
    })
  }

  const handleDialogResponse = (response: boolean) => {
    if (confirmDecision) {
      confirmDecision(response)
      setConfirmDecision(null)
    }
    setShowConfirmRepeated(false)
  }

  const onSubmit = async (formData: ICaixa) => {
    const newFormData = exclude(formData, ['id_caixa'])
    if (
      idsSetores.has(newFormData.id_setor) &&
      identificacoes.has(newFormData.identificacao)
    ) {
      const confirmed = await showConfirmDialog()
      if (!confirmed) {
        return
      }
    }

    if (formMode === 'CREATE') {
      newFormData.estado = estadoCriacaoCaixa
      const response = await Toast(create(newFormData))
      setData(response)
    } else {
      const response = await Toast(
        update({
          ...newFormData,
          id_caixa: data.id_caixa
        }),
        'UPDATED'
      )
      setData(response)
    }

    if (closeForm) {
      setFormMode('LIST')
    } else if (formMode === 'CREATE') {
      setFormMode('EDIT')
    }
  }

  useEffect(() => {
    if (data?.id_contrato) {
      handleContratoChange(data?.id_contrato)
    }

    if (data?.id_setor) {
      setValue('id_setor', data?.id_setor)
      setIdSetor(data?.id_setor)
    }

    if (data?.id_tipo_documento) {
      setValue('id_tipo_documento', data?.id_tipo_documento)
    }
  }, [])

  const desabilitarSetor =
    !editavel ||
    (documentos && documentos.length > 0) ||
    (caixas && caixas.length > 0)

  return (
    <>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} mt={4}>
        <Stack gap={2}>
          <Input
            label="Identificação"
            {...register('identificacao')}
            defaultValue={data?.identificacao}
            message={errors.identificacao?.message}
            disabled={!editavel}
          />
          {!editavel && (
            <input
              type="hidden"
              {...register('identificacao')}
              value={data?.identificacao || ''}
            />
          )}

          <Input
            label="Conteúdo"
            multiline={true}
            minRows={3}
            {...register('conteudo')}
            defaultValue={data?.conteudo}
            message={errors.conteudo?.message}
            disabled={!editavel}
          />
          {!editavel && (
            <input
              type="hidden"
              {...register('conteudo')}
              value={data?.conteudo || ''}
            />
          )}

          <Stack gap={2} direction="row">
            <Input
              label="Data Inicial"
              type="date"
              {...register('data_inicio')}
              defaultValue={data_inicio}
              message={errors.data_inicio?.message}
              InputLabelProps={{
                shrink: true
              }}
              disabled={!editavel}
            />

            <Input
              label="Data Final"
              type="date"
              {...register('data_fim')}
              defaultValue={data_fim}
              message={errors.data_fim?.message}
              InputLabelProps={{
                shrink: true
              }}
              disabled={!editavel}
            />

            <Input
              label="Sequência Inicial"
              {...register('sequencia_inicio')}
              defaultValue={data?.sequencia_inicio}
              message={errors.sequencia_inicio?.message}
              disabled={!editavel}
            />
            <Input
              label="Sequência Final"
              {...register('sequencia_fim')}
              defaultValue={data?.sequencia_fim}
              message={errors.sequencia_fim?.message}
              disabled={!editavel}
            />
          </Stack>

          <Stack gap={2} direction="row" sx={{ marginBottom: '4px' }}>
            <ComponentGroup
              title="Privacidade"
              display={'flex'}
              flexDirection={'row'}
              tooltip={<PrivacyLegends />}
            >
              {/* <FormGroup
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  position: 'absolute',
                  top: 'calc(6px)'
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={data?.dados_pessoais}
                      {...register('dados_pessoais')}
                    />
                  }
                  label="P"
                  disabled={!editavel}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={data?.dados_pessoais_sensiveis}
                      {...register('dados_pessoais_sensiveis')}
                    />
                  }
                  label="S"
                  disabled={!editavel}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={data?.dados_criancas_adolescentes}
                      {...register('dados_criancas_adolescentes')}
                    />
                  }
                  label="C"
                  disabled={!editavel}
                />
              </FormGroup> */}
              <FormGroup
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  position: 'absolute',
                  top: 'calc(6px)'
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedBox.P}
                      onChange={handleCheckboxChange('P')}
                    />
                  }
                  label="P"
                  disabled={!editavel}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedBox.S}
                      onChange={handleCheckboxChange('S')}
                    />
                  }
                  label="S"
                  disabled={!editavel}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedBox.C}
                      onChange={handleCheckboxChange('C')}
                    />
                  }
                  label="C"
                  disabled={!editavel}
                />
              </FormGroup>
            </ComponentGroup>

            <Select
              label="Contrato*"
              placeholder="Selecione o Contrato"
              data={contratos}
              fields={['id_contrato', 'descricao']}
              {...(register('id_contrato'),
              {
                onChange: (event) => {
                  handleContratoChange(String(event.target.value))
                }
              })}
              value={idContratoSelecionado}
              defaultValue={data?.id_contrato ?? ''}
              message={errors.id_contrato?.message}
              disabled={!editavel}
            />
            <Select
              label="Setor*"
              placeholder="Selecione o Setor"
              data={listaSetores}
              fields={['id_setor', 'nome']}
              {...(register('id_setor'),
              {
                onChange: (event) => {
                  handleSetorChange(String(event.target.value))
                }
              })}
              defaultValue={data?.id_setor ?? ''}
              message={errors.id_setor?.message}
              disabled={desabilitarSetor}
            />
          </Stack>
          <Stack gap={2} direction="row" sx={{ marginBottom: '4px' }}>
            <Select
              label="Tipo de documento*"
              placeholder="Selecione o Tipo de documento"
              data={listaTiposDocumentos}
              fields={['id_tipo_documento', 'descricao']}
              {...register('id_tipo_documento')}
              defaultValue={data?.id_tipo_documento ?? ''}
              message={errors.id_tipo_documento?.message}
              disabled={
                formMode === 'EDIT' &&
                !hasAccess(
                  usuario?.permissoes,
                  Permissions.EDITAR_TIPO_DOCUMENTO_CAIXA
                )
              }
            />
            <Select
              label="Tipo de Caixas*"
              placeholder="Selecione o Tipo de Caixa"
              data={tiposCaixasFiltradas}
              fields={['id_tipo_caixa', 'descricao']}
              {...(register('id_tipo_caixa'),
              {
                onChange: (event) =>
                  handleTipoCaixaChange(String(event.target.value))
              })}
              value={idTipoCaixa}
              message={errors.id_tipo_caixa?.message}
              disabled={!editavel || tiposCaixasFiltradas.length === 0}
            />
          </Stack>
        </Stack>

        {tipoCaixa.acondiciona_caixas && (
          <ComponentGroup
            title={'Caixas'}
            marginBottom={'1rem'}
            marginTop={'1rem'}
          >
            <Box
              border={1}
              borderColor="lightgray"
              sx={{
                borderTopRightRadius: '6px',
                borderTopLeftRadius: '6px'
              }}
            >
              <Box
                bgcolor="#f2f2f2"
                px={2}
                py={0.5}
                sx={{
                  borderTopRightRadius: '6px',
                  borderTopLeftRadius: '6px'
                }}
              >
                <Typography
                  display="flex"
                  justifyContent="center"
                  variant="subtitle1"
                  color="#555"
                >
                  Caixa(s) incluída(s) nesta caixa
                </Typography>
              </Box>

              {caixas && caixas.length > 0 ? (
                <Box px={2} py={1}>
                  <Stack direction="row">
                    <Typography
                      fontWeight="600"
                      variant="button"
                      sx={{ width: '480px', color: '#737373' }}
                    >
                      Contrato
                    </Typography>
                    <Typography
                      fontWeight="600"
                      variant="button"
                      sx={{ width: '320px', color: '#737373' }}
                    >
                      Identificação
                    </Typography>
                    <Typography
                      fontWeight="600"
                      variant="button"
                      sx={{ width: '320px', color: '#737373' }}
                    >
                      Tipo de Caixa
                    </Typography>
                    <Typography />
                  </Stack>

                  <Divider sx={{ margin: 0, padding: 0 }} />

                  <Box
                    sx={{
                      overflow: 'auto',
                      maxHeight: 320
                    }}
                  >
                    {caixas?.map((cx) => (
                      <Stack
                        key={cx.id_caixa}
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Stack direction="row" alignItems="center">
                          <Typography sx={{ width: '480px' }}>
                            {cx.contrato?.descricao}
                          </Typography>
                          <Typography sx={{ width: '320px' }}>
                            {cx.identificacao}
                          </Typography>
                          <Typography sx={{ width: '320px' }}>
                            {cx.tipoCaixa?.descricao}
                          </Typography>
                        </Stack>

                        <Tooltip title="Excluir caixa">
                          <span>
                            <IconButton
                              sx={{ py: 0.5 }}
                              onClick={() => handleRemoveCaixa(cx)}
                              disabled={!editavel}
                            >
                              <DeleteIcon color="primary" fontSize="large" />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Stack>
                    ))}
                  </Box>
                </Box>
              ) : (
                <Typography
                  px={2}
                  py={2}
                  fontStyle="italic"
                  variant="subtitle2"
                >
                  Nenhuma caixa foi incluída até o momento
                </Typography>
              )}
            </Box>
            <Button
              variant="outlined"
              startIcon={<AddIcon sx={{ width: 24, height: 24 }} />}
              disabled={
                caixasLacradas ? true : formMode === 'CREATE' || !editavel
              }
              onClick={() => {
                setOpenModalCaixa(true)
              }}
              sx={{ width: '240px', fontWeight: 600 }}
            >
              Adicionar Caixa
            </Button>
          </ComponentGroup>
        )}

        {tipoCaixa.acondiciona_documentos && (
          <ComponentGroup
            title={'Documentos'}
            marginBottom={'1rem'}
            marginTop={'1rem'}
          >
            <Box
              border={1}
              borderColor="lightgray"
              sx={{
                borderTopRightRadius: '6px',
                borderTopLeftRadius: '6px'
              }}
            >
              <Box
                bgcolor="#f2f2f2"
                px={2}
                py={0.5}
                sx={{
                  borderTopRightRadius: '6px',
                  borderTopLeftRadius: '6px'
                }}
              >
                <Typography
                  display="flex"
                  justifyContent="center"
                  variant="subtitle1"
                  color="#555"
                >
                  Documento(s) incluído(s) nesta caixa
                </Typography>
              </Box>

              {documentos && documentos.length > 0 ? (
                <Box px={2} py={1}>
                  <Stack direction="row">
                    <Typography
                      fontWeight="600"
                      variant="button"
                      sx={{ width: '480px', color: '#737373' }}
                    >
                      Descrição
                    </Typography>
                    <Typography
                      fontWeight="600"
                      variant="button"
                      sx={{ width: '320px', color: '#737373' }}
                    >
                      Tipo de Documento
                    </Typography>
                    <Typography
                      fontWeight="600"
                      variant="button"
                      sx={{ width: '320px', color: '#737373' }}
                    >
                      Privacidade
                    </Typography>
                    <Typography />
                  </Stack>

                  <Divider sx={{ margin: 0, padding: 0 }} />

                  <Box
                    sx={{
                      overflow: 'auto',
                      maxHeight: 320
                    }}
                  >
                    {documentos?.map((doc) => (
                      <Stack
                        key={doc.id_documento}
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Stack direction="row" alignItems="center">
                          <Typography sx={{ width: '480px' }}>
                            {doc.descricao}
                          </Typography>
                          <Typography sx={{ width: '320px' }}>
                            {doc.tipo_documento?.descricao}
                          </Typography>

                          <Typography sx={{ width: '320px' }}>
                            {doc.dados_pessoais ? (
                              <span>
                                [<strong>{' P '}</strong>]
                              </span>
                            ) : null}
                            {doc.dados_pessoais_sensiveis ? (
                              <span>
                                [<strong>{' S '}</strong>]
                              </span>
                            ) : null}
                            {doc.dados_criancas_adolescentes ? (
                              <span>
                                [<strong>{' C '}</strong>]
                              </span>
                            ) : null}
                          </Typography>
                        </Stack>

                        <Tooltip title="Excluir documento">
                          <span>
                            <IconButton
                              sx={{ py: 0.5 }}
                              onClick={() =>
                                handleRemoveDocumento(doc.id_documento!)
                              }
                              disabled={!editavel}
                            >
                              <DeleteIcon color="primary" fontSize="large" />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Stack>
                    ))}
                  </Box>
                </Box>
              ) : (
                <Typography
                  px={2}
                  py={2}
                  fontStyle="italic"
                  variant="subtitle2"
                >
                  Nenhum documento foi incluído até o momento
                </Typography>
              )}
            </Box>

            <Stack direction="row" gap={3}>
              {hasAccess(
                usuario?.permissoes,
                Permissions.INCLUIR_DOCUMENTO
              ) && (
                <Button
                  variant="outlined"
                  startIcon={<AddIcon sx={{ width: 24, height: 24 }} />}
                  disabled={
                    caixasLacradas ? true : formMode === 'CREATE' || !editavel
                  }
                  onClick={() => {
                    setOpenModal(true)
                  }}
                  sx={{ width: '240px', fontWeight: 600 }}
                >
                  Incluir Documentos
                </Button>
              )}

              {hasAccess(
                usuario?.permissoes,
                Permissions.INCLUIR_DOCUMENTO_DIGITAL
              ) && (
                <Button
                  variant="outlined"
                  disabled={
                    // caixasLacradas ? true : formMode === 'CREATE' || !editavel
                    caixasLacradas ? true : formMode === 'CREATE'
                  }
                  startIcon={<UploadIcon sx={{ width: 24, height: 24 }} />}
                  onClick={() => {
                    setOpenModalDocDigital(true)
                  }}
                >
                  Incluir Documentos Digitais
                </Button>
              )}
            </Stack>
          </ComponentGroup>
        )}

        <FormControlLabel
          sx={{ py: 2 }}
          control={
            <Checkbox
              // defaultChecked={false}
              checked={acceptLgpd}
              onChange={() => setAcceptLgpd(!acceptLgpd)}
            />
          }
          label="Afirmo que todas informações prestadas estão corretas, em especial quanto à presença de dados pessoais, dados pessoais sensíveis e dados de crianças e adolescentes nos conteúdos informados, em conformidade com a Lei geral e Proteção de Dados (LGPD)"
          //disabled={!editavel}
        />

        <Stack direction="row" gap={2} mt={3}>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<CancelIcon sx={{ width: 24, height: 24 }} />}
            onClick={() => setFormMode('LIST')}
            sx={{ width: '110px', fontWeight: 600 }}
          >
            Cancelar
          </Button>

          {formMode === 'CREATE' && (
            <Button
              onClick={handleClearCheckbox}
              color="secondary"
              variant="outlined"
              startIcon={<Clear sx={{ width: 24, height: 24 }} />}
              style={{ fontWeight: 'bold' }}
              sx={{ width: '110px', fontWeight: 600 }}
            >
              Limpar
            </Button>
          )}

          <Button
            type="submit"
            variant="contained"
            disabled={!acceptLgpd || isLoadingCreate || isLoadingUpdate}
            startIcon={<SaveIcon sx={{ width: 24, height: 24 }} />}
            onClick={() => setCloseForm(true)}
            sx={{ width: '110px', fontWeight: 600 }}
          >
            Salvar
          </Button>

          <Divider
            orientation="vertical"
            flexItem
            light={false}
            variant="fullWidth"
          />

          <Button
            type="submit"
            variant="outlined"
            disabled={!acceptLgpd || isLoadingCreate || isLoadingUpdate}
            startIcon={<SaveIconAndContinue sx={{ width: 24, height: 24 }} />}
            onClick={() => setCloseForm(false)}
            sx={{ width: '200px', fontWeight: 600, px: 1 }}
          >
            Salvar e Continuar
          </Button>

          {/* <Button
            variant="outlined"
            startIcon={<TagIcon sx={{ width: 24, height: 24 }} />}
            disabled={formMode === 'CREATE'}
            sx={{ width: '200px', fontWeight: 600 }}
            onClick={() =>
              Etiqueta({
                data: {
                  id_caixa: data.id_caixa!,
                  conteudo: data.conteudo,
                  identificacao: data.identificacao,
                  cliente: 'cliente'
                }
              })
            }
          >
            Gerar Etiqueta
          </Button> */}
        </Stack>
      </Box>

      <FormDocumentos
        open={openModal}
        handleClose={handleCloseModal}
        idCaixa={data.id_caixa ?? ''}
        idContrato={data.id_contrato}
        idTipoDocumento={data.id_tipo_documento}
        idSetor={idSetor}
      />

      <FormDocumentoDigital
        open={openModalDocDigital}
        handleClose={handleCloseModalDocDigital}
        idCaixa={data.id_caixa ?? ''}
        idContrato={data.id_contrato}
        idTipoDocumento={data.id_tipo_documento}
      />

      <FormCaixa
        open={openModalCaixa}
        handleClose={handleCloseModalCaixa}
        idCaixa={data.id_caixa ?? ''}
        tamanho={tipoCaixa.tamanho}
        tiposCaixas={tiposCaixasBusca}
        caixas={caixas ?? []}
        isInventario={isInventario}
      />
      <Dialog
        handleAnswerNo={() => {
          setShowDismiss(false)
        }}
        handleAnswerYes={() => {
          handleSubmitRemove()
        }}
        messageText="Confirma a remoção da caixa?"
        title="Atenção"
        openDialog={showDismiss}
      />
      <Dialog
        handleAnswerNo={() => handleDialogResponse(false)}
        handleAnswerYes={() => handleDialogResponse(true)}
        messageText="Adicionar caixa com nome repetido?"
        title="Atenção"
        openDialog={showConfirmRepeated}
      />
    </>
  )
}
