import { ReactElement } from 'react'
import DifferenceIcon from '@mui/icons-material/Difference'
import ApartmentIcon from '@mui/icons-material/Apartment'
import EnterpraiseIcon from '@mui/icons-material/AccountBalance'
import TreeItem, {
  TreeItemContentProps,
  TreeItemProps,
  useTreeItem
} from '@mui/lab/TreeItem'
import clsx from 'clsx'
import { Box, Typography } from '@mui/material'

import {
  IArvoreDocumental,
  IArvoreDocumentalSetor,
  IArvoreDocumentalTipoDocumento
} from '../../services/arvore-documental/types'
import React from 'react'

import { IEmpresa } from '../../services/empresa/types'
import {
  getTextoArvoreDocumentalTipoDocumento,
  getTextoArvoreDocumentalSetor
} from '../../utils/getTextoTipoDocumento'

/**
 * Forward de dados para definição e propagação de propriedades customizada
 * (click).
 * O conteúdo do TreeItem passa a ser como o return, mas a expansão do item
 * só ocorre quando clica no botão de expansão.
 * Clicar no item propriamente dito dispara a função definida na propriedade
 * click, se estiver definida.
 */
const ClicableContent = React.forwardRef(function CustomContent(
  props: TreeItemContentProps & { click?: () => void },
  ref
) {
  const {
    classes,
    className,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
    click
  } = props

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection
  } = useTreeItem(nodeId)

  const icon = iconProp || expansionIcon || displayIcon

  const handleMouseDown = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    preventSelection(event)
  }

  const handleExpansionClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    handleExpansion(event)
  }

  const handleSelectionClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    handleSelection(event)
    if (click) {
      click()
    }
  }

  return (
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled
      })}
      onMouseDown={handleMouseDown}
      ref={ref as React.Ref<HTMLDivElement>}
    >
      <div onClick={handleExpansionClick} className={classes.iconContainer}>
        {icon}
      </div>
      <Typography
        onClick={handleSelectionClick}
        component="div"
        className={classes.label}
      >
        {label}
      </Typography>
    </div>
  )
})

/**
 * Componente que extend funcionalidades de TreeItem, adicionando a
 * propriedade click e só expande a sub árvore ao clicar no botão de expansão
 * @param props Propriedades TreeItemProps adiconada da propriedade opcional click
 * @returns nada
 */
const ClicableTreeItem = (
  props: TreeItemProps & { ContentProps: { click: () => void } }
) => <TreeItem ContentComponent={ClicableContent} {...props} />

/**
 * Função para exibição de texto com ícone (necessário, devido limitação do
 * TreeItem)
 * @param icon Compnente de ícone a ser exibido
 * @param text Texto a ser exibido
 * @returns Nó react contento ícone e texto
 */
function styledText(icon: any, text: string) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 1
      }}
    >
      {icon}
      <span>{text}</span>
    </Box>
  )
}

/**
 * Interface para componente de exibição de árvore documental
 * @prop data: Dados da árvore documental
 * @prop empresa: Dados da empresa da árvore
 * @prop setorClick: método a ser chamado quando um setor for clicado
 * @prop tipoDocumentoClick: método a ser chamado quando um tipo de documento
 * for clicado
 */
interface DocumentalTreeProps {
  empresa: IEmpresa
  data: IArvoreDocumental
  setorClick?: (setor: IArvoreDocumentalSetor) => void
  tipoDocumentoClick?: (
    tipoDocumento: IArvoreDocumentalTipoDocumento,
    id_branch: string
  ) => void
  empresaClick?: () => void
}

/**
 * Gera a árvore de componentes a ser exibida
 * @param arvore Conjunto de dados recuperados da HOOK
 * @returns Árvore de elementos react
 */
export function DocumentalTree({
  data,
  setorClick,
  tipoDocumentoClick,
  empresaClick,
  empresa
}: DocumentalTreeProps): ReactElement {
  const dig = (currentBranch: IArvoreDocumentalSetor) => {
    const tiposDocumentos =
      !!currentBranch.arvore_documento_tipo_documento &&
      currentBranch.arvore_documento_tipo_documento.length > 0
        ? currentBranch.arvore_documento_tipo_documento
            .sort((a, b) => {
              if (a.codigo && b.codigo) {
                return a.codigo > b.codigo
                  ? 1
                  : a.codigo < b.codigo
                  ? -1
                  : a.tipo_documento.descricao > b.tipo_documento.descricao
                  ? 1
                  : a.tipo_documento.descricao < b.tipo_documento.descricao
                  ? -1
                  : 0
              }
              if (!a.codigo && b.codigo) {
                return -1
              }
              if (a.codigo && !b.codigo) {
                return 1
              }
              return a.tipo_documento.descricao > b.tipo_documento.descricao
                ? 1
                : a.tipo_documento.descricao < b.tipo_documento.descricao
                ? -1
                : 0
            })
            .map((arvoreDocumentalTipoDocumento) => (
              <ClicableTreeItem
                key={
                  arvoreDocumentalTipoDocumento.id_arvore_documental_tipo_documento
                }
                nodeId={
                  arvoreDocumentalTipoDocumento.id_arvore_documental_tipo_documento
                }
                label={styledText(
                  <DifferenceIcon />,
                  getTextoArvoreDocumentalTipoDocumento(
                    arvoreDocumentalTipoDocumento
                  )
                )}
                ContentProps={{
                  click: () => {
                    if (tipoDocumentoClick)
                      tipoDocumentoClick(
                        arvoreDocumentalTipoDocumento,
                        currentBranch.id_arvore_documental
                      )
                  }
                }}
              />
            ))
        : []
    const subBranches =
      currentBranch.ramos.length > 0
        ? currentBranch.ramos
            .sort((a, b) => {
              if (a.codigo && b.codigo) {
                return a.codigo > b.codigo
                  ? 1
                  : a.codigo < b.codigo
                  ? -1
                  : a.setor.nome > b.setor.nome
                  ? 1
                  : a.setor.nome < b.setor.nome
                  ? -1
                  : 0
              }
              if (!a.codigo && b.codigo) {
                return -1
              }
              if (a.codigo && !b.codigo) {
                return 1
              }
              return a.setor.nome > b.setor.nome
                ? 1
                : a.setor.nome < b.setor.nome
                ? -1
                : 0
            })
            .map((ramo) => dig(ramo))
        : []
    if (tiposDocumentos.length === 0 && subBranches.length === 0) {
      return (
        <ClicableTreeItem
          nodeId={currentBranch.id_setor}
          label={styledText(
            <ApartmentIcon />,
            getTextoArvoreDocumentalSetor(currentBranch)
          )}
          key={currentBranch.id_setor}
          ContentProps={{
            click: () => {
              if (setorClick) setorClick(currentBranch)
            }
          }}
        />
      )
    }
    return (
      <ClicableTreeItem
        key={currentBranch.id_setor}
        nodeId={currentBranch.id_setor}
        label={styledText(
          <ApartmentIcon />,
          getTextoArvoreDocumentalSetor(currentBranch)
        )}
        ContentProps={{
          click: () => {
            if (setorClick) setorClick(currentBranch)
          }
        }}
      >
        {[...tiposDocumentos]}
        {[...subBranches]}
      </ClicableTreeItem>
    )
  }

  const itens = (
    <ClicableTreeItem
      key={data?.id_empresa ?? ''}
      nodeId={data?.id_empresa ?? ''}
      label={styledText(<EnterpraiseIcon />, empresa.razao_social)}
      ContentProps={{
        click: () => {
          if (empresaClick) {
            empresaClick()
          }
        }
      }}
    >
      {data?.ramos
        .sort((a, b) => {
          if (a.codigo && b.codigo) {
            return a.codigo > b.codigo
              ? 1
              : a.codigo < b.codigo
              ? -1
              : a.setor.nome > b.setor.nome
              ? 1
              : a.setor.nome < b.setor.nome
              ? -1
              : 0
          }
          if (!a.codigo && b.codigo) {
            return -1
          }
          if (a.codigo && !b.codigo) {
            return 1
          }
          return a.setor.nome > b.setor.nome
            ? 1
            : a.setor.nome < b.setor.nome
            ? -1
            : 0
        })
        .map((ramo) => dig(ramo))}
    </ClicableTreeItem>
  )
  return itens
}
