import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Stack, TextField } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { Select } from '../Form/Select'
import { useEmpresa } from '../../hooks/empresa/useEmpresa'
import { FormTitle } from '../Form/FormTitle'
import { useState } from 'react'
import { CSVDownload } from 'react-csv'
import { ReportViewModeFormat } from '../../services/relatorio/types'
import { useAuth } from '../../hooks/auth'
import { Input } from '../Form/Input'
import { isValidDate } from '../../utils/validations'
import msg from '../../utils/validationMessages'
import { ReportButtons } from './ReportButtons'
import { useSetor } from '../../hooks/setor/useSetor'
import { useContratoPermitido } from '../../hooks/contratoPermitido/useContratoPermitido'
import { useItemContrato } from '../../hooks/itemContrato/useItemContrato'
import { useCentroDocumentacao } from '../../hooks/centroDocumentacao/useCentroDocumentacao'
import { useEstadoAtividade } from '../../hooks/estadoAtividade/useEstadoAtividade'
import { useTipoTemporalidade } from '../../hooks/tipoTemporalidade/useTipoTemporalidade'
import { useTipoDocumento } from '../../hooks/tipoDocumento/useTipoDocumento'
import { useUsuario } from '../../hooks/usuario/useUsuario'
import { useSetorPermitido } from '../../hooks/setorPermitido/useSetorPermitido'
import { useTipoCaixa } from '../../hooks/tipoCaixa/useTipoCaixa'

type ReportFormat = 'pdf' | 'csv'

interface IEstadoInventario {
  key: string
  value: string
}

const estadosInventario: IEstadoInventario[] = [
  { key: 'TODOS', value: 'Todos' },
  { key: 'INVENTARIANDO', value: 'Inventariando' },
  { key: 'HOMOLOGADA', value: 'Homologada' }
]

interface ReportSchemaProps {
  temContrato?: boolean
  temServico?: boolean
  temEmpresa?: boolean
  isEmpresaObrigatorio?: boolean
  temCentroDocumentacao?: boolean
  isSetorObrigatorio?: boolean
  temAtraso?: boolean
}
function generateReportSchema({
  temContrato = false,
  temCentroDocumentacao = false,
  temServico = false,
  temEmpresa = true,
  isEmpresaObrigatorio = true,
  isSetorObrigatorio = false,
  temAtraso = false
}: ReportSchemaProps) {
  const contratoSchemaItens =
    temContrato && temServico
      ? {
          id_contrato: yup.string().required(msg.REQUIRED),
          id_item_contrato: yup.string()
        }
      : undefined
  const empresaSchemaItens = temEmpresa
    ? {
        id_empresa: isEmpresaObrigatorio
          ? yup.string().required(msg.REQUIRED)
          : yup.string(),
        id_setor: isSetorObrigatorio
          ? yup.string().required(msg.REQUIRED)
          : yup.string()
      }
    : undefined

  const centroDocumentacaoSchemaItens = temCentroDocumentacao
    ? { id_centrodocumentacao: yup.string() }
    : undefined
  const atrasoItem = temAtraso ? { atrasoDevolucao: yup.number() } : undefined
  const schema = yup
    .object({
      ...empresaSchemaItens,
      endereco: yup.string(),
      ...contratoSchemaItens,
      ...centroDocumentacaoSchemaItens,
      data_inicio: yup.date().transform((value) => {
        return isValidDate(value) ? value : undefined
      }),
      data_fim: yup
        .date()
        .transform((value) => {
          return isValidDate(value) ? value : undefined
        })
        .when(
          'data_inicio',
          (inicio, yup) => inicio && yup.min(inicio, msg.DATEINTERVAL)
        ),
      tipoConteudo: yup.string(),
      tipoPrazo: yup.string(),
      country: yup.string(),
      id_tipo_temporalidade: yup.string(),
      ...atrasoItem
    })
    .required()

  return schema
}

export interface IHandleReportSubmitProps {
  format: ReportFormat
  mode: 'open' | 'save'
  id_usuario: string
  filtros: IReportFilter
}

export interface IReportFormProps {
  title: string
  handleReportSubmit: (reportProps: IHandleReportSubmitProps) => any
  mostrarSetor?: boolean
  mostrarEndereco?: boolean
  mostrarEnderecoFinal?: boolean
  mostrarPeriodo?: boolean
  mostrarContrato?: boolean
  mostrarServico?: boolean
  mostrarEmpresa?: boolean
  mostrarCentroDocumentacao?: boolean
  mostrarEstado?: boolean
  mostrarEstadoInventario?: boolean
  mostrarTipoConteudo?: boolean
  mostrarAtraso?: boolean
  mostrarTemporalidade?: boolean
  mostrarTipoDocumento?: boolean
  mostrarUsuario?: boolean
  mostrarTipoCaixa?: boolean
  isEmpresasXSolution?: boolean
  isEmpresaObrigatorio?: boolean
  isSetorObrigatorio?: boolean
}

export interface IReportFilter {
  id_empresa?: string
  id_setor?: string
  endereco?: string
  data_inicio?: Date
  data_fim?: Date
  id_contrato?: string
  id_item_contrato?: string
  id_centrodocumentacao?: string
  id_tipo_documento?: string
  estado?: string
  estadoInventario?: string
  tipoConteudo?: string
  atrasoDevolucao?: number
  id_tipo_temporalidade?: string
  tipoPrazo?: string
  id_usuario?: string
  id_tipo_caixa?: string
  endereco_final?: string
}
export function ReportForm({
  title,
  handleReportSubmit,
  mostrarEndereco = false,
  mostrarContrato = false,
  mostrarServico = true,
  mostrarCentroDocumentacao = false,
  mostrarEstado = false,
  mostrarSetor = true,
  mostrarPeriodo = true,
  mostrarEmpresa = true,
  isEmpresaObrigatorio = true,
  isSetorObrigatorio = false,
  mostrarEstadoInventario = false,
  mostrarTipoConteudo = false,
  mostrarAtraso = false,
  mostrarTemporalidade = false,
  mostrarTipoDocumento = false,
  mostrarUsuario = false,
  mostrarTipoCaixa = false,
  mostrarEnderecoFinal = false,
  isEmpresasXSolution = false
}: IReportFormProps) {
  const [format, setFormat] = useState<'pdf' | 'csv'>('pdf')
  const [mode, setMode] = useState<'open' | 'save'>('open')
  const [isSubmitting, setIsSubmmiting] = useState(false)
  const [csvData, setCSVData] = useState<IReportFilter[]>([])
  const [idEmpresa, setIdEmpresa] = useState('')
  const [idSetor, setIdSetor] = useState('')
  const [idContrato, setIdContrato] = useState('')
  const [idItemContrato, setIdItemContrato] = useState('')

  const { data: listaUsuarios } = useUsuario.ListAllXSolution()
  const { data: centrosDocumentacao } =
    idEmpresa === '' || !isEmpresasXSolution
      ? useCentroDocumentacao.ListAll()
      : useCentroDocumentacao.ListAllByIdEmpresa(idEmpresa)
  const { data: listaSetores } = mostrarEmpresa
    ? useSetor.FindByEmpresa(idEmpresa)
    : useSetorPermitido.FindByUsuarioLogado()
  const { data: listaTipoDocumentos } =
    idSetor === ''
      ? useTipoDocumento.ListAll()
      : useTipoDocumento.ListAllByIdSetor(idSetor)
  const { data: userContratos } = useContratoPermitido.FindByUsuarioLogado()
  const { data: dataFindByContrato } = useItemContrato.ListByIdContratoAll(
    idContrato ?? '0'
  )
  const { data: listaEstadosAtividades } = useEstadoAtividade.ListAll()
  const listaEstados = listaEstadosAtividades?.map((estado) => {
    return {
      estado: estado
    }
  })

  const { data: dataTiposCaixa } = mostrarTipoCaixa
    ? useTipoCaixa.ListAll()
    : { data: [] }

  listaEstados?.sort((a, b) => {
    if (a.estado < b.estado) return -1
    if (a.estado > b.estado) return 1
    return 0
  })

  const { data: temporalidadeData } = useTipoTemporalidade.ListAll()

  const listaTemporalidade = temporalidadeData?.map((item) => ({
    id: item.id_tipo_temporalidade,
    descricao: `${item.tempo} - ${item.unidade} - ${item.fundamentacao}`
  }))

  const listaTipoConteudo = [
    { id: '0', nome: 'Todos' },
    { id: '1', nome: 'Caixa' },
    { id: '2', nome: 'Documento' }
  ]

  const listaTipoPrazo = [
    { id: '0', nome: 'Todos' },
    { id: '1', nome: 'Vencendo' },
    { id: '2', nome: 'Vencido' }
  ]

  const listServico = dataFindByContrato?.map(
    ({ id_item_contrato, servico: { nome } }) => ({
      id_item_contrato,
      nome
    })
  )

  function handleReportFormatAndMode({ format, mode }: ReportViewModeFormat) {
    setFormat(format)
    setMode(mode)
  }

  const formSchema = generateReportSchema({
    temContrato: mostrarContrato,
    temEmpresa: mostrarEmpresa,
    temCentroDocumentacao: mostrarCentroDocumentacao,
    isEmpresaObrigatorio,
    isSetorObrigatorio,
    temAtraso: mostrarAtraso
  })

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    resetField,
    reset,
    control
  } = useForm<IReportFilter>({
    resolver: yupResolver(formSchema)
  })

  function handleEmpresaChange(idEmpresa: string) {
    setIdEmpresa(idEmpresa)
    setIdSetor('')
    setValue('id_setor', '')
    setValue('id_centrodocumentacao', '')
    setValue('id_usuario', '')
  }

  function handleSetorChange(idSetor: string) {
    resetField('id_tipo_documento')
    setIdSetor(idSetor)
  }

  function handleSelectContratoChange(idContratoSelected: string) {
    setIdContrato(idContratoSelected)
    setIdItemContrato('')
    setValue('id_item_contrato', '')

    const contrato = userContratos?.find(
      (contrato) => contrato.id_contrato === idContratoSelected
    )

    if (contrato) {
      handleEmpresaChange(contrato.id_empresa_contratante)
    }
  }

  function handleSelectItemContratoChange(idItemContratoSelected: string) {
    setIdItemContrato(idItemContratoSelected)
  }

  const { data: empresas } = isEmpresasXSolution
    ? useEmpresa.ListAllXSolution()
    : useEmpresa.ListAll()
  const { user } = useAuth()

  const localData = localStorage.getItem('@easydoc:user')
  let userData: any
  if (localData) {
    userData = JSON.parse(localData)
  }

  const userEmpresas = empresas?.filter((empresa) => {
    return userContratos?.some((contrato) => {
      return (
        empresa.id_empresa === userData.pessoa.setor.empresa.id_empresa ||
        empresa.id_empresa === contrato.id_empresa_contratante
      )
    })
  })

  const userEmpresaUnica = empresas?.filter((empresa) => {
    return empresa.id_empresa === userData.pessoa.setor.empresa.id_empresa
  })

  const onSubmit = async ({
    data_fim,
    data_inicio,
    id_centrodocumentacao,
    endereco,
    estado,
    estadoInventario = 'TODOS',
    tipoConteudo = '0',
    atrasoDevolucao = 0,
    id_tipo_temporalidade,
    tipoPrazo = '0',
    id_tipo_documento,
    id_usuario,
    id_tipo_caixa,
    endereco_final
  }: IReportFilter) => {
    try {
      setCSVData([])
      setIsSubmmiting(true)

      const reportData = await handleReportSubmit({
        format,
        mode,
        id_usuario: user.id_usuario,
        filtros: {
          id_empresa: idEmpresa && idEmpresa !== '' ? idEmpresa : undefined,
          id_setor: idSetor && idSetor !== '' ? idSetor : undefined,
          id_contrato: idContrato && idContrato !== '' ? idContrato : undefined,
          id_item_contrato: idItemContrato,
          id_centrodocumentacao: id_centrodocumentacao
            ? id_centrodocumentacao
            : undefined,
          estado: estado ? estado : undefined,
          estadoInventario,
          endereco,
          endereco_final,
          data_fim,
          data_inicio,
          tipoConteudo,
          atrasoDevolucao,
          id_tipo_temporalidade,
          id_tipo_caixa:
            id_tipo_caixa && id_tipo_caixa !== '' ? id_tipo_caixa : undefined,
          tipoPrazo,
          id_tipo_documento:
            id_tipo_documento && id_tipo_documento !== ''
              ? id_tipo_documento
              : undefined,
          id_usuario: id_usuario && id_usuario !== '' ? id_usuario : undefined
        }
      })
      if (format === 'csv') {
        setCSVData(reportData)
      }
    } catch (error) {
      setIsSubmmiting(false)
    } finally {
      setIsSubmmiting(false)
    }
  }

  const handleResetFields = () => {
    setIdEmpresa('')
    setIdSetor('')
    setIdContrato('')
    setIdItemContrato('')
    reset({
      tipoConteudo: '0',
      id_empresa: '',
      id_setor: '',
      id_centrodocumentacao: '',
      id_contrato: undefined,
      id_item_contrato: '',
      id_tipo_caixa: '',
      id_tipo_documento: '',
      id_usuario: '',
      id_tipo_temporalidade: '',
      endereco_final: '',
      endereco: '',
      estado: '',
      data_inicio: undefined,
      data_fim: undefined
    })
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <FormTitle title={title} />
      {mostrarContrato && (
        <Stack
          sx={{
            flexDirection: {
              sm: 'column',
              md: 'row'
            }
          }}
          mt={2}
          mb={2}
          gap={3}
        >
          <Controller
            name="id_contrato"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Select
                label={`Contrato ${
                  mostrarContrato && mostrarServico ? '*' : ''
                }`}
                placeholder="Selecione o Contrato"
                data={userContratos}
                message={errors.id_contrato?.message}
                fields={['id_contrato', 'descricao']}
                {...field}
                value={field.value || ''}
                onChange={(event) => {
                  handleSelectContratoChange(String(event.target.value))
                  field.onChange(event.target.value)
                }}
              />
            )}
          />
          {mostrarServico && (
            <Select
              label="Serviço"
              placeholder="Selecione o Serviço"
              data={listServico}
              fields={['id_item_contrato', 'nome']}
              {...register('id_item_contrato')}
              onChange={(e) =>
                handleSelectItemContratoChange(String(e.target.value))
              }
              value={idItemContrato}
              message={errors.id_item_contrato?.message}
            />
          )}
        </Stack>
      )}
      {(mostrarEmpresa || mostrarSetor) && (
        <Stack
          sx={{
            flexDirection: {
              sm: 'column',
              md: 'row'
            }
          }}
          mb={2}
          mt={mostrarContrato ? 0 : 2}
          gap={3}
        >
          {mostrarEmpresa && (
            <Controller
              name="id_empresa"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  label={`Empresa${isEmpresaObrigatorio ? '*' : ''}`}
                  placeholder="Selecione a Empresa"
                  data={
                    userEmpresas?.length === 0 ? userEmpresaUnica : userEmpresas
                  }
                  fields={['id_empresa', 'nome']}
                  message={errors.id_empresa?.message}
                  {...field}
                  value={field.value || ''}
                  onChange={(event) => {
                    handleEmpresaChange(String(event.target.value))
                    field.onChange(event.target.value)
                  }}
                />
              )}
            />
          )}
          {mostrarSetor && (
            <Select
              label={`Setor${isSetorObrigatorio ? '*' : ''}`}
              placeholder="Selecione o Setor"
              data={listaSetores}
              fields={[
                'id_setor',
                `${mostrarEmpresa ? 'nome' : 'nome_com_empresa'}`
              ]}
              {...register('id_setor')}
              value={idSetor}
              onChange={(e) => handleSetorChange(String(e.target.value))}
              message={errors.id_setor?.message}
            />
          )}
        </Stack>
      )}
      {mostrarCentroDocumentacao && (
        <Stack
          direction="row"
          mt={mostrarEmpresa || mostrarContrato ? 0 : 2}
          gap={3}
          mb={2}
        >
          <Controller
            name="id_centrodocumentacao"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Select
                label="Centro Documentação"
                placeholder="Selecione o Centro de Documentação"
                data={centrosDocumentacao}
                fields={['id_centrodocumentacao', 'nome']}
                message={errors.id_centrodocumentacao?.message}
                {...field}
                value={field.value || ''}
                onChange={(event) => {
                  field.onChange(event.target.value)
                }}
              />
            )}
          />
        </Stack>
      )}

      {(mostrarTipoDocumento || mostrarUsuario) && (
        <Stack
          sx={{
            flexDirection: {
              sm: 'column',
              md: 'row'
            }
          }}
          mb={2}
          gap={3}
        >
          {mostrarTipoDocumento && (
            <Controller
              name="id_tipo_documento"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  label={'Tipo Documento'}
                  placeholder="Selecione o Tipo de Documento"
                  data={listaTipoDocumentos}
                  fields={['id_tipo_documento', 'descricao']}
                  message={errors.id_tipo_documento?.message}
                  {...field}
                  value={field.value || ''}
                  onChange={(event) => {
                    field.onChange(event.target.value)
                  }}
                />
              )}
            />
          )}

          {mostrarUsuario && (
            <Controller
              name="id_usuario"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  label={'Usuário'}
                  placeholder="Selecione o Usuário"
                  data={listaUsuarios}
                  fields={['id_usuario', 'username']}
                  message={errors.id_usuario?.message}
                  {...field}
                  value={field.value || ''}
                  onChange={(event) => {
                    field.onChange(event.target.value)
                  }}
                />
              )}
            />
          )}
        </Stack>
      )}

      {mostrarPeriodo && (
        <Stack
          sx={{
            flexDirection: {
              sm: 'column',
              md: 'row'
            }
          }}
          mb={2}
          gap={3}
        >
          <Controller
            name="data_inicio"
            control={control}
            render={({ field }) => (
              <Input
                label="Data Início"
                type="date"
                {...field}
                onChange={(event) => {
                  field.onChange(event.target.value)
                }}
                InputLabelProps={{
                  shrink: true
                }}
                value={field.value || ''}
                message={errors.data_inicio?.message}
              />
            )}
          />

          <Controller
            name="data_fim"
            control={control}
            render={({ field }) => (
              <Input
                label="Data Fim"
                type="date"
                {...field}
                onChange={(event) => {
                  field.onChange(event.target.value)
                }}
                InputLabelProps={{
                  shrink: true
                }}
                value={field.value || ''}
                message={errors.data_fim?.message}
              />
            )}
          />
        </Stack>
      )}

      {mostrarTipoConteudo && (
        <Stack
          sx={{
            flexDirection: {
              sm: 'column',
              md: 'row'
            }
          }}
          mb={2}
          gap={3}
        >
          <Controller
            name="tipoConteudo"
            control={control}
            defaultValue="0"
            render={({ field }) => (
              <Select
                label="Tipo de Conteúdo"
                placeholder="Selecione o Tipo de Conteúdo"
                message={errors.tipoConteudo?.message}
                data={listaTipoConteudo}
                fields={['id', 'nome']}
                {...field}
                value={field.value || ''}
                onChange={(event) => field.onChange(event.target.value)}
              />
            )}
          />

          {mostrarAtraso && (
            <Controller
              name="tipoConteudo"
              control={control}
              defaultValue="0"
              render={({ field }) => (
                <TextField
                  type={'number'}
                  {...register('atrasoDevolucao')}
                  InputProps={{ inputProps: { min: 0 } }}
                  label="Atraso (dias)"
                  size="small"
                  {...field}
                  value={field.value || ''}
                  onChange={(event) => field.onChange(event.target.value)}
                />
              )}
            />
          )}

          {mostrarTemporalidade && (
            <>
              <Controller
                name="tipoPrazo"
                control={control}
                defaultValue="0"
                render={({ field }) => (
                  <Select
                    label="Prazo"
                    placeholder="Selecione o Prazo"
                    message={errors.tipoPrazo?.message}
                    data={listaTipoPrazo}
                    fields={['id', 'nome']}
                    {...field}
                    value={field.value || ''}
                    onChange={(event) => field.onChange(event.target.value)}
                  />
                )}
              />

              <Controller
                name="id_tipo_temporalidade"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    label="Temporalidade"
                    placeholder="Selecione a Temporalidade"
                    message={errors.id_tipo_temporalidade?.message}
                    data={listaTemporalidade}
                    fields={['id', 'descricao', 'fundamentacao']}
                    {...field}
                    value={field.value || ''}
                    onChange={(event) => field.onChange(event.target.value)}
                  />
                )}
              />
            </>
          )}
        </Stack>
      )}
      {mostrarTipoCaixa && (
        <Stack
          sx={{
            flexDirection: {
              sm: 'column',
              md: 'row'
            }
          }}
          gap={3}
          mb={2}
        >
          <Controller
            name="id_tipo_caixa"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Select
                label="Tipo Caixa"
                placeholder="Selecione o Tipo de Caixa"
                data={dataTiposCaixa}
                fields={['id_tipo_caixa', 'descricao']}
                message={errors.id_tipo_caixa?.message}
                {...field}
                value={field.value || ''}
                onChange={(event) => field.onChange(event.target.value)}
              />
            )}
          />
        </Stack>
      )}
      {(mostrarEndereco || mostrarEstado) && (
        <Stack
          sx={{
            flexDirection: {
              sm: 'column',
              md: 'row'
            }
          }}
          gap={3}
          mb={2}
        >
          {mostrarEstado && (
            <Controller
              name="estado"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  label="Estado"
                  placeholder="Selecione o Estado"
                  data={listaEstados}
                  fields={['estado', 'estado']}
                  message={errors.estado?.message}
                  {...field}
                  value={field.value || ''}
                  onChange={(event) => field.onChange(event.target.value)}
                />
              )}
            />
          )}
          {mostrarEndereco && (
            <Input
              label={'Endereço'}
              fullWidth={true}
              {...register('endereco')}
              message={errors.endereco?.message}
            />
          )}

          {mostrarEnderecoFinal && (
            <Input
              label={'Endereço Final'}
              fullWidth={true}
              {...register('endereco_final')}
              message={errors.endereco_final?.message}
            />
          )}
        </Stack>
      )}

      {mostrarEstadoInventario && (
        <Stack
          sx={{
            flexDirection: {
              sm: 'column',
              md: 'row'
            }
          }}
          gap={3}
          mb={2}
        >
          <Controller
            name="estadoInventario"
            control={control}
            defaultValue="TODOS"
            render={({ field }) => (
              <Select
                label="Estado"
                placeholder="Selecione o Estado"
                data={estadosInventario}
                fields={['key', 'value']}
                message={errors.estadoInventario?.message}
                {...field}
                value={field.value || ''}
                onChange={(event) => field.onChange(event.target.value)}
              />
            )}
          />
        </Stack>
      )}

      <ReportButtons
        mode={mode}
        format={format}
        isSubmitting={isSubmitting}
        handleReportFormatAndMode={handleReportFormatAndMode}
        handleResetFields={handleResetFields}
      />
      {csvData.length > 0 && <CSVDownload data={csvData} target="_self" />}
    </Box>
  )
}
