import { useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import BackspaceIcon from '@mui/icons-material/Backspace'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'

import TreeView from '@mui/lab/TreeView'
import SaveIcon from '@mui/icons-material/CheckCircleOutlined'
import CancelIcon from '@mui/icons-material/DoDisturbAltOutlined'

import useToast from '../../../hooks/toast/useToast'
import { IEmpresa } from '../../../services/empresa/types'
import { FormMode } from '../../../types/formMode'
import { ComponentGroup } from '../../../components/ComponentGroup'
import { Loading } from '../../../components/Loading'
import { useTipoDocumento } from '../../../hooks/tipoDocumento/useTipoDocumento'
import { useArvoreDocumental } from '../../../hooks/arvoreDcoumental/useArvoreDocumental'
import {
  IArvoreDocumentalSetor,
  IArvoreDocumentalTipoDocumento
} from '../../../services/arvore-documental/types'
import { useSetor } from '../../../hooks/setor/useSetor'
import { ISetor } from '../../../services/setor/types'
import { DocumentalTree } from '../../../components/DocumentalTree'
import { ITipoDocumento } from '../../../services/tipoDocumento/types'
import { getTextoArvoreDocumentalTipoDocumento } from '../../../utils/getTextoTipoDocumento'

const arvoreDocumentalTipoDocumentoBlank: IArvoreDocumentalTipoDocumento = {
  codigo: '',
  id_arvore_documental_tipo_documento: '',
  tipo_documento: {
    assunto: '',
    descricao: '',
    codigo: '',
    guarda_digital: false,
    guarda_fisica: false,
    tempo_guarda: 'PERMANENTE',
    id_destinacao_documento: '',
    id_tipo_documento: '',
    id_tipo_temporalidade: ''
  }
}

interface IFormArvoreDocumental {
  data: IEmpresa
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

export function Form({ data, setFormMode }: IFormArvoreDocumental) {
  const { Toast } = useToast()
  const { mutateAsync: update } = useArvoreDocumental.Update()

  //Estados (INÍCIO)
  //Lista que não mudam (INÍCIO)
  const { data: arvoreDocumental, isLoading } =
    useArvoreDocumental.FindByIdEmpresa(data.id_empresa ?? '')

  const { data: listaTiposDocumentos, isLoading: isLoadingTiposDocumentos } =
    useTipoDocumento.ListAll() ?? []
  const { data: listaSetores, isLoading: isLoadingListaSetores } =
    useSetor.ListAll()
  //Lista que não mudam (FIM)

  //Itens que mudam de acordo o item selecionado (INÍCIO)
  const [visao, setVisao] = useState<number>(0)

  const [
    listaTiposDocumentosDisponiveisOriginal,
    setListaTiposDocumentosDisponiveisOriginal
  ] = useState<ITipoDocumento[]>([])
  const [listaTiposDocumentosOriginal, setListaTiposDocumentosOriginal] =
    useState<IArvoreDocumentalTipoDocumento[]>([])
  const [setorPaiOriginal, setSetorPaiOriginal] = useState<string>('')
  const [listaSetoresPais, setListaSetoresPais] = useState<ISetor[]>()
  //Itens que mudam de acordo o item selecionado (FIM)

  //Itens que mudam, de acordo com a interação do usuário (INÍCIO)
  const [codigo, setCodigo] = useState<string>('')
  const [codigoOriginal, setCodigoOriginal] = useState<string>('')
  const [listaTiposDocumentosSetor, setListaTiposDocumentosSetor] = useState<
    IArvoreDocumentalTipoDocumento[]
  >([])
  const [listaTiposDocumentosDisponivel, setListaTiposDocumentosDisponivel] =
    useState<ITipoDocumento[]>([])
  const [setorPaiAtual, setSetorPaiAtual] = useState<string>('')
  const [podeSalvar, setPodeSalvar] = useState<boolean>(false)
  const [idArvoreDocumentalAtual, setArvoreDocumentalAtual] =
    useState<string>('')
  const [arvoreDocumentalTipoDocumento, setArvoreDocumentalTipoDocumento] =
    useState<IArvoreDocumentalTipoDocumento>(arvoreDocumentalTipoDocumentoBlank)
  //Itens que mudam, de acordo com a interação do usuário (FIM)

  //Estados (FIM)

  //Função recursivas (INÍCIO)
  /**
   * Recupera o id do setor pai do setor atual
   * @param target id do setor que se deseja o pai
   * @param data conjunto de ramos atuais
   * @param current id do setor atual
   * @returns objeto contendo flag se o pai foi encontrado e o id do pai (caso
   * positivo)
   */
  const getSetorPai = (
    target: string,
    data: IArvoreDocumentalSetor[],
    current: string
  ): { encontrado: boolean; id: string } => {
    const found = data.some((branch) => branch.id_setor === target)
    if (found) {
      return {
        encontrado: true,
        id: current
      }
    } else {
      for (const branch of data) {
        const retrievedData = getSetorPai(target, branch.ramos, branch.id_setor)
        if (retrievedData.encontrado) {
          return retrievedData
        }
      }
      return {
        encontrado: false,
        id: ''
      }
    }
  }

  /**
   * Identifica quais setores podem ser definidos como setor pai de setor
   * @param data ramos da árvore de setores
   * @param target setor alvo
   * @returns lista de possíveis pais para o setor
   */
  const setoresValidos = (
    data: IArvoreDocumentalSetor[],
    target: string
  ): IArvoreDocumentalSetor[] => {
    if (target === '') return []

    const subSet = data.filter(
      (localData) => localData.id_arvore_documental !== target
    )

    let returnValue: IArvoreDocumentalSetor[] = []

    for (const localData of subSet) {
      if (localData.ramos.length > 0) {
        returnValue = [
          ...returnValue,
          localData,
          ...setoresValidos(localData.ramos, target)
        ]
      } else {
        returnValue = [...returnValue, localData]
      }
    }

    return returnValue
  }
  //Função recursivas (FIM)

  //Funções de validação (INÍCIO)
  /**
   * Mudar o estado da flag se pode salvar os dados atuais, baseando na
   * validação se o setor pai foi modificao ou se a lista de tipos de documentos
   * foi modificada
   * @param idSetorPaiAtual id ramo do setor pai a ser validado
   * @param listaTiposDocumentos lista de tipos de documentos a ser validada
   * @returns
   */
  const validarPodeSalvar = (
    idSetorPaiAtual: string,
    listaTiposDocumentos: IArvoreDocumentalTipoDocumento[]
  ): boolean => {
    const setorPai = idSetorPaiAtual !== setorPaiOriginal

    const listaTPD =
      listaTiposDocumentos.length !== listaTiposDocumentosOriginal.length ||
      !listaTiposDocumentosOriginal.every(
        (tpDocumentoOriginal) =>
          !!listaTiposDocumentos.find(
            (tpDocumento) =>
              tpDocumento.tipo_documento.id_tipo_documento ===
              tpDocumentoOriginal.tipo_documento.id_tipo_documento
          )
      )

    const cdg = codigo !== codigoOriginal
    return setorPai || listaTPD || cdg
  }
  //Funções de validação (FIM)

  //Funções de interação com a interface (INÍCIO)
  const handleSetorClick = (setor: IArvoreDocumentalSetor) => {
    const listaSetoresValidos = setoresValidos(
      arvoreDocumental?.ramos ?? [],
      setor.id_arvore_documental
    )
    setArvoreDocumentalAtual(setor.id_arvore_documental)
    const newListaSetoresPais = listaSetores?.filter((setorListado) =>
      listaSetoresValidos.some((setorLocal) => {
        return setorLocal.id_setor === setorListado.id_setor
      })
    )
    const { encontrado, id } = getSetorPai(
      setor.id_setor,
      arvoreDocumental?.ramos ?? [],
      ''
    )
    setSetorPaiOriginal(encontrado ? id : '')
    setSetorPaiAtual(encontrado ? id : '')
    setListaSetoresPais(newListaSetoresPais)
    setListaTiposDocumentosSetor(setor.arvore_documento_tipo_documento)
    setListaTiposDocumentosOriginal(setor.arvore_documento_tipo_documento)
    const newListaTiposDocumentosDisponivel =
      listaTiposDocumentos
        ?.filter(
          (tpDocumento) =>
            !setor.arvore_documento_tipo_documento.some(
              (tpDocumentoSetor) =>
                tpDocumentoSetor.tipo_documento.id_tipo_documento ===
                tpDocumento.id_tipo_documento
            )
        )
        .map((tipoDocumento) => ({
          ...tipoDocumento,
          id_tipo_documento: tipoDocumento.id_tipo_documento ?? ''
        }))
        .sort((a, b) =>
          a.codigo > b.codigo ? 1 : a.codigo < b.codigo ? -1 : 0
        ) ?? []
    setListaTiposDocumentosDisponiveisOriginal(
      newListaTiposDocumentosDisponivel
    )
    setListaTiposDocumentosDisponivel(newListaTiposDocumentosDisponivel)
    setPodeSalvar(false)
    setCodigo(setor.codigo ?? '')
    setCodigoOriginal(setor.codigo ?? '')
    setVisao(1)
  }

  const handleEmpresaClick = () => {
    setArvoreDocumentalAtual('')
    setSetorPaiAtual('')
    setListaSetoresPais([])
    setListaTiposDocumentosSetor([])
    setListaTiposDocumentosOriginal([])
    setListaTiposDocumentosDisponiveisOriginal([])
    setListaTiposDocumentosDisponivel([])
    setPodeSalvar(false)
    setVisao(0)
  }

  const handleTipoDocumentoClick = (
    tipoDocumento: IArvoreDocumentalTipoDocumento,
    id_branch: string
  ) => {
    setArvoreDocumentalTipoDocumento(tipoDocumento)
    setArvoreDocumentalAtual(id_branch)
    setSetorPaiAtual('')
    setListaSetoresPais([])
    setListaTiposDocumentosSetor([])
    setListaTiposDocumentosOriginal([])
    setListaTiposDocumentosDisponiveisOriginal([])
    setListaTiposDocumentosDisponivel([])
    setPodeSalvar(false)
    setCodigo(tipoDocumento.codigo ?? '')
    setCodigoOriginal(tipoDocumento.codigo ?? '')
    setVisao(2)
  }

  const handleSetorPaiChange = (id: string) => {
    setSetorPaiAtual(id)
    setPodeSalvar(validarPodeSalvar(id, listaTiposDocumentosSetor))
  }

  const handleCodigoChange = (texto: string) => {
    setCodigo(texto)
    setPodeSalvar(texto !== codigoOriginal)
  }

  const handleTipoDocumentoAdicionarClick = (
    tipoDocumento: IArvoreDocumentalTipoDocumento,
    adicionar: boolean
  ) => {
    let newListaTipoDocumentoSetor: IArvoreDocumentalTipoDocumento[] = []
    let newListaTipoDocumentoDisponivel: ITipoDocumento[] = []
    if (adicionar) {
      newListaTipoDocumentoSetor = [...listaTiposDocumentosSetor, tipoDocumento]
      newListaTipoDocumentoDisponivel = listaTiposDocumentosDisponivel.filter(
        (tipoDocumentoAntigo) =>
          tipoDocumento.tipo_documento.id_tipo_documento !==
          tipoDocumentoAntigo.id_tipo_documento
      )
    } else {
      newListaTipoDocumentoSetor = listaTiposDocumentosSetor.filter(
        (tipoDocumentoAntigo) =>
          tipoDocumento.tipo_documento.id_tipo_documento !==
          tipoDocumentoAntigo.tipo_documento.id_tipo_documento
      )
      newListaTipoDocumentoDisponivel = [
        ...listaTiposDocumentosDisponivel,
        tipoDocumento.tipo_documento
      ]
    }

    setListaTiposDocumentosSetor(
      newListaTipoDocumentoSetor.sort((a, b) =>
        a.tipo_documento.codigo > b.tipo_documento.codigo
          ? 1
          : a.tipo_documento.codigo < b.tipo_documento.codigo
          ? -1
          : 0
      )
    )
    setListaTiposDocumentosDisponivel(
      newListaTipoDocumentoDisponivel.sort((a, b) =>
        a.codigo > b.codigo ? 1 : a.codigo < b.codigo ? -1 : 0
      )
    )

    setPodeSalvar(validarPodeSalvar(setorPaiAtual, newListaTipoDocumentoSetor))
  }

  const handleCancelarClick = () => {
    setSetorPaiAtual(setorPaiOriginal)
    setListaTiposDocumentosDisponivel(listaTiposDocumentosDisponiveisOriginal)
    setListaTiposDocumentosSetor(listaTiposDocumentosOriginal)
    setCodigo(codigoOriginal)
    setPodeSalvar(false)
  }

  const handleTipoDocumentoSalvarClick = async () => {
    const findArvoreDocumental = (
      target: string,
      current: IArvoreDocumentalSetor
    ): IArvoreDocumentalSetor | undefined => {
      if (current.id_arvore_documental === target) {
        return current
      }
      if (current.ramos.length === 0) {
        return
      }
      for (const branch of current.ramos) {
        const existsBranch = findArvoreDocumental(target, branch)
        if (existsBranch) {
          return existsBranch
        }
      }
    }

    if (arvoreDocumental?.ramos) {
      let branchDocumental: IArvoreDocumentalSetor | undefined
      for (const branch of arvoreDocumental.ramos) {
        if (!branchDocumental) {
          branchDocumental = findArvoreDocumental(
            idArvoreDocumentalAtual,
            branch
          )
        }
      }
      if (branchDocumental) {
        const indexTipoDocumento =
          branchDocumental.arvore_documento_tipo_documento.findIndex(
            (tipoDocumento) =>
              tipoDocumento.id_arvore_documental_tipo_documento ===
              arvoreDocumentalTipoDocumento.id_arvore_documental_tipo_documento
          )
        const tmpArvoreDocumentalTipoDocumental = {
          ...branchDocumental.arvore_documento_tipo_documento.at(
            indexTipoDocumento
          ),
          codigo
        }

        const newArvoreDocumentalTipoDocumentoList = [
          ...branchDocumental.arvore_documento_tipo_documento.filter(
            (tipoDocumento) =>
              tipoDocumento.id_arvore_documental_tipo_documento !==
              arvoreDocumentalTipoDocumento.id_arvore_documental_tipo_documento
          ),
          tmpArvoreDocumentalTipoDocumental
        ]
        const formData = {
          codigo: branchDocumental.codigo,
          /*id_setor_pai: branchDocumental.id_setor ?? '',*/
          id_arvore_documental: branchDocumental.id_arvore_documental,
          arvore_documental_tipo_documento:
            newArvoreDocumentalTipoDocumentoList.map((tipoDocumento) => ({
              id_tipo_documento:
                tipoDocumento.tipo_documento?.id_tipo_documento ?? '',
              codigo: tipoDocumento.codigo
            }))
        }
        await Toast(update(formData))
        setSetorPaiOriginal(setorPaiAtual)
        setListaTiposDocumentosOriginal(listaTiposDocumentosSetor)
        setListaTiposDocumentosDisponiveisOriginal(
          listaTiposDocumentosDisponivel
        )
        setCodigoOriginal(codigo)
        setPodeSalvar(false)
      }
    }
  }

  const handleSalvarClick = async () => {
    const newFormData = {
      codigo,
      id_setor_pai: setorPaiAtual,
      arvore_documental_tipo_documento: listaTiposDocumentosSetor.map(
        (tipoDocumento) => ({
          id_tipo_documento:
            tipoDocumento.tipo_documento.id_tipo_documento ?? '',
          codigo: tipoDocumento.codigo
        })
      ),
      id_arvore_documental: idArvoreDocumentalAtual
    }
    await Toast(update(newFormData))
    setSetorPaiOriginal(setorPaiAtual)
    setListaTiposDocumentosOriginal(listaTiposDocumentosSetor)
    setListaTiposDocumentosDisponiveisOriginal(listaTiposDocumentosDisponivel)
    setCodigoOriginal(codigo)
    setPodeSalvar(false)
  }

  const showComponentsEmpresa = () => {
    return (
      <ComponentGroup title="Empresa" width={'50%'}>
        <Stack>
          <label>{data.razao_social}</label>
        </Stack>
      </ComponentGroup>
    )
  }

  const showComponentsTipoDocumento = () => {
    return (
      <ComponentGroup title="Tipo de Documento" width={'50%'}>
        <Stack>
          <FormControl>
            <TextField
              label={'Código'}
              onChange={(element) =>
                handleCodigoChange(String(element.target.value))
              }
              value={codigo}
            />
          </FormControl>
        </Stack>
        <Stack direction="row" gap={2} mt={3}>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<CancelIcon />}
            onClick={() => handleCancelarClick()}
            disabled={!podeSalvar}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={() => handleTipoDocumentoSalvarClick()}
            disabled={!podeSalvar}
          >
            Salvar
          </Button>
        </Stack>
      </ComponentGroup>
    )
  }

  const showComponentsSetor = () => {
    return (
      <ComponentGroup title="Setor" width={'50%'}>
        <Stack gap={2}>
          <TextField
            label={'Código'}
            onChange={(element) =>
              handleCodigoChange(String(element.target.value))
            }
            value={codigo}
          />
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="label-setor">Subordinado a</InputLabel>
            <Select
              labelId="label-setor"
              id="setor"
              value={setorPaiAtual}
              label="Surobordinado a"
              onChange={(event) => {
                handleSetorPaiChange(String(event.target.value))
              }}
            >
              <MenuItem value="">
                <em>Nenhum</em>
              </MenuItem>
              {listaSetoresPais?.map((setor) => (
                <MenuItem value={setor.id_setor} key={setor.id_setor}>
                  {setor.nome}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Stack direction="row" gap={2}>
            <ComponentGroup title="Emitidos pelo Setor " width={'50%'}>
              <List sx={{ height: '360px', overflow: 'auto' }}>
                {listaTiposDocumentosSetor?.map(
                  (
                    tipoDocumento: IArvoreDocumentalTipoDocumento,
                    index: number
                  ) => (
                    <ListItem
                      key={index}
                      disableGutters
                      secondaryAction={
                        <IconButton
                          onClick={() =>
                            handleTipoDocumentoAdicionarClick(
                              tipoDocumento,
                              false
                            )
                          }
                        >
                          <BackspaceIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary={getTextoArvoreDocumentalTipoDocumento(
                          tipoDocumento
                        )}
                      />
                    </ListItem>
                  )
                )}
              </List>
            </ComponentGroup>

            <ComponentGroup title="Disponíveis" width={'50%'}>
              <List sx={{ height: '360px', overflow: 'auto' }}>
                {listaTiposDocumentosDisponivel?.map(
                  (tipoDocumento: ITipoDocumento, index: number) => (
                    <ListItem
                      key={index}
                      disableGutters
                      secondaryAction={
                        <IconButton
                          onClick={() =>
                            handleTipoDocumentoAdicionarClick(
                              {
                                codigo: '',
                                id_arvore_documental_tipo_documento: '',
                                tipo_documento: {
                                  id_tipo_documento:
                                    tipoDocumento.id_tipo_documento ?? '',
                                  ...tipoDocumento
                                }
                              },
                              true
                            )
                          }
                        >
                          <AddIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText primary={tipoDocumento.codigo} />
                    </ListItem>
                  )
                )}
              </List>
            </ComponentGroup>
          </Stack>
        </Stack>
        <Stack direction="row" gap={2} mt={3}>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<CancelIcon />}
            onClick={() => handleCancelarClick()}
            disabled={!podeSalvar}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={() => handleSalvarClick()}
            disabled={!podeSalvar}
          >
            Salvar
          </Button>
        </Stack>
      </ComponentGroup>
    )
  }
  //Funções de interação com a interface (FIM)

  if (isLoading || isLoadingTiposDocumentos || isLoadingListaSetores)
    return <Loading />

  return (
    <Stack>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          width: '100%',
          marginTop: '16px'
        }}
      >
        <ComponentGroup title="Árvore Documental" width={'50%'}>
          <TreeView
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
            defaultEndIcon={<div style={{ width: 24 }} />}
            sx={{
              height: 555,
              flexGrow: 1,
              overflowY: 'auto'
            }}
          >
            <DocumentalTree
              data={
                arvoreDocumental ?? {
                  id_empresa: '',
                  ramos: []
                }
              }
              empresa={data}
              setorClick={(setor: IArvoreDocumentalSetor) =>
                handleSetorClick(setor)
              }
              empresaClick={handleEmpresaClick}
              tipoDocumentoClick={(
                arvoreDocumentalTipoDocumental: IArvoreDocumentalTipoDocumento,
                id_branch: string
              ) =>
                handleTipoDocumentoClick(
                  arvoreDocumentalTipoDocumental,
                  id_branch
                )
              }
            />
          </TreeView>
        </ComponentGroup>
        {visao === 0
          ? showComponentsEmpresa()
          : visao === 1
          ? showComponentsSetor()
          : showComponentsTipoDocumento()}
      </Box>
      <Stack direction="row" gap={2} mt={3}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<CancelIcon />}
          onClick={() => setFormMode('LIST')}
        >
          Cancelar
        </Button>
      </Stack>
    </Stack>
  )
}
