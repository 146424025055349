import { Box } from '@mui/material'

interface DashboardWidgetSummaryHeaderTitleProps {
  title: string
}
export function DashboardWidgetSummaryHeaderTitle({
  title
}: DashboardWidgetSummaryHeaderTitleProps) {
  return (
    <Box
      sx={{
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '1.75rem',
        color: '#1C1C1C',
        padding: 0,
        margin: 0
      }}
    >
      <span>{title}</span>
    </Box>
  )
}
