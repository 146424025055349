import { useQuery } from 'react-query'
import { ApiPessoa } from '../../services/pessoa'

const QUERY_KEY = 'qkPessoa'

// const FindAll = (page: number, q = '') => {
//   return useQuery([QUERY_KEY, q, page], () => ApiPessoa.findAll(page, q))
// }

const ListAll = () => {
  return useQuery([QUERY_KEY], () => ApiPessoa.listAll())
}

const FindOne = (id: string) => {
  return useQuery([QUERY_KEY, id], () => ApiPessoa.findOne(id))
}

const FindByIdUsuario = (id: string) => {
  return useQuery([QUERY_KEY, id], () => ApiPessoa.findByIdUsuario(id))
}

// const Create = () => {
//   const queryClient = useQueryClient()

//   return useMutation(ApiPessoa.create, {
//     onSuccess: () => {
//       queryClient.invalidateQueries(QUERY_KEY)
//     }
//   })
// }

// const Update = () => {
//   const queryClient = useQueryClient()

//   return useMutation(ApiPessoa.update, {
//     onSuccess: () => {
//       queryClient.invalidateQueries(QUERY_KEY)
//     }
//   })
// }

// const Remove = () => {
//   const queryClient = useQueryClient()

//   return useMutation(ApiPessoa.remove, {
//     onSuccess: () => {
//       queryClient.invalidateQueries(QUERY_KEY)
//     }
//   })
// }

export const usePessoa = {
  // Create,
  // FindAll,
  ListAll,
  FindOne,
  // Update,
  // Remove,
  FindByIdUsuario
}
