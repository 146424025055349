import { Box, Button, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import TreeItem from '@mui/lab/TreeItem'

import { useDocumento } from '../../../../../hooks/documento/useDocumento'
import { TreeView } from '@mui/lab'
import { getCodigo } from '../../../../../utils/getCodigo'
import { useState } from 'react'
import { Loading } from '../../../../../components/Loading'

interface DocumentTreeProps {
  idDocumento: string
  close: () => void
}

export function DocumentTree({ close, idDocumento }: DocumentTreeProps) {
  const { data: documento, isLoading } = useDocumento.FindById(idDocumento)
  const [expanded, setExpanded] = useState<string[]>([
    `arvore-${documento?.id_documento}`
  ])

  const fileParts = documento?.path?.split('.')
  let fileExtension
  if (fileParts && fileParts[fileParts.length - 1] !== undefined) {
    fileExtension = fileParts[fileParts.length - 1]
  } else {
    fileExtension = '-'
  }

  const fileSize = documento?.tamanho
  let sizeNumber
  let sizeText
  if (fileSize && parseFloat(fileSize) < 1) {
    sizeNumber = (parseFloat(fileSize) * 1000).toFixed(1)
    sizeText = ' KILOBYTES'
  } else if (fileSize && parseFloat(fileSize) > 1) {
    sizeNumber = parseFloat(fileSize).toFixed(2)
    sizeText = ' MEGABYTES'
  }

  const collapseAll = () => {
    if (expanded.length === 0) {
      setExpanded([`arvore-${documento?.id_documento}`])
      return
    }
    setExpanded([])
  }

  const toggleMetadata = () => {
    if (
      expanded.find(
        (key) => key === `arvoreMETADADOS-${documento?.id_documento}`
      )
    ) {
      setExpanded([`arvore-${documento?.id_documento}`])
      return
    }
    setExpanded([
      `arvore-${documento?.id_documento}`,
      `arvoreMETADADOS-${documento?.id_documento}`
    ])
  }

  if (isLoading) return <Loading />

  return (
    <Box
      sx={{
        width: '40rem',
        height: '30rem',
        background: '#fff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '1.5rem',
        borderRadius: '0.5rem',
        alignItems: 'center'
      }}
    >
      <Box>
        <Typography
          sx={{
            marginLeft: '1rem',
            fontSize: '1.5rem',
            fontWeight: 'bold'
          }}
        >
          Árvore Hierárquica
        </Typography>
        <Box
          sx={{
            margin: '1rem',
            width: '38rem',
            height: '22rem',
            overflowY: 'auto'
          }}
        >
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={[...expanded]}
            sx={{
              width: '100%'
            }}
          >
            <TreeItem
              nodeId={`arvore-${documento?.id_documento}`}
              key={`arvore-${documento?.id_documento}`}
              label={`DOCUMENTO: ${documento?.descricao ?? ''} `}
              onClick={() => collapseAll()}
            >
              <TreeItem
                nodeId={`arvoreCAIXA-${documento?.id_documento}`}
                key={`arvoreCAIXA-${documento?.id_documento}`}
                label={`CAIXA: ${documento?.caixa?.identificacao} (${getCodigo(
                  documento?.id_caixa ?? ''
                )})`}
              />
              <TreeItem
                nodeId={`arvoreLOCALIZACAO-${documento?.id_documento}`}
                label={`LOCALIZAÇÃO: ${documento?.endereco}`}
              />
              <TreeItem
                nodeId={`arvorePRIVACIDADE-${documento?.id_documento}`}
                key={`arvorePRIVACIDADE-${documento?.id_documento}`}
                label={`PRIVACIDADE: ${documento?.dados_pessoais ? 'P' : ''}${
                  documento?.dados_pessoais_sensiveis ? 'S' : ''
                }${documento?.dados_criancas_adolescentes ? 'C' : ''}`}
              />
              <TreeItem
                nodeId={`arvoreTRAMITE-${documento?.id_documento}`}
                key={`arvoreTRAMITE-${documento?.id_documento}`}
                label={`ESTADO DE TRÂMITE: ${documento?.estado}`}
              />
              <TreeItem
                nodeId={`arvoreTRAMITE-${documento?.id_documento}`}
                key={`arvoreTRAMITE-${documento?.id_documento}`}
                label={`TIPO DE ARQUIVO: ${fileExtension?.toUpperCase()}`}
              />
              <TreeItem
                nodeId={`arvoreTRAMITE-${documento?.id_documento}`}
                key={`arvoreTRAMITE-${documento?.id_documento}`}
                // label={`TAMANHO DO ARQUIVO: ${
                //   Math.floor(Math.random() * 100000) + 1
                // } BYTES`}
                label={`TAMANHO DO ARQUIVO: ${
                  sizeNumber ? sizeNumber + sizeText : '-'
                }`}
              />
              {fileExtension === 'pdf' ? (
                <TreeItem
                  nodeId={`arvoreTRAMITE-${documento?.nr_paginas}`}
                  key={`arvoreTRAMITE-${documento?.nr_paginas}`}
                  label={`NÚMERO DE PÁGINAS: ${
                    documento?.nr_paginas ? documento?.nr_paginas : '-'
                  }`}
                />
              ) : (
                ''
              )}
              {documento?.metadados && documento.metadados.length > 0 && (
                <TreeItem
                  nodeId={`arvoreMETADADOS-${documento?.id_documento}`}
                  key={`arvoreMETADADOS-${documento?.id_documento}`}
                  label={'METADADOS'}
                  onClick={() => toggleMetadata()}
                >
                  {documento.metadados.map((metadado, index) => {
                    return (
                      <TreeItem
                        nodeId={
                          `arvoreMETADADOS-${documento?.id_documento}` +
                          `${metadado.chave}${index}`
                        }
                        label={`${metadado.chave.toLocaleUpperCase()}: ${
                          metadado.valor
                        }`}
                      />
                    )
                  })}
                </TreeItem>
              )}
            </TreeItem>
          </TreeView>
        </Box>
      </Box>
      <Button
        onClick={() => close()}
        variant="outlined"
        sx={{ width: '10rem' }}
      >
        Fechar
      </Button>
    </Box>
  )
}
