import { useQuery } from 'react-query'
import { ApiPosicaoPrateleira } from '../../services/posicaoPrateleira'

const QUERY_KEY = 'qkPosicaoPrateleira'

const ListAll = () => {
  return useQuery([QUERY_KEY], ApiPosicaoPrateleira.listAll)
}

const FindByPrateleira = (id: string) => {
  return useQuery([QUERY_KEY, id], () =>
    ApiPosicaoPrateleira.findByPrateleira(id)
  )
}

export const usePosicaoPrateleira = {
  ListAll,
  FindByPrateleira
}
