import { forwardRef, ForwardRefRenderFunction } from 'react'
import { TextField, TextFieldProps } from '@mui/material'

export type InputProps = {
  label: string
  message?: string
  disabled?: boolean
  isPassword?: boolean
  readOnly?: boolean
} & TextFieldProps

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    label,
    message,
    disabled = false,
    isPassword = false,
    readOnly = false,
    ...rest
  },
  ref
) => {
  return (
    <TextField
      label={label}
      placeholder={label}
      error={!!message}
      helperText={message}
      disabled={disabled}
      size="small"
      fullWidth
      inputProps={{
        readOnly: Boolean(readOnly)
      }}
      type={isPassword ? 'password' : 'text'}
      sx={{ bgcolor: disabled ? '#f9f9f9' : undefined }}
      ref={ref}
      {...rest}
    />
  )
}

export const Input = forwardRef(InputBase)
