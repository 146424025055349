import { Box } from '@mui/material'
import { ReactNode } from 'react'

interface DashboardWidgetFormActionsProps {
  children: ReactNode
}

export function DashboardWidgetFormActions({
  children
}: DashboardWidgetFormActionsProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          sm: 'column',
          md: 'row'
        },
        gap: {
          sm: '0.5rem',
          md: '2rem'
        }
      }}
    >
      {children}
    </Box>
  )
}
