import { Box } from '@mui/material'
import BulletOk from '../../assets/images/clockGreen.svg'
import BulletError from '../../assets/images/clockRed.svg'
import BulletWarning from '../../assets/images/clockOrange.svg'
import BulletInfo from '../../assets/images/clockBlue.svg'
import { ReactElement } from 'react'

export type BoxStatus = 'Ok' | 'Error' | 'Warning' | 'Info'

interface StatusElements {
  textColor: string
  backgroundColor: string
  bullet: ReactElement
}

interface StatusBoxProps {
  caption: string
  status: BoxStatus
}

const getTheme = (status: BoxStatus): StatusElements => {
  switch (status) {
    case 'Ok': {
      return {
        textColor: 'rgb(0, 186, 52)',
        backgroundColor: 'rgba(0, 186, 52,0.1)',
        bullet: <img src={BulletOk} />
      }
    }
    case 'Warning': {
      return {
        textColor: 'rgb(249, 134, 0)',
        backgroundColor: 'rgba(249, 134, 0, 0.1)',
        bullet: <img src={BulletWarning} />
      }
    }
    case 'Info': {
      return {
        textColor: 'rgb(0, 133, 255)',
        backgroundColor: 'rgba(0, 133, 255, 0.1)',
        bullet: <img src={BulletInfo} />
      }
    }
  }
  return {
    textColor: 'rgb(233, 44, 44)',
    backgroundColor: 'rgba(233, 44, 44, 0.15)',
    bullet: <img src={BulletError} />
  }
}

export function StatusBox({ caption, status }: StatusBoxProps) {
  const theme = getTheme(status)
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '2px 6px',
        gap: '4px',
        width: '102px',
        height: '33px',
        borderRadius: '6px',
        fontSize: '0.6rem',
        backgroundColor: theme.backgroundColor,
        color: theme.textColor
      }}
    >
      {theme.bullet}
      <span>{caption}</span>
    </Box>
  )
}
