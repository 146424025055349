import {
  FinanceiroReportForm,
  IHandleFinanceiroReportSubmitProps
} from '../../../../../../components/Financeiro/FinanceiroReportForm'
import { ApiRelatorioFinanceiro } from '../../../../../../services/financeiro/relatorio'
import { PrevisaoLancamentoReport } from './report/PrevisaoLancamentoReport'

export interface IPrevisaoLancamentoRelatorioFormProps {
  tipo_relacionamento?: string
}
export function PrevisaoLancamentoRelatorio({
  tipo_relacionamento
}: IPrevisaoLancamentoRelatorioFormProps) {
  const isCliente = tipo_relacionamento === 'C'
  const isPrevisao = !tipo_relacionamento ? false : true
  async function handleSubmitForm({
    mode,
    format,
    id_usuario,
    filtros
  }: IHandleFinanceiroReportSubmitProps) {
    const {
      data_fim,
      data_inicio,
      id_centro_custo,
      id_forma_pagamento,
      id_receita_despesa,
      id_parceiro,
      id_parceiro_2,
      order_by
    } = filtros

    const previsaoPagar = await ApiRelatorioFinanceiro.getPrevisaoLancamento({
      data_fim,
      data_inicio,
      id_centro_custo,
      id_forma_pagamento,
      id_receita_despesa,
      id_parceiro,
      id_parceiro_2,
      id_usuario,
      order_by,
      tipo_relacionamento
    })

    if (format === 'pdf') {
      await PrevisaoLancamentoReport({
        data: previsaoPagar,
        id_usuario: id_usuario,
        filtros: {
          data_fim,
          data_inicio,
          order_by,
          id_centro_custo,
          id_forma_pagamento,
          id_parceiro,
          id_parceiro_2,
          id_receita_despesa,
          tipo_relacionamento
        },
        mode
      })
    }

    return previsaoPagar
  }
  return (
    <FinanceiroReportForm
      title={`Lançamentos à ${
        isPrevisao ? (isCliente ? 'Receber' : 'Pagar') : 'Receber x Pagar'
      }`}
      handleReportSubmit={handleSubmitForm}
      tipo_relacionamento={tipo_relacionamento}
      temParceiro2={tipo_relacionamento ? false : true}
    />
  )
}
