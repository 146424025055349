import { useState, useMemo } from 'react'
import {
  Box,
  Button,
  Divider,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import { useDocumento } from '../../../hooks/documento/useDocumento'
import { formata } from '../../../utils/formataData'
import React from 'react'
import { Loading } from '../../../components/Loading'
import api from '../../../services/api'
import useToast from '../../../hooks/toast/useToast'

export function List() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [q, setQ] = useState<string>('')
  const searchInput = React.useRef<HTMLInputElement>(null)

  const handleSearchClick = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setSearchParams({ ...searchParams, q: searchTerm })
    setQ(searchTerm)
    searchInput.current?.focus()
  }

  const page = useMemo(() => {
    return Number(searchParams.get('page') ?? 1)
  }, [searchParams])

  const { data: records, isLoading } = useDocumento.FindAllCompartilhamentos(
    page,
    q
  )
  const { ToastError, ToastSuccess } = useToast()

  async function inativarCompartilhamento(id_compartilhamento: string) {
    try {
      const response = await api.put(
        `/documento/compartilhamentos/${id_compartilhamento}`
      )
      return response.data
    } catch (error) {
      console.error('Erro ao inativar o compartilhamento', error)
      throw error
    }
  }

  if (isLoading) return <Loading />

  return (
    <Box>
      <Stack
        component="form"
        onSubmit={handleSearchClick}
        direction="row"
        py={3}
        gap={3}
        mb={2}
      >
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          placeholder="Nome/Ação/IP"
          inputRef={searchInput}
          autoFocus
          value={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value)
          }}
        />
        <Button variant="outlined" startIcon={<SearchIcon />} type="submit">
          Pesquisar
        </Button>
      </Stack>

      <Divider />

      <TableContainer
        component={Box}
        border={1}
        borderRadius={2}
        borderColor="#f2f2f2"
      >
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Documento Compartilhado
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Email Compartilhado
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Número de Acessos
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Validade do Compartilhamento
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Data do Compartilhamento
              </TableCell>
              <TableCell width="12px" />
              <TableCell width="12px" />
            </TableRow>
          </TableHead>
          <TableBody>
            {records?.data?.map((record) => (
              <TableRow key={record.id_compartilhamento} hover>
                <TableCell>{record.descricao}</TableCell>
                <TableCell>{record.email}</TableCell>
                <TableCell>{record.numero_acessos}</TableCell>
                <TableCell>
                  {record.validade_link
                    ? formata(
                        record.validade_link?.toString() ?? '',
                        'dd/MM/yyyy HH:mm'
                      )
                    : 'Não possui validade'}
                </TableCell>
                <TableCell>
                  {formata(
                    record.created_at?.toString() ?? '',
                    'dd/MM/yyyy HH:mm'
                  )}
                </TableCell>

                <TableCell>
                  <Tooltip title={'Cancelar compartilhamento'} arrow>
                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{ minWidth: '24px', padding: '6px' }}
                      onClick={async () => {
                        try {
                          await inativarCompartilhamento(
                            record.id_compartilhamento
                          )
                          ToastSuccess(
                            'Compartilhamento inativado com sucesso!'
                          )
                        } catch (error) {
                          ToastError('Erro ao inativar o compartilhamento')
                        }
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {records?.pagination &&
        records.pagination.totalItems > records.pagination.itemsPerPage && (
          <Pagination
            count={records?.pagination.totalPages}
            sx={{ marginTop: '12px' }}
            onChange={(_, newPage) =>
              setSearchParams({ page: String(newPage) })
            }
            page={records.pagination.currentPage}
          />
        )}
    </Box>
  )
}
