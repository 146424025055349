import React from 'react'
import { AuthProvider } from './auth'
import { TrackRouteProvider } from './trackroute'

interface AppProviderProps {
  children: React.ReactNode
}

function AppProvider({ children }: AppProviderProps) {
  return (
    <AuthProvider>
      <TrackRouteProvider>{children}</TrackRouteProvider>
    </AuthProvider>
  )
}

export default AppProvider
