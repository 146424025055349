import { TableCell, TableRow, TextField } from '@mui/material'
import { useState } from 'react'

import { Input } from '../../../../components/Form/Input'
import { SearchDialog } from '../../../../components/SearchDialog'
import { IPrecoAtividade, ISetor } from '../../../../services/contrato/types'
import { ListaSetores } from './ItemContratoAccordion'
import { masks } from '../../../../utils/masks'
import { formata } from '../../../../utils/formataData'

interface PrecoAtividadeRow {
  precoAtividade: IPrecoAtividade
  setores: ListaSetores[]
  updatePreco: (precoAtividade: IPrecoAtividade) => void
}

export function PrecoAtividadeRow({
  precoAtividade,
  setores,
  updatePreco
}: PrecoAtividadeRow) {
  const [showDialog, setShowDialog] = useState<boolean>(false)

  function handleToggleSearchDialog() {
    setShowDialog((previousValue) => !previousValue)
  }

  function handleSelect(setor: ISetor) {
    precoAtividade.setor = setor
    updatePreco(precoAtividade)
    handleToggleSearchDialog()
  }

  function handleSetorClick() {
    handleToggleSearchDialog()
  }

  function handleValorChange(value: string): string {
    try {
      const newValue = masks.getFloatValue({ value })
      precoAtividade.valor = newValue
      updatePreco(precoAtividade)
      return value
    } catch (error) {
      let lastValidChar = 0
      let validChar = true
      do {
        try {
          lastValidChar++
          masks.getFloatString({ value: value.substring(0, lastValidChar) })
        } catch (error) {
          lastValidChar--
          validChar = false
        }
      } while (validChar)
      return value.substring(0, lastValidChar)
    }
  }

  function handleSlaChange(value: string) {
    precoAtividade.sla = Number(value)
    updatePreco(precoAtividade)
  }

  function handleDataInicioChange(value: string) {
    precoAtividade.data_inicio = new Date(value)
    updatePreco(precoAtividade)
  }

  return (
    <TableRow hover>
      <TableCell>
        {precoAtividade.atividade.tipo_atividade?.identificacao}
      </TableCell>
      <TableCell>
        <Input
          label=""
          value={precoAtividade.setor.nome}
          type={'text'}
          // disabled={true}
          onClick={() => {
            handleSetorClick()
          }}
        />
      </TableCell>
      <TableCell>
        <Input
          label=""
          defaultValue={masks.getFloatString({
            value: (precoAtividade.valor ?? 0).toLocaleString()
          })}
          onChange={(event) => {
            const value = event?.target.value
            event.target.value = handleValorChange(value)
          }}
        />
      </TableCell>
      <TableCell>
        <Input
          label=""
          onChange={(event) => {
            handleSlaChange(event?.target.value)
          }}
          defaultValue={precoAtividade?.sla ?? 0}
          InputProps={{ inputProps: { min: 0 } }}
          type={'number'}
        />
      </TableCell>
      <TableCell>
        <TextField
          label=""
          type="date"
          value={formata(new Date(precoAtividade.data_inicio), 'yyyy-MM-dd')}
          size="small"
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
          onChange={(event) => {
            handleDataInicioChange(event?.target.value)
          }}
        />
      </TableCell>
      <SearchDialog
        data={setores}
        displayFields={[
          { fieldName: 'nome', fieldLabel: 'Nome' },
          { fieldName: 'empresa', fieldLabel: 'Empresa' }
        ]}
        keyField="id_setor"
        openDialog={showDialog}
        title={'Selecione o Setor que executará a atividade'}
        innerPlaceholder="Nome/Empresa"
        handleAnswerCancel={handleToggleSearchDialog}
        handleAnswerSelect={handleSelect}
      />
    </TableRow>
  )
}
