import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import {
  Box,
  Stack,
  Button,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem
} from '@mui/material'
import SaveIcon from '@mui/icons-material/CheckCircleOutlined'
import CancelIcon from '@mui/icons-material/DoDisturbAltOutlined'

import { Input } from '../../../components/Form/Input'
import { FormMode } from '../../../types/formMode'
import { ITipoTemporalidade } from '../../../services/tipoTemporalidade/types'
import msg from '../../../utils/validationMessages'
import useToast from '../../../hooks/toast/useToast'
import { useTipoTemporalidade } from '../../../hooks/tipoTemporalidade/useTipoTemporalidade'

const schema = yup
  .object({
    descricao: yup.string().required(msg.REQUIRED).min(4, msg.MIN(4)),
    fundamentacao: yup.string().required(msg.REQUIRED).min(4, msg.MIN(4)),
    tempo: yup.number().required(msg.REQUIRED).min(0, msg.MIN(0)),
    unidade: yup.string().required(msg.REQUIRED).oneOf(['ANO', 'MES', 'DIA'])
  })
  .required()

interface IForm {
  data: any
  formMode: FormMode
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

export function FormTipoTemporalidade({ data, formMode, setFormMode }: IForm) {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ITipoTemporalidade>({ resolver: yupResolver(schema) })
  const { Toast } = useToast()

  const { mutateAsync: create } = useTipoTemporalidade.Create()
  const { mutateAsync: update } = useTipoTemporalidade.Update()

  const handleSubmitTipoTemporalidade = async (
    formData: ITipoTemporalidade
  ) => {
    formMode === 'CREATE'
      ? await Toast(create(formData))
      : await Toast(
          update({
            id_tipo_temporalidade: data.id_tipo_temporalidade,
            ...formData
          }),
          'UPDATED'
        )
    setFormMode('LIST')
  }

  return (
    <Box
      component="form"
      mt={4}
      onSubmit={handleSubmit(handleSubmitTipoTemporalidade)}
    >
      <Stack>
        <Stack
          direction="row"
          sx={{
            width: '100%',
            gap: '2rem'
          }}
        >
          <Input
            label="Descrição"
            {...register('descricao')}
            defaultValue={data?.descricao}
            message={errors.descricao?.message}
            fullWidth={false}
            sx={{ flex: 1 }}
          />
          <Input
            label="Fundamentação"
            {...register('fundamentacao')}
            defaultValue={data?.fundamentacao}
            message={errors.fundamentacao?.message}
            fullWidth={false}
            sx={{ width: '29%' }}
          />

          <TextField
            variant="outlined"
            size="small"
            {...register('tempo')}
            type={'number'}
            defaultValue={data?.tempo}
            InputProps={{ inputProps: { min: 1 } }}
            label="Duração"
            sx={{ width: '8rem' }}
          />
          <FormControl>
            <InputLabel id="label-temporalidade">Temporalidade</InputLabel>
            <Select
              labelId="label-temporalidade"
              id="demo-simple-select"
              size="small"
              defaultValue={data?.unidade ?? 'ANO'}
              label="Temporalidade"
              {...register('unidade')}
              sx={{ width: '8rem' }}
            >
              <MenuItem value={'ANO'}>Ano</MenuItem>
              <MenuItem value={'MES'}>Mês</MenuItem>
              <MenuItem value={'DIA'}>Dia</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Stack direction="row" gap={2} mt={3}>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<CancelIcon />}
            onClick={() => setFormMode('LIST')}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="contained"
            startIcon={<SaveIcon />}
            sx={{ width: '110px' }}
          >
            Salvar
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}
