import { DefaultApi } from '../../../utils/defaultApi'
import { IResult, TAtivo } from '../../../utils/defaultApi/types'
import api from '../../api'
import { IRecorrencia } from './types'

const endpoint = '/financeiro/recorrencias'
const resourceId = 'id_recorrencia'

class ServiceRecorrencia extends DefaultApi<IRecorrencia> {
  gerarLancamentos = async (id_recorrencia: string) => {
    const { data } = await api.post<IRecorrencia[]>(
      `${endpoint}/gerar/${id_recorrencia}`
    )
    return data
  }

  findAllRecorrencia = async (
    page: number,
    selectedOrder: string,
    q?: string,
    ativo: TAtivo = true
  ): Promise<IResult<IRecorrencia>> => {
    const pageString = page ? `page=${page}` : ''
    const qString = q ? `q=${q}` : ''
    const ativoString = ativo !== undefined ? `ativo=${ativo}` : ''
    const ordemString = selectedOrder ? `ordem=${selectedOrder}` : ''
    const params = [pageString, qString, ativoString, ordemString]
      .filter((value) => value !== '')
      .join('&')

    const { data } = await api.get<IResult<IRecorrencia>>(
      `${this.endpoint}?${params}`
    )
    return data
  }

  cancelar = async (id_recorrencia: string) => {
    const { data } = await api.put<IRecorrencia[]>(
      `${endpoint}/cancelar/${id_recorrencia}`
    )
    return data
  }
}

const apiRecorrencia = new ServiceRecorrencia(endpoint, resourceId)

export const ApiRecorrencia = {
  ...apiRecorrencia,
  gerarLancamentos: apiRecorrencia.gerarLancamentos,
  cancelar: apiRecorrencia.cancelar
}
