import { DefaultApi } from '../../utils/defaultApi'
import api from '../api'
import { ITipoDocumento } from './types'
import { IResult, TAtivo } from '../../utils/defaultApi/types'

const endpoint = '/tipo-documento'
const resourceId = 'id_tipo_documento'

class ServiceTipoDocumento extends DefaultApi<ITipoDocumento> {
  findAllOrder = async (
    page: number,
    selectedOrder?: string,
    q?: string,
    id_contrato?: string,
    ativo: TAtivo = true
  ): Promise<IResult<ITipoDocumento>> => {
    const pageString = page ? `page=${page}` : ''
    const qString = q ? `q=${q}` : ''
    const ativoString = ativo !== undefined ? `ativo=${ativo}` : ''
    const ordemString = selectedOrder ? `ordem=${selectedOrder}` : ''
    const qContrato = id_contrato ? `id_contrato=${id_contrato}` : ''
    const params = [pageString, qString, ativoString, ordemString, qContrato]
      .filter((value) => value !== '')
      .join('&')

    const { data } = await api.get<IResult<ITipoDocumento>>(
      `${this.endpoint}?${params}`
    )
    return data
  }

  public listAllByIdSetor = async (
    id_setor: string
  ): Promise<ITipoDocumento[]> => {
    const { data } = await api.get<ITipoDocumento[]>(
      `${this.endpoint}/lista/setor/${id_setor}`
    )
    return data
  }

  public findByCode = async (
    code: string
  ): Promise<ITipoDocumento | undefined> => {
    if (code.trim() === '') {
      return undefined
    }
    const { data } = await api.get<ITipoDocumento>(
      `${this.endpoint}/codigo/${code}`
    )
    return data
  }
}

const apiTipoDocumento = new ServiceTipoDocumento(endpoint, resourceId)

export const ApiTipoDocumento = {
  ...apiTipoDocumento
}
