import { Box, Button, Checkbox, FormControlLabel, Stack } from '@mui/material'
import { useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { red } from '@mui/material/colors'
import { IUsuario } from '../../../services/usuario/types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import msg from '../../../utils/validationMessages'
import useToast from '../../../hooks/toast/useToast'
import { FormMode } from '../../../types/formMode'
import { Select } from '../../../components/Form/Select'
import { Input } from '../../../components/Form/Input'
import { Loading } from '../../../components/Loading'
import { useUsuario } from '../../../hooks/usuario/useUsuario'
import { useSetor } from '../../../hooks/setor/useSetor'
import { useContratoPermitido } from '../../../hooks/contratoPermitido/useContratoPermitido'
import { isValidDate, validation } from '../../../utils/validations'
import { masks } from '../../../utils/masks'
import { useEmpresa } from '../../../hooks/empresa/useEmpresa'
import { hasAccess } from '../../../utils/hasAccess'
import { Permissions } from '../../../types/permissions'

const schema = yup.object({
  username: yup.string().required(msg.REQUIRED).min(3, msg.MIN(3)),
  habilita_2fa: yup.boolean(),
  template: yup.boolean(),
  dt_inicio_inatividade: yup.date().transform((value) => {
    return isValidDate(value) ? value : undefined
  }),
  dt_termino_inatividade: yup
    .date()
    .transform((value) => {
      return isValidDate(value) ? value : undefined
    })
    .when(
      'dt_inicio_inatividade',
      (inicio, yup) => inicio && yup.min(inicio, msg.DATEINTERVAL)
    ),
  pessoa: yup.object({
    nome: yup.string().required(msg.REQUIRED).min(3, msg.MIN(3)),
    telefone1: yup.string().phone(msg.PHONE),
    telefone2: yup.string().phone(msg.PHONE),
    cpf: yup.string().required(msg.REQUIRED).cpf(msg.CPF),
    email: yup.string().required(msg.EMAIL).email(msg.EMAIL),
    cargo: yup.string().required(msg.REQUIRED).min(3, msg.MIN(3)),
    setor: yup.object({
      id_setor: yup.string().required(msg.REQUIRED).min(3, msg.MIN(3))
    })
  })
})

interface IForm {
  data: any
  formMode: FormMode
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

export function Form({ data, formMode, setFormMode }: IForm) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<IUsuario>({
    resolver: yupResolver(schema)
  })

  const [idEmpresa, setIdEmpresa] = useState(
    data?.pessoa?.setor?.empresa?.id_empresa
      ? data?.pessoa?.setor?.empresa?.id_empresa
      : ''
  )

  const { Toast } = useToast()
  const { mutateAsync: create, isLoading: isLoadingCreate } =
    useUsuario.Create()
  const { mutateAsync: update } = useUsuario.Update()

  const { data: empresas } = useEmpresa.ListAll()
  const { data: usuario } = useUsuario.FindByUsername()
  const { data: contratos } = useContratoPermitido.FindByUsuarioLogado()
  const { data: setores, isLoading } = useSetor.FindByEmpresa(idEmpresa)

  const dt_inicio_inatividade = (data?.dt_inicio_inatividade ?? '')
    .toLocaleString()
    .slice(0, 10)
  const dt_termino_inatividade = (data?.dt_termino_inatividade ?? '')
    .toLocaleString()
    .slice(0, 10)

  const localData = localStorage.getItem('@easydoc:user')
  let userData: any
  if (localData) {
    userData = JSON.parse(localData)
  }

  const userContratos = empresas?.filter((empresa) => {
    return contratos?.some((contrato) => {
      return (
        empresa.id_empresa === userData.pessoa.setor.empresa.id_empresa ||
        empresa.id_empresa === contrato.id_empresa_contratante
      )
    })
  })

  const userContratoUnico = empresas?.filter((empresa) => {
    return empresa.id_empresa === userData.pessoa.setor.empresa.id_empresa
  })

  const setorData = setores?.map((setor) => ({
    id: setor.id_setor,
    nome: setor.nome
  }))

  function handlePhoneChange(value: string): string {
    const newValue = masks.phone({ value, mandatoryDDD: true })
    return newValue
  }

  function handlePhoneBlur(value: string): string {
    const newValue = validation.clearValue(value)
    if (newValue.length === 0) {
      return ''
    }
    return value
  }

  function handleCpfChange(value: string): string {
    const newValue = masks.cpf({ value })
    return newValue
  }

  function handleEmpresaChange(idEmpresa: string) {
    setIdEmpresa(idEmpresa)
    setValue('pessoa.setor.id_setor', '')
  }

  function handleSetorChange(idSetor: string) {
    setValue('pessoa.setor.id_setor', idSetor)
  }

  const onSubmit = async (formData: IUsuario) => {
    formMode === 'CREATE'
      ? await Toast(create(formData))
      : await Toast(
          update({
            ...formData,
            pessoa: {
              ...formData.pessoa,
              id_pessoa: data.id_pessoa
            }
          }),
          'UPDATED'
        )
    setFormMode('LIST')
  }

  if (isLoading) <Loading />

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      mt={4}
      sx={{
        fontWeight: 400,
        fontSize: '14px',
        color: '#585757'
      }}
    >
      <span>Pessoal</span>
      <Stack
        direction="row"
        gap={2}
        mt={3}
        sx={{ position: 'fixed', right: 55, top: 140 }}
      >
        <Button
          type="submit"
          disabled={isLoadingCreate}
          style={{
            backgroundColor: '#00BA34',
            borderRadius: '8px'
          }}
          variant="contained"
          startIcon={formMode === 'CREATE' ? <AddIcon /> : <CheckIcon />}
          sx={{ width: '110px' }}
        >
          {formMode === 'CREATE' ? 'Adicionar' : 'Salvar'}
        </Button>

        <Button
          variant="outlined"
          style={{
            border: 'none',
            color: '#585757'
          }}
          startIcon={<ClearIcon sx={{ color: red[500] }} />}
          onClick={() => setFormMode('LIST')}
        >
          Cancelar
        </Button>
      </Stack>

      <Stack direction="row" gap={2} mt={3}>
        <Input
          label="Nome"
          size="medium"
          {...register('pessoa.nome')}
          defaultValue={data?.pessoa?.nome}
          message={errors.pessoa?.nome?.message}
        />
        <Input
          label="CPF"
          size="medium"
          {...register('pessoa.cpf', {
            onChange: (event) => {
              const { value } = event.target

              event.target.value = handleCpfChange(value)
            }
          })}
          defaultValue={data?.pessoa?.cpf}
          message={errors.pessoa?.cpf?.message}
        />
      </Stack>
      <Stack direction="row" gap={2} mt={2}>
        <Input
          label="E-mail"
          size="medium"
          {...register('pessoa.email')}
          defaultValue={data?.pessoa?.email}
          message={errors.pessoa?.email?.message}
        />
        <Input
          label="Username"
          size="medium"
          {...register('username')}
          defaultValue={data?.username}
          message={errors.username?.message}
        />
      </Stack>

      <Stack gap={2} direction="row" mt={2} mb={2}>
        <Input
          label="Telefone 1"
          size="medium"
          {...register('pessoa.telefone1', {
            onChange: (event) => {
              const { value } = event.target
              event.target.value = handlePhoneChange(value)
            },
            onBlur: (event) => {
              const { value } = event.target
              event.target.value = handlePhoneBlur(value)
            }
          })}
          defaultValue={data?.pessoa?.telefone1}
          message={errors.pessoa?.telefone1?.message}
        />
        <Input
          label="Telefone 2"
          size="medium"
          {...register('pessoa.telefone2', {
            onChange: (event) => {
              const { value } = event.target
              event.target.value = handlePhoneChange(value)
            },
            onBlur: (event) => {
              const { value } = event.target
              event.target.value = handlePhoneBlur(value)
            }
          })}
          defaultValue={data?.pessoa?.telefone2}
          message={errors.pessoa?.telefone2?.message}
        />
      </Stack>
      <span>Profissional</span>
      <Stack direction="row" gap={2} mt={2}>
        <Select
          label={`Empresa`}
          placeholder="Selecione a Empresa"
          size="medium"
          data={
            hasAccess(usuario?.permissoes, Permissions.PAPEL_ADMIN)
              ? empresas
              : userContratos?.length === 0
              ? userContratoUnico
              : userContratos
          }
          fields={['id_empresa', 'nome']}
          onChange={(e) => handleEmpresaChange(String(e.target.value))}
          defaultValue={data?.pessoa?.setor?.empresa?.id_empresa}
        />
        <Select
          IconComponent={ExpandMoreIcon}
          label="Setor"
          size="medium"
          placeholder="Selecione o Setor"
          data={setorData}
          fields={['id', 'nome']}
          {...register('pessoa.setor.id_setor')}
          onChange={(e) => {
            handleSetorChange(String(e.target.value))
          }}
          defaultValue={data?.pessoa?.setor?.id_setor}
          message={errors.pessoa?.setor?.id_setor?.message}
        />
      </Stack>
      <Stack mt={2} gap={2} mb={2}>
        <Input
          label="Cargo"
          size="medium"
          {...register('pessoa.cargo')}
          defaultValue={data?.pessoa?.cargo}
          message={errors.pessoa?.cargo?.message}
        />
      </Stack>
      <span>Período de Inatividade</span>
      <Stack direction="row" gap={2} mt={2} mb={2}>
        <Input
          label="Data Inicial"
          type="date"
          {...register('dt_inicio_inatividade')}
          InputLabelProps={{
            shrink: true
          }}
          defaultValue={dt_inicio_inatividade}
          message={errors.dt_inicio_inatividade?.message}
        />
        <Input
          label="Data Final"
          type="date"
          {...register('dt_termino_inatividade')}
          InputLabelProps={{
            shrink: true
          }}
          defaultValue={dt_termino_inatividade}
          message={errors.dt_termino_inatividade?.message}
        />
      </Stack>
      <Stack direction="row" gap={10}>
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked={data?.habilita_2fa}
              {...register('habilita_2fa')}
            />
          }
          label="Habilitar Login (2FA)"
        />
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked={data?.template}
              {...register('template')}
            />
          }
          label="Usuário Template"
        />
      </Stack>
    </Box>
  )
}
