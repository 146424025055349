import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material'

import SearchIcon from '@mui/icons-material/Search'
import { ReactNode, useEffect, useState } from 'react'

import LibraryAddIcon from '@mui/icons-material/LibraryAdd'

import { ICentroDocumentacao } from '../../../../services/centroDocumentacao/types'
import { ICorredor } from '../../../../services/corredor/types'
import { IPrateleira } from '../../../../services/prateleira/types'
import { IPosicaoPrateleira } from '../../../../services/posicaoPrateleira/types'
import { Input } from '../../../../components/Form/Input'
import { sleep } from '../../../../utils/sleep'
import { Descriptor } from './Descriptor'
import {
  getCDSpace,
  getCorredorSpace,
  getEstanteSpace,
  getPrateleiraSpace
} from '../lib/func'
import { CorredorDraw } from './CorredorDraw'
import { PositionDescriptor } from './PositionDescriptor'
import { PosicaoPrateleiraIncludePopup } from './PosicaoPrateleiraIncludePopup'
import { ExcludePopup } from './ExcludePopup'
import { IEstante } from '../../../../services/estante/types'
import { PrateleiraIncludePopup } from './PrateleiraIncludePopup'
import { EstanteIncludePopup } from './EstanteIncludePopup'
import { CorredorIncludePopup } from './CorredorIncludePopup'
import { PrateleiraChangePopup } from './PrateleiraChangePopup'
import { useTipoCaixa } from '../../../../hooks/tipoCaixa/useTipoCaixa'
import { ITipoCaixa } from '../../../../services/tipoCaixa/types'
import { Loading } from '../../../../components/Loading'

interface CDDrawProps {
  centroDocumentacao: ICentroDocumentacao
  excluirPosicaoPrateleira: (posicaoPrateleira: string) => void
  incluirPosicaoPrateleira: (id_parteleira: string, quantidade: number) => void
  excluirPrateleira: (prateleira: string) => void
  incluirPrateleira: (
    id_estante: string,
    quantidadePrateleiras: number,
    quantidadePosicoes: number,
    idTipoCaixa: string
  ) => void
  excluirEstante: (estante: string) => void
  incluirEstante: (
    id_corredor: string,
    quantidadeEstantes: number,
    quantidadePrateleiras: number,
    quantidadePosicoes: number,
    idTipoCaixa: string
  ) => void
  excluirCorredor: (corredor: string) => void
  incluirCorredor: (
    id_cd: string,
    quantidadeCorredores: number,
    quantidadeEstantes: number,
    quantidadePrateleiras: number,
    quantidadePosicoes: number,
    idTipoCaixa: string
  ) => void
  mudarPrateleira: (id_prateleira: string, id_tipo_caixa: string) => void
  alternarPosicaoPrateleira: (id_posicao_prateleira: string) => void
}

export function CDDraw({
  centroDocumentacao,
  excluirPosicaoPrateleira,
  incluirPosicaoPrateleira,
  excluirPrateleira,
  incluirPrateleira,
  mudarPrateleira,
  excluirEstante,
  incluirEstante,
  excluirCorredor,
  incluirCorredor,
  alternarPosicaoPrateleira
}: CDDrawProps) {
  // Dados (INÍCIO) ------------------------------------------------------------

  //Dados em branco (INÍCIO)
  const corredorBlank: ICorredor = {
    id_centrodocumentacao: '',
    id_corredor: '',
    identificacao: '',
    nCorredor: 0,
    estante: []
  }

  //Dados em branco (FIM)

  //Dados de busca (INÍCIO)
  const [centroAtual, setCentroAtual] = useState<string>(
    centroDocumentacao.id_centrodocumentacao ?? ''
  )
  const [searchElement, setSearchElement] = useState<string>('')
  const [corredorAtual, setCorredorAtual] = useState<string>('')
  const [estanteAtual, setEstanteAtual] = useState<string>('')
  const [corredorView, setCorredorView] = useState<ICorredor>(
    centroDocumentacao.corredor?.at(0) ?? corredorBlank
  )
  const [listaEstantes, setListaEstantes] = useState<ReactNode[]>([])

  //Dados de busca (FIM)

  const getPrimeiraEstante = (data: {
    corredor?: ICorredor
    estantes?: IEstante[]
  }): string => {
    if (!data.corredor && !data.estantes) {
      return ''
    }
    if (data.corredor) {
      const { corredor } = data
      const posEstanteAtual = corredor.estante?.reduce((acc, cur) => {
        return acc > +cur.nestante ? +cur.nestante : acc
      }, 99999)
      return (
        corredor.estante?.find((est) => +est.nestante === posEstanteAtual)
          ?.id_estante ?? ''
      )
    }
    const { estantes } = data
    const posEstanteAtual = estantes!.reduce((acc, cur) => {
      return acc > +cur.nestante ? +cur.nestante : acc
    }, 99999)
    return (
      estantes!.find((est) => +est.nestante === posEstanteAtual)?.id_estante ??
      ''
    )
  }

  const getEstanteByPosicao = (
    estantes: IEstante[],
    posicao: number
  ): string => {
    const estante =
      estantes.find((est) => +est.nestante === posicao)?.id_estante ??
      getPrimeiraEstante({ estantes })
    return estante
  }

  const getPrimeiroCorredor = (corredor: ICorredor[]): ICorredor => {
    const posCorredorAtual = corredor.reduce((acc, cur) => {
      return acc > +cur.nCorredor ? +cur.nCorredor : acc
    }, 99999)
    return (
      corredor.find((cor) => +cor.nCorredor === posCorredorAtual) ?? corredor[0]
    )
  }

  const getCorredorByPosicao = (
    corredores: ICorredor[],
    posicao: number
  ): ICorredor => {
    const corredor =
      corredores.find((cor) => cor.nCorredor === posicao) ??
      getPrimeiroCorredor(corredores)
    return corredor
  }

  const defPosicaoInicial = () => {
    if (centroDocumentacao.corredor) {
      const newCorredorAtual =
        centroDocumentacao.corredor && centroDocumentacao.corredor.length > 0
          ? getPrimeiroCorredor(centroDocumentacao.corredor)
          : corredorBlank
      setCorredorAtual(newCorredorAtual.id_corredor)
      setCorredorView(newCorredorAtual)
      setListaEstantes(getListaEstantes(newCorredorAtual.estante ?? []))
      setEstanteAtual(getPrimeiraEstante({ corredor: newCorredorAtual }))
    }
  }

  useEffect(() => {
    if (
      centroDocumentacao &&
      centroDocumentacao.id_centrodocumentacao !== centroAtual
    ) {
      setCentroAtual(centroDocumentacao.id_centrodocumentacao ?? '')
      defPosicaoInicial()
    }
  }, [centroDocumentacao])

  useEffect(() => defPosicaoInicial(), [])

  // PopPup (INÍCIO)

  const [showExcludePosicaoPopup, setShowExcludePosicaoPopup] =
    useState<boolean>(false)
  const [showIncludePosicaoPopup, setShowIncludePosicaoPopup] =
    useState<boolean>(false)
  const [showExcludePrateleiraPopup, setShowExcludePrateleiraPopup] =
    useState<boolean>(false)
  const [showIncludePrateleiraPopup, setShowIncludePrateleiraPopup] =
    useState<boolean>(false)
  const [showExcludeEstantePopup, setShowExcludeEstantePopup] =
    useState<boolean>(false)
  const [showIncludeEstantePopup, setShowIncludeEstantePopup] =
    useState<boolean>(false)
  const [showExcludeCorredorPopup, setShowExcludeCorredorPopup] =
    useState<boolean>(false)
  const [showIncludeCorredorPopup, setShowIncludeCorredorPopup] =
    useState<boolean>(false)
  const [showChangePrateleiraPopup, setShowChangePrateleiraPopup] =
    useState<boolean>(false)

  const [popupData, setPopupData] = useState<ReactNode>(<></>)
  const [id, setId] = useState<string>('')
  const [title, setTitle] = useState<string>('')
  const [idTipoCaixa, setIdTipoCaixa] = useState<string>('')
  const [tipoCaixa, setTipoCaixa] = useState<ITipoCaixa>()
  const { data: dtTipoCaixa, isLoading: isLoadingTipoCaixa } =
    useTipoCaixa.FindById(idTipoCaixa ?? '')

  useEffect(() => {
    if (dtTipoCaixa) {
      setTipoCaixa(dtTipoCaixa)
    }
  }, [idTipoCaixa, dtTipoCaixa])
  // PopPup (FIM)
  // Dados (FIM) ---------------------------------------------------------------

  // Úteis (INÍCIO) ------------------------------------------------------------

  // Recuperar listas (INÍCIO)

  const getListaCorredores = (): ReactNode[] => {
    let lista: ReactNode[] = []
    centroDocumentacao.corredor
      ?.sort((a, b) => {
        if (a.nCorredor > b.nCorredor) return 1
        if (a.nCorredor < b.nCorredor) return -1
        return 0
      })
      .forEach((cor) => {
        lista = [
          ...lista,
          <MenuItem
            value={cor.id_corredor}
            key={`ID-CORREDOR-${cor.id_corredor}`}
          >
            {cor.nCorredor}
          </MenuItem>
        ]
      })
    return lista
  }

  const getListaEstantes = (estantes: IEstante[]): ReactNode[] => {
    let lista: ReactNode[] = []
    estantes
      ?.filter((est) => est.ativo ?? true)
      .sort((a, b) => {
        if (a.nestante > b.nestante) return 1
        if (a.nestante < b.nestante) return -1
        return 0
      })
      .forEach((est) => {
        lista = [
          ...lista,
          <MenuItem value={est.id_estante} key={`ID-ESTANTE-${est.id_estante}`}>
            {est.identificacao}
          </MenuItem>
        ]
      })
    return lista
  }
  // Recuperar listas (FIM)
  //Úteis (FIM) ---------------------------------------------------------------

  // Handlers (INÍCIO) ---------------------------------------------------------

  // Busca de locais no CD (INÍCIO)
  const handleSearchClick = async () => {
    if (centroDocumentacao.corredor) {
      const data = searchElement.split('.')

      const corr = +data[0] ?? 1
      const est = +data[1] ?? 1
      const estPos = +data[2] ?? -1

      const newCorredor = getCorredorByPosicao(
        centroDocumentacao.corredor,
        corr
      )

      if (newCorredor.nCorredor === corr) {
        if (corredorAtual !== newCorredor.id_corredor) {
          setCorredorAtual(newCorredor.id_corredor)
          setCorredorView(newCorredor)
          setListaEstantes(getListaEstantes(newCorredor.estante ?? []))
        }

        const newEstantes = newCorredor.estante
        if (newEstantes) {
          const newEstante = getEstanteByPosicao(newEstantes, est)
          const newEst = newEstantes.find(
            (est) => est.id_estante === newEstante
          )
          if (newEst) {
            setEstanteAtual(newEstante)
            if (newEst.identificacao !== est.toString()) {
              setSearchElement(`${corr}.${newEst.nestante}`)
            }
            if (estPos > -1) {
              const eleID =
                'ID-' + centroDocumentacao.prefixo + '.' + searchElement

              await sleep(500)
              const element = document.getElementById(eleID)
              if (element) {
                element.scrollIntoView({ behavior: 'smooth' })
              }
              if (!element) {
                setSearchElement(`${corr}.${newEst.nestante}`)
              }
            }
          }
        }
        if (!newEstantes) {
          setEstanteAtual('')
        }
      }
      if (newCorredor.nCorredor !== corr) {
        setSearchElement(corredorView.identificacao)
      }
    }

    if (!centroDocumentacao.corredor) {
      setCorredorView(corredorBlank)
      setCorredorAtual('')
      setEstanteAtual('')
    }
  }

  const handleCorredorChange = (value: string) => {
    const newCorredor =
      centroDocumentacao.corredor?.find((cor) => cor.id_corredor === value) ??
      corredorBlank
    if (newCorredor && newCorredor.id_corredor !== corredorAtual) {
      setListaEstantes(getListaEstantes(newCorredor.estante ?? []))
      setCorredorView(newCorredor)
      setCorredorAtual(value)
      setEstanteAtual(getPrimeiraEstante({ corredor: newCorredor }))
    }
    setSearchElement('')
  }

  const handleAlternarPosicaoClick = (
    posicaoPrateleira: IPosicaoPrateleira
  ) => {
    alternarPosicaoPrateleira(posicaoPrateleira.id_posicao_prateleira)
  }

  const handleEstanteChange = (value: string) => {
    setEstanteAtual(value)
    setSearchElement('')
  }

  // Busca de locais no CD (FIM)

  // Itens de Menu de Contexto (INÍCIO)

  const handleMudarTipoCaixaPrateleira = (id_tipo_caixa: string) => {
    mudarPrateleira(id, id_tipo_caixa)
    handlePopupsClose()
  }

  const handlePopupsClose = () => {
    setShowExcludePrateleiraPopup(false)
    setShowIncludePrateleiraPopup(false)
    setShowChangePrateleiraPopup(false)
    setShowExcludePosicaoPopup(false)
    setShowIncludePosicaoPopup(false)

    setShowExcludeEstantePopup(false)
    setShowIncludeEstantePopup(false)
    setShowExcludeCorredorPopup(false)
    setShowIncludeCorredorPopup(false)

    setPopupData(<></>)
    setTitle('')
    setId('')
  }

  const handlePosicaoMenuExcludeClick = (
    posicao: IPosicaoPrateleira,
    enderecoPrateleira: string
  ) => {
    if (posicao) {
      setId(posicao.id_posicao_prateleira)
      setTitle('Excluir Posição de Prateleira e Subsequentes')
      setPopupData(
        <PositionDescriptor
          key={`TMP-ID-POSICAO-${posicao.id_posicao_prateleira}`}
          posicao={posicao}
          enderecoPrateleira={enderecoPrateleira}
        />
      )
      setShowExcludePosicaoPopup(true)
    }
  }

  const handleAdicionarPosicaoClick = (
    prateleira: IPrateleira,
    enderecoEstante: string
  ) => {
    if (prateleira) {
      setId(prateleira.id_prateleira)
      setPopupData(
        <Descriptor
          data={getPrateleiraSpace(prateleira)}
          title={`Prateleira: ${prateleira.identificacao} (${enderecoEstante}.${prateleira.identificacao}) `}
          showButton1={false}
          showButton2={false}
        />
      )
      setShowIncludePosicaoPopup(true)
    }
  }

  const handleExcluirPrateleiraClick = (
    prateleira: IPrateleira,
    enderecoEstante: string
  ) => {
    if (prateleira) {
      setId(prateleira.id_prateleira)
      setTitle('Excluir Prateleira e Subsequentes')
      setPopupData(
        <Descriptor
          title={`Prateleira: ${prateleira.identificacao} (${enderecoEstante}.${prateleira.identificacao})`}
          data={getPrateleiraSpace(prateleira)}
        />
      )
      setShowExcludePrateleiraPopup(true)
    }
  }

  const handleMudarPrateleiraTipoCaixaClick = (
    prateleira: IPrateleira,
    enderecoEstante: string
  ) => {
    if (prateleira) {
      setShowChangePrateleiraPopup(() => {
        const tipoCaixaInfo = (
          <TableRow>
            <TableCell>
              <Typography fontWeight={'bold'}>{`Tipo de Caixa`}</Typography>
            </TableCell>

            <TableCell>{tipoCaixa?.descricao}</TableCell>
          </TableRow>
        )
        setTitle('Mudar Tipo de Caixa')
        setPopupData(
          <Descriptor
            title={`Prateleira: ${prateleira.identificacao} (${enderecoEstante}.${prateleira.identificacao})`}
            data={{ ...getPrateleiraSpace(prateleira), other: tipoCaixaInfo }}
          />
        )
        return true
      })
    }
  }

  const handlePrateleiraSelect = (prateleira: IPrateleira) => {
    setId(prateleira.id_prateleira)
    setIdTipoCaixa(prateleira.id_tipo_caixa)
  }

  const handleAdicionarPrateleiraClick = (
    estante: IEstante,
    enderecoCorredor: string
  ) => {
    if (estante) {
      setId(estante.id_estante)
      setPopupData(
        <Descriptor
          data={getEstanteSpace(estante)}
          title={
            `Estante: ${estante.identificacao} ` +
            `(${enderecoCorredor}.${estante.identificacao}) `
          }
          showButton1={false}
          showButton2={false}
        />
      )
      setShowIncludePrateleiraPopup(true)
    }
  }

  const handleExcluirEstanteClick = (
    estante: IEstante,
    enderecoCorredor: string
  ) => {
    if (estante) {
      setId(estante.id_estante)
      setTitle('Excluir Estante e Subsequentes')
      setPopupData(
        <Descriptor
          title={`Estante: ${estante.identificacao} (${enderecoCorredor})`}
          data={getEstanteSpace(estante)}
        />
      )
      setShowExcludeEstantePopup(true)
    }
  }

  const handleAdicionarEstanteClick = (
    id_corredor: string,
    enderecoCentro: string
  ) => {
    const corredor = centroDocumentacao.corredor?.find(
      (corr) => corr.id_corredor === id_corredor
    )
    if (corredor) {
      setId(corredor.id_corredor)
      setPopupData(
        <Descriptor
          data={getCorredorSpace(corredor)}
          title={
            `Corredor: ${corredor.identificacao} ` +
            `(${enderecoCentro}.${corredor.identificacao}) `
          }
          showButton1={false}
          showButton2={false}
        />
      )
      setShowIncludeEstantePopup(true)
    }
  }

  const handleExcluirCorredorClick = (
    corredor: ICorredor,
    enderecoCentro: string
  ) => {
    if (corredor) {
      setId(corredor.id_corredor)
      setTitle('Excluir Corredor e Subsequentes')
      setPopupData(
        <Descriptor
          title={`Corredor: ${corredor.identificacao} (${enderecoCentro})`}
          data={getCorredorSpace(corredor)}
        />
      )
      setShowExcludeCorredorPopup(true)
    }
  }

  const handleAdicionarCorredorClick = () => {
    setId(centroDocumentacao.id_centrodocumentacao!)
    setPopupData(
      <Descriptor
        data={getCDSpace(centroDocumentacao)}
        title={`Centro de Documentacao: ${centroDocumentacao.prefixo} `}
        showButton1={false}
        showButton2={false}
      />
    )
    setShowIncludeCorredorPopup(true)
  }

  // Itens de Menu de Contexto (FIM)
  // Handlers (FIM) ------------------------------------------------------------

  if (isLoadingTipoCaixa) <Loading />

  return (
    <Stack
      id={'CDDraw-ROOT'}
      gap={2}
      sx={{
        marginTop: '14px'
      }}
    >
      <Box display="flex" flexDirection="row" gap={1}>
        <FormControl fullWidth>
          <InputLabel id="lbl-corredor">Corredor</InputLabel>
          <Select
            labelId="lbl-corredor"
            id="select-corredor"
            value={corredorAtual}
            label="Corredor"
            onChange={(event) =>
              handleCorredorChange(String(event.target.value))
            }
          >
            {getListaCorredores()}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="lbl-estante">Estante</InputLabel>
          <Select
            labelId="lbl-estante"
            id="select-estante"
            value={estanteAtual}
            label="Estante"
            onChange={(event) =>
              handleEstanteChange(String(event.target.value))
            }
          >
            {listaEstantes}
          </Select>
        </FormControl>
        <Input
          label="Pesquisa por Endereço"
          value={searchElement}
          onChange={(event) => {
            setSearchElement(String(event.target.value))
          }}
          size="medium"
        />
        <Button
          variant="outlined"
          startIcon={<SearchIcon />}
          onClick={handleSearchClick}
        ></Button>
      </Box>
      <Stack
        gap={6}
        direction={'row'}
        sx={{
          padding: '12px',
          // maxHeight: '700px',
          overflow: 'auto'
        }}
      >
        <Descriptor
          title={`Centro de Documentação: ${centroDocumentacao.prefixo}`}
          data={getCDSpace(centroDocumentacao)}
          showButton1={false}
        />
        {corredorView &&
        centroDocumentacao.corredor &&
        centroDocumentacao.corredor.length > 0 ? (
          <CorredorDraw
            corredor={corredorView}
            prefixoCD={centroDocumentacao.prefixo}
            indiceEstante={estanteAtual}
            handleExcluirPosicaoClick={handlePosicaoMenuExcludeClick}
            handleAdicionarPosicaoClick={handleAdicionarPosicaoClick}
            handleExcluirPrateleiraClick={handleExcluirPrateleiraClick}
            handleAdicionarPrateleiraClick={handleAdicionarPrateleiraClick}
            handleExcluirEstanteClick={handleExcluirEstanteClick}
            handleAdicionarEstanteClick={handleAdicionarEstanteClick}
            handleExcluirCorredorClick={handleExcluirCorredorClick}
            handleIncluirCorredorClick={handleAdicionarCorredorClick}
            handlePrateleiraSelect={handlePrateleiraSelect}
            handleMudarPrateleiraTipoCaixaClick={
              handleMudarPrateleiraTipoCaixaClick
            }
            handleAlternarPosicaoClick={handleAlternarPosicaoClick}
          />
        ) : (
          <>
            <Box>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <Tooltip title={'Adiciona corredores ao CD'}>
                        <Button
                          onClick={() => {
                            handleAdicionarCorredorClick()
                          }}
                          variant="contained"
                          color="success"
                        >
                          <LibraryAddIcon />
                        </Button>
                      </Tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </>
        )}
        {/* Diálogos (INÍCIO) ---------------------------------------------- */}

        {/* Diálogos - Posições (INÍCIO)*/}
        <ExcludePopup
          show={showExcludePosicaoPopup}
          title={title}
          close={handlePopupsClose}
          action={() => {
            excluirPosicaoPrateleira(id)
            handlePopupsClose()
          }}
          data={popupData}
        />

        <PosicaoPrateleiraIncludePopup
          action={(qtde: number) => {
            incluirPosicaoPrateleira(id, qtde)
            handlePopupsClose()
          }}
          close={handlePopupsClose}
          data={popupData}
          show={showIncludePosicaoPopup}
          title={'Expandir Prateleira'}
        />
        {/* Diálogo - Posições(INÍCIO)*/}

        {/* Diálogo - Prateleira (INÍCIO)*/}
        <ExcludePopup
          show={showExcludePrateleiraPopup}
          title={title}
          close={handlePopupsClose}
          action={() => {
            excluirPrateleira(id)
            handlePopupsClose()
          }}
          data={popupData}
        />

        <PrateleiraIncludePopup
          show={showIncludePrateleiraPopup}
          title={'Expandir Estante'}
          close={handlePopupsClose}
          action={(
            quantidadePrateleiras: number,
            quantidadePosicoes: number,
            idTipoCaixa: string
          ) => {
            incluirPrateleira(
              id,
              quantidadePrateleiras,
              quantidadePosicoes,
              idTipoCaixa
            )
            handlePopupsClose()
          }}
          data={popupData}
        />

        <PrateleiraChangePopup
          show={showChangePrateleiraPopup}
          title={'Expandir Estante'}
          close={handlePopupsClose}
          action={handleMudarTipoCaixaPrateleira}
          id_tipo_caixa={idTipoCaixa}
          data={popupData}
        />

        {/* Diálogo - Prateleira (FIM)*/}

        {/* Diálogo - Estante (INÍCIO)*/}
        <ExcludePopup
          show={showExcludeEstantePopup}
          title={title}
          close={handlePopupsClose}
          action={() => {
            excluirEstante(id)
            handlePopupsClose()
          }}
          data={popupData}
        />

        <EstanteIncludePopup
          show={showIncludeEstantePopup}
          title={'Expandir Corredor'}
          close={handlePopupsClose}
          action={(
            quantidadeEstantes: number,
            quantidadePrateleiras: number,
            quantidadePosicoes: number,
            idTipoCaixa: string
          ) => {
            incluirEstante(
              id,
              quantidadeEstantes,
              quantidadePrateleiras,
              quantidadePosicoes,
              idTipoCaixa
            )
            handlePopupsClose()
          }}
          data={popupData}
        />
        {/* Diálogo - Estante (FIM)*/}

        {/* Diálogo - Corredor (INÍCIO)*/}
        <ExcludePopup
          show={showExcludeCorredorPopup}
          title={title}
          close={handlePopupsClose}
          action={() => {
            excluirCorredor(id)
            handlePopupsClose()
          }}
          data={popupData}
        />

        <CorredorIncludePopup
          show={showIncludeCorredorPopup}
          title={'Expandir Centro de Documentação'}
          close={handlePopupsClose}
          action={(
            quantidadeCorredores: number,
            quantidadeEstantes: number,
            quantidadePrateleiras: number,
            quantidadePosicoes: number,
            idTipoCaixa: string
          ) => {
            incluirCorredor(
              id,
              quantidadeCorredores,
              quantidadeEstantes,
              quantidadePrateleiras,
              quantidadePosicoes,
              idTipoCaixa
            )
            handlePopupsClose()
          }}
          data={popupData}
        />
        {/* Diálogo - Corredor (FIM)*/}

        {/* Diálogos (FIM) ------------------------------------------------- */}
      </Stack>
    </Stack>
  )
}
