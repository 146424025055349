import { Box } from '@mui/material'
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartData
} from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

interface DashboardWidgetSummaryBodyPie {
  data: ChartData<'doughnut', number[], string>
}

export function DashboardWidgetSummaryBodyPie({
  data
}: DashboardWidgetSummaryBodyPie) {
  const options = {
    plugins: {
      legend: {
        display: false
      }
    }
  }
  ChartJS.register(ArcElement, Tooltip, Legend)
  return (
    <Box
      sx={{
        width: '250px',
        height: '250px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        margin: 0
      }}
    >
      <Doughnut data={data} options={options} />
    </Box>
  )
}
