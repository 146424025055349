import { IDocumentosEstatisticaData } from '../../../../../services/relatorio/types'
import { TemplateReport } from '../../../../../components/Report'
import { ApiEmpresa } from '../../../../../services/empresa'
import { format } from 'date-fns'

export interface FiltrosProps {
  id_empresa?: string
  data_inicio?: Date
  data_fim?: Date
}

type Props = {
  id_usuario: string
  data: IDocumentosEstatisticaData[]
  filtros: FiltrosProps
  mode?: 'open' | 'save'
}

export async function DocumentosEstatisticaRelatorio({
  data,
  mode,
  id_usuario,
  filtros
}: Props) {
  const filterReportText = async ({
    id_empresa,
    data_fim,
    data_inicio
  }: FiltrosProps) => {
    const filtersReport = []
    filtersReport.push({ text: 'Empresa: ', style: ['filterText', 'bold'] })
    if (id_empresa) {
      const empresa = await ApiEmpresa.findOne(id_empresa)
      if (empresa) {
        filtersReport.push({
          text: empresa.razao_social,
          style: ['filterText']
        })
      }
    } else filtersReport.push({ text: '<todas> ', style: ['filterText'] })

    filtersReport.push({ text: '\n' })
    filtersReport.push({ text: 'Período: ', style: ['filterText', 'bold'] })
    if (data_inicio && data_fim) {
      filtersReport.push({
        text: `${format(new Date(data_inicio), 'dd/MM/yyyy')} até ${format(
          new Date(data_fim),
          'dd/MM/yyyy'
        )}`,
        style: ['filterText']
      })
    } else filtersReport.push({ text: '<global> ', style: ['filterText'] })

    return filtersReport
  }

  let total_arquivos = 0
  let total_tamanho = 0
  let total_imagens = 0
  const documentosEstatisticaTable = () => {
    let rows: Array<any> = []
    if (data && data.length > 0) {
      rows = [
        [
          { text: 'Empresa', style: 'columnTitle' },
          { text: 'Qtde Arquivos', style: 'columnTitle' },
          { text: 'Tamanho (Mb)', style: 'columnTitle' },
          { text: 'Qtde Páginas', style: 'columnTitle' }
        ]
      ]
      data.forEach((empresa) => {
        total_arquivos += empresa.qtd_arquivos
        total_imagens += empresa.numero_paginas
        total_tamanho += empresa.tamanho_em_mb
        const row = [
          { text: empresa.nome_empresa, style: 'row' },
          {
            text: Math.round(empresa.qtd_arquivos).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }),
            style: 'quantityDoc'
          },
          {
            text: (
              Math.round(empresa.tamanho_em_mb * 100) / 100
            ).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }),
            style: 'quantityDoc'
          },
          {
            text: Math.round(empresa.numero_paginas).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }),
            style: 'quantityDoc'
          }
        ]

        rows.push(row as any)
      })
      return [
        {
          table: {
            widths: ['*', 80, 60, 65],
            headerRows: 1,
            body: rows
          }
        },
        {
          text: `Total de Empresas Analisadas: ${data.length}`,
          style: 'total'
        },
        {
          text: `Total de Arquivos: ${Math.round(total_arquivos).toLocaleString(
            'pt-BR',
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }
          )}`,
          style: 'total'
        },
        {
          text: `Tamanho Total (Mb): ${Math.round(total_tamanho).toLocaleString(
            'pt-BR',
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }
          )}`,
          style: 'total'
        },
        {
          text: `Total de Imagens: ${Math.round(total_imagens).toLocaleString(
            'pt-BR',
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }
          )}`,
          style: 'total'
        }
      ]
    } else {
      return [{ text: '\n' }]
    }
  }

  const filterText = await filterReportText(filtros)
  const content = documentosEstatisticaTable()

  await TemplateReport({
    id_usuario,
    content,
    filterText,
    reportTitle: 'Estatística de Armazenamento dos Documentos',
    mode
  })
}
