import { ICorredor } from '../corredor/types'
import { IEndereco } from '../endereco/types'

export interface ICentroDocumentacao {
  id_centrodocumentacao?: string
  nome: string
  prefixo: string
  empresa: {
    id_empresa: string
    nome: string
  }
  endereco: IEndereco

  id_empresa: string

  identificacao_corredor: string
  nCorredor: number

  identificacao_estante: string
  nestante: number

  identificacao_prateleira: string
  nprateleira: number

  identificacao_posicao_prateleira: string
  posicao_prateleira: number

  corredor?: ICorredor[]
}

export const centroDocumentacaoBlank: ICentroDocumentacao = {
  id_centrodocumentacao: '',
  empresa: {
    id_empresa: '',
    nome: ''
  },
  endereco: {
    id_endereco: '',
    logradouro: '',
    id_municipio: '',
    cep: '',
    numero: '',
    bairro: ''
  },
  id_empresa: '',
  identificacao_corredor: '',
  nCorredor: 0,
  identificacao_estante: '',
  nestante: 0,
  identificacao_prateleira: '',
  nprateleira: 0,
  identificacao_posicao_prateleira: '',
  posicao_prateleira: 0,
  nome: '',
  prefixo: ''
}
