import api from '../api'

const endpoint = '/estado-atividade'

export const listAll = async () => {
  const { data } = await api.get<string[]>(`${endpoint}`)
  return data
}

export const findOne = async (id: string) => {
  const { data } = await api.get<string>(`${endpoint}/${id}`)
  return data
}

export const ApiEstadoAtividade = {
  listAll,
  findOne
}
