import { ApiCaixa } from '../services/caixa'
import { ApiDocumento } from '../services/documento'
import { ILote } from '../services/lote/types'
import { ApiPessoa } from '../services/pessoa'
import { IPessoa } from '../services/pessoa/types'
import { ApiRequisicao } from '../services/requisicao'
import { IRequisicao } from '../services/requisicao/types'
import { getCodigo } from './getCodigo'

interface ICaixaProtocoloRequisicao {
  tipoCaixa: string
  identificacao: string
  quantidadeDocumentos: number
  privacidade: string
  endereco?: string
}

interface IDocumentoProtocoloRequisicao {
  tipoDocumento: string
  descricao: string
  privacidade: string
  endereco?: string
}

export interface IProtocoloRequisicaoData {
  id_lote: string
  usuario: IPessoa
  caixas?: ICaixaProtocoloRequisicao[]
  documentos?: IDocumentoProtocoloRequisicao[]
  requisicao: IRequisicao
  id_usuario_validou?: string
  validado_em?: string
  tokenRequisicao?: string
  observacao?: string
}

export async function loteToProcoloRequisicao(
  lote: ILote
): Promise<IProtocoloRequisicaoData> {
  const requisicao = await ApiRequisicao.findOne(lote.id_requisicao)
  const pessoa = await ApiPessoa.findByIdUsuario(requisicao.id_usuario)
  const caixas: Array<ICaixaProtocoloRequisicao> = []
  const documentos: Array<IDocumentoProtocoloRequisicao> = []

  for (const itemRequisicao of requisicao.itens_requisicao!) {
    const movimentacao = lote.itens_lote.find((itemLote) =>
      itemRequisicao.movimentacoes.some(
        (mov) => mov.id_movimentacao === itemLote.id_movimentacao
      )
    )

    if (!movimentacao) continue
    if (itemRequisicao.id_caixa) {
      const caixa = await ApiCaixa.findOne(itemRequisicao.id_caixa)
      if (caixa) {
        const complemento = caixa.id_caixa_container
          ? ` / ${getCodigo(caixa.id_caixa_container).substring(0, 9)}`
          : ''
        caixas.push({
          tipoCaixa: caixa.tipoCaixa?.descricao ?? '',
          identificacao: caixa.identificacao,
          quantidadeDocumentos: caixa.documentos?.length ?? 0,
          endereco: caixa.endereco + complemento,
          privacidade:
            (caixa.dados_pessoais ? '  P  ' : '') +
            (caixa.dados_pessoais_sensiveis ? '  S  ' : '') +
            (caixa.dados_criancas_adolescentes ? '  C  ' : '')
        })
      }
    } else {
      const documento = await ApiDocumento.findOne(itemRequisicao.id_documento)
      const complemento = documento.caixa
        ? ` / ${getCodigo(documento.id_caixa).substring(0, 9)}`
        : ''
      if (documento) {
        documentos.push({
          descricao: documento.descricao ?? '',
          tipoDocumento: documento.tipo_documento?.descricao ?? '',
          endereco: documento.endereco + complemento,
          privacidade:
            (documento.dados_pessoais ? '  P  ' : '') +
            (documento.dados_pessoais_sensiveis ? '  S  ' : '') +
            (documento.dados_criancas_adolescentes ? '  C  ' : '')
        })
      }
    }
  }

  return {
    id_lote: lote.id_lote ?? '',
    usuario: pessoa,
    caixas,
    documentos,
    requisicao
  }
}
