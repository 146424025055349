import { DefaultApi } from '../../utils/defaultApi'
import api from '../api'
import { IMensagem } from './types'

const endpoint = '/mensagem'
const resourceId = 'id_mensagem'

class ServiceMensagem extends DefaultApi<IMensagem> {
  findAllMessages = async () => {
    const { data } = await api.get<IMensagem[]>(`${this.endpoint}`)
    return data
  }

  findBySender = async (id: string): Promise<IMensagem[]> => {
    const { data } = await api.get<IMensagem[]>(
      `${this.endpoint}/remetente/${id}`
    )
    return data
  }

  findByReceiver = async (id: string): Promise<IMensagem[]> => {
    const { data } = await api.get<IMensagem[]>(
      `${this.endpoint}/destinatario/${id}`
    )
    return data
  }

  findByRequisicao = async (id: string): Promise<IMensagem[]> => {
    const { data } = await api.get<IMensagem[]>(
      `${this.endpoint}/requisicao/${id}`
    )
    return data
  }
}

export const ApiMensagem = new ServiceMensagem(endpoint, resourceId)
