import { DefaultApi } from '../../utils/defaultApi'
import api from '../api'
import { ICorredor } from './types'

const endpoint = '/corredor'
const resourceId = 'id_corredor'

class ServiceCorredor extends DefaultApi<ICorredor> {
  listAll = async () => {
    const { data } = await api.get<ICorredor[]>(`${endpoint}/lista/`)
    return data
  }

  findByCentro = async (id_centrodocumentacao: string) => {
    if (id_centrodocumentacao === '' || id_centrodocumentacao === '0') {
      return []
    }
    const { data } = await api.get<ICorredor[]>(
      `${endpoint}/centro/${id_centrodocumentacao}`
    )
    return data
  }
}

const apiCorredor = new ServiceCorredor(endpoint, resourceId)

export const ApiCorredor = {
  listAll: apiCorredor.listAll,
  findByCentro: apiCorredor.findByCentro
}
