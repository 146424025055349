import React, { useMemo, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search'
import EditIcon from '@mui/icons-material/Edit'
import { useEmpresa } from '../../../hooks/empresa/useEmpresa'
import { useContratoPermitido } from '../../../hooks/contratoPermitido/useContratoPermitido'
import { useUsuario } from '../../../hooks/usuario/useUsuario'
import { FormMode } from '../../../types/formMode'
import { Loading } from '../../../components/Loading'

interface IForm {
  setData: (value: React.SetStateAction<any>) => void
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

export function List({ setData, setFormMode }: IForm) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [q, setQ] = useState<string>('')
  const searchInput = React.useRef<HTMLInputElement>(null)

  const handleSearchClick = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setSearchParams({ ...searchParams, q: searchTerm })
    setQ(searchTerm)
    searchInput.current?.focus()
  }

  const page = useMemo(() => {
    return Number(searchParams.get('page') ?? 1)
  }, [searchParams])

  const { data: usuario } = useUsuario.FindByUsername()
  const empresaUsuario = usuario?.pessoa.setor.empresa?.id_empresa ?? ''
  const { data: userContratos = [] } =
    useContratoPermitido.FindByUsuarioLogado()
  if (empresaUsuario) {
    userContratos.push({
      id_contrato: empresaUsuario,
      descricao: '',
      id_empresa_contratada: '',
      id_empresa_contratante: '',
      created_at: new Date(),
      uspdate_at: new Date()
    })
  }
  const { data: records, isLoading } = useEmpresa.FindAllByUserContratos(
    page,
    userContratos ?? [],
    q
  )

  const handleEditForm = (data: any) => {
    setData(data)
    setFormMode('EDIT')
  }

  if (isLoading) return <Loading />

  return (
    <Box>
      <Stack
        component="form"
        onSubmit={handleSearchClick}
        direction="row"
        py={3}
        gap={3}
        mb={2}
      >
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          placeholder="Nome/Razão Social"
          inputRef={searchInput}
          autoFocus
          value={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value)
          }}
        />
        <Button variant="outlined" startIcon={<SearchIcon />} type="submit">
          Pesquisar
        </Button>
      </Stack>

      <Divider />
      <TableContainer
        component={Box}
        border={1}
        borderRadius={2}
        borderColor="#f2f2f2"
      >
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Nome
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Razão Social
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Papel
              </TableCell>
              <TableCell width="12px" />
            </TableRow>
          </TableHead>
          <TableBody>
            {records?.data?.map((record) => (
              <TableRow key={record.id_empresa} hover>
                <TableCell>{record.nome}</TableCell>
                <TableCell>{record.razao_social}</TableCell>
                <TableCell>{record.papel.descricao}</TableCell>

                <TableCell>
                  <Tooltip title="Editar" arrow>
                    <Button
                      variant="outlined"
                      sx={{ minWidth: '24px', padding: '6px' }}
                      onClick={() => handleEditForm(record)}
                    >
                      <EditIcon />
                    </Button>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {records?.pagination &&
        records.pagination.totalItems > records.pagination.itemsPerPage && (
          <Pagination
            count={records?.pagination.totalPages}
            sx={{ marginTop: '12px' }}
            onChange={(_, newPage) =>
              setSearchParams({ page: String(newPage) })
            }
            page={records.pagination.currentPage}
          />
        )}
    </Box>
  )
}
