import { useMemo, useState } from 'react'
import {
  Box,
  Button,
  Chip,
  Divider,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/AddCircle'
import EditIcon from '@mui/icons-material/Edit'
import ClearIcon from '@mui/icons-material/Clear'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import useToast from '../../../hooks/toast/useToast'
import { FormMode } from '../../../types/formMode'
import { Loading } from '../../../components/Loading'
import React from 'react'
import { formataSomenteData } from '../../../utils/formataData'
import {
  formatarComoMoeda,
  primeiraMaiuscula
} from '../../../utils/formataStrings'
import { Dialog } from '../../../components/Dialog'
import { useRecorrencia } from '../../../hooks/financeiro/recorrencia/useRecorrencia'

interface IForm {
  setData: (value: React.SetStateAction<any>) => void
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

export function List({ setData, setFormMode }: IForm) {
  const [showDismiss, setShowDismiss] = useState(false)
  const [showDismissGerar, setShowDismissGerar] = useState(false)
  const [currentLancamento, setCurrentLancamento] = useState<string>('')
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [q, setQ] = useState<string>('')
  const [selectedOrder, setSelectedOrder] = useState<string>('Inicio')
  const { Toast } = useToast()
  const searchInput = React.useRef<HTMLInputElement>(null)

  const handleSearchClick = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setSearchParams({ ...searchParams, q: searchTerm })
    setQ(searchTerm)
    searchInput.current?.focus()
  }

  const page = useMemo(() => {
    return Number(searchParams.get('page') ?? 1)
  }, [searchParams])

  const { mutateAsync: cancel } = useRecorrencia.Cancel()
  const { mutateAsync: generate } = useRecorrencia.Generate()
  const { data: records, isLoading } = useRecorrencia.FindAll(
    page,
    selectedOrder,
    q
  )

  const handleNewForm = () => {
    setFormMode('CREATE')
  }

  const handleCancel = async () => {
    setShowDismiss(false)
    await Toast(cancel(currentLancamento), 'REMOVED')
  }

  const handleEditForm = (selectedData: any) => {
    setData(selectedData)
    setFormMode('EDIT')
  }

  const handleGenerate = async () => {
    setShowDismissGerar(false)
    await Toast(generate(currentLancamento), 'UPDATED')
  }
  if (isLoading) return <Loading />

  return (
    <Box>
      <Stack
        component="form"
        onSubmit={handleSearchClick}
        direction="row"
        py={3}
        gap={3}
        mb={2}
      >
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          placeholder="Parceiro/Número do documento/Forma de Pagamento/Centro de Custo"
          inputRef={searchInput}
          autoFocus
          value={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value)
          }}
        />
        <Button variant="outlined" startIcon={<SearchIcon />} type="submit">
          Pesquisar
        </Button>
      </Stack>

      <Divider />

      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleNewForm}
        sx={{ float: 'right', my: '12px' }}
      >
        Novo registro
      </Button>

      <TableContainer
        component={Box}
        border={1}
        borderRadius={2}
        borderColor="#f2f2f2"
      >
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Número documento
              </TableCell>
              <TableCell
                sx={{ fontWeight: 'bold', fontSize: '14px', cursor: 'pointer' }}
                onClick={() => {
                  selectedOrder === 'Parceiro'
                    ? setSelectedOrder('ParceiroInv')
                    : setSelectedOrder('Parceiro')
                }}
              >
                Cliente / Fornecedor
                {selectedOrder === 'Parceiro' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Tipo
              </TableCell>
              <TableCell
                sx={{ fontWeight: 'bold', fontSize: '14px', cursor: 'pointer' }}
                onClick={() => {
                  selectedOrder === 'Inicio'
                    ? setSelectedOrder('InicioInv')
                    : setSelectedOrder('Inicio')
                }}
              >
                Início
                {selectedOrder === 'Inicio' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>
              <TableCell
                sx={{ fontWeight: 'bold', fontSize: '14px', cursor: 'pointer' }}
                onClick={() => {
                  selectedOrder === 'Termino'
                    ? setSelectedOrder('TerminoInv')
                    : setSelectedOrder('Termino')
                }}
              >
                Término
                {selectedOrder === 'Termino' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>
              <TableCell
                sx={{ fontWeight: 'bold', fontSize: '14px', cursor: 'pointer' }}
                onClick={() => {
                  selectedOrder === 'Frequencia'
                    ? setSelectedOrder('FrequenciaInv')
                    : setSelectedOrder('Frequencia')
                }}
              >
                Frequência
                {selectedOrder === 'Frequencia' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Status
              </TableCell>
              <TableCell
                sx={{ fontWeight: 'bold', fontSize: '14px', cursor: 'pointer' }}
                onClick={() => {
                  selectedOrder === 'Valor'
                    ? setSelectedOrder('ValorInv')
                    : setSelectedOrder('Valor')
                }}
              >
                Valor
                {selectedOrder === 'Valor' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>
              <TableCell width="12px" />
              <TableCell width="12px" />
              <TableCell width="12px" />
            </TableRow>
          </TableHead>
          <TableBody>
            {records?.data?.map((record) => (
              <TableRow key={record.id_recorrencia} hover>
                <TableCell>{record.num_documento}</TableCell>
                <TableCell>{record.parceiro?.nome}</TableCell>
                <TableCell>
                  <Chip
                    label={record.tipo_lancamento === 'P' ? 'Pagar' : 'Receber'}
                    color={record.tipo_lancamento === 'P' ? 'error' : 'success'}
                    size="small"
                    variant="filled"
                  />
                </TableCell>
                <TableCell>
                  {formataSomenteData(record.dt_inicio?.toString() ?? '')}
                </TableCell>
                <TableCell>
                  {formataSomenteData(record.dt_termino?.toString() ?? '')}
                </TableCell>
                <TableCell>{primeiraMaiuscula(record.frequencia)}</TableCell>
                <TableCell>
                  <Chip
                    label={primeiraMaiuscula(record.status)}
                    color={record.status === 'RASCUNHO' ? 'warning' : 'success'}
                    size="small"
                    variant="filled"
                  />
                </TableCell>
                <TableCell>{formatarComoMoeda(record.valor)}</TableCell>
                <TableCell>
                  {record.status !== 'CANCELADA' && (
                    <Tooltip title="Editar" arrow>
                      <Button
                        variant="outlined"
                        sx={{ minWidth: '24px', padding: '6px' }}
                        onClick={() => handleEditForm(record)}
                      >
                        <EditIcon />
                      </Button>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell>
                  <Tooltip title="Cancelar" arrow>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        setCurrentLancamento(record.id_recorrencia!)
                        setShowDismiss(true)
                      }}
                      sx={{ minWidth: '24px', padding: '6px' }}
                    >
                      <ClearIcon />
                    </Button>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {record.status === 'RASCUNHO' && (
                    <Tooltip title="Gerar" arrow>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          setCurrentLancamento(record.id_recorrencia!)
                          setShowDismissGerar(true)
                        }}
                        sx={{ minWidth: '24px', padding: '6px' }}
                      >
                        <AttachMoneyIcon />
                      </Button>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {records?.pagination &&
        records.pagination.totalItems > records.pagination.itemsPerPage && (
          <Pagination
            count={records?.pagination.totalPages}
            sx={{ marginTop: '12px' }}
            onChange={(_, newPage) =>
              setSearchParams({ page: String(newPage) })
            }
            page={records.pagination.currentPage}
          />
        )}

      <Dialog
        handleAnswerNo={() => {
          setShowDismiss(false)
        }}
        handleAnswerYes={() => {
          handleCancel()
        }}
        messageText={
          <>
            <p>
              Tem certeza que deseja <strong>EXCLUIR</strong> esta Recorrência?
            </p>
            <p>
              Em caso afirmativo, o mesmo ficará cancelado e os lançamentos
              gerados que ainda estão pendentes também serão cancelados.
            </p>
            <p>
              <strong>IMPORTANTE: </strong>Esta ação não poderá ser desfeita.
            </p>
          </>
        }
        title="Atenção"
        openDialog={showDismiss}
      />

      <Dialog
        handleAnswerNo={() => {
          setShowDismissGerar(false)
        }}
        handleAnswerYes={() => {
          handleGenerate()
        }}
        messageText={
          <>
            <p>
              Tem certeza que deseja <strong>GERAR</strong> os lançamentos desta
              recorrência?
            </p>
            <p>Em caso afirmativo, a mesma não poderar mais ser alterada</p>
            <p>
              <strong>IMPORTANTE: </strong>Esta ação não poderá ser desfeita.
            </p>
          </>
        }
        title="Atenção"
        openDialog={showDismissGerar}
      />
    </Box>
  )
}
