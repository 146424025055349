import { format } from 'date-fns'
import { IPrazoGuardaData } from '../../../../../../services/relatorio/types'
import {
  groupByField,
  iterateGroupedData
} from '../../../../../../utils/report'
import { TemplateReport } from '../../../../../../components/Report'
import { ApiEmpresa } from '../../../../../../services/empresa'
import ptBR from 'date-fns/locale/pt-BR'
import { Content, PageOrientation } from 'pdfmake/interfaces'
import { ApiSetor } from '../../../../../../services/setor'
import { ApiTipoTemporalidade } from '../../../../../../services/tipoTemporalidade'

type Props = {
  id_usuario: string
  data: IPrazoGuardaData[]
  mode?: 'open' | 'save'
  orientation?: PageOrientation
  filtros: {
    id_empresa?: string
    id_setor?: string
    data_inicio?: Date
    data_fim?: Date
    tipoConteudo?: string
    id_tipo_temporalidade?: string
    tipoPrazo?: string
  }
}

export async function PrazoGuardaReport({
  id_usuario,
  data,
  mode,
  filtros
}: Props) {
  const dadosAgrupados = groupByField(data, 'nome_empresa')

  const filterReportText = async () => {
    const {
      id_empresa,
      data_fim,
      data_inicio,
      id_setor,
      tipoConteudo,
      id_tipo_temporalidade,
      tipoPrazo
    } = filtros
    const filtersReport = []
    filtersReport.push({ text: 'Empresa: ', style: ['filterText', 'bold'] })
    if (id_empresa) {
      const empresa = await ApiEmpresa.findOne(id_empresa)
      if (empresa) {
        filtersReport.push({
          text: empresa.razao_social,
          style: ['filterText']
        })
      }
    } else filtersReport.push({ text: '<todas> ', style: ['filterText'] })

    filtersReport.push({ text: '\n' })
    filtersReport.push({ text: 'Setor: ', style: ['filterText', 'bold'] })
    if (id_setor) {
      const setor = await ApiSetor.findOne(id_setor)
      if (setor) {
        filtersReport.push({
          text: setor.nome,
          style: ['filterText']
        })
      }
    } else filtersReport.push({ text: '<todas> ', style: ['filterText'] })

    filtersReport.push({ text: '\n' })
    filtersReport.push({
      text: 'Tipo de Conteúdo: ',
      style: ['filterText', 'bold']
    })
    filtersReport.push({
      text:
        tipoConteudo === '0'
          ? '<todos>'
          : tipoConteudo === '1'
          ? 'Caixa'
          : 'Documento',
      style: ['filterText']
    })

    filtersReport.push({ text: '\n' })
    filtersReport.push({
      text: 'Prazo: ',
      style: ['filterText', 'bold']
    })
    filtersReport.push({
      text:
        tipoPrazo === '0'
          ? '<todos>'
          : tipoPrazo === '1'
          ? 'Vencendo'
          : 'Vencido',
      style: ['filterText']
    })

    filtersReport.push({ text: '\n' })
    filtersReport.push({
      text: 'Tipo Temporalidade: ',
      style: ['filterText', 'bold']
    })
    if (id_tipo_temporalidade) {
      const tipoTemporalidade = await ApiTipoTemporalidade.findOne(
        id_tipo_temporalidade
      )
      if (tipoTemporalidade) {
        filtersReport.push({
          text: `${tipoTemporalidade.tempo} - ${tipoTemporalidade.unidade} - ${tipoTemporalidade.fundamentacao} `,
          style: ['filterText']
        })
      }
    } else filtersReport.push({ text: '<todos> ', style: ['filterText'] })

    filtersReport.push({ text: '\n' })
    filtersReport.push({ text: 'Período: ', style: ['filterText', 'bold'] })
    if (data_inicio && data_fim) {
      filtersReport.push({
        text: `${format(new Date(data_inicio), 'dd/MM/yyyy', {
          locale: ptBR
        })} até ${format(new Date(data_fim), 'dd/MM/yyyy', { locale: ptBR })}`,
        style: ['filterText']
      })
    } else filtersReport.push({ text: '<global> ', style: ['filterText'] })

    return filtersReport
  }

  const lotesPorEmpresaGroupTable = () => {
    const tables: Array<any> = []
    let rows: Array<any> = []
    if (dadosAgrupados) {
      iterateGroupedData(dadosAgrupados, (empresa, lotes) => {
        rows = [
          [
            { text: 'Id', style: 'columnTitle' },
            { text: 'Tipo Caixa', style: 'columnTitle' },
            { text: 'Endereço', style: 'columnTitle' },
            { text: 'Conteúdo do Lote', style: 'columnTitle' },
            { text: 'Tipo Documento', style: 'columnTitle' },
            { text: 'Privacidade', style: 'columnTitle' },
            { text: 'Data de Cadastro', style: 'columnTitle' },
            { text: 'Tempo', style: 'columnTitle' },
            { text: 'Prazo Guarda', style: 'columnTitle' }
          ]
        ]

        lotes.forEach((lote) => {
          const row = [
            { text: lote.id_caixa, style: 'row' },
            { text: lote.tipo_caixa, style: 'rowCenter' },
            { text: lote.endereco, style: 'rowCenter' },
            { text: lote.conteudo, style: 'row' },
            { text: lote.descricao_tipo_documento, style: 'row' },
            { text: lote.privacidade, style: 'row' },
            {
              text: format(new Date(lote.data_criacao), 'dd/MM/yyyy'),
              style: 'rowCenter'
            },
            { text: lote.temporalidade, style: 'rowCenter' },
            {
              text: format(new Date(lote.guarda_obrigatoria), 'dd/MM/yyyy'),
              style: 'rowCenter'
            }
          ]

          rows.push(row as any)
        })

        tables.push([
          {
            text: `Empresa: ${empresa} \nRazão Social: ${lotes[0].nome_empresa}`,
            style: 'subtitle'
          },
          {
            table: {
              widths: [75, 65, 50, 210, 80, 65, 55, 50, 50],
              headerRows: 1,
              body: rows
            }
          }
        ])
      })
      return tables
    } else {
      return [{ text: '\n' }]
    }
  }

  const sumarizarQuantidadesPorTipoCaixa = () => {
    const quantidadePorTipoCaixa: Record<string, number> = data.reduce(
      (accumulator, item) => {
        const { tipo_caixa } = item
        accumulator[tipo_caixa] = (accumulator[tipo_caixa] || 0) + 1
        return accumulator
      },
      {} as Record<string, number>
    )
    const summaryReport: Content = []
    Object.keys(quantidadePorTipoCaixa).forEach((tipo) => {
      summaryReport.push({
        text: `Total ${tipo}: `,
        style: ['summaryText', 'bold']
      })
      summaryReport.push({
        text: `${quantidadePorTipoCaixa[tipo]}`,
        style: ['summaryText']
      })
      summaryReport.push({ text: '\n' })
    })
    return summaryReport
  }
  await TemplateReport({
    id_usuario,
    reportTitle: 'Prazo Guarda',
    mode,
    pageOrientation: 'landscape',
    content: lotesPorEmpresaGroupTable(),
    filterText: await filterReportText(),
    summary: sumarizarQuantidadesPorTipoCaixa()
  })
}
