import { Box, Button, Stack } from '@mui/material'
import SaveIcon from '@mui/icons-material/CheckCircleOutlined'
import CancelIcon from '@mui/icons-material/DoDisturbAltOutlined'
import { useEffect, useState } from 'react'
import { Xwrapper } from 'react-xarrows'

import { IServico } from '../../../services/servico/types'
import { ITipoAtividade } from '../../../services/tipoAtividade/types'
import { FormMode } from '../../../types/formMode'
import { FormHeader } from './components/FormHeader'
import { DrawArea } from './components/DrawArea'
import { ApiTipoAtividade } from '../../../services/tipoAtividade'
import useToast from '../../../hooks/toast/useToast'
import { Loading } from '../../../components/Loading'
import { ApiServico } from '../../../services/servico'

interface IForm {
  data: IServico
  formMode: FormMode
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

export type TTipoAcao = 'SELECIONA' | 'APAGA' | 'PADRAO' | 'ALTERNATIVO'

export type TNo = 'INICIO' | 'FIM' | 'PADRAO' | 'ALTERNATIVO'

export interface ItemGrafico {
  x: number
  xFinal: number
  y: number
  yFinal: number
  tipo: TNo
  proximo?: string
  alternativo?: string
  tipoAtividade?: ITipoAtividade
  id: string
}

export function Form({ data, formMode, setFormMode }: IForm) {
  const [acao, setAcao] = useState<TTipoAcao>('SELECIONA')
  const [grafico, setGrafico] = useState<ItemGrafico[]>([])
  const [listTiposAtividades, setListTiposAtividades] = useState<
    ITipoAtividade[]
  >([])
  const [servico, setServico] = useState<IServico>({
    id_servico: '',
    nome: '',
    descricao: '',
    modelagem_fluxo: '',
    interno: false,
    em_uso: false
  })
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const toast = useToast()
  const { Toast } = useToast()

  function changeServico(newServico: IServico): void {
    setServico(newServico)
  }

  function changeAcao(newAcao: TTipoAcao) {
    setAcao(newAcao)
  }

  /**
   * Modificar a lista de itens do gráfico.
   *
   * Antes da modificação os vínculos são validados. Itens podem mudar de tipo,
   * de acordo com os ligações que recebe ou que seus antecessores recebem
   *
   * @param newGrafico Nova lista de dados
   * @returns Nada
   */
  function changeGrafico(newGrafico: ItemGrafico[]) {
    function ValidarGrafico() {
      return true
    }

    if (!ValidarGrafico()) {
      return false
    }

    //Caso alguma atividade tenha sido removida do gráfico, ela deve voltar
    //a combobox de adição de atividade (INÍCIO)
    const deletedTipoAtividade = grafico.find(
      (oldItem) => !newGrafico.some((newItem) => oldItem.id === newItem.id)
    )
    if (!!deletedTipoAtividade && !!deletedTipoAtividade.tipoAtividade) {
      const tipoAtividadeToAdd = deletedTipoAtividade.tipoAtividade
      setListTiposAtividades((currentState) =>
        [...currentState, tipoAtividadeToAdd].sort((a, b) => {
          if (a.descricao.toLocaleUpperCase() < b.descricao.toLocaleUpperCase())
            return -1
          if (a.descricao.toLocaleUpperCase() > b.descricao.toLocaleUpperCase())
            return 1
          return 0
        })
      )
    }
    //Caso alguma atividade tenha sido removida do gráfico, ela deve voltar
    //a combobox de adição de atividade (FIM)

    //Modificação de tipo de item de gráfico para CANCELADO, caso algum
    //antecessor tenha o status de cancelado (INÍCIO)

    //Para garantir a definição do status de ALTERNATIVO ser definido apenas
    //para os itens necessários, todos são redefinidos como PADRAO
    newGrafico.forEach(
      (item) =>
        (item.tipo =
          item.tipo !== 'INICIO' && item.tipo !== 'FIM' ? 'PADRAO' : item.tipo)
    )

    let modificado = false
    do {
      modificado = false
      for (let i = 0; i < newGrafico.length; i++) {
        //Caso um item esteja com tipo PADRAO, ele deve ser modificado para
        //ALTERNATIVO, quando for vinculado como fluxo alternativo
        if (newGrafico[i].alternativo) {
          const itemAlternativo = newGrafico.find(
            (item) => item.id === newGrafico[i].alternativo
          )!
          if (itemAlternativo.tipo === 'PADRAO') {
            modificado = true
            itemAlternativo.tipo = 'ALTERNATIVO'
          }
        }

        //Se o antecessor de um item for ALTERNATIVO, ele deve ser modificado
        //para alternativo
        if (newGrafico[i].tipo === 'ALTERNATIVO' && newGrafico[i].proximo) {
          const itemAlternativo = newGrafico.find(
            (item) => item.id === newGrafico[i].proximo
          )!
          if (itemAlternativo.tipo === 'PADRAO') {
            modificado = true
            itemAlternativo.tipo = 'ALTERNATIVO'
          }
        }
      }
    } while (modificado)
    //Modificação de tipo de item de gráfico para CANCELADO, caso algum
    //antecessor tenha o status de cancelado (INÍCIO)

    //Redefinição dos itens do gráfico
    setGrafico(newGrafico)
  }

  //Cria os dados iniciais do gráfico (ponto inicial e final) e carrega a lista
  //de tipos de atividades disponíveis (INÍCIO)
  useEffect(() => {
    //Recuperação dos tipos de atividade disponíveis (INÍCIO)
    const fetchTiposAtividades = async (): Promise<ITipoAtividade[]> => {
      setIsLoading(true)
      const tiposAtividades = await ApiTipoAtividade.listAll()
      if (tiposAtividades) {
        return tiposAtividades.sort((a, b) => {
          if (a.descricao.toLocaleUpperCase() < b.descricao.toLocaleUpperCase())
            return -1
          if (a.descricao.toLocaleUpperCase() > b.descricao.toLocaleUpperCase())
            return 1
          return 0
        })
      }
      return []
    }
    //Recuperação dos tipos de atividade disponíveis (FIM)

    //Os dados da tela dependem do carregamento da lista de atividades
    const loadData = async () => {
      const tiposAtividades = await fetchTiposAtividades()
      let listaTiposAtividades = [...tiposAtividades]
      if (data.id_servico) {
        setServico(data)
      }
      if (data.modelagem_fluxo && data.modelagem_fluxo.trim().length > 0) {
        const itensFluxo = JSON.parse(data.modelagem_fluxo)

        const newGrafico = (itensFluxo as Array<any>).map((itemFluxo) => {
          const tipoAtividade = listaTiposAtividades.find(
            (item) => item.id_tipo_atividade === itemFluxo.id
          )

          listaTiposAtividades = listaTiposAtividades.filter(
            (item) =>
              item.id_tipo_atividade !== tipoAtividade?.id_tipo_atividade
          )
          return {
            x: itemFluxo.x,
            xFinal: itemFluxo.x,
            y: itemFluxo.y,
            yFinal: itemFluxo.y,
            tipo: itemFluxo.tipo,
            proximo: itemFluxo.proximo,
            alternativo: itemFluxo.alternativo,
            id: itemFluxo.id,
            tipoAtividade: tipoAtividade
          } as ItemGrafico
        })
        setGrafico(newGrafico)
      } else {
        //Criação do ponto inicial e final do grafo (INÍCIO)
        const inicio = {
          x: 20,
          xFinal: 20,
          y: 20,
          yFinal: 20,
          tipo: 'INICIO',
          proximo: undefined,
          alternativo: undefined,
          tipoAtividade: undefined,
          id: 'INICIO'
        } as ItemGrafico

        const fim = {
          x: 950,
          xFinal: 950,
          y: 605,
          yFinal: 605,
          tipo: 'FIM',
          proximo: undefined,
          alternativo: undefined,
          tipoAtividade: undefined,
          id: 'FIM'
        } as ItemGrafico
        setGrafico([inicio, fim])

        //Criação do ponto inicial e final do grafo (FIM)
      }
      setListTiposAtividades(listaTiposAtividades)
      setIsLoading(false)
    }

    loadData()
  }, [data, setFormMode])
  //Cria os dados iniciais do gráfico (ponto inicial e final) e carrega a lista
  //de tipos de atividades disponíveis (FIM)

  /**
   * Adiciona um tipo de atividade ao gráfico e remove da lista de tipos de
   * atividade disponíveis para seleção.
   *
   * Este método é passado para a combo de seleção de tipo de atividade para
   * adicionar
   *
   * @param tipoAtividade Tipo de atividade a ser adicionado ao gráfico
   */
  function selectTipoAtividade(tipoAtividade: ITipoAtividade) {
    //Remoção do tipo de atividade da lista
    const newListaTiposAtividades = listTiposAtividades.filter(
      (oldItem) => oldItem.id_tipo_atividade !== tipoAtividade.id_tipo_atividade
    )
    setListTiposAtividades(newListaTiposAtividades)

    //Criação do item a ser adicionado ao gráfico
    const newItemGrafico = {
      x: (grafico.length + 1) * 15,
      y: 200 + (grafico.length + 1) * 15,
      tipo: 'PADRAO',
      proximo: undefined,
      alternativo: undefined,
      tipoAtividade,
      id: tipoAtividade.id_tipo_atividade!
    } as ItemGrafico

    //Redefinição dos itens do gráfico
    setGrafico([...grafico, newItemGrafico])
  }

  /**
   * Faz validações da organização do grafo, salva o grafo e converte o grafo
   * em um conjunto de atividades equivalente.
   */
  function handleClickSaveButton(): void {
    /**
     * Converte o grafo em um conjunto de atividades equivalente.
     * Se houver um conjunto de atividades previamente existente, ela é
     * excluída.
     */
    async function salvarAtividades(): Promise<boolean> {
      const exportacao = grafico.map((itemGrafico) => ({
        x: itemGrafico.xFinal,
        y: itemGrafico.yFinal,
        tipo: itemGrafico.tipo,
        id: itemGrafico.id,
        proximo: itemGrafico.proximo,
        alternativo: itemGrafico.alternativo
      }))

      const formData: IServico = {
        id_servico: servico.id_servico,
        nome: servico.nome,
        descricao: servico.descricao,
        interno: servico.interno,
        em_uso: true,
        modelagem_fluxo: JSON.stringify(exportacao)
      }

      const action = formMode === 'CREATE' ? 'CREATED' : 'UPDATED'
      await Toast(ApiServico.setFluxoAtividades(formData), action)

      setFormMode('LIST')

      return true
    }

    /**
     * Validar se há algum nó que não é destino de uma aresta
     *
     * @returns flag indicando a ausência de nós órfãos
     */
    function validarNosOrfaos(): string {
      const nos = grafico.filter(
        (itemGrafico) => itemGrafico.id !== 'INICIO' && itemGrafico.id !== 'FIM'
      )
      if (
        nos.some((itemGrafico) => {
          const isProximo = grafico.find(
            (item) => item.proximo === itemGrafico.id
          )
          const isAlternativo = grafico.find(
            (item) => item.alternativo === itemGrafico.id
          )
          return !isProximo && !isAlternativo
        })
      ) {
        return 'Há atividade não inclusa em fluxo padrão ou alternativo'
      }
      return ''
    }

    /**
     * Função recursiva que percorre o grafo, verificando se há um fluxo
     *
     * @param itemAtual
     * @param itensVisitados
     * @param itemAnterior
     * @returns
     */
    function validarFluxoPadrao(
      itemAtual: ItemGrafico,
      itensVisitados: string[],
      itemAnterior?: ItemGrafico
    ): string {
      itensVisitados.push(itemAtual.id)
      if (itemAtual.tipo === 'FIM') {
        return ''
      }

      if (!itemAtual.proximo) {
        return 'O fluxo padrão não chega ao fim'
      }

      if (
        itemAtual.proximo &&
        itensVisitados.some(
          (itemVisitado) => itemVisitado === itemAtual.proximo
        )
      ) {
        return 'Há um loop no fluxo padrão'
      }

      if (
        itemAtual.tipo === 'ALTERNATIVO' &&
        itemAnterior!.tipo !== 'ALTERNATIVO'
      ) {
        return (
          'Uma atividade não pode estar no ' +
          'fluxo padrão e alternativo ao mesmo tempo'
        )
      }

      const proximoItem = grafico.find((item) => item.id === itemAtual.proximo)
      return validarFluxoPadrao(proximoItem!, itensVisitados, itemAtual)
    }

    /**
     * Validar o fluxos alternativos
     *
     * @param itemAtual: Item do gráfico atualmente em análise
     * @return Mensagem de erro ou nada
     */
    function validarFluxoAlternativo(itemAtual: ItemGrafico): string[] {
      if (itemAtual.tipo === 'ALTERNATIVO' && itemAtual.alternativo) {
        return ['Não pode haver fluxo alternativo em um fluxo alternativo']
      }

      if (itemAtual.tipo === 'ALTERNATIVO' && !itemAtual.proximo) {
        return ['Há fluxo alternativo que não chega ao fim']
      }

      const proximoItem = itemAtual.proximo
        ? grafico.find((itemGrafico) => itemGrafico.id === itemAtual.proximo)
        : null
      const proximaValidacao = proximoItem
        ? validarFluxoAlternativo(proximoItem)
        : ['']

      const itemAlternativo = itemAtual.alternativo
        ? grafico.find(
            (itemGrafico) => itemGrafico.id === itemAtual.alternativo
          )
        : null
      const validacaoAlternativa = itemAlternativo
        ? validarFluxoAlternativo(itemAlternativo)
        : ['']

      return [...proximaValidacao, ...validacaoAlternativa].filter(
        (retorno) => retorno !== ''
      )
    }

    /**
     * Fazer a validação do grafo para saber se pode ser um fluxo de atividades
     * válido
     *
     * @returns flag se o fluxo é válido
     */
    function validarFluxo(): boolean {
      //Validar se o serviço foi definido
      if (!servico.id_servico) {
        toast.ToastWarning('Um serviço deve ser definido')
        return false
      }

      //Validação se o fluxo está vazio
      if (grafico.length === 2) {
        toast.ToastError('Nenhuma atividade foi adicionado ao fluxo.')
        return false
      }

      //Validação de nós órfãos
      const hasNosOrfaos = validarNosOrfaos()
      if (hasNosOrfaos) {
        toast.ToastWarning(hasNosOrfaos)
        return false
      }

      //Validação do fluxo padrão
      const itemInicial = grafico.find((item) => item.id === 'INICIO')!
      const hasProblemaFluxoPadrao = validarFluxoPadrao(itemInicial, [])
      if (hasProblemaFluxoPadrao) {
        toast.ToastWarning(hasProblemaFluxoPadrao)
        return false
      }

      //Validação do fluxo alternativo
      const hasProblemaFluxoAlternativo = validarFluxoAlternativo(itemInicial)
      if (hasProblemaFluxoAlternativo.length > 0) {
        toast.ToastWarning(hasProblemaFluxoAlternativo[0])
        return false
      }

      return true
    }

    if (!validarFluxo()) {
      return
    }

    salvarAtividades()
  }

  if (isLoading) return <Loading />
  return (
    <Xwrapper>
      <Box
        sx={{
          width: '100%',
          height: '800px',
          display: 'flex',
          flexDirection: 'column',
          marginTop: '2rem',
          alignItems: 'center'
        }}
      >
        <FormHeader
          formMode={formMode}
          data={data}
          acao={acao}
          changeAcao={changeAcao}
          setTipoAtividade={selectTipoAtividade}
          listaTipoAtividade={listTiposAtividades}
          servico={servico}
          changeServico={changeServico}
          disableControls={data.em_uso || formMode === 'VIEW'}
        />

        <DrawArea
          acao={acao}
          changeGrafico={changeGrafico}
          grafico={grafico}
          disabledControls={data.em_uso || formMode === 'VIEW'}
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%'
          }}
        >
          <Stack direction="row" gap={2} mt={3}>
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<CancelIcon />}
              onClick={() => setFormMode('LIST')}
            >
              Cancelar
            </Button>

            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              sx={{ width: '110px' }}
              onClick={() => {
                handleClickSaveButton()
              }}
              disabled={data.em_uso || formMode === 'VIEW'}
            >
              Salvar
            </Button>
          </Stack>
        </Box>
      </Box>
    </Xwrapper>
  )
}
