import { Box, Typography } from '@mui/material'
import { ElementType } from 'react'
import { corTituloWidget } from '../../cores'

interface DashboardWidgetListHeaderDataProps {
  icon: ElementType
  total: number
}

export function DashboardWidgetListHeaderData({
  icon: Icon,
  total
}: DashboardWidgetListHeaderDataProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 1,
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        fontStyle: 'normal',
        alignContent: 'baseline',
        marginLeft: '8px'
      }}
    >
      <Icon sx={{ width: '20px', height: '20px' }} />
      <Typography fontWeight={500} color={corTituloWidget}>
        {total} total
      </Typography>
    </Box>
  )
}
