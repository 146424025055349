import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  // Checkbox,
  Dialog,
  Divider,
  // FormControlLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { ReactNode, useEffect, useState } from 'react'

import { XMLParser } from 'fast-xml-parser'

import Processando from '../../../assets/images/procesando.gif'
import SaveIcon from '@mui/icons-material/CheckCircleOutlined'
import BackIcon from '@mui/icons-material/ArrowBack'
import ForwardIcon from '@mui/icons-material/ArrowForward'
import { Cancel, Warning } from '@mui/icons-material'
import { ArrowUpward, Block } from '@mui/icons-material'
import { ExpandMore } from '@mui/icons-material'

import { FormTitle } from '../../../components/Form/FormTitle'
import { Timeline } from './components/Timeline'

import AddFileImg from '../../../assets/images/addFile.svg'
import { ICaixa, ICaixaImport } from '../../../services/caixa/types'
import { Loading } from '../../../components/Loading'
import { useContrato } from '../../../hooks/contrato/useContrato'
import { formata } from '../../../utils/formataData'
import { useCaixa } from '../../../hooks/caixa/useCaixa'
import { useSetor } from '../../../hooks/setor/useSetor'
import { Select } from '../../../components/Form/Select'
import { useTipoCaixa } from '../../../hooks/tipoCaixa/useTipoCaixa'
import { useTipoDocumento } from '../../../hooks/tipoDocumento/useTipoDocumento'
import { getTempoDecorrido } from '../../../utils/getTempoDecorrido'
import { LogDialog } from './components/LogDialog'

export const ImportSteps = [
  'Seleção de Arquivo XML',
  'Vincular a Setor',
  'Confirmação de Dados'
]

interface IVolumeImportar {
  caixa: ICaixa
  subVolumes: IVolumeImportar[]
  endereco: string
  numero: number
  importar: boolean
  volumes: number
}

interface IMenssagemValidacao {
  descricao: string
  lista: ReactNode
}

export function ImportarVolumes() {
  // Estados (INÍCIO) ----------------------------------------------------------

  // Dados de navegação e compartilhados (INÍCIO)
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [stepForward, setStepForward] = useState<boolean>(false)
  const [volumesImportar, setVolumesImportar] = useState<IVolumeImportar[]>([])
  const [idContrato, setIdContrato] = useState<string>('')
  const { data: contrato, isLoading } = useContrato.FindOne(idContrato)
  const { data: tiposVolumes } = useTipoCaixa.ListAll()
  const { data: tiposDocumentos } = useTipoDocumento.ListAll()
  const [idEmpresa, setIdEmrpesa] = useState<string>(
    contrato?.empresa_contratante?.id_empresa ?? ''
  )
  const [progress, setProgress] = useState<number>(0)
  const [tempo, setTempo] = useState<Date>(new Date())
  const [inicioExecucao, setInicioExecucao] = useState<Date>(new Date())
  const [tempoDecorrido, setTempoDecorrido] = useState<string>('')
  const [showWaitDialog, setShowWaitDialog] = useState<boolean>(false)

  useEffect(() => {
    if (!isLoading) {
      setIdEmrpesa(contrato?.empresa_contratante?.id_empresa ?? '')
    }
  }, [contrato])
  // Dados de navegação e compartilhados (FIM)

  const getFlatList = (lista: IVolumeImportar[]): IVolumeImportar[] => {
    let retValue: IVolumeImportar[] = []
    for (const item of lista) {
      retValue = [
        ...retValue,
        {
          ...item
        }
      ]
      if (item.subVolumes && item.subVolumes.length > 0) {
        for (const subitem of item.subVolumes) {
          retValue = [
            ...retValue,
            {
              ...subitem,
              caixa: {
                ...subitem.caixa,
                identificacao: ` > ${subitem.caixa.identificacao}`
              }
            }
          ]
        }
      }
    }
    return retValue
  }

  // Leitura do XML (INÍCIO)
  const [arquivoXML, setArquivoXML] = useState<string>('')
  const [errosLeituraXML, setErroLeituraXML] = useState<IMenssagemValidacao[]>(
    []
  )
  const [alertasLeituraXML, setAlertasLeituraXML] = useState<
    IMenssagemValidacao[]
  >([])
  const [processamentoXML, setProcessamentoXML] = useState<string[]>([])

  // Leitura do XML (FIM)

  //Selecção de Setor (INÍCIO)
  const [idSetor, setIdSetor] = useState<string>('')
  //Selecção de Setor (FIM)

  // Importação (INÍCIO)
  const [listaMensagensImportacao, setListaMensagensImportacao] = useState<
    IMenssagemValidacao[]
  >([])
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [importando, setImportando] = useState<boolean>(false)
  // Importação (FIM)

  // Estados (FIM) -------------------------------------------------------------

  /**
   * Converte as flags de "dados pessoais", "dados sensíveis" e "dados de
   * criaça ou adolescente" em uma string que as representa
   * @param documento Documento com informação de tipo de documento
   * @returns String representando as flags
   */
  const flagsToString = (caixa: ICaixa): string => {
    let ret = caixa.dados_criancas_adolescentes ? 'C' : ''
    if (caixa.dados_pessoais) {
      ret += (ret.length > 0 ? '-' : '') + 'P'
    }
    if (caixa.dados_pessoais_sensiveis) {
      ret += (ret.length > 0 ? '-' : '') + 'S'
    }
    return ret
  }

  // Formatação (FIM) ----------------------------------------------------------

  // Hooks de dados auxiliares (INÍCIO) ----------------------------------------
  const { mutateAsync: create } = useCaixa.Import()
  const { data: listaSetores, isLoading: isLoadingSetores } =
    useSetor.FindByEmpresa(idEmpresa)
  // Hooks de dados auxiliares (FIM) -------------------------------------------

  // Marcação de caixas para importação (INÍCIO) ---------------------------

  // const getTotalMarcados = (): number => {
  //   const marcados = volumesImportar.filter((item) => item.importar)
  //   return marcados.length
  // }

  /**
   * Verifica o status de marcação do documentos para importação
   * @returns Status da marcação (0: nenhum, 1: vários, 2: todos)
   */
  // const getMarcados = (): number => {
  //   const marcados = getTotalMarcados()
  //   return marcados === 0 ? 0 : marcados === volumesImportar.length ? 2 : 1
  // }

  // const marcarDesmarcarTodos = () => {
  //   const marcar = getTotalMarcados() === 0
  //   setVolumesImportar((previousValue) =>
  //     previousValue.map((item) => ({
  //       ...item,
  //       importar: marcar,
  //       subVolumes: [
  //         ...item.subVolumes.map((sub) => ({ ...sub, importar: marcar }))
  //       ]
  //     }))
  //   )
  //   setStepForward(marcar)
  // }

  // Marcação de caixas para importação (FIM) ------------------------------

  // Handlers (INÍCIO) ---------------------------------------------------------
  /**
   * Ir para o passo anterior
   */
  const handlePreviousButtonClick = () => {
    setCurrentStep(currentStep - 1)
    setStepForward(true)
  }

  /**
   * Ir para o próximo passo
   */
  const handleNextButtonClick = () => {
    setCurrentStep(currentStep + 1)
    if (currentStep + 1 !== 2) setStepForward(false)
  }

  /**
   * Cancela a importação
   */
  const handleCancelButtonClick = () => {
    setCurrentStep(0)
    setStepForward(false)
    setVolumesImportar([])
    setIdContrato('')
    setArquivoXML('')
    setErroLeituraXML([])
    setAlertasLeituraXML([])
    setProcessamentoXML([])
    setListaMensagensImportacao([])
    setIdSetor('')
    setShowDialog(false)
    setImportando(false)
  }

  /**
   * Ler arquivo XML relativo a um lote.
   *
   * Após a leitura do arquivo o arquivo é validado quanto ao formato, se as
   * caixas estão corretamente identificadas e prepara os dados dos documentos
   * para importação.
   * @param event Evento de seleção do arquivo
   * @returns
   */
  const handleXMLSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErroLeituraXML([])
    const convertDate = (dt: string): Date => {
      const newDt = dt.split('/')
      if (dt.length < 10 || newDt.length < 2) {
        throw Error(`Data inválida (${dt})`)
      }
      const newDate = new Date(`${newDt[2]}-${newDt[1]}-${newDt[0]} 00:00:00`)
      return newDate
    }

    let processamento: string[] = []
    let erros: IMenssagemValidacao[] = []

    // Recuperação dos dados do arquivo selecionado
    const xmlFile = event.target.files ? event.target.files[0] : new Blob()
    const newFileName = event.target.files ? event.target.files[0].name : ''
    const textType = 'text/xml'

    processamento = ['Validação de tipo de arquivo.']

    // Validação do tipo de arquivo
    if (!xmlFile.type.match(textType)) {
      erros = [
        {
          descricao: 'Arquivo selecionado não é um arquivo XML',
          lista: []
        }
      ]
      return
    }

    // Definição do nome do arquivo (para exibição em tela)
    setArquivoXML(newFileName)

    // Criação do leitor de arquivo
    const xmlReader = new FileReader()

    const xmlToVolume = (
      volumeAtual: any,
      index: number,
      internal: boolean,
      idContrato: string
    ): IVolumeImportar => {
      const volume = volumeAtual.caixa ? volumeAtual.caixa : volumeAtual
      if (!volume.identificacao) {
        throw new Error(`Volume ${index + 1} não possui campo "identificacao".`)
      }
      if (!volume.conteudo) {
        throw new Error(
          `Volume ${index + 1} (${volume.identificacao}) não possui` +
            ` campo "conteudo".`
        )
      }
      if (!volume.data_inicial) {
        throw new Error(
          `Volume ${index + 1} (${volume.identificacao}) não possui` +
            ` campo "data_inicial".`
        )
      }
      if (!volume.data_final) {
        throw new Error(
          `Volume ${index + 1} (${volume.identificacao}) não possui` +
            ` campo "data_final".`
        )
      }
      if (!volume.sequencia_inicial) {
        throw new Error(
          `Volume ${index + 1} (${volume.identificacao}) não possui` +
            ` campo "sequencia_inicial".`
        )
      }
      if (!volume.sequencia_final) {
        throw new Error(
          `Volume ${index + 1} (${volume.identificacao}) não possui` +
            ` campo "sequencia_final".`
        )
      }
      if (!volume.privacidade) {
        throw new Error(
          `Volume ${index + 1} (${volume.identificacao}) não possui` +
            ` campo "privacidade".`
        )
      }
      if (!volume.tipo_documento) {
        throw new Error(
          `Volume ${index + 1} (${volume.identificacao}) não possui` +
            ` campo "tipo_documento".`
        )
      }
      if (!volume.tipo_documento) {
        throw new Error(
          `Volume ${index + 1} (${volume.identificacao}) não possui` +
            ` campo "tipo_documento".`
        )
      }
      if (!volume.tipo_caixa) {
        throw new Error(
          `Volume ${index + 1} (${volume.identificacao}) não possui` +
            ` campo "tipo_caixa".`
        )
      }
      if (!internal && !volume.endereco) {
        throw new Error(
          `Volume ${index + 1} (${volume.identificacao}) não possui` +
            ` campo "endereco".`
        )
      }

      if (!internal && volume.endereco.split('.').length !== 5) {
        throw new Error(
          `Volume ${index + 1} (${volume.identificacao}) possui` +
            ` campo "endereco" inválido.`
        )
      }

      if (
        tiposVolumes &&
        tiposVolumes.length > 0 &&
        !tiposVolumes.filter(
          (tpVolume) => tpVolume.id_tipo_caixa === volume.tipo_caixa
        )
      ) {
        throw new Error(
          `Volume ${index + 1} (${volume.identificacao}) possui` +
            ` campo "tipo_caixa" com dados inválidos.`
        )
      }

      if (
        tiposDocumentos &&
        tiposDocumentos?.length > 0 &&
        !tiposDocumentos?.filter(
          (tpDocumento) =>
            tpDocumento.id_tipo_documento === volume.tipo_documento
        )
      ) {
        throw new Error(
          `Volume ${index + 1} (${volume.identificacao}) possui` +
            ` campo "tipo_documento" com dados inválidos.`
        )
      }

      const tipoVolume = tiposVolumes?.find(
        (vol) => vol.id_tipo_caixa === volume.tipo_caixa
      )

      if (
        tipoVolume &&
        tipoVolume.acondiciona_caixas &&
        volume.caixas &&
        volume.caixas.length > 0
      ) {
        throw new Error(
          `Volume ${index + 1} (${volume.identificacao}) é` +
            ` de um tipo que não permite caixas nele.`
        )
      }

      let subVolumes: IVolumeImportar[] = []
      let volumeTotal = 0
      if (volume.caixas) {
        for (const caixa of volume.caixas.caixa) {
          const subVolume = xmlToVolume(
            caixa,
            volumeTotal + 1 + index,
            true,
            idContrato
          )
          subVolumes = [...subVolumes, subVolume]
          volumeTotal += subVolume.caixa.tipoCaixa?.tamanho ?? 1
        }
      }

      if (tipoVolume && volumeTotal > tipoVolume?.tamanho) {
        throw new Error(
          `Volume ${index} (${volume.identificacao}) contém` +
            ` mais volumes do que pode acondicionar.`
        )
      }

      return {
        caixa: {
          conteudo: volume.conteudo,
          dados_criancas_adolescentes: volume.privacidade.includes('C'),
          dados_pessoais: volume.privacidade.includes('P'),
          dados_pessoais_sensiveis: volume.privacidade.includes('S'),
          estado: 'ARMAZENADO',
          id_contrato: idContrato,
          data_fim: convertDate(volume.data_final),
          data_inicio: convertDate(volume.data_inicial),
          sequencia_fim: String(volume.sequencia_final),
          sequencia_inicio: String(volume.sequencia_inicial),
          id_tipo_caixa: volume.tipo_caixa,
          id_tipo_documento: volume.tipo_documento,
          identificacao: volume.identificacao,
          estadoAtividade: {
            descricao: '',
            estado: ''
          },
          posicaoPrateleira: volume.endereco
        },
        subVolumes,
        endereco: volume.endereco,
        importar: false,
        numero: index,
        volumes: 1 + volumeTotal
      }
    }

    // Definição do evento para lidar com o processamento do arquivo
    // (após o completo carregamento do arquivo)
    xmlReader.onload = (fileEvent) => {
      // Carregamento do conteúdo
      const content = (
        fileEvent.target ? fileEvent.target.result : ''
      ) as string

      // Parseamento do XML para objeto Javascript (INÍCIO)
      const options = {
        ignoreAttributes: false
      }

      const xmlParser = new XMLParser(options)
      const xmlObject = xmlParser.parse(content)
      // Parseamento do XML para objeto Javascript (FIM)

      //Carregamento e validação da estrutura inicial do XML (INÍCIO)
      let caixas: Array<any> = []
      let contrato: any
      try {
        const tmpContrato = xmlObject['contrato']
        contrato = tmpContrato.identificador
        setIdContrato(contrato)
        caixas = [...tmpContrato.caixas.caixa]
      } catch (err) {
        if (err instanceof TypeError) {
          try {
            caixas = [xmlObject['contrato']['caixas']]
          } catch (e) {
            setErroLeituraXML([
              {
                descricao: 'Formato de XML inválido',
                lista: []
              }
            ])
            setStepForward(false)
            return
          }
        }
      }
      //Carregamento e validação da estrutura inicial do XML (FIM)

      processamento = [...processamento, 'Validação de caixas']

      let nCaixa = 0
      let lstVolImportar: Array<IVolumeImportar> = []
      // Loop de validação de todas as caixas
      for (const caixa of caixas) {
        // Validação (INÍCIO)
        try {
          const volume = xmlToVolume(caixa, nCaixa, false, idContrato)
          nCaixa += volume.volumes
          lstVolImportar = [...lstVolImportar, volume]
          setIdSetor('')
        } catch (e: any) {
          setErroLeituraXML([
            {
              descricao: e.message,
              lista: []
            }
          ])
          setStepForward(false)
          return
        }
        // Validação (FIM)
      }

      // Exibição dos logs (INÍCIO)
      const totalCaixas = lstVolImportar.reduce((acc, cur) => {
        return acc + cur.volumes
      }, 0)
      processamento = [
        ...processamento,
        `XML lido`,
        `Total de caixas: ${totalCaixas}`
      ]
      setProcessamentoXML(processamento)
      setErroLeituraXML(erros)

      if (erros.length === 0) {
        setStepForward(true)
        setVolumesImportar(lstVolImportar)
      }
      // Exibição dos logs (FIM)
    }

    // Chamado do processo de leitura do XML
    xmlReader.readAsText(xmlFile)
  }

  /**
   * Ações a serem executadas quando muda o setor
   * @param idSetor Id do setor a ser vinculado com as caixas
   */
  const handleSetorChange = (idSetor: string) => {
    setIdSetor(idSetor)
    setStepForward(true)
  }

  /**
   * Enviar os dados dos documentos para o back
   */
  const handleSubmitClick = async () => {
    const volumeToCaixaImport = (volume: IVolumeImportar): ICaixaImport => {
      return {
        conteudo: volume.caixa.conteudo,
        dados_criancas_adolescentes: volume.caixa.dados_criancas_adolescentes,
        dados_pessoais: volume.caixa.dados_pessoais,
        dados_pessoais_sensiveis: volume.caixa.dados_pessoais_sensiveis,
        data_fim: new Date(volume.caixa.data_fim ?? ''),
        data_inicio: new Date(volume.caixa.data_inicio ?? ''),
        endereco: volume.endereco ?? '-',
        estado: volume.caixa.estado,
        id_contrato: contrato?.id_contrato ?? '',
        id_tipo_caixa: volume.caixa.id_tipo_caixa ?? '',
        id_tipo_documento: volume.caixa.id_tipo_documento ?? '',
        identificacao: volume.caixa.identificacao,
        sequencia_fim: volume.caixa.sequencia_fim ?? '',
        sequencia_inicio: volume.caixa.sequencia_inicio ?? '',
        id_setor: idSetor
      }
    }

    // setImportando(true)
    const newTempo = new Date()

    setTempo(newTempo)
    setInicioExecucao(newTempo)
    setTempoDecorrido('00:00:00')
    setShowWaitDialog(true)

    const volumesToSend: ICaixaImport[] = volumesImportar.map((vol) => {
      if (vol.volumes > 1) {
        return {
          ...volumeToCaixaImport(vol),
          caixas: [
            ...vol.subVolumes.map((subvol) => volumeToCaixaImport(subvol))
          ]
        }
      }
      const newCaixaImport = volumeToCaixaImport(vol)
      return newCaixaImport
    })

    const strData = JSON.stringify(volumesToSend)

    const formData = new FormData()
    formData.append('formData', strData)

    setImportando(true)

    let mensagensImportacao: IMenssagemValidacao[] = []
    try {
      const logData = await create({
        formData,
        progressCallback: (progress) => {
          setProgress(progress)
        }
      })
      const totalVolumes = volumesImportar.reduce((acc, curr) => {
        return acc + curr.volumes
      }, 0)

      mensagensImportacao = [
        {
          descricao: 'Processo de importação concluído',
          lista: <span>&nbsp;</span>
        },
        {
          descricao: 'Total de Volumes para Importação',
          lista: <span>{totalVolumes}</span>
        },
        {
          descricao: 'Total de Volumes Importados',
          lista: <span>{logData.total_efetivados}</span>
        },
        ...((logData.erros_importacao ?? '')
          .split('\n')
          .filter((erro: string) => erro.trim() !== '')
          .map((erro: string) => {
            return {
              descricao: 'Mensagem de Erro',
              lista: <span>{erro}</span>
            }
          }) ?? []),
        {
          descricao: 'Erros de importação',
          lista: <span>{logData.total_erros}</span>
        }
      ]
    } catch (e: any) {
      mensagensImportacao = [
        {
          descricao: 'Erro inesperado',
          lista: <span>{e.message}</span>
        }
      ]
    }

    setListaMensagensImportacao(mensagensImportacao)
    setImportando(false)
    setShowWaitDialog(false)
    setShowDialog(true)
    setTempoDecorrido('00:00:00')
  }

  useEffect(() => {
    if (importando)
      setTimeout(() => {
        const newTempo = new Date()
        setTempo(newTempo)
        setTempoDecorrido(getTempoDecorrido(inicioExecucao, newTempo))
      }, 5000)
  }, [tempo, importando])

  /**
   * Fechar a caixa de diálogo de log e limpar os dados para uma nova importação
   */
  const handleLogDialogClose = () => {
    setShowDialog(false)
    handleCancelButtonClick()
  }

  // const marcarImportacao = (numero: number, tipo: number): void => {
  //   const newLista = [...volumesImportar]
  //   newLista.forEach((volume) => {
  //     if (volume.numero === numero) {
  //       volume.importar = tipo === 1
  //     }
  //     if (volume.subVolumes && volume.subVolumes.length > 0) {
  //       volume.subVolumes.forEach((vol) => {
  //         if (vol.numero === numero) {
  //           vol.importar = tipo === 1
  //         }
  //       })
  //     }
  //   })
  //   setVolumesImportar(newLista)
  // }

  // const handleImportarChange = (volume: IVolumeImportar) => {
  //   if (!volume.importar) {
  //     marcarImportacao(volume.numero, 1)
  //     setStepForward(getMarcados() > 0)
  //     return
  //   }
  //   if (volume.subVolumes && volume.subVolumes.length > 0) {
  //     volume.subVolumes.forEach((vol) => marcarImportacao(vol.numero, 0))
  //   }
  //   marcarImportacao(volume.numero, 0)
  //   setStepForward(getMarcados() > 0)
  // }

  // Handlers (FIM) ------------------------------------------------------------

  if (importando || isLoading || isLoadingSetores) <Loading />

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '6px',
        background: '#FFFFFF',
        padding: '16px',
        gap: 2
      }}
    >
      {/* Cabeçalho 00000000000000000000000000000000000000000000000000000000 */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignContent: 'center',
          alignItems: 'center'
        }}
      >
        <FormTitle title="Importação de Volumes" />

        <Stack gap={2} flexDirection={'row'}>
          <Button
            variant="outlined"
            disabled={!(currentStep > 0)}
            startIcon={<BackIcon />}
            sx={{ fontWeight: 600 }}
            onClick={handlePreviousButtonClick}
          >
            Voltar
          </Button>

          <Button
            variant={currentStep === 1 ? 'contained' : 'outlined'}
            endIcon={
              currentStep === 2 ? (
                <SaveIcon sx={{ width: 24, height: 24 }} />
              ) : (
                <ForwardIcon />
              )
            }
            disabled={!stepForward}
            sx={{ fontWeight: 600 }}
            onClick={() =>
              currentStep < 2 ? handleNextButtonClick() : handleSubmitClick()
            }
          >
            {currentStep === 2 ? 'Confirmar' : 'Avançar'}
          </Button>
          <Button
            sx={{
              marginLeft: '16px',
              fontWeight: 600
            }}
            color="error"
            variant="outlined"
            startIcon={<Cancel />}
            onClick={handleCancelButtonClick}
          >
            Cancelar
          </Button>
        </Stack>
      </Box>
      <Timeline step={currentStep} steps={ImportSteps} />
      <Divider
        sx={{
          marginBottom: 2,
          marginTop: 2
        }}
      />
      {/* Passo 1 ########################################################## */}
      {currentStep === 0 ? (
        <Stack gap={2}>
          <Box
            sx={{
              border: '1px dashed  #E8E8E8',
              borderRadius: '4px',
              boxSizing: 'border-box',
              padding: '12px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              alignContent: 'center',
              verticalAlign: 'center'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
                alignItems: 'center',
                alignContent: 'center',
                verticalAlign: 'center',
                margin: '12px'
              }}
            >
              <img src={AddFileImg} alt={arquivoXML} />
              <Typography>{arquivoXML}</Typography>
            </Box>
            <Button
              startIcon={<ArrowUpward />}
              color="success"
              variant="outlined"
              component="label"
              sx={{
                height: '40px'
              }}
            >
              Procurar
              <input
                accept="text/xml"
                type="file"
                hidden
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleXMLSelect(event)
                }}
              />
            </Button>
          </Box>
          {processamentoXML.length > 0 ? (
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {processamentoXML.map((item, index) => {
                    return (
                      <TableRow key={`processamento${index}`}>
                        <TableCell>{item}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            ''
          )}

          {errosLeituraXML.length > 0 ? (
            <Box
              sx={{
                gap: 2
              }}
            >
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  sx={{
                    backgroundColor: '#F8F8F8'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 2,
                      alignItems: 'center',
                      alignContent: 'center',
                      verticalAlign: 'center',
                      margin: '12px'
                    }}
                  >
                    <Block
                      sx={{
                        color: 'red',
                        width: '24px',
                        height: '24px'
                      }}
                    />
                    <Typography>Erros</Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        {errosLeituraXML.map((item, index) => {
                          return (
                            <TableRow key={`erros${index}`}>
                              <TableCell>{item.descricao}</TableCell>
                              <TableCell>{item.lista}</TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            </Box>
          ) : (
            ''
          )}

          {alertasLeituraXML.length > 0 ? (
            <Box
              sx={{
                gap: 2
              }}
            >
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  sx={{
                    backgroundColor: '#F8F8F8'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 2,
                      alignItems: 'center',
                      alignContent: 'center',
                      verticalAlign: 'center',
                      margin: '12px'
                    }}
                  >
                    <Warning
                      sx={{
                        color: 'orange',
                        width: '32px',
                        height: '32px'
                      }}
                    />
                    <Typography>Alertas</Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        {alertasLeituraXML.map((item, index) => {
                          return (
                            <TableRow key={`alerta${index}`}>
                              <TableCell>{item.descricao}</TableCell>
                              <TableCell>{item.lista}</TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            </Box>
          ) : (
            ''
          )}
        </Stack>
      ) : (
        ''
      )}
      {/* Passo 1 $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */}
      {currentStep === 1 ? (
        <Stack gap={2}>
          <Select
            label="Setor*"
            placeholder="Selecione o Setor de Origem"
            data={listaSetores}
            fields={['id_setor', 'nome']}
            value={idSetor ?? ''}
            onChange={(e) => handleSetorChange(String(e.target.value))}
          />
        </Stack>
      ) : (
        ''
      )}
      {/* Passo 2 $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */}
      {currentStep === 2 ? (
        <>
          <Stack gap={2}>
            <p>Contrato: {contrato?.descricao}</p>
            <TableContainer sx={{ height: '450px' }}>
              <Table stickyHeader={true} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        backgroundColor: '#F8F8F8'
                      }}
                    >
                      <Typography sx={{ fontWeight: 'bold', color: '#969696' }}>
                        Identificação
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: '#F8F8F8'
                      }}
                    >
                      <Typography sx={{ fontWeight: 'bold', color: '#969696' }}>
                        Privacidade
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: '#F8F8F8'
                      }}
                    >
                      <Typography sx={{ fontWeight: 'bold', color: '#969696' }}>
                        Sequência
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: '#F8F8F8'
                      }}
                    >
                      <Typography sx={{ fontWeight: 'bold', color: '#969696' }}>
                        Data
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: '#F8F8F8'
                      }}
                    >
                      <Typography sx={{ fontWeight: 'bold', color: '#969696' }}>
                        Tipo de Volume
                      </Typography>
                    </TableCell>
                    {/* <TableCell
                      sx={{
                        backgroundColor: '#F8F8F8'
                      }}
                    >
                      <FormControlLabel
                        sx={{ fontWeight: 'bold', color: '#969696' }}
                        label="Importar"
                        control={
                          <Checkbox
                            onChange={() => {
                              marcarDesmarcarTodos()
                            }}
                            checked={getMarcados() === 2}
                            indeterminate={getMarcados() === 1}
                          />
                        }
                      />
                    </TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getFlatList(volumesImportar).map((volumeImportar) => (
                    <TableRow key={`${volumeImportar.numero}${idContrato}`}>
                      <TableCell>
                        {volumeImportar.caixa.identificacao}
                      </TableCell>
                      <TableCell>
                        {flagsToString(volumeImportar.caixa)}
                      </TableCell>
                      <TableCell>
                        {volumeImportar.caixa.sequencia_inicio} a{' '}
                        {volumeImportar.caixa.sequencia_fim}
                      </TableCell>
                      <TableCell>
                        {volumeImportar.caixa.data_inicio
                          ? `${formata(
                              volumeImportar.caixa.data_inicio,
                              'dd/MM/yyyy'
                            )}`
                          : ''}
                        {volumeImportar.caixa.data_fim
                          ? ` a ${formata(
                              volumeImportar.caixa.data_fim,
                              'dd/MM/yyyy'
                            )}`
                          : ''}
                      </TableCell>
                      <TableCell>
                        {tiposVolumes &&
                          tiposVolumes.find(
                            (item) =>
                              item.id_tipo_caixa ===
                              volumeImportar.caixa.id_tipo_caixa
                          )?.descricao}
                      </TableCell>
                      {/* <TableCell>
                        <Checkbox
                          onChange={() => {
                            handleImportarChange(volumeImportar)
                          }}
                          checked={volumeImportar.importar}
                        />
                      </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
          <LogDialog
            showDialog={showDialog}
            closeHandler={handleLogDialogClose}
            log={listaMensagensImportacao}
            informacoesAdicionais={[
              {
                descricao: 'Tempo total decorrido',
                lista: <span>{getTempoDecorrido(inicioExecucao, tempo)}</span>
              }
            ]}
          />
          <Dialog open={showWaitDialog} fullWidth>
            <Box
              display={'flex'}
              flexDirection={'column'}
              width={'100%'}
              height={300}
              bgcolor={'#fff'}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                alignContent: 'center',
                alignItems: 'center',
                padding: '2rem'
              }}
            >
              <img
                src={Processando}
                alt={'Processando'}
                width={'35%'}
                height={'50%'}
              />
              <Box
                sx={{
                  width: '100%',
                  margin: '3rem',
                  padding: '1rem',
                  display: 'flex',
                  flexDirection: 'column',
                  alignContent: 'center',
                  alignItems: 'center'
                }}
              >
                {progress < 100 && (
                  <Box
                    width={'100%'}
                    bgcolor={'#555'}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      height: '0.5rem',
                      margin: 0,
                      padding: 0
                    }}
                  >
                    <Box
                      bgcolor={'#ddd'}
                      sx={{
                        width: `${progress}%`,
                        height: '0.5rem',
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    ></Box>
                  </Box>
                )}
                {progress < 100 && (
                  <span>
                    Enviado ({progress}% - {tempoDecorrido}) ...
                  </span>
                )}
                {progress >= 100 && (
                  <span>
                    Aguardando resposta de processamento ({tempoDecorrido})...{' '}
                  </span>
                )}
              </Box>
            </Box>
          </Dialog>
        </>
      ) : (
        ''
      )}
    </Box>
  )
}
