import { DefaultApi } from '../../utils/defaultApi'
import api from '../api'
import { ICentroDocumentacao } from './types'

const endpoint = '/centro-documentacao'
const resourceId = 'id_centrodocumentacao'

class ServiceCentroDocumentacao extends DefaultApi<ICentroDocumentacao> {
  deletePosicaoPrateleira = async (id_posicao_prateleira: string) => {
    const { data } = await api.put<ICentroDocumentacao>(
      `${endpoint}/posicao-prateleira/excluir/${id_posicao_prateleira}`
    )
    return data
  }

  createPosicaoPrateleira = async (formData: {
    id_prateleira: string
    quantidade: number
  }) => {
    const { data } = await api.post<ICentroDocumentacao>(
      `${endpoint}/posicao-prateleira/adicionar/`,
      formData
    )
    return data
  }

  togglePosicaoPrateleira = async (formData: {
    id_posicao_prateleira: string
  }) => {
    const { id_posicao_prateleira: id } = formData
    const { data } = await api.post<ICentroDocumentacao>(
      `${endpoint}/posicao-prateleira/alternar-habilitado/${id}`
    )
    return data
  }

  deletePrateleira = async (id_prateleira: string) => {
    const { data } = await api.put<ICentroDocumentacao>(
      `${endpoint}/prateleira/excluir/${id_prateleira}`
    )
    return data
  }

  changeTypePrateleira = async (formData: {
    id_prateleira: string
    id_tipo_caixa: string
  }) => {
    const { data } = await api.put<ICentroDocumentacao>(
      `${endpoint}/prateleira/mudar-tipo-prateleira/${formData.id_prateleira}`,
      { id_tipo_caixa: formData.id_tipo_caixa }
    )
    return data
  }

  createPrateleira = async (formData: {
    id_estante: string
    quantidadePrateleiras: number
    quantidadePosicoes: number
    id_tipo_caixa: string
  }) => {
    const { data } = await api.post<ICentroDocumentacao>(
      `${endpoint}/prateleira/adicionar/`,
      formData
    )
    return data
  }

  deleteEstante = async (id_estante: string) => {
    const { data } = await api.put<ICentroDocumentacao>(
      `${endpoint}/estante/excluir/${id_estante}`
    )
    return data
  }

  createEstante = async (formData: {
    id_corredor: string
    quantidadeEstantes: number
    quantidadePrateleiras: number
    quantidadePosicoes: number
    id_tipo_caixa: string
  }) => {
    const { data } = await api.post<ICentroDocumentacao>(
      `${endpoint}/estante/adicionar/`,
      formData
    )
    return data
  }

  deleteCorredor = async (id_corredor: string) => {
    const { data } = await api.put<ICentroDocumentacao>(
      `${endpoint}/corredor/excluir/${id_corredor}`
    )
    return data
  }

  createCorredor = async (formData: {
    id_centrodocumentacao: string
    quantidadeCorredores: number
    quantidadeEstantes: number
    quantidadePrateleiras: number
    quantidadePosicoes: number
    id_tipo_caixa: string
  }) => {
    const { data } = await api.post<ICentroDocumentacao>(
      `${endpoint}/corredor/adicionar/`,
      formData
    )
    return data
  }

  findOneWithoutPositions = async (
    id: string
  ): Promise<ICentroDocumentacao> => {
    const { data } = await api.get<ICentroDocumentacao>(
      `${this.endpoint}/sem-posicao/${id}`
    )

    return data
  }

  listAllByIdEmpresa = async (id_empresa: string) => {
    const { data } = await api.get<ICentroDocumentacao>(
      `${this.endpoint}/lista/${id_empresa}`
    )
    return data
  }
}

const apiCentroDocumentacao = new ServiceCentroDocumentacao(
  endpoint,
  resourceId
)

export const ApiCentroDocumentacao = {
  ...apiCentroDocumentacao
}
