import { Box } from '@mui/material'
import { ReactNode } from 'react'

interface DashboardWidgetListBodyRootProps {
  children: ReactNode
}

export function DashboardWidgetListBodyRoot({
  children
}: DashboardWidgetListBodyRootProps) {
  return (
    <Box
      sx={{
        width: '100%',
        padding: '1rem'
      }}
    >
      {children}
    </Box>
  )
}
