import { useState } from 'react'
import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import { Input } from '../../../../components/Form/Input'
import { useRequisicao } from '../../../../hooks/requisicao/useRequisicao'
import { ToastType } from '../../lib/widgets/@types/toast'

type ProtocoloEletronicoProps = {
  open: boolean
  handleClose: () => void
  toast: ToastType
  id_requisicao: string
  id_lote: string
}

export function ProtocoloEletronico({
  open,
  handleClose,
  toast,
  id_requisicao,
  id_lote
}: ProtocoloEletronicoProps) {
  const { mutateAsync: validate } = useRequisicao.ValidateToken()
  const [codigo, setCodigo] = useState<string>('')
  useState<boolean>(false)

  const onSubmit = () => {
    toast(validate({ id_lote, id_requisicao, token: codigo }))
    setCodigo('')
    handleClose()
  }

  const handleCodigoChange = (newCodigo: string) => {
    setCodigo(newCodigo)
  }

  const handleCloseForm = () => {
    handleClose()
  }

  return (
    <Modal
      open={open}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        top: '20%'
      }}
    >
      <Box
        borderRadius="4px"
        borderColor="lightgray"
        sx={{
          bgcolor: 'background.paper',
          boxShadow: 24
          // width: 880
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          bgcolor="#2835ad"
          px={2}
          sx={{
            borderTopRightRadius: '4px',
            borderTopLeftRadius: '4px'
          }}
        >
          <Typography color="#FFF" py={1.5} variant="subtitle1">
            Protocolo Eletrônico
          </Typography>

          <Tooltip title="Fechar" arrow>
            <IconButton onClick={() => handleCloseForm()}>
              <CloseIcon sx={{ color: '#fff' }} />
            </IconButton>
          </Tooltip>
        </Box>

        <Stack p={2}>
          <Stack gap={1}>
            <Typography>
              Insira a <strong>Chave da Requisição</strong> para confirmar a
              entrega/recebimento:
            </Typography>
            <Input
              label="Código*"
              value={codigo}
              onChange={(event) => {
                handleCodigoChange(String(event.target.value))
              }}
            />
          </Stack>
        </Stack>

        <Divider />

        <Stack direction="row" gap={2} p={2} justifyContent="flex-end">
          <Button
            variant="contained"
            onClick={() => onSubmit()}
            sx={{
              fontWeight: 600
            }}
          >
            Confirmar
          </Button>
        </Stack>
      </Box>
    </Modal>
  )
}
