import { ApiRelatorio } from '../../../../services/relatorio'
import { UsuariosPorEmpresaReport } from './report/UsuariosPorEmpresaReport'
import {
  IHandleReportSubmitProps,
  ReportForm
} from '../../../../components/ReportForm'

export function UsuariosPorEmpresaRelatorio() {
  async function handleSubmitForm({
    mode,
    format,
    id_usuario,
    filtros
  }: IHandleReportSubmitProps) {
    const { data_fim, data_inicio, id_empresa, id_setor } = filtros

    const usuariosPorEmpresaData = await ApiRelatorio.getUsersByCompany({
      id_empresa,
      id_setor,
      data_fim,
      data_inicio
    })

    if (format === 'pdf') {
      UsuariosPorEmpresaReport({
        data: usuariosPorEmpresaData,
        id_usuario: id_usuario,
        filtros: {
          id_empresa,
          id_setor,
          data_fim,
          data_inicio
        },
        mode
      })
    }

    return usuariosPorEmpresaData
  }
  return (
    <ReportForm
      title="Usuários por Empresa"
      handleReportSubmit={handleSubmitForm}
      mostrarPeriodo={true}
      isEmpresaObrigatorio={false}
    />
  )
}
