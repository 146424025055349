import { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  List,
  ListItemButton,
  ListItemText,
  FormControlLabel,
  Stack,
  TextField,
  Typography
} from '@mui/material'

import SaveIcon from '@mui/icons-material/CheckCircleOutlined'
import CancelIcon from '@mui/icons-material/DoDisturbAltOutlined'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Add, ManageSearch, Remove } from '@mui/icons-material'
import { v4 as uuid } from 'uuid'

import * as yup from 'yup'

import useToast from '../../../hooks/toast/useToast'
import { Input } from '../../../components/Form/Input'
import msg from '../../../utils/validationMessages'
import { Select } from '../../../components/Form/Select'
import { FormMode } from '../../../types/formMode'
import {
  IContrato,
  IPessoa,
  IServico,
  IItemContrato,
  IPrecoAtividade,
  ITipoCaixaContrato
} from '../../../services/contrato/types'
import { useContrato } from '../../../hooks/contrato/useContrato'
import { useEmpresa } from '../../../hooks/empresa/useEmpresa'
import { usePessoa } from '../../../hooks/pessoa/usePessoa'
import { Loading } from '../../../components/Loading'
import { SearchDialog } from '../../../components/SearchDialog'
import { ComponentGroup } from '../../../components/ComponentGroup'
import { useServico } from '../../../hooks/servico/useServico'
import { ServicoSelect } from './components/ServicoSelect'
import { ItemContratoAccordion } from './components/ItemContratoAccordion'
import { useTipoCaixa } from '../../../hooks/tipoCaixa/useTipoCaixa'

const schema = yup
  .object({
    descricao: yup.string().required(msg.REQUIRED).min(3, msg.MIN(3)),
    empresa_contratante: yup.object({
      id_empresa: yup.string().required(msg.REQUIRED)
    }),
    gerente: yup.object({
      id_pessoa: yup.string().required(msg.REQUIRED)
    }),
    responsavel: yup.object({
      id_pessoa: yup.string().required(msg.REQUIRED)
    }),
    data_inicio: yup.date().required(msg.REQUIRED),
    data_assinatura: yup.date().required(msg.REQUIRED),
    id_projeto_sgd: yup.string().required(msg.REQUIRED),
    tipos_caixas_contratos: yup.array().of(
      yup.object().shape({
        tipo_caixa: yup.object().shape({
          id_tipo_caixa: yup.string().required(msg.REQUIRED),
          descricao: yup.string()
        })
      })
    ),
    itens_contrato: yup
      .array()
      .of(
        yup.object().shape({
          servico: yup.object({
            id_servico: yup.string().required(msg.REQUIRED)
          }),
          precos_atividades: yup.array().of(
            yup.object().shape({
              valor: yup
                .number()
                .required(msg.REQUIRED)
                .min(0, msg.REAL({ min: 0 })),
              sla: yup
                .number()
                .required(msg.REQUIRED)
                .min(0, msg.NUMBER({ min: 0 })),
              atividade: yup.object({
                id_atividade: yup.string().required(msg.REQUIRED)
              }),
              setor: yup.object({
                id_setor: yup.string().required(msg.REQUIRED)
              })
            })
          )
        })
      )
      .required(msg.REQUIRED)
  })
  .required()

type PessoaIdentifier = 'gerente' | 'responsavel'
type TipoCaixaIdentifier = 'disponivel' | 'selecionado'

interface IDialogData {
  title: string
  identifier: PessoaIdentifier
}

interface IFormProps {
  data: IContrato
  formMode: FormMode
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

export function Form({ data, formMode, setFormMode }: IFormProps) {
  /* Inicialização de estados (INICIO) -------------------------------------- */
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [id_empresa_contratante, setIdEmpresaContratante] = useState<string>(
    data?.empresa_contratante?.id_empresa ?? ''
  )
  const [pessoasFiltradas, setPessoasFiltradas] = useState<Array<any>>([])
  const [gerente, setGerente] = useState<IPessoa>(
    data?.gerente ?? { id_pessoa: '', nome: '' }
  )
  const [responsavel, setResponsavel] = useState<IPessoa>(
    data?.responsavel ?? { id_pessoa: '', nome: '' }
  )
  const [diagData, setDiagData] = useState<IDialogData>({
    title: 'Selecione o Gerente da Empresa',
    identifier: 'gerente'
  })
  const [servicosFiltrados, setServicosFiltrados] = useState<Array<IServico>>(
    []
  )
  const [servicoSelecionado, setServicoSelecionado] = useState<IServico>({
    id_servico: '',
    nome: ''
  })
  const [itensContrato, setItensContrato] = useState<Array<IItemContrato>>([])
  const [inicioVigencia, setInicioVigencia] = useState<Date>(
    data.data_inicio ?? new Date()
  )

  const [tiposCaixas, setTiposCaixas] = useState<Array<ITipoCaixaContrato>>([])
  const [listaFiltradaTiposCaixas, setListaFiltradaTiposCaixas] = useState<
    Array<ITipoCaixaContrato>
  >([])
  const [tipoCaixaSelecionada, setTipoCaixaSelecionada] =
    useState<ITipoCaixaContrato>({
      tipo_caixa: {
        id_tipo_caixa: '',
        descricao: ''
      }
    })
  const [tipoCaixaFiltradaSelecionada, setTipoCaixaFiltradaSelecionada] =
    useState<ITipoCaixaContrato>({
      tipo_caixa: {
        id_tipo_caixa: '',
        descricao: ''
      }
    })
  const [showDialogContrato, setShowDialogContrato] = useState<boolean>(false)

  const [errosItensContrato, setErrosItensContrato] = useState<boolean>(false)

  const [descricao, setDescricao] = useState(data.descricao)
  /* Inicialização de estados (FIM) ----------------------------------------- */

  const {
    register,
    handleSubmit,
    setValue,

    formState: { errors }
  } = useForm<IContrato>({
    resolver: yupResolver(schema)
  })

  /* Recuperação de dados auxiliares (INICIO) ------------------------------- */
  const { Toast } = useToast()
  const { mutateAsync: create } = useContrato.Create()
  const { mutateAsync: update } = useContrato.Update()
  const { data: listTiposCaixas } = useTipoCaixa.ListAll()
  const { data: empresas } = useEmpresa.ListAll()
  const { data: pessoas } = usePessoa.ListAll()
  const { data: contratos } = useContrato.ListAll()
  const { data: servicos, isLoading } = useServico.ListAll()
  /* Recuperação de dados auxiliares (FIM) ---------------------------------- */

  /* Funções de efeitos colaterais (INICIO) --------------------------------- */

  //Na inicialização ou no recarregamento da lista de serviços, os dados do form
  //devem ser repreenchidos, para evitar que componentes não vinculados deixem
  //os dados do form não preenchidos

  useEffect(() => {
    defineValues(data)
  }, [servicos])

  function defineValues(dt: IContrato) {
    if (dt.descricao) {
      data.descricao = dt.descricao
      setValue('descricao', dt.descricao)
    }
    if (formMode === 'CREATE') {
      setValue('data_fim', undefined)
    }
    if (dt.gerente?.id_pessoa) {
      setValue('gerente', dt.gerente)
    }

    setValue('data_inicio', dt.data_inicio ? dt.data_inicio : new Date())
    setValue(
      'data_assinatura',
      dt.data_assinatura ? dt.data_assinatura : new Date()
    )

    if (dt.responsavel?.id_pessoa) {
      setValue('responsavel', dt.responsavel)
    }

    if (dt.empresa_contratante?.id_empresa) {
      setValue('empresa_contratante', dt.empresa_contratante)
    }

    if (dt.tipos_caixas_contratos && dt.tipos_caixas_contratos.length > 0) {
      setValue('tipos_caixas_contratos', dt.tipos_caixas_contratos)
      setTiposCaixas([...dt.tipos_caixas_contratos])
    }

    if (dt.itens_contrato && dt.itens_contrato.length > 0) {
      setValue('itens_contrato', dt.itens_contrato)
    }
    let listaServicos: Array<string> = []
    if (dt.itens_contrato) {
      const servicosContrato = dt.itens_contrato.map((itemContrato) => {
        return itemContrato.servico.id_servico
      })
      listaServicos = [...servicosContrato]
      setItensContrato(dt.itens_contrato)
    }
    const newServicosFiltrados = servicos
      ?.sort((a, b) => {
        if (a.nome > b.nome) {
          return 1
        }
        if (a.nome < b.nome) {
          return -1
        }
        return 0
      })
      ?.filter(
        (servico) =>
          !!servico.modelagem_fluxo &&
          servico.modelagem_fluxo.length > 0 &&
          !listaServicos.some((itemLista) => itemLista === servico.id_servico)
      )
      .map((servico) => ({
        id_servico: servico?.id_servico!,
        nome: servico?.nome
      }))
    setServicosFiltrados(newServicosFiltrados ?? [])
  }

  //Quando os dados auxiliares de tipos de caixas ou a lista de tipos de caixas
  //definida para o form mudarem, a lista de tipos de caixas filtradas deve
  //mudar para indicar o novo estado
  useEffect(() => {
    const newListaFiltrada = [...(listTiposCaixas ?? [])]
      .filter(
        (tipoCaixa) =>
          !tiposCaixas.some(
            (selectedTipoCaixa) =>
              selectedTipoCaixa.tipo_caixa.id_tipo_caixa ===
              tipoCaixa.id_tipo_caixa
          )
      )
      .map(
        (newTipoCaixa) =>
          ({
            tipo_caixa: {
              id_tipo_caixa: newTipoCaixa.id_tipo_caixa,
              descricao: newTipoCaixa.descricao
            }
          } as ITipoCaixaContrato)
      )
    setListaFiltradaTiposCaixas(newListaFiltrada)
  }, [listTiposCaixas, tiposCaixas])

  useEffect(() => {
    const newPessoas = pessoas?.filter(
      (pessoa) => pessoa.setor.empresa.id_empresa === id_empresa_contratante
    )
    setPessoasFiltradas(newPessoas ?? [])
  }, [id_empresa_contratante])

  /* Funções de efeitos colaterais (FIM) ------------------------------------ */

  /* Funções para interação com a interface (INÍCIO) -------------------------*/
  /**
   * Recuperar o id da empresa selecionada na interface e preencher o dado
   * correspondente no form
   * @param id ID da empresa contratante
   */
  function handleEmpresaContratanteChange(id: string) {
    setIdEmpresaContratante(id)
    setValue('empresa_contratante.id_empresa', id)
  }

  function handleDescricaoChange(texto: string) {
    setDescricao(texto)
    setValue('descricao', texto)
  }

  /**
   * Recuperar o id da pessoa selecionada na interface e preencher o dado
   * correspondente no form
   * @param pessoa ID da pessoa
   * @param field Tipo de pessoa a ser definida ('gerente' ou 'responsavel')
   */
  function handlePessoaChange(
    pessoa: IPessoa,
    field: 'gerente' | 'responsavel'
  ) {
    if (field === 'gerente') {
      setGerente({ id_pessoa: pessoa.id_pessoa, nome: pessoa.nome })
      setValue('gerente.id_pessoa', pessoa.id_pessoa)
      setValue('gerente.nome', pessoa.nome)
    } else {
      setResponsavel({ id_pessoa: pessoa.id_pessoa, nome: pessoa.nome })
      setValue('responsavel.id_pessoa', pessoa.id_pessoa)
      setValue('responsavel.nome', pessoa.nome)
    }
  }

  /**
   * Define o valor da flag para mostrar ou esconder o diálogo de seleção de
   * pessoas.
   */
  function handleToggleShowDialog() {
    setShowDialog((previousValue) => !previousValue)
  }

  /**
   * Define dados relativos a pessoas para exibição do diálogo de seleção
   * @param identifier Tipo de pessoa a ser definida('gerente' ou 'responsavel')
   */
  function handleShowSelectDialog(identifier: PessoaIdentifier) {
    switch (identifier) {
      case 'gerente': {
        setDiagData({ title: 'Selecione o Gerente da Empresa', identifier })
        break
      }
      case 'responsavel': {
        setDiagData({
          title: 'Selecione o Responsável pelo Contrato',
          identifier
        })
      }
    }
    handleToggleShowDialog()
  }

  /**
   * Define o estado atual de serviço selecionado
   * @param id ID do serviço
   */
  function handleSelectServico(id: string) {
    const newServicoSelectionado = servicosFiltrados.find(
      (servico) => servico.id_servico === id
    )
    setServicoSelecionado(
      newServicoSelectionado ?? { id_servico: '', nome: '' }
    )
  }

  /**
   * Converte o serviço atualmente selecionado para item de contrato e remove da
   * lista de serviços disponíveis.
   */
  function handleAddServico() {
    if (
      !!servicoSelecionado.id_servico &&
      servicoSelecionado.id_servico.length > 0
    ) {
      setServicosFiltrados((previousValue) => {
        const newServicos = previousValue
          .filter(
            (servico) => !(servico.id_servico === servicoSelecionado.id_servico)
          )
          .sort((a, b) => {
            if (a.nome && b.nome && a.nome > b.nome) return 1
            if (a.nome && b.nome && a.nome < b.nome) return -1
            return 0
          })
        return newServicos
      })

      setItensContrato((previousValue) => {
        const newItensContrato = [
          {
            servico: servicoSelecionado,
            precos_atividades: [],
            id_item_contrato: uuid()
          },
          ...previousValue
        ]
        return newItensContrato
      })
      setServicoSelecionado({ id_servico: '', nome: '' })
    }
  }

  /**
   * Converte um item de contrato (vinculado a um serviço) em um serviço
   * na lista de serviços disponíveis.
   * @param id ID do serviço a ser convertido
   */
  function handleDeleteServico(id: string) {
    const deletedServico = servicos?.find(
      (servico) => servico.id_servico === id
    ) ?? { id_servico: '', nome: '' }
    setServicosFiltrados((previousValue) => {
      const newServicosFiltrados = [
        ...previousValue,
        {
          id_servico: deletedServico?.id_servico ?? '',
          nome: deletedServico?.nome ?? ''
        }
      ].sort((a, b) => {
        if (a?.nome && b?.nome && a?.nome > b?.nome) {
          return 1
        }
        if (a?.nome && b?.nome && a?.nome < b?.nome) {
          return -1
        }
        return 0
      })
      return newServicosFiltrados
    })
    const newItensContrato = itensContrato.filter(
      (itemContrato) => itemContrato.servico.id_servico !== id
    )
    setItensContrato(newItensContrato)
    setValue('itens_contrato', newItensContrato)
  }

  /**
   * Função para lidar com a mudança dos dados dos itens de contrato. Esta
   * função é usada pelos componentes que lidam diretamente com os dados dos
   * itens de contrato.
   * @param itemContrato Dados do item de contrato
   */
  function handleUpdateItemContrato(itemContrato: IItemContrato) {
    const oldItemContrato = itensContrato.find(
      (item) => item.id_item_contrato === itemContrato.id_item_contrato
    )
    if (oldItemContrato) {
      const index = itensContrato.indexOf(oldItemContrato)

      const newItensContrato = itensContrato
      newItensContrato[index] = itemContrato
      setValue('itens_contrato', newItensContrato)
      setItensContrato(newItensContrato)
    }
  }

  /**
   * Define a data selecionada para o estado de data inicio (também usada na
   * definição de novos itens de contrato) e o mesmo dado no form
   * @param date Data a ser definida
   */
  function handleVigenciaChange(date: string, inicioVigencia: boolean) {
    if (inicioVigencia) {
      const dataInicio = new Date(date)
      setInicioVigencia(dataInicio)
      const newItensContrato = itensContrato.map((itemContrato) => {
        itemContrato.precos_atividades = itemContrato.precos_atividades.map(
          (precoAtividade) => {
            precoAtividade.data_inicio =
              new Date(precoAtividade.data_inicio) < dataInicio
                ? dataInicio
                : new Date(precoAtividade.data_inicio)
            precoAtividade.atividade.tipo_atividade!.descricao =
              precoAtividade.atividade.tipo_atividade?.descricao +
              precoAtividade.data_inicio.toString()
            return precoAtividade
          }
        )
        return itemContrato
      })
      setItensContrato(newItensContrato)
      setValue('data_inicio', new Date(date))
    } else {
      setValue('data_fim', new Date(date))
    }
  }

  /**
   * Define a data selecionada para data de assinatura do form
   * @param date Data a ser definida
   */
  function handleDataAssinaturaChange(date: string) {
    setValue('data_assinatura', new Date(date))
  }

  /**
   * Converte um item da lista de tipos de caixas disponíveis em um dado do form
   * de tiposCaixas
   * @param tipoCaixa Dados do tipo de caixa a ser convertido
   */
  function handleAddTiposCaixas(tipoCaixa: ITipoCaixaContrato) {
    if (tipoCaixa.tipo_caixa.id_tipo_caixa === '') {
      return
    }
    const newTiposCaixas = [...tiposCaixas, tipoCaixa]
    setValue('tipos_caixas_contratos', newTiposCaixas)
    setTiposCaixas(newTiposCaixas)
    setTipoCaixaFiltradaSelecionada({
      tipo_caixa: { id_tipo_caixa: '', descricao: '' }
    })
  }

  /**
   * Converte um dado do form de tiposCaixas em um item da lista de tipos de
   * caixas disponíveis
   * @param tipoCaixa Dados do tipo de caixa a ser convertido
   */
  function handleRemoveTiposCaixas(tipoCaixa: ITipoCaixaContrato) {
    if (tipoCaixa.tipo_caixa.id_tipo_caixa === '') {
      return
    }
    const newTiposCaixas = tiposCaixas.filter(
      (oldTipoCaixa) =>
        oldTipoCaixa.tipo_caixa.id_tipo_caixa !==
        tipoCaixa.tipo_caixa.id_tipo_caixa
    )
    setValue('tipos_caixas_contratos', newTiposCaixas)
    setTiposCaixas(newTiposCaixas)
    setTipoCaixaSelecionada({
      tipo_caixa: { id_tipo_caixa: '', descricao: '' }
    })
  }

  /**
   * Selecionado os dado sde um tipo de caixa para migrar entre as listas
   * @param tipoCaixa Tipo de caixa
   * @param componente Identificação de a qual lista pertence o dado selecionado
   */
  function handleSelectTipoCaixa(
    tipoCaixa: ITipoCaixaContrato,
    componente: TipoCaixaIdentifier
  ) {
    if (componente === 'disponivel') {
      setTipoCaixaFiltradaSelecionada(tipoCaixa)
    } else {
      setTipoCaixaSelecionada(tipoCaixa)
    }
  }

  /**
   * Muda a flag de exibição do diálogo de cópia de contratos
   */
  function handleToggleShowDialogContrato() {
    setShowDialogContrato((previousState) => !previousState)
  }

  function handleCopyContrato(contrato: IContrato) {
    contrato.gerente = { id_pessoa: '', nome: '' }
    contrato.responsavel = { id_pessoa: '', nome: '' }
    contrato.empresa_contratante = { id_empresa: '', nome: '' }
    contrato.data_assinatura = new Date()
    contrato.data_inicio = new Date()
    defineValues(contrato)
    handleToggleShowDialogContrato()
  }
  /* Funções para interação com a interface (FIM) ----------------------------*/

  /* Funções para lidar com o envio do form (INICIO) -------------------------*/
  const onSubmit = async (formData: IContrato) => {
    const newResponsavel = {
      id_pessoa: formData.responsavel.id_pessoa
    }
    const newGerente = {
      id_pessoa: formData.gerente.id_pessoa
    }
    const newEmpresaContratante = {
      id_empresa: formData.empresa_contratante.id_empresa
    }

    const newItensContratos = itensContrato.map((itemContrato) => {
      const newServico = {
        id_servico: itemContrato.servico.id_servico
      }

      const newPrecos = itemContrato.precos_atividades.map((precoAtividade) => {
        return {
          valor: precoAtividade.valor,
          data_inicio: precoAtividade.data_inicio,
          data_fim: precoAtividade.data_fim,
          sla: precoAtividade.sla,
          atividade: {
            id_atividade: precoAtividade.atividade.id_atividade
          },
          setor: {
            id_setor: precoAtividade.setor.id_setor
          }
        } as IPrecoAtividade
      })

      return {
        id_item_contrato: itemContrato.id_item_contrato,
        servico: newServico,
        precos_atividades: newPrecos
      }
    })
    const newFormData = {
      ...formData,
      empresa_contratante: newEmpresaContratante,
      responsavel: newResponsavel,
      gerente: newGerente,
      ativo: true,
      tipos_caixas_contratos: tiposCaixas.map((tipoCaixa) => ({
        tipo_caixa: {
          id_tipo_caixa: tipoCaixa.tipo_caixa.id_tipo_caixa,
          descricao: tipoCaixa.tipo_caixa.descricao
        }
      })),
      itens_contrato: newItensContratos as Array<IItemContrato>
    }

    formMode === 'CREATE'
      ? await Toast(create(newFormData))
      : await Toast(
          update({
            id_contrato: data.id_contrato,
            ...newFormData
          }),
          'UPDATED'
        )

    setFormMode('LIST')
  }

  const onError = (errors: any) => {
    const keys = Object.keys(errors)
    setErrosItensContrato(keys.some((erro) => erro === 'itens_contrato'))
  }
  /* Funções para lidar com o envio do form (FIM) ----------------------------*/

  if (isLoading) return <Loading />

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit, onError)} mt={4}>
      <Stack
        gap={2}
        style={
          data.ativo === false
            ? {
                pointerEvents: 'none',
                opacity: 0.6
              }
            : undefined
        }
      >
        {formMode === 'CREATE' ? (
          <Box display={'flex'} flexDirection={'row'} justifyContent={'right'}>
            <Button
              sx={{ width: '250px' }}
              variant="outlined"
              onClick={handleToggleShowDialogContrato}
              disabled={data.ativo === false}
            >
              Copiar dados de contrato...
            </Button>
          </Box>
        ) : (
          ''
        )}
        <Input
          label="Descricao*"
          {...(register('descricao'),
          {
            onChange: (event) =>
              handleDescricaoChange(String(event.target.value))
          })}
          defaultValue={data?.descricao}
          message={errors.descricao?.message}
          InputLabelProps={{ shrink: !!descricao }}
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row'
          }}
          gap={2}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            sx={{ width: '60%' }}
            gap={2}
          >
            <Select
              label="Empresa *"
              placeholder="Selecione a Empresa"
              data={empresas}
              fields={['id_empresa', 'nome']}
              {...register('empresa_contratante.id_empresa')}
              value={id_empresa_contratante}
              onChange={(event) => {
                handleEmpresaContratanteChange(String(event.target.value))
              }}
              message={errors.empresa_contratante?.id_empresa?.message}
            />
            <Box display={'flex'} flexDirection={'row'} sx={{ width: '100%' }}>
              <Input
                label="Gerente*"
                value={gerente.nome}
                InputLabelProps={{ shrink: !!gerente.nome }}
                message={errors.gerente?.id_pessoa?.message}
                onClick={() => {
                  handleShowSelectDialog('gerente')
                }}
              />

              <Button
                type="button"
                variant="outlined"
                disabled={data.ativo === false}
                sx={{
                  width: '2rem',
                  borderRadius: '0 8px 8px 0',
                  height: '2.29rem'
                }}
                onClick={() => {
                  handleShowSelectDialog('gerente')
                }}
              >
                <ManageSearch />
              </Button>
            </Box>
            <Box display={'flex'} flexDirection={'row'} sx={{ width: '100%' }}>
              <Input
                label="Responsável*"
                value={responsavel.nome}
                InputLabelProps={{ shrink: !!responsavel.nome }}
                message={errors.responsavel?.id_pessoa?.message}
                onClick={() => {
                  handleShowSelectDialog('responsavel')
                }}
              />

              <Button
                type="button"
                variant="outlined"
                disabled={data.ativo === false}
                sx={{
                  width: '2rem',
                  borderRadius: '0 8px 8px 0',
                  height: '2.29rem'
                }}
                onClick={() => {
                  handleShowSelectDialog('responsavel')
                }}
              >
                <ManageSearch />
              </Button>
            </Box>
            <Box display={'flex'} flexDirection={'row'} gap={2}>
              <TextField
                label="Data de Assinatura *"
                type="date"
                defaultValue={
                  (data.data_assinatura
                    ? new Date(data.data_assinatura)
                    : new Date()
                  )
                    .toISOString()
                    .split('T')[0]
                }
                size="small"
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(event) => {
                  handleDataAssinaturaChange(event.target.value)
                }}
              />
              <TextField
                label="Início de Vigência *"
                type="date"
                defaultValue={
                  (data.data_inicio ? new Date(data.data_inicio) : new Date())
                    .toISOString()
                    .split('T')[0]
                }
                size="small"
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(event) => {
                  handleVigenciaChange(event.target.value, true)
                }}
              />
              <TextField
                label="Fim de Vigência"
                type="date"
                defaultValue={
                  (data.data_fim ? new Date(data.data_fim) : new Date())
                    .toISOString()
                    .split('T')[0]
                }
                size="small"
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(event) => {
                  handleVigenciaChange(event.target.value, false)
                }}
              />
              <Input
                label="ID Projeto - SGD*"
                {...register('id_projeto_sgd')}
                defaultValue={data?.id_projeto_sgd}
                message={errors.id_projeto_sgd?.message}
              />
            </Box>
          </Box>
          <ComponentGroup title="Tipos de Caixas *" width={'40%'}>
            <Box
              display={'flex'}
              flexDirection={'row'}
              gap={1}
              sx={{ py: 0, my: 0 }}
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                gap={1}
                sx={{ width: '40%', py: 0, my: 0 }}
              >
                <Typography
                  sx={{
                    fontSize: '12px',
                    paddingX: '3px',
                    color: 'gray'
                  }}
                >
                  Disponíveis
                </Typography>
                <List
                  sx={{
                    width: '100%',
                    height: '100px',
                    overflow: 'auto',
                    border: '1px solid lightgray',
                    borderRadius: '6px',
                    py: 0
                  }}
                >
                  {listaFiltradaTiposCaixas?.map((tipoCaixa) => (
                    <ListItemButton
                      dense={true}
                      key={tipoCaixa.tipo_caixa.id_tipo_caixa}
                      selected={
                        tipoCaixa.tipo_caixa.id_tipo_caixa ===
                        tipoCaixaFiltradaSelecionada.tipo_caixa.id_tipo_caixa
                      }
                    >
                      <ListItemText
                        primary={tipoCaixa.tipo_caixa.descricao}
                        onClick={() =>
                          handleSelectTipoCaixa(tipoCaixa, 'disponivel')
                        }
                      ></ListItemText>
                    </ListItemButton>
                  ))}
                </List>
              </Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignContent={'center'}
                alignItems={'center'}
                justifyContent={'space-around'}
                gap={1}
                sx={{ width: '20%' }}
              >
                <Button
                  type="button"
                  variant="outlined"
                  disabled={data.ativo === false}
                  onClick={() =>
                    handleAddTiposCaixas(tipoCaixaFiltradaSelecionada)
                  }
                  sx={{
                    maxWidth: '4rem',
                    height: '2rem',
                    justifyContent: 'center',
                    alignContent: 'center'
                  }}
                >
                  <Add />
                </Button>
                <Button
                  type="button"
                  variant="outlined"
                  disabled={data.ativo === false}
                  onClick={() => handleRemoveTiposCaixas(tipoCaixaSelecionada)}
                  sx={{
                    maxWidth: '4rem',
                    height: '2rem',
                    justifyContent: 'center',
                    alignContent: 'center'
                  }}
                >
                  <Remove />
                </Button>
              </Box>

              <Box
                display={'flex'}
                flexDirection={'column'}
                gap={1}
                sx={{ width: '40%' }}
              >
                <Typography
                  sx={{
                    fontSize: '12px',
                    paddingX: '3px',
                    color: 'gray'
                  }}
                >
                  Selecionados
                </Typography>
                <List
                  sx={{
                    width: '100%',
                    height: '100px',
                    overflow: 'auto',
                    border: '1px solid lightgray',
                    borderRadius: '6px',
                    py: 0
                  }}
                >
                  {tiposCaixas?.map((tipoCaixa) => (
                    <ListItemButton
                      dense={true}
                      key={tipoCaixa.tipo_caixa.id_tipo_caixa}
                      selected={
                        tipoCaixa.tipo_caixa.id_tipo_caixa ===
                        tipoCaixaSelecionada.tipo_caixa.id_tipo_caixa
                      }
                    >
                      <ListItemText
                        primary={tipoCaixa.tipo_caixa.descricao}
                        onClick={() =>
                          handleSelectTipoCaixa(tipoCaixa, 'selecionado')
                        }
                      ></ListItemText>
                    </ListItemButton>
                  ))}
                </List>
              </Box>
            </Box>
            <Box sx={{ p: 0, m: 0 }}>
              <FormControlLabel
                sx={{ p: 0, m: 0 }}
                control={
                  <Checkbox
                    defaultChecked={data?.lacrado}
                    {...register('lacrado')}
                  />
                }
                label="Todas as caixas deste contrato são lacradas"
                disabled={false} //{!editavel}
              />
            </Box>
          </ComponentGroup>
        </Box>

        <ComponentGroup title="Itens do Contrato*" erro={errosItensContrato}>
          <ServicoSelect
            data={servicosFiltrados}
            selectedItem={servicoSelecionado.id_servico}
            addServicoCallback={handleAddServico}
            selectServicoCallback={handleSelectServico}
            disableButtons={data.ativo === false}
          />

          <Box>
            {itensContrato.map((itemContrato) => {
              return (
                <ItemContratoAccordion
                  key={itemContrato.servico.id_servico}
                  itemContrato={itemContrato}
                  dataVigencia={inicioVigencia}
                  removeItemPrecoCallBack={handleDeleteServico}
                  updateDataCallback={handleUpdateItemContrato}
                  disableButtons={data.ativo === false}
                />
              )
            })}
          </Box>
        </ComponentGroup>
      </Stack>

      <Stack direction="row" gap={2} mt={3}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<CancelIcon />}
          onClick={() => setFormMode('LIST')}
        >
          Cancelar
        </Button>

        <Button
          type="submit"
          variant="contained"
          startIcon={<SaveIcon />}
          sx={{ width: '110px' }}
          disabled={data.ativo === false}
        >
          Salvar
        </Button>
      </Stack>
      <SearchDialog
        data={pessoasFiltradas}
        displayFields={[
          { fieldName: 'nome', fieldLabel: 'Nome' },
          { fieldName: 'cpf', fieldLabel: 'CPF' }
        ]}
        keyField={'id_pessoa'}
        title={diagData.title}
        innerPlaceholder={'Nome/CPF'}
        handleAnswerSelect={(pessoa) => {
          handlePessoaChange(pessoa, diagData.identifier)
          handleToggleShowDialog()
        }}
        handleAnswerCancel={handleToggleShowDialog}
        openDialog={showDialog}
      />
      <SearchDialog
        data={contratos!}
        displayFields={[
          {
            fieldName: 'empresa_contratante.nome',
            fieldLabel: 'Empresa Contratante'
          },
          { fieldName: 'descricao', fieldLabel: 'Descrição' },
          { fieldName: 'data_inicio', fieldLabel: 'Início de Vigência' },
          { fieldName: 'data_assinatura', fieldLabel: 'Data de Assinatura' }
        ]}
        keyField={'id_contrato'}
        title={'Selecione o contrato a ser copiado'}
        innerPlaceholder={'Nome/CPFSEl'}
        handleAnswerSelect={(contrato) => {
          handleCopyContrato(contrato)
        }}
        handleAnswerCancel={handleToggleShowDialogContrato}
        openDialog={showDialogContrato}
      />
    </Box>
  )
}
