import { useQuery } from 'react-query'
import { ApiPrateleira } from '../../services/prateleira'

const QUERY_KEY = 'qkPrateleira'

const ListAll = () => {
  return useQuery([QUERY_KEY], ApiPrateleira.listAll)
}

const FindByEstante = (id: string) => {
  return useQuery([QUERY_KEY, id], () => ApiPrateleira.findByEstante(id))
}

export const usePrateleira = {
  ListAll,
  FindByEstante
}
