import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select as SelectMaterial,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material'

import SaveIcon from '@mui/icons-material/CheckCircleOutlined'
import BackIcon from '@mui/icons-material/ArrowBack'
import ForwardIcon from '@mui/icons-material/ArrowForward'
import SearchIcon from '@mui/icons-material/Search'
import InventoryIcon from '@mui/icons-material/Inventory'
import ListIcon from '@mui/icons-material/List'
import { Cancel, DeleteForever } from '@mui/icons-material'
import { toast as toastDef } from 'react-toastify'

import boxImg from '../../../assets/images/box.png'
import { Timeline } from '../Timeline'
import { ICaixaNaoAtendida } from '../../../services/caixa/types'
import { IAlocacao, IPosicaoCaixa } from '../../../services/movimentacao/types'
import { useUsuario } from '../../../hooks/usuario/useUsuario'
import { useCentroDocumentacao } from '../../../hooks/centroDocumentacao/useCentroDocumentacao'
import { ICentroDocumentacao } from '../../../services/centroDocumentacao/types'
import { ICorredor } from '../../../services/corredor/types'
import { IEstante } from '../../../services/estante/types'
import { useCorredor } from '../../../hooks/corredor/useCorredor'
import { useEstante } from '../../../hooks/estante/useEstante'
import { usePrateleira } from '../../../hooks/prateleira/usePrateleira'
import { usePosicaoPrateleira } from '../../../hooks/posicaoPrateleira/usePosicaoPrateleira'
import { Loading } from '../../../components/Loading'
import { ComponentGroup } from '../../../components/ComponentGroup'
import { Select } from '../../../components/Form/Select'
import { IPrateleira } from '../../../services/prateleira/types'
import { IPosicaoPrateleira } from '../../../services/posicaoPrateleira/types'
import { useMovimentacao } from '../../../hooks/movimentacao/useMovimentacao'
import { getCodigo } from '../../../utils/getCodigo'
import { DashboardWidgetListBody } from '../DashboardWidgetList/DashboardWidgetListBody'
import { corFundoLinha } from '../../../pages/Dashboard/Widgets/lib/consts'
import { corTextoLinha } from '../cores'
import { DashboardWidget } from '..'

export const ImportSteps = [
  'Seleção de Caixas',
  'Tipo de Alocação',
  'Posições de Caixas',
  'Confirmação de Dados'
]

interface VisaoCaixasProps {
  isVisaoCaixas: boolean
  setIsVisaoCaixas: (value: boolean) => void
}

export default function VisaoCaixas({
  isVisaoCaixas,
  setIsVisaoCaixas
}: VisaoCaixasProps) {
  const handleView = (
    event: React.MouseEvent<HTMLElement>,
    novaVisao: boolean
  ) => {
    setIsVisaoCaixas(novaVisao)
  }

  return (
    <ToggleButtonGroup
      color="primary"
      value={isVisaoCaixas}
      exclusive
      onChange={handleView}
      aria-label="text alignment"
    >
      <ToggleButton value={true} aria-label="Visão de Caixas">
        <InventoryIcon />
      </ToggleButton>
      <ToggleButton value={false} aria-label="Visão de Listas">
        <ListIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

interface DashboardWidgetFormAlocacaoProps {
  listaCaixaNaoAlocadas: ICaixaNaoAtendida[]
  // allocateBoxes: AllocateBoxType
}

type TTipoAlocacao = 0 | 1 | 2 | 3 | 4

interface LocalIPosicaoCaixa extends IPosicaoCaixa {
  caixaNaoAtendida: ICaixaNaoAtendida
  identificadorAlocacao: string
}

export function DashboardWidgetFormAlocacao({
  listaCaixaNaoAlocadas
}: DashboardWidgetFormAlocacaoProps) {
  // Dados (INÍCIO) ------------------------------------------------------------
  const { mutateAsync: allocateBoxes } = useMovimentacao.Allocate()
  // Dados da sessão (INÍCIO)
  const { data: usuario } = useUsuario.FindByUsername()
  // Dados da sessão (FIM)

  // Navegação (INÍCIO)
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [canGoNext, setCanGoNext] = useState<boolean>(false)
  // Navegação (FIM)

  // Seleção de Caixas (INÍCIO)
  const [selectedBoxes, setSelectedBoxes] = useState<ICaixaNaoAtendida[]>([])
  const [selectedBoxesUnallocated, setSelectedBoxesUnallocated] = useState<
    ICaixaNaoAtendida[]
  >([])
  // Seleção de Caixas (FIM)

  // Tipo de Alocação (INÍCIO)
  const [tipoAlocacao, setTipoAlocacao] = useState<TTipoAlocacao>(0)
  // Tipo de Alocação (FIM)

  // Vínculo de Caixa com Posições (INÍCIO)
  const blankBox: ICaixaNaoAtendida = {
    ativo: false,
    conteudo: '',
    identificacao: '',
    item_requisicao: {
      movimentacoes: [{ id_movimentacao: '' }]
    }
  }
  const [currentBox, setCurrentBox] = useState<ICaixaNaoAtendida>(blankBox)
  const [posicoesCaixas, setPosicoesCaixas] = useState<LocalIPosicaoCaixa[]>([])

  const [idCentro, setIdCentro] = useState<string>('')
  const [idCorredor, setIdCorredor] = useState<string>('')
  const [idEstante, setIdEstante] = useState<string>('')
  const [idPrateleira, setIdPrateleira] = useState<string>('')
  const [idPosicaoPrateleira, setIdPosicaoPrateleira] = useState<string>('')

  const { data: centrosDocumentacao, isLoading: isLoadingCentro } =
    useCentroDocumentacao.ListAll()
  const [centrosDocumentacaoFiltrado, setCentrosDocumentacaoFiltrados] =
    useState<ICentroDocumentacao[]>([])
  const [corredores, setCorredores] = useState<ICorredor[]>([])
  const { data: listaCorredores, isLoading: isLoadingCorredores } =
    useCorredor.FindByCentro(idCentro)
  const [estantes, setEstantes] = useState<IEstante[]>([])
  const { data: listaEstantes, isLoading: isLoadingEstantes } =
    useEstante.FindByCorredor(idCorredor)
  const [prateleiras, setPrateleiras] = useState<IPrateleira[]>([])
  const { data: listaPrateleiras, isLoading: isLoadingPrateleiras } =
    usePrateleira.FindByEstante(idEstante)
  const {
    data: listaPosicoesPrateleira,
    isLoading: isLoadingPosicoesPrateleiras
  } = usePosicaoPrateleira.FindByPrateleira(idPrateleira)
  const [posicoesPrateleiras, setPosicoesPrateleiras] = useState<
    IPosicaoPrateleira[]
  >([])

  const [marcarTodos, setMarcarTodos] = useState<boolean>(false)
  const [isVisaoCaixas, setIsVisaoCaixas] = useState<boolean>(false) // true- Visão em caixas false-lista
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false)
  const [buscaSelecionada, setBuscaSelecionada] = useState<string>('')

  const checaFiltroCaixa = (caixa: ICaixaNaoAtendida) => {
    return getCodigo(caixa.item_requisicao.id_requisicao ?? '').includes(
      buscaSelecionada.toUpperCase().trim()
    )
  }

  function compararPorIdentificacao(
    a: ICaixaNaoAtendida,
    b: ICaixaNaoAtendida
  ) {
    if (a.identificacao < b.identificacao) {
      return -1
    }
    if (a.identificacao > b.identificacao) {
      return 1
    }
    return 0
  }
  const caixasOrdenadas = listaCaixaNaoAlocadas.sort(compararPorIdentificacao)

  const [listaCaixaNaoAlocadasfiltradas, setListaCaixaNaoAlocadasfiltradas] =
    useState<ICaixaNaoAtendida[]>(caixasOrdenadas)

  const handleFiltrarCaixas = () => {
    const caixasFiltradas =
      buscaSelecionada.trim() !== ''
        ? caixasOrdenadas.filter((caixa) => checaFiltroCaixa(caixa))
        : caixasOrdenadas
    setListaCaixaNaoAlocadasfiltradas(caixasFiltradas)
  }

  const debouncedBuscaOnChange = useCallback(
    debounce((value) => {
      setBuscaSelecionada(String(value))
    }, 500),
    []
  )

  useEffect(() => {
    handleFiltrarCaixas()
  }, [listaCaixaNaoAlocadas])

  useEffect(() => {
    if (listaCorredores) {
      setIdCorredor('')
      setCorredores(listaCorredores)
    }
  }, [listaCorredores])

  useEffect(() => {
    if (listaEstantes) {
      setIdEstante('')
      setEstantes(listaEstantes)
    }
  }, [listaEstantes])

  useEffect(() => {
    if (listaPrateleiras) {
      setIdPrateleira('')
      setPrateleiras(listaPrateleiras)
    }
  }, [listaPrateleiras])

  useEffect(() => {
    const newPosicoes =
      listaPosicoesPrateleira?.filter((posicao) => {
        const some = posicoesCaixas.find(
          (pos) => pos.posicao === posicao.id_posicao_prateleira
        )
        return !some
      }) ?? []
    setPosicoesPrateleiras(newPosicoes)
  }, [listaPosicoesPrateleira, posicoesCaixas])

  useEffect(() => {
    if (centrosDocumentacao) {
      setCentrosDocumentacaoFiltrados(
        centrosDocumentacao.filter(
          (cd) => cd.id_empresa === usuario?.pessoa.setor.empresa?.id_empresa
        )
      )
    }
  }, [centrosDocumentacao])
  // Vínculo de Caixa com Posições (FIM)

  // Dados (FIM) ---------------------------------------------------------------

  // Handles (INÍCIO) ----------------------------------------------------------
  // Navegação (INÍCIO)
  const handleNextStepClick = () => {
    if (canGoNext) {
      let step = currentStep

      if ([2, 3, 4].includes(step)) {
        step = 5
        setCanGoNext(true)
      }
      if (step === 1) {
        if (tipoAlocacao === 0) {
          step = 2
          setCanGoNext(false)
        }
        if ([1, 3].includes(tipoAlocacao)) {
          step = 4
          // const newBoxes: LocalIPosicaoCaixa[] = selectedBoxes.map((box) => ({
          //   caixa: box.id_caixa ?? '',
          //   caixaNaoAtendida: box,
          //   posicao: '',
          //   identificadorAlocacao: '',
          //   cd: ''
          // }))
          // setPosicoesCaixas(newBoxes)
          setCanGoNext(idCentro !== '0')
        }
        if ([2, 4].includes(tipoAlocacao)) {
          step = 3
          setCanGoNext(false)
        }
      }
      if (step === 0) {
        step = 1
        setCanGoNext(true)
      }
      setCurrentStep(step)
    }
  }

  const handlePreviousStepClick = () => {
    let step = currentStep
    if (step > 0) {
      if (step === 1) {
        step = 0
      }
      if ([2, 3].includes(step)) {
        step = 1
      }
      if (step === 4) {
        if (tipoAlocacao === 0) {
          step = 2
        }
        if ([1, 3].includes(tipoAlocacao)) {
          step = 1
        }
        if ([2, 4].includes(tipoAlocacao)) {
          step = 3
        }
      }
    }
    setCurrentStep(step)
    setCanGoNext(true)
  }

  const handleCancelClick = () => {
    setCurrentStep(0)
    setCanGoNext(false)
    setTipoAlocacao(0)
    setSelectedBoxes([])
    setSelectedBoxesUnallocated([])
    setPosicoesPrateleiras([])
    setPosicoesCaixas([])
    setCurrentBox(blankBox)
    setMarcarTodos(false)

    setIdCentro('')
    setIdCorredor('')
    setIdEstante('')
    setIdPrateleira('')
    setIdPosicaoPrateleira('')
  }
  // Navegação (FIM)

  // Selecção de Caixas (INÍCIO)
  const handleAddBox = (box: ICaixaNaoAtendida) => {
    let boxes = []
    if (!selectedBoxes.includes(box)) {
      boxes = [...selectedBoxes, box]
    } else {
      boxes = selectedBoxes.filter(
        (state) => state.id_caixa !== (box.id_caixa ?? '')
      )
      setMarcarTodos(false)
    }
    setSelectedBoxes(boxes)
    setSelectedBoxesUnallocated(boxes)
    setPosicoesCaixas([])
    setCurrentBox(blankBox)
    setCanGoNext(boxes.length > 0)

    setTipoAlocacao(0)
    setPosicoesCaixas([])
    setCurrentBox(blankBox)

    setIdCentro('')
    setIdCorredor('')
    setIdEstante('')
    setIdPrateleira('')
    setIdPosicaoPrateleira('')
  }

  // Selecção de Caixas (INÍCIO)
  const handleAddAllBox = () => {
    let boxes: ICaixaNaoAtendida[] = []
    if (!marcarTodos) {
      boxes = caixasOrdenadas
    }
    setMarcarTodos(!marcarTodos)

    setSelectedBoxes(boxes)
    setSelectedBoxesUnallocated(boxes)
    setPosicoesCaixas([])
    setCurrentBox(blankBox)
    setCanGoNext(boxes.length > 0)

    setTipoAlocacao(0)
    setPosicoesCaixas([])
    setCurrentBox(blankBox)

    setIdCentro('')
    setIdCorredor('')
    setIdEstante('')
    setIdPrateleira('')
    setIdPosicaoPrateleira('')
  }
  // Selecção de Caixas (FIM)
  const isfiltroCaixa =
    listaCaixaNaoAlocadasfiltradas.length < listaCaixaNaoAlocadas.length
  const colunasLista = [
    {
      title: '',
      width: 20,
      component: (
        <Checkbox
          onClick={handleAddAllBox}
          checked={marcarTodos}
          disabled={isfiltroCaixa}
        />
      )
    },
    {
      title: 'Código da Requisição',
      width: 100
    },
    {
      title: 'Descrição'
    },
    {
      title: 'Tipo Caixa'
    }
  ]

  // Tipo de Alocação (INÍCIO)
  const handleTipoAlocacaoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTipoAlocacao(
      Number((event.target as HTMLInputElement).value) as TTipoAlocacao
    )
    setPosicoesCaixas([])
    setCurrentBox(blankBox)

    setIdCentro('')
    setIdCorredor('')
    setIdEstante('')
    setIdPrateleira('')
    setIdPosicaoPrateleira('')
  }
  // Tipo de Alocação (FIM)

  // Alocação de Caixas - MANUAL (INÍCIO)
  const handleBoxClick = (box: ICaixaNaoAtendida) => {
    setCurrentBox(box)
  }

  const getLocationDescriptor = (id: string): string => {
    const posicao = posicoesPrateleiras?.find(
      (pos) => pos.id_posicao_prateleira === id
    )
    const prateleira = prateleiras?.find(
      (pra) => pra.id_prateleira === posicao?.id_prateleira
    )
    const estante = estantes?.find(
      (est) => est.id_estante === prateleira?.id_estante
    )
    const corredor = corredores?.find(
      (cor) => cor.id_corredor === estante?.id_corredor
    )
    const centro = centrosDocumentacaoFiltrado?.find(
      (ced) => ced.id_centrodocumentacao === corredor?.id_centrodocumentacao
    )

    return (
      (centro?.prefixo ?? '') +
      '.' +
      (corredor?.identificacao ?? '') +
      '.' +
      (estante?.identificacao ?? '') +
      '.' +
      (prateleira?.identificacao ?? '') +
      '.' +
      (posicao?.identificacao ?? '')
    )
  }

  const handleAddBoxClick = () => {
    const newBox: LocalIPosicaoCaixa = {
      caixa: currentBox?.id_caixa ?? '',
      caixaNaoAtendida: currentBox,
      posicao: idPosicaoPrateleira,
      identificadorAlocacao: getLocationDescriptor(idPosicaoPrateleira),
      cd: idCentro
    }

    const newUnallocated = selectedBoxesUnallocated.filter(
      (box) => box.id_caixa !== currentBox.id_caixa
    )
    setSelectedBoxesUnallocated(newUnallocated)
    const newListBox = [...posicoesCaixas, newBox]
    setPosicoesCaixas(newListBox)
    setCurrentBox(blankBox)
    setIdPosicaoPrateleira('')
    setCanGoNext(newListBox.length === selectedBoxes.length)
  }

  const handleRemoveBoxClick = (box: LocalIPosicaoCaixa) => {
    const newListBox = posicoesCaixas.filter(
      (oldBox) => oldBox.caixa !== box.caixa
    )
    setPosicoesCaixas(newListBox)
    const newUnallocated = [...selectedBoxesUnallocated, box.caixaNaoAtendida]
    setSelectedBoxesUnallocated(newUnallocated)
    setCanGoNext(false)
  }

  const handleCentroChange = (id: string) => {
    setIdCorredor('')
    setIdEstante('')
    setIdPrateleira('')
    setIdPosicaoPrateleira('')
    setIdCentro(id)
  }

  const handleCorredorChange = (id: string) => {
    setIdEstante('')
    setIdPrateleira('')
    setIdPosicaoPrateleira('')
    setIdCorredor(id)
  }
  const handleEstanteChange = (id: string) => {
    setIdPrateleira('')
    setIdPosicaoPrateleira('')
    setIdEstante(id)
  }

  const handlePrateleiraChange = (id: string) => {
    setIdPosicaoPrateleira('')
    setIdPrateleira(id)
  }

  const handlePosicaoChange = (id: string) => {
    setIdPosicaoPrateleira(id)
  }

  // Alocação de Caixas - MANUAL (FIM)

  // Alocação de Caixas - Automáticas com posição inicial (INÍCIO)
  const handlePosicaoPrateleiraChange = (id: string) => {
    setIdPosicaoPrateleira(id)
    const boxes: LocalIPosicaoCaixa[] = selectedBoxes.map((box) => {
      return {
        caixaNaoAtendida: box,
        caixa: box.id_caixa ?? '',
        posicao: id,
        identificadorAlocacao: getLocationDescriptor(id),
        cd: idCentro
      }
    })
    setPosicoesCaixas(boxes)
    setCanGoNext(true)
  }
  // Alocação de Caixas - Automáticas com posição inicial (FIM)

  // Alocação de Caixas - Automáticas (INÍCIO)
  const getCDDescriptor = (id: string): string => {
    const centro = centrosDocumentacaoFiltrado?.find(
      (ced) => ced.id_centrodocumentacao === id
    )

    return centro?.nome ?? ''
  }

  const handleCDChange = (id: string) => {
    setIdCentro(id)
    const boxes: LocalIPosicaoCaixa[] = selectedBoxes.map((box) => {
      return {
        caixaNaoAtendida: box,
        caixa: box.id_caixa ?? '',
        posicao: '',
        identificadorAlocacao: '',
        cd: id
      }
    })
    setPosicoesCaixas(boxes)
    setCanGoNext(true)
  }
  // Alocação de Caixas - Automáticas (FIM)

  const handleSubmitClick = async () => {
    setIsSubmiting(true)
    const formData: IAlocacao = {
      tipo: tipoAlocacao,
      posicoesCaixas: posicoesCaixas.map((pos) => ({
        caixa: pos.caixa,
        posicao: pos.posicao,
        cd: pos.cd
      }))
    }
    await toastDef.promise(
      allocateBoxes(formData),
      {
        success: {
          render({ data }) {
            setIsSubmiting(false)
            handleCancelClick()
            return (
              <>
                <h4>Alocação de Caixas</h4>
                <TableContainer>
                  <Table>
                    <TableBody>
                      {(data ?? []).map((item) => (
                        <TableRow key={`alocacao-${item.caixa.id_caixa}`}>
                          <TableCell>
                            {item.caixa.identificacao}(
                            {item.caixa.tipoCaixa.descricao})
                          </TableCell>
                          <TableCell>{item.mensagem}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )
          }
        },
        error: {
          render({ data }) {
            setIsSubmiting(false)
            return <>{data.message}</>
          }
        }
      },
      {
        style: {
          minWidth: '450px'
        },
        autoClose: false,
        closeButton: true,
        position: 'top-center',
        closeOnClick: false,
        rtl: true
      }
    )
  }

  // Handles (FIM) -------------------------------------------------------------

  if (
    isLoadingCentro ||
    isLoadingCorredores ||
    isLoadingEstantes ||
    isLoadingPrateleiras ||
    isLoadingPosicoesPrateleiras
  )
    <Loading />
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '6px',
        background: '#FFFFFF',
        padding: '16px',
        gap: 2
      }}
    >
      {/* Cabeçalho 00000000000000000000000000000000000000000000000000000000 */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end'
        }}
      >
        <Stack
          gap={2}
          flexDirection={'row'}
          justifyContent={'space-between'}
          display={'flex'}
        >
          {currentStep === 0 && (
            <>
              <VisaoCaixas
                isVisaoCaixas={isVisaoCaixas}
                setIsVisaoCaixas={setIsVisaoCaixas}
              />
            </>
          )}
          <Button
            variant="outlined"
            disabled={!(currentStep > 0)}
            startIcon={<BackIcon />}
            sx={{ fontWeight: 600 }}
            onClick={handlePreviousStepClick}
          >
            Voltar
          </Button>

          <Button
            variant={currentStep === 5 ? 'contained' : 'outlined'}
            endIcon={
              currentStep === 5 ? (
                isSubmiting ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <SaveIcon sx={{ width: 24, height: 24 }} />
                )
              ) : (
                <ForwardIcon />
              )
            }
            disabled={!canGoNext || isSubmiting}
            sx={{ fontWeight: 600 }}
            onClick={() =>
              currentStep === 5 ? handleSubmitClick() : handleNextStepClick()
            }
          >
            {currentStep === 5
              ? isSubmiting
                ? 'Aguarde..'
                : 'Confirmar'
              : 'Avançar'}
          </Button>
          <Button
            sx={{
              marginLeft: '16px',
              fontWeight: 600
            }}
            color="error"
            variant="outlined"
            startIcon={<Cancel />}
            onClick={handleCancelClick}
          >
            Cancelar
          </Button>
        </Stack>
      </Box>
      <Timeline step={currentStep} steps={ImportSteps} />
      <Divider
        sx={{
          marginBottom: 2,
          marginTop: 2
        }}
      />
      <>
        {/* Passo 1 - 1111111111111111111111111111111111111111111111111111111 */}
        {currentStep === 0 && isVisaoCaixas && (
          <>
            <Stack direction="row" flexWrap="wrap">
              {caixasOrdenadas?.map((cx) => (
                <Box
                  key={cx.id_caixa}
                  m={1}
                  py={1}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  minWidth={160}
                  minHeight={160}
                  bgcolor={selectedBoxes.includes(cx) ? '#eef4f7' : undefined}
                  border={
                    selectedBoxes.includes(cx) ? '1px solid #c5d9e3' : undefined
                  }
                  onClick={() => handleAddBox(cx)}
                  sx={{ cursor: 'pointer', borderRadius: 2 }}
                >
                  <img src={boxImg} alt="Caixas" />
                  <Typography fontWeight={700}>{cx.identificacao}</Typography>
                  <Typography variant="body2">
                    {cx.tipoCaixa?.descricao} (
                    {cx.tipoCaixa?.tamanho.toFixed(0)}
                    U)
                  </Typography>
                  <Typography fontWeight={700} variant="body2">
                    {selectedBoxes.indexOf(cx) + 1 > 0 &&
                      `Ordem de seleção: ${selectedBoxes.indexOf(cx) + 1}`}
                  </Typography>
                </Box>
              ))}
            </Stack>

            {selectedBoxes.length === 0 && (
              <Typography color="red" mb={3}>
                Selecione uma caixa
              </Typography>
            )}

            {(listaCaixaNaoAlocadas?.length ?? 0) < 1 && (
              <Typography color="red" my={3}>
                Não existe nenhuma caixa para ser alocada!
              </Typography>
            )}
          </>
        )}

        {currentStep === 0 && !isVisaoCaixas && (
          <Stack gap={1} direction={'row'} alignItems="flex-end">
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              placeholder={'Código da Requisição'}
              autoFocus
              onChange={(event) => {
                const newValue = event.target.value
                debouncedBuscaOnChange(newValue)
              }}
            />{' '}
            <Button
              variant="contained"
              sx={{ paddingTop: 1 }}
              onClick={handleFiltrarCaixas}
            >
              <SearchIcon />
              Pesquisar
            </Button>
          </Stack>
        )}

        {currentStep === 0 &&
          !isVisaoCaixas &&
          listaCaixaNaoAlocadasfiltradas.length !== 0 && (
            <DashboardWidget.Root
              sx={{
                borderRadius: '20px',
                boxShadow:
                  '0px 8px 16px -4px rgba(24, 39, 75, 0.08), 0px 6px 0px -6px rgba(0, 0, 0, 0.25)',
                backgroundColor: '#fff',
                margin: '0rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '1rem',
                maxHeight: '840px',
                maxWidth: '100%'
              }}
            >
              <DashboardWidgetListBody.Root>
                <DashboardWidgetListBody.Table
                  // title={tipoAtividade.descricao}
                  columnsDefs={colunasLista}
                >
                  {listaCaixaNaoAlocadasfiltradas?.map((cx, idx) => {
                    const values = [
                      <FormControlLabel
                        control={
                          <Checkbox
                            onClick={() => {
                              handleAddBox(cx)
                            }}
                            checked={
                              marcarTodos ||
                              selectedBoxes.some(
                                (item) => item.id_caixa === cx.id_caixa
                              )
                            }
                            sx={{
                              paddingLeft: 2
                            }}
                          />
                        }
                        label=""
                      />,
                      getCodigo(cx.item_requisicao.id_requisicao ?? ''),
                      cx.identificacao,
                      `${
                        cx.tipoCaixa?.descricao
                      } (${cx.tipoCaixa?.tamanho.toFixed(0)}U)`
                    ]
                    return (
                      <DashboardWidgetListBody.Row
                        sx={{
                          backgroundColor: idx % 2 === 0 ? corFundoLinha : ''
                        }}
                        cellStyle={{
                          fontStyle: 'normal',
                          fontWeight: 'bold',
                          fontSize: '0.75rem',
                          color: corTextoLinha,
                          paddingBottom: 0,
                          paddingTop: 0,
                          paddingLeft: 1,
                          borderBottom: 0,
                          height: '40px'
                        }}
                        values={values}
                        key={cx.id_caixa}
                      >
                        <></>
                      </DashboardWidgetListBody.Row>
                    )
                  })}
                </DashboardWidgetListBody.Table>
              </DashboardWidgetListBody.Root>
            </DashboardWidget.Root>
          )}
        {(listaCaixaNaoAlocadasfiltradas?.length ?? 0) < 1 && (
          <Typography color="red" my={3}>
            Não existe nenhuma caixa para ser alocada!
          </Typography>
        )}
      </>
      {/* Passo 2 - 22222222222222222222222222222222222222222222222222222222 */}
      {currentStep === 1 && (
        <Stack>
          <FormControl>
            <FormLabel id="lbl-tipoAlocacao">Tipo de Alocação</FormLabel>
            <RadioGroup
              aria-labelledby="lbl-tipoAlocacao"
              name="tipo-alocacao"
              value={tipoAlocacao}
              onChange={handleTipoAlocacaoChange}
            >
              <FormControlLabel value="0" control={<Radio />} label="Manual" />
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="Automática"
              />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label="Automática à partir de endereço"
              />
              {/* <FormControlLabel
                value="3"
                control={<Radio />}
                label="Contínua"
              />
              <FormControlLabel
                value="4"
                control={<Radio />}
                label="Contínua à partir de endereço"
              /> */}
            </RadioGroup>
          </FormControl>
        </Stack>
      )}
      {/* Passo 3.1 - 313131313131313131313131313131313131313131313131313131 */}
      {currentStep === 2 && (
        <Stack gap={4}>
          <Typography sx={{ fontSize: '16px' }}>Alocação Manual</Typography>
          <ComponentGroup title={'Caixas para Definir Alocação'}>
            <Stack gap={2} direction={'row'}>
              <TableContainer sx={{ maxHeight: '400px' }}>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography sx={{ fontWeight: 'bold', color: 'gray' }}>
                          Caixa
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={{ fontWeight: 'bold', color: 'gray' }}>
                          Descrição
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedBoxesUnallocated.map((box) => (
                      <TableRow
                        key={`unallocated-${box.id_caixa}`}
                        hover
                        sx={{
                          backgroundColor:
                            currentBox?.id_caixa === box.id_caixa
                              ? 'lightgray'
                              : '',
                          cursor: 'default'
                        }}
                        onClick={() => handleBoxClick(box)}
                      >
                        <TableCell>{box.identificacao}</TableCell>
                        <TableCell>{`${
                          box.tipoCaixa?.descricao
                        } (${box.tipoCaixa?.tamanho.toFixed(0)}U)`}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <ComponentGroup title={'Localização'} width={'300px'}>
                <Stack gap={2} pt={2}>
                  <FormControl fullWidth>
                    <InputLabel id="centro-label">
                      Centro de Documentação*
                    </InputLabel>
                    <SelectMaterial
                      labelId="centro-label"
                      id="centro-select"
                      value={idCentro}
                      label="Centro de Documentação*"
                      onChange={(event) =>
                        handleCentroChange(String(event.target.value))
                      }
                    >
                      {!!centrosDocumentacao &&
                        centrosDocumentacao.length > 0 &&
                        centrosDocumentacao.map((centro) => (
                          <MenuItem
                            key={centro.id_centrodocumentacao}
                            value={centro.id_centrodocumentacao}
                          >
                            {centro.nome} ({centro.prefixo})
                          </MenuItem>
                        ))}
                    </SelectMaterial>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="corredor-label">Corredor*</InputLabel>
                    <SelectMaterial
                      labelId="corredor-label"
                      id="corredor-select"
                      value={idCorredor}
                      label="Corredor*"
                      onChange={(event) =>
                        handleCorredorChange(String(event.target.value))
                      }
                    >
                      {!!corredores &&
                        corredores.length > 0 &&
                        corredores.map((corredor) => (
                          <MenuItem
                            key={corredor.id_corredor}
                            value={corredor.id_corredor}
                          >
                            {corredor.identificacao}
                          </MenuItem>
                        ))}
                    </SelectMaterial>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="estante-label">Estante*</InputLabel>
                    <SelectMaterial
                      labelId="estante-label"
                      id="estante-select"
                      value={idEstante}
                      label="Estante*"
                      onChange={(event) =>
                        handleEstanteChange(String(event.target.value))
                      }
                    >
                      {!!estantes &&
                        estantes.length > 0 &&
                        estantes.map((estante) => (
                          <MenuItem
                            key={estante.id_estante}
                            value={estante.id_estante}
                          >
                            {estante.identificacao}
                          </MenuItem>
                        ))}
                    </SelectMaterial>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="prateleira-label">Prateleira*</InputLabel>
                    <SelectMaterial
                      labelId="prateleira-label"
                      id="prateleira-select"
                      value={idPrateleira}
                      label="Prateleira*"
                      onChange={(event) =>
                        handlePrateleiraChange(String(event.target.value))
                      }
                    >
                      {!!prateleiras &&
                        prateleiras.length > 0 &&
                        prateleiras.map((prateleira) => (
                          <MenuItem
                            key={prateleira.id_prateleira}
                            value={prateleira.id_prateleira}
                          >
                            {prateleira.identificacao} (
                            {prateleira.tipoCaixa.descricao})
                          </MenuItem>
                        ))}
                    </SelectMaterial>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="posicao-prateleira-label">
                      Posição de Prateleira*
                    </InputLabel>
                    <SelectMaterial
                      labelId="posicao-prateleira-label"
                      id="posicao-prateleira-select"
                      value={idPosicaoPrateleira}
                      label="Posição de Prateleira*"
                      onChange={(event) =>
                        handlePosicaoChange(String(event.target.value))
                      }
                    >
                      {!!posicoesPrateleiras &&
                        posicoesPrateleiras.length > 0 &&
                        posicoesPrateleiras.map((posicaoPrateleira) => (
                          <MenuItem
                            key={posicaoPrateleira.id_posicao_prateleira}
                            value={posicaoPrateleira.id_posicao_prateleira}
                          >
                            {posicaoPrateleira.identificacao}
                          </MenuItem>
                        ))}
                    </SelectMaterial>
                  </FormControl>
                  <Button
                    variant="outlined"
                    onClick={() => handleAddBoxClick()}
                    disabled={
                      idPosicaoPrateleira === '' || !currentBox.id_caixa
                    }
                  >
                    Adicionar
                  </Button>
                </Stack>
              </ComponentGroup>
            </Stack>
          </ComponentGroup>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography sx={{ fontWeight: 'bold', color: 'gray' }}>
                      Caixa
                    </Typography>
                  </TableCell>
                  <TableCell width={'20%'}>
                    <Typography sx={{ fontWeight: 'bold', color: 'gray' }}>
                      Posição
                    </Typography>
                  </TableCell>
                  <TableCell width={'30px'}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {posicoesCaixas.map((posicao) => (
                  <TableRow key={`${posicao.caixa}-${posicao.posicao}`}>
                    <TableCell>
                      {posicao.caixaNaoAtendida.identificacao}
                    </TableCell>
                    <TableCell>{posicao.identificadorAlocacao}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleRemoveBoxClick(posicao)}
                      >
                        <DeleteForever />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      )}
      {/* Passo 3.2 - 323232323232323232323232323232323232323232323232323232 */}
      {currentStep === 3 && (
        <Stack gap={4}>
          <Typography sx={{ fontSize: '16px' }}>
            Alocação{' '}
            {`${
              tipoAlocacao === 2
                ? 'Automática à partir de localização'
                : 'Contínua à partir de localização'
            }`}
          </Typography>
          <ComponentGroup title={'Localização Inicial'}>
            <Stack gap={2} pt={2}>
              <FormControl fullWidth>
                <InputLabel id="centro-label">
                  Centro de Documentação*
                </InputLabel>
                <SelectMaterial
                  labelId="centro-label"
                  id="centro-select"
                  value={idCentro}
                  label="Centro de Documentação*"
                  onChange={(event) =>
                    handleCentroChange(String(event.target.value))
                  }
                >
                  {!!centrosDocumentacao &&
                    centrosDocumentacao.length > 0 &&
                    centrosDocumentacao.map((centro) => (
                      <MenuItem
                        key={centro.id_centrodocumentacao}
                        value={centro.id_centrodocumentacao}
                      >
                        {centro.nome} ({centro.prefixo})
                      </MenuItem>
                    ))}
                </SelectMaterial>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="corredor-label">Corredor*</InputLabel>
                <SelectMaterial
                  labelId="corredor-label"
                  id="corredor-select"
                  value={idCorredor}
                  label="Corredor*"
                  onChange={(event) =>
                    handleCorredorChange(String(event.target.value))
                  }
                >
                  {!!corredores &&
                    corredores.length > 0 &&
                    corredores.map((corredor) => (
                      <MenuItem
                        key={corredor.id_corredor}
                        value={corredor.id_corredor}
                      >
                        {corredor.identificacao}
                      </MenuItem>
                    ))}
                </SelectMaterial>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="estante-label">Estante*</InputLabel>
                <SelectMaterial
                  labelId="estante-label"
                  id="estante-select"
                  value={idEstante}
                  label="Estante*"
                  onChange={(event) =>
                    handleEstanteChange(String(event.target.value))
                  }
                >
                  {!!estantes &&
                    estantes.length > 0 &&
                    estantes.map((estante) => (
                      <MenuItem
                        key={estante.id_estante}
                        value={estante.id_estante}
                      >
                        {estante.identificacao}
                      </MenuItem>
                    ))}
                </SelectMaterial>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="prateleira-label">Prateleira*</InputLabel>
                <SelectMaterial
                  labelId="prateleira-label"
                  id="prateleira-select"
                  value={idPrateleira}
                  label="Prateleira*"
                  onChange={(event) =>
                    handlePrateleiraChange(String(event.target.value))
                  }
                >
                  {!!prateleiras &&
                    prateleiras.length > 0 &&
                    prateleiras.map((prateleira) => (
                      <MenuItem
                        key={prateleira.id_prateleira}
                        value={prateleira.id_prateleira}
                      >
                        {prateleira.identificacao} (
                        {prateleira.tipoCaixa.descricao})
                      </MenuItem>
                    ))}
                </SelectMaterial>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="posicao-prateleira-label">
                  Posição de Prateleira*
                </InputLabel>
                <SelectMaterial
                  labelId="posicao-prateleira-label"
                  id="posicao-prateleira-select"
                  value={idPosicaoPrateleira}
                  label="Posição de Prateleira*"
                  onChange={(event) =>
                    handlePosicaoPrateleiraChange(String(event.target.value))
                  }
                >
                  {!!listaPosicoesPrateleira &&
                    listaPosicoesPrateleira.length > 0 &&
                    listaPosicoesPrateleira.map((posicaoPrateleira) => (
                      <MenuItem
                        key={posicaoPrateleira.id_posicao_prateleira}
                        value={posicaoPrateleira.id_posicao_prateleira}
                      >
                        {posicaoPrateleira.identificacao}
                      </MenuItem>
                    ))}
                </SelectMaterial>
              </FormControl>
            </Stack>
          </ComponentGroup>
        </Stack>
      )}
      {/* Passo 3.3 - 333333333333333333333333333333333333333333333333333333 */}
      {currentStep === 4 && (
        <Stack gap={4}>
          <Typography sx={{ fontSize: '16px' }}>
            Alocação {`${tipoAlocacao === 3 ? 'Automática' : 'Contínua'}`}
          </Typography>
          <ComponentGroup title={'Centro de Distribuição'}>
            <Stack gap={2} pt={2}>
              <Select
                label="Centro de Documentação*"
                placeholder="Selecione o Centro de Documentação"
                data={centrosDocumentacaoFiltrado}
                fields={['id_centrodocumentacao', 'prefixo']}
                value={idCentro === '0' ? '' : idCentro}
                onChange={(event) => handleCDChange(String(event.target.value))}
              />
            </Stack>
          </ComponentGroup>
        </Stack>
      )}
      {/* Passo 4 - 44444444444444444444444444444444444444444444444444444444 */}
      {currentStep === 5 && (
        <Stack gap={2}>
          {/* Alocação Manual ---------------------------------------------- */}
          {tipoAlocacao === 0 && (
            <>
              <Typography sx={{ fontSize: '16px' }}>Alocação Manual</Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography sx={{ fontWeight: 'bold', color: 'gray' }}>
                          Caixa
                        </Typography>
                      </TableCell>
                      <TableCell width={'20%'}>
                        <Typography sx={{ fontWeight: 'bold', color: 'gray' }}>
                          Posição
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {posicoesCaixas.map((posicao) => (
                      <TableRow key={`${posicao.caixa}-${posicao.posicao}`}>
                        <TableCell>
                          {posicao.caixaNaoAtendida.identificacao}
                        </TableCell>
                        <TableCell>{posicao.identificadorAlocacao}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
          {/* Alocação Automatizada ---------------------------------------- */}
          {(tipoAlocacao === 2 || tipoAlocacao === 4) && (
            <>
              <Typography sx={{ fontSize: '16px' }}>
                Alocação{' '}
                {`${
                  tipoAlocacao === 2
                    ? 'Automática à partir de localização'
                    : 'Contínua à partir de localização'
                }`}
              </Typography>
              <Typography sx={{ fontSize: '14px' }}>
                Local de Início de Alocação:{' '}
                {posicoesCaixas[0].identificadorAlocacao}
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography sx={{ fontWeight: 'bold', color: 'gray' }}>
                          Caixa
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {posicoesCaixas.map((posicao) => (
                      <TableRow key={`${posicao.caixa}-${posicao.posicao}`}>
                        <TableCell>
                          {posicao.caixaNaoAtendida.identificacao}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
          {/* Alocação Automática ---------------------------------------- */}
          {(tipoAlocacao === 1 || tipoAlocacao === 3) && (
            <>
              <Typography sx={{ fontSize: '16px' }}>
                Alocação {`${tipoAlocacao === 1 ? 'Automática' : 'Contínua'}`}
              </Typography>
              <Typography sx={{ fontSize: '14px' }}>
                Centro de Documentação: {getCDDescriptor(posicoesCaixas[0].cd)}
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography sx={{ fontWeight: 'bold', color: 'gray' }}>
                          Caixa
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {posicoesCaixas.map((posicao) => (
                      <TableRow key={`${posicao.caixa}-${posicao.posicao}`}>
                        <TableCell>
                          {posicao.caixaNaoAtendida.identificacao}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Stack>
      )}
    </Box>
  )
}
