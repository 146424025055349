import { Box, Button, Stack } from '@mui/material'
import SaveIcon from '@mui/icons-material/CheckCircleOutlined'
import CancelIcon from '@mui/icons-material/DoDisturbAltOutlined'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import msg from '../../../utils/validationMessages'
import useToast from '../../../hooks/toast/useToast'
import { FormMode } from '../../../types/formMode'
import { Input } from '../../../components/Form/Input'
import { useFormaPagamento } from '../../../hooks/financeiro/formaPagamento/useFormaPagamento'
import { IFormaPagamento } from '../../../services/financeiro/formaPagamento/types'

const schema = yup
  .object({
    descricao: yup.string().required(msg.REQUIRED).min(3, msg.MIN(3))
  })
  .required()

interface IForm {
  data: any
  formMode: FormMode
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

export function Form({ data, formMode, setFormMode }: IForm) {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IFormaPagamento>({
    resolver: yupResolver(schema)
  })

  const { Toast } = useToast()
  const { mutateAsync: create } = useFormaPagamento.Create()
  const { mutateAsync: update } = useFormaPagamento.Update()

  const onSubmit = async (formData: IFormaPagamento) => {
    formMode === 'CREATE'
      ? await Toast(create(formData))
      : await Toast(
          update({
            id_forma_pagamento: data.id_forma_pagamento,
            ...formData
          }),
          'UPDATED'
        )

    setFormMode('LIST')
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} mt={4}>
      <Stack gap={2}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2
          }}
        >
          <Input
            label="Descrição*"
            {...register('descricao')}
            defaultValue={data?.descricao}
            message={errors.descricao?.message}
          />
        </Box>
      </Stack>

      <Stack direction="row" gap={2} mt={3}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<CancelIcon />}
          onClick={() => setFormMode('LIST')}
        >
          Cancelar
        </Button>

        <Button
          type="submit"
          variant="contained"
          startIcon={<SaveIcon />}
          sx={{ width: '110px' }}
        >
          Salvar
        </Button>
      </Stack>
    </Box>
  )
}
