import { ManageSearch } from '@mui/icons-material'
import { useMemo, useState } from 'react'
import {
  Box,
  Button,
  Chip,
  Divider,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/AddCircle'
import EditIcon from '@mui/icons-material/Edit'
import ClearIcon from '@mui/icons-material/Clear'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import useToast from '../../../hooks/toast/useToast'
import { FormMode } from '../../../types/formMode'
import { Loading } from '../../../components/Loading'
import React from 'react'
import { useLancamento } from '../../../hooks/financeiro/lancamento/useLancamento'
import { formataSomenteData } from '../../../utils/formataData'
import {
  formatarComoMoeda,
  primeiraMaiuscula
} from '../../../utils/formataStrings'
import { Dialog } from '../../Dialog'
import { LancamentoBaixaForm } from './LancamentoBaixaForm'
import { Permissions } from '../../../types/permissions'
import { useUsuario } from '../../../hooks/usuario/useUsuario'
import { ILancamento } from '../../../services/financeiro/lancamento/types'

interface IForm {
  tipo_lancamento: string
  setData: (value: React.SetStateAction<ILancamento>) => void
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

export function LancamentoList({
  tipo_lancamento,
  setData,
  setFormMode
}: IForm) {
  const [showDismiss, setShowDismiss] = useState(false)
  const [showDismissEstorno, setShowDismissEstorno] = useState(false)
  const [currentLancamento, setCurrentLancamento] = useState<string>('')
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [q, setQ] = useState<string>('')
  const [selectedOrder, setSelectedOrder] = useState<string>('Vencimento')
  const { Toast } = useToast()
  const searchInput = React.useRef<HTMLInputElement>(null)

  const { data: usuario } = useUsuario.FindByUsername()

  const [openModalBaixa, setOpenModalBaixa] = useState(false)
  const handleCloseModalBaixa = () => setOpenModalBaixa(false)

  const handleSearchClick = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setSearchParams({ ...searchParams, q: searchTerm })
    setQ(searchTerm)
    searchInput.current?.focus()
  }

  const page = useMemo(() => {
    return Number(searchParams.get('page') ?? 1)
  }, [searchParams])

  const isContasAReceber = tipo_lancamento === 'R'
  const { mutateAsync: cancel } = useLancamento.Cancel()
  const { mutateAsync: reversePayment } = useLancamento.ReversePayment()
  const { data: records, isLoading } = isContasAReceber
    ? useLancamento.FindAllReceber(page, selectedOrder, q)
    : useLancamento.FindAllPagar(page, selectedOrder, q)

  const handleNewForm = () => {
    setFormMode('CREATE')
  }

  const handleCancel = async () => {
    setShowDismiss(false)
    await Toast(cancel(currentLancamento), 'REMOVED')
  }

  const handleEditForm = (selectedData: any) => {
    setData(selectedData)
    setFormMode('EDIT')
  }

  const handleViewForm = (selectedData: any) => {
    setData(selectedData)
    setFormMode('VIEW')
  }

  const handleReversePayment = async () => {
    setShowDismissEstorno(false)
    await Toast(reversePayment(currentLancamento), 'REMOVED')
  }

  const hasAccess = (permission: number) =>
    usuario?.permissoes.includes(permission) ?? false

  const canUserCancel = hasAccess(
    isContasAReceber
      ? Permissions.FAT_CANCELAR_CONTAS_A_RECEBER
      : Permissions.FAT_CANCELAR_CONTAS_A_PAGAR
  )

  const canUserPay = hasAccess(
    isContasAReceber
      ? Permissions.FAT_BAIXA_CONTAS_A_RECEBER
      : Permissions.FAT_BAIXA_CONTAS_A_PAGAR
  )

  const canUserReversePayment = hasAccess(
    isContasAReceber
      ? Permissions.FAT_ESTORNAR_BAIXA_CONTAS_A_RECEBER
      : Permissions.FAT_ESTORNAR_BAIXA_CONTAS_A_PAGAR
  )

  const statusColor = (status: string) => {
    //record.status === 'PENDENTE' ? 'warning' : 'success'
    switch (status) {
      case 'PENDENTE':
        return 'warning'
      case 'PAGO':
        return 'success'
      case 'PARCIAL':
        return 'info'
      default:
        break
    }
  }

  const hasBaixa = (status: string) => {
    return status === 'PAGO' || status === 'PARCIAL'
  }

  if (isLoading) return <Loading />

  return (
    <Box>
      <Stack
        component="form"
        onSubmit={handleSearchClick}
        direction="row"
        py={3}
        gap={3}
        mb={2}
      >
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          placeholder={`Parceiro/Número do documento/Forma de Pagamento/Centro de Custo/${
            tipo_lancamento === 'R' ? 'Receita' : 'Despesa'
          }`}
          inputRef={searchInput}
          autoFocus
          value={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value)
          }}
        />
        <Button variant="outlined" startIcon={<SearchIcon />} type="submit">
          Pesquisar
        </Button>
      </Stack>

      <Divider />

      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleNewForm}
        sx={{ float: 'right', my: '12px' }}
      >
        Novo registro
      </Button>

      <TableContainer
        component={Box}
        border={1}
        borderRadius={2}
        borderColor="#f2f2f2"
      >
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Número documento
              </TableCell>
              <TableCell
                sx={{ fontWeight: 'bold', fontSize: '14px', cursor: 'pointer' }}
                onClick={() => {
                  selectedOrder === 'Parceiro'
                    ? setSelectedOrder('ParceiroInv')
                    : setSelectedOrder('Parceiro')
                }}
              >
                {isContasAReceber ? 'Cliente' : 'Fornecedor'}
                {selectedOrder === 'Parceiro' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>
              <TableCell
                sx={{ fontWeight: 'bold', fontSize: '14px', cursor: 'pointer' }}
                onClick={() => {
                  selectedOrder === 'Vencimento'
                    ? setSelectedOrder('VencimentoInv')
                    : setSelectedOrder('Vencimento')
                }}
              >
                Vencimento
                {selectedOrder === 'Vencimento' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>
              <TableCell
                sx={{ fontWeight: 'bold', fontSize: '14px', cursor: 'pointer' }}
                onClick={() => {
                  selectedOrder === 'ReceitaDespesa'
                    ? setSelectedOrder('ReceitaDespesaInv')
                    : setSelectedOrder('ReceitaDespesa')
                }}
              >
                {tipo_lancamento === 'R' ? 'Receita' : 'Despesa'}
                {selectedOrder === 'ReceitaDespesa' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>
              <TableCell
                sx={{ fontWeight: 'bold', fontSize: '14px', cursor: 'pointer' }}
                onClick={() => {
                  selectedOrder === 'Status'
                    ? setSelectedOrder('StatusInv')
                    : setSelectedOrder('Status')
                }}
              >
                Status
                {selectedOrder === 'Status' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>
              <TableCell
                sx={{ fontWeight: 'bold', fontSize: '14px', cursor: 'pointer' }}
                onClick={() => {
                  selectedOrder === 'Valor'
                    ? setSelectedOrder('ValorInv')
                    : setSelectedOrder('Valor')
                }}
              >
                Valor
                {selectedOrder === 'Valor' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Baixas
              </TableCell>
              <TableCell width="12px" />
              <TableCell width="12px" />
              <TableCell width="12px" />
              <TableCell width="12px" />
            </TableRow>
          </TableHead>
          <TableBody>
            {records?.data?.map((record) => (
              <TableRow key={record.id_lancamento} hover>
                <TableCell>{record.num_documento}</TableCell>
                <TableCell>{record.parceiro?.nome}</TableCell>
                <TableCell>
                  {formataSomenteData(record.dt_vencimento?.toString() ?? '')}
                </TableCell>
                <TableCell>{record.receitaDespesa?.descricao ?? ''}</TableCell>
                <TableCell>
                  <Chip
                    label={primeiraMaiuscula(record.status)}
                    color={statusColor(record.status)}
                    size="small"
                    variant="filled"
                  />
                </TableCell>
                <TableCell>{formatarComoMoeda(record.valor)}</TableCell>
                <TableCell>{formatarComoMoeda(record.valor_pago)}</TableCell>
                <TableCell>
                  {!hasBaixa(record.status) && (
                    <Tooltip title="Editar" arrow>
                      <Button
                        variant="outlined"
                        sx={{ minWidth: '24px', padding: '6px' }}
                        onClick={() => handleEditForm(record)}
                      >
                        <EditIcon />
                      </Button>
                    </Tooltip>
                  )}
                  {hasBaixa(record.status) && (
                    <Tooltip title="Visualizar" arrow>
                      <Button
                        variant="outlined"
                        sx={{ minWidth: '24px', padding: '6px' }}
                        onClick={() => handleViewForm(record)}
                      >
                        <ManageSearch />
                      </Button>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell>
                  {canUserCancel && record.status === 'PENDENTE' && (
                    <Tooltip title="Cancelar" arrow>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                          setCurrentLancamento(record.id_lancamento!)
                          setShowDismiss(true)
                        }}
                        sx={{ minWidth: '24px', padding: '6px' }}
                      >
                        <ClearIcon />
                      </Button>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell>
                  {canUserPay && record.status !== 'PAGO' && (
                    <Tooltip title="Baixar" arrow>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          setCurrentLancamento(record.id_lancamento!)
                          setOpenModalBaixa(true)
                        }}
                        sx={{ minWidth: '24px', padding: '6px' }}
                      >
                        <AttachMoneyIcon />
                      </Button>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell>
                  {canUserReversePayment && hasBaixa(record.status) && (
                    <Tooltip title="Estornar Baixa" arrow>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                          setCurrentLancamento(record.id_lancamento!)
                          setShowDismissEstorno(true)
                        }}
                        sx={{ minWidth: '24px', padding: '6px' }}
                      >
                        <CurrencyExchangeIcon />
                      </Button>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {records?.pagination &&
        records.pagination.totalItems > records.pagination.itemsPerPage && (
          <Pagination
            count={records?.pagination.totalPages}
            sx={{ marginTop: '12px' }}
            onChange={(_, newPage) =>
              setSearchParams({ page: String(newPage) })
            }
            page={records.pagination.currentPage}
          />
        )}

      <Dialog
        handleAnswerNo={() => {
          setShowDismiss(false)
        }}
        handleAnswerYes={() => {
          handleCancel()
        }}
        messageText={
          <>
            <p>
              Tem certeza que deseja <strong>CANCELAR</strong> este Contas à
              {` ${isContasAReceber ? 'Receber' : 'Pagar'}`}?
            </p>
            <p>
              Em caso afirmativo, o mesmo ficará Cancelado e indisponível para
              movimentação
            </p>
            <p>
              <strong>IMPORTANTE: </strong>Esta ação não poderá ser desfeita.
            </p>
          </>
        }
        title="Atenção"
        openDialog={showDismiss}
      />

      <Dialog
        handleAnswerNo={() => {
          setShowDismissEstorno(false)
        }}
        handleAnswerYes={() => {
          handleReversePayment()
        }}
        messageText={
          <>
            <p>
              Tem certeza que deseja <strong>ESTORNAR</strong> a baixa deste
              Contas à{` ${isContasAReceber ? 'Receber' : 'Pagar'}`}?
            </p>
            <p>Em caso afirmativo, a última baixa será estornada</p>
            <p>
              <strong>IMPORTANTE: </strong>Esta ação não poderá ser desfeita.
            </p>
          </>
        }
        title="Atenção"
        openDialog={showDismissEstorno}
      />

      <LancamentoBaixaForm
        id_lancamento={currentLancamento}
        open={openModalBaixa}
        handleClose={handleCloseModalBaixa}
      />
    </Box>
  )
}
