import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack
} from '@mui/material'
import SaveIcon from '@mui/icons-material/CheckCircleOutlined'
import CancelIcon from '@mui/icons-material/DoDisturbAltOutlined'
import { IServico } from '../../../services/servico/types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import useToast from '../../../hooks/toast/useToast'
import { useServico } from '../../../hooks/servico/useServico'
import { Input } from '../../../components/Form/Input'
import msg from '../../../utils/validationMessages'
import { FormMode } from '../../../types/formMode'
import { useState } from 'react'

const schema = yup
  .object({
    nome: yup.string().required(msg.REQUIRED).min(4, msg.MIN(4)),
    descricao: yup.string().required(msg.REQUIRED).min(10, msg.MIN(10))
  })
  .required()
interface IForm {
  data: any
  formMode: FormMode
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

export function Form({ data, formMode, setFormMode }: IForm) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<IServico>({ resolver: yupResolver(schema) })

  const [interno, setInterno] = useState<boolean>(data?.interno ?? false)

  const handleInternoCheck = () => {
    const servicoInterno = !interno
    setInterno(servicoInterno)
    setValue('interno', servicoInterno)
  }

  const { Toast } = useToast()
  const { mutateAsync: create } = useServico.Create()
  const { mutateAsync: update } = useServico.Update()

  const onSubmit = async (formData: IServico) => {
    formMode === 'CREATE'
      ? await Toast(create(formData))
      : await Toast(
          update({
            id_servico: data.id_servico,
            ...formData
          }),
          'UPDATED'
        )

    setFormMode('LIST')
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} mt={4}>
      <Stack gap={2}>
        <Input
          label="Nome*"
          {...register('nome')}
          defaultValue={data?.nome}
          message={errors.nome?.message}
          disabled={data?.em_uso || formMode === 'VIEW'}
        />
        <Input
          label="Descrição*"
          {...register('descricao')}
          defaultValue={data?.descricao}
          multiline
          maxRows={3}
          message={errors.descricao?.message}
          disabled={data?.em_uso || formMode === 'VIEW'}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={interno}
                onClick={handleInternoCheck}
                disabled={data?.em_uso || formMode === 'VIEW'}
              />
            }
            label="Serviço interno"
          />
        </FormGroup>
      </Stack>

      <Stack direction="row" gap={2} mt={3}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<CancelIcon />}
          onClick={() => setFormMode('LIST')}
        >
          Cancelar
        </Button>

        <Button
          type="submit"
          variant="contained"
          startIcon={<SaveIcon />}
          sx={{ width: '110px' }}
          disabled={data?.em_uso || formMode === 'VIEW'}
        >
          Salvar
        </Button>
      </Stack>
    </Box>
  )
}
