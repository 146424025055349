import { useState, useEffect, useRef } from 'react'
import {
  Box,
  Button,
  TextField,
  Stack,
  Tooltip,
  Menu,
  MenuItem,
  Typography
} from '@mui/material'
import { Link } from 'react-router-dom'
import SendIcon from '@mui/icons-material/Send'
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon'
import CachedIcon from '@mui/icons-material/Cached'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { FormTitle } from '../../../components/Form/FormTitle'
import { useMensagem } from '../../../hooks/mensagem/useMensagem'
import { useUsuario } from '../../../hooks/usuario/useUsuario'
import { useRequisicao } from '../../../hooks/requisicao/useRequisicao'
import { IRequisicao } from '../../../services/requisicao/types'
import api from '../../../services/api'
import { getCodigo } from '../../../utils/getCodigo'
import { ShowMessage } from '../../../components/ShowMessage'
import UsernameDisplay from './components/UsernameDisplay'

export function Mensagens() {
  // const [mensagem, setMensagem] = useState<string>()
  const [mensagem, setMensagem] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>()
  // const parentRef = useRef<HTMLDivElement>(null)
  // const [parentWidth, setParentWidth] = useState<number>(1)
  const textFieldRef = useRef<HTMLInputElement>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [open, setOpen] = useState(false)

  const localData = localStorage.getItem('@easydoc:user')
  let idData: any
  if (localData) {
    idData = JSON.parse(localData)
  }
  const req_id = localStorage.getItem('@easydoc:req_id')
  let reqData: any
  if (req_id) {
    reqData = req_id
  }
  const id_destinatario = localStorage.getItem('@easydoc:req_user')
  let idDest: any
  if (id_destinatario) {
    idDest = id_destinatario
  }

  const handleOpenEmojis = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }

  const filteredIcons = [
    { emoji: '🙂' },
    { emoji: '😐' },
    { emoji: '😞' },
    { emoji: '😂' },
    { emoji: '😢' },
    { emoji: '😍' },
    { emoji: '🤔' },
    { emoji: '😕' },
    { emoji: '😳' },
    { emoji: '😡' },
    { emoji: '👍' },
    { emoji: '👎' },
    { emoji: '🙏' },
    { emoji: '👏' },
    { emoji: '💪' },
    { emoji: '📞' },
    { emoji: '📅' },
    { emoji: '📌' }
  ]

  const handleSelectEmoji = (emoji: string) => {
    const newMessage = mensagem + emoji
    setMensagem(newMessage)
    handleClose()

    setTimeout(() => {
      const inputElement = textFieldRef.current
      if (inputElement) {
        inputElement.focus()
        inputElement.setSelectionRange(newMessage.length, newMessage.length)
      }
    }, 0)
  }

  async function submitMessage(event: React.FormEvent<HTMLInputElement>) {
    event.preventDefault()

    try {
      await api.post('/mensagem', {
        id_remetente: idData.id_usuario,
        id_destinatario: idDest,
        id_requisicao: reqData,
        mensagem: mensagem
      })
    } catch (err: any) {
      setErrorMessage(err?.message)
    }
    setMensagem('')
    window.location.reload()
  }

  const { data: records } = useMensagem.findByRequisicao(reqData)
  const { data: userName } = useUsuario.FindByUsername()
  const { data: reqStatus } = useRequisicao.FindOne(reqData)

  let fullData
  if (records) {
    fullData = [...records]
    fullData.sort(
      (a, b) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
    )
  }

  async function updateUnread(id_mensagem: string) {
    try {
      await api.put(`/mensagem/${id_mensagem}`, { lida: true })
    } catch (err: any) {
      setErrorMessage(err?.message)
    }
  }

  if (idData.id_usuario === idDest) {
    fullData?.map(async (obj) => {
      if (obj.id_remetente !== userName?.id_usuario) {
        await updateUnread(obj.id_mensagem)
      }
    })
  }

  if (userName?.pessoa.setor.empresa?.nome === 'X-SOLUTION') {
    fullData?.map(async (obj) => {
      if (obj.id_remetente === idDest) {
        await updateUnread(obj.id_mensagem)
      }
    })
  }

  function dateFormat(dataHoraString: string) {
    dataHoraString = dataHoraString.slice(1)

    const [dataPart, horaPart] = dataHoraString.split('T')
    const [ano, mes, dia] = dataPart.split('-')
    // eslint-disable-next-line prefer-const
    let [hora, minutos] = horaPart.slice(0, -5).split(':')

    if (Number(hora) < 3) {
      hora = String(Number(hora) + 21)
    } else hora = String(Number(hora) - 3)

    const dataFormatada = `${dia}-${mes}-${ano}`
    const horaFormatada = `${hora.padStart(2, '0')}:${minutos.padStart(2, '0')}`

    return `${dataFormatada} / ${horaFormatada}`
  }

  const getEstadoExecucao = (requisicao: IRequisicao): string => {
    if (!requisicao.itens_requisicao) return 'COMPLETO'

    const todasMovimentacoesCompleto = requisicao.itens_requisicao.some(
      (item) =>
        item.movimentacoes.some(
          (movimentacao) =>
            movimentacao.estado === 'PENDENTE' ||
            movimentacao.estado === 'EXECUTANDO'
        )
    )

    return todasMovimentacoesCompleto ? 'EXECUTANDO/PENDENTE' : 'COMPLETO'
  }

  const estadoExecucao = reqStatus ? getEstadoExecucao(reqStatus) : ''

  // useEffect(() => {
  //   const parentElement = parentRef.current
  //   if (parentElement) {
  //     const width = parentElement.getBoundingClientRect().width
  //     setParentWidth(width)
  //   }
  // }, [parentRef])

  const messagesEndRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    scrollToBottom()
  }, [fullData])

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <>
      <Box
        sx={{
          padding: '15px',
          background: '#fff',
          borderRadius: 2,
          height: '75vh',
          position: 'relative',
          overflowY: 'auto',
          marginBottom: 'calc((100vh - 70vh) / 6)'
        }}
      >
        <Box
          sx={{
            padding: '15px',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <FormTitle
            title={`Mensagem (Requisição: ${getCodigo(reqData ?? '')})`}
          />

          <Link to="/gerenciar-solicitacoes/status-solicitacoes">
            <Tooltip title="voltar">
              <Button variant="contained">
                <ArrowBackIcon sx={{ minWidth: '12px' }} />
              </Button>
            </Tooltip>
          </Link>
        </Box>

        <Box>
          <Stack gap={5} mb={3} mt={3} sx={{ zIndex: 0 }}>
            <Stack gap={2} sx={{ position: 'relative' }}>
              {fullData?.map((obj) => (
                <>
                  <Typography
                    sx={{
                      marginTop: '10px',
                      marginBottom: '-15px',
                      alignSelf:
                        obj.id_remetente === idData.id_usuario
                          ? 'flex-end'
                          : 'flex-start'
                    }}
                  >
                    {obj.id_remetente === idData.id_usuario ? (
                      <Typography>{userName?.username}</Typography>
                    ) : (
                      <Typography>
                        <UsernameDisplay userId={obj.id_remetente} />
                      </Typography>
                    )}
                  </Typography>
                  <Typography
                    key={obj.id_mensagem}
                    sx={{
                      fontSize: '17px',
                      bgcolor:
                        obj.id_remetente === idData.id_usuario
                          ? '#2835ad'
                          : '#626ff5',
                      color: '#fff',
                      maxWidth: '70%',
                      borderRadius: '10px',
                      padding: '10px',
                      alignSelf:
                        obj.id_remetente === idData.id_usuario
                          ? 'flex-end'
                          : 'flex-start'
                    }}
                  >
                    {obj.mensagem
                      .split(
                        /(\s|,|\.|!|\?|#|&|@|%|\^|\*|\(|\)|-|\/|=|<|>|\+|;|:|_|~|`|\$|€|£|¥|°|{|}|\[|\]|\d+|\w+|🙂|😐|😞|👍|👎|😂|😢|😍|🤔|🙏|👏|💪|😕|😳|😡|📞|📅|📌|🎉|❤️|✈️|\d{1,2}[/-]\d{1,2}[/-]\d{2,4}|\d{4}[-/]\d{1,2}[-/]\d{1,2})/
                      )
                      .map((part, index) => (
                        <span
                          key={index}
                          style={{
                            fontSize: filteredIcons
                              .map((icon) => icon.emoji)
                              .includes(part)
                              ? '30px'
                              : 'inherit'
                          }}
                        >
                          {part}
                        </span>
                      ))}
                    {/* {obj.mensagem} */}
                    <Typography
                      sx={{
                        fontSize: '11px',
                        color: '#aeaeb0',
                        textAlign: 'right'
                      }}
                    >
                      {dateFormat(JSON.stringify(obj.created_at))}
                    </Typography>
                  </Typography>
                </>
              ))}
              <div ref={messagesEndRef}></div>
            </Stack>
          </Stack>
        </Box>
      </Box>
      <Box
        component="form"
        onSubmit={submitMessage}
        sx={{
          display: 'flex',
          position: 'relative',
          width: '100%'
        }}
      >
        <TextField
          inputRef={textFieldRef}
          sx={{
            bgcolor: '#fff',
            flex: 1,
            marginRight: '10px',
            '& .MuiInputBase-input': {
              fontSize: '16px'
            }
          }}
          placeholder="Mensagem"
          required
          autoFocus
          autoComplete="off"
          value={mensagem}
          onChange={(e) => setMensagem(e.target.value)}
        />
        <Button
          variant="contained"
          type="submit"
          disabled={estadoExecucao === 'COMPLETO' ? true : false}
          sx={{ height: '40px', ml: 2, mt: 0.7 }}
        >
          <SendIcon sx={{ color: '#fff' }} />
        </Button>
        <Button
          variant="outlined"
          sx={{ height: '40px', ml: 2, mt: 0.7, bgcolor: '#fff' }}
          onClick={handleOpenEmojis}
        >
          <InsertEmoticonIcon />
        </Button>

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          sx={{
            maxWidth: '400px',
            maxHeight: '500px'
          }}
        >
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(4, 1fr)',
              padding: '8px'
            }}
          >
            {filteredIcons.map(({ emoji }, index) => (
              <MenuItem key={index} onClick={() => handleSelectEmoji(emoji)}>
                <Button
                  sx={{
                    minWidth: '35px',
                    fontSize: '30px',
                    '&:hover': {
                      backgroundColor: 'transparent'
                    }
                  }}
                >
                  {emoji}
                </Button>
              </MenuItem>
            ))}
          </Box>
        </Menu>
        <Button
          variant="outlined"
          sx={{ height: '40px', ml: 2, mt: 0.7, bgcolor: '#fff' }}
          onClick={() => window.location.reload()}
        >
          <CachedIcon />
        </Button>
      </Box>

      <ShowMessage
        hasError={!!errorMessage}
        message={errorMessage}
        onClose={() => setErrorMessage(undefined)}
      />
    </>
  )
}
