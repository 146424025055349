import { useEffect, useState } from 'react'
import { useUsuario } from '../../../../hooks/usuario/useUsuario'

interface UsernameDisplayProps {
  userId: string
}

const UsernameDisplay = ({ userId }: UsernameDisplayProps) => {
  const { data: userData, isLoading, isError } = useUsuario.FindById(userId)
  const [username, setUsername] = useState<string | null>(null)

  useEffect(() => {
    if (userData) {
      setUsername(userData.username)
    }
  }, [userData])

  if (isLoading) {
    return <div>Carregando...</div>
  }

  if (isError) {
    return <div>Ocorreu um erro ao carregar o nome de usuário.</div>
  }

  return <div>{username}</div>
}

export default UsernameDisplay
