import { Box, BoxProps } from '@mui/material'
import { ReactNode } from 'react'

interface DashboardWidgetSummaryRootProps extends BoxProps {
  children: ReactNode
}

export function DashboardWidgetSummaryRoot({
  children,
  ...rest
}: DashboardWidgetSummaryRootProps) {
  return <Box {...rest}>{children}</Box>
}
