import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip
} from '@mui/material'
import { useDiretorio } from '../../../hooks/diretorio/useDiretorio'
import { Loading } from '../../../components/Loading'
import { useEffect, useState } from 'react'
import FolderIcon from '@mui/icons-material/Folder'
import EditIcon from '@mui/icons-material/Edit'
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder' // Ícone para novo diretório
import PostAddIcon from '@mui/icons-material/PostAdd'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import PDFIcon from '@mui/icons-material/PictureAsPdf'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { ModalDiretorioForm } from './components/ModalDiretorio'
import { IDiretorio } from '../../../services/diretorio/types'
import useToast from '../../../hooks/toast/useToast'
import { ApiDiretorio } from '../../../services/diretorio'
import { DrivePath } from './components/DrivePath'
import { useSetor } from '../../../hooks/setor/useSetor'
import { ModalDocumentosSetor } from '../../../components/ModalDocumentoSetor'
import { useDocumento } from '../../../hooks/documento/useDocumento'
import { IAddDocumentoDiretorioRequest } from '../../../services/documento/types'
import { ApiDocumento } from '../../../services/documento'
import { hasPermission } from '../../../utils/hasPermission'
import { useUsuario } from '../../../hooks/usuario/useUsuario'
import { Permissions } from '../../../types/permissions'
import UndoIcon from '@mui/icons-material/Undo'
import { FormMode } from '../../../types/formMode'

export interface ISetorDriveProps {
  id_setor: string
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

export function SetorDrive({ id_setor, setFormMode }: ISetorDriveProps) {
  //const iconColor = '#7bd148'
  const iconColor = '#0076ff'
  const { Toast } = useToast()
  const { data: usuario } = useUsuario.FindByUsername()

  const [currentDirectory, setCurrentDirectory] = useState<IDiretorio>()
  const [browseDirectory, setBrowseDirectory] = useState<IDiretorio>()
  const [openModalDiretorio, setOpenModalDiretorio] = useState<boolean>(false)
  const { data: setor, isLoading: isLoadingSetor } = useSetor.FindOne(id_setor)
  const [openModalDocumentos, setOpenModalDocumentos] = useState(false)

  const [isLoadingFile, setIsLoadingFile] = useState(false)

  const canModifyDrive = (
    permissions: number[] | undefined,
    permission: number
  ) => {
    if (!(permissions && permissions.includes(permission))) {
      return false
    } else return true
  }

  const canManipulateDrive = canModifyDrive(
    usuario?.permissoes,
    Permissions.SETORES_DRIVE_VIRTUAL
  )

  const handleReturn = () => {
    setFormMode('LIST')
  }

  const [path, setPath] = useState<string[]>([setor?.nome ?? ''])

  const { data: records, isLoading: isLoadingDiretorioRaiz } =
    useDiretorio.findBySetor(id_setor)
  const {
    data: directory,
    isLoading,
    refetch
  } = useDiretorio.findOne(browseDirectory?.id_diretorio ?? '')
  const { mutateAsync: remove } = useDiretorio.Delete()
  useEffect(() => {
    setPath([setor?.nome ?? ''])
  }, [setor])

  const { mutateAsync: removeDocumentoDiretorio } =
    useDocumento.RemoveDocumentoDiretorio()

  const { mutateAsync: addDocumentoDiretorio } =
    useDocumento.AddDocumentoDiretorio()

  if (isLoading || isLoadingDiretorioRaiz || isLoadingSetor) return <Loading />

  const browseDirectoryData = browseDirectory ? directory?.ramos : records
  const browseDocumentsData = browseDirectory ? directory?.documentos : []

  const handleAddDocumentToDirectory = async ({
    id_diretorio,
    id_documento
  }: IAddDocumentoDiretorioRequest) => {
    await await Toast(
      addDocumentoDiretorio({ id_diretorio, id_documento }),
      'CREATED'
    )
    refetch()
  }

  const handleRemoveDocumentFromDirectory = async (id_documento: string) => {
    await Toast(removeDocumentoDiretorio(id_documento), 'REMOVED')
    refetch()
  }

  const handleAddDirectory = () => {
    setCurrentDirectory(undefined)
    setOpenModalDiretorio(true)
  }

  const handleEditDirectory = (diretorio: IDiretorio) => {
    setCurrentDirectory(diretorio)
    setOpenModalDiretorio(true)
  }

  const handleRemoveDirectory = async (id_diretorio: string) => {
    //
    await Toast(remove(id_diretorio), 'REMOVED')
  }

  const handleAddFile = () => {
    // Implemente a lógica para adicionar um novo arquivo aqui
    setOpenModalDocumentos(true)
  }

  const handleReturnDirectory = async () => {
    if (browseDirectory?.id_diretorio_pai) {
      const previousDirectory = await ApiDiretorio.findOne(
        browseDirectory.id_diretorio_pai
      )
      setBrowseDirectory(previousDirectory)
    } else {
      setBrowseDirectory(undefined)
    }
    handleRemovePath()
  }

  const handleAddPath = (directory: IDiretorio) => {
    setPath((prev) => [...prev, directory.descricao])
  }

  const handleRemovePath = () => {
    setPath((prev) => prev.slice(0, -1))
  }

  const handleOpenDirectory = (directory: IDiretorio) => {
    handleAddPath(directory)
    setBrowseDirectory(directory)
  }

  const handleDigitalFile = async (
    id_contrato: string,
    filePath: string,
    id_documento: string,
    action: 'download' | 'preview'
  ) => {
    try {
      setIsLoadingFile(true)
      const url = await ApiDocumento.digitalFile(
        id_contrato,
        filePath,
        id_documento
      )

      if (action === 'preview') {
        if (
          hasPermission(usuario?.permissoes, Permissions.VISUALIZAR_DOCUMENTO)
        )
          window.open(url, '_blank', 'popup=yes')
      } else {
        if (
          hasPermission(
            usuario?.permissoes,
            Permissions.DOWNLOAD_DOCUMENTO_DIGITAL
          )
        ) {
          const fileName = url.split('/').pop()
          const anchor = document.createElement('a')
          anchor.href = url
          anchor.setAttribute('download', fileName!)
          document.body.appendChild(anchor)
          anchor.click()
          document.body.removeChild(anchor)
          anchor.remove()
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoadingFile(false)
    }
  }

  /* 
    const [openModalMoveFolder, setOpenModalMoveFolder] = useState(false)
  {canManipulateDrive && (
            <Tooltip title="Mover para">
              <IconButton onClick={() => setOpenModalMoveFolder(true)}>
                <DriveFileMoveIcon sx={{ fontSize: 32 }} />
              </IconButton>
            </Tooltip>
          )}

                <ModalMoveFolder
        id_setor={id_setor}
        open={openModalMoveFolder}
        handleClose={() => setOpenModalMoveFolder(false)}
      />

  */

  return (
    <Box mt={2}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <DrivePath path={path} />
        <Toolbar disableGutters sx={{ justifyContent: 'flex-end' }}>
          <Tooltip title="Retornar para lista">
            <IconButton
              onClick={handleReturn}
              sx={{
                color: iconColor
              }}
            >
              <UndoIcon sx={{ fontSize: 32 }} />
            </IconButton>
          </Tooltip>
          {canManipulateDrive && (
            <Tooltip title="Adicionar novo diretório">
              <IconButton
                onClick={handleAddDirectory}
                sx={{
                  color: iconColor
                }}
              >
                <CreateNewFolderIcon sx={{ fontSize: 32 }} />
              </IconButton>
            </Tooltip>
          )}

          {canManipulateDrive && browseDirectory && (
            <Tooltip title="Adicionar novo documento">
              <IconButton
                onClick={handleAddFile}
                sx={{
                  color: iconColor
                }}
              >
                <PostAddIcon sx={{ fontSize: 32 }} />
              </IconButton>
            </Tooltip>
          )}
        </Toolbar>
      </Box>
      <TableContainer
        component={Box}
        border={1}
        borderRadius={2}
        borderColor="#f2f2f2"
      >
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
              <TableCell
                sx={{ fontWeight: 'bold', fontSize: '14px', width: '100%' }}
              >
                Nome
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Tamanho
              </TableCell>
              <TableCell width="12px" />
              <TableCell width="12px" />
              <TableCell width="12px" />
            </TableRow>
          </TableHead>
          <TableBody>
            {browseDirectory && (
              <TableRow
                sx={{
                  height: '40px',
                  '&:hover': {
                    // Quando o TableRow tem hover, altera a opacidade do botão para 1
                    '& button': {
                      opacity: 1
                    }
                  },
                  '&:hover svg': {
                    // Mantém o estilo aplicado ao svg (ícone) quando o TableRow tem hover
                    opacity: 1
                  }
                }}
                onDoubleClick={handleReturnDirectory}
              >
                <TableCell sx={{ fontSize: '14px' }}>
                  <Box display="flex" alignItems="flex-end" gap={1}>
                    <FolderIcon
                      sx={{
                        color: iconColor
                      }}
                    />
                    ...
                  </Box>
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            )}
            {browseDirectoryData?.map((record) => (
              <TableRow
                key={record.id_diretorio}
                hover
                sx={{
                  height: '40px',
                  '&:hover': {
                    // Quando o TableRow tem hover, altera a opacidade do botão para 1
                    '& button': {
                      opacity: 1
                    }
                  },
                  '&:hover svg': {
                    // Mantém o estilo aplicado ao svg (ícone) quando o TableRow tem hover
                    opacity: 1
                  }
                }}
                onDoubleClick={() => handleOpenDirectory(record)}
              >
                <TableCell sx={{ fontSize: '14px' }}>
                  <Box display="flex" alignItems="flex-end" gap={1}>
                    <FolderIcon
                      sx={{
                        color: iconColor
                      }}
                    />
                    {record.descricao}
                  </Box>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  {canManipulateDrive && (
                    <Tooltip title="Editar" arrow sx={{}}>
                      <Button
                        sx={{
                          opacity: 0,
                          transition: 'opacity 0.3s',
                          minWidth: '24px',
                          padding: '6px'
                        }}
                        variant="outlined"
                        color="primary"
                        onClick={() => handleEditDirectory(record)}
                      >
                        <EditIcon />
                      </Button>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell>
                  {canManipulateDrive && (
                    <Tooltip title="Excluir" arrow sx={{}}>
                      <Button
                        sx={{
                          opacity: 0,
                          transition: 'opacity 0.3s',
                          minWidth: '24px',
                          padding: '6px'
                        }}
                        variant="outlined"
                        color="primary"
                        onClick={() =>
                          handleRemoveDirectory(record.id_diretorio ?? '')
                        }
                      >
                        <DeleteIcon />
                      </Button>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            ))}
            {browseDocumentsData?.map((record) => (
              <TableRow
                key={record.id_documento}
                hover
                sx={{
                  height: '40px',
                  '&:hover': {
                    // Quando o TableRow tem hover, altera a opacidade do botão para 1
                    '& button': {
                      opacity: 1
                    }
                  },
                  '&:hover svg': {
                    // Mantém o estilo aplicado ao svg (ícone) quando o TableRow tem hover
                    opacity: 1
                  }
                }}
              >
                <TableCell sx={{ fontSize: '14px' }}>
                  <Box display="flex" alignItems="flex-end" gap={1}>
                    <PDFIcon />
                    {record.descricao}
                  </Box>
                </TableCell>
                <TableCell>
                  {record.tamanho
                    ? parseFloat(record.tamanho).toFixed(2)
                    : '0.00'}{' '}
                  MB
                </TableCell>
                <TableCell>
                  <Tooltip title="Download" arrow sx={{}}>
                    <Button
                      sx={{
                        opacity: 0,
                        transition: 'opacity 0.3s',
                        minWidth: '24px',
                        padding: '6px'
                      }}
                      variant="outlined"
                      color="primary"
                      disabled={isLoadingFile}
                      onClick={() =>
                        handleDigitalFile(
                          record.id_contrato,
                          record.path!,
                          record.id_documento!,
                          'preview'
                        )
                      }
                    >
                      <CloudDownloadIcon />
                    </Button>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {canManipulateDrive && (
                    <Tooltip title="Excluir" arrow sx={{}}>
                      <Button
                        sx={{
                          opacity: 0,
                          transition: 'opacity 0.3s',
                          minWidth: '24px',
                          padding: '6px'
                        }}
                        variant="outlined"
                        color="primary"
                        onClick={() =>
                          handleRemoveDocumentFromDirectory(
                            record.id_documento ?? ''
                          )
                        }
                      >
                        <DeleteIcon />
                      </Button>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ModalDiretorioForm
        open={openModalDiretorio}
        handleClose={() => setOpenModalDiretorio(false)}
        id_setor={id_setor}
        diretorio={currentDirectory}
        id_diretorio_pai={browseDirectory?.id_diretorio}
      />
      <ModalDocumentosSetor
        handleClose={() => setOpenModalDocumentos(false)}
        open={openModalDocumentos}
        handleDigitalFile={handleDigitalFile}
        id_setor={id_setor}
        id_diretorio={browseDirectory?.id_diretorio ?? ''}
        handleAddDocumentToDirectory={handleAddDocumentToDirectory}
      />
    </Box>
  )
}
