import { Box, Tab, Tabs } from '@mui/material'
import { FormTitle } from '../../../components/Form/FormTitle'
import { Form } from './Form'
import { useState, SyntheticEvent } from 'react'
import { CopiarPermissoes } from './CopiarPermissoes'

export function SetoresPermitidos() {
  const [tabSelected, setTabSelected] = useState(0)

  const onChangeTabSelected = (event: SyntheticEvent, newValue: number) => {
    setTabSelected(newValue)
  }

  return (
    <Box bgcolor="#fff" p={4} borderRadius={2}>
      <FormTitle title="Setores Permitidos dos Usuários" />

      <Tabs value={tabSelected} onChange={onChangeTabSelected}>
        <Tab label="Copiar " style={{ fontSize: 16 }} />
        <Tab label="Gerenciar " style={{ fontSize: 16 }} />
      </Tabs>

      {tabSelected === 1 ? <Form /> : <CopiarPermissoes />}
    </Box>
  )
}
