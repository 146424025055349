import { ApiRelatorio } from '../../../../services/relatorio'
import { TipoDocumentosPorPaginaReport } from './report/TipoDocumentosPorPagina'

import {
  IHandleReportSubmitProps,
  ReportForm
} from '../../../../components/ReportForm'

export function TipoDocumentosPorPaginaRelatorio() {
  async function handleSubmitForm({
    mode,
    format,
    id_usuario,
    filtros
  }: IHandleReportSubmitProps) {
    const { id_empresa, id_tipo_documento, data_fim, data_inicio, id_setor } =
      filtros
    const tipoDocumentosPorPaginaData =
      await ApiRelatorio.getTipoDocumentosPorPagina(filtros)

    if (format === 'pdf') {
      await TipoDocumentosPorPaginaReport({
        id_usuario: id_usuario,
        data: tipoDocumentosPorPaginaData,
        mode,
        filtros: {
          id_empresa: id_empresa ?? '',
          id_tipo_documento,
          data_fim,
          data_inicio,
          id_setor
        }
      })
    }

    return tipoDocumentosPorPaginaData
  }
  return (
    <ReportForm
      title="Tipos Documentos / Total Imagens"
      handleReportSubmit={handleSubmitForm}
      mostrarSetor={true}
      mostrarEndereco={false}
      mostrarTipoDocumento={true}
    />
  )
}
