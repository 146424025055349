import { ApiRelatorio } from '../../../../../services/relatorio'
import { CaixasMovimentadasPorServicoReport } from './report/CaixasMovimentadasPorServicoReport'
import {
  IHandleReportSubmitProps,
  ReportForm
} from '../../../../../components/ReportForm'

export function CaixasMovimentadasPorServicoRelatorio() {
  async function handleSubmitForm({
    mode,
    format,
    id_usuario,
    filtros
  }: IHandleReportSubmitProps) {
    const {
      data_fim,
      data_inicio,
      id_contrato,
      id_item_contrato,
      tipoConteudo,
      id_setor
    } = filtros
    const caixasMovimentadasPorServico =
      await ApiRelatorio.getMovimentedBoxesByService({
        data_fim,
        data_inicio,
        id_contrato: id_contrato ? id_contrato : '',
        id_item_contrato,
        tipoConteudo,
        id_setor
      })

    if (format === 'pdf') {
      CaixasMovimentadasPorServicoReport({
        id_usuario: id_usuario,
        data: caixasMovimentadasPorServico,
        filtros: {
          data_fim,
          data_inicio,
          id_contrato,
          id_item_contrato,
          tipoConteudo,
          id_setor
        },
        mode
      })
    }

    return caixasMovimentadasPorServico
  }
  return (
    <ReportForm
      title="Caixas / Documentos Movimentados por Serviço"
      handleReportSubmit={handleSubmitForm}
      mostrarPeriodo={true}
      mostrarContrato={true}
      mostrarEmpresa={false}
      mostrarSetor={true}
      mostrarTipoConteudo={true}
    />
  )
}
