import { useEffect, useState } from 'react'
import { Box, Button, Stack } from '@mui/material'
import SaveIcon from '@mui/icons-material/CheckCircleOutlined'
import CancelIcon from '@mui/icons-material/DoDisturbAltOutlined'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import useToast from '../../../hooks/toast/useToast'
import { useUf } from '../../../hooks/uf/useUf'
import { useMunicipio } from '../../../hooks/municipio/useMunicipio'
import { Input } from '../../../components/Form/Input'
import msg from '../../../utils/validationMessages'
import { Select } from '../../../components/Form/Select'
import { FormMode } from '../../../types/formMode'
import yup from '../../../types/yup.string.d'
import { masks } from '../../../utils/masks'
import { validation } from '../../../utils/validations'
import { ComponentGroup } from '../../../components/ComponentGroup'
import { Loading } from '../../../components/Loading'
import { useParceiro } from '../../../hooks/financeiro/parceiro/useParceiro'
import { IParceiro } from '../../../services/financeiro/parceiro/types'
import { useCategoriaParceiro } from '../../../hooks/financeiro/categoriaParceiro/useCategoriaParceiro'
import { useEmpresa } from '../../../hooks/empresa/useEmpresa'

const tipoParceiro = [
  {
    descricao: 'Físico',
    value: 'F'
  },
  {
    descricao: 'Jurídico',
    value: 'J'
  }
]

const schema = yup
  .object({
    nome: yup.string().required(msg.REQUIRED).min(3, msg.MIN(3)),
    razao_social: yup.string(),
    cpfCNPJ: yup.string().required(msg.REQUIRED),
    email: yup.string().email(msg.EMAIL),
    telefone1: yup.string().phone(msg.PHONE),
    telefone2: yup.string().phone(msg.PHONE),
    tipo: yup.string().required(msg.REQUIRED),
    tipo_relacionamento: yup.string().required(msg.REQUIRED),
    inscricao_estadual: yup.string(),
    inscricao_municipal: yup.string(),
    id_empresa: yup.string(),
    endereco: yup.object({
      logradouro: yup.string(),
      id_municipio: yup.string(),
      numero: yup.string(),
      bairro: yup.string(),
      cep: yup.string(),
      referencia: yup.string(),
      complemento: yup.string()
    })
  })
  .required()

interface IForm {
  tipo_relacionamento: string
  data: any
  formMode: FormMode
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

export function ParceiroForm({
  tipo_relacionamento,
  data,
  formMode,
  setFormMode
}: IForm) {
  const [uf, setUf] = useState<string>(data?.endereco?.municipio?.estado?.sigla)
  const [tipo, setTipo] = useState<string>(data?.tipo)
  const [id_municipio, setIdMunicipio] = useState<string>(
    data?.endereco?.id_municipio ?? ''
  )
  const {
    register,
    handleSubmit,
    resetField,
    setValue,
    getValues,
    formState: { errors }
  } = useForm<IParceiro>({
    resolver: yupResolver(schema)
  })
  const { Toast, ToastError } = useToast()
  const { mutateAsync: create } = useParceiro.Create()
  const { mutateAsync: update } = useParceiro.Update()
  const { data: ufs, isLoading } = useUf.ListAll()
  const { data: municipios } = useMunicipio.FindByUf(uf)
  const { data: listCategorias } =
    useCategoriaParceiro.ListAll(tipo_relacionamento)
  const { data: listaEmpresas } = useEmpresa.ListAllXSolution()

  useEffect(() => {
    // Atualize o estado no início da renderização
    setValue('tipo_relacionamento', tipo_relacionamento)
  }, [])

  const onSubmit = async (formData: IParceiro) => {
    const { tipo, cpfCNPJ, email } = formData
    let validaCPFCNPJ = false
    if (tipo === 'F') {
      validaCPFCNPJ = validation.cpf(cpfCNPJ)
    } else {
      validaCPFCNPJ = validation.cnpj(cpfCNPJ)
    }

    const emailData = email === '' ? undefined : email

    if (validaCPFCNPJ) {
      formMode === 'CREATE'
        ? await Toast(
            create({
              ...formData,
              email: email === '' ? undefined : email
            })
          )
        : await Toast(
            update({
              id_parceiro: data.id_parceiro,
              email: emailData,
              ...formData
            }),
            'UPDATED'
          )

      setFormMode('LIST')
    } else ToastError('CPF / CNPJ Inválido')
  }

  function handlePhoneChange(value: string): string {
    const newValue = masks.phone({ value, mandatoryDDD: true })
    return newValue
  }

  function handlePhoneBlur(value: string): string {
    const newValue = validation.clearValue(value)
    if (newValue.length === 0) {
      return ''
    }
    return value
  }

  function handleCpfCnpjChange(value: string): string {
    const newValue =
      getValues('tipo') === 'F' ? masks.cpf({ value }) : masks.cnpj({ value })
    return newValue
  }

  function handleCepChange(value: string): string {
    const newValue = masks.cep({ value })
    return newValue
  }

  function handleMunicipioChange(id: string) {
    setIdMunicipio(id)
    setValue('endereco.id_municipio', id)
  }

  function handleTipoChange(value: string) {
    setValue('cpfCNPJ', '')
    setTipo(value)
    setValue('tipo', value)
  }

  const isCliente = tipo_relacionamento === 'C'

  if (isLoading) return <Loading />

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} mt={4}>
      <Stack gap={2} direction={'row'}>
        <Select
          label="Tipo"
          placeholder="Selecione o tipo"
          data={tipoParceiro}
          fields={['value', 'descricao']}
          {...register('tipo')}
          defaultValue={data?.tipo}
          onChange={(event) => handleTipoChange(String(event.target.value))}
          message={errors.tipo?.message}
        />

        <Select
          label={`Categoria do ${isCliente ? 'Cliente' : 'Fornecedor'}`}
          placeholder="Selecione a categoria"
          data={listCategorias}
          fields={['id_categoria_parceiro', 'descricao']}
          {...register('id_categoria_parceiro')}
          defaultValue={data?.id_categoria_parceiro}
          message={errors.id_categoria_parceiro?.message}
        />
      </Stack>
      {!isCliente && (
        <Stack gap={2} direction={'row'} mt={1}>
          <Select
            label={`Empresa`}
            placeholder="Selecione a empresa"
            data={listaEmpresas}
            fields={['id_empresa', 'nome']}
            {...register('id_empresa')}
            defaultValue={data?.id_empresa}
            message={errors.id_empresa?.message}
          />
        </Stack>
      )}
      <Stack gap={2} mt={1}>
        <Input
          label="Nome*"
          {...register('nome')}
          defaultValue={data?.nome}
          message={errors.nome?.message}
        />

        {tipo === 'J' && (
          <Input
            label="Razão social*"
            {...register('razao_social')}
            defaultValue={data?.razao_social}
            message={errors.razao_social?.message}
          />
        )}

        <Stack gap={2} direction="row">
          <Input
            label={`${tipo === 'F' ? 'CPF' : 'CNPJ'}*`}
            {...register('cpfCNPJ', {
              onChange: (event) => {
                const { value } = event.target

                event.target.value = handleCpfCnpjChange(value)
              }
            })}
            defaultValue={data?.cpfCNPJ}
            message={errors.cpfCNPJ?.message}
          />

          <Input label="Site" {...register('site')} defaultValue={data?.site} />
        </Stack>

        <Stack gap={2} direction="row">
          <Input
            label="E-mail"
            {...register('email')}
            defaultValue={data?.email}
            message={errors.email?.message}
          />
        </Stack>

        <Stack gap={2} direction="row">
          <Input
            label="Telefone 1"
            {...register('telefone1', {
              onChange: (event) => {
                const { value } = event.target
                event.target.value = handlePhoneChange(value)
              },
              onBlur: (event) => {
                const { value } = event.target
                event.target.value = handlePhoneBlur(value)
              }
            })}
            defaultValue={data?.telefone1}
            message={errors.telefone1?.message}
          />
          <Input
            label="Telefone 2"
            {...register('telefone2', {
              onChange: (event) => {
                const { value } = event.target
                event.target.value = handlePhoneChange(value)
              },
              onBlur: (event) => {
                const { value } = event.target
                event.target.value = handlePhoneBlur(value)
              }
            })}
            defaultValue={data?.telefone2}
            message={errors.telefone2?.message}
          />
        </Stack>

        <ComponentGroup title={'Endereço'}>
          <Stack gap={2} direction="row">
            <Select
              label="Estado"
              placeholder="Selecione o Estado"
              data={ufs}
              fields={['sigla', 'nome']}
              value={uf ?? ''}
              defaultValue={data?.endereco?.municipio?.estado?.sigla}
              onChange={(e) => {
                setUf(String(e.target.value))
                setIdMunicipio('')
              }}
              onBlur={() => resetField('endereco.id_municipio')}
            />

            <Select
              label="Município"
              placeholder="Selecione o município"
              data={municipios}
              fields={['id_municipio', 'nome']}
              value={id_municipio ?? ''}
              {...register('endereco.id_municipio')}
              onChange={(event) =>
                handleMunicipioChange(String(event.target.value))
              }
              defaultValue={data?.endereco?.municipio?.id_municipio}
              message={errors.endereco?.id_municipio?.message}
            />
          </Stack>

          <Input
            label="Logradouro*"
            {...register('endereco.logradouro')}
            defaultValue={data?.endereco?.logradouro}
            message={errors.endereco?.logradouro?.message}
          />

          <Stack gap={2} direction="row">
            <Input
              label="Número*"
              {...register('endereco.numero')}
              defaultValue={data?.endereco?.numero}
              message={errors.endereco?.numero?.message}
            />

            <Input
              label="Bairro*"
              {...register('endereco.bairro')}
              defaultValue={data?.endereco?.bairro}
              message={errors.endereco?.bairro?.message}
            />

            <Input
              label="Cep*"
              {...register('endereco.cep', {
                onChange: (event) => {
                  const { value } = event.target
                  event.target.value = handleCepChange(value)
                }
              })}
              defaultValue={data?.endereco?.cep}
              message={errors.endereco?.cep?.message}
            />
          </Stack>

          <Input
            label="Complemento"
            {...register('endereco.complemento')}
            defaultValue={data?.endereco?.complemento}
          />

          <Input
            label="Referencia"
            {...register('endereco.referencia')}
            defaultValue={data?.endereco?.referencia}
          />
        </ComponentGroup>
      </Stack>

      <Stack direction="row" gap={2} mt={3}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<CancelIcon />}
          onClick={() => setFormMode('LIST')}
        >
          Cancelar
        </Button>

        <Button
          type="submit"
          variant="contained"
          startIcon={<SaveIcon />}
          sx={{ width: '110px' }}
        >
          Salvar
        </Button>
      </Stack>
    </Box>
  )
}
