import { Box } from '@mui/material'
import { ReactNode } from 'react'

interface DashboardWidgetListBodyActionsProps {
  children: ReactNode
}

export function DashboardWidgetListBodyActions({
  children
}: DashboardWidgetListBodyActionsProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'space-between',
        gap: '1rem'
      }}
    >
      {children}
    </Box>
  )
}
