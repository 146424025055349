import { Typography } from '@mui/material'

interface DashboardWidgetHeaderSummaryDatabarDataProps {
  title: string
  data: string
}

export function DashboardWidgetHeaderSummaryDatabarData({
  title,
  data
}: DashboardWidgetHeaderSummaryDatabarDataProps) {
  return (
    <Typography
      sx={{
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '10px',
        lineHeight: '12px',
        color: '#969696',
        gap: 2,
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'space-between'
      }}
    >
      {title}: {data}
    </Typography>
  )
}
