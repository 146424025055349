import { useQuery } from 'react-query'
import { ApiTipoPapelEmpresa } from '../../services/tipoPapelEmpresa'

const QUERY_KEY = 'qkTipoPapelEmpresa'

const FindAll = (page = 1, q = '') => {
  return useQuery([QUERY_KEY, q, page], () =>
    ApiTipoPapelEmpresa.findAll(page, q)
  )
}

const ListAll = () => {
  return useQuery(QUERY_KEY, ApiTipoPapelEmpresa.listAll)
}

export const useTipoPapelEmpresa = {
  FindAll,
  ListAll
}
