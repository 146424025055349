import {
  IHandleReportSubmitProps,
  ReportForm
} from '../../../components/ReportForm'
import { ApiRelatorio } from '../../../services/relatorio'
import { TipoDocumentosReport } from './report/TipoDocumentos'

export function TipoDocumentosRelatorio() {
  async function handleSubmitForm({
    mode,
    format,
    id_usuario,
    filtros
  }: IHandleReportSubmitProps) {
    const { id_empresa, id_tipo_documento } = filtros
    const tipoDocumentosPorPaginaData = await ApiRelatorio.getTipoDocumentos(
      filtros
    )

    if (format === 'pdf') {
      await TipoDocumentosReport({
        id_usuario: id_usuario,
        data: tipoDocumentosPorPaginaData,
        mode,
        filtros: {
          id_empresa: id_empresa ?? '',
          id_tipo_documento
        }
      })
    }

    return tipoDocumentosPorPaginaData
  }
  return (
    <ReportForm
      title="Tipos de Documentos"
      handleReportSubmit={handleSubmitForm}
      mostrarSetor={false}
      mostrarEndereco={false}
      mostrarPeriodo={false}
      mostrarTipoDocumento={true}
      isEmpresaObrigatorio={false}
    />
  )
}
