import { Box, Tab, Tabs } from '@mui/material'
import { FormTitle } from '../../../components/Form/FormTitle'
import { Form } from './Form'
import { useState, SyntheticEvent } from 'react'
import { CopiarPermissoes } from './CopiarPermissoes'
import { useUsuario } from '../../../hooks/usuario/useUsuario'
import { Permissions as pms } from '../../../types/permissions'
import { hasAccess } from '../../../utils/hasAccess'

export function Permissoes() {
  const [tabSelected, setTabSelected] = useState(0)
  const { data: usuario } = useUsuario.FindByUsername()

  const onChangeTabSelected = (event: SyntheticEvent, newValue: number) => {
    setTabSelected(newValue)
  }

  return (
    <Box bgcolor="#fff" p={4} borderRadius={2}>
      <FormTitle title="Permissões dos Usuários" />

      <Tabs value={tabSelected} onChange={onChangeTabSelected}>
        <Tab label="Copiar " style={{ fontSize: 16 }} />
        <Tab
          label="Gerenciar "
          style={{ fontSize: 16 }}
          disabled={!hasAccess(usuario?.permissoes, pms.PAPEL_ADMIN)}
        />
      </Tabs>

      {tabSelected === 1 ? (
        <Form usuarioLogado={usuario} />
      ) : (
        <CopiarPermissoes usuarioLogado={usuario} />
      )}
    </Box>
  )
}
