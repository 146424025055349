export function removeNumericMask(value: string): number | null {
  try {
    const sanitizedValue = value.replace(/[^0-9,]/g, '')
    // Substitui o separador decimal pelo ponto
    const numericValue = sanitizedValue.replace(',', '.')

    const parsedValue = parseFloat(numericValue)
    return isNaN(parsedValue) ? null : parsedValue
  } catch (error) {
    return Number(value)
  }
}
