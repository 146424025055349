import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { BoxTitle } from '../../../../components/BoxTitle'
import { ReactNode } from 'react'
import { corTituloColunaTabela } from '../../cores'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'

interface IColumnDef {
  title: string
  width?: number | string
  component?: ReactNode
  field?: string
  canOrder?: boolean
}

interface DashboardWidgetListBodyTableProps {
  title?: string
  columnsDefs: IColumnDef[]
  orderField?: string
  orderDirection?: string
  setOrderField?: (field: string) => void
  setOrder?: (order: string) => void
  canOrder?: boolean
  children: ReactNode
}

const FormatacaoTituloColuna = {
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '24px',
  color: corTituloColunaTabela,
  borderBottom: 0,
  margin: 0,
  paddingY: 0,
  paddingX: 0.5
}

export function DashboardWidgetListBodyTable({
  title,
  orderDirection = 'asc',
  orderField,
  setOrder,
  setOrderField,
  canOrder = false,
  columnsDefs,
  children
}: DashboardWidgetListBodyTableProps) {
  const key = Math.floor(Math.random() * 100000)

  return title ? (
    <BoxTitle title={title} sx={{ marginTop: '1rem' }}>
      <TableContainer sx={{ maxHeight: '750px', overflow: 'auto' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow
              sx={{
                borderBottom: 0
              }}
            >
              {columnsDefs.map((columnDef, index) => {
                return (
                  <TableCell
                    key={`tblColHeader${key + index}`}
                    sx={{
                      minWidth: columnDef.width ? `${columnDef.width}px` : '0',
                      ...FormatacaoTituloColuna
                    }}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      const isSameField = columnDef.field === orderField
                      setOrderField && setOrderField(columnDef.field ?? '')
                      setOrder &&
                        setOrder(
                          isSameField ?? ''
                            ? orderDirection === 'asc'
                              ? 'desc'
                              : 'asc'
                            : 'desc'
                        )
                    }}
                  >
                    {columnDef.title}
                    {columnDef.component}
                    {columnDef.canOrder &&
                    canOrder &&
                    orderField === columnDef.field ? (
                      orderDirection === 'asc' ? (
                        <ArrowDownwardIcon
                          fontSize="small"
                          sx={{ marginLeft: '5px', marginTop: '5px' }}
                        />
                      ) : (
                        <ArrowUpwardIcon
                          fontSize="small"
                          sx={{ marginLeft: '5px', marginTop: '5px' }}
                        />
                      )
                    ) : (
                      <ArrowDownwardIcon
                        fontSize="small"
                        sx={{ marginLeft: '5px', marginTop: '5px' }}
                      />
                    )}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
    </BoxTitle>
  ) : (
    <Box>
      <TableContainer sx={{ maxHeight: '750px' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow
              sx={{
                borderBottom: 0
              }}
            >
              {columnsDefs.map((columnDef, index) => (
                <TableCell
                  key={`tblColHeader${key + index}`}
                  sx={{
                    minWidth: columnDef.width ? `${columnDef.width}px` : '0',
                    ...FormatacaoTituloColuna
                  }}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    const isSameField = columnDef.field === orderField
                    setOrder &&
                      setOrder(
                        isSameField ?? ''
                          ? orderDirection === 'asc'
                            ? 'desc'
                            : 'asc'
                          : 'desc'
                      )
                    setOrderField && setOrderField(columnDef.field ?? '')
                  }}
                >
                  {columnDef.title}
                  {columnDef.canOrder &&
                  canOrder &&
                  orderField === columnDef.field ? (
                    orderDirection === 'asc' ? (
                      <ArrowUpwardIcon
                        fontSize="small"
                        sx={{ marginLeft: '5px', marginTop: '5px' }}
                      />
                    ) : (
                      <ArrowDownwardIcon
                        fontSize="small"
                        sx={{ marginLeft: '5px', marginTop: '5px' }}
                      />
                    )
                  ) : columnDef.canOrder ? (
                    <ArrowUpwardIcon
                      fontSize="small"
                      sx={{ marginLeft: '5px', marginTop: '5px' }}
                    />
                  ) : (
                    <> </>
                  )}
                  {columnDef.component}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
