import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ApiContrato } from '../../services/contrato'

const QUERY_KEY = 'qkContrato'

const FindAll = (page: number, q = '') => {
  return useQuery([QUERY_KEY, q, page], () => ApiContrato.findAll(page, q))
}

const ListAll = () => {
  return useQuery(QUERY_KEY, ApiContrato.listAll)
}

const ListAllWithoutUserFilter = () => {
  return useQuery(QUERY_KEY, ApiContrato.listAllWithoutUserFilter)
}

const Create = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiContrato.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Update = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiContrato.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const FindOne = (id: string) => {
  return useQuery([QUERY_KEY, id], () => ApiContrato.findOne(id))
}

const Remove = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiContrato.remove, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Activate = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiContrato.activate, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

export const useContrato = {
  Create,
  FindAll,
  FindOne,
  ListAll,
  Update,
  Remove,
  Activate,
  ListAllWithoutUserFilter
}
