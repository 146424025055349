import { CheckCircle, HighlightOffOutlined } from '@mui/icons-material'
import { Box } from '@mui/material'

interface YesnoProps {
  yesProp: boolean
}

export const Yesno = ({ yesProp }: YesnoProps) => {
  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'space-around'}
      width={'100%'}
    >
      <Box
        borderRadius={'0.5rem'}
        bgcolor={yesProp ? 'lightgreen' : 'pink'}
        height={'2rem'}
        width={'2rem'}
        color={yesProp ? 'green' : 'red'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-around'}
        alignItems={'center'}
      >
        {yesProp ? <CheckCircle /> : <HighlightOffOutlined />}
      </Box>
    </Box>
  )
}
