import { useState } from 'react'
import { Box } from '@mui/material'
import { FormMode } from '../../../types/formMode'
import { FormTitle } from '../../../components/Form/FormTitle'
import { ParceiroForm } from './ParceiroForm'
import { ParceiroList } from './ParceiroList'

interface IParceiroProps {
  tipo_relacionamento: string
}

export function Parceiro({ tipo_relacionamento }: IParceiroProps) {
  const [formMode, setFormMode] = useState<FormMode>('LIST')
  const [data, setData] = useState<any>({})

  return (
    <Box bgcolor="#fff" p={4} borderRadius={2}>
      <FormTitle
        title={tipo_relacionamento === 'C' ? 'Clientes' : 'Fornecedores'}
        subtitle={formMode}
      />

      {formMode === 'LIST' ? (
        <ParceiroList
          setData={setData}
          setFormMode={setFormMode}
          tipo_relacionamento={tipo_relacionamento}
        />
      ) : (
        <ParceiroForm
          tipo_relacionamento={tipo_relacionamento}
          data={formMode === 'EDIT' ? data : {}}
          formMode={formMode}
          setFormMode={setFormMode}
        />
      )}
    </Box>
  )
}
