import { Box } from '@mui/material'
import { useState } from 'react'
import { FormTitle } from '../../../components/Form/FormTitle'
import { FormMode } from '../../../types/formMode'
import { FormTipoTemporalidade } from './FormTipoTemporalidade'
import { ListTipoTemporalidade } from './ListTipoTemporalidade'

export function TipoTemporalidade() {
  const [formMode, setFormMode] = useState<FormMode>('LIST')
  const [data, setData] = useState<any>()
  return (
    <Box bgcolor="#fff" p={4} borderRadius={2}>
      <FormTitle title="Tipos Temporalidade" subtitle={formMode} />

      {formMode === 'LIST' ? (
        <ListTipoTemporalidade setData={setData} setFormMode={setFormMode} />
      ) : (
        <FormTipoTemporalidade
          data={formMode === 'EDIT' ? data : {}}
          formMode={formMode}
          setFormMode={setFormMode}
        />
      )}
    </Box>
  )
}
