import { useEffect, useState } from 'react'
import useToast from '../../../../hooks/toast/useToast'
//import { useUsuario } from '../../../hooks/usuario/useUsuario'
import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useDiretorio } from '../../../../hooks/diretorio/useDiretorio'
import { IDiretorio } from '../../../../services/diretorio/types'
import { Input } from '../../../../components/Form/Input'

type ModalDiretorioFormProps = {
  title?: string
  open: boolean
  handleClose: () => void
  diretorio?: IDiretorio
  id_setor: string
  id_diretorio_pai?: string
}

export function ModalDiretorioForm({
  open,
  handleClose,
  diretorio,
  id_diretorio_pai,
  id_setor
}: ModalDiretorioFormProps) {
  const { Toast, ToastError } = useToast()

  useState<string>('')

  const { mutateAsync: create } = useDiretorio.Create()
  const { mutateAsync: update } = useDiretorio.Update()
  const [descricao, setDescricao] = useState<string>(diretorio?.descricao ?? '')

  useEffect(() => {
    setDescricao(diretorio?.descricao ?? '')
  }, [diretorio])

  const onSubmit = async () => {
    if (descricao !== '') {
      const formData = {
        id_diretorio: diretorio?.id_diretorio,
        descricao: descricao,
        id_setor,
        id_diretorio_pai
      }
      if (!diretorio) {
        await Toast(
          create(formData),
          'CREATED',
          'Diretório criado com sucesso!'
        )
      } else {
        await Toast(
          update(formData),
          'UPDATED',
          'Diretório atualizado com sucesso!'
        )
      }

      handleClose()
    } else {
      ToastError('Preencha os campos obrigatórios!')
    }
  }

  return (
    <Modal
      open={open}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        top: '5%'
      }}
    >
      <Box
        borderRadius="4px"
        borderColor="lightgray"
        display={'flex'}
        flexDirection={'column'}
        sx={{
          bgcolor: 'background.paper',
          boxShadow: 24,
          width: 400,
          height: 190
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          bgcolor="#2835ad"
          px={2}
          sx={{
            borderTopRightRadius: '4px',
            borderTopLeftRadius: '4px'
          }}
        >
          <Typography color="#FFF" py={1.5} variant="subtitle1">
            {diretorio ? 'Editar Diretório' : 'Novo Diretório'}
          </Typography>

          <Tooltip title="Fechar" arrow>
            <IconButton onClick={() => handleClose()}>
              <CloseIcon sx={{ color: '#fff' }} />
            </IconButton>
          </Tooltip>
        </Box>

        <Box component="form" mt={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2
            }}
            mx={2}
            mb={2}
          >
            <Input
              label="Descricao"
              type="text"
              required={true}
              onChange={(event) => setDescricao(event.target.value)}
              value={descricao}
            />
          </Box>

          <Divider />

          <Stack direction="row" gap={2} p={2} justifyContent="flex-end">
            <Button
              variant="contained"
              onClick={() => onSubmit()}
              sx={{
                fontWeight: 600
              }}
            >
              Confirmar
            </Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  )
}
