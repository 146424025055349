import { Add } from '@mui/icons-material'
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material'

import { IServico } from '../../../../services/contrato/types'

interface ServicoSelectProps {
  data: Array<IServico>
  selectedItem: string
  addServicoCallback: () => void
  selectServicoCallback: (id: string) => void
  disableButtons?: boolean
}

export function ServicoSelect({
  data,
  selectedItem,
  addServicoCallback,
  selectServicoCallback,
  disableButtons = false
}: ServicoSelectProps) {
  return (
    <Box display={'flex'} flexDirection={'row'}>
      <FormControl size="small" sx={{ flex: '1' }}>
        <InputLabel id="label-servico">Serviço</InputLabel>
        <Select
          labelId="label-servico"
          value={selectedItem}
          label="Serviço"
          onChange={(event) => selectServicoCallback(event.target.value)}
          sx={{
            height: '2.5rem',
            borderRadius: '4px 0 0 4px'
          }}
        >
          <MenuItem value="" key="0">
            Selecione um Serviço
          </MenuItem>
          {data.map((servico) => {
            return (
              <MenuItem value={servico.id_servico} key={servico.id_servico}>
                {servico.nome}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
      <Button
        type="button"
        variant="outlined"
        disabled={disableButtons}
        onClick={addServicoCallback}
        sx={{ borderRadius: '0 4px 4px 0', height: '2.5rem' }}
      >
        <Add />
      </Button>
    </Box>
  )
}
