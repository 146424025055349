import { useMemo, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio
} from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/AddCircle'
import EditIcon from '@mui/icons-material/Edit'
import InactiveIcon from '@mui/icons-material/DoNotDisturb'
import ActiveIcon from '@mui/icons-material/ThumbUp'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import LockResetIcon from '@mui/icons-material/LockReset'
import useToast from '../../../hooks/toast/useToast'
import { FormMode } from '../../../types/formMode'
import { Loading } from '../../../components/Loading'
import React from 'react'
import { useUsuario } from '../../../hooks/usuario/useUsuario'
import api from '../../../services/api'
import { Dialog } from '../../../components/Dialog'
import { useContratoPermitido } from '../../../hooks/contratoPermitido/useContratoPermitido'
import { TAtivo } from '../../../utils/defaultApi/types'

interface IForm {
  setData: (value: React.SetStateAction<any>) => void
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

export function List({ setData, setFormMode }: IForm) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [userStatus, setuserStatus] = useState<TAtivo>('all')
  const [status, setStatus] = useState<TAtivo>('all')
  const [q, setQ] = useState<string>('')
  const [selectedOrder, setSelectedOrder] = useState<string>('')
  const [showDismiss, setShowDismiss] = useState<boolean>(false)
  const [selectedEmail, setSelectedEmail] = useState<string | null>(null)
  const { Toast, ToastSuccess, ToastError } = useToast()
  const searchInput = React.useRef<HTMLInputElement>(null)

  const handleSearchClick = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setSearchParams({ ...searchParams, q: searchTerm })
    setQ(searchTerm)
    setStatus(userStatus)
    searchInput.current?.focus()
  }

  const page = useMemo(() => {
    return Number(searchParams.get('page') ?? 1)
  }, [searchParams])

  const { mutateAsync: changeStatus } = useUsuario.changeStatus()
  const { data: contratos } = useContratoPermitido.FindByUsuarioLogado()
  //const { data: records, isLoading } = useUsuario.FindAll(page, q, status)
  const { data: usuario } = useUsuario.FindByUsername()

  const contracts = contratos
    ?.map((contrato) => contrato.id_empresa_contratante)
    .join(', ')

  const userEmpresa = usuario?.pessoa.setor.empresa?.id_empresa
  const { data: recordsContracts, isLoading: isLoadingContracts } =
    useUsuario.FindAllUsersByContract(
      page,
      contracts,
      userEmpresa,
      selectedOrder,
      q,
      status
    )

  const handleSendEmail = async () => {
    if (!selectedEmail) {
      ToastError('Email não encontrado para este usuário.')
      setShowDismiss(false)
      return
    }
    try {
      await api.post('/auth/email/', {
        email: selectedEmail
      })
      setShowDismiss(false)
      ToastSuccess('Email enviado com sucesso!')
    } catch (err) {
      ToastError('Ocorreu um erro ao enviar o email.')
    } finally {
      setShowDismiss(false)
    }
  }

  const handleNewForm = () => {
    setFormMode('CREATE')
  }

  const handleRemove = async (id: string, ativo: boolean) =>
    await Toast(
      changeStatus(id),
      undefined,
      `Usuário ${ativo ? 'Inativado' : 'Ativado'} com sucesso!`
    )

  const handleEditForm = (data: any) => {
    setData(data)
    setFormMode('EDIT')
  }

  if (isLoadingContracts) return <Loading />

  return (
    <Box>
      <Stack
        component="form"
        onSubmit={handleSearchClick}
        direction="row"
        py={3}
        gap={3}
      >
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          placeholder="Nome / Cargo / Username / Empresa / Setor"
          inputRef={searchInput}
          autoFocus
          value={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value)
          }}
        />

        <Button variant="outlined" startIcon={<SearchIcon />} type="submit">
          Pesquisar
        </Button>
      </Stack>
      <Stack mb={2}>
        <FormControl>
          <FormLabel id="status-usuario">Status Usuário</FormLabel>
          <RadioGroup
            row
            aria-labelledby="dados-pessoais-label"
            name="dados_pessoais"
            value={userStatus}
            onChange={(event) => {
              const value: TAtivo =
                String(event.target.value) === 'all'
                  ? 'all'
                  : String(event.target.value) === 'true'
                  ? true
                  : false
              setuserStatus(value)
            }}
          >
            <FormControlLabel value="true" control={<Radio />} label="Ativo" />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="Inativo"
            />
            <FormControlLabel value="all" control={<Radio />} label="Todos" />
          </RadioGroup>
        </FormControl>
      </Stack>

      <Divider />

      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleNewForm}
        sx={{ float: 'right', my: '12px' }}
      >
        Novo registro
      </Button>

      <TableContainer
        component={Box}
        border={1}
        borderRadius={2}
        borderColor="#f2f2f2"
      >
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  selectedOrder === 'Nome'
                    ? setSelectedOrder('NomeInv')
                    : setSelectedOrder('Nome')
                }}
              >
                Nome
                {selectedOrder === 'Nome' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  selectedOrder === 'Cargo'
                    ? setSelectedOrder('CargoInv')
                    : setSelectedOrder('Cargo')
                }}
              >
                Cargo
                {selectedOrder === 'Cargo' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  selectedOrder === 'Username'
                    ? setSelectedOrder('UsernameInv')
                    : setSelectedOrder('Username')
                }}
              >
                Username
                {selectedOrder === 'Username' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  selectedOrder === 'Empresa'
                    ? setSelectedOrder('EmpresaInv')
                    : setSelectedOrder('Empresa')
                }}
              >
                Empresa
                {selectedOrder === 'Empresa' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  selectedOrder === 'Setor'
                    ? setSelectedOrder('SetorInv')
                    : setSelectedOrder('Setor')
                }}
              >
                Setor
                {selectedOrder === 'Setor' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>
              <TableCell width="12px" />
              <TableCell width="12px" />
              <TableCell width="12px" />
            </TableRow>
          </TableHead>
          <TableBody>
            {recordsContracts?.data?.map((record) => (
              <TableRow key={record.id_usuario} hover>
                <TableCell>{record.pessoa?.nome}</TableCell>
                <TableCell>{record.pessoa?.cargo}</TableCell>
                <TableCell>{record.username}</TableCell>
                <TableCell>{record.pessoa?.setor?.empresa?.nome}</TableCell>
                <TableCell>{record.pessoa?.setor?.nome}</TableCell>

                <TableCell>
                  {/* <Tooltip title="Redefinir Senha" arrow> */}
                  <Tooltip title={'Redefinir Senha'}>
                    <Button
                      variant="outlined"
                      sx={{ minWidth: '24px', padding: '6px' }}
                      onClick={() => {
                        setSelectedEmail(record.pessoa?.email),
                          setShowDismiss(true)
                      }}
                      disabled={!record.ativo}
                    >
                      <LockResetIcon />
                    </Button>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {/* <Tooltip title="Editar" arrow> */}
                  <Button
                    variant="outlined"
                    sx={{ minWidth: '24px', padding: '6px' }}
                    onClick={() => handleEditForm(record)}
                    disabled={!record.ativo}
                  >
                    <EditIcon />
                  </Button>
                  {/* </Tooltip> */}
                </TableCell>
                <TableCell>
                  <Tooltip
                    title={record.ativo ? 'Inativar Usuário' : 'Ativar Usuário'}
                    arrow
                  >
                    <Button
                      variant="outlined"
                      color={record.ativo ? 'secondary' : 'success'}
                      onClick={() =>
                        handleRemove(record.id_usuario, record.ativo)
                      }
                      sx={{ minWidth: '24px', padding: '6px' }}
                    >
                      {record.ativo ? <InactiveIcon /> : <ActiveIcon />}
                    </Button>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {recordsContracts?.pagination &&
        recordsContracts.pagination.totalItems >
          recordsContracts.pagination.itemsPerPage && (
          <Pagination
            count={recordsContracts?.pagination.totalPages}
            sx={{ marginTop: '12px' }}
            onChange={(_, newPage) =>
              setSearchParams({ page: String(newPage) })
            }
            page={recordsContracts.pagination.currentPage}
          />
        )}
      <Dialog
        handleAnswerNo={() => {
          setShowDismiss(false)
        }}
        handleAnswerYes={() => {
          handleSendEmail()
        }}
        messageText={
          <>
            <p>
              Você tem certeza de que deseja redefinir a senha do usuário? Se
              confirmar, a senha atual será alterada e um e-mail com instruções
              para criar uma nova senha será enviado ao usuário.
            </p>
            <p>
              <strong>IMPORTANTE: </strong>Esta ação não poderá ser desfeita.
            </p>
          </>
        }
        title="Atenção"
        openDialog={showDismiss}
      />
    </Box>
  )
}
