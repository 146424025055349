import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ToastContainer } from 'react-toastify'
import { GoogleOAuthProvider } from '@react-oauth/google'
import 'react-toastify/dist/ReactToastify.css'

import customTheme from './theme'
import { AppRoutes } from './routes'
import AppProvider from './hooks'

const queryClient = new QueryClient()

function App() {
  //const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || ''
  const googleClientId =
    '365997794437-olcahd92b5477bcdbof14rtchr268q4d.apps.googleusercontent.com'
  return (
    <QueryClientProvider client={queryClient}>
      <GoogleOAuthProvider clientId={googleClientId}>
        <ThemeProvider theme={customTheme}>
          <CssBaseline />
          <AppProvider>
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
          </AppProvider>
          <ToastContainer />
        </ThemeProvider>
      </GoogleOAuthProvider>
      <ReactQueryDevtools initialIsOpen />
    </QueryClientProvider>
  )
}

export default App
