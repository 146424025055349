import { DefaultApi } from '../../utils/defaultApi'
import { IUf } from './types'

const endpoint = '/uf'
const resourceId = 'id_uf'

const apiUf = new DefaultApi<IUf>(endpoint, resourceId)

export const ApiUf = {
  findAll: apiUf.findAll,
  listAll: apiUf.listAll
}
