import { Routes, Route, Navigate } from 'react-router-dom'

import { Login } from '../pages/Login'
import { SendRecoverEmail } from '../pages/EnvioEmailRecuperacao'
import { PasswordRecover } from '../pages/RecuperacaoDados'
import { ModifyPassword } from '../pages/ModificarSenha'
import { DocumentosComaprtilhados } from '../pages/DocumentosCompartilhados'

export function PublicRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/email-recover" element={<SendRecoverEmail />} />
      <Route path="/password-recover" element={<PasswordRecover />} />
      <Route path="/modify-password" element={<ModifyPassword />} />
      <Route
        path="/documentos-compartilhados/:id_compartilhamento/:id_documento"
        element={<DocumentosComaprtilhados />}
      />
      <Route path="/*" element={<Navigate to="/login" />} />
    </Routes>
  )
}
