export const anonimizacao = (content: string, length: number): string => {
  if (content.length <= length) {
    let ret = ''
    for (let i = 0; i < content.length; i++) {
      ret += '*'
    }
    return ret
  }
  let ret = content.substring(0, length)
  for (let i = length; i < content.length; i++) {
    ret += '*'
  }
  return ret
}
