import { ITipoDocumentosPorPagina } from '../../../../../services/relatorio/types'
import { TemplateReport } from '../../../../../components/Report'
import { ApiEmpresa } from '../../../../../services/empresa'
import { format } from 'date-fns'
import { ApiTipoDocumento } from '../../../../../services/tipoDocumento'
import { getCodigo } from '../../../../../utils/getCodigo'
import { ApiSetor } from '../../../../../services/setor'

export interface FiltrosProps {
  id_empresa: string
  data_inicio?: Date
  data_fim?: Date
  id_tipo_documento?: string
  id_setor?: string
}

type Props = {
  id_usuario: string
  data: ITipoDocumentosPorPagina[]
  filtros: FiltrosProps
  mode?: 'open' | 'save'
}

export async function TipoDocumentosPorPaginaReport({
  data,
  mode,
  id_usuario,
  filtros
}: Props) {
  const filterReportText = async ({
    id_empresa,
    data_fim,
    data_inicio,
    id_tipo_documento,
    id_setor
  }: FiltrosProps) => {
    const filtersReport = []
    filtersReport.push({ text: 'Empresa: ', style: ['filterText', 'bold'] })
    if (id_empresa) {
      const empresa = await ApiEmpresa.findOne(id_empresa)
      if (empresa) {
        filtersReport.push({
          text: empresa.razao_social,
          style: ['filterText']
        })
      }
    } else filtersReport.push({ text: '<todas> ', style: ['filterText'] })
    filtersReport.push({ text: '\n' })
    filtersReport.push({ text: 'Setor: ', style: ['filterText', 'bold'] })
    if (id_setor) {
      const setor = await ApiSetor.findOne(id_setor)
      if (setor) {
        filtersReport.push({
          text: setor.nome,
          style: ['filterText']
        })
      }
    } else filtersReport.push({ text: '<todos> ', style: ['filterText'] })

    filtersReport.push({ text: '\n' })
    filtersReport.push({
      text: 'Tipo de Documento: ',
      style: ['filterText', 'bold']
    })
    if (id_tipo_documento) {
      const tipoDocumento = await ApiTipoDocumento.findOne(id_tipo_documento)
      if (tipoDocumento) {
        filtersReport.push({
          text: tipoDocumento.descricao,
          style: ['filterText']
        })
      }
    } else filtersReport.push({ text: '<todos> ', style: ['filterText'] })
    filtersReport.push({ text: '\n' })

    filtersReport.push({ text: 'Período: ', style: ['filterText', 'bold'] })
    if (data_inicio && data_fim) {
      filtersReport.push({
        text: `${format(new Date(data_inicio), 'dd/MM/yyyy')} até ${format(
          new Date(data_fim),
          'dd/MM/yyyy'
        )}`,
        style: ['filterText']
      })
    } else filtersReport.push({ text: '<global> ', style: ['filterText'] })

    return filtersReport
  }
  let totalPaginas = 0
  let totalDocumentos = 0
  const tipoDocumentosPorPaginaTable = () => {
    let rows: Array<any> = []
    if (data && data.length > 0) {
      rows = [
        [
          { text: 'Id', style: 'columnTitle' },
          { text: 'Data Criação', style: 'columnTitle' },
          { text: 'Tipo de Documento', style: 'columnTitle' },
          { text: 'Setor', style: 'columnTitle' },
          { text: 'Qtde Arquivos', style: 'columnTitle' },
          { text: 'Qtde Imagens', style: 'columnTitle' }
        ]
      ]
      data.forEach((tipoDocumento) => {
        const row = [
          { text: getCodigo(tipoDocumento.id_tipo_documento), style: 'row' },
          {
            text: format(
              new Date(tipoDocumento.tipodocumento_created_at),
              'dd/MM/yyyy'
            ),
            style: 'rowCenter'
          },
          { text: tipoDocumento.tipodocumento_descricao, style: 'row' },
          { text: tipoDocumento.setor_nome, style: 'row' },
          {
            text: tipoDocumento.qtde_documentos,
            style: 'quantityDoc'
          },
          { text: tipoDocumento.qtde_paginas, style: 'quantityDoc' }
        ]
        totalPaginas += tipoDocumento.qtde_paginas
        totalDocumentos += tipoDocumento.qtde_documentos
        rows.push(row as any)
      })
      return [
        {
          text: `Empresa: ${data[0].empresa_nome} \nRazão Social: ${data[0].empresa_razao_social}`,
          style: 'subtitle'
        },
        {
          table: {
            widths: [75, 60, '*', 80, 70, 50],
            headerRows: 1,
            body: rows
          }
        },
        {
          text: `Total de Arquivos: ${totalDocumentos}`,
          style: 'total'
        },
        {
          text: `Total de Imagens: ${totalPaginas}`,
          style: 'total'
        }
      ]
    } else {
      return [{ text: '\n' }]
    }
  }

  const filterText = await filterReportText(filtros)
  const content = tipoDocumentosPorPaginaTable()

  await TemplateReport({
    id_usuario,
    content,
    filterText,
    reportTitle: 'Tipos de Documentos / Total Imagens',
    mode
  })
}
