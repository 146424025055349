import { ButtonHTMLAttributes, ElementType } from 'react'

interface DashboadWidgetSummaryHeaderToolbarActionProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: ElementType
}

export function DashboadWidgetSummaryHeaderToolbarAction({
  icon: Icon,
  ...rest
}: DashboadWidgetSummaryHeaderToolbarActionProps) {
  return (
    <button
      {...rest}
      style={{
        width: '24px',
        height: '24px',
        border: '0px',
        borderRadius: '12px',
        backgroundColor: 'white',
        padding: 0,
        margin: 0,
        textAlign: 'center'
      }}
    >
      <Icon sx={{ width: '18px', height: '18px', color: '#969696' }} />
    </button>
  )
}
