// import { ReactNode } from 'react'
import { IResultadoXML } from '../@types/IResultadoXML'
import { XMLParser } from 'fast-xml-parser'
import { ICaixaImportar } from '../@types/ICaixaImportar'
import { ICaixa } from '../../../../services/caixa/types'
import { IDocumentoImportar } from '../@types/IDocumentosImportar'
import { IMetadadoImportar } from '../@types/IMetadadoImportar'
import { ITipoDocumento } from '../../../../services/tipoDocumento/types'
import { IContrato } from '../../../../services/contrato/types'

interface readDocumentsXMLProps {
  content: string
  fileName: string
  contratoByCodigo: (codigo: string) => Promise<IContrato | undefined>
  caixaByCodigo: (codigo: string) => Promise<ICaixa | undefined>
  tipoDocumentoByCodigo: (codigo: string) => Promise<ITipoDocumento | undefined>
}

const compareList = (list1: Array<string>, list2: Array<string>): boolean => {
  let itemsList1 = true
  list1.forEach((item1) => {
    itemsList1 = itemsList1 && !!list2.find((item2) => item2 === item1)
  })

  let itemsList2 = true
  list2.forEach((item2) => {
    itemsList2 = itemsList2 && !!list1.find((item1) => item2 === item1)
  })

  return itemsList1 && itemsList2
}

/**
 * Lê um arquivo XML para importação de documentos.
 *
 * Este procedimentos lê um arquivo XML faz o parsing, valida dados (IDs das
 * caixas e IDs dos tipos de arquivos), gera a lista de ações tomadas, a lista
 * de erros, gera lista de alertas e a descrição dos documentos para importação
 * @param fileName Nome do arquivo a ser lido
 */
export const readDocumentsXML = async ({
  content,
  fileName,
  caixaByCodigo,
  tipoDocumentoByCodigo,
  contratoByCodigo
}: readDocumentsXMLProps): Promise<IResultadoXML> => {
  // Recuperação dos dados do arquivo selecionado
  const newFileName = fileName

  const result: IResultadoXML = {
    alertas: [],
    erros: [],
    listaDescricoes: [],
    processamento: [],
    totalDocumentos: 0,
    totalCaixas: 0,
    fileName: newFileName,
    documentos: [],
    caixas: []
  }

  result.processamento = ['Validação de tipo de arquivo.']

  // Definição do evento para lidar com o processamento do arquivo
  // (após o completo carregamento do arquivo)

  // Carregamento do conteúdo

  // Parseamento do XML para objeto Javascript (INÍCIO)
  const options = {
    ignoreAttributes: false
  }

  const xmlParser = new XMLParser(options)
  const xmlObject = xmlParser.parse(content)
  // Parseamento do XML para objeto Javascript (FIM)

  //Carregamento e validação da estrutura inicial do XML (INÍCIO)
  let projeto: any = null
  let codigoContrato = ''
  let contrato: IContrato | undefined
  result.processamento = [...result.processamento, 'Validação de contrato']
  try {
    projeto = xmlObject['Projeto']
    codigoContrato = projeto['@_Numero']
    contrato = await contratoByCodigo(codigoContrato.toLowerCase())

    if (!contrato) {
      result.erros = [
        {
          descricao: 'Contrato inválido',
          lista: []
        }
      ]
      return result
    }
  } catch (err) {
    result.erros = [
      {
        descricao: 'Formato de XML inválido',
        lista: []
      }
    ]
    return result
  }

  let caixasImportar: Array<any> = []

  try {
    caixasImportar = Array.isArray(projeto['Caixa'])
      ? projeto['Caixa']
      : [projeto['Caixa']]
  } catch (err) {
    if (err instanceof TypeError) {
      caixasImportar = [projeto['Caixa']]
    } else {
      result.erros = [
        ...result.erros,
        {
          descricao: `XML mal formado (processando caixas)`,
          lista: <span>{String(err)}</span>
        }
      ]
    }
  }
  //Carregamento e validação da estrutura inicial do XML (FIM)

  result.processamento = [...result.processamento, 'Validação de caixas']

  // Loop de validação de todas as caixas
  for (const caixaImportar of caixasImportar) {
    // Validação dos dados da caixa (INICIO)
    const codigoCaixa: string = caixaImportar['@_Numero']
    const caixa = await caixaByCodigo(codigoCaixa.toLowerCase())
    if (!caixa) {
      result.erros = [
        ...result.erros,
        {
          descricao: `Caixa não cadastrada (${codigoCaixa})`,
          lista: []
        }
      ]
      continue
    }
    if (caixa && caixa.id_contrato !== contrato.id_contrato) {
      result.erros = [
        ...result.erros,
        {
          descricao:
            `Caixa não cadastrada (${codigoCaixa}) ` +
            `no contrato (${codigoContrato})`,
          lista: []
        }
      ]
      continue
    }
    if (!!caixa.documentos && caixa.documentos.length > 0) {
      result.alertas = [
        ...result.alertas,
        {
          descricao: `Caixa já possui documentos`,
          lista: <span>{codigoCaixa}</span>
        }
      ]
    }
    // Validação dos dados da caixa (FIM)

    let documentos: IDocumentoImportar[] = []

    // Carregamentos dos documentos da caixa atual (INICIO)
    let documentosImportas: Array<any> = []

    try {
      Array.isArray(caixaImportar['Documento'])
      documentosImportas = Array.isArray(caixaImportar['Documento'])
        ? caixaImportar['Documento']
        : [caixaImportar['Documento']]
    } catch (err) {
      if (err instanceof TypeError) {
        documentosImportas = [caixaImportar['Documento']]
      } else {
        result.erros = [
          ...result.erros,
          {
            descricao: `XML inválido(Caixa ${codigoCaixa})`,
            lista: <span>{String(err)}</span>
          }
        ]
        continue
      }
    }
    // Carregamentos dos documentos da caixa atual (FIM)
    let countDocumentos = 0

    // Validação dos documentos da caixa atual (INICIO)
    for (const documentoImportar of documentosImportas) {
      countDocumentos++
      try {
        // Leitura dos dados do documento atual (INICIO)
        const tipoDocumentoImportar: string =
          documentoImportar['@_TipoDocumento']
        const numeroDocumentoImportar: string = documentoImportar['@_Numero']
        const imagensImportar: number = documentoImportar['@_Imagens'] ?? 0
        const caminhoImportar: string = documentoImportar['Caminhopdf'] ?? ''
        const metadadosImportar: IMetadadoImportar[] = Object.entries(
          documentoImportar['Indices']
        ).map(([key, value]) => {
          return {
            chave: String(key),
            valor: String(value)
          }
        })
        // Leitura dos dados do documento atual (FIM)

        // Validação do tipo de documento atual (INICIO)
        const tpDocumento = await tipoDocumentoByCodigo(
          tipoDocumentoImportar.toLocaleLowerCase()
        )

        if (!tpDocumento) {
          result.erros = [
            ...result.erros,
            {
              descricao:
                `O tipo de documento (${tipoDocumentoImportar}), ` +
                `no documento ${numeroDocumentoImportar} , é inválido`,
              lista: []
            }
          ]
          continue
        }
        let descricaoTpDocumento = result.listaDescricoes.find(
          (descr) =>
            descr.tipoDocumento.id_tipo_documento ===
            tpDocumento.id_tipo_documento
        )
        const chavesImportar = metadadosImportar.map((item) => item.chave)

        if (descricaoTpDocumento) {
          if (!compareList(chavesImportar, descricaoTpDocumento.chaves)) {
            result.erros = [
              ...result.erros,
              {
                descricao:
                  `O tipo de documento (${tipoDocumentoImportar}) ` +
                  `está associado a documentos com diferentes coleções de` +
                  ` índices.` +
                  ` Divergência encontrada no documento ` +
                  `(${numeroDocumentoImportar})`,
                lista: []
              }
            ]
            continue
          }
        }

        if (!descricaoTpDocumento) {
          descricaoTpDocumento = {
            tipoDocumento: {
              ...tpDocumento
            },
            chaves: [...chavesImportar],
            descricao: []
          }
          result.listaDescricoes = [
            ...result.listaDescricoes,
            descricaoTpDocumento
          ]
        }
        // Validação do tipo de documento atual (FIM)
        const newDocumento: IDocumentoImportar = {
          caminho: caminhoImportar,
          dadosArquivo: new Blob([]),
          numero: numeroDocumentoImportar,
          metadados: [...metadadosImportar],
          importar: 0,
          documento: {
            nr_paginas: imagensImportar,
            dados_criancas_adolescentes: caixa.dados_criancas_adolescentes,
            dados_pessoais: caixa.dados_pessoais,
            dados_pessoais_sensiveis: caixa.dados_pessoais_sensiveis,
            id_caixa: caixa.id_caixa ?? '',
            id_contrato: caixa.id_contrato,
            id_tipo_documento: tpDocumento.id_tipo_documento ?? '',
            descricao: '', //Essa propriedade deve ser montada posteriormente
            metadados: [...metadadosImportar],
            id_documento: numeroDocumentoImportar,
            tipo_documento: {
              ...tpDocumento
            }
          }
        }
        documentos = [...documentos, { ...newDocumento }]
        result.totalDocumentos++
      } catch (err) {
        result.erros = [
          ...result.erros,
          {
            descricao:
              `Há erro no XML, no documento ${countDocumentos} ` +
              `da caixa ${codigoCaixa}`,
            lista: []
          }
        ]
        continue
      }
    }

    const newCaixaImportar: ICaixaImportar = {
      local: caixaImportar['@_Local'],
      numero: caixaImportar['@_Numero'],
      setor: caixaImportar['@_Setor'],
      documentos: [...documentos],
      caixa: {
        ...caixa,
        id_caixa: caixa.id_caixa ?? ''
      }
    }
    result.caixas = [...result.caixas, { ...newCaixaImportar }]
    result.documentos = [...result.documentos, ...documentos]
    result.totalCaixas++
  }
  // Validação dos documentos da caixa atual (FIM)

  if (result.totalDocumentos > 5000) {
    result.erros = [
      {
        descricao:
          'A quantidade máxima de documentos a ' +
          'serem importados por XML é de 5000 ' +
          `(quantidade identificada: ${result.totalDocumentos})`,
        lista: []
      }
    ]
    return result
  }
  result.processamento = [
    ...result.processamento,
    `XML lido`,
    `Total de caixas: ${result.totalCaixas}`,
    `Total de documentos: ${result.totalDocumentos}`
  ]
  return result
}
