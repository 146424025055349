import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ApiLote } from '../../services/lote'

const QUERY_KEY = 'qkLote'
const QUERY_KEY_DASHBOARD = 'qkDashboard'

const LotesByIdRequisicao = (id_requisicao: string) => {
  return useQuery([QUERY_KEY, id_requisicao], () =>
    ApiLote.lotesByIdRequisicao(id_requisicao)
  )
}

const ListAll = () => {
  return useQuery(QUERY_KEY, ApiLote.listAll)
}

const Create = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiLote.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
      queryClient.invalidateQueries(QUERY_KEY_DASHBOARD)
    }
  })
}

const Update = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiLote.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
      queryClient.invalidateQueries(QUERY_KEY_DASHBOARD)
    }
  })
}

// const Remove = () => {
//   const queryClient = useQueryClient()

//   return useMutation(ApiLote.remove, {
//     onSuccess: () => {
//       queryClient.invalidateQueries(QUERY_KEY)
//     }
//   })
// }

export const useLote = {
  Create,
  // FindAll,
  ListAll,
  LotesByIdRequisicao,
  Update
  // Remove
}
