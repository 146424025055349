import { useQuery } from 'react-query'
import { ApiEstante } from '../../services/estante'

const QUERY_KEY = 'qkEstante'

const ListAll = () => {
  return useQuery([QUERY_KEY], ApiEstante.listAll)
}

const FindByCorredor = (id: string) => {
  return useQuery([QUERY_KEY, id], () => ApiEstante.findByCorredor(id))
}

export const useEstante = {
  ListAll,
  FindByCorredor
}
