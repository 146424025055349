import { DefaultApi } from '../../utils/defaultApi'
import api from '../api'
import { IEmpresa } from './types'
import { TAtivo, IResult } from '../../utils/defaultApi/types'
import { IContratoPermitidoUsuario } from '../contratoPermitido/types'

const endpoint = '/empresa'
const resourceId = 'id_empresa'

export class ServiceEmpresa extends DefaultApi<IEmpresa> {
  public async listAllXSolution() {
    const { data } = await api.get<IEmpresa[]>(
      `${this.endpoint}/lista/x-solution`
    )
    return data
  }

  findAllByUserContratos = async (
    page: number,
    userContratos: IContratoPermitidoUsuario[],
    q?: string,
    ativo: TAtivo = true
  ): Promise<IResult<IEmpresa>> => {
    const pageString = page ? `page=${page}` : ''
    const qString = q ? `q=${q}` : ''
    const ativoString = ativo !== undefined ? `ativo=${ativo}` : ''
    const params = [pageString, qString, ativoString]
      .filter((value) => value !== '')
      .join('&')

    const contratosParam = userContratos.map((uc) => uc.id_contrato).join(',')

    const { data } = await api.get<IResult<IEmpresa>>(
      `${this.endpoint}/contratos-usuario?${params}&contratos=${contratosParam}`
    )
    return data
  }

  findAllByContratoPermitidos = async () => {
    const { data } = await api.get<IEmpresa[]>(
      `${this.endpoint}/contratos-permitidos`
    )
    return data
  }
}

const apiEmpresa = new ServiceEmpresa(endpoint, resourceId)

export const ApiEmpresa = {
  ...apiEmpresa,
  listAllXSolution: apiEmpresa.listAllXSolution,
  findAllByUserContratos: apiEmpresa.findAllByUserContratos
}
