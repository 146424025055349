import { DefaultApi } from '../../../utils/defaultApi'
import { IResult, TAtivo } from '../../../utils/defaultApi/types'
import api from '../../api'
import { IReceitaDespesa } from './types'

const endpoint = '/financeiro/receitas-despesas'
const resourceId = 'id_receita_despesa'

class ServiceReceitaDespesa extends DefaultApi<IReceitaDespesa> {
  findAllByTipo = async (
    page: number,
    q?: string,
    ativo: TAtivo = true,
    tipo_relacionamento?: string
  ): Promise<IResult<IReceitaDespesa>> => {
    const pageString = page ? `page=${page}` : ''
    const qString = q ? `q=${q}` : ''
    const ativoString = ativo !== undefined ? `ativo=${ativo}` : ''
    const params = [pageString, qString, ativoString, tipo_relacionamento]
      .filter((value) => value !== '')
      .join('&')

    const { data } = await api.get<IResult<IReceitaDespesa>>(
      `${this.endpoint}/${
        tipo_relacionamento === 'R' ? 'receita' : 'despesa'
      }?${params}`
    )
    return data
  }

  listAllByTipo = async (tipo: string) => {
    const { data } = await api.get<IReceitaDespesa[]>(
      `${endpoint}/lista/${
        tipo === '' ? '' : tipo === 'R' ? 'receita' : 'despesa'
      }`
    )
    return data
  }
}

const apiReceitaDespesa = new ServiceReceitaDespesa(endpoint, resourceId)

export const ApiReceitaDespesa = {
  ...apiReceitaDespesa,
  findAllByTipo: apiReceitaDespesa.findAllByTipo,
  listAllByTipo: apiReceitaDespesa.listAllByTipo
}
