import { useState } from 'react'
import { Box, Button, Stack, Checkbox, FormControlLabel } from '@mui/material'
import SaveIcon from '@mui/icons-material/CheckCircleOutlined'
import CancelIcon from '@mui/icons-material/DoDisturbAltOutlined'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import useToast from '../../../hooks/toast/useToast'
import { useNavigate } from 'react-router-dom'
import { IConfigArmazenamento } from '../../../services/configuracaoArmazenamento/types'
import { Input } from '../../../components/Form/Input'
import msg from '../../../utils/validationMessages'
import { Select } from '../../../components/Form/Select'
import { FormMode } from '../../../types/formMode'
import yup from '../../../types/yup.string.d'
import { ComponentGroup } from '../../../components/ComponentGroup'

const schema = yup
  .object({
    auditoria: yup.object({
      ip: yup.string().required(msg.REQUIRED).min(5, msg.MIN(5)),
      porta: yup.string().required(msg.REQUIRED).min(5, msg.MIN(5)),
      usuario: yup.string().required(msg.REQUIRED).min(5, msg.MIN(5)),
      senha: yup.string().required(msg.REQUIRED).min(5, msg.MIN(5))
    }),
    negocio: yup.object({
      ip: yup.string().required(msg.REQUIRED).min(5, msg.MIN(5)),
      porta: yup.string().required(msg.REQUIRED).min(5, msg.MIN(5)),
      usuario: yup.string().required(msg.REQUIRED).min(5, msg.MIN(5)),
      senha: yup.string().required(msg.REQUIRED).min(5, msg.MIN(5))
    })
  })
  .required()

interface IForm {
  data: any
  formMode: FormMode
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

export function Form({ setFormMode }: IForm) {
  const [detalhamentoAuditoria, setDetalhamentoAuditoria] =
    useState<string>('MÉDIO')
  const [detalhamentoNegocio, setDetalhamentoNegocio] =
    useState<string>('MÉDIO')
  const { register } = useForm<IConfigArmazenamento>({
    resolver: yupResolver(schema)
  })
  const { ToastCreatedData } = useToast()
  const navigate = useNavigate()

  function handleSubmit() {
    ToastCreatedData()
    navigate('/dashboard')
  }

  function handleNivelAuditoriaChange(nivel: string) {
    setDetalhamentoAuditoria(nivel)
  }

  function handleNivelNegocioChange(nivel: string) {
    setDetalhamentoNegocio(nivel)
  }

  return (
    <Box component="form" mt={4}>
      <Stack gap={2}>
        <ComponentGroup title={'Dados de Auditoria'}>
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Habilitar"
          />
          <Stack gap={2}>
            <Input
              label="IP*"
              {...register('auditoria.ip')}
              defaultValue={'201.76.150.59'}
            />

            <Input
              label="Porta*"
              {...register('auditoria.porta')}
              defaultValue={'5601'}
            />

            <Input label="Usuário*" {...register('auditoria.usuario')} />

            <Input label="Senha*" {...register('auditoria.senha')} isPassword />

            <Select
              label="Nível de Detalhamento"
              placeholder="Nível"
              data={[{ nome: 'ALTO' }, { nome: 'MÉDIO' }, { nome: 'BAIXO' }]}
              fields={['nome', 'nome']}
              value={detalhamentoAuditoria}
              //{...register('endereco.id_municipio')}
              onChange={(event) =>
                handleNivelAuditoriaChange(String(event.target.value))
              }
              //defaultValue={data?.endereco?.municipio?.id_municipio}
              //message={errors.endereco?.id_municipio?.message}
            />
          </Stack>
        </ComponentGroup>
        <ComponentGroup title={'Dados de Negócio do Repositório'}>
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Habilitar"
          />
          <Stack gap={2}>
            <Input
              label="IP*"
              {...register('negocio.ip')}
              defaultValue={'201.76.150.59'}
            />

            <Input
              label="Porta*"
              {...register('negocio.porta')}
              defaultValue={'5601'}
            />

            <Input label="Usuário*" {...register('negocio.usuario')} />

            <Input label="Senha*" {...register('negocio.senha')} isPassword />

            <Select
              label="Nível de Detalhamento"
              placeholder="Nível"
              data={[{ nome: 'ALTO' }, { nome: 'MÉDIO' }, { nome: 'BAIXO' }]}
              fields={['nome', 'nome']}
              value={detalhamentoNegocio}
              //{...register('endereco.id_municipio')}
              onChange={(event) =>
                handleNivelNegocioChange(String(event.target.value))
              }
              //defaultValue={data?.endereco?.municipio?.id_municipio}
              //message={errors.endereco?.id_municipio?.message}
            />
          </Stack>
        </ComponentGroup>
      </Stack>

      <Stack direction="row" gap={2} mt={3}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<CancelIcon />}
          onClick={() => setFormMode('LIST')}
        >
          Cancelar
        </Button>

        <Button
          variant="contained"
          startIcon={<SaveIcon />}
          sx={{ width: '110px' }}
          onClick={() => handleSubmit()}
        >
          Salvar
        </Button>
      </Stack>
    </Box>
  )
}
