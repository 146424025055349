import { useQuery } from 'react-query'
import { ApiCorredor } from '../../services/corredor'

const QUERY_KEY = 'qkCorredor'

const ListAll = () => {
  return useQuery([QUERY_KEY], ApiCorredor.listAll)
}

const FindByCentro = (id_centrodocumentacao: string) => {
  return useQuery([QUERY_KEY, id_centrodocumentacao], () =>
    ApiCorredor.findByCentro(id_centrodocumentacao)
  )
}

export const useCorredor = {
  ListAll,
  FindByCentro
}
