import { useState } from 'react'
import { Box, Button, Stack, Checkbox, FormControlLabel } from '@mui/material'
import SaveIcon from '@mui/icons-material/CheckCircleOutlined'
import CancelIcon from '@mui/icons-material/DoDisturbAltOutlined'

import useToast from '../../../hooks/toast/useToast'
import { useNavigate } from 'react-router-dom'
import { Input } from '../../../components/Form/Input'
import { FormMode } from '../../../types/formMode'
import { ComponentGroup } from '../../../components/ComponentGroup'

interface IForm {
  data: any
  formMode: FormMode
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

export function Form({ setFormMode }: IForm) {
  const [enableLdap, setEnableLdap] = useState(false)
  const [enableAzure, setEnableAzure] = useState(false)
  const [enableAd, setEnableAd] = useState(false)
  const [enableGov, setEnableGov] = useState(false)
  const [enableWebDAV, setEnableWebDAV] = useState(false)
  const { ToastUpdatedData } = useToast()
  const navigate = useNavigate()

  function handleSubmit() {
    ToastUpdatedData()
    navigate('/dashboard')
  }

  return (
    <Box component="form" mt={4}>
      <Stack gap={2}>
        <ComponentGroup title={'Configuração LDAP'}>
          <FormControlLabel
            control={<Checkbox onClick={() => setEnableLdap(!enableLdap)} />}
            label="Habilitar"
          />
          <Stack gap={2}>
            <Input label="URL" disabled={!enableLdap} />
            <Input label="Porta" disabled={!enableLdap} />
            <Input label="DN de base" disabled={!enableLdap} />
            <Input label="Prefixo de usuário" disabled={!enableLdap} />
            <Input label="Usuário" disabled={!enableLdap} />
            <Input label="Senha" disabled={!enableLdap} />
            <Input label="Chave" disabled={!enableLdap} />
          </Stack>
        </ComponentGroup>

        <ComponentGroup title={'Configuração AZURE'}>
          <FormControlLabel
            control={<Checkbox onClick={() => setEnableAzure(!enableAzure)} />}
            label="Habilitar"
          />
          <Stack gap={2}>
            <Input label="URL" disabled={!enableAzure} />
            <Input label="Porta" disabled={!enableAzure} />
            <Input label="Usuário" disabled={!enableAzure} />
            <Input label="Senha" disabled={!enableAzure} isPassword />
            <Input label="Chave" disabled={!enableAzure} />
          </Stack>
        </ComponentGroup>

        <ComponentGroup title={'Configuração Active Directory'}>
          <FormControlLabel
            control={<Checkbox onClick={() => setEnableAd(!enableAd)} />}
            label="Habilitar"
          />
          <Stack gap={2}>
            <Input label="URL" disabled={!enableAd} />
            <Input label="Porta" disabled={!enableAd} />
            <Input label="Usuário" disabled={!enableAd} />
            <Input label="Senha" disabled={!enableAd} isPassword />
            <Input label="Chave" disabled={!enableAd} />
          </Stack>
        </ComponentGroup>

        <ComponentGroup title={'Configuração Gov'}>
          <FormControlLabel
            control={<Checkbox onClick={() => setEnableGov(!enableGov)} />}
            label="Habilitar"
          />
          <Stack gap={2}>
            <Input label="URL" disabled={!enableGov} />
            <Input label="Porta" disabled={!enableGov} />
            <Input label="Usuário" disabled={!enableGov} />
            <Input label="Senha" disabled={!enableGov} isPassword />
            <Input label="Chave" disabled={!enableGov} />
          </Stack>
        </ComponentGroup>
        <ComponentGroup title={'Configuração WebDAV'}>
          <FormControlLabel
            control={
              <Checkbox onClick={() => setEnableWebDAV(!enableWebDAV)} />
            }
            label="Habilitar"
          />
          <Stack gap={2}>
            <Input label="Porta de Serviço" disabled={!enableWebDAV} />
            <Input label="Porta de Serviço (SSL)" disabled={!enableWebDAV} />
          </Stack>
        </ComponentGroup>
      </Stack>

      <Stack direction="row" gap={2} mt={3}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<CancelIcon />}
          onClick={() => setFormMode('LIST')}
        >
          Cancelar
        </Button>

        <Button
          variant="contained"
          startIcon={<SaveIcon />}
          sx={{ width: '110px' }}
          onClick={() => handleSubmit()}
        >
          Salvar
        </Button>
      </Stack>
    </Box>
  )
}
