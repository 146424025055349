import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material'
import { ReactNode } from 'react'

interface IMenssagemValidacao {
  descricao: string
  lista: ReactNode
}

interface LogDialogProps {
  showDialog: boolean
  log: Array<IMenssagemValidacao>
  informacoesAdicionais?: Array<IMenssagemValidacao>
  closeHandler: () => void
}

export function LogDialog({
  showDialog,
  closeHandler,
  log,
  informacoesAdicionais
}: LogDialogProps) {
  return (
    <Dialog open={showDialog}>
      <DialogTitle>Log de Importação</DialogTitle>
      <Divider />
      <DialogContent>
        <Stack gap={2}>
          <TableContainer>
            <Table size="small">
              <TableBody>
                {log.map((mensagem, index) => {
                  return (
                    <TableRow key={'message' + index}>
                      <TableCell>{mensagem.descricao}</TableCell>
                      <TableCell>{mensagem.lista}</TableCell>
                    </TableRow>
                  )
                })}
                {!!informacoesAdicionais &&
                  informacoesAdicionais.map((mensagem, index) => {
                    return (
                      <TableRow key={'informacao' + index}>
                        <TableCell>{mensagem.descricao}</TableCell>
                        <TableCell>{mensagem.lista}</TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <Button onClick={closeHandler}>Fechar</Button>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
