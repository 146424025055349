import { DashboardWidgetListHeaderAction } from './DashboardWidgetListHeaderAction'
import { DashboardWidgetListHeaderActions } from './DashboardWidgetListHeaderActions'
import { DashboardWidgetListHeaderData } from './DashboardWidgetListHeaderData'
import { DashboardWidgetListHeaderIconAction } from './DashboardWidgetListHeaderIconAction'
import { DashboardWidgetListHeaderRoot } from './DashboardWidgetListHeaderRoot'

export const DashboardWidgetListHeader = {
  Root: DashboardWidgetListHeaderRoot,
  Data: DashboardWidgetListHeaderData,
  Actions: DashboardWidgetListHeaderActions,
  Action: DashboardWidgetListHeaderAction,
  IconAction: DashboardWidgetListHeaderIconAction
}
