import { Box } from '@mui/material'
import { ReactNode } from 'react'

interface DashboadWidgetSummaryHeaderToolbarRootProps {
  children: ReactNode
}

export function DashboadWidgetSummaryHeaderToolbarRoot({
  children
}: DashboadWidgetSummaryHeaderToolbarRootProps) {
  return (
    <Box
      sx={{
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '14px',
        color: '#969696',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      {children}
    </Box>
  )
}
