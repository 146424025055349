import { useState } from 'react'
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import BackspaceIcon from '@mui/icons-material/Backspace'
import { Select } from '../../../components/Form/Select'
import { Loading } from '../../../components/Loading'
import { useUsuario } from '../../../hooks/usuario/useUsuario'
import { ComponentGroup } from '../../../components/ComponentGroup'
import { useEmpresa } from '../../../hooks/empresa/useEmpresa'
import { useSetor } from '../../../hooks/setor/useSetor'
import { IUsuario } from '../../../services/usuario/types'
import { IContrato } from '../../../services/contrato/types'
import { ApiContratoPermitido } from '../../../services/contratoPermitido'
import { ISetor } from '../../../services/setor/types'
import { IContratoPermitidoRequest } from '../../../services/contratoPermitido/types'
import { useContrato } from '../../../hooks/contrato/useContrato'

export function Form() {
  const [idEmpresa, setIdEmpresa] = useState<string>('')
  const [idSetor, setIdSetor] = useState<string>('')
  const [listaSetoresFiltrados, setListaSetoresFiltrados] = useState<ISetor[]>(
    []
  )

  const [listaContratosDisponiveis, setListaContratosDisponiveis] = useState<
    IContrato[]
  >([])
  const [listaContratosPermitidos, setListaContratosPermitidos] = useState<
    IContrato[]
  >([])
  const [listaUsuariosFiltrados, setListaUsuariosFiltrados] = useState<
    IUsuario[]
  >([])
  const [idUsuario, setIdUsuario] = useState('')
  const { data: listaEmpresas, isLoading: isLoadingEmpresas } =
    useEmpresa.ListAll()
  const { data: listaSetores, isLoading: isLoadingSetores } = useSetor.ListAll()
  const { data: listaContratos } = useContrato.ListAllWithoutUserFilter()
  const { data: usuarios, isLoading } = useUsuario.ListAll()

  const handleEmpresaChange = (id_empresa: string) => {
    setIdEmpresa(id_empresa)
    if (id_empresa === '') {
      return
    }
    const newListaSetores =
      listaSetores?.filter(
        (setor) => setor.empresa.id_empresa === id_empresa
      ) ?? []
    setIdSetor('')
    setIdUsuario('')
    setListaUsuariosFiltrados([])
    setListaSetoresFiltrados(newListaSetores)
  }

  const handleSetorChange = (id_setor: string) => {
    setIdSetor(id_setor)
    if (id_setor === '') {
      return
    }
    const newListaUsuarios =
      usuarios?.filter(
        (usuario) => usuario.pessoa.setor.id_setor === id_setor
      ) ?? []
    setIdUsuario('')
    setListaUsuariosFiltrados(newListaUsuarios)
  }

  const handleUsuarioChange = async (id_usuario: string) => {
    setIdUsuario(id_usuario)

    const contratosPermitidosUsuario = await ApiContratoPermitido.findByUserId(
      id_usuario
    )

    const mappedContratosPermitidos = contratosPermitidosUsuario.map(
      (contratoPermitido) => {
        return contratoPermitido.contrato as IContrato
      }
    )

    setListaContratosPermitidos(
      mappedContratosPermitidos ? mappedContratosPermitidos : []
    )
    const newContratosDisponiveis = listaContratos?.filter(
      (contrato) =>
        !contratosPermitidosUsuario.find(
          (contratoPermitido) =>
            contratoPermitido.id_contrato === contrato.id_contrato
        )
    )
    setListaContratosDisponiveis(newContratosDisponiveis ?? [])
  }

  const handleAddPermission = async ({
    id_usuario,
    id_contrato
  }: IContratoPermitidoRequest) => {
    await ApiContratoPermitido.create({
      id_contrato,
      id_usuario
    })

    const contratoAdicionado = listaContratosDisponiveis.find(
      (contrato) => contrato.id_contrato === id_contrato
    )

    const novosContratosDisponiveis = listaContratosDisponiveis.filter(
      (contrato) => contrato.id_contrato !== id_contrato
    )

    setListaContratosDisponiveis(novosContratosDisponiveis)

    if (contratoAdicionado)
      setListaContratosPermitidos([
        ...listaContratosPermitidos,
        contratoAdicionado
      ])
  }

  const handleRemovePermission = async ({
    id_usuario,
    id_contrato
  }: IContratoPermitidoRequest) => {
    await ApiContratoPermitido.remove({
      id_usuario,
      id_contrato
    })

    const setorRemovido = listaContratosPermitidos.find(
      (setorPermitido) => setorPermitido.id_contrato === id_contrato
    )

    const novosContratosPermitidos = listaContratosPermitidos.filter(
      (setorPermitido) => setorPermitido.id_contrato !== id_contrato
    )

    setListaContratosPermitidos(novosContratosPermitidos)
    if (setorRemovido)
      setListaContratosDisponiveis([
        ...listaContratosDisponiveis,
        setorRemovido
      ])
  }

  if (isLoading || isLoadingEmpresas || isLoadingSetores) <Loading />

  return (
    <Box component="form" mt={4}>
      <Stack gap={2}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2
          }}
        >
          <Select
            label="Empresa*"
            placeholder="Selecione a Empresa"
            data={listaEmpresas}
            fields={['id_empresa', 'nome']}
            onChange={(e) => handleEmpresaChange(String(e.target.value))}
            value={idEmpresa}
          />
          <Select
            label="Setor*"
            placeholder="Selecione o Setor"
            data={listaSetoresFiltrados}
            fields={['id_setor', 'nome']}
            onChange={(e) => handleSetorChange(String(e.target.value))}
            value={idSetor}
          />
        </Box>
        <Select
          label="Usuário*"
          placeholder="Selecione o Usuario"
          data={listaUsuariosFiltrados}
          fields={['id_usuario', 'descricao']}
          onChange={(e) => handleUsuarioChange(String(e.target.value))}
          value={idUsuario}
        />

        <Stack direction="row" gap={2}>
          <ComponentGroup title="Contratos Disponíveis" width={'50%'}>
            <List sx={{ height: '360px', overflow: 'auto' }}>
              {listaContratosDisponiveis
                .sort((a, b) => {
                  if (a.descricao > b.descricao) return 1
                  if (a.descricao < b.descricao) return -1
                  return 0
                })
                .map((p: IContrato, index: number) => (
                  <ListItem
                    // key={p.key}
                    key={index}
                    disableGutters
                    secondaryAction={
                      <IconButton
                        onClick={() => {
                          handleAddPermission({
                            id_usuario: idUsuario,
                            id_contrato: p.id_contrato ?? ''
                          })
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText primary={p.descricao} />
                  </ListItem>
                ))}
            </List>
          </ComponentGroup>

          <ComponentGroup title="Contratos Permitidos" width={'50%'}>
            <List sx={{ height: '360px', overflow: 'auto' }}>
              {listaContratosPermitidos
                .sort((a, b) => {
                  if (a.descricao > b.descricao) return 1
                  if (a.descricao < b.descricao) return -1
                  return 0
                })
                .map((p: IContrato, index: number) => (
                  <ListItem
                    // key={p}
                    key={index}
                    disableGutters
                    secondaryAction={
                      <IconButton
                        onClick={() =>
                          handleRemovePermission({
                            id_usuario: idUsuario,
                            id_contrato: p.id_contrato ? p.id_contrato : ''
                          })
                        }
                      >
                        <BackspaceIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText primary={p.descricao} />
                  </ListItem>
                ))}
            </List>
          </ComponentGroup>
        </Stack>
      </Stack>
    </Box>
  )
}
