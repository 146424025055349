import { useMemo, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/AddCircle'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import FolderIcon from '@mui/icons-material/Folder'
import { useSetor } from '../../../hooks/setor/useSetor'
import useToast from '../../../hooks/toast/useToast'
import { FormMode } from '../../../types/formMode'
import { Loading } from '../../../components/Loading'
import React from 'react'
import { useUsuario } from '../../../hooks/usuario/useUsuario'
import { Permissions } from '../../../types/permissions'
import { hasAccess } from '../../../utils/hasAccess'

interface IForm {
  setData: (value: React.SetStateAction<any>) => void
  setFormMode: (value: React.SetStateAction<FormMode>) => void
  isDrive?: boolean
}

export function List({ setData, setFormMode, isDrive = false }: IForm) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [q, setQ] = useState<string>('')
  const { Toast } = useToast()
  const searchInput = React.useRef<HTMLInputElement>(null)

  const { data: usuario } = useUsuario.FindByUsername()

  const canAccessDrive =
    hasAccess(usuario?.permissoes, Permissions.DRIVE_VIRTUAL) ||
    hasAccess(usuario?.permissoes, Permissions.SETORES_DRIVE_VIRTUAL)

  const handleSearchClick = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setSearchParams({ ...searchParams, q: searchTerm })
    setQ(searchTerm)
    searchInput.current?.focus()
  }

  const page = useMemo(() => {
    return Number(searchParams.get('page') ?? 1)
  }, [searchParams])

  const { mutateAsync: remove } = useSetor.Remove()
  const { data: records, isLoading } = isDrive
    ? useSetor.FindAllByDrive(page, q)
    : useSetor.FindAll(page, q)

  const handleNewForm = () => {
    setFormMode('CREATE')
  }

  const handleRemove = async (id: string) => {
    await Toast(remove(id), 'REMOVED')
    setSearchParams({ page: '1' })
  }

  const handleEditForm = (data: any) => {
    setData(data)
    setFormMode('EDIT')
  }

  const handleDrive = (data: any) => {
    setData(data)
    setFormMode('DRIVE')
  }

  if (isLoading) return <Loading />
  return (
    <Box>
      <Stack
        component="form"
        onSubmit={handleSearchClick}
        direction="row"
        py={3}
        gap={3}
        mb={2}
      >
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          placeholder="Setor/Empresa"
          inputRef={searchInput}
          autoFocus
          value={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value)
          }}
        />
        <Button variant="outlined" startIcon={<SearchIcon />} type="submit">
          Pesquisar
        </Button>
      </Stack>

      <Divider />

      {!isDrive && (
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleNewForm}
          sx={{ float: 'right', my: '12px' }}
        >
          Novo registro
        </Button>
      )}

      <TableContainer
        component={Box}
        border={1}
        borderRadius={2}
        borderColor="#f2f2f2"
      >
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Nome
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Empresa
              </TableCell>
              <TableCell width="12px" />
              <TableCell width="12px" />
              <TableCell width="12px" />
            </TableRow>
          </TableHead>
          <TableBody>
            {records?.data?.map((record) => (
              <TableRow key={record.id_setor} hover>
                <TableCell>{record.nome}</TableCell>
                <TableCell>{record.empresa.razao_social}</TableCell>
                <TableCell>
                  {!isDrive && (
                    <Tooltip title="Editar" arrow>
                      <Button
                        variant="outlined"
                        sx={{ minWidth: '24px', padding: '6px' }}
                        onClick={() => handleEditForm(record)}
                      >
                        <EditIcon />
                      </Button>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell>
                  {canAccessDrive && (
                    <Tooltip title="Drive" arrow>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleDrive(record.id_setor!)}
                        sx={{ minWidth: '24px', padding: '6px' }}
                      >
                        <FolderIcon />
                      </Button>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell>
                  {!isDrive && (
                    <Tooltip title="Excluir" arrow>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleRemove(record.id_setor!)}
                        sx={{ minWidth: '24px', padding: '6px' }}
                      >
                        <DeleteIcon />
                      </Button>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {records?.pagination &&
        records.pagination.totalItems > records.pagination.itemsPerPage && (
          <Pagination
            count={records?.pagination.totalPages}
            sx={{ marginTop: '12px' }}
            onChange={(_, newPage) =>
              setSearchParams({ page: String(newPage) })
            }
            page={records.pagination.currentPage}
          />
        )}
    </Box>
  )
}
