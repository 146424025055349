import { useMemo, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/AddCircle'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import useToast from '../../../hooks/toast/useToast'
import { FormMode } from '../../../types/formMode'
import { useTipoTemporalidade } from '../../../hooks/tipoTemporalidade/useTipoTemporalidade'
import { Loading } from '../../../components/Loading'
import React from 'react'

interface IForm {
  setData: (value: React.SetStateAction<any>) => void
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

export function ListTipoTemporalidade({ setData, setFormMode }: IForm) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [q, setQ] = useState<string>('')
  const { Toast } = useToast()
  const searchInput = React.useRef<HTMLInputElement>(null)

  const handleSearchClick = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setSearchParams({ ...searchParams, q: searchTerm })
    setQ(searchTerm)
    searchInput.current?.focus()
  }

  const page = useMemo(() => {
    return Number(searchParams.get('page') ?? 1)
  }, [searchParams])

  const { mutateAsync: remove } = useTipoTemporalidade.Remove()
  const { data: records, isLoading } = useTipoTemporalidade.FindAll(page, q)

  const handleNewForm = () => {
    setFormMode('CREATE')
  }

  const handleRemove = async (id: string) => {
    await Toast(remove(id), 'REMOVED')
    setSearchParams({ page: '1' })
  }

  const handleEditForm = (data: any) => {
    setData(data)
    setFormMode('EDIT')
  }

  if (isLoading) return <Loading />

  return (
    <Box>
      <Stack
        component="form"
        onSubmit={handleSearchClick}
        direction="row"
        py={3}
        gap={3}
        mb={2}
      >
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          placeholder="Descrição/Fundamentação"
          inputRef={searchInput}
          autoFocus
          value={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value)
          }}
        />
        <Button variant="outlined" startIcon={<SearchIcon />} type="submit">
          Pesquisar
        </Button>
      </Stack>

      <Divider />

      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleNewForm}
        sx={{ float: 'right', my: '12px' }}
      >
        Novo registro
      </Button>

      <TableContainer
        component={Box}
        border={1}
        borderRadius={2}
        borderColor="#f2f2f2"
      >
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Descrição
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Fundamentação
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  width: '15rem'
                }}
              >
                Tempo de Guarda
              </TableCell>
              <TableCell width="12px" />
              <TableCell width="12px" />
            </TableRow>
          </TableHead>
          <TableBody>
            {records?.data?.map((item) => (
              <TableRow key={item.id_tipo_temporalidade} hover>
                <TableCell>{item.descricao}</TableCell>
                <TableCell>{item.fundamentacao}</TableCell>
                <TableCell>{`${item.tempo} (${item.unidade})`}</TableCell>
                <TableCell>
                  <Tooltip title="Editar" arrow>
                    <Button
                      variant="outlined"
                      sx={{ minWidth: '24px', padding: '6px' }}
                      onClick={() => handleEditForm(item)}
                    >
                      <EditIcon />
                    </Button>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title="Excluir" arrow>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleRemove(item.id_tipo_temporalidade!)}
                      sx={{ minWidth: '24px', padding: '6px' }}
                    >
                      <DeleteIcon />
                    </Button>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {records?.pagination &&
        records.pagination.totalItems > records.pagination.itemsPerPage && (
          <Pagination
            count={records?.pagination.totalPages}
            sx={{ marginTop: '12px' }}
            onChange={(_, newPage) =>
              setSearchParams({ page: String(newPage) })
            }
            page={records.pagination.currentPage}
          />
        )}
    </Box>
  )
}
