import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ApiMunicipio } from '../../services/municipio'

const QUERY_KEY = 'qkMunicipio'

const FindAll = (page = 1, q = '') => {
  return useQuery([QUERY_KEY, q, page], () => ApiMunicipio.findAll(page, q))
}

const ListAll = () => useQuery(QUERY_KEY, ApiMunicipio.listAll)

const FindByUf = (uf?: string) =>
  useQuery([QUERY_KEY, uf], () => ApiMunicipio.findByUf(uf))

const Create = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiMunicipio.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Remove = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiMunicipio.remove, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

export const useMunicipio = {
  Create,
  FindAll,
  ListAll,
  FindByUf,
  Remove
}
