import { useState } from 'react'
import {
  Avatar,
  Box,
  Button,
  Drawer,
  List,
  Stack,
  Typography,
  Paper
} from '@mui/material'
import { SidebarMenu } from './SidebarMenu'
import { menuList } from './menuList'
import AvatarIcon from '@mui/icons-material/Person'
import MenuIcon from '@mui/icons-material/Menu'
import { IUsuario } from '../../../services/usuario/types'

type SideBarProps = {
  usuario?: IUsuario
  drawerWidth: number
}

export function Sidebar({ usuario, drawerWidth }: SideBarProps) {
  const [selectedMenu, setSelectedMenu] = useState('Dashboard')
  const [open, setOpen] = useState(true)
  const [isClosed, setIsClosed] = useState(false)

  const handleSideBarSlide = () => {
    setOpen(!open)
    !open ? setIsClosed(false) : setIsClosed(true)
  }

  const handleClickMenu = (menuTitle: string) => {
    setSelectedMenu(menuTitle)
  }

  const handleMouseEnter = () => {
    if (isClosed) setOpen(true)
  }

  const handleMouseLeave = () => {
    if (isClosed) setOpen(false)
  }

  const handleReloadRequests = () =>
    localStorage.removeItem('@easydoc:onlyIncomplete')

  return (
    <Box>
      <Box
        position="fixed"
        top={64}
        paddingTop={3}
        width={`${open ? drawerWidth : drawerWidth / 4}px`}
        bgcolor="#2835ad"
        sx={{
          transition: 'width 0.5s ease'
        }}
      >
        <Stack direction="row" alignItems="center" gap={1} px={3}>
          <Avatar
            variant="rounded"
            sx={{
              bgcolor: '#4f769c',
              border: '2px solid #c8d5ea',
              width: 48,
              height: 48,
              marginLeft: `${open ? 0 : -11.5}px`,
              marginRight: `${open ? 0 : 4}px`
            }}
          >
            <AvatarIcon sx={{ width: 40, height: 40 }} />
          </Avatar>
          <Box>
            {open ? (
              <>
                <Typography fontWeight={600} color="ButtonFace">
                  {usuario?.pessoa.nome}
                </Typography>
                <Typography color="ButtonFace" fontSize={10}>
                  {usuario?.pessoa.setor.empresa?.nome}
                </Typography>
              </>
            ) : (
              ''
            )}
          </Box>
        </Stack>
        <Button
          aria-label="open drawer"
          onClick={handleSideBarSlide}
          sx={{
            mt: 1,
            color: 'white',
            borderRadius: '100px',
            marginLeft: `${open ? 11.5 : 5}px`
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
      <Drawer
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          width: `${open ? drawerWidth : drawerWidth / 4}px`,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: `${open ? drawerWidth : drawerWidth / 4}px`,
            marginTop: '177px',
            height: 'calc(100% - 177px)',
            boxSizing: 'revert',
            backgroundColor: '#2835ad',
            borderRightColor: '#f2f5fa',
            borderRightWidth: '5px',
            transition: 'width 0.5s ease'
          }
        }}
        variant="permanent"
        onClick={handleReloadRequests}
        open={open}
      >
        <Paper
          sx={{
            backgroundColor: '#2835ad',
            height: '100%',
            overflowY: 'scroll',
            '::-webkit-scrollbar': {
              display: 'none'
            }
          }}
        >
          <List
            sx={{
              padding: 0,
              marginLeft: `${open ? 0 : -3.5}px`,
              marginRight: `${open ? 0 : -8}px`
            }}
          >
            {open
              ? menuList.map((menu) => {
                  return (
                    <SidebarMenu
                      key={menu.title}
                      menu={menu}
                      onClickMenu={handleClickMenu}
                      selectedMenu={selectedMenu}
                      userPermission={usuario?.permissoes ?? []}
                    />
                  )
                })
              : menuList.map((menu) => {
                  return (
                    <SidebarMenu
                      key={menu.title}
                      menu={{
                        title: menu.title,
                        permission: menu.permission,
                        icon: menu.icon,
                        path: menu.path
                      }}
                      onClickMenu={handleClickMenu}
                      selectedMenu={selectedMenu}
                      userPermission={usuario?.permissoes ?? []}
                    />
                  )
                })}
          </List>
        </Paper>
      </Drawer>
      {!open ? <Box sx={{ width: '175px' }}></Box> : ''}
    </Box>
  )
}
