import { Tooltip } from '@mui/material'
import { IPosicaoPrateleira } from '../../../../services/posicaoPrateleira/types'
import { IPrateleira } from '../../../../services/prateleira/types'

interface PosicaoPrateleiraDrawProps {
  posicaoPrateleira: IPosicaoPrateleira
  handleMouseClick: (
    event: React.MouseEvent<HTMLElement>,
    posicao: IPosicaoPrateleira,
    prateleira: IPrateleira
  ) => void
  openMenu: boolean
  enderecoPrateleira: string
  prateleira: IPrateleira
}

export function PosicaoPrateleiraDraw({
  posicaoPrateleira,
  enderecoPrateleira,
  handleMouseClick,
  openMenu,
  prateleira
}: PosicaoPrateleiraDrawProps) {
  const handleMouseOver = (event: React.MouseEvent<HTMLElement>) => {
    event.currentTarget.style.filter = 'brightness(80%)'
  }
  const handleMouseLeave = (event: React.MouseEvent<HTMLElement>) => {
    event.currentTarget.style.filter = 'brightness(100%)'
  }

  const getAddressPosicaoPrateleira = () => {
    return enderecoPrateleira + '.' + posicaoPrateleira.identificacao
  }

  const tooltip = posicaoPrateleira.caixa
    ? `${posicaoPrateleira.caixa.identificacao} - (${posicaoPrateleira.caixa.endereco})`
    : 'Vazia'

  return (
    <td id={`ID-${getAddressPosicaoPrateleira()}`}>
      <Tooltip title={tooltip}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: !posicaoPrateleira.habilitada
              ? '#cccccc'
              : !posicaoPrateleira.caixa
              ? '#C1E4CB'
              : '#EE675C',
            borderRadius: '12px',
            border: '1px solid lightgray',
            margin: '2px',
            width: '36px',
            height: '36px',
            cursor: 'pointer'
          }}
          aria-controls={openMenu ? 'MENU-POSICAOPRATELEIRA' : undefined}
          aria-haspopup="true"
          aria-expanded={openMenu ? 'true' : undefined}
          onClick={(event) => {
            handleMouseClick(event, posicaoPrateleira, prateleira)
          }}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseLeave}
        >
          {posicaoPrateleira.identificacao}
        </div>
      </Tooltip>
    </td>
  )
}
