import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ApiCentroCusto } from '../../../services/financeiro/centroCusto'

const QUERY_KEY = 'qkCentroCusto'

const FindAll = (page: number, q = '') => {
  return useQuery([QUERY_KEY, q, page], () => ApiCentroCusto.findAll(page, q))
}

const ListAll = () => {
  return useQuery([QUERY_KEY], ApiCentroCusto.listAll)
}

const Create = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiCentroCusto.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Update = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiCentroCusto.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Remove = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiCentroCusto.remove, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

export const useCentroCusto = {
  Create,
  Update,
  FindAll,
  ListAll,
  Remove
}
