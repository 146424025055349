import { Box } from '@mui/material'
import { ReactNode, useState } from 'react'

import { useUsuario } from '../../hooks/usuario/useUsuario'
import { useDashboard } from '../../hooks/dashboard/useDashboard'
import { Loading } from '../../components/Loading'
import {
  IRequisicoesDashboard,
  ITipoAtividade
} from '../../services/dashboard/types'
import { usePermissaoDisponivel } from '../../hooks/permissaoDisponivel/usePermissaoDisponivel'
import { IPermissaoDisponivel } from '../../services/permissaoDisponivel/types'
import { Permissions } from '../../types/permissions'
import { WidgetCaixa } from './Widgets/Caixa'
import { WidgetRequisicoes } from './Widgets/Requisicoes'
import { WidgetTabela } from './Widgets/Tabela'
import { WidgetProtocolo } from './Widgets/Protocolo'
import { WidgetAlocacao } from './Widgets/Alocacao'
import { WidgetLote } from './Widgets/Lote'
import { TWidgetAtividade } from './Widgets/lib/@types/TWidgetAtividade'
import { WidgetCard } from './Widgets/Card'

export type DashboardFormMode = 'ALL' | 'FORM' | 'LIST'

export function Dashboard() {
  const [tipoAtividadeAtual, setTipoAtividadeAtual] =
    useState<TWidgetAtividade>(undefined)
  const { data: usuario, isLoading } = useUsuario.FindByUsername()
  const { data: permissaoCaixa, isLoading: isLoadingPermissao } =
    usePermissaoDisponivel.FindByCodigoPermissao(105)
  const {
    data: permissaoCaixaMovimentacao,
    isLoading: isLoadingPermissaoCaixaMovimentacao
  } = usePermissaoDisponivel.FindByCodigoPermissao(1051)

  const { data: permissaoRequisicao, isLoading: isLoadingPermissaoRequisicao } =
    usePermissaoDisponivel.FindByCodigoPermissao(106)
  const {
    data: permissaoRequisicaoMovimentacao,
    isLoading: isLoadingPermissaoRequisicaoMovimentacao
  } = usePermissaoDisponivel.FindByCodigoPermissao(1061)

  const permissoes = usuario?.permissoes as Array<number>

  const { data: dataSumario, isLoading: isLoadingSumarioData } =
    useDashboard.GetSumarioCaixas()
  const { data: dataTiposAtividade, isLoading: isLoadingTiposAtividadeData } =
    useDashboard.GetTiposAtividade()
  const { data: dataRequisicoes, isLoading: isLoadingRequisicoesData } =
    useDashboard.GetRequisicoes()

  const getTiposAtividade = (): ITipoAtividade[] => {
    const recoveryTiposAtividades: Array<ITipoAtividade> =
      dataRequisicoes?.tiposAtividades ?? []
    const tiposAtividades: Array<ITipoAtividade> = []
    for (const tipoAtividade of recoveryTiposAtividades) {
      if (
        !tiposAtividades.find(
          (tpAtividade) =>
            tpAtividade.id_tipo_atividade === tipoAtividade.id_tipo_atividade
        )
      ) {
        const tipoAtividadeData = dataTiposAtividade?.requisicoesData?.find(
          (tipo) => tipoAtividade.id_tipo_atividade === tipo.id_tipo_atividade
        )

        if (tipoAtividadeData) {
          tiposAtividades.push({ ...tipoAtividade, data: tipoAtividadeData })
        }
      }
    }
    if (dataSumario?.data) {
      const caixas = {
        id_tipo_atividade: 'id_caixas',
        descricao: 'Caixas',
        identificacao: 'Caixas',
        permissao: permissaoCaixa ?? ({} as IPermissaoDisponivel),
        permissao_movimentacao:
          permissaoCaixaMovimentacao ?? ({} as IPermissaoDisponivel)
      }
      tiposAtividades.push(caixas)
    }

    const requisicoesSumary = {
      id_tipo_atividade: 'id_requisicoes',
      descricao: 'Requisições',
      identificacao: 'Requisições Não Concluídas',
      permissao: permissaoRequisicao ?? ({} as IPermissaoDisponivel),
      permissao_movimentacao:
        permissaoRequisicaoMovimentacao ?? ({} as IPermissaoDisponivel)
    }
    tiposAtividades.push(requisicoesSumary)

    return tiposAtividades
  }

  if (
    isLoading ||
    isLoadingSumarioData ||
    isLoadingRequisicoesData ||
    isLoadingTiposAtividadeData ||
    isLoadingPermissao ||
    isLoadingPermissaoRequisicao ||
    isLoadingPermissaoCaixaMovimentacao ||
    isLoadingPermissaoRequisicaoMovimentacao
  )
    return <Loading />

  const hasPermission = (permission: number) => permissoes.includes(permission)

  const permissaoSLA = hasPermission(Permissions.DASHBOARD_VER_SLA_ATRASO)

  const tiposAtividades = getTiposAtividade()

  const selectWidgets = (tpAtividade: TWidgetAtividade): ReactNode | null => {
    return tiposAtividades.map((tipoAtividade) => {
      if (
        [
          Permissions.DASHBOARD_TABELA,
          Permissions.DASHBOARD_CARDS,
          Permissions.DASHBOARD_LOTE_E_REQUISICOES,
          Permissions.DASHBOARD_LOTES_PROTOCOLO,
          Permissions.DASHBOARD_ALOCACAO_CAIXAS,
          Permissions.DASHBOARD_CAIXAS,
          Permissions.DASHBOARD_REQUISICOES
        ].includes(tipoAtividade.permissao.codigo_permissao)
      ) {
        if (
          !!tpAtividade &&
          tpAtividade !== 'Requisicao' &&
          tipoAtividade.id_tipo_atividade !== tpAtividade.id_tipo_atividade
        )
          return null
        if (hasPermission(tipoAtividade.permissao.codigo_permissao)) {
          switch (tipoAtividade.permissao.codigo_permissao) {
            case Permissions.DASHBOARD_CAIXAS: {
              return tpAtividade === 'Requisicao' ? null : (
                <WidgetCaixa key={`widget${tipoAtividade.id_tipo_atividade}`} />
              )
            }

            case Permissions.DASHBOARD_CARDS: {
              return tpAtividade === 'Requisicao'
                ? null
                : tipoAtividade?.data && (
                    <>
                      <WidgetCard
                        key={`widget${tipoAtividade.id_tipo_atividade}`}
                        data={tipoAtividade.data?.cardData}
                        tipoAtividade={tipoAtividade}
                        onStateChange={handleWidgetStateChange}
                      />
                    </>
                  )
              // <p>Teste - {tipoAtividade.descricao}</p>
            }
            case Permissions.DASHBOARD_REQUISICOES: {
              return (
                <WidgetRequisicoes
                  showSLA={permissaoSLA}
                  data={dataRequisicoes as IRequisicoesDashboard}
                  key={`widget${tipoAtividade.id_tipo_atividade}`}
                  onStateChange={handleWidgetStateChange}
                />
              )
            }
            case Permissions.DASHBOARD_TABELA: {
              return tpAtividade === 'Requisicao'
                ? null
                : tipoAtividade?.data && (
                    <WidgetTabela
                      data={tipoAtividade?.data.tabelaData}
                      key={`widget${tipoAtividade.id_tipo_atividade}`}
                      tipoAtividade={tipoAtividade}
                      onStateChange={handleWidgetStateChange}
                      showSla={permissaoSLA}
                    />
                  )
            }
            case Permissions.DASHBOARD_LOTES_PROTOCOLO: {
              return tpAtividade === 'Requisicao'
                ? null
                : tipoAtividade?.data && (
                    <WidgetProtocolo
                      data={tipoAtividade?.data.protocoloData}
                      dataRequisicoes={
                        tipoAtividade?.data.protocoloData
                          .requisicoesAtividade ?? []
                      }
                      key={`widget${tipoAtividade.id_tipo_atividade}`}
                      tipoAtividade={tipoAtividade}
                      onStateChange={handleWidgetStateChange}
                      showSLA={permissaoSLA}
                    />
                  )
            }
            case Permissions.DASHBOARD_ALOCACAO_CAIXAS: {
              return tpAtividade === 'Requisicao'
                ? null
                : tipoAtividade?.data && (
                    <WidgetAlocacao
                      key={`widget${tipoAtividade.id_tipo_atividade}`}
                      data={tipoAtividade.data.alocacoesData}
                      tipoAtividade={tipoAtividade}
                      onStateChange={handleWidgetStateChange}
                    />
                  )
            }
            case Permissions.DASHBOARD_LOTE_E_REQUISICOES: {
              return tpAtividade === 'Requisicao'
                ? null
                : tipoAtividade?.data && (
                    <WidgetLote
                      data={tipoAtividade?.data.protocoloData}
                      //dataRequisicoes={dataTiposAtividade?.requisicoes ?? []}
                      dataRequisicoes={
                        tipoAtividade?.data.protocoloData
                          .requisicoesAtividade ?? []
                      }
                      key={`widget${tipoAtividade.id_tipo_atividade}`}
                      tipoAtividade={tipoAtividade}
                      onStateChange={handleWidgetStateChange}
                      showSla={permissaoSLA}
                    />
                  )
            }
            default: {
              return (
                <p key={`widget${tipoAtividade.id_tipo_atividade}`}>
                  {tipoAtividade.descricao}
                </p>
              )
            }
          }
        }
      }
      return null
    })
  }

  const handleWidgetStateChange = (tipoAtividade: TWidgetAtividade): void => {
    setTipoAtividadeAtual(tipoAtividade)
  }

  // const selectContent = (
  //   dados: IDashboard,
  //   currentFormMode: DashboardFormMode
  // ): ReactNode | null => {
  //   builder.updateWidgetsList(
  //     tiposAtividades,
  //     {
  //       data: dados,
  //       setTipoAtividadeAtual,
  //       setFormMode
  //     },
  //     formMode,
  //     tipoAtividadeAtual
  //   )

  //   if (currentFormMode !== 'ALL') {
  //     if (
  //       !!tipoAtividadeAtual &&
  //       hasPermission(tipoAtividadeAtual?.permissao.codigo_permissao)
  //     ) {
  //       const widget = builder.getDashboardWidget(
  //         tipoAtividadeAtual.id_tipo_atividade
  //       )
  //       if (!widget) return null
  //       return currentFormMode === 'LIST' && widget
  //         ? widget.renderList()
  //         : widget.renderForm()
  //     }
  //     return null
  //   }
  //   return tiposAtividades.map((tipoAtividade) => {
  //     if (hasPermission(tipoAtividade.permissao.codigo_permissao)) {
  //       const widget = builder.getDashboardWidget(
  //         tipoAtividade.id_tipo_atividade
  //       )
  //       return widget ? widget.renderSummary() : null
  //     }
  //     return null
  //   })
  // }

  return (
    <Box
      sx={{
        display: !tipoAtividadeAtual ? 'grid' : '',
        gridTemplateColumns: {
          xs: '1fr', // for mobile
          sm: '1fr', // for tablet
          md: !tipoAtividadeAtual ? '1fr 1fr' : ''
        },
        gap: '1rem'
      }}
    >
      {/* {selectContent(refData, formMode)} */}
      {selectWidgets(tipoAtividadeAtual)}
    </Box>
  )
}
