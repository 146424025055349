import { ReactNode } from 'react'

interface DashboardWidgetSummaryBodyRootProps {
  children: ReactNode
}

export function DashboardWidgetSummaryBodyRoot({
  children
}: DashboardWidgetSummaryBodyRootProps) {
  return <>{children}</>
}
