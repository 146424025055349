import { ICaixasArmazenadasPorEmpresaESetor } from '../../../../../../../services/relatorio/types'
import {
  groupByField,
  iterateGroupedData
} from '../../../../../../../utils/report'
import { TemplateReport } from '../../../../../../../components/Report'
import { ApiEmpresa } from '../../../../../../../services/empresa'
import { format } from 'date-fns'
import { Content } from 'pdfmake/interfaces'
import { ApiSetor } from '../../../../../../../services/setor'

type Props = {
  data: ICaixasArmazenadasPorEmpresaESetor[]
  mode?: 'open' | 'save'
  id_usuario: string
  filtros: {
    id_empresa?: string
    id_setor?: string
    data_inicio?: Date
    data_fim?: Date
    tipoConteudo?: string
  }
}

export async function CaixasArmazenadasPorEmpresaESetorReport({
  data,
  mode,
  id_usuario,
  filtros
}: Props) {
  const { id_empresa, data_fim, data_inicio, id_setor, tipoConteudo } = filtros
  const filterReportText = async () => {
    const filtersReport = []
    filtersReport.push({ text: 'Empresa: ', style: ['filterText', 'bold'] })
    if (id_empresa) {
      const empresa = await ApiEmpresa.findOne(id_empresa)
      if (empresa) {
        filtersReport.push({
          text: empresa.razao_social,
          style: ['filterText']
        })
      }
    } else filtersReport.push({ text: '<todas> ', style: ['filterText'] })

    filtersReport.push({ text: '\n' })
    filtersReport.push({ text: 'Setor: ', style: ['filterText', 'bold'] })
    if (id_setor) {
      const setor = await ApiSetor.findOne(id_setor)
      if (setor) {
        filtersReport.push({
          text: setor.nome,
          style: ['filterText']
        })
      }
    } else filtersReport.push({ text: '<todas> ', style: ['filterText'] })

    filtersReport.push({ text: '\n' })
    filtersReport.push({
      text: 'Tipo de Conteúdo: ',
      style: ['filterText', 'bold']
    })
    filtersReport.push({
      text:
        tipoConteudo === '0'
          ? '<todos>'
          : tipoConteudo === '1'
          ? 'Caixa'
          : 'Documento',
      style: ['filterText']
    })

    filtersReport.push({ text: '\n' })
    filtersReport.push({ text: 'Período: ', style: ['filterText', 'bold'] })
    if (data_inicio && data_fim) {
      filtersReport.push({
        text: `${format(new Date(data_inicio), 'dd/MM/yyyy')} até ${format(
          new Date(data_fim),
          'dd/MM/yyyy'
        )}`,
        style: ['filterText']
      })
    } else filtersReport.push({ text: '<global> ', style: ['filterText'] })

    return filtersReport
  }

  const dadosAgrupados = groupByField(data, 'nome')

  const caixasArmazenadasPorEmpresaESetorGroupTable = () => {
    const tables: Array<any> = []
    let rows: Array<any> = []
    if (dadosAgrupados) {
      iterateGroupedData(dadosAgrupados, (empresa, setores) => {
        rows = [
          [
            { text: 'Setor', style: 'columnTitle' },
            { text: 'Estado', style: 'columnTitle' },
            { text: 'Tipo Lote', style: 'columnTitle' },
            { text: 'Qtde', style: 'columnTitle' }
          ]
        ]

        setores.forEach((setor) => {
          const row = [
            { text: setor.nome_setor, style: 'row' },
            { text: setor.estado_final, style: 'row' },
            { text: setor.tipo, style: 'rowCenter' },
            { text: setor.qtd_armazenadas, style: 'quantityDoc' }
          ]

          rows.push(row as any)
        })

        tables.push([
          {
            text: `Empresa: ${empresa}`,
            style: 'subtitle'
          },
          {
            table: {
              widths: ['*', 110, 80, 50],
              headerRows: 1,
              body: rows
            }
          },
          {
            text: `Total de Registros: ${setores.length}`,
            style: 'total'
          }
        ])
      })
      return tables
    } else {
      return [{ text: '\n' }]
    }
  }

  const sumarizarQuantidadesPorTipoCaixa = () => {
    const quantidadePorTipoCaixa: Record<string, number> = data.reduce(
      (accumulator, item) => {
        const { tipo, qtd_armazenadas } = item
        accumulator[tipo] = (accumulator[tipo] || 0) + qtd_armazenadas
        return accumulator
      },
      {} as Record<string, number>
    )
    const summaryReport: Content = []
    Object.keys(quantidadePorTipoCaixa).forEach((tipo) => {
      summaryReport.push({
        text: `Total ${tipo}: `,
        style: ['summaryText', 'bold']
      })
      summaryReport.push({
        text: `${quantidadePorTipoCaixa[tipo]}`,
        style: ['summaryText']
      })
      summaryReport.push({ text: '\n' })
    })
    return summaryReport
  }

  await TemplateReport({
    id_usuario,
    reportTitle: 'Caixas/Documentos Armazenados por Empresa / Setor',
    filterText: await filterReportText(),
    content: caixasArmazenadasPorEmpresaESetorGroupTable(),
    summary: sumarizarQuantidadesPorTipoCaixa(),
    mode
  })
}
