import { DefaultApi } from '../../utils/defaultApi'
import { ITipoAtividade } from './types'

const endpoint = '/tipo-atividade'
const resourceId = 'id_tipo_atividade'

export const ApiTipoAtividade = new DefaultApi<ITipoAtividade>(
  endpoint,
  resourceId
)
