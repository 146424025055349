import { Content, PageOrientation } from 'pdfmake/interfaces'
import { groupByField, iterateGroupedData } from '../../../../../utils/report'
import { TemplateReport } from '../../../../../components/Report'
import { ApiEmpresa } from '../../../../../services/empresa'
import { IReportFilter } from '../../../../../components/ReportForm'
import { IArvoreDocumental } from '../../../../../services/relatorio/types'

type Props = {
  id_usuario: string
  data: IArvoreDocumental[]
  mode?: 'open' | 'save'
  orientation?: PageOrientation
  filtros: IReportFilter
}

export async function ArvoreDocumentalReport({
  id_usuario,
  data,
  mode,
  filtros
}: Props) {
  const dadosAgrupados = groupByField(data, 'nome_empresa')

  const filterReportText = async () => {
    const { id_empresa } = filtros
    const filtersReport = []
    filtersReport.push({ text: 'Empresa: ', style: ['filterText', 'bold'] })
    if (id_empresa) {
      const empresa = await ApiEmpresa.findOne(id_empresa)
      if (empresa) {
        filtersReport.push({
          text: empresa.razao_social,
          style: ['filterText']
        })
      }
    } else filtersReport.push({ text: '<todas> ', style: ['filterText'] })

    return filtersReport
  }

  const arvoreDocumentalEmpresaTable = () => {
    const tables: Array<any> = []
    let rows: Array<any> = []
    if (dadosAgrupados) {
      iterateGroupedData(dadosAgrupados, (empresa, arvore) => {
        rows = [
          [
            { text: 'Hierarquia', style: 'columnTitle' },
            { text: 'ID', style: 'columnTitle' },
            { text: 'Nome', style: 'columnTitle' },
            { text: 'ID', style: 'columnTitle' },
            { text: 'Subordinado ao Setor', style: 'columnTitle' },
            { text: 'ID', style: 'columnTitle' },
            { text: 'Documentos Vinculados', style: 'columnTitle' }
          ]
        ]

        arvore.forEach((itemArvore) => {
          const row = [
            { text: itemArvore.hierarquia, style: 'row' },
            { text: itemArvore.codigo_setor, style: 'rowRight' },
            { text: itemArvore.nome_setor, style: 'row' },
            { text: itemArvore.codigo_setor_subordinado, style: 'rowRight' },
            { text: itemArvore.nome_setor_subordinado, style: 'row' },
            { text: itemArvore.codigo_tipo_documento, style: 'rowRight' },
            { text: itemArvore.descricao_tipo_documento, style: 'row' }
          ]

          rows.push(row as any)
        })

        tables.push([
          {
            text: `Empresa: ${empresa} \nRazão Social: ${arvore[0].nome_empresa}`,
            style: 'subtitle'
          },
          {
            table: {
              widths: [60, 45, 200, 45, 200, 45, 130],
              headerRows: 1,
              body: rows
            }
          }
        ])
      })
      return tables
    } else {
      return [{ text: '\n' }]
    }
  }

  function sumarizarOcorrencias(
    arvore: IArvoreDocumental[]
  ): Record<string, number> {
    const ocorrencias: Record<string, number> = {}
    const setoresContados: Record<string, boolean> = {}

    for (const itemArvore of arvore) {
      const hierarquia = itemArvore.hierarquia
      const nomeSetor = itemArvore.nome_setor

      if (!setoresContados[nomeSetor]) {
        if (!ocorrencias[hierarquia]) {
          ocorrencias[hierarquia] = 1
        } else {
          ocorrencias[hierarquia]++
        }
        setoresContados[nomeSetor] = true
      }
    }

    return ocorrencias
  }

  const sumarizarHierarquia = () => {
    const summaryReport: Content = []
    const ocorrenciasHierarquia = sumarizarOcorrencias(data)
    for (const hierarquia in ocorrenciasHierarquia) {
      summaryReport.push({
        text: `Total ${hierarquia}: `,
        style: ['summaryText', 'bold']
      })
      summaryReport.push({
        text: `${ocorrenciasHierarquia[hierarquia]}`,
        style: ['summaryText']
      })
      summaryReport.push({
        text: `\n`
      })
    }

    return summaryReport
  }
  await TemplateReport({
    id_usuario,
    reportTitle: 'Árvore Documental',
    mode,
    content: arvoreDocumentalEmpresaTable(),
    filterText: await filterReportText(),
    summary: sumarizarHierarquia(),
    pageOrientation: 'landscape'
  })
}
