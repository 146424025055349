export const corPendente = 'rgba(0, 186, 52, 0.1)'
export const corPendenteTexto = 'rgb(0, 186, 52)'
export const corExecutando = 'rgba(0, 133, 255, 0.1)'
export const corExecutandoTexto = 'rgb(0, 133, 255)'
export const corAtrasoPendente = 'rgba(233, 44, 44, 0.15)'
export const corAtrasoPendenteTexto = 'rgb(233, 44, 44)'
export const corAtrasoExecucao = 'rgba(249, 134, 0, 0.15)'
export const corAtrasoExecucaoTexto = 'rgb(249, 134, 0)'
export const corFundoLinha = '#f6f6f6'

export const corConteudoBotaoTabela = '#0076ff'
export const corFundoBotaoTabela = '#e0f6ff'

export const defaultWidgetSummaryStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: 0,
  padding: 0,
  gap: '1rem',
  maxWidth: '373px',
  maxHeight: '373px'
}
