import { useState } from 'react'
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import BackspaceIcon from '@mui/icons-material/Backspace'
import { Select } from '../../../components/Form/Select'
import { Loading } from '../../../components/Loading'
import { useUsuario } from '../../../hooks/usuario/useUsuario'
import { ComponentGroup } from '../../../components/ComponentGroup'
import { useEmpresa } from '../../../hooks/empresa/useEmpresa'
import { useSetor } from '../../../hooks/setor/useSetor'
import { useContratoPermitido } from '../../../hooks/contratoPermitido/useContratoPermitido'
import { IUsuario } from '../../../services/usuario/types'
import { ISetor } from '../../../services/setor/types'
import { ApiSetorPermitido } from '../../../services/setorPermitido'
import { ISetorPermitidoRequest } from '../../../services/setorPermitido/types'

export function Form() {
  const [idEmpresa, setIdEmpresa] = useState<string>('')
  const [idSetor, setIdSetor] = useState<string>('')
  const [listaSetoresFiltrados, setListaSetoresFiltrados] = useState<ISetor[]>(
    []
  )

  const [listaSetoresDisponiveis, setListaSetoresDisponiveis] = useState<
    ISetor[]
  >([])
  const [listaSetoresPermitidos, setListaSetoresPermitidos] = useState<
    ISetor[]
  >([])
  const [listaUsuariosFiltrados, setListaUsuariosFiltrados] = useState<
    IUsuario[]
  >([])
  const [idUsuario, setIdUsuario] = useState('')
  const { data: listaEmpresas, isLoading: isLoadingEmpresas } =
    useEmpresa.ListAll()
  const { data: listaSetores, isLoading: isLoadingSetores } = useSetor.ListAll()
  const { data: usuarios, isLoading } = useUsuario.ListAll()
  const { data: contratos } = useContratoPermitido.FindByUsuarioLogado()

  const localData = localStorage.getItem('@easydoc:user')
  let userData: any
  if (localData) {
    userData = JSON.parse(localData)
  }

  const userEmpresas = listaEmpresas?.filter((empresa) => {
    return contratos?.some((contrato) => {
      return (
        empresa.id_empresa === userData.pessoa.setor.empresa.id_empresa ||
        empresa.id_empresa === contrato.id_empresa_contratante
      )
    })
  })

  const userEmpresaUnica = listaEmpresas?.filter((empresa) => {
    return empresa.id_empresa === userData.pessoa.setor.empresa.id_empresa
  })

  const handleEmpresaChange = (id_empresa: string) => {
    setIdEmpresa(id_empresa)
    if (id_empresa === '') {
      return
    }
    const newListaSetores =
      listaSetores?.filter(
        (setor) => setor.empresa.id_empresa === id_empresa
      ) ?? []
    setIdSetor('')
    setIdUsuario('')
    setListaUsuariosFiltrados([])
    setListaSetoresFiltrados(newListaSetores)
  }

  const handleSetorChange = (id_setor: string) => {
    setIdSetor(id_setor)
    if (id_setor === '') {
      return
    }
    const newListaUsuarios =
      usuarios?.filter(
        (usuario) => usuario.pessoa.setor.id_setor === id_setor
      ) ?? []
    setIdUsuario('')
    setListaUsuariosFiltrados(newListaUsuarios)
  }

  const handleUsuarioChange = async (id_usuario: string) => {
    setIdUsuario(id_usuario)

    const setoresPermitidosUsuario = await ApiSetorPermitido.findByUserId(
      id_usuario
    )

    const mappedSetoresPermitidos = setoresPermitidosUsuario.map(
      (setorPermitido) => {
        return setorPermitido.setor as ISetor
      }
    )

    setListaSetoresPermitidos(
      mappedSetoresPermitidos ? mappedSetoresPermitidos : []
    )
    const newSetoresDisponiveis = listaSetores?.filter(
      (setor) =>
        !setoresPermitidosUsuario.find(
          (setorPermitido) => setorPermitido.id_setor === setor.id_setor
        )
    )
    const newSetoresDisponíveisPermitidos = newSetoresDisponiveis?.filter(
      (setor) => {
        return contratos?.some((contrato) => {
          return setor.empresa.id_empresa === contrato.id_empresa_contratante
        })
      }
    )
    setListaSetoresDisponiveis(newSetoresDisponíveisPermitidos ?? [])
  }

  const handleAddPermission = async ({
    id_usuario,
    id_setor
  }: ISetorPermitidoRequest) => {
    await ApiSetorPermitido.create({
      id_setor,
      id_usuario
    })

    const setorDisponivel = listaSetoresDisponiveis.find(
      (setorDisponivel) => setorDisponivel.id_setor === id_setor
    )

    const novosSetoresDisponiveis = listaSetoresDisponiveis.filter(
      (setorDisponivel) => setorDisponivel.id_setor !== id_setor
    )

    setListaSetoresDisponiveis(novosSetoresDisponiveis)

    if (setorDisponivel)
      setListaSetoresPermitidos([...listaSetoresPermitidos, setorDisponivel])
  }

  const handleRemovePermission = async ({
    id_usuario,
    id_setor
  }: ISetorPermitidoRequest) => {
    await ApiSetorPermitido.remove({
      id_usuario,
      id_setor
    })

    const setorRemovido = listaSetoresPermitidos.find(
      (setorPermitido) => setorPermitido.id_setor === id_setor
    )

    const novosSetoresPermitidos = listaSetoresPermitidos.filter(
      (setorPermitido) => setorPermitido.id_setor !== id_setor
    )

    setListaSetoresPermitidos(novosSetoresPermitidos)
    if (setorRemovido)
      setListaSetoresDisponiveis([...listaSetoresDisponiveis, setorRemovido])
  }

  if (isLoading || isLoadingEmpresas || isLoadingSetores) <Loading />

  return (
    <Box component="form" mt={4}>
      <Stack gap={2}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2
          }}
        >
          <Select
            label="Empresa*"
            placeholder="Selecione a Empresa"
            data={userEmpresas?.length === 0 ? userEmpresaUnica : userEmpresas}
            fields={['id_empresa', 'nome']}
            onChange={(e) => handleEmpresaChange(String(e.target.value))}
            value={idEmpresa}
          />
          <Select
            label="Setor*"
            placeholder="Selecione o Setor"
            data={listaSetoresFiltrados}
            fields={['id_setor', 'nome']}
            onChange={(e) => handleSetorChange(String(e.target.value))}
            value={idSetor}
          />
        </Box>
        <Select
          label="Usuário*"
          placeholder="Selecione o Usuario"
          data={listaUsuariosFiltrados}
          fields={['id_usuario', 'descricao']}
          onChange={(e) => handleUsuarioChange(String(e.target.value))}
          value={idUsuario}
        />

        <Stack direction="row" gap={2}>
          <ComponentGroup title="Setores Disponíveis" width={'50%'}>
            <List sx={{ height: '360px', overflow: 'auto' }}>
              {listaSetoresDisponiveis
                .sort((a, b) => {
                  const empresaA = a.empresa ? a.empresa.nome : ''
                  const empresaB = b.empresa ? b.empresa.nome : ''
                  if (empresaA > empresaB) return 1
                  if (empresaA < empresaB) return -1
                  return 0
                })
                .map((p: ISetor, index: number) => {
                  const empresaA = p.empresa ? p.empresa.nome : ''
                  return (
                    <ListItem
                      // key={p.key}
                      key={index}
                      disableGutters
                      secondaryAction={
                        <IconButton
                          onClick={() => {
                            handleAddPermission({
                              id_usuario: idUsuario,
                              id_setor: p.id_setor ?? ''
                            })
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText primary={`${empresaA} -> ${p.nome}`} />
                    </ListItem>
                  )
                })}
            </List>
          </ComponentGroup>

          <ComponentGroup title="Setores Permitidos" width={'50%'}>
            <List sx={{ height: '360px', overflow: 'auto' }}>
              {listaSetoresPermitidos
                .sort((a, b) => {
                  if (a.nome > b.nome) return 1
                  if (a.nome < b.nome) return -1
                  return 0
                })
                .map((p: ISetor, index: number) => (
                  <ListItem
                    // key={p}
                    key={index}
                    disableGutters
                    secondaryAction={
                      <IconButton
                        onClick={() =>
                          handleRemovePermission({
                            id_usuario: idUsuario,
                            id_setor: p.id_setor ? p.id_setor : ''
                          })
                        }
                      >
                        <BackspaceIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText primary={p.nome} />
                  </ListItem>
                ))}
            </List>
          </ComponentGroup>
        </Stack>
      </Stack>
    </Box>
  )
}
