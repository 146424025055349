import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Pagination,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useDocumento } from '../../hooks/documento/useDocumento'
import PreviewIcon from '@mui/icons-material/DocumentScannerOutlined'
import { SetStateAction, useState } from 'react'
import { Input } from '../Form/Input'
import PostAddIcon from '@mui/icons-material/PostAdd'
import { IAddDocumentoDiretorioRequest } from '../../services/documento/types'

export interface ModalDocumentsBoxProps {
  id_setor: string
  id_diretorio: string
  handleClose: () => void
  open: boolean
  handleAddDocumentToDirectory: (
    data: IAddDocumentoDiretorioRequest
  ) => Promise<void>
  handleDigitalFile: (
    id_contrato: string,
    filePath: string,
    id_documento: string,
    action: 'download' | 'preview'
  ) => void
}

export function ModalDocumentosSetor({
  id_setor,
  id_diretorio,
  open,
  handleClose,
  handleDigitalFile,
  handleAddDocumentToDirectory
}: ModalDocumentsBoxProps) {
  const [page, setPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')
  const [filterEdit, setFilterEdit] = useState('')
  const { data: documentosPagination, isLoading } =
    useDocumento.FindAllWithoutDriveByIdSetor(id_setor, page, searchQuery)

  const documentosDigitais = Array.isArray(documentosPagination?.data)
    ? documentosPagination?.data.filter((doc) => !!doc.path)
    : []

  const modalHeight =
    documentosDigitais && documentosDigitais?.length > 0 ? '500px' : '250px'

  return (
    <Modal
      open={open}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        top: '20%'
      }}
    >
      <Box
        borderRadius="4px"
        borderColor="lightgray"
        sx={{
          bgcolor: 'background.paper',
          boxShadow: 24,
          width: {
            sm: '780px',
            md: '880px'
          },
          height: modalHeight
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          bgcolor="#2835ad"
          px={2}
          sx={{
            borderTopRightRadius: '4px',
            borderTopLeftRadius: '4px'
          }}
        >
          <Typography color="#FFF" py={1.5} variant="subtitle1">
            Adicionar Documentos Digitais ao Diretório
          </Typography>

          <Tooltip title="Fechar" arrow>
            <IconButton onClick={() => handleClose()}>
              <CloseIcon sx={{ color: '#fff' }} />
            </IconButton>
          </Tooltip>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            marginBottom: '20px'
          }}
          mx={3}
          mt={3}
        >
          <Input
            fullWidth
            label="Filtro"
            variant="outlined"
            placeholder="Descrição | Tipo de Documento"
            onChange={(e) => setFilterEdit(e.target.value)} // Implement this function to update search query state
            value={filterEdit}
          />
          <Button
            variant="contained"
            onClick={() => setSearchQuery(filterEdit)} // Implement this function to perform the search
          >
            Pesquisar
          </Button>
        </Box>
        <Box px={2} py={1}>
          {!!documentosDigitais?.length && (
            <>
              <Stack
                gap={2}
                direction="row"
                alignItems="center"
                sx={{ marginTop: 2, padding: 0 }}
              >
                <Typography sx={{ width: '460px' }} fontWeight="600">
                  Descrição
                </Typography>
                <Typography sx={{ width: '190px' }} fontWeight="600">
                  Tipo de Documento
                </Typography>
                <Typography fontWeight="600" sx={{ width: '100px' }}>
                  Privacidade
                </Typography>
                <Typography fontWeight="600" sx={{ width: '120px' }}>
                  Ações
                </Typography>
              </Stack>

              <Divider sx={{ margin: 0, padding: 0 }} />
            </>
          )}

          <Box
            sx={{
              overflow: 'auto',
              maxHeight: 320
            }}
          >
            {documentosDigitais?.map((doc) => (
              <Stack
                key={doc.id_documento}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography sx={{ width: '450px' }}>{doc.descricao}</Typography>
                <Typography sx={{ width: '190px' }}>
                  {doc.tipo_documento?.descricao}
                </Typography>
                <Typography sx={{ width: '100px' }}>
                  {doc.dados_pessoais ? (
                    <span>
                      [<strong>{' P '}</strong>]
                    </span>
                  ) : null}
                  {doc.dados_pessoais_sensiveis ? (
                    <span>
                      [<strong>{' S '}</strong>]
                    </span>
                  ) : null}
                  {doc.dados_criancas_adolescentes ? (
                    <span>
                      [<strong>{' C '}</strong>]
                    </span>
                  ) : null}
                </Typography>
                <Stack
                  key={doc.id_documento}
                  direction="row"
                  alignItems="center"
                  gap={0}
                  sx={{ width: '120px' }}
                >
                  <Tooltip title="Visualizar documento" arrow>
                    <IconButton
                      onClick={() =>
                        handleDigitalFile(
                          doc.id_contrato ?? '',
                          doc.path ?? '',
                          doc.id_documento!,
                          'preview'
                        )
                      }
                      disabled={!doc.path}
                    >
                      <PreviewIcon color="primary" fontSize="large" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Adicionar Documento ao Diretório" arrow>
                    <IconButton
                      onClick={() =>
                        handleAddDocumentToDirectory({
                          id_documento: doc.id_documento!,
                          id_diretorio
                        })
                      }
                      disabled={!doc.path}
                    >
                      <PostAddIcon color="primary" fontSize="large" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>
            ))}

            {documentosDigitais?.length === 0 && !isLoading && (
              <Typography
                sx={{
                  margin: 2,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 1,
                  fontSize: '1.2rem'
                }}
              >
                <PreviewIcon fontSize="large" />
                Nenhum documento encontrado.
              </Typography>
            )}

            {documentosPagination?.pagination &&
              documentosPagination.pagination.totalItems >
                documentosPagination.pagination.itemsPerPage && (
                <Pagination
                  count={documentosPagination?.pagination.totalPages}
                  sx={{ marginTop: '12px' }}
                  onChange={(_: any, newPage: SetStateAction<number>) =>
                    setPage(newPage)
                  }
                  page={documentosPagination.pagination.currentPage}
                />
              )}
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
