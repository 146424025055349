import {
  BuildCircle,
  CheckCircle,
  Close,
  DoneAll,
  History,
  MoreHoriz
} from '@mui/icons-material'
import RequestIcon from '@mui/icons-material/AutoAwesomeMotionOutlined'

import {
  IProtocoloData,
  IRequisicaoDashboard,
  ITipoAtividade
} from '../../../../services/dashboard/types'
import { useState } from 'react'
import { ManageSearch } from '@mui/icons-material'
import PrintIcon from '@mui/icons-material/LocalPrintshopOutlined'
import ReceiveIcon from '@mui/icons-material/ViewInAr'

import { DashboardWidgetSummary } from '../../../../types/DashboardWidget/DashboardWidgetSummary'
import { DashboardWidgetSummaryBody } from '../../../../types/DashboardWidget/DashboardWidgetSummary/DashboardWidgetSummaryBody'
import { DashboardWidgetSummaryHeader } from '../../../../types/DashboardWidget/DashboardWidgetSummary/DashboardWidgetSummaryHeader'
import { DashboadWidgetSummaryHeaderToolbar } from '../../../../types/DashboardWidget/DashboardWidgetSummary/DashboardWidgetSummaryHeader/DashboadWidgetSummaryHeaderToolbar/index.tsx'
import { DashboardWidgetSummaryHeaderDatabar } from '../../../../types/DashboardWidget/DashboardWidgetSummary/DashboardWidgetSummaryHeader/DashboardWidgetSummaryHeaderDatabar'
import { TWidgetState } from '../../../../types/DashboardWidget/WidgetState'
import { DashboardWidget } from '../../../../types/DashboardWidget'
import { DashboardWidgetList } from '../../../../types/DashboardWidget/DashboardWidgetList'
import { DashboardWidgetListHeader } from '../../../../types/DashboardWidget/DashboardWidgetList/DashboardWidgetListHeader'
import { DashboardWidgetListBody } from '../../../../types/DashboardWidget/DashboardWidgetList/DashboardWidgetListBody'
import { ReactComponent as CloseButton } from '../../../../assets/images/botaoFechar.svg'
import { getCodigo } from '../../../../utils/getCodigo'
import { formata } from '../../../../utils/formataData'
import { DashboardWidgetForm } from '../../../../types/DashboardWidget/DashboardWidgetForm'
import useToast from '../../../../hooks/toast/useToast'
import {
  corAtrasoPendente,
  corFundoLinha,
  defaultWidgetSummaryStyle
} from '../lib/consts'
import { atrasoAtividade } from '../../../../utils/atrasoAtividade'
import { corTextoLinha } from '../../../../types/DashboardWidget/cores'
import { IRequisicaoNaoAtendida } from '../../../../services/requisicao/types'
import { cloneData } from '../../../../utils/cloneData'
import { useLote } from '../../../../hooks/lote/useLote'
import { ILote } from '../../../../services/lote/types'
import { loteToProcoloRequisicao } from '../../../../utils/loteToProcoloRequisicao'
import { LoteReport } from '../lib/LoteReport'
import { useUsuario } from '../../../../hooks/usuario/useUsuario'
import { ProtocoloEletronico } from '../lib/ProtocoloEletronico'
import { isEqual } from '../../../../utils/isEqual'
import { TWidgetAtividade } from '../lib/@types/TWidgetAtividade'

interface WidgetProtocoloProps {
  data: IProtocoloData
  dataRequisicoes: IRequisicaoDashboard[]
  tipoAtividade: ITipoAtividade
  showSLA: boolean
  onStateChange: (tipoAtividade: TWidgetAtividade) => void
}

export function WidgetProtocolo({
  data,
  dataRequisicoes,
  tipoAtividade,
  onStateChange,
  showSLA = true
}: WidgetProtocoloProps) {
  const { data: usuarioLogado } = useUsuario.FindByUsername()
  const permissoes = usuarioLogado?.permissoes as Array<number>
  const hasAccess = (permission: number) => permissoes.includes(permission)
  const { mutateAsync: createLote } = useLote.Create()
  const { mutateAsync: updateLote } = useLote.Update()
  const [lote, setLote] = useState<string>('')
  const [requisicao, setRequisicao] = useState<string>('')
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [viewForm, setViewForm] = useState<'LOTE' | 'REQUISICAO'>('LOTE')

  const widgetSummaryHeight = '400px'
  const widgetSummaryWidth = '400px'
  const widgetOtherWidth = '100%'
  const widgetOtherHeight = '840px'
  const { Toast } = useToast()
  //const processedData = processData(data, tipoAtividade)
  const processedData = data
  const [requisicaoAtual, setRequisicaoAtual] =
    useState<IRequisicaoNaoAtendida>()
  const [dadosLote, setDadosLote] = useState<ILote>()
  const [dadosLoteInicial, setDadosLoteInicial] = useState<ILote>()
  const [loteModificado, setLoteModificado] = useState<boolean>(false)

  const [requisicaoLote, setRequisicaoLote] = useState<IRequisicaoDashboard>()

  const [widgetState, setWidgetState] = useState<TWidgetState>('SUMMARY')
  const [maxWidgetHeight, setMaxWidgetHeight] =
    useState<string>(widgetSummaryHeight)
  const [maxWidgetWidth, setMaxWidgetWidth] =
    useState<string>(widgetSummaryWidth)

  const handleMoreClick = (): void => {
    setWidgetState('LIST')
    setMaxWidgetHeight(widgetOtherHeight)
    setMaxWidgetWidth(widgetOtherWidth)
    onStateChange(tipoAtividade)
  }

  const handleCloseListClick = (): void => {
    setWidgetState('SUMMARY')
    setMaxWidgetHeight(widgetSummaryHeight)
    setMaxWidgetWidth(widgetSummaryWidth)
    onStateChange(undefined)
  }

  const handleViewItemClick = (id: string): void => {
    setViewForm('REQUISICAO')
    setWidgetState('FORM')
    setMaxWidgetHeight(widgetOtherHeight)
    setMaxWidgetWidth(widgetOtherWidth)
    //verificar regra de negocio para pegar a requisicao correta
    const newRequisicaoAtual = processedData.data.requisicoes.tableData.filter(
      (req) => req.id_requisicao === id
    )

    if (newRequisicaoAtual.length > 0) {
      setRequisicaoAtual(
        cloneData<IRequisicaoNaoAtendida>(newRequisicaoAtual.at(0)!)
      )
    }
    onStateChange(tipoAtividade)
  }

  const handleCloseFormClick = (): void => {
    setWidgetState('LIST')
    setMaxWidgetHeight(widgetOtherHeight)
    setMaxWidgetWidth(widgetOtherWidth)
    onStateChange(tipoAtividade)
  }

  const handleCreateBatchDataChange = (newLote: ILote) => {
    setDadosLote(newLote)
  }

  const handleCreateNewBatchClick = async () => {
    if (!!dadosLote && dadosLote.itens_lote.length > 0) {
      const formData = {
        id_requisicao: dadosLote.id_requisicao,
        previsao: dadosLote.previsao,
        observacao: dadosLote.observacao,
        itens_lote: [...dadosLote.itens_lote]
      }
      await Toast(createLote(formData), 'CREATED')
      setDadosLote(undefined)
      setWidgetState('LIST')
      onStateChange(tipoAtividade)
    }
  }

  const handlePrintClick = async (lote: ILote) => {
    const { caixas, usuario, requisicao, documentos } =
      await loteToProcoloRequisicao(lote)

    LoteReport({
      usuarioLogado,
      data: {
        usuario,
        caixas,
        requisicao,
        documentos,
        id_lote: lote.id_lote ?? '',
        id_usuario_validou: lote.id_usuario_validou,
        validado_em: lote.validado_em,
        tokenRequisicao: (lote.requisicao?.token ?? '')
          .slice(-15)
          .replace('.', '')
          .toUpperCase()
      }
    })
  }

  const handleProtocoloDigitalClick = (lote: ILote) => {
    setLote(lote.id_lote ?? '')
    setRequisicao(lote.id_requisicao)
    setOpenModal(true)
  }

  const handleLoteSelectClick = (lote: ILote) => {
    setDadosLote(lote)
    const req = dataRequisicoes.filter(
      (requisicao) => requisicao.id_requisicao === lote.id_requisicao
    )
    if (req.length > 0) {
      setRequisicaoLote(req.at(0))
      setViewForm('LOTE')
      setDadosLoteInicial(lote)
      setLoteModificado(false)
      setWidgetState('FORM')
    }
  }

  const handleFormDataChange = (lote: ILote): void => {
    setDadosLote(lote)
    setLoteModificado(!isEqual(lote, dadosLoteInicial))
  }

  const handleFinishClick = async () => {
    const newFormData = {
      id_lote: dadosLote!.id_lote,
      id_requisicao: dadosLote!.id_requisicao,
      previsao: dadosLote!.previsao,
      observacao: dadosLote!.observacao,
      assinado: dadosLote!.assinado,
      id_usuario_validou: dadosLote!.id_usuario_validou,
      validado_em: dadosLote!.validado_em,
      itens_lote: [
        ...dadosLote!.itens_lote.map((itemLote) => ({
          id_movimentacao: itemLote.id_movimentacao
        }))
      ],
      executado: true
    }
    if (dadosLote) {
      await Toast(updateLote(newFormData), 'UPDATED')
      setWidgetState('LIST')
    }
  }

  const handleSaveClick = async () => {
    const newFormData = {
      id_lote: dadosLote!.id_lote,
      id_requisicao: dadosLote!.id_requisicao,
      previsao: dadosLote!.previsao,
      observacao: dadosLote!.observacao,
      assinado: dadosLote!.assinado,
      id_usuario_validou: dadosLote!.id_usuario_validou,
      validado_em: dadosLote!.validado_em,
      itens_lote: [
        ...dadosLote!.itens_lote.map((itemLote) => ({
          id_movimentacao: itemLote.id_movimentacao
        }))
      ]
    }
    if (dadosLote) {
      await Toast(updateLote(newFormData), 'UPDATED')
    }
  }

  const chartData = {
    labels: processedData.summaryData.chartData.map((dt) => dt.descricao),
    datasets: [
      {
        label: 'Total de Itens',
        data: processedData.summaryData.chartData.map((dt) => dt.quantidade),
        backgroundColor: ['rgb(233, 44, 44)', '#219653'],
        hoverOffset: 4
      }
    ]
  }
  const colunasSlaAtraso = showSLA
    ? [
        {
          title: 'SLA (horas)'
        },
        {
          title: 'Atraso Total (horas)'
        }
      ]
    : []
  const colunasListaLotes = [
    {
      title: 'Código'
    },
    {
      title: 'Cód. Requisição'
    },
    {
      title: 'Contrato'
    },
    {
      title: 'Itens Disponíveis'
    },
    {
      title: 'Aberto em'
    },
    ...colunasSlaAtraso,
    {
      title: '',
      width: 50
    }
  ]
  const colunasListaRequisicoes = [
    {
      title: 'Código'
    },
    {
      title: 'Contrato'
    },
    {
      title: 'Requisitante'
    },
    {
      title: 'Itens Disponíveis'
    },
    {
      title: 'Aberta em'
    },
    ...colunasSlaAtraso,
    {
      title: ''
      // width: 50
    }
  ]
  return (
    <>
      {widgetState === 'SUMMARY' && (
        <DashboardWidget.Root
          sx={{
            borderRadius: '20px',
            boxShadow:
              '0px 8px 16px -4px rgba(24, 39, 75, 0.08), 0px 6px 0px -6px rgba(0, 0, 0, 0.25)',
            backgroundColor: '#fff',
            margin: '2rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1rem',
            maxHeight: maxWidgetHeight,
            maxWidth: maxWidgetWidth
          }}
        >
          <DashboardWidgetSummary.Root sx={{ ...defaultWidgetSummaryStyle }}>
            <DashboardWidgetSummaryHeader.Root>
              <DashboadWidgetSummaryHeaderToolbar.Root>
                <DashboadWidgetSummaryHeaderToolbar.Type
                  type={tipoAtividade.identificacao}
                />
                <DashboadWidgetSummaryHeaderToolbar.Action
                  icon={MoreHoriz}
                  onClick={handleMoreClick}
                />
              </DashboadWidgetSummaryHeaderToolbar.Root>
              <DashboardWidgetSummaryHeader.Title
                title={processedData.summaryData.totalItens.toString()}
              />
              <DashboardWidgetSummaryHeaderDatabar.Root>
                {processedData.summaryData.headerData.map((dt, index) => (
                  <DashboardWidgetSummaryHeaderDatabar.Data
                    key={`wRequisicoesData${index}`}
                    data={Math.floor(dt.quantidade).toString()}
                    title={dt.descricao}
                  />
                ))}
              </DashboardWidgetSummaryHeaderDatabar.Root>
            </DashboardWidgetSummaryHeader.Root>
            <DashboardWidgetSummaryBody.Root>
              <DashboardWidgetSummaryBody.Pie data={chartData} />
            </DashboardWidgetSummaryBody.Root>
          </DashboardWidgetSummary.Root>
        </DashboardWidget.Root>
      )}

      {widgetState === 'LIST' && (
        <DashboardWidget.Root
          sx={{
            borderRadius: '20px',
            boxShadow:
              '0px 8px 16px -4px rgba(24, 39, 75, 0.08), 0px 6px 0px -6px rgba(0, 0, 0, 0.25)',
            backgroundColor: '#fff',
            // margin: '2rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1rem',
            // maxHeight: maxWidgetHeight,
            maxWidth: maxWidgetWidth
          }}
        >
          <DashboardWidgetList.Root
            sx={{
              width: maxWidgetWidth,
              // height: maxWidgetHeight,
              margin: 0
            }}
          >
            <DashboardWidgetListHeader.Root title={tipoAtividade.identificacao}>
              <DashboardWidgetListHeader.Data
                total={processedData.summaryData.totalItens}
                icon={History}
              />
              <DashboardWidgetListHeader.Actions>
                <DashboardWidgetListHeader.IconAction
                  icon={CloseButton}
                  onClick={handleCloseListClick}
                  tip={'Voltar para Dashboard'}
                  style={{
                    width: '40px',
                    height: '40px',
                    padding: 0,
                    margin: 0,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    borderRadius: '20px',
                    border: 0
                  }}
                />
              </DashboardWidgetListHeader.Actions>
            </DashboardWidgetListHeader.Root>
            <DashboardWidgetListBody.Root>
              <DashboardWidgetListBody.Table
                title={'Lotes'}
                columnsDefs={colunasListaLotes}
              >
                {processedData.data.lotes.tableData.map((lote, idx) => {
                  const slaValues = showSLA
                    ? []
                    : [
                        (lote?.itens_lote?.length > 0 &&
                          lote.itens_lote[0].movimentacao?.preco_atividade
                            ?.sla) ??
                          '',
                        lote?.itens_lote?.length > 0 &&
                          atrasoAtividade(
                            lote.itens_lote[0].movimentacao?.preco_atividade
                              ?.sla ?? 0,
                            lote?.itens_lote[0]?.movimentacao?.data_inicio
                          )
                      ]
                  const values = [
                    getCodigo(lote.id_lote ?? ''),
                    getCodigo(lote.id_requisicao),
                    lote.requisicao?.item_contrato?.contrato?.descricao ?? '',
                    lote?.itens_lote?.length,
                    formata(
                      lote?.itens_lote?.length > 0 &&
                        lote.itens_lote[0].movimentacao?.data_inicio
                        ? new Date(lote.itens_lote[0].movimentacao?.data_inicio)
                        : new Date(),
                      "dd/MM/yyyy 'às' HH:mm"
                    ),
                    ...slaValues
                  ]
                  return (
                    <DashboardWidgetListBody.Row
                      sx={{
                        backgroundColor:
                          lote?.itens_lote?.length > 0 &&
                          atrasoAtividade(
                            lote.itens_lote[0].movimentacao?.preco_atividade
                              ?.sla ?? 0,
                            lote?.itens_lote[0]?.movimentacao?.data_inicio
                          ) > 0
                            ? corAtrasoPendente
                            : idx % 2 === 0
                            ? corFundoLinha
                            : ''
                      }}
                      cellStyle={{
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: '0.8rem',
                        color: corTextoLinha,
                        paddingBottom: 0,
                        paddingTop: 0,
                        borderBottom: 0,
                        height: '40px'
                      }}
                      values={values}
                      key={
                        lote.id_lote +
                        Math.floor(Math.random() * 10000).toString()
                      }
                    >
                      <DashboardWidgetListBody.Actions>
                        <DashboardWidgetListBody.Action
                          tip="Consultar"
                          icon={ManageSearch}
                          variant="contained"
                          color="primary"
                          sx={{ minWidth: '24px', padding: '6px' }}
                          disabled={!lote.assinado}
                          onClick={() => {
                            handleLoteSelectClick(lote)
                          }}
                        />
                        <DashboardWidgetListBody.Action
                          tip="Imprimir"
                          icon={PrintIcon}
                          variant="contained"
                          color="primary"
                          sx={{ minWidth: '24px', padding: '6px' }}
                          onClick={() => {
                            handlePrintClick(lote)
                          }}
                        />
                        <DashboardWidgetListBody.Action
                          tip="Protoloco Eletrônico"
                          icon={ReceiveIcon}
                          variant="contained"
                          color="primary"
                          sx={{ minWidth: '24px', padding: '6px' }}
                          disabled={lote.assinado}
                          onClick={() => {
                            handleProtocoloDigitalClick(lote)
                          }}
                        />
                      </DashboardWidgetListBody.Actions>
                    </DashboardWidgetListBody.Row>
                  )
                })}
              </DashboardWidgetListBody.Table>
              <DashboardWidgetListBody.Table
                title={'Requisições'}
                columnsDefs={colunasListaRequisicoes}
              >
                {processedData.data.requisicoes.tableData.map(
                  (requisicao, idx) => {
                    const atraso = atrasoAtividade(
                      requisicao.itens_requisicao[0].movimentacoes[0]
                        .preco_atividade?.sla ?? 0,
                      requisicao.itens_requisicao[0].movimentacoes[0]
                        .data_inicio
                    )
                    const slaValues = showSLA
                      ? [
                          (
                            requisicao.itens_requisicao[0].movimentacoes[0]
                              .preco_atividade?.sla ?? 0
                          ).toString(),
                          atraso
                        ]
                      : []

                    const values = [
                      getCodigo(requisicao.id_requisicao ?? ''),
                      requisicao.item_contrato?.contrato?.descricao ?? '',
                      `${requisicao.usuario.pessoa.nome} ` +
                        `(${requisicao.usuario.pessoa.setor.nome} - ` +
                        `${requisicao.usuario.pessoa.setor.empresa?.nome})`,
                      requisicao.itens_requisicao.length,
                      formata(
                        new Date(
                          requisicao.itens_requisicao[0].movimentacoes[0].data_inicio
                        ),
                        "dd/MM/yyyy 'às' HH:mm"
                      ),
                      ...slaValues
                    ]
                    return (
                      <DashboardWidgetListBody.Row
                        sx={{
                          backgroundColor:
                            atraso > 0
                              ? corAtrasoPendente
                              : idx % 2 === 0
                              ? corFundoLinha
                              : ''
                        }}
                        cellStyle={{
                          fontStyle: 'normal',
                          // fontWeight: 'bold',
                          fontSize: '0.8rem',
                          color: corTextoLinha,
                          paddingBottom: 0,
                          paddingTop: 0,
                          borderBottom: 0,
                          paddingX: 0.5,
                          height: '40px'
                        }}
                        values={values}
                        key={
                          requisicao.id_requisicao +
                          Math.floor(Math.random() * 10000).toString()
                        }
                      >
                        <DashboardWidgetListBody.Actions>
                          <DashboardWidgetListBody.Action
                            tip="Criar Lote"
                            icon={RequestIcon}
                            variant="contained"
                            color="primary"
                            sx={{ minWidth: '24px', padding: '6px' }}
                            onClick={() => {
                              handleViewItemClick(
                                requisicao.id_requisicao ?? ''
                              )
                            }}
                          />
                        </DashboardWidgetListBody.Actions>
                      </DashboardWidgetListBody.Row>
                    )
                  }
                )}
              </DashboardWidgetListBody.Table>
              <ProtocoloEletronico
                open={openModal}
                handleClose={() => setOpenModal(false)}
                toast={Toast}
                id_requisicao={requisicao}
                id_lote={lote}
              />
            </DashboardWidgetListBody.Root>
          </DashboardWidgetList.Root>
        </DashboardWidget.Root>
      )}
      {widgetState === 'FORM' && viewForm === 'LOTE' && (
        <DashboardWidget.Root
          sx={{
            borderRadius: '20px',
            boxShadow:
              '0px 8px 16px -4px rgba(24, 39, 75, 0.08), 0px 6px 0px -6px rgba(0, 0, 0, 0.25)',
            backgroundColor: '#fff',
            margin: '2rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1rem',
            maxHeight: maxWidgetHeight,
            maxWidth: maxWidgetWidth
          }}
        >
          <DashboardWidgetForm.Root
            sx={{ width: maxWidgetWidth, height: maxWidgetHeight }}
          >
            <DashboardWidgetForm.Header title={tipoAtividade.identificacao}>
              <DashboardWidgetForm.Actions>
                {hasAccess(
                  tipoAtividade.permissao_movimentacao.codigo_permissao
                ) && (
                  <DashboardWidgetForm.Action
                    tip={'Finalizar Execução de Atividade'}
                    title={'Finalizar'}
                    icon={DoneAll}
                    onClick={() => handleFinishClick()}
                    variant="contained"
                    color="info"
                  />
                )}
                {hasAccess(
                  tipoAtividade.permissao_movimentacao.codigo_permissao
                ) && (
                  <DashboardWidgetForm.Action
                    tip="Salvar as modificações feitas ao lote"
                    title="Salvar"
                    variant="contained"
                    color="success"
                    icon={CheckCircle}
                    onClick={() => handleSaveClick()}
                    disabled={!loteModificado}
                  />
                )}
                <DashboardWidgetForm.Action
                  tip="Voltar para tela anterior sem salvar modificações"
                  title="Cancelar"
                  icon={Close}
                  variant="outlined"
                  color="secondary"
                  sx={{ gap: 1, color: '#1C1C1C', fontWeight: 'bold' }}
                  onClick={() => handleCloseFormClick()}
                />
              </DashboardWidgetForm.Actions>
            </DashboardWidgetForm.Header>

            <DashboardWidgetForm.Lote
              data={dadosLote!}
              requisicao={requisicaoLote!}
              setData={(data: ILote) => {
                handleFormDataChange(data)
              }}
            />
          </DashboardWidgetForm.Root>
        </DashboardWidget.Root>
      )}
      {widgetState === 'FORM' && viewForm === 'REQUISICAO' && (
        <DashboardWidget.Root
          sx={{
            borderRadius: '20px',
            boxShadow:
              '0px 8px 16px -4px rgba(24, 39, 75, 0.08), 0px 6px 0px -6px rgba(0, 0, 0, 0.25)',
            backgroundColor: '#fff',
            margin: '2rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1rem',
            maxHeight: maxWidgetHeight,
            maxWidth: maxWidgetWidth
          }}
        >
          <DashboardWidgetForm.Root
            sx={{ width: maxWidgetWidth, height: maxWidgetHeight }}
          >
            <DashboardWidgetForm.Header title={'Requisicões'}>
              <DashboardWidgetForm.Actions>
                {hasAccess(
                  tipoAtividade.permissao_movimentacao.codigo_permissao
                ) && (
                  <DashboardWidgetForm.Action
                    tip="Criar lote a partir dos itens selecionados"
                    title="Criar lote"
                    icon={BuildCircle}
                    variant="contained"
                    color="info"
                    onClick={() => handleCreateNewBatchClick()}
                  />
                )}

                <DashboardWidgetForm.Action
                  tip="Voltar para tela anterior sem salvar modificações"
                  title="Cancelar"
                  icon={Close}
                  variant="outlined"
                  color="secondary"
                  sx={{ gap: 1, color: '#1C1C1C', fontWeight: 'bold' }}
                  onClick={() => handleCloseFormClick()}
                />
              </DashboardWidgetForm.Actions>
            </DashboardWidgetForm.Header>

            <DashboardWidgetForm.RequisicaoNaoAtendida
              data={requisicaoAtual!}
              setData={handleCreateBatchDataChange}
            />
          </DashboardWidgetForm.Root>
        </DashboardWidget.Root>
      )}
    </>
  )
}
