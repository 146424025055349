import { DefaultApi } from '../../utils/defaultApi'
import api from '../api'
import {
  FindContratosByUsuario,
  IContratoPermitido,
  ICopiaPermissoes,
  IContratoPermitidoRequest,
  IContratoPermitidoUsuario
} from './types'

const endpoint = '/contrato-permitido'
const resourceId = 'id_contrato_permitido'

class ContratoPermitido extends DefaultApi<IContratoPermitido> {
  createContratoPermitido = async ({
    id_usuario,
    id_contrato
  }: IContratoPermitidoRequest) => {
    const formData = {
      id_usuario,
      id_contrato
    }

    const { data } = await api.post(`${this.endpoint}`, formData)

    return data
  }

  removePermissaoAtribuida = async ({
    id_usuario,
    id_contrato
  }: IContratoPermitidoRequest) => {
    const { data } = await api.delete(
      `${this.endpoint}/${id_usuario}/${id_contrato}`
    )
    return data
  }

  copyPermissions = async ({
    id_usuario_origem,
    id_usuario_destino
  }: ICopiaPermissoes) => {
    const formData = {
      id_usuario_origem,
      id_usuario_destino
    }
    const data = await api.post(`${this.endpoint}/copy-permissions/`, formData)

    return data
  }

  findByUserId = async (
    id_usuario: string
  ): Promise<FindContratosByUsuario> => {
    const { data } = await api.get(`${this.endpoint}/lista/${id_usuario}`)
    return data
  }

  getByLoggedUser = async (): Promise<IContratoPermitidoUsuario[]> => {
    const { data } = await api.get(`${this.endpoint}/usuario/`)
    return data
  }
}

const apiContratoPermitido = new ContratoPermitido(endpoint, resourceId)

export const ApiContratoPermitido = {
  create: apiContratoPermitido.createContratoPermitido,
  remove: apiContratoPermitido.removePermissaoAtribuida,
  copyPermissions: apiContratoPermitido.copyPermissions,
  findByUserId: apiContratoPermitido.findByUserId,
  getByLoggedUser: apiContratoPermitido.getByLoggedUser
}
