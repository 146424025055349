import { DefaultApi } from '../../../utils/defaultApi'
import { IResult, TAtivo } from '../../../utils/defaultApi/types'
import api from '../../api'
import { ICategoriaParceiro } from './types'

const endpoint = '/financeiro/categorias-parceiro'
const resourceId = 'id_categoria_parceiro'

class ServiceCategoriaParceiro extends DefaultApi<ICategoriaParceiro> {
  findAllByTipo = async (
    page: number,
    q?: string,
    ativo: TAtivo = true,
    tipo_relacionamento?: string
  ): Promise<IResult<ICategoriaParceiro>> => {
    const pageString = page ? `page=${page}` : ''
    const qString = q ? `q=${q}` : ''
    const ativoString = ativo !== undefined ? `ativo=${ativo}` : ''
    const params = [pageString, qString, ativoString, tipo_relacionamento]
      .filter((value) => value !== '')
      .join('&')

    const { data } = await api.get<IResult<ICategoriaParceiro>>(
      `${this.endpoint}/${
        tipo_relacionamento === 'C' ? 'cliente' : 'fornecedor'
      }?${params}`
    )
    return data
  }

  listAllByTipo = async (tipo_relacionamento: string) => {
    const { data } = await api.get<ICategoriaParceiro[]>(
      `${endpoint}/lista/${
        tipo_relacionamento === 'C' ? 'cliente' : 'fornecedor'
      }`
    )
    return data
  }
}

const apiCategoriaParceiro = new ServiceCategoriaParceiro(endpoint, resourceId)

export const ApiCategoriaParceiro = {
  ...apiCategoriaParceiro,
  findAllByTipo: apiCategoriaParceiro.findAllByTipo,
  listAllByTipo: apiCategoriaParceiro.listAllByTipo
}
