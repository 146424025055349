import { Box, Stack, Typography, BoxProps, styled } from '@mui/material'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import InfoIcon from '@mui/icons-material/Info'

type BoxTitleProps = {
  title: string
  children: React.ReactNode
  message?: string
  erro?: boolean
  tooltip?: NonNullable<React.ReactNode>
} & BoxProps

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
    backgroundColor: 'transparent'
  }
})

export function ComponentGroup({
  title,
  children,
  message,
  erro,
  tooltip,
  ...rest
}: BoxTitleProps) {
  return (
    <Box
      borderRadius={'5px'}
      border={1}
      borderColor={!message && !erro ? 'lightgray' : 'red'}
      {...rest}
      sx={{
        position: 'relative'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          position: 'absolute',
          top: 'calc(0% - 12px)',
          left: 'calc(0% + 6px)',
          backgroundColor: 'white',
          paddingX: '6px',
          alignItems: 'center',
          alignContent: 'center',
          gap: '4px'
        }}
      >
        <Typography
          sx={{
            fontSize: '13px',
            fontWeight: 'bold',
            color: !!message && message !== '' ? 'red' : 'gray',
            bgcolor: 'none'
          }}
        >
          {title}
        </Typography>
        {tooltip ? (
          <CustomTooltip placement="right" title={tooltip}>
            <InfoIcon sx={{ height: '1rem' }} color="secondary" />
          </CustomTooltip>
        ) : (
          ''
        )}
      </Box>

      <Stack gap={2} p={2} sx={{ minWidth: '200px' }}>
        {children}
      </Stack>
      <Typography
        sx={{
          position: 'absolute',
          top: 'calc(100% + 10px)',
          left: 'calc(0%)',
          fontSize: '12px',
          paddingX: '6px',
          color: 'red',
          visibility: message ? 'visible' : 'hidden'
        }}
      >
        {message}
      </Typography>
    </Box>
  )
}
