import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ApiCentroDocumentacao } from '../../services/centroDocumentacao'
import { ICentroDocumentacao } from '../../services/centroDocumentacao/types'

const QUERY_KEY = 'qkCentroDocumentacao'

const FindAll = (page: number, q = '') => {
  return useQuery([QUERY_KEY, q, page], () =>
    ApiCentroDocumentacao.findAll(page, q)
  )
}

const ListAll = () => {
  return useQuery([QUERY_KEY], ApiCentroDocumentacao.listAll)
}

const ListAllByIdEmpresa = (id_empresa: string) => {
  return useQuery([QUERY_KEY, id_empresa], () =>
    ApiCentroDocumentacao.listAllByIdEmpresa(id_empresa)
  )
}

const FindById = (id: string) => {
  return useQuery([QUERY_KEY, id], () => ApiCentroDocumentacao.findOne(id))
}

const Create = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiCentroDocumentacao.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Update = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiCentroDocumentacao.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Remove = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiCentroDocumentacao.remove, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const DeletePosicaoPrateleira = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiCentroDocumentacao.deletePosicaoPrateleira, {
    onSuccess: (data: ICentroDocumentacao) => {
      queryClient.invalidateQueries(QUERY_KEY)
      queryClient.invalidateQueries([QUERY_KEY, data.id_centrodocumentacao])
    }
  })
}

const AddPosicaoPrateleira = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiCentroDocumentacao.createPosicaoPrateleira, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([QUERY_KEY, data.id_centrodocumentacao])
    }
  })
}

const ToggleStatePosicaoPrateleira = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiCentroDocumentacao.togglePosicaoPrateleira, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([QUERY_KEY, data.id_centrodocumentacao])
    }
  })
}

const DeletePrateleira = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiCentroDocumentacao.deletePrateleira, {
    onSuccess: (data: ICentroDocumentacao) => {
      queryClient.invalidateQueries(QUERY_KEY)
      queryClient.invalidateQueries([QUERY_KEY, data.id_centrodocumentacao])
    }
  })
}

const AddPrateleira = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiCentroDocumentacao.createPrateleira, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([QUERY_KEY, data.id_centrodocumentacao])
    }
  })
}

const ChangePrateleira = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiCentroDocumentacao.changeTypePrateleira, {
    onSuccess: (data: ICentroDocumentacao) => {
      queryClient.invalidateQueries([QUERY_KEY, data.id_centrodocumentacao])
    }
  })
}

const DeleteEstante = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiCentroDocumentacao.deleteEstante, {
    onSuccess: (data: ICentroDocumentacao) => {
      queryClient.invalidateQueries(QUERY_KEY)
      queryClient.invalidateQueries([QUERY_KEY, data.id_centrodocumentacao])
    }
  })
}

const AddEstante = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiCentroDocumentacao.createEstante, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([QUERY_KEY, data.id_centrodocumentacao])
    }
  })
}

const DeleteCorredor = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiCentroDocumentacao.deleteCorredor, {
    onSuccess: (data: ICentroDocumentacao) => {
      queryClient.invalidateQueries(QUERY_KEY)
      queryClient.invalidateQueries([QUERY_KEY, data.id_centrodocumentacao])
    }
  })
}

const AddCorredor = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiCentroDocumentacao.createCorredor, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([QUERY_KEY, data.id_centrodocumentacao])
    }
  })
}

export const useCentroDocumentacao = {
  AddPosicaoPrateleira,
  AddPrateleira,
  AddCorredor,
  AddEstante,
  ChangePrateleira,
  Create,
  DeleteCorredor,
  DeleteEstante,
  DeletePosicaoPrateleira,
  DeletePrateleira,
  FindAll,
  FindById,
  ListAll,
  ListAllByIdEmpresa,
  Remove,
  Update,
  ToggleStatePosicaoPrateleira
}
