import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ApiTipoTemporalidade } from '../../services/tipoTemporalidade'

const QUERY_KEY = 'qkTipoTemporalidade'

const FindAll = (page: number, q = '') => {
  return useQuery([QUERY_KEY, q, page], () =>
    ApiTipoTemporalidade.findAll(page, q)
  )
}

const ListAll = () => {
  return useQuery([QUERY_KEY], () => ApiTipoTemporalidade.listAll())
}

const Create = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiTipoTemporalidade.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Update = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiTipoTemporalidade.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Remove = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiTipoTemporalidade.remove, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

export const useTipoTemporalidade = {
  Create,
  Update,
  FindAll,
  Remove,
  ListAll
}
