import { format } from 'date-fns'
import { Content } from 'pdfmake/interfaces'
import { TemplateReport } from '../../../../../../components/Report'
import { ApiContrato } from '../../../../../../services/contrato'
import { ICaixasMovimentadasPorServico } from '../../../../../../services/relatorio/types'
import { getCodigo } from '../../../../../../utils/getCodigo'
import {
  groupByField,
  iterateGroupedData
} from '../../../../../../utils/report'
import { ApiItemContrato } from '../../../../../../services/item-contrato'
import { ApiSetor } from '../../../../../../services/setor'

export interface FiltrosProps {
  id_contrato?: string
  id_item_contrato?: string
  data_inicio?: Date
  data_fim?: Date
  tipoConteudo?: string
  id_setor?: string
}

type Props = {
  id_usuario: string
  data: ICaixasMovimentadasPorServico[]
  mode?: 'open' | 'save'
  filtros: FiltrosProps
}

export async function CaixasMovimentadasPorServicoReport({
  data,
  mode,
  filtros,
  id_usuario
}: Props) {
  const sumarizarQuantidadesPorTipoLote = (
    summaryData: ICaixasMovimentadasPorServico[]
  ) => {
    const quantidadePorTipoCaixa: Record<string, number> = summaryData.reduce(
      (accumulator, item) => {
        const { tipo_lote, quantidade } = item
        accumulator[tipo_lote] = (accumulator[tipo_lote] || 0) + quantidade
        return accumulator
      },
      {} as Record<string, number>
    )
    const summaryReport: Content = []
    Object.keys(quantidadePorTipoCaixa).forEach((tipo) => {
      summaryReport.push({
        text: `Total ${tipo}: `,
        style: ['summaryText', 'bold']
      })
      summaryReport.push({
        text: `${quantidadePorTipoCaixa[tipo]}\n`,
        style: ['summaryText']
      })
    })
    summaryReport.push({
      text: `\n`,
      style: ['summaryText']
    })
    return summaryReport
  }

  const caixasMovimentadasPorServicoGroupTable = () => {
    const dadosAgrupados = groupByField(data, 'nome_servico')
    const tables: Array<any> = []
    let rows: Array<any> = []
    if (dadosAgrupados) {
      iterateGroupedData(dadosAgrupados, (servico, requisicoes) => {
        rows = [
          [
            { text: 'Cód Requisição', style: 'columnTitle' },
            { text: 'Requisitante', style: 'columnTitle' },
            { text: 'Setor', style: 'columnTitle' },
            { text: 'Data Início', style: 'columnTitle' },
            { text: 'Data Fechamento', style: 'columnTitle' },
            { text: 'Tipo Lote', style: 'columnTitle' },
            { text: 'Qtd.', style: 'columnTitle' }
          ]
        ]

        requisicoes.forEach((requisicao) => {
          const row = [
            { text: getCodigo(requisicao.id_requisicao), style: 'row' },
            { text: requisicao.nome_pessoa, style: 'row' },
            { text: requisicao.nome_setor, style: 'row' },
            {
              text: format(new Date(requisicao.data_inicio), 'dd/MM/yyyy'),
              style: 'rowCenter'
            },
            {
              text: format(new Date(requisicao.data_fechamento), 'dd/MM/yyyy'),
              style: 'rowCenter'
            },
            { text: requisicao.tipo_lote, style: 'rowCenter' },
            { text: requisicao.quantidade, style: 'quantityDoc' }
          ]

          rows.push(row as any)
        })

        const summary = sumarizarQuantidadesPorTipoLote(requisicoes)

        tables.push([
          {
            text: `Serviço: ${servico} `,
            style: 'subtitle'
          },
          {
            table: {
              widths: [80, 200, 180, 70, 70, 70, 50],
              headerRows: 1,
              body: rows
            }
          },
          [
            {
              columns: [
                {
                  text: summary,
                  style: 'filterText',
                  width: '*'
                }
              ]
            }
          ]
        ])
      })
      return tables
    } else {
      return [{ text: '\n' }]
    }
  }

  const filterReportText = async ({
    id_contrato,
    id_item_contrato,
    data_fim,
    data_inicio,
    tipoConteudo,
    id_setor
  }: FiltrosProps) => {
    const filtersReport = []
    filtersReport.push({ text: 'Contrato: ', style: ['filterText', 'bold'] })
    if (id_contrato) {
      const contrato = await ApiContrato.findOne(id_contrato)
      if (contrato) {
        filtersReport.push({
          text: contrato.descricao,
          style: ['filterText']
        })
      }
    } else filtersReport.push({ text: '<todos> ', style: ['filterText'] })

    filtersReport.push({ text: '\n' })
    filtersReport.push({ text: 'Serviço: ', style: ['filterText', 'bold'] })
    if (id_item_contrato) {
      const itemContrato = await ApiItemContrato.findOne(id_item_contrato)
      if (itemContrato) {
        filtersReport.push({
          text: itemContrato.servico.nome,
          style: ['filterText']
        })
      }
    } else filtersReport.push({ text: '<todos> ', style: ['filterText'] })

    filtersReport.push({ text: '\n' })
    filtersReport.push({ text: 'Setor: ', style: ['filterText', 'bold'] })
    if (id_setor) {
      const setor = await ApiSetor.findOne(id_setor)
      if (setor) {
        filtersReport.push({
          text: setor.nome,
          style: ['filterText']
        })
      }
    } else filtersReport.push({ text: '<todos> ', style: ['filterText'] })
    filtersReport.push({ text: '\n' })

    filtersReport.push({
      text: 'Tipo de Conteúdo: ',
      style: ['filterText', 'bold']
    })
    filtersReport.push({
      text:
        tipoConteudo === '0'
          ? '<todos>'
          : tipoConteudo === '1'
          ? 'Caixa'
          : 'Documento',
      style: ['filterText']
    })

    filtersReport.push({ text: '\n' })
    filtersReport.push({ text: 'Período: ', style: ['filterText', 'bold'] })
    if (data_inicio && data_fim) {
      filtersReport.push({
        text: `${format(new Date(data_inicio), 'dd/MM/yyyy')} até ${format(
          new Date(data_fim),
          'dd/MM/yyyy'
        )}`,
        style: ['filterText']
      })
    } else filtersReport.push({ text: '<global> ', style: ['filterText'] })

    return filtersReport
  }
  const filterText = await filterReportText(filtros)

  await TemplateReport({
    id_usuario,
    content: caixasMovimentadasPorServicoGroupTable(),
    filterText,
    reportTitle: 'Caixas / Documentos Movimentados por Serviço',
    pageOrientation: 'landscape',
    mode
  })
}
