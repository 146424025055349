import { TemplateReport } from '../../../../../../../components/Report'
import { ILancamento } from '../../../../../../../services/financeiro/lancamento/types'
import { IFinanceiroReportFilter } from '../../../../../../../components/Financeiro/FinanceiroReportForm/types'
import { filterReportText } from '../../Filter'
import {
  groupByField,
  iterateGroupedData
} from '../../../../../../../utils/report'
import { formataSomenteData } from '../../../../../../../utils/formataData'

type Props = {
  data: ILancamento[]
  mode?: 'open' | 'save'
  id_usuario: string
  filtros: IFinanceiroReportFilter
}

export async function BaixaLancamentoReport({
  data,
  mode,
  id_usuario,
  filtros
}: Props) {
  let total_baixado = 0
  let total_recebido = 0
  let total_pago = 0

  const isCliente = filtros.tipo_relacionamento === 'C'
  const isTodos = filtros.tipo_relacionamento ? false : true

  const summary = () => {
    const summaryTodos = isTodos
      ? [
          {
            text: [
              {
                text: `Total Recebido: `,
                style: ['summaryText', 'bold']
              },
              {
                text: `${Math.abs(total_recebido).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                })}\n`,
                style: ['summaryText', 'blue']
              }
            ],
            style: 'filterTextSummary',
            width: '*'
          },

          {
            text: [
              {
                text: `Total Pago: `,
                style: ['summaryText', 'bold']
              },
              {
                text: `${Math.abs(total_pago).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                })}\n`,
                style: ['summaryText', 'red']
              }
            ],
            style: 'filterTextSummary',
            width: '*'
          }
        ]
      : [{}]
    return [
      ...summaryTodos,
      {
        text: [
          {
            text: `Total ${
              !isTodos ? (isCliente ? 'Recebido' : 'Pago') : 'Recebido x Pago '
            }: `,
            style: ['summaryText', 'bold']
          },
          {
            text: `${Math.abs(total_baixado).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL'
            })}\n`,
            style: ['summaryText', `${total_baixado >= 0 ? 'blue' : 'red'}`]
          }
        ],
        style: 'filterTextSummary',
        width: '*'
      }
    ]
  }

  const dadosAgrupados = groupByField(data, 'id_parceiro')

  const baixaLancamentoPorParceiroTable = () => {
    const tables: Array<any> = []
    let rows: Array<any> = []
    if (dadosAgrupados) {
      iterateGroupedData(dadosAgrupados, (parceiro, lancamentos) => {
        const isParceiroCliente =
          lancamentos[0].parceiro?.tipo_relacionamento === 'C'
        rows = [
          [
            { text: 'Núm Documento', style: 'columnTitle' },
            { text: 'Histórico', style: 'columnTitle' },
            { text: 'Centro de Custo', style: 'columnTitle' },
            { text: 'Forma de Pagamento', style: 'columnTitle' },
            {
              text: `${isParceiroCliente ? 'Receita' : 'Despesa'}`,
              style: 'columnTitle'
            },
            { text: 'Vencimento', style: 'columnTitle' },
            { text: 'Pagamento', style: 'columnTitle' },
            { text: 'Valor', style: 'columnTitle' },
            {
              text: `Valor ${isParceiroCliente ? 'Recebido' : 'Pago'}`,
              style: 'columnTitle'
            }
          ]
        ]

        let total_baixado_parceiro = 0

        lancamentos.forEach((lancamento) => {
          const row = [
            { text: lancamento.num_documento, style: 'row' },
            { text: lancamento.historico, style: 'row' },
            {
              text: `${
                lancamento.centroCusto ? lancamento.centroCusto.descricao : ''
              }`,
              style: 'row'
            },
            {
              text: `${
                lancamento.formaPagamento
                  ? lancamento.formaPagamento.descricao
                  : ''
              }`,
              style: 'row'
            },
            {
              text: `${
                lancamento.receitaDespesa
                  ? lancamento.receitaDespesa.descricao
                  : ''
              }`,
              style: 'row'
            },
            {
              text: formataSomenteData(
                lancamento.dt_vencimento?.toString() ?? ''
              ),
              style: 'rowCenter'
            },
            {
              text: formataSomenteData(
                lancamento.dt_pagamento?.toString() ?? ''
              ),
              style: 'rowCenter'
            },
            {
              text: lancamento.valor.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }),
              style: ['quantityDoc', `${isParceiroCliente ? 'blue' : 'red'}`]
            },
            {
              text: lancamento.valor_pago.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }),
              style: ['quantityDoc', `${isParceiroCliente ? 'blue' : 'red'}`]
            }
          ]
          total_baixado_parceiro += lancamento.valor_pago

          rows.push(row as any)
        })

        if (isParceiroCliente) {
          total_baixado += total_baixado_parceiro
          total_recebido += total_baixado_parceiro
        } else {
          total_baixado -= total_baixado_parceiro
          total_pago += total_baixado_parceiro
        }

        tables.push([
          {
            text: `${isParceiroCliente ? 'Cliente: ' : 'Fornecedor: '} ${
              lancamentos[0].parceiro?.nome
            }`,
            style: 'subtitle'
          },
          {
            table: {
              widths: [80, 150, 90, 70, 60, 65, 65, 60, 60],
              headerRows: 1,
              body: rows
            }
          },
          {
            text: [
              {
                text: `Total ${
                  isParceiroCliente ? 'Recebido Cliente' : 'Pago Fornecedor'
                }: `,
                style: ['total']
              },
              {
                text: `${Math.abs(total_baixado_parceiro).toLocaleString(
                  'pt-BR',
                  {
                    style: 'currency',
                    currency: 'BRL'
                  }
                )}`,
                style: ['total', `${isParceiroCliente ? 'blue' : 'red'}`]
              }
            ]
          },
          {
            text: '\n'
          }
        ])
      })
      tables.push(summary())
      return tables
    } else {
      return [{ text: '\n' }]
    }
  }

  const filterText = await filterReportText(filtros)

  await TemplateReport({
    id_usuario,
    content: baixaLancamentoPorParceiroTable(),
    filterText,
    pageOrientation: 'landscape',
    reportTitle: `Baixa de Lançamentos ${
      !isTodos ? (isCliente ? 'Receber' : 'Pagar') : 'Receber x Pagar'
    }`,
    mode
  })
}
