import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ApiTipoCaixa } from '../../services/tipoCaixa'

const QUERY_KEY = 'qkTipoCaixa'

const FindAll = (page: number, q = '') => {
  return useQuery([QUERY_KEY, q, page], () => ApiTipoCaixa.findAll(page, q))
}

const ListAll = () => {
  return useQuery([QUERY_KEY], ApiTipoCaixa.listAll)
}

const FindById = (id: string) => {
  return useQuery([QUERY_KEY, id], () => ApiTipoCaixa.findOne(id))
}

const Create = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiTipoCaixa.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Update = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiTipoCaixa.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Remove = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiTipoCaixa.remove, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

export const useTipoCaixa = {
  Create,
  FindAll,
  FindById,
  ListAll,
  Remove,
  Update
}
