import { useMemo, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/AddCircle'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { useTipoDocumento } from '../../../hooks/tipoDocumento/useTipoDocumento'
import { useTipoTemporalidade } from '../../../hooks/tipoTemporalidade/useTipoTemporalidade'
import useToast from '../../../hooks/toast/useToast'
import { FormMode } from '../../../types/formMode'
import { Loading } from '../../../components/Loading'
import React from 'react'
import { useUsuario } from '../../../hooks/usuario/useUsuario'
import { Permissions } from '../../../types/permissions'
import { hasAccess } from '../../../utils/hasAccess'
import { useContratoPermitido } from '../../../hooks/contratoPermitido/useContratoPermitido'
import { Select } from '../../../components/Form/Select'

interface IForm {
  setData: (value: React.SetStateAction<any>) => void
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

export function List({ setData, setFormMode }: IForm) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [idContrato, setIdContrato] = useState<string>('')
  const [q, setQ] = useState<string>('')
  const [selectedOrder, setSelectedOrder] = useState<string>('')
  const { Toast } = useToast()
  const searchInput = React.useRef<HTMLInputElement>(null)
  const { data: usuario } = useUsuario.FindByUsername()
  const { data: contratos } = useContratoPermitido.FindByUsuarioLogado()

  const handleSearchClick = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setSearchParams({ ...searchParams, q: searchTerm })
    setQ(searchTerm)
    searchInput.current?.focus()
  }

  const page = useMemo(() => {
    return Number(searchParams.get('page') ?? 1)
  }, [searchParams])

  const { mutateAsync: remove } = useTipoDocumento.Remove()
  const { data: records, isLoading } = useTipoDocumento.FindAll(
    page,
    selectedOrder,
    q,
    idContrato
  )
  const { data: temporalidade } = useTipoTemporalidade.ListAll()

  const dataTemporalidade = Object.fromEntries(
    temporalidade?.map((t) => [
      t.id_tipo_temporalidade,
      `${t.tempo} - ${t.unidade} - ${t.fundamentacao}`
    ]) ?? []
  )
  const handleNewForm = () => {
    setFormMode('CREATE')
  }

  const handleRemove = async (id: string) => await Toast(remove(id), 'REMOVED')

  const handleEditForm = (selectedData: any) => {
    setData(selectedData)
    setFormMode('EDIT')
  }

  const OrderIcon = (condition: boolean) =>
    condition ? (
      <ArrowDownwardIcon
        fontSize="small"
        sx={{ marginLeft: '5px', marginTop: '5px' }}
      />
    ) : (
      <ArrowUpwardIcon
        fontSize="small"
        sx={{ marginLeft: '5px', marginTop: '5px' }}
      />
    )

  if (isLoading) return <Loading />

  return (
    <Box>
      <Stack
        component="form"
        onSubmit={handleSearchClick}
        direction="row"
        py={3}
        gap={3}
        mb={2}
      >
        <Stack direction="row" gap={3} width="100%">
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            placeholder="Código/Descrição/Assunto/Atividade/Contrato"
            inputRef={searchInput}
            autoFocus
            value={searchTerm}
            onChange={(event) => {
              setSearchTerm(event.target.value)
            }}
          />

          <Select
            fullWidth={false}
            label="Contrato"
            placeholder="Selecione o contrato"
            data={[
              { id_contrato: '', descricao: 'Todos' },
              ...(contratos ?? [])
            ]}
            fields={['id_contrato', 'descricao']}
            value={idContrato}
            onChange={(e) => setIdContrato(String(e.target.value))}
          />

          <Button
            variant="outlined"
            startIcon={<SearchIcon />}
            sx={{ width: '220px', height: '36px' }}
            type="submit"
          >
            Pesquisar
          </Button>
        </Stack>
      </Stack>

      <Divider />

      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleNewForm}
        sx={{ float: 'right', my: '12px' }}
      >
        Novo registro
      </Button>

      <TableContainer
        component={Box}
        border={1}
        borderRadius={2}
        borderColor="#f2f2f2"
      >
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
              <TableCell
                sx={{ fontWeight: 'bold', fontSize: '14px' }}
                onClick={() =>
                  setSelectedOrder(
                    selectedOrder === 'Assunto' ? 'AssuntoInv' : 'Assunto'
                  )
                }
              >
                Assunto
                {OrderIcon(selectedOrder === 'Assunto')}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  selectedOrder === 'Codigo'
                    ? setSelectedOrder('CodigoInv')
                    : setSelectedOrder('Codigo')
                }}
              >
                Código
                {selectedOrder === 'Codigo' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  selectedOrder === 'Descricao'
                    ? setSelectedOrder('DescricaoInv')
                    : setSelectedOrder('Descricao')
                }}
              >
                Descrição
                {selectedOrder === 'Descricao' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>

              <TableCell
                sx={{ fontWeight: 'bold', fontSize: '14px' }}
                onClick={() =>
                  setSelectedOrder(
                    selectedOrder === 'Atividade' ? 'AtividadeInv' : 'Atividade'
                  )
                }
              >
                Atividade
                {OrderIcon(selectedOrder === 'Atividade')}
              </TableCell>

              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  selectedOrder === 'Temporalidade'
                    ? setSelectedOrder('TemporalidadeInv')
                    : setSelectedOrder('Temporalidade')
                }}
              >
                Temporalidade
                {selectedOrder === 'Temporalidade' ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ marginLeft: '5px', marginTop: '5px' }}
                  />
                )}
              </TableCell>

              <TableCell
                sx={{ fontWeight: 'bold', fontSize: '14px' }}
                onClick={() =>
                  setSelectedOrder(
                    selectedOrder === 'Contrato' ? 'ContratoInv' : 'Contrato'
                  )
                }
              >
                Contrato
                {OrderIcon(selectedOrder === 'Contrato')}
              </TableCell>

              <TableCell width="12px" />
              <TableCell width="12px" />
            </TableRow>
          </TableHead>
          <TableBody>
            {records?.data?.map((record) => (
              <TableRow key={record.id_tipo_documento} hover>
                <TableCell>{record.assunto}</TableCell>
                <TableCell>
                  {record.codigo}
                  {hasAccess(
                    usuario?.permissoes,
                    Permissions.EXIBIR_ID_TIPODOCUMENTO
                  ) ? (
                    <Typography
                      sx={{
                        fontSize: '10px',
                        fontWeight: 'bold',
                        color: 'GrayText'
                      }}
                    >
                      ({record.id_tipo_documento})
                    </Typography>
                  ) : (
                    ''
                  )}
                </TableCell>
                <TableCell>{record.descricao}</TableCell>

                <TableCell>{record.atividade}</TableCell>
                <TableCell>
                  {dataTemporalidade[record.id_tipo_temporalidade]}
                </TableCell>

                <TableCell>{record.contrato?.descricao}</TableCell>

                <TableCell>
                  <Tooltip title="Editar" arrow>
                    <Button
                      variant="outlined"
                      sx={{ minWidth: '24px', padding: '6px' }}
                      onClick={() => handleEditForm(record)}
                    >
                      <EditIcon />
                    </Button>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title="Excluir" arrow>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleRemove(record.id_tipo_documento!)}
                      sx={{ minWidth: '24px', padding: '6px' }}
                    >
                      <DeleteIcon />
                    </Button>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {records?.pagination &&
        records.pagination.totalItems > records.pagination.itemsPerPage && (
          <Pagination
            count={records?.pagination.totalPages}
            sx={{ marginTop: '12px' }}
            onChange={(_, newPage) =>
              setSearchParams({ page: String(newPage) })
            }
            page={records.pagination.currentPage}
          />
        )}
    </Box>
  )
}
