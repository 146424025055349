import { ApiRelatorio } from '../../../../../services/relatorio'
import {
  IHandleReportSubmitProps,
  ReportForm
} from '../../../../../components/ReportForm'
import { LotesArquivadosReport } from './report/LotesArquivadosReport'

export function LotesArquivadosRelatorio() {
  async function handleSubmitForm({
    mode,
    format,
    id_usuario,
    filtros
  }: IHandleReportSubmitProps) {
    const {
      data_fim,
      data_inicio,
      id_empresa,
      id_tipo_caixa,
      endereco,
      endereco_final,
      estado,
      id_setor
    } = filtros

    const lotesNaoDevolvidosData = await ApiRelatorio.getLotesArquivados({
      id_empresa,
      data_fim,
      data_inicio,
      estado,
      id_tipo_caixa,
      endereco,
      endereco_final,
      id_setor
    })

    if (format === 'pdf') {
      LotesArquivadosReport({
        data: lotesNaoDevolvidosData,
        mode,
        id_usuario: id_usuario,
        filtros: {
          id_empresa,
          data_fim,
          data_inicio,
          estado,
          id_setor,
          id_tipo_caixa,
          endereco,
          endereco_final
        }
      })
    }

    return lotesNaoDevolvidosData
  }
  return (
    <ReportForm
      title="Lotes Arquivados"
      handleReportSubmit={handleSubmitForm}
      mostrarPeriodo={true}
      mostrarEndereco={true}
      mostrarEnderecoFinal={true}
      mostrarEstado={true}
      mostrarTipoCaixa={true}
    />
  )
}
