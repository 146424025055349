import DialogUI from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import AlertIcon from '@mui/icons-material/Warning'
import { Divider } from '@mui/material'

interface IDialogProps {
  title: string
  messageText: string | React.ReactNode
  openDialog: boolean
  handleAnswerYes: () => void
  handleAnswerNo: () => void
}

export const Dialog = ({
  title,
  messageText,
  openDialog,
  handleAnswerYes,
  handleAnswerNo
}: IDialogProps) => {
  return (
    <DialogUI open={openDialog} fullWidth>
      <DialogTitle
        display="flex"
        alignItems="center"
        gap={1}
        m={0}
        p={0}
        color="#333"
        sx={{ fontWeight: 'bold' }}
      >
        <AlertIcon color="info" />
        {title}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText color="#333" sx={{ whiteSpace: 'pre-wrap' }}>
          {messageText}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ padding: '15px 22px' }}>
        <Button
          onClick={handleAnswerYes}
          color="primary"
          variant="outlined"
          fullWidth
          style={{ fontWeight: 'bold' }}
        >
          Sim
        </Button>
        <Button
          onClick={handleAnswerNo}
          color="secondary"
          variant="outlined"
          fullWidth
          style={{ fontWeight: 'bold' }}
        >
          Não
        </Button>
      </DialogActions>
    </DialogUI>
  )
}
