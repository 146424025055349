import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Chip,
  Avatar,
  Stack,
  Box,
  Divider
} from '@mui/material'
import { red } from '@mui/material/colors'
import { IMovimentacao } from '../../../../../services/movimentacao/types'
import { format, formatDistanceToNow } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { ColorType, capitalizeFirstLetter, color } from '../utils'

export interface MovimentacaoTimeLineCard {
  movimentacao: IMovimentacao
}

export function MovimentacaoTimeLineCard({
  movimentacao
}: MovimentacaoTimeLineCard) {
  const isCanceled = movimentacao.estado === 'CANCELADO'
  const isComplete = movimentacao.estado === 'COMPLETO'
  const formattedDate = movimentacao.updated_at
    ? format(new Date(movimentacao.updated_at), "dd 'de' MMMM 'de' yyyy", {
        locale: ptBR
      })
    : ''

  const timeAgo = formatDistanceToNow(new Date(movimentacao.updated_at!), {
    addSuffix: true,
    locale: ptBR
  })

  const estado = movimentacao.estado

  const chipColor = color(estado, false)

  return (
    <Card>
      <Card
        variant="outlined"
        sx={{
          borderRadius: '8px', // Bordas arredondadas
          boxShadow: 3, // Sombra
          padding: 1, // Espaçamento interno (opcional)
          backgroundColor: 'white', // Cor de fundo (opcional),
          margin: 0
        }}
      >
        <CardHeader
          avatar={
            <Chip
              label={
                isComplete || isCanceled
                  ? formattedDate
                  : capitalizeFirstLetter(estado)
              }
              color={chipColor as ColorType}
            />
          }
          action={
            <Box display="flex" alignItems="center" justifyContent="center">
              <Typography variant="body2" color="textSecondary">
                {timeAgo}
              </Typography>
            </Box>
          }
        />
        <CardContent>
          <Stack direction="column" alignItems="flex-start" spacing={2}>
            <Typography variant="body2" color="MenuText" fontSize={14}>
              {movimentacao.preco_atividade?.atividade.tipo_atividade.descricao}
            </Typography>
            {movimentacao.id_usuario && (
              <>
                <Divider flexItem />
                <Typography variant="body2" color="textSecondary">
                  Responsável
                </Typography>
                <Stack direction="row" spacing={2} alignItems={'center'}>
                  <Avatar
                    sx={{ bgcolor: red[500] }}
                    aria-label="profile-picture"
                  >
                    {movimentacao.responsavel?.pessoa.nome[0]}
                  </Avatar>
                  <Typography variant="body2" color="MenuText">
                    {movimentacao.responsavel?.pessoa.nome}
                  </Typography>
                </Stack>

                <Typography variant="body2" color="textSecondary">
                  Departamento
                </Typography>
                <Typography variant="body2" color="MenuText">
                  {movimentacao.responsavel?.pessoa.setor?.nome}
                </Typography>
              </>
            )}
          </Stack>
        </CardContent>
      </Card>
    </Card>
  )
}
