import { Button } from '@mui/material'

import LibraryAddIcon from '@mui/icons-material/LibraryAdd'

import { IPosicaoPrateleira } from '../../../../services/posicaoPrateleira/types'
import { IPrateleira } from '../../../../services/prateleira/types'
import { PosicaoPrateleiraDraw } from './PosicaoPrateleiraDraw'

interface PrateleiraDrawProps {
  prateleira: IPrateleira
  enderecoEstante: string
  openPosicaoMenu: boolean
  handlePosicaoPrateleiraClick: (
    event: React.MouseEvent<HTMLElement>,
    posicao: IPosicaoPrateleira,
    prateleira: IPrateleira
  ) => void
  handleAdicionarPosicaoClick: (
    prateleira: IPrateleira,
    enderecoEstante: string
  ) => void
}
export function PrateleiraDraw({
  prateleira,
  enderecoEstante,
  openPosicaoMenu,
  handlePosicaoPrateleiraClick,
  handleAdicionarPosicaoClick
}: PrateleiraDrawProps) {
  const getAddressPrateleira = () => {
    return enderecoEstante + '.' + prateleira.identificacao
  }
  return (
    <tr id={`ID-${getAddressPrateleira()}-POSICOES`}>
      {!prateleira.posicao_prateleira ||
        (prateleira.posicao_prateleira?.length === 0 && (
          <td>
            <Button
              onClick={() => {
                handleAdicionarPosicaoClick(prateleira, enderecoEstante)
              }}
              variant="contained"
              color="success"
            >
              <LibraryAddIcon />
              Expandir
            </Button>
          </td>
        ))}
      {prateleira.posicao_prateleira
        ?.sort((a, b) => {
          if (a.posicao_prateleira > b.posicao_prateleira) {
            return 1
          }
          if (a.posicao_prateleira < b.posicao_prateleira) {
            return -1
          }
          return 0
        })
        .map((posicao) => (
          <PosicaoPrateleiraDraw
            key={`ID-${getAddressPrateleira()}.${posicao.identificacao}`}
            enderecoPrateleira={getAddressPrateleira()}
            handleMouseClick={handlePosicaoPrateleiraClick}
            openMenu={openPosicaoMenu}
            posicaoPrateleira={posicao}
            prateleira={prateleira}
          />
        ))}
    </tr>
  )
}
