import { ApiRelatorio } from '../../../../../services/relatorio'
import { LotesNaoDevolvidosReport } from './report/LotesNaoDevolvidosReport'
import {
  IHandleReportSubmitProps,
  ReportForm
} from '../../../../../components/ReportForm'

export function LotesNaoDevolvidosRelatorio() {
  async function handleSubmitForm({
    mode,
    format,
    id_usuario,
    filtros
  }: IHandleReportSubmitProps) {
    const {
      data_fim,
      data_inicio,
      id_empresa,
      id_setor,
      atrasoDevolucao,
      tipoConteudo
    } = filtros

    const lotesNaoDevolvidosData = await ApiRelatorio.getLotesNaoDevolvidos({
      id_empresa,
      id_setor,
      data_fim,
      data_inicio,
      tipoConteudo,
      atrasoDevolucao
    })

    if (format === 'pdf') {
      LotesNaoDevolvidosReport({
        data: lotesNaoDevolvidosData,
        mode,
        id_usuario: id_usuario,
        filtros: {
          id_empresa,
          id_setor,
          data_fim,
          data_inicio,
          tipoConteudo,
          atrasoDevolucao
        }
      })
    }

    return lotesNaoDevolvidosData
  }
  return (
    <ReportForm
      title="Lotes não Devolvidos"
      handleReportSubmit={handleSubmitForm}
      mostrarPeriodo={true}
      mostrarTipoConteudo={true}
      mostrarAtraso={true}
    />
  )
}
