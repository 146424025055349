import { format } from 'date-fns'
import { IDocumentosInventario } from '../../../../../services/relatorio/types'
import { groupByField, iterateGroupedData } from '../../../../../utils/report'
import { TemplateReport } from '../../../../../components/Report'
import { ApiEmpresa } from '../../../../../services/empresa'
import ptBR from 'date-fns/locale/pt-BR'
import { Content, PageOrientation } from 'pdfmake/interfaces'
import { ApiSetor } from '../../../../../services/setor'
import { getCodigo } from '../../../../../utils/getCodigo'
import { IReportFilter } from '../../../../../components/ReportForm'
import { ApiTipoDocumento } from '../../../../../services/tipoDocumento'

type Props = {
  id_usuario: string
  data: IDocumentosInventario[]
  mode?: 'open' | 'save'
  orientation?: PageOrientation
  filtros: IReportFilter
}

export async function DocumentosInventarioReport({
  id_usuario,
  data,
  mode,
  filtros
}: Props) {
  const dadosAgrupados = groupByField(data, 'nome_empresa')

  const filterReportText = async () => {
    const {
      id_empresa,
      data_fim,
      data_inicio,
      id_setor,
      endereco,
      estado,
      id_tipo_documento
    } = filtros
    const filtersReport = []
    filtersReport.push({ text: 'Empresa: ', style: ['filterText', 'bold'] })
    if (id_empresa) {
      const empresa = await ApiEmpresa.findOne(id_empresa)
      if (empresa) {
        filtersReport.push({
          text: empresa.razao_social,
          style: ['filterText']
        })
      }
    } else filtersReport.push({ text: '<todas> ', style: ['filterText'] })

    filtersReport.push({ text: '\n' })
    filtersReport.push({ text: 'Setor: ', style: ['filterText', 'bold'] })
    if (id_setor) {
      const setor = await ApiSetor.findOne(id_setor)
      if (setor) {
        filtersReport.push({
          text: setor.nome,
          style: ['filterText']
        })
      }
    } else filtersReport.push({ text: '<todos> ', style: ['filterText'] })

    filtersReport.push({ text: '\n' })
    filtersReport.push({
      text: 'Tipo de Documento: ',
      style: ['filterText', 'bold']
    })
    if (id_tipo_documento) {
      const tipoDocumento = await ApiTipoDocumento.findOne(id_tipo_documento)
      if (tipoDocumento) {
        filtersReport.push({
          text: tipoDocumento.descricao,
          style: ['filterText']
        })
      }
    } else filtersReport.push({ text: '<todos> ', style: ['filterText'] })

    filtersReport.push({ text: '\n' })
    filtersReport.push({ text: 'Período: ', style: ['filterText', 'bold'] })
    if (data_inicio && data_fim) {
      filtersReport.push({
        text: `${format(new Date(data_inicio), 'dd/MM/yyyy', {
          locale: ptBR
        })} até ${format(new Date(data_fim), 'dd/MM/yyyy', { locale: ptBR })}`,
        style: ['filterText']
      })
    } else filtersReport.push({ text: '<global> ', style: ['filterText'] })

    filtersReport.push({ text: '\n' })
    filtersReport.push({ text: 'Endereço: ', style: ['filterText', 'bold'] })
    if (endereco) {
      filtersReport.push({
        text: endereco,
        style: ['filterText']
      })
    } else filtersReport.push({ text: '<todos> ', style: ['filterText'] })

    filtersReport.push({ text: '\n' })
    filtersReport.push({ text: 'Estado: ', style: ['filterText', 'bold'] })
    if (estado) {
      filtersReport.push({
        text: estado,
        style: ['filterText']
      })
    } else filtersReport.push({ text: '<todos> ', style: ['filterText'] })

    return filtersReport
  }

  const documentosPorEmpresaGroupTable = () => {
    const tables: Array<any> = []
    let rows: Array<any> = []
    if (dadosAgrupados) {
      iterateGroupedData(dadosAgrupados, (empresa, documentos) => {
        rows = [
          [
            { text: 'Id', style: 'columnTitle' },
            { text: 'Endereço de Origem', style: 'columnTitle' },
            { text: 'Descrição do Documento', style: 'columnTitle' },
            { text: 'Setor', style: 'columnTitle' },
            { text: 'Tipo Documento', style: 'columnTitle' },
            { text: 'Privacidade', style: 'columnTitle' },
            { text: 'Possui Imagem', style: 'columnTitle' },
            { text: 'Estado', style: 'columnTitle' },
            { text: 'Data Cadastro', style: 'columnTitle' }
          ]
        ]

        documentos.forEach((documento) => {
          const row = [
            { text: getCodigo(documento.id_documento), style: 'row' },
            { text: documento.endereco, style: 'rowCenter' },
            { text: documento.conteudo, style: 'rowCenter' },
            { text: documento.nome_setor, style: 'row' },
            { text: documento.descricao_tipo_documento, style: 'row' },
            { text: documento.privacidade, style: 'row' },
            {
              text: documento.possui_imagem ? 'Sim' : 'Não',
              style: 'rowCenter'
            },
            { text: documento.estado, style: 'row' },
            {
              text: format(new Date(documento.data_criacao), 'dd/MM/yyyy'),
              style: 'rowCenter'
            }
          ]

          rows.push(row as any)
        })

        tables.push([
          {
            text: `Empresa: ${empresa} \nCNPJ: ${documentos[0].cnpj}`,
            style: 'subtitle'
          },
          {
            table: {
              widths: [75, 65, 190, 50, 70, 65, 45, 75, 60],
              headerRows: 1,
              body: rows
            }
          }
        ])
      })
      return tables
    } else {
      return [{ text: '\n' }]
    }
  }

  const sumarizarDocumentos = () => {
    const summaryReport: Content = []
    summaryReport.push({
      text: `Total Documentos: `,
      style: ['summaryText', 'bold']
    })
    summaryReport.push({
      text: `${data.length}`,
      style: ['summaryText']
    })
    return summaryReport
  }
  await TemplateReport({
    id_usuario,
    reportTitle: 'Inventário Documentos',
    mode,
    pageOrientation: 'landscape',
    content: documentosPorEmpresaGroupTable(),
    filterText: await filterReportText(),
    summary: sumarizarDocumentos()
  })
}
