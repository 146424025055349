import { useState } from 'react'
import { Box, Button, Grid, Stack } from '@mui/material'
import { Select } from '../../../components/Form/Select'
import ForwardIcon from '@mui/icons-material/Forward'
import SaveIcon from '@mui/icons-material/CheckCircleOutlined'
import { useUsuario } from '../../../hooks/usuario/useUsuario'
import msg from '../../../utils/validationMessages'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import useToast from '../../../hooks/toast/useToast'
import { useNavigate } from 'react-router-dom'
import { useEmpresa } from '../../../hooks/empresa/useEmpresa'
import { ComponentGroup } from '../../../components/ComponentGroup'
import { ICopiaPermissoes } from '../../../services/permissao/types'
import { useContratoPermitido } from '../../../hooks/contratoPermitido/useContratoPermitido'
import { Permissions as pms } from '../../../types/permissions'
import { IUsuario } from '../../../services/usuario/types'
import { hasAccess } from '../../../utils/hasAccess'

const schema = yup
  .object({
    id_usuario_origem: yup.string().required(msg.REQUIRED),
    id_usuario_destino: yup.string().required(msg.REQUIRED)
  })
  .required()

export function CopiarPermissoes({
  usuarioLogado
}: {
  usuarioLogado: IUsuario | undefined
}) {
  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors }
  } = useForm<ICopiaPermissoes>({
    resolver: yupResolver(schema)
  })
  const [idEmpresaOrigem, setIdEmpresaOrigem] = useState('')
  const [idEmpresaDestino, setIdEmpresaDestino] = useState('')
  const [idUsuarioOrigem, setIdUsuarioOrigem] = useState('')
  const [idUsuarioDestino, setIdUsuarioDestino] = useState('')
  const navigate = useNavigate()
  const { Toast } = useToast()
  const { data: usuarios } = useUsuario.ListAll()
  const { data: listaEmpresas } = useEmpresa.ListAll()
  const { mutateAsync: copyPermissions } =
    useContratoPermitido.CopyPermissions()

  const onSubmit = async (formData: ICopiaPermissoes) => {
    await Toast(
      copyPermissions(formData),
      'UPDATED',
      'Permissões copiadas com sucessso!'
    )

    navigate('/dashboard')
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} mt={4}>
      <Grid container spacing={2}>
        <Grid item xs>
          <ComponentGroup title={'Usuário (ORIGEM)'}>
            <Select
              label="Empresa"
              placeholder="Selecione a Empresa"
              data={
                hasAccess(usuarioLogado?.permissoes, pms.PAPEL_ADMIN)
                  ? listaEmpresas
                  : listaEmpresas?.filter(
                      (e) =>
                        e.id_empresa ===
                        usuarioLogado?.pessoa.setor.empresa?.id_empresa
                    )
              }
              fields={['id_empresa', 'nome']}
              onClick={() => {
                resetField('id_usuario_origem')
                setIdUsuarioOrigem('')
              }}
              onChange={(e) => setIdEmpresaOrigem(String(e.target.value))}
              defaultValue=""
            />
            <Select
              label="Login/Nome do Usuário*"
              placeholder="Selecione o Login/Nome do Usuário"
              data={usuarios?.filter(
                (u) => u.pessoa.setor.empresa?.id_empresa === idEmpresaOrigem
              )}
              fields={['id_usuario', 'descricao']}
              {...register('id_usuario_origem')}
              message={errors.id_usuario_origem?.message}
              onChange={(e) => {
                setIdUsuarioOrigem(String(e.target.value))
              }}
              defaultValue=""
              value={idUsuarioOrigem}
              disabled={!idEmpresaOrigem}
            />
          </ComponentGroup>
        </Grid>
        <Grid item alignSelf={'center'}>
          <ForwardIcon fontSize="large" />
        </Grid>
        <Grid item xs>
          <ComponentGroup title={'Usuário (DESTINO)'}>
            <Select
              label="Empresa"
              placeholder="Selecione a Empresa"
              data={
                hasAccess(usuarioLogado?.permissoes, pms.PAPEL_ADMIN)
                  ? listaEmpresas
                  : listaEmpresas?.filter(
                      (e) =>
                        e.id_empresa ===
                        usuarioLogado?.pessoa.setor.empresa?.id_empresa
                    )
              }
              fields={['id_empresa', 'nome']}
              onClick={() => {
                resetField('id_usuario_destino')
                setIdUsuarioDestino('')
              }}
              onChange={(e) => setIdEmpresaDestino(String(e.target.value))}
              defaultValue=""
            />
            <Select
              label="Login/Nome do Usuário*"
              placeholder="Selecione o Login/Nome do Usuário"
              data={usuarios?.filter(
                (u) => u.pessoa.setor.empresa?.id_empresa === idEmpresaDestino
              )}
              fields={['id_usuario', 'descricao']}
              {...register('id_usuario_destino')}
              message={errors.id_usuario_destino?.message}
              onChange={(e) => {
                setIdUsuarioDestino(String(e.target.value))
              }}
              defaultValue=""
              value={idUsuarioDestino}
              disabled={!idEmpresaDestino}
            />
          </ComponentGroup>
        </Grid>
      </Grid>

      <Box
        bgcolor={'bisque'}
        borderRadius={2}
        px={2}
        py={1.5}
        mt={2}
        mb={3}
        fontSize={13}
      >
        <Stack gap={2}>
          <div>
            <strong>ATENÇÃO:</strong> Certifique-se que selecionou corretamente
            os usuários, pois todos os contratos permitidos do
            <i>
              <strong> usuário (ORIGEM) </strong>
            </i>
            serão copiadas para o
            <i>
              <strong> usuário (DESTINO)</strong>
            </i>
          </div>
        </Stack>
      </Box>
      <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
        Confirmar
      </Button>
    </Box>
  )
}
