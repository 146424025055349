import pdfMake from 'pdfmake/build/pdfmake'
import { format } from 'date-fns'
import { vfs } from '../../../../../../utils/report/fonts/pdfFonts'
import {
  ILotesArquivados,
  ILotesArquivadosData
} from '../../../../../../services/relatorio/types'
import {
  groupByField,
  iterateGroupedData
} from '../../../../../../utils/report'
import { TemplateReport } from '../../../../../../components/Report'
import { ApiEmpresa } from '../../../../../../services/empresa'
import ptBR from 'date-fns/locale/pt-BR'
import { Content, PageOrientation } from 'pdfmake/interfaces'
import { ApiTipoCaixa } from '../../../../../../services/tipoCaixa'
import { ApiSetor } from '../../../../../../services/setor'

pdfMake.vfs = vfs
pdfMake.fonts = {
  Montserrat: {
    normal: 'Montserrat-Regular.ttf',
    bold: 'Montserrat-SemiBold.ttf',
    italics: 'Montserrat-Italic.ttf',
    bolditalics: 'Montserrat-SemiBoldItalic.ttf'
  }
}

type Props = {
  id_usuario: string
  data: ILotesArquivadosData[]
  mode?: 'open' | 'save'
  orientation?: PageOrientation
  filtros: ILotesArquivados
}

export async function LotesArquivadosReport({
  id_usuario,
  data,
  mode,
  filtros
}: Props) {
  const dadosAgrupados = groupByField(data, 'nome_empresa')

  const filterReportText = async () => {
    const {
      id_empresa,
      data_fim,
      data_inicio,
      estado,
      id_tipo_caixa,
      endereco,
      endereco_final,
      id_setor
    } = filtros
    const filtersReport = []
    filtersReport.push({ text: 'Empresa: ', style: ['filterText', 'bold'] })
    if (id_empresa) {
      const empresa = await ApiEmpresa.findOne(id_empresa)
      if (empresa) {
        filtersReport.push({
          text: empresa.razao_social,
          style: ['filterText']
        })
      }
    } else filtersReport.push({ text: '<todas> ', style: ['filterText'] })

    filtersReport.push({ text: '\n' })
    filtersReport.push({ text: 'Setor: ', style: ['filterText', 'bold'] })
    if (id_setor) {
      const setor = await ApiSetor.findOne(id_setor)
      if (setor) {
        filtersReport.push({
          text: setor.nome,
          style: ['filterText']
        })
      }
    } else filtersReport.push({ text: '<todos> ', style: ['filterText'] })

    filtersReport.push({ text: '\n' })
    filtersReport.push({ text: 'Tipo Caixa: ', style: ['filterText', 'bold'] })
    if (id_tipo_caixa) {
      const tipoCaixa = await ApiTipoCaixa.findOne(id_tipo_caixa)
      if (tipoCaixa) {
        filtersReport.push({
          text: tipoCaixa.descricao,
          style: ['filterText']
        })
      }
    } else filtersReport.push({ text: '<todas> ', style: ['filterText'] })

    filtersReport.push({ text: '\n' })
    filtersReport.push({ text: 'Endereço: ', style: ['filterText', 'bold'] })
    if (endereco && endereco_final) {
      filtersReport.push({
        text: `${endereco} até ${endereco_final ?? endereco}`,
        style: ['filterText']
      })
    } else filtersReport.push({ text: '<todos> ', style: ['filterText'] })

    if (estado) {
      filtersReport.push({ text: '\n' })
      filtersReport.push({ text: 'Status: ', style: ['filterText', 'bold'] })
      filtersReport.push({
        text: estado,
        style: ['filterText']
      })
    }

    filtersReport.push({ text: '\n' })
    filtersReport.push({ text: 'Período: ', style: ['filterText', 'bold'] })
    if (data_inicio && data_fim) {
      filtersReport.push({
        text: `${format(new Date(data_inicio), 'dd/MM/yyyy', {
          locale: ptBR
        })} até ${format(new Date(data_fim), 'dd/MM/yyyy', { locale: ptBR })}`,
        style: ['filterText']
      })
    } else filtersReport.push({ text: '<global> ', style: ['filterText'] })

    return filtersReport
  }

  const lotesPorEmpresaGroupTable = () => {
    const tables: Array<any> = []
    let rows: Array<any> = []
    if (dadosAgrupados) {
      iterateGroupedData(dadosAgrupados, (empresa, lotes) => {
        rows = [
          [
            { text: 'Id Caixa', style: 'columnTitle' },
            { text: 'Data Cadastro', style: 'columnTitle' },
            { text: 'Endereço', style: 'columnTitle' },
            { text: 'Tipo Caixa', style: 'columnTitle' },
            { text: 'Setor', style: 'columnTitle' },
            { text: 'Identificação', style: 'columnTitle' },
            { text: 'Conteúdo', style: 'columnTitle' },
            { text: 'Tipo Documento', style: 'columnTitle' },
            { text: 'Privacidade', style: 'columnTitle' }
          ]
        ]

        lotes.forEach((lote) => {
          const row = [
            { text: lote.id_caixa, style: 'row' },
            {
              text: format(
                new Date(lote.created_at ?? new Date()),
                'dd/MM/yyyy'
              ),
              style: 'row'
            },
            { text: lote.endereco, style: 'rowCenter' },
            { text: lote.tipoCaixa?.descricao, style: 'row' },
            { text: lote.setor?.nome, style: 'row' },
            { text: lote.identificacao, style: 'row' },
            { text: lote.conteudo, style: 'row' },
            { text: lote.tipo_documento?.descricao, style: 'row' },
            { text: lote.privacidade, style: 'rowCenter' }
          ]

          rows.push(row as any)
        })

        tables.push([
          {
            text: `Empresa: ${empresa} \nRazão Social: ${lotes[0].nome_empresa}`,
            style: 'subtitle'
          },
          {
            table: {
              widths: [80, 50, 70, 60, 100, 105, 105, 75, 70],
              headerRows: 1,
              body: rows
            }
          }
        ])
      })
      return tables
    } else {
      return [{ text: '\n' }]
    }
  }

  const sumarizarQuantidadesPorTipoCaixa = () => {
    const quantidadePorTipoCaixa: Record<string, number> = data.reduce(
      (accumulator, item) => {
        const { descricao: tipo_caixa } = item?.tipoCaixa || {
          descricao: 'Indefinido'
        }
        accumulator[tipo_caixa] = (accumulator[tipo_caixa] || 0) + 1
        return accumulator
      },
      {} as Record<string, number>
    )
    const summaryReport: Content = []
    Object.keys(quantidadePorTipoCaixa).forEach((tipo) => {
      summaryReport.push({
        text: `Total ${tipo}: `,
        style: ['summaryText', 'bold']
      })
      summaryReport.push({
        text: `${quantidadePorTipoCaixa[tipo]}`,
        style: ['summaryText']
      })
      summaryReport.push({ text: '\n' })
    })
    return summaryReport
  }
  await TemplateReport({
    id_usuario,
    reportTitle: 'Lotes Arquivados',
    mode,
    pageOrientation: 'landscape',
    content: lotesPorEmpresaGroupTable(),
    filterText: await filterReportText(),
    summary: sumarizarQuantidadesPorTipoCaixa()
  })
}
