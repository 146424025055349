import { Dialog, DialogTitle, Stack, Box, Button } from '@mui/material'
import { ReactNode } from 'react'

interface ExcludePopupProps {
  show: boolean
  title: string
  data: ReactNode
  close: () => void
  action: () => void
}

export function ExcludePopup({
  show,
  title,
  data,
  action,
  close
}: ExcludePopupProps) {
  return (
    <Dialog open={show} fullWidth maxWidth={'xs'}>
      <DialogTitle
        sx={{
          backgroundColor: 'lightgray'
        }}
      >
        {title}
      </DialogTitle>
      <Stack display={'flex'} justifyContent={'space-between'} margin={'12px'}>
        {data}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            width: '100%',
            marginTop: '12px'
          }}
          gap={2}
        >
          <Button
            onClick={() => {
              action()
            }}
            variant="contained"
            color="error"
          >
            Confirmar Exclusão
          </Button>
          <Button
            onClick={() => {
              close()
            }}
            variant="outlined"
            color="error"
          >
            Cancelar Exclusão
          </Button>
        </Box>
      </Stack>
    </Dialog>
  )
}
