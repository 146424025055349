import { useState } from 'react'
import { Box } from '@mui/material'
import { FormMode } from '../../../types/formMode'
import { FormTitle } from '../../Form/FormTitle'
import { ReceitaDespesaList } from './ReceitaDespesaList'
import { ReceitaDespesaForm } from './ReceitaDespesaForm'

export interface IReceitaDespesaProps {
  tipo: string
}

export function ReceitaDespesa({ tipo }: IReceitaDespesaProps) {
  const [formMode, setFormMode] = useState<FormMode>('LIST')
  const [data, setData] = useState<any>({})

  return (
    <Box bgcolor="#fff" p={4} borderRadius={2}>
      <FormTitle
        title={`${tipo === 'R' ? 'Receitas' : 'Despesas'}`}
        subtitle={formMode}
      />

      {formMode === 'LIST' ? (
        <ReceitaDespesaList
          setData={setData}
          setFormMode={setFormMode}
          tipo_relacionamento={tipo}
        />
      ) : (
        <ReceitaDespesaForm
          data={formMode === 'EDIT' ? data : {}}
          formMode={formMode}
          setFormMode={setFormMode}
          tipo={tipo}
        />
      )}
    </Box>
  )
}
