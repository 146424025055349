import { DefaultApi } from '../../utils/defaultApi'
import api from '../api'
import { IPermissaoDisponivel } from './types'

const endpoint = '/permissao-disponivel'
const resourceId = 'id_permissao_disponivel'

class PermissaoDisponivelService extends DefaultApi<IPermissaoDisponivel> {
  findByCodigoPermissao = async (
    codigo_permissao: number
  ): Promise<IPermissaoDisponivel> => {
    if (codigo_permissao === 0) {
      return {} as IPermissaoDisponivel
    }
    const { data } = await api.get<IPermissaoDisponivel>(
      `${endpoint}/${codigo_permissao}`
    )
    return data
  }
}

export const ApiPermissaoDisponivel = new PermissaoDisponivelService(
  endpoint,
  resourceId
)
