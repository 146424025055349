import * as yup from 'yup'
import { AnyObject, Maybe } from 'yup/lib/types'
import { validation } from '../utils/validations'

/**
 * Redefinição do yup para adição de novos métodos de validação
 */
declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    phone(validationProps: ValidationProps): StringSchema<TType, Context>
    cnpj(validationProps: ValidationProps): StringSchema<TType, Context>
    cpf(validationProps: ValidationProps): StringSchema<TType, Context>
    real(validationProps: ValidationProps): StringSchema<TType, Context>
  }
}

/**
 * Adição ao yup de método para validação de CNPJ
 */
yup.addMethod<StringSchema>(yup.string, 'cnpj', function (message: string) {
  return this.test('test-cnpj', message, function (value) {
    return !value || validation.cnpj(value)
  })
})

/**
 * Adição ao yup de método para validação de CPF
 */
yup.addMethod<StringSchema>(yup.string, 'cpf', function (message: string) {
  return this.test('test-cpf', message, function (value) {
    return !value || validation.cpf(value)
  })
})

/**
 * Adição ao yup de método para validação de telefone (fixo ou móvel)
 */
yup.addMethod<StringSchema>(yup.string, 'phone', function (message: string) {
  return this.test('test-phone', message, function (value) {
    return !value || validation.phone(value)
  })
})

interface RealProps {
  message: string
  max: number
  min: number
}

/**
 * Valida uma string como valor real, podendo validar um valor mínimo e um
 * valor máximo.
 * @param message Mensagem a ser exibida em caso de não validade
 * @param minValue Valor mínimo do número (opcional)
 * @param maxValue Valor máximo do número (opcional)
 * @return Flag de valor válido
 */
yup.addMethod<StringSchema>(
  yup.string,
  'real',
  function ({ message, maxValue, minValue }: RealProps) {
    return this.test('test-real', message, function (value) {
      let newValue: number
      try {
        newValue = Number(value)
      } catch (error) {
        return false
      }
      if (isNaN(newValue)) return false

      if (minValue && newValue < minValue) return false

      if (maxValue && newValue > maxValue) return false

      return true
    })
  }
)

export default yup
