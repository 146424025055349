import { Box, Typography } from '@mui/material'
import LinearProgress, {
  linearProgressClasses
} from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'

type LoadingProps = {
  message?: string
}

export function Loading({ message }: LoadingProps) {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'
    }
  }))

  return (
    <Box my={6}>
      <BorderLinearProgress sx={{ height: '16px', borderRadius: '8px' }} />
      <Typography variant="subtitle1" color="GrayText">
        {message ?? 'Aguarde...'}
      </Typography>
    </Box>
  )
}
