import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ApiDiretorio } from '../../services/diretorio'

const QUERY_KEY = 'qkDiretorio'

const findBySetor = (id_setor: string) => {
  return useQuery([QUERY_KEY, id_setor], () =>
    ApiDiretorio.findBySetor(id_setor)
  )
}

const directoryTreeBySetor = (id_setor: string) => {
  return useQuery([QUERY_KEY, 'arvore', id_setor], () =>
    ApiDiretorio.directoryTreeBySetor(id_setor)
  )
}

const Create = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiDiretorio.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Update = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiDiretorio.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const FindById = (id: string) => {
  return useQuery([QUERY_KEY, id], () => ApiDiretorio.findOne(id))
}

const Delete = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiDiretorio.delete, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

export const useDiretorio = {
  Create,
  findBySetor,
  directoryTreeBySetor,
  findOne: FindById,
  Update,
  Delete
}
