import { useMemo, useState, useRef } from 'react'
import {
  Box,
  Button,
  Divider,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/AddCircle'
import EditIcon from '@mui/icons-material/Edit'
import InactiveIcon from '@mui/icons-material/DoNotDisturb'
import ActiveIcon from '@mui/icons-material/ThumbUp'
import { useContrato } from '../../../hooks/contrato/useContrato'
import useToast from '../../../hooks/toast/useToast'
import { FormMode } from '../../../types/formMode'
import { Loading } from '../../../components/Loading'
import { formata } from '../../../utils/formataData'
import { hasPermission } from '../../../utils/hasPermission'
import { Permissions } from '../../../types/permissions'
import { useUsuario } from '../../../hooks/usuario/useUsuario'
import { hasAccess } from '../../../utils/hasAccess'

interface IForm {
  setData: (value: React.SetStateAction<any>) => void
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

export function List({ setData, setFormMode }: IForm) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [q, setQ] = useState<string>('')
  const { Toast } = useToast()
  const searchInput = useRef<HTMLInputElement>(null)
  const { data: usuario } = useUsuario.FindByUsername()

  const handleSearchClick = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setSearchParams({ ...searchParams, q: searchTerm })
    setQ(searchTerm)
    searchInput.current?.focus()
  }

  const page = useMemo(() => {
    return Number(searchParams.get('page') ?? 1)
  }, [searchParams])

  const { mutateAsync: remove } = useContrato.Remove()
  const { mutateAsync: activate } = useContrato.Activate()
  const { data: records, isLoading } = useContrato.FindAll(page, q)

  const handleNewForm = () => {
    setFormMode('CREATE')
  }

  const handleRemove = async (id: string, ativo: boolean) => {
    if (
      !hasPermission(
        usuario?.permissoes,
        ativo ? Permissions.INATIVAR_CONTRATO : Permissions.ATIVAR_CONTRATO
      )
    )
      return

    await Toast(
      ativo ? remove(id) : activate(id),
      undefined,
      `Contrato ${ativo ? 'Inativado' : 'Ativado'} com sucesso!`
    )
    setSearchParams({ page: '1' })
  }

  const handleEditForm = (data: any) => {
    setData(data)
    setFormMode('EDIT')
  }

  if (isLoading) return <Loading />

  return (
    <Box>
      <Stack
        component="form"
        onSubmit={handleSearchClick}
        direction="row"
        py={3}
        gap={3}
        mb={2}
      >
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          placeholder="Descrição/Empresa Contratante"
          inputRef={searchInput}
          autoFocus
          value={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value)
          }}
        />
        <Button variant="outlined" startIcon={<SearchIcon />} type="submit">
          Pesquisar
        </Button>
      </Stack>

      <Divider />

      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleNewForm}
        sx={{ float: 'right', my: '12px' }}
      >
        Novo registro
      </Button>

      <TableContainer
        component={Box}
        border={1}
        borderRadius={2}
        borderColor="#f2f2f2"
      >
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Descrição
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Vigência (Início e Fim)
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Lacrado
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Empresa Contratante
              </TableCell>

              <TableCell width="12px" />
              <TableCell width="12px" />
            </TableRow>
          </TableHead>
          <TableBody>
            {records?.data?.map((record) => (
              <TableRow
                key={record.id_contrato}
                hover
                style={
                  !record.ativo
                    ? {
                        textDecoration: 'line-through'
                      }
                    : undefined
                }
              >
                <TableCell>
                  {record.descricao}
                  {hasAccess(
                    usuario?.permissoes,
                    Permissions.EXIBIR_ID_CONTRATO
                  ) && (
                    <Typography
                      sx={{
                        fontSize: '10px',
                        fontWeight: 'bold',
                        color: 'GrayText'
                      }}
                    >
                      ({record.id_contrato})
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  {formata(new Date(record.data_inicio), 'dd/MM/yyyy')} -
                  {record.data_fim
                    ? formata(new Date(record.data_fim), 'dd/MM/yyyy')
                    : 'xx/xx/xxxx'}
                </TableCell>
                <TableCell>{record.lacrado === true ? 'Sim' : 'Não'}</TableCell>
                <TableCell>{record.empresa_contratante.nome}</TableCell>

                <TableCell>
                  <Tooltip title="Editar" arrow>
                    <Button
                      variant="outlined"
                      sx={{ minWidth: '24px', padding: '6px' }}
                      onClick={() => handleEditForm(record)}
                      // disabled={!record.ativo}
                    >
                      <EditIcon />
                    </Button>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip
                    title={
                      record.ativo ? 'Inativar Contrato' : 'Ativar Contrato'
                    }
                    arrow
                  >
                    <Button
                      variant="outlined"
                      color={record.ativo ? 'secondary' : 'success'}
                      onClick={() =>
                        handleRemove(record.id_contrato!, record.ativo)
                      }
                      sx={{ minWidth: '24px', padding: '6px' }}
                    >
                      {record.ativo ? <InactiveIcon /> : <ActiveIcon />}
                    </Button>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {records?.pagination &&
        records.pagination.totalItems > records.pagination.itemsPerPage && (
          <Pagination
            count={records?.pagination.totalPages}
            sx={{ marginTop: '12px' }}
            onChange={(_, newPage) =>
              setSearchParams({ page: String(newPage) })
            }
            page={records.pagination.currentPage}
          />
        )}
    </Box>
  )
}
