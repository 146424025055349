import { Box, Stack, Typography, BoxProps } from '@mui/material'

type BoxTitleProps = {
  title: string
  children: React.ReactNode
} & BoxProps

export function BoxTitle({ title, children, ...rest }: BoxTitleProps) {
  return (
    <Box borderRadius={2} border={1} borderColor="lightgray" {...rest}>
      <Box
        bgcolor="ButtonHighlight"
        p={1}
        sx={{
          borderTopRightRadius: 8,
          borderTopLeftRadius: 8
        }}
      >
        <Typography fontWeight="bold" color="#555">
          {title}
        </Typography>
      </Box>

      <Stack gap={2} p={2}>
        {children}
      </Stack>
    </Box>
  )
}
