import { DefaultApi } from '../../utils/defaultApi'
import { ITipoTemporalidade } from './types'

const endpoint = '/tipo-temporalidade'
const resourceId = 'id_tipo_temporalidade'

export const ApiTipoTemporalidade = new DefaultApi<ITipoTemporalidade>(
  endpoint,
  resourceId
)
