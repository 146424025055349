import { validation } from './validations'

/**
 * Interface base para métodos para aplicação de máscaras
 *
 * @property value - Valor a ser mascarado
 */
interface MaskProps {
  value: string
}

/**
 * Interface para método de aplicação de máscara para telefone (fixo ou móvel)
 *
 * @property mandatoryDDD - Flag para obrigatoriedade de DDD
 */
interface PhonePros extends MaskProps {
  mandatoryDDD?: boolean
}

/**
 * Aplica uma máscara numérica a uma string.
 * Substitui o carácter # da máscara pelos caracteres numéricos da string.
 * A aplicação da máscara pode exceder a quantidade de caracteres da string
 * passada como parâmetro. Os caracteres excedentes da máscara são preenchidos
 * com undefined.
 *
 * @param value Valor a ser mascarado
 * @param pattern Máscara a ser aplicada
 * @returns String mascarada
 */
const applyMask = (value: string, pattern: string): string => {
  let i = 0
  return pattern.replace(/#/g, () => {
    return value[i++]
  })
}

/**
 * Aplica máscara de telefone a uma string
 * @param value Valor a ser mascarado
 * @param mandatoryDDD Flag para mascarar obrigatoriamente com DDD
 * (opcional, padrão True)
 * @returns
 */
const phone = ({ value, mandatoryDDD = true }: PhonePros): string => {
  //Remove caracteres não numéricos
  value = validation.clearValue(value)

  let newValue = value

  //Aplicação de máscara de DDD não obrigatório
  if (!mandatoryDDD) {
    if (value.length === 0) {
      return ''
    }
    if (value.length <= 8) {
      newValue = applyMask(value, '#### - ####')
      if (value.length < 5) {
        return newValue.substring(0, value.length)
      }
      return newValue.substring(0, value.length + 3)
    }
    if (value.length === 9) {
      return applyMask(value, '##### - ####')
    }
    if (value.length === 10) {
      return applyMask(value, '(##) #### - ####')
    }
    return applyMask(value, '(##) ##### - ####')
  }
  //Aplicação de máscara com DDD obrigatório
  else {
    if (value.length === 0) {
      return ''
    }
    newValue =
      value.length <= 10
        ? applyMask(value, '(##) #### - ####')
        : applyMask(value, '(##) ##### - ####')
    if (value.length < 3) return newValue.substring(0, value.length + 1)
    if (value.length < 7) return newValue.substring(0, value.length + 3)
    if (value.length < 10) return newValue.substring(0, value.length + 6)
    return newValue
  }
  // return newValue
}

/**
 * Aplica máscara de CNPJ a uma string
 * @param value Valor a ser mascarado
 * @returns
 */
const cnpj = ({ value }: MaskProps): string => {
  //Remove caracteres não numéricos
  value = validation.clearValue(value)
  const newValue = value

  //Aplica máscara de CNPJ
  const padded = applyMask(newValue, '##.###.###/####-##')

  //Retorna apenas a quantidade necessária de caracteres(INÍCIO)
  if (newValue.length < 3) {
    return padded.substring(0, newValue.length)
  }
  if (newValue.length < 6) {
    return padded.substring(0, newValue.length + 1)
  }
  if (newValue.length < 9) {
    return padded.substring(0, newValue.length + 2)
  }
  if (newValue.length < 13) {
    return padded.substring(0, newValue.length + 3)
  }
  return padded.substring(0, newValue.length + 4)
  //Retorna apenas a quantidade necessária de caracteres(FIM)
}

/**
 * Aplica máscara de CPF a uma string
 * @param value Valor a ser mascarado
 * @returns
 */
const cpf = ({ value }: MaskProps): string => {
  //Remove caracteres não numéricos
  value = validation.clearValue(value)
  const newValue = value

  //Aplica máscara de CPF
  const padded = applyMask(newValue, '###.###.###-##')

  //Retorna apenas a quantidade necessária de caracteres(INÍCIO)
  if (newValue.length < 4) {
    return padded.substring(0, newValue.length)
  }
  if (newValue.length < 7) {
    return padded.substring(0, newValue.length + 1)
  }
  if (newValue.length < 10) {
    return padded.substring(0, newValue.length + 2)
  }
  return padded.substring(0, newValue.length + 3)
  //Retorna apenas a quantidade necessária de caracteres(FIM)
}

const cep = ({ value }: MaskProps): string => {
  //Remove caracteres não numéricos
  value = validation.clearValue(value)
  let newValue = value

  newValue = applyMask(value, '##### - ###')

  //Retorna apenas a quantidade necessária de caracteres(INÍCIO)
  if (value.length < 6) {
    return newValue.substring(0, value.length)
  }
  return newValue.substring(0, value.length + 3)
  //Retorna apenas a quantidade necessária de caracteres(FIM)
}

const getFloatString = ({ value }: MaskProps): string => {
  value = value.trim()
  if (validation.validateFloat(value)) {
    value = value.replace(',', '.')
    return parseFloat(value).toLocaleString()
  }
  throw new Error(`Número inválido (${value})`)
}

const getFloatValue = ({ value }: MaskProps): number => {
  value = value.trim()
  if (validation.validateFloat(value)) {
    value = value.replace(/,/, '.')
    return parseFloat(value)
  }
  throw new Error(`Número inválido (${value})`)
}

const bankCode = ({ value }: MaskProps): string => {
  value = validation.clearValue(value)
  let newValue = value

  newValue = applyMask(value, '###')
  return newValue
}

const bankAgencyAccount = ({ value }: MaskProps): string => {
  const lastChar = value.slice(-1).toUpperCase() // get the last character and convert to uppercase
  if (lastChar === 'X' || !isNaN(Number(lastChar))) {
    // check if the last character is 'X' or a digit
    value = value.slice(0, -1).replace(/[^0-9]/g, '') + '-' + lastChar // remove non-digits from the rest of the value
  } else {
    value = value.replace(/[^0-9]/g, '') // remove non-digits
  }
  return value
}

export const masks = {
  phone,
  cnpj,
  cpf,
  cep,
  getFloatString,
  getFloatValue,
  bankCode,
  bankAgencyAccount
}
