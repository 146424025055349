import { Box, Tooltip, Typography } from '@mui/material'
import { ReactNode, useCallback, useEffect, useState } from 'react'
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable'
import Xarrow, { useXarrow } from 'react-xarrows'
import { useEstadoAtividade } from '../../../../../hooks/estadoAtividade/useEstadoAtividade'
import useToast from '../../../../../hooks/toast/useToast'

import { ITipoAtividade } from '../../../../../services/tipoAtividade/types'
import { requirementsParser } from '../../../../../utils/requirementsParser'
import { ItemGrafico, TTipoAcao } from '../../Form'

//Paleta de cores para setas e caixas do grafo
const CorItem = {
  ALTERNATIVO: {
    borda: '#EB662788',
    fundo: '#F7DBC5',
    texto: '#D54E25'
  },
  PADRAO: {
    borda: '#6791B388',
    fundo: '#D5E8F2',
    texto: '#315B85'
  }
}

interface DrawAreaProps {
  acao: TTipoAcao
  grafico: ItemGrafico[]
  changeGrafico: (grafico: ItemGrafico[]) => void
  disabledControls: boolean
}

export function DrawArea({
  acao,
  grafico,
  changeGrafico,
  disabledControls
}: DrawAreaProps) {
  //Lista de elementos React a serem desenhados (arestas e nós do grafo)
  const [elementos, setElementos] = useState<ReactNode[]>([])
  //Caso se esteja usando a ferramenta de fluxo padrão ou fluxo alternativo
  //deve-se guarda a identificação do item inicialmente clicado para
  //identificar o nó inicial da aresta
  const [pontoInicial, setPontoInicial] = useState<string>('')

  const { data: listaEstadosAtividades } = useEstadoAtividade.ListAll()

  const updateXarrow = useXarrow()

  const toast = useToast()

  /**
   * Seleciona um tipo de ponteiro do mouse, de acordo com o botão selecionado
   * na barra de ferramentas.
   *
   * Isso é usado no corpo da div container do gráfico.
   *
   * @returns Nome do ponteiro do mouse.
   */
  function getMousePointer(): string {
    if (disabledControls) {
      return 'not-allowed'
    }
    switch (acao) {
      case 'SELECIONA': {
        return 'grab'
      }
      case 'APAGA': {
        return 'cell'
      }
      case 'PADRAO': {
        return 'crosshair'
      }
      case 'ALTERNATIVO': {
        return 'alias'
      }
    }
  }

  /**
   * Validar a aresta a ser criada (válida apenas para as ações PADRAO ou
   * ALTERNATIVA)
   *
   * Algumas validações são próprias ao grafo, outras vão depender de requisitos
   * dos tipos de atividades envolvidos
   *
   * @param idInicio ID do nó origem da aresta
   * @param idFim ID do nó destino da aresta
   * @param acaoAtual Ação atualmente em curso
   * @return Mensagem de erro ou nada
   */
  const validarLink = useCallback(
    (idInicio: string, idFim: string, acaoAtual: TTipoAcao): string => {
      switch (acaoAtual) {
        case 'PADRAO': {
          if (idInicio === 'INICIO' && idFim === 'FIM') {
            return 'O fluxo não pode ir diretamente do início para o fim'
          }
          break
        }
        case 'ALTERNATIVO': {
          if (idInicio === 'INICIO') {
            return 'O Início pode ser apenas início do fluxo padrão 2'
          }

          if (idFim === 'FIM') {
            return 'O Fim pode ser destino apenas de fluxo padrão'
          }
        }
      }

      return ''
    },
    []
  )

  /**
   * Fazer pequenas validações sobre o nó atualmente selecionado.
   *
   * @param id ID do elemento clicado
   * @param acaoAtual Ação atuamente em curso
   * @return Mensagem de erro ou string vazia
   */
  const validarPontoAtual = useCallback(
    (id: string, acaoAtual: TTipoAcao): string => {
      switch (acaoAtual) {
        case 'SELECIONA': {
          break
        }
        case 'APAGA': {
          if (id === 'INICIO') {
            return 'O Início não pode ser apagado'
          }
          if (id === 'FIM') {
            return 'O Fim não pode ser apagado'
          }
          break
        }
        default: {
          if (id === 'FIM' && pontoInicial === '') {
            return 'O Fim pode pode ser apenas destino de fluxo padrão'
          }

          if (
            id === 'INICIO' &&
            pontoInicial === '' &&
            acaoAtual === 'ALTERNATIVO'
          )
            return 'O Início pode ser apenas início do fluxo padrão '
        }
      }

      return ''
    },
    [pontoInicial]
  )

  /**
   * Lidar com o evento de click sobre algum nó do grafo.
   *
   * Essa função é vinculada ao evento onClick dos nós servindo para
   * efetuação da ação definida pelo botão atualmente selecionado na barra
   * de ferramentas
   *
   * @param event Evento de HTML
   * @returns nada
   */
  const handleNodeMouseClick = useCallback(
    (event: React.MouseEvent<HTMLElement>): void => {
      const id = event.currentTarget.id

      const hasProblemaPontoAtual = validarPontoAtual(id, acao)
      if (hasProblemaPontoAtual) {
        toast.ToastWarning(hasProblemaPontoAtual)
        setPontoInicial('')
        return
      }

      //Validacao da aresta
      const hasProblema = validarLink(pontoInicial, id, acao)
      if (hasProblema) {
        toast.ToastWarning(hasProblema)
        setPontoInicial('')
        return
      }

      switch (acao) {
        // Quando a ação for APAGA, o nó é removido do grafo e as referência
        // a ele também
        case 'APAGA': {
          //Nem o nó início, nem o nó Fim podem ser excluídos
          if (id === 'inicio' || id === 'fim') return
          const newGrafico = grafico.filter((item) => item.id !== id)
          newGrafico.forEach((item) => {
            if (item.proximo && item.proximo === id) {
              item.proximo = undefined
            }
            if (item.alternativo && item.alternativo === id) {
              item.alternativo = undefined
            }
          })
          changeGrafico([...newGrafico])
          break
        }
        //Vincula dois nós no fluxo padrão
        case 'PADRAO': {
          //Se não existe um nó inicial ele é definido
          if (pontoInicial === '') {
            setPontoInicial(id)
          } else {
            //Caso contrário, a aresta é definida e a ferramenta é reiniciada

            const itemGrafico = grafico.find((item) => item.id === pontoInicial)
            const newGrafico = grafico.filter(
              (item) => item.id !== pontoInicial
            )
            setPontoInicial('')

            const itemProximo = newGrafico.find((item) => item.id === id)

            if (
              !(pontoInicial === 'INICIO') &&
              !(id === 'FIM') &&
              itemProximo!.tipoAtividade &&
              itemGrafico!.tipoAtividade &&
              listaEstadosAtividades &&
              !requirementsParser({
                estados: listaEstadosAtividades,
                expressao: itemProximo!.tipoAtividade.prerequisito ?? '',
                estado: itemGrafico!.tipoAtividade.estado_final
              })
            ) {
              toast.ToastWarning('Pré-requisitos não foram atendidos')
              return
            }

            itemGrafico!.proximo = itemProximo!.id

            changeGrafico([...newGrafico, itemGrafico!])
          }
          break
        }
        //Vincula dois nós no fluxo alternativo
        case 'ALTERNATIVO': {
          const itemGrafico = grafico.find((item) => item.id === pontoInicial)

          //Se não existe um nó inicial ele é definido
          if (pontoInicial === '') {
            setPontoInicial(id)
          } else {
            //Caso contrário a aresta é criada e a ferramenta é reiniciada
            const newGrafico = grafico.filter(
              (item) => item.id !== pontoInicial
            )

            setPontoInicial('')

            const itemProximo = newGrafico.find((item) => item.id === id)

            if (
              listaEstadosAtividades &&
              itemProximo!.tipoAtividade &&
              itemGrafico!.tipoAtividade &&
              !requirementsParser({
                estados: listaEstadosAtividades,
                expressao: itemProximo!.tipoAtividade.prerequisito ?? '',
                estado: itemGrafico!.tipoAtividade.estado_alternativo
              })
            ) {
              toast.ToastWarning('Pré-requisitos não foram atendidos')
              return
            }

            itemGrafico!.alternativo = itemProximo!.id
            changeGrafico([...newGrafico, itemGrafico!])
            setPontoInicial('')
          }
          break
        }
      }
    },
    [
      acao,
      changeGrafico,
      grafico,
      pontoInicial,
      toast,
      validarLink,
      validarPontoAtual,
      listaEstadosAtividades
    ]
  )

  /**
   * Apaga uma aresta.
   *
   * @param id Identificação da aresta
   * @param tipo Tipo da aresta
   */
  const handleArrowMouseClick = useCallback(
    (id: string, tipo: string): void => {
      if (acao === 'APAGA') {
        const itemGrafico = grafico.find((item) => item.id === id)
        const newGrafico = grafico.filter((item) => item.id !== id)
        if (tipo === 'PADRAO') {
          itemGrafico!.proximo = undefined
        } else {
          itemGrafico!.alternativo = undefined
        }
        changeGrafico([...newGrafico, itemGrafico!])
      }
    },
    [acao, grafico, changeGrafico]
  )

  const handleDragDrop = useCallback(
    (event: DraggableEvent, data: DraggableData) => {
      updateXarrow()
      const itemGrafico = grafico.find((item) => item.id === data.node.id)
      itemGrafico!.xFinal = data.node.offsetLeft + data.x - 16
      itemGrafico!.yFinal = data.node.offsetTop + data.y - 8
    },
    [grafico, updateXarrow]
  )

  const setTip = useCallback(
    (tipoAtividade?: ITipoAtividade): string => {
      if (acao === 'PADRAO' || acao === 'ALTERNATIVO' || disabledControls) {
        return (
          `${tipoAtividade?.descricao}\n` +
          `--------------------------------\n` +
          `‣ Pré-Requisito: ${tipoAtividade?.prerequisito} \n` +
          `‣ Estado final: ${tipoAtividade?.estado_final} \n` +
          `‣ Estado alternativo: ${tipoAtividade?.estado_alternativo}`
        )
      }

      return tipoAtividade?.descricao ?? ''
    },
    [acao]
  )

  /**
   * Cria o elemento React para exibição de um nó
   *
   * @param item Identificação visual e lógica do item
   * @returns Nó a ser exibido
   */
  const createNo = useCallback(
    (item: ItemGrafico): ReactNode => {
      let elemento: ReactNode = undefined
      switch (item.tipo) {
        //O nó Início tem posição fixa e tem a definição do evento de click
        case 'INICIO': {
          elemento = (
            <Box
              key="inicio"
              id={item.id}
              sx={{
                width: '30px',
                height: '30px',
                borderRadius: '100%',
                backgroundColor: '#0F8D00',
                border: '2px solid white',
                outline: '2px solid #0F8D00',
                position: 'absolute',
                top: `${item.y}px`,
                left: `${item.x}px`
              }}
              onClick={handleNodeMouseClick}
            >
              <Typography
                sx={{
                  position: 'relative',
                  top: '-22px',
                  left: '-5px'
                }}
              >
                Início
              </Typography>
            </Box>
          )
          break
        }
        //O nó fim tem posição fixa e recebe evento de click
        case 'FIM': {
          elemento = (
            <Box
              key="fim"
              id={item.id}
              sx={{
                width: '30px',
                height: '30px',
                borderRadius: '100%',
                backgroundColor: '#BD0101',
                border: '2px solid white',
                outline: '2px solid #BD0101',
                position: 'absolute',
                top: `${item.y}px`,
                left: `${item.x}px`
              }}
              onClick={handleNodeMouseClick}
            >
              <Typography
                sx={{
                  position: 'relative',
                  top: '27px'
                }}
              >
                Fim
              </Typography>
            </Box>
          )
          break
        }
        //Os outros nós são criados como Draggable que possui uma div com
        //formatação condicionada ao tipo de nó. O evento onClick é pré-definido
        //e os eventos relativos a drag/drop são vinculados às atualizações
        //das arestas a que estão vinculados
        default: {
          elemento = (
            <Draggable
              disabled={acao !== 'SELECIONA' || disabledControls}
              bounds="parent"
              key={item.id}
              onDrag={updateXarrow}
              onStop={handleDragDrop}
            >
              <Tooltip
                title={
                  <span style={{ whiteSpace: 'pre-line' }}>
                    {setTip(item.tipoAtividade)}
                  </span>
                }
              >
                <Box
                  id={item.id}
                  sx={{
                    width: '200px',
                    padding: '8px',
                    borderRadius: '6px',
                    backgroundColor: CorItem[item.tipo].fundo,
                    border: `2px solid ${CorItem[item.tipo].borda}`,
                    color: CorItem[item.tipo].texto,
                    position: 'absolute',
                    top: `${item.y}px`,
                    left: `${item.x}px`
                  }}
                  onClick={handleNodeMouseClick}
                >
                  <Typography>{item.tipoAtividade?.identificacao}</Typography>
                </Box>
              </Tooltip>
            </Draggable>
          )
        }
      }
      return elemento
    },
    [acao, updateXarrow, handleNodeMouseClick, handleDragDrop, setTip]
  )

  /**
   * Criação das arestas a partir do vínculo entre os nós.
   *
   * As arestas são criadas como ReactElements com Xarrow.
   *
   * @returns Conjunto de arestas a serem desenhadas
   */
  const createArrows = useCallback((): ReactNode[] => {
    const setas: ReactNode[] = []
    //Para cada nó, são verificadas a vinculação pelas propriedades
    //próximo e alternativo, sendo criadas as arestas relativas a elas
    for (let i = 0; i < grafico.length; i++) {
      if (grafico[i].proximo) {
        setas.push(
          <Xarrow
            key={`${grafico[i].id}-${grafico[i].proximo}`}
            start={grafico[i].id}
            end={grafico[i].proximo!}
            color={CorItem.PADRAO.borda}
            strokeWidth={8}
            headSize={2}
            arrowBodyProps={{
              onClick: () => handleArrowMouseClick(`${grafico[i].id}`, 'PADRAO')
            }}
          />
        )
      }
      if (grafico[i].alternativo) {
        setas.push(
          <Xarrow
            key={`${grafico[i].id}-${grafico[i].alternativo}`}
            start={grafico[i].id}
            end={grafico[i].alternativo!}
            color={CorItem.ALTERNATIVO.borda}
            strokeWidth={8}
            headSize={2}
            arrowBodyProps={{
              onClick: () =>
                handleArrowMouseClick(`${grafico[i].id}`, 'ALTERNATIVO')
            }}
          />
        )
      }
    }

    return setas
  }, [grafico, handleArrowMouseClick])

  //Sempre que o tipo de ação mudar deve-se reiniciar o ponto inicial das
  //ferramentas de arestas
  useEffect(() => {
    setPontoInicial('')
  }, [acao])

  // Deve-se redesenhar o grafo, quando algum item for adicionado, excluído ou
  // transladado. Isso também deve ocorrer quando vértices forem adicionados ou
  // excluídos
  useEffect(() => {
    //Criação de todos os elementos gráficos a serem exibidos (INÍCIO)
    const novosNos = grafico.map((itemGrafico) => {
      return createNo(itemGrafico)
    })

    const novasSetas = createArrows()

    setElementos([...novosNos, ...novasSetas])
    //Criação de todos os elementos gráficos a serem exibidos (FIM)
  }, [createNo, grafico, createArrows])

  return (
    <Box
      sx={{
        bgcolor: '#eee',
        width: '1000px',
        height: '100%',
        borderRadius: '8px',
        paddingX: '16px',
        paddingY: '8px',
        marginTop: '1rem',
        cursor: getMousePointer(),
        position: 'relative'
      }}
    >
      {elementos}
    </Box>
  )
}
