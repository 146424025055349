import DialogUI from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import AlertIcon from '@mui/icons-material/Warning'
import {
  Box,
  Divider,
  Input,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { useEffect, useState } from 'react'
import { format } from 'date-fns'

interface InnerFieldProps {
  fieldName: string //Nome do campo (como vem definido no parâmetro data)
  fieldLabel: string //Nome do campo a ser exibido
}

interface IDialogProps {
  title: string //Título da janela
  openDialog: boolean //Flag para exibição da caixa de diálogo (como na ShowMessage)
  data: Array<any> //Conjunto de dados a ser usado pelo componente
  displayFields: InnerFieldProps[] //Lista de campos a serem usados
  keyField: string //Nome do campo principal
  innerPlaceholder?: string //Placeholder a ser exibido no input de busca
  handleAnswerSelect: (record: any) => void //Método a ser chamado quando um item for clicado
  handleAnswerCancel: () => void //Método a ser chamado quando solicitado o cancelamento
}

export function SearchDialog({
  title,
  openDialog,
  data,
  displayFields,
  keyField,
  innerPlaceholder,
  handleAnswerSelect,
  handleAnswerCancel
}: IDialogProps) {
  const [filteredList, setFilteredList] = useState<Array<any>>(data)

  function getFieldValue(fieldName: string, record: any) {
    let field = record
    const struct = fieldName.split('.')
    for (const property of struct) {
      field = field[property]
    }
    const validation = new Date(String(field))
    field = validation.getTime()
      ? format(validation, 'dd/MM/yyyy')
      : String(field)

    return field
  }

  function handleCriteriaChange(value: string) {
    const criteria = value.trim().toLowerCase().split(' ')
    if (criteria.length >= 1 && criteria[0].length > 0) {
      setFilteredList(
        data.filter((record: any) =>
          criteria.every((singleCriteria) =>
            displayFields.some(
              (field) =>
                getFieldValue(field.fieldName, record)
                  .toLowerCase()
                  .search(singleCriteria) > -1
            )
          )
        )
      )
    } else {
      setFilteredList(data)
    }
  }

  function handleItemClick(record: any) {
    handleAnswerSelect(record)
  }

  useEffect(() => {
    setFilteredList(data)
  }, [openDialog])

  return (
    <DialogUI open={openDialog} fullWidth sx={{ height: '50%' }}>
      <DialogTitle display="flex" alignItems="center" gap={1} m={0} p={0}>
        <Stack gap={2}>
          <Box display={'flex'} flexDirection={'row'}>
            <AlertIcon color="info" />
            <Typography variant="h6" color="#333">
              {title}
            </Typography>
          </Box>
          <Input
            placeholder={innerPlaceholder}
            onChange={(event) => {
              handleCriteriaChange(String(event.target.value))
            }}
            autoFocus
            inputRef={(input) => input && input.focus()}
          />
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack gap={2}>
          <Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
                    {displayFields.map((field) => (
                      <TableCell
                        sx={{ fontWeight: 'bold', fontSize: '0.8rem' }}
                        size="small"
                        key={field.fieldName}
                      >
                        {field.fieldLabel}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredList?.map((record) => (
                    <TableRow
                      key={record[keyField]}
                      hover
                      onClick={() => handleItemClick(record)}
                    >
                      {displayFields.map((field) => (
                        <TableCell
                          key={`${record[keyField]}${field.fieldName}`}
                        >
                          {getFieldValue(field.fieldName, record)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions style={{ padding: '15px 22px' }}>
        <Button
          onClick={() => handleAnswerCancel()}
          color="secondary"
          variant="outlined"
          fullWidth
          style={{ fontWeight: 'bold' }}
        >
          Cancelar
        </Button>
      </DialogActions>
    </DialogUI>
  )
}
