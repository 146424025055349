import { Box, Button, Stack } from '@mui/material'
import SaveIcon from '@mui/icons-material/CheckCircleOutlined'
import CancelIcon from '@mui/icons-material/DoDisturbAltOutlined'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import msg from '../../../utils/validationMessages'
import useToast from '../../../hooks/toast/useToast'
import { FormMode } from '../../../types/formMode'
import { Input } from '../../../components/Form/Input'
import { useEventoContaCorrente } from '../../../hooks/conta-corrente/evento/useEvento'
import { Select } from '../../../components/Form/Select'
import { useMovimentoContaCorrente } from '../../../hooks/conta-corrente/movimento/useMovimentoContaCorrente'
import { IMovimentoContaCorrente } from '../../../services/conta-corrente/movimento/types'
import { useConta } from '../../../hooks/conta-corrente/conta/useConta'
import { removeNumericMask } from '../../../utils/formataNumeric'

const schema = yup
  .object({
    historico: yup.string().required(msg.REQUIRED).min(3, msg.MIN(3)),
    dt_movimento: yup
      .date()
      .transform((value, originalValue) => {
        return originalValue === '' ? null : value
      })
      .typeError('A data do movimento deve ser uma data válida')
      .required(msg.REQUIRED),
    id_evento_conta_corrente: yup.string().required(msg.REQUIRED),
    id_conta: yup.string().required(msg.REQUIRED),
    valor: yup
      .number()
      .transform((_value, originalValue) => removeNumericMask(originalValue))
      .required(msg.REQUIRED)
      .typeError('O valor do lançamento deve ser preenchido corretamente')
  })
  .required()

interface IForm {
  data: any
  formMode: FormMode
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

export function Form({ data, formMode, setFormMode }: IForm) {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IMovimentoContaCorrente>({
    resolver: yupResolver(schema)
  })

  const { Toast } = useToast()
  const { mutateAsync: create } = useMovimentoContaCorrente.Create()
  const { mutateAsync: update } = useMovimentoContaCorrente.Update()
  const { data: listaEventos } = useEventoContaCorrente.ListAll()
  const { data: listaContas } = useConta.ListAll()

  const onSubmit = async (formData: IMovimentoContaCorrente) => {
    formMode === 'CREATE'
      ? await Toast(create(formData))
      : await Toast(
          update({
            ...formData,
            id_movimento_conta_corrente: data.id_movimento_conta_corrente
          }),
          'UPDATED'
        )

    setFormMode('LIST')
  }

  const dt_movimento = (data?.dt_movimento ?? '').toLocaleString().slice(0, 10)

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} mt={4}>
      <Stack gap={2} direction="row">
        <Input
          type="date"
          label="Data*"
          {...register('dt_movimento')}
          InputLabelProps={{
            shrink: true
          }}
          defaultValue={dt_movimento}
          message={errors.dt_movimento?.message}
        />

        <Select
          label="Evento"
          placeholder="Selecione o Evento"
          data={listaEventos}
          fields={['id_evento_conta_corrente', 'descricao']}
          {...register('id_evento_conta_corrente')}
          defaultValue={data?.id_evento_conta_corrente}
          message={errors.id_evento_conta_corrente?.message}
        />

        <Input
          label="Valor R$*"
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*[,]?[0-9]{0,2}',
            inputProps: { min: 0, step: '0.01' }
          }}
          {...register('valor')}
          defaultValue={data?.valor}
          message={errors.valor?.message}
        />
      </Stack>
      <Stack gap={2} direction="row" mt={2}>
        <Select
          label="Conta Corrente"
          placeholder="Selecione a Conta Corrente"
          data={listaContas}
          fields={['id_conta', 'descricao']}
          {...register('id_conta')}
          defaultValue={data?.id_conta}
          message={errors.id_conta?.message}
        />
      </Stack>
      <Stack gap={2} direction="row" mt={2}>
        <Input
          label="Histórico*"
          {...register('historico')}
          defaultValue={data?.historico}
          message={errors.historico?.message}
          multiline={true}
          rows={3}
        />
      </Stack>

      <Stack direction="row" gap={2} mt={3}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<CancelIcon />}
          onClick={() => setFormMode('LIST')}
        >
          Cancelar
        </Button>

        <Button
          type="submit"
          variant="contained"
          startIcon={<SaveIcon />}
          sx={{ width: '110px' }}
        >
          Salvar
        </Button>
      </Stack>
    </Box>
  )
}
