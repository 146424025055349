/**
 * Calcula a quantidade de horas de atraso de um timestamp em relação ao
 * timestamp atual
 * @param targetSLA SLA a ser validado (em horas)
 * @param targetDate Timestamp do instante inicial. Se não for passado, será
 * usado o timestamp atual
 * @param currentTimestamp Timestamp do instante final. Se não for passado,
 * será o timestamp atual
 * @returns Quantidade de horas em atraso (0, se não estiver em atraso)
 */
export const atrasoAtividade = (
  targetSLA: number,
  targetDate?: string | Date,
  currentTimestamp?: string | Date,
  qtdItens = 0
) => {
  let newTarget: Date
  if (targetDate) {
    newTarget = new Date(targetDate)
  } else {
    newTarget = new Date()
  }

  let newCurTimestamp: Date
  if (currentTimestamp) {
    newCurTimestamp = new Date(currentTimestamp)
  } else {
    newCurTimestamp = new Date()
  }

  const dateDiff = Math.floor(
    (newCurTimestamp.valueOf() - newTarget.valueOf()) / 36e5
  )

  const atraso =
    targetSLA > 0 ? (qtdItens > 0 ? dateDiff / qtdItens : dateDiff) : 0
  //return dateDiff <= targetSLA ? 0 : dateDiff
  return atraso <= targetSLA ? 0 : atraso
}
