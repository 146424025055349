import { useState } from 'react'
import { Box } from '@mui/material'
import { Form } from './Form'
import { List } from './List'
import { FormMode } from '../../../types/formMode'
import { FormTitle } from '../../../components/Form/FormTitle'

export function TipoDocumentos() {
  const [formMode, setFormMode] = useState<FormMode>('LIST')
  const [data, setData] = useState<any>({})

  return (
    <Box bgcolor="#fff" p={4} borderRadius={2}>
      <FormTitle title="Tipos Documentos" subtitle={formMode} />

      {formMode === 'LIST' ? (
        <List setData={setData} setFormMode={setFormMode} />
      ) : (
        <Form
          data={formMode === 'EDIT' ? data : {}}
          formMode={formMode}
          setFormMode={setFormMode}
        />
      )}
    </Box>
  )
}
