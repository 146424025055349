import pdfMake from 'pdfmake/build/pdfmake'
import { format } from 'date-fns'
import { vfs } from '../../../../../utils/report/fonts/pdfFonts'
import { ISetoresPorEmpresaData } from '../../../../../services/relatorio/types'
import { groupByField, iterateGroupedData } from '../../../../../utils/report'
import { TemplateReport } from '../../../../../components/Report'
import { ApiEmpresa } from '../../../../../services/empresa'
import ptBR from 'date-fns/locale/pt-BR'

pdfMake.vfs = vfs
pdfMake.fonts = {
  Montserrat: {
    normal: 'Montserrat-Regular.ttf',
    bold: 'Montserrat-SemiBold.ttf',
    italics: 'Montserrat-Italic.ttf',
    bolditalics: 'Montserrat-SemiBoldItalic.ttf'
  }
}

type Props = {
  id_usuario: string
  data: ISetoresPorEmpresaData[]
  mode?: 'open' | 'save'
  filtros: {
    id_empresa?: string
    data_inicio?: Date
    data_fim?: Date
  }
}

export async function SetoresPorEmpresaReport({
  id_usuario,
  data,
  mode,
  filtros
}: Props) {
  const dadosAgrupados = groupByField(data, 'nome_empresa')

  const filterReportText = async () => {
    const { id_empresa, data_fim, data_inicio } = filtros
    const filtersReport = []
    filtersReport.push({ text: 'Empresa: ', style: ['filterText', 'bold'] })
    if (id_empresa) {
      const empresa = await ApiEmpresa.findOne(id_empresa)
      if (empresa) {
        filtersReport.push({
          text: empresa.razao_social,
          style: ['filterText']
        })
      }
    } else filtersReport.push({ text: '<todas> ', style: ['filterText'] })

    filtersReport.push({ text: '\n' })
    filtersReport.push({ text: 'Período: ', style: ['filterText', 'bold'] })
    if (data_inicio && data_fim) {
      filtersReport.push({
        text: `${format(new Date(data_inicio), 'dd/MM/yyyy', {
          locale: ptBR
        })} até ${format(new Date(data_fim), 'dd/MM/yyyy', { locale: ptBR })}`,
        style: ['filterText']
      })
    } else filtersReport.push({ text: '<global> ', style: ['filterText'] })

    return filtersReport
  }

  const setoresPorEmpresaGroupTable = () => {
    const tables: Array<any> = []
    let rows: Array<any> = []
    if (dadosAgrupados) {
      iterateGroupedData(dadosAgrupados, (empresa, setores) => {
        rows = [
          [
            { text: 'Setor', style: 'columnTitle' },
            { text: 'Email', style: 'columnTitle' },
            { text: 'Fone', style: 'columnTitle' },
            { text: 'Data Cadastro', style: 'columnTitle' },
            { text: 'Ativo', style: 'columnTitle' }
          ]
        ]

        setores.forEach((setor) => {
          const row = [
            { text: setor.nome_setor, style: 'row' },
            { text: setor.email, style: 'row' },
            { text: setor.telefone?.replaceAll(' ', ''), style: 'row' },
            {
              text: format(new Date(setor.created_at), 'dd/MM/yyyy'),
              style: 'rowCenter'
            },
            { text: setor.ativo ? 'Sim' : 'Não', style: 'rowCenter' }
          ]

          rows.push(row as any)
        })

        tables.push([
          {
            text: `Empresa: ${empresa} \nRazão Social: ${setores[0].razao_social_empresa}`,
            style: 'subtitle'
          },
          {
            table: {
              widths: [150, 150, 75, 80, 45],
              headerRows: 1,
              body: rows
            }
          },
          {
            text: `Total de Setores: ${setores.length}`,
            style: 'total',
            margin: [0, 0, 0, 10]
          }
        ])
      })
      return tables
    } else {
      return [{ text: '\n' }]
    }
  }
  await TemplateReport({
    id_usuario,
    content: setoresPorEmpresaGroupTable(),
    filterText: await filterReportText(),
    reportTitle: 'Setores por Empresa',
    mode
  })
}
