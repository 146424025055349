import { Box, Typography } from '@mui/material'
import { ReactNode } from 'react'
import { corDivisoria, corTituloWidget } from '../cores'

interface DashboardWidgetFormHeaderProps {
  children: ReactNode
  title: string
}

export function DashboardWidgetFormHeader({
  children,
  title
}: DashboardWidgetFormHeaderProps) {
  return (
    <Box
      sx={{
        padding: '16px 32px 16px 32px',
        borderBottom: `1px solid ${corDivisoria}`,
        display: 'flex',
        flexDirection: {
          sm: 'column',
          md: 'row'
        },
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginBottom: '2rem'
      }}
    >
      <Typography
        sx={{
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '24px',
          color: corTituloWidget
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '41px'
        }}
      >
        {children}
      </Box>
    </Box>
  )
}
