import { useState } from 'react'
import { Box } from '@mui/material'
import { Form } from './Form'
import { List } from './List'
import { FormMode } from '../../../types/formMode'
import { FormTitle } from '../../../components/Form/FormTitle'

export function FluxoServico() {
  const [formMode, setFormMode] = useState<FormMode>('LIST')
  const [data, setData] = useState<any>({})

  const getFormType = (formMode: FormMode) => {
    switch (formMode) {
      case 'LIST': {
        return <List setData={setData} setFormMode={setFormMode} />
      }
      case 'CREATE': {
        return (
          <Form
            data={{
              id_servico: '',
              nome: '',
              descricao: '',
              modelagem_fluxo: '',
              interno: false,
              em_uso: false
            }}
            formMode={formMode}
            setFormMode={setFormMode}
          />
        )
      }
      default: {
        return (
          <Form data={data} formMode={formMode} setFormMode={setFormMode} />
        )
      }
    }
  }

  return (
    <Box bgcolor="#fff" p={4} borderRadius={2}>
      <FormTitle title="Fluxos de Serviços" subtitle={formMode} />

      {getFormType(formMode)}
    </Box>
  )
}
