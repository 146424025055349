import { DefaultApi } from '../../utils/defaultApi'
import api from '../api'
import {
  IArvoreDocumental,
  IArvoreDocumentalSetorRequest,
  ITipoDocumento
} from './types'

const endpoint = '/arvore-documental'
const resourceId = 'id_arvore-documental'

class ServiceArvoreDocumental extends DefaultApi<IArvoreDocumental> {
  public findByEmpresa = async (
    id_empresa: string
  ): Promise<IArvoreDocumental> => {
    const id = id_empresa
    const { data } = await api.get<IArvoreDocumental>(
      `${this.endpoint}/empresa/${id}`
    )
    return data
  }

  public findTiposDocumentosByUsuario = async (): Promise<ITipoDocumento[]> => {
    const { data } = await api.get<ITipoDocumento[]>(
      `${this.endpoint}/usuario-tipodocumento/`
    )
    return data
  }

  public findTiposDocumentosByUsuarioContrato = async (): Promise<
    ITipoDocumento[]
  > => {
    const { data } = await api.get<ITipoDocumento[]>(
      `${this.endpoint}/contratos-usuario-tipodocumento/`
    )
    return data
  }

  public findTiposDocumentosBySetor = async (
    id_setor: string
  ): Promise<ITipoDocumento[]> => {
    if (id_setor === '') return []
    const { data } = await api.get<ITipoDocumento[]>(
      `${this.endpoint}/setor-tipodocumento/${id_setor}`
    )
    return data
  }

  public updateItem = async (
    formData: IArvoreDocumentalSetorRequest
  ): Promise<void> => {
    const id = formData.id_arvore_documental
    let dt = {}

    if (formData.codigo) {
      dt = {
        ...dt,
        codigo: formData.codigo
      }
    }

    if (formData.id_setor_pai !== undefined) {
      dt = {
        ...dt,
        id_setor_pai: formData.id_setor_pai
      }
    }
    if (typeof formData.ativo !== undefined) {
      dt = {
        ...dt,
        ativo: formData.ativo
      }
    }

    if (formData.arvore_documental_tipo_documento) {
      dt = {
        ...dt,
        arvore_documental_tipo_documento:
          formData.arvore_documental_tipo_documento
      }
    }
    const { data } = await api.put(`${this.endpoint}/${id}`, {
      ...dt
    })
    return data
  }
}

const apiArvoreDocumental = new ServiceArvoreDocumental(endpoint, resourceId)

export const ApiArvoreDocumental = {
  ...apiArvoreDocumental,
  update: apiArvoreDocumental.updateItem
}
