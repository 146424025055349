import { Box, Button, TextField } from '@mui/material'
import { IMovimentacaoExtendida } from '../../../services/movimentacao/types'
import { ComponentGroup } from '../../../components/ComponentGroup'
import { formata } from '../../../utils/formataData'
import { Input } from '../../../components/Form/Input'
import { useState } from 'react'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import PreviewIcon from '@mui/icons-material/DocumentScannerOutlined'
import UploadIcon from '@mui/icons-material/CloudUpload'
import { useUsuario } from '../../../hooks/usuario/useUsuario'
import { Permissions } from '../../permissions'
import { ModalUploadDocument } from '../../../components/ModalUploadDocument'
import { useDocumento } from '../../../hooks/documento/useDocumento'
import { hasPermission } from '../../../utils/hasPermission'
import { ApiDocumento } from '../../../services/documento'
import { FormDocumentoDigital } from '../../../pages/Cadastros/Caixas/FormDocumentoDigital'
import { hasAccess } from '../../../utils/hasAccess'
import { ModalDocumentsBox } from '../../../components/ModalDocumentsBox'

interface DashboardWidgetFormMovimentacaoProps {
  data: IMovimentacaoExtendida
  onDataChange: (data: IMovimentacaoExtendida) => void
}

export function DashboardWidgetFormMovimentacao({
  data,
  onDataChange
}: DashboardWidgetFormMovimentacaoProps) {
  const newPrevisao = data.previsao
    ? new Date(data.previsao)
    : data?.item_requisicao?.requisicao?.previsao_inicio
    ? new Date(data?.item_requisicao?.requisicao?.previsao_inicio)
    : new Date()

  const [previsao, setPrevisao] = useState<Date>(new Date(newPrevisao))
  const [observacao, setObservacao] = useState<string>(data.observacoes ?? '')
  const [openModal, setOpenModal] = useState(false)
  const [openModalDocumentos, setOpenModalDocumentos] = useState(false)
  const [openModalDocDigital, setOpenModalDocDigital] = useState(false)
  const [isLoadingFile, setIsLoadingFile] = useState(false)
  const [currentDocumentId, setCurrentDocumentId] = useState<string>(
    data?.item_requisicao?.documento?.id_documento ?? ''
  )
  const { data: documentoData } = useDocumento.FindById(currentDocumentId)

  const { data: usuario } = useUsuario.FindByUsername()

  const getDocumentInfo = (id_documento: string) => {
    setCurrentDocumentId('')
    setCurrentDocumentId(id_documento)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    getDocumentInfo(currentDocumentId)
  }

  const handleCloseModalDocumentos = () => {
    setOpenModalDocumentos(false)
  }

  const handleUploadDocumento = (id_documento: string) => {
    setCurrentDocumentId(id_documento)
    setOpenModal(true)
  }

  const handlePrevisaoChange = (newData: string) => {
    data.previsao = new Date(newData)
    setPrevisao(data.previsao)
    onDataChange(data)
  }

  const handleObservacaoChange = (newObservacao: string) => {
    data.observacoes = newObservacao
    setObservacao(data.observacoes)
    onDataChange(data)
  }

  const handleCloseModalDocDigital = () => {
    setOpenModalDocDigital(false)
  }

  const handleDigitalFile = async (
    id_contrato: string,
    filePath: string,
    id_documento: string,
    action: 'download' | 'preview'
  ) => {
    try {
      setIsLoadingFile(true)
      const url = await ApiDocumento.digitalFile(
        id_contrato,
        filePath,
        id_documento
      )

      if (action === 'preview') {
        if (
          hasPermission(usuario?.permissoes, Permissions.VISUALIZAR_DOCUMENTO)
        )
          window.open(url, '_blank', 'popup=yes')
      } else {
        if (
          hasPermission(
            usuario?.permissoes,
            Permissions.DOWNLOAD_DOCUMENTO_DIGITAL
          )
        ) {
          const fileName = url.split('/').pop()
          const anchor = document.createElement('a')
          anchor.href = url
          anchor.setAttribute('download', fileName!)
          document.body.appendChild(anchor)
          anchor.click()
          document.body.removeChild(anchor)
          anchor.remove()
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoadingFile(false)
    }
  }

  const canUploadDocument =
    usuario?.permissoes.includes(Permissions.UPLOAD_DOCUMENTO_DIGITAL) &&
    !documentoData?.path

  return (
    <Box
      sx={{ gap: 2, display: 'flex', flexDirection: 'column', padding: '2rem' }}
    >
      <Input
        label={'Requisitante'}
        value={
          data?.item_requisicao?.requisicao?.item_contrato?.contrato
            ?.empresa_contratante?.nome
        }
        inputProps={{ disabled: 'disabled' }}
      />
      <ComponentGroup title="Requisição">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Input
            label={'Sugestão de início'}
            value={formata(
              data?.item_requisicao?.requisicao?.sugestao_inicio
                ? new Date(data?.item_requisicao?.requisicao?.sugestao_inicio)
                : new Date(),
              "dd/MM/yyyy 'às' hh:mm"
            )}
            inputProps={{ disabled: 'disabled' }}
            sx={{ width: '48%' }}
          />
        </Box>
        <Input
          label={'Observações'}
          value={data?.item_requisicao?.requisicao?.observacoes}
          inputProps={{ disabled: 'disabled' }}
        />
      </ComponentGroup>
      <ComponentGroup title={'Item requisitado'}>
        <Input
          label={'Tipo'}
          value={data.tipoItem}
          inputProps={{ disabled: 'disabled' }}
        />
        <Input
          label={'Descrição'}
          value={
            data?.item_requisicao?.documento?.descricao ??
            data?.item_requisicao?.caixa?.identificacao
          }
          inputProps={{ disabled: 'disabled' }}
        />
        {data?.tipoItem === 'Documento' &&
          data.estado == 'EXECUTANDO' &&
          data?.preco_atividade?.atividade.tipo_atividade.permissao
            ?.codigo_permissao === Permissions.DASHBOARD_CARDS && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 2
              }}
            >
              <Button
                variant="outlined"
                color="info"
                sx={{ gap: 1 }}
                disabled={!canUploadDocument}
                onClick={() =>
                  handleUploadDocumento(
                    data?.item_requisicao?.documento?.id_documento ?? ''
                  )
                }
              >
                <FileUploadOutlinedIcon /> Upload
              </Button>
              {documentoData?.path && (
                <Button
                  variant="outlined"
                  color="info"
                  disabled={isLoadingFile}
                  sx={{ gap: 1 }}
                  onClick={() =>
                    handleDigitalFile(
                      documentoData.id_contrato,
                      documentoData.path!,
                      documentoData.id_documento!,
                      'preview'
                    )
                  }
                >
                  <PreviewIcon /> Visualizar
                </Button>
              )}
            </Box>
          )}

        {data?.tipoItem === 'Caixa' && data?.estado == 'EXECUTANDO' && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 2
            }}
          >
            {hasAccess(
              usuario?.permissoes,
              Permissions.INCLUIR_DOCUMENTO_DIGITAL
            ) && (
              <Button
                variant="outlined"
                startIcon={<UploadIcon sx={{ width: 24, height: 24 }} />}
                onClick={() => {
                  setOpenModalDocDigital(true)
                }}
              >
                Incluir Documentos Digitais
              </Button>
            )}

            <Button
              variant="outlined"
              startIcon={<PreviewIcon sx={{ width: 24, height: 24 }} />}
              onClick={() => {
                setOpenModalDocumentos(true)
              }}
            >
              Ver Documentos
            </Button>
          </Box>
        )}
      </ComponentGroup>
      <ComponentGroup title={'Atividade'}>
        <Input
          label={'Descrição'}
          value={data?.preco_atividade?.atividade.tipo_atividade.descricao}
          inputProps={{ disabled: 'disabled' }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Input
            label={'SLA (em horas)'}
            value={data?.preco_atividade?.sla}
            inputProps={{ disabled: 'disabled' }}
            sx={{ width: '48%' }}
          />
          <Input
            label={'Atraso (em horas)'}
            value={data.atraso}
            inputProps={{ disabled: 'disabled' }}
            sx={{ width: '48%' }}
          />
        </Box>
      </ComponentGroup>
      <ComponentGroup title="Sobre a atividade">
        <TextField
          label="Previsão de Execução*"
          type="date"
          value={previsao.toISOString().split('T')[0]}
          size="small"
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
          onChange={(event) => {
            handlePrevisaoChange(String(event.target.value))
          }}
        />
        <Input
          label={'Observações'}
          value={observacao}
          onChange={(event) => {
            handleObservacaoChange(String(event.target.value))
          }}
        />
      </ComponentGroup>
      <ModalUploadDocument
        id_documento={currentDocumentId}
        open={openModal}
        handleClose={handleCloseModal}
      />

      <ModalDocumentsBox
        open={openModalDocumentos}
        handleClose={handleCloseModalDocumentos}
        idCaixa={data.item_requisicao?.id_caixa ?? ''}
        handleDigitalFile={handleDigitalFile}
      />

      <FormDocumentoDigital
        open={openModalDocDigital}
        handleClose={handleCloseModalDocDigital}
        idCaixa={data.item_requisicao?.id_caixa ?? ''}
        idContrato={
          data.item_requisicao?.requisicao?.item_contrato?.contrato
            ?.id_contrato ?? ''
        }
        idTipoDocumento={data.item_requisicao?.caixa?.id_tipo_documento ?? ''}
      />
    </Box>
  )
}
