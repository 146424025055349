import { Box, BoxProps } from '@mui/material'
import { ReactNode } from 'react'

interface DashboardWidgetFormRootProps extends BoxProps {
  children: ReactNode
}

export function DashboardWidgetFormRoot({
  children,
  ...rest
}: DashboardWidgetFormRootProps) {
  return <Box {...rest}>{children}</Box>
}
