import { Box, Button, Stack } from '@mui/material'
import SaveIcon from '@mui/icons-material/CheckCircleOutlined'
import CancelIcon from '@mui/icons-material/DoDisturbAltOutlined'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import msg from '../../../utils/validationMessages'
import useToast from '../../../hooks/toast/useToast'
import { FormMode } from '../../../types/formMode'
import { Input } from '../../../components/Form/Input'
import { IConta } from '../../../services/conta-corrente/conta/types'
import { useConta } from '../../../hooks/conta-corrente/conta/useConta'
import { useBanco } from '../../../hooks/conta-corrente/banco/useBanco'
import { Select } from '../../../components/Form/Select'
import { useEffect, useState } from 'react'
import { ComponentGroup } from '../../../components/ComponentGroup'
import { useUf } from '../../../hooks/uf/useUf'
import { masks } from '../../../utils/masks'
import { validation } from '../../../utils/validations'
import { Loading } from '../../../components/Loading'
import { useMunicipio } from '../../../hooks/municipio/useMunicipio'
import { formatarComoMoeda } from '../../../utils/formataStrings'
import { ApiMovimentoContaCorrente } from '../../../services/conta-corrente/movimento'

const schema = yup
  .object({
    descricao: yup.string().required(msg.REQUIRED).min(3, msg.MIN(3)),
    agencia: yup.string().required(msg.REQUIRED),
    numero_conta: yup.string().required(msg.REQUIRED),
    id_banco: yup.string().required(msg.REQUIRED),
    tipo_conta: yup.string().required(msg.REQUIRED),
    telefone: yup.string().nullable(),
    contato: yup.string().nullable(),
    endereco: yup.object({
      logradouro: yup.string(),
      id_municipio: yup.string(),
      numero: yup.string(),
      bairro: yup.string(),
      cep: yup.string(),
      referencia: yup.string(),
      complemento: yup.string()
    })
  })
  .required()

interface IForm {
  data: any
  formMode: FormMode
  setFormMode: (value: React.SetStateAction<FormMode>) => void
}

const tiposConta = [
  { id: 'CORRENTE', descricao: 'Corrente' },
  { id: 'POUPANÇA', descricao: 'Poupança' },
  { id: 'SALARIO', descricao: 'Salário' },
  { id: 'EMPRESARIAL', descricao: 'Empresarial' },
  { id: 'INVESTIMENTO', descricao: 'Investimento' }
]

export function Form({ data, formMode, setFormMode }: IForm) {
  const {
    register,
    handleSubmit,
    setValue,
    resetField,
    formState: { errors }
  } = useForm<IConta>({
    resolver: yupResolver(schema)
  })

  const { Toast } = useToast()
  const { mutateAsync: create } = useConta.Create()
  const { mutateAsync: update } = useConta.Update()
  const { data: bancos } = useBanco.ListAll()

  const [uf, setUf] = useState<string>(data?.endereco?.municipio?.estado?.sigla)
  const [saldo, setSaldo] = useState<string>('****')
  const [verSaldo, setVersaldo] = useState<boolean>(false)
  const [id_municipio, setIdMunicipio] = useState<string>(
    data?.endereco?.id_municipio ?? ''
  )
  const { data: ufs, isLoading } = useUf.ListAll()
  const { data: municipios } = useMunicipio.FindByUf(uf)

  function handlePhoneChange(value: string): string {
    const newValue = masks.phone({ value, mandatoryDDD: true })
    return newValue
  }

  function handlePhoneBlur(value: string): string {
    const newValue = validation.clearValue(value)
    if (newValue.length === 0) {
      return ''
    }
    return value
  }

  function handleCepChange(value: string): string {
    const newValue = masks.cep({ value })
    return newValue
  }

  function handleAgencyAccountChange(value: string): string {
    const newValue = masks.bankAgencyAccount({ value })
    return newValue
  }

  function handleMunicipioChange(id: string) {
    setIdMunicipio(id)
    setValue('endereco.id_municipio', id)
  }

  useEffect(() => {
    const fetchData = async () => {
      const dadosSaldo = await ApiMovimentoContaCorrente.getSaldoConta(
        data.id_conta
      )
      setSaldo(formatarComoMoeda(dadosSaldo?.saldoConta ?? 0))
      // You can use dadosSaldo here if needed
    }

    if (verSaldo) {
      fetchData()
    } else setSaldo('****')
  }, [verSaldo])

  const onSubmit = async (formData: IConta) => {
    formMode === 'CREATE'
      ? await Toast(create(formData))
      : await Toast(
          update({
            ...formData,
            id_conta: data.id_conta
          }),
          'UPDATED'
        )

    setFormMode('LIST')
  }

  if (isLoading) return <Loading />

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} mt={4}>
      <Stack gap={2}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { md: 'row', sm: 'column' },
            gap: 2
          }}
        >
          <Select
            label="Banco*"
            placeholder="Selecione o Banco"
            data={bancos}
            fields={['id_banco', 'descricao']}
            {...register('id_banco')}
            defaultValue={data?.id_banco}
            message={errors.id_banco?.message}
          />

          <Select
            label="Tipo*"
            placeholder="Selecione o Tipo da Conta"
            data={tiposConta}
            fields={['id', 'descricao']}
            {...register('tipo_conta')}
            defaultValue={data?.tipo_conta}
            message={errors.tipo_conta?.message}
          />
          <Stack direction={'row'} gap={2} width={{ md: 460, sm: 390 }}>
            <Input label="Saldo" value={saldo} disabled={true} />

            <Button
              variant="outlined"
              color="primary"
              onClick={() => setVersaldo(!verSaldo)}
              sx={{ minWidth: '24px', padding: '6px', height: '36px' }}
            >
              {verSaldo ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </Button>
          </Stack>
        </Box>
      </Stack>
      <Stack gap={2} mt={2}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { md: 'row', sm: 'column' },
            gap: 2
          }}
        >
          <Input
            label="Agência*"
            {...register('agencia', {
              onChange: (event) => {
                const { value } = event.target
                event.target.value = handleAgencyAccountChange(value)
              }
            })}
            defaultValue={data?.agencia}
            message={errors.agencia?.message}
            sx={{ maxWidth: '150px' }}
          />
          <Input
            label="Número da Conta*"
            {...register('numero_conta', {
              onChange: (event) => {
                const { value } = event.target
                event.target.value = handleAgencyAccountChange(value)
              }
            })}
            defaultValue={data?.numero_conta}
            message={errors.numero_conta?.message}
            sx={{ maxWidth: '150px' }}
          />
          <Input
            label="Descrição*"
            {...register('descricao')}
            defaultValue={data?.descricao}
            message={errors.descricao?.message}
          />
        </Box>
      </Stack>

      <Stack gap={2} direction="row" mt={2}>
        <Input
          label="Contato"
          {...register('contato')}
          defaultValue={data?.contato}
          message={errors.contato?.message}
        />
        <Input
          label="Telefone"
          {...register('telefone', {
            onChange: (event) => {
              const { value } = event.target
              event.target.value = handlePhoneChange(value)
            },
            onBlur: (event) => {
              const { value } = event.target
              event.target.value = handlePhoneBlur(value)
            }
          })}
          defaultValue={data?.telefone}
          message={errors.telefone?.message}
        />
      </Stack>

      <ComponentGroup title={'Endereço'} mt={2}>
        <Stack gap={2} direction="row">
          <Select
            label="Estado"
            placeholder="Selecione o Estado"
            data={ufs}
            fields={['sigla', 'nome']}
            value={uf ?? ''}
            defaultValue={data?.endereco?.municipio?.estado?.sigla}
            onChange={(e) => {
              setUf(String(e.target.value))
              setIdMunicipio('')
            }}
            onBlur={() => resetField('endereco.id_municipio')}
          />

          <Select
            label="Município"
            placeholder="Selecione o município"
            data={municipios}
            fields={['id_municipio', 'nome']}
            value={id_municipio ?? ''}
            {...register('endereco.id_municipio')}
            onChange={(event) =>
              handleMunicipioChange(String(event.target.value))
            }
            defaultValue={data?.endereco?.municipio?.id_municipio}
            message={errors.endereco?.id_municipio?.message}
          />
        </Stack>

        <Input
          label="Logradouro*"
          {...register('endereco.logradouro')}
          defaultValue={data?.endereco?.logradouro}
          message={errors.endereco?.logradouro?.message}
        />

        <Stack gap={2} direction="row">
          <Input
            label="Número*"
            {...register('endereco.numero')}
            defaultValue={data?.endereco?.numero}
            message={errors.endereco?.numero?.message}
          />

          <Input
            label="Bairro*"
            {...register('endereco.bairro')}
            defaultValue={data?.endereco?.bairro}
            message={errors.endereco?.bairro?.message}
          />

          <Input
            label="Cep*"
            {...register('endereco.cep', {
              onChange: (event) => {
                const { value } = event.target
                event.target.value = handleCepChange(value)
              }
            })}
            defaultValue={data?.endereco?.cep}
            message={errors.endereco?.cep?.message}
          />
        </Stack>

        <Input
          label="Complemento"
          {...register('endereco.complemento')}
          defaultValue={data?.endereco?.complemento}
        />

        <Input
          label="Referencia"
          {...register('endereco.referencia')}
          defaultValue={data?.endereco?.referencia}
        />
      </ComponentGroup>

      <Stack direction="row" gap={2} mt={3}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<CancelIcon />}
          onClick={() => setFormMode('LIST')}
        >
          Cancelar
        </Button>

        <Button
          type="submit"
          variant="contained"
          startIcon={<SaveIcon />}
          sx={{ width: '110px' }}
        >
          Salvar
        </Button>
      </Stack>
    </Box>
  )
}
