import { IReportFilter } from '../../components/ReportForm'
import api from '../../services/api'
import { getCodigo } from '../../utils/getCodigo'
import {
  ICaixasArmazenadasPorEmpresaESetor,
  ICaixasArmazenadasPorCDData,
  IDocumentosEstatisticaData,
  ISetoresPorEmpresaData,
  IUsuariosPorEmpresaData,
  ICaixasMovimentadasPorServicoRequestDTO,
  ICaixasMovimentadasPorServico,
  ICaixasMovimentadasPorCDRequestDTO,
  IUsuariosPorEmpresaFilter,
  ISetorPorEmpresaFilter,
  ICaixasArmazenadasPorEmpresaFilter,
  ILotesNaoDevolvidosFilter,
  ILotesNaoDevolvidosData,
  IPrazoGuardaFilter,
  IPrazoGuardaData,
  IDocumentosInventario,
  IArvoreDocumental,
  IInventarioCaixasFilter,
  IInventarioCaixasData,
  IDadosImportacaoFilter,
  IDadosImportacaoData,
  ITipoDocumentosPorPagina,
  ILotesArquivados,
  ILotesArquivadosData,
  ITipoDocumentos
} from './types'

const endpoint = '/relatorios'

export class ServiceRelatorio {
  constructor(protected endpoint: string) {}

  public async getResume(id_empresa?: string) {
    const { data } = await api.get<IDocumentosEstatisticaData[]>(
      `${this.endpoint}/documentos/estatisticas`,
      {
        params: {
          id_empresa
        }
      }
    )
    return data
  }

  public async getImportedData({
    data_fim,
    data_inicio,
    id_contrato,
    id_usuario
  }: IDadosImportacaoFilter) {
    const params = {
      data_inicio,
      data_fim,
      id_contrato,
      id_usuario
    }
    const { data } = await api.get<IDadosImportacaoData[]>(
      `${this.endpoint}/documentos/importacao`,
      {
        params
      }
    )

    return data
  }

  public async getSectorByCompany({
    id_empresa,
    data_fim,
    data_inicio
  }: ISetorPorEmpresaFilter) {
    const params = {
      id_empresa,
      data_inicio,
      data_fim
    }
    const { data } = await api.get<ISetoresPorEmpresaData[]>(
      `${this.endpoint}/empresa/setores`,
      {
        params
      }
    )

    return data
  }

  public async getUsersByCompany({
    id_empresa,
    id_setor,
    data_inicio,
    data_fim
  }: IUsuariosPorEmpresaFilter) {
    const params = {
      id_empresa,
      id_setor,
      data_fim,
      data_inicio
    }
    const { data } = await api.get<IUsuariosPorEmpresaData[]>(
      `${this.endpoint}/empresa/usuarios`,
      {
        params
      }
    )

    return data
  }

  public async getMovimentedBoxesByService({
    data_fim,
    data_inicio,
    id_contrato,
    id_item_contrato,
    tipoConteudo,
    id_setor
  }: ICaixasMovimentadasPorServicoRequestDTO) {
    const { data } = await api.get<ICaixasMovimentadasPorServico[]>(
      `${this.endpoint}/caixas/movimentadas/servico`,
      {
        params: {
          data_inicio,
          data_fim,
          id_contrato,
          id_item_contrato,
          tipoConteudo,
          id_setor
        }
      }
    )
    return data
  }

  public async getStoredBoxesByDC({
    data_fim,
    data_inicio,
    id_centrodocumentacao,
    id_empresa
  }: ICaixasMovimentadasPorCDRequestDTO) {
    const { data } = await api.get<ICaixasArmazenadasPorCDData[]>(
      `${this.endpoint}/caixas/armazenadas/cd`,
      {
        params: {
          data_inicio,
          data_fim,
          id_centrodocumentacao,
          id_empresa
        }
      }
    )
    return data
  }

  public async getStoredBoxesByCompanyAndSector({
    data_fim,
    data_inicio,
    id_empresa,
    id_setor,
    tipoConteudo
  }: ICaixasArmazenadasPorEmpresaFilter) {
    const { data } = await api.get<ICaixasArmazenadasPorEmpresaESetor[]>(
      `${this.endpoint}/caixas/armazenadas/empresa`,
      {
        params: {
          id_empresa,
          data_inicio,
          data_fim,
          id_setor,
          tipoConteudo
        }
      }
    )
    return data
  }

  public async getLotesArquivados({
    data_fim,
    data_inicio,
    id_empresa,
    id_tipo_caixa,
    endereco,
    endereco_final,
    id_setor,
    estado
  }: ILotesArquivados) {
    const { data } = await api.get<ILotesArquivadosData[]>(
      `${this.endpoint}/lotes/arquivados`,
      {
        params: {
          id_empresa,
          data_inicio,
          data_fim,
          id_tipo_caixa,
          id_setor,
          endereco,
          endereco_final,
          estado
        }
      }
    )

    const lotesData = data.map((item) => {
      const { id_caixa, ...rest } = item
      return {
        id_caixa: getCodigo(id_caixa ?? ''),
        nome_empresa: item.setor?.empresa?.nome ?? '',
        ...rest
      }
    })
    return lotesData
  }

  public async getLotesNaoDevolvidos({
    data_fim,
    data_inicio,
    id_empresa,
    id_setor,
    tipoConteudo,
    atrasoDevolucao
  }: ILotesNaoDevolvidosFilter) {
    const { data } = await api.get<ILotesNaoDevolvidosData[]>(
      `${this.endpoint}/lotes/nao-devolvidos`,
      {
        params: {
          id_empresa,
          data_inicio,
          data_fim,
          id_setor,
          tipoConteudo,
          atrasoDevolucao
        }
      }
    )

    const lotesData = data.map((item) => {
      const { id_requisicao, token, ...rest } = item
      return {
        id_requisicao: getCodigo(id_requisicao),
        token: token.slice(-15).replace('.', '').toUpperCase(),
        ...rest
      }
    })
    return lotesData
  }

  public async getPrazoGuarda({
    data_fim,
    data_inicio,
    id_empresa,
    id_setor,
    tipoConteudo = '0',
    id_tipo_temporalidade,
    tipoPrazo = '0'
  }: IPrazoGuardaFilter) {
    const { data } = await api.get<IPrazoGuardaData[]>(
      `${this.endpoint}/caixas/prazo-guarda`,
      {
        params: {
          id_empresa,
          data_inicio,
          data_fim,
          id_setor,
          tipoConteudo,
          id_tipo_temporalidade,
          tipoPrazo
        }
      }
    )
    const caixasData = data.map((item) => {
      const { id_caixa, ...rest } = item
      return {
        id_caixa: getCodigo(id_caixa),
        ...rest
      }
    })
    return caixasData
  }

  public async getInventarioCaixas({
    data_fim,
    data_inicio,
    id_empresa,
    id_setor,
    estadoInventario,
    id_tipo_documento,
    id_usuario
  }: IInventarioCaixasFilter) {
    const { data } = await api.get<IInventarioCaixasData[]>(
      `${this.endpoint}/caixas/inventario`,
      {
        params: {
          id_empresa,
          data_inicio,
          data_fim,
          id_setor,
          estadoInventario,
          id_tipo_documento,
          id_usuario
        }
      }
    )

    const caixasData = data.map((item) => {
      const { id_caixa, ...rest } = item
      return {
        id_caixa: getCodigo(id_caixa),
        ...rest
      }
    })
    return caixasData
  }

  public async getDocumentosInventario({
    data_fim,
    data_inicio,
    id_empresa,
    id_setor,
    id_tipo_documento,
    endereco,
    estado
  }: IReportFilter) {
    const { data } = await api.get<IDocumentosInventario[]>(
      `${this.endpoint}/documentos/inventario`,
      {
        params: {
          id_empresa,
          data_inicio,
          data_fim,
          id_setor,
          endereco,
          estado,
          id_tipo_documento
        }
      }
    )
    return data
  }

  public async getArvoreDocumental({ id_empresa }: IReportFilter) {
    const { data } = await api.get<IArvoreDocumental[]>(
      `${this.endpoint}/arvores-documentais/empresa/${id_empresa}`
    )
    return data
  }

  public async getTipoDocumentosPorPagina({
    data_fim,
    data_inicio,
    id_empresa,
    id_tipo_documento,
    id_setor
  }: IReportFilter) {
    const { data } = await api.get<ITipoDocumentosPorPagina[]>(
      `${this.endpoint}/documentos/tipo-documento`,
      {
        params: {
          id_empresa,
          data_inicio,
          data_fim,
          id_tipo_documento,
          id_setor
        }
      }
    )
    return data
  }

  public async getTipoDocumentos({
    id_empresa,
    id_tipo_documento
  }: IReportFilter) {
    const { data } = await api.get<ITipoDocumentos[]>(
      `${this.endpoint}/tipo-documento`,
      {
        params: {
          id_empresa,
          id_tipo_documento
        }
      }
    )
    return data
  }
}

export const ApiRelatorio = new ServiceRelatorio(endpoint)
