import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import useToast from '../../hooks/toast/useToast'
import { Input } from '../Form/Input'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDocumento } from '../../hooks/documento/useDocumento'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

type IUploadDocData = {
  file?: any
}

export interface ModalUploadDocumentProps {
  id_documento: string
  handleClose: () => void
  open: boolean
}

const schema = yup
  .object({
    file: yup
      .mixed()
      .test('file', 'Selecione um arquivo', (value) =>
        value.length > 0 ? true : false
      )
  })
  .required()
export function ModalUploadDocument({
  id_documento,
  open,
  handleClose
}: ModalUploadDocumentProps) {
  const { Toast } = useToast()
  const { mutateAsync: uploadDocumentoDigital } =
    useDocumento.UploadDocumentoDigital()

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<IUploadDocData>({
    resolver: yupResolver(schema)
  })

  const onSubmit = async (formDocData: IUploadDocData) => {
    const { file } = formDocData

    const formData = new FormData()

    formData.append('file', file[0])
    formData.append('id_documento', id_documento)

    reset()
    await Toast(uploadDocumentoDigital(formData))
    handleClose()
  }
  return (
    <Modal
      open={open}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        top: '20%'
      }}
    >
      <Box
        borderRadius="4px"
        borderColor="lightgray"
        sx={{
          bgcolor: 'background.paper',
          boxShadow: 24,
          width: {
            sm: '780px',
            md: '880px'
          }
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          bgcolor="#2835ad"
          px={2}
          sx={{
            borderTopRightRadius: '4px',
            borderTopLeftRadius: '4px'
          }}
        >
          <Typography color="#FFF" py={1.5} variant="subtitle1">
            Upload Documento Digital
          </Typography>

          <Tooltip title="Fechar" arrow>
            <IconButton onClick={() => handleClose()}>
              <CloseIcon sx={{ color: '#fff' }} />
            </IconButton>
          </Tooltip>
        </Box>

        <Stack p={2} component="form" onSubmit={handleSubmit(onSubmit)}>
          <Input
            label=""
            type="file"
            sx={{ pt: 1 }}
            {...register('file')}
            message={errors.file?.message}
          />
          <Stack direction="row" gap={2} p={2} justifyContent="flex-end">
            <Button
              variant="contained"
              type="submit"
              sx={{
                fontWeight: 600
              }}
            >
              Upload Documento
            </Button>
          </Stack>
        </Stack>

        <Divider />
      </Box>
    </Modal>
  )
}
