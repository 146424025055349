import { ICentroDocumentacao } from '../../../../services/centroDocumentacao/types'
import { ICorredor } from '../../../../services/corredor/types'
import { IEstante } from '../../../../services/estante/types'
import { IPrateleira } from '../../../../services/prateleira/types'
import { Space, emptySpace } from '../@types/Space'

export const getCDSpace = (cd: ICentroDocumentacao): Space => {
  const retVal = { free: 0, busy: 0, total: 0 }
  if (cd.corredor) {
    cd.corredor.forEach((corredor) => {
      const corr = getCorredorSpace(corredor)
      retVal.free += corr.free
      retVal.busy += corr.busy
      retVal.total += corr.total
    })
  }

  return retVal
}

export const getCorredorSpace = (corredor: ICorredor): Space => {
  const retVal = { free: 0, busy: 0, total: 0 }
  if (corredor.estante) {
    corredor.estante.forEach((estante) => {
      const est = getEstanteSpace(estante)
      retVal.free += est.free
      retVal.busy += est.busy
      retVal.total += est.total
    })
  }

  return retVal
}

export const getEstanteSpace = (estante: IEstante): Space => {
  if (estante.prateleira) {
    const retVal = { free: 0, busy: 0, total: 0 }
    estante.prateleira.forEach((prateleira) => {
      const prat = getPrateleiraSpace(prateleira)
      retVal.free += prat.free
      retVal.busy += prat.busy
      retVal.total += prat.total
    })
    return retVal
  }
  return emptySpace
}

export const getPrateleiraSpace = (prateleira: IPrateleira): Space => {
  if (prateleira.posicao_prateleira) {
    const retVal = {
      free: 0,
      busy: 0,
      total: 0
    }
    prateleira.posicao_prateleira.forEach((posicao) => {
      if (posicao.caixa) {
        retVal.busy++
      } else {
        retVal.free++
      }
      retVal.total++
    })
    return retVal
  }
  return emptySpace
}
