import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ApiPermissaoDisponivel } from '../../services/permissaoDisponivel'

const QUERY_KEY = 'qkPermissaoDisponivel'

const FindAll = (page: number, q = '') => {
  return useQuery([QUERY_KEY, q, page], () =>
    ApiPermissaoDisponivel.findAll(page, q)
  )
}

const ListAll = () => {
  return useQuery([QUERY_KEY], ApiPermissaoDisponivel.listAll)
}

const Create = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiPermissaoDisponivel.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const FindByCodigoPermissao = (codigo_permissao: number) => {
  return useQuery(
    [QUERY_KEY, codigo_permissao],
    () => ApiPermissaoDisponivel.findByCodigoPermissao(codigo_permissao),
    {
      refetchOnWindowFocus: false
    }
  )
}

export const usePermissaoDisponivel = {
  Create,
  FindAll,
  ListAll,
  FindByCodigoPermissao
}
