import {
  IHandleReportSubmitProps,
  ReportForm
} from '../../../../components/ReportForm'
import { ApiRelatorio } from '../../../../services/relatorio'
import { DocumentosInventarioReport } from './report/DocumentosInventario'

export function DocumentosInventarioRelatorio() {
  async function handleSubmitForm({
    mode,
    format,
    id_usuario,
    filtros
  }: IHandleReportSubmitProps) {
    const documentosInventarioData = await ApiRelatorio.getDocumentosInventario(
      {
        id_empresa: filtros.id_empresa,
        id_setor: filtros.id_setor,
        data_fim: filtros.data_fim,
        data_inicio: filtros.data_inicio,
        endereco: filtros.endereco,
        estado: filtros.estado,
        id_tipo_documento: filtros.id_tipo_documento
      }
    )

    if (format === 'pdf') {
      await DocumentosInventarioReport({
        data: documentosInventarioData,
        mode,
        orientation: 'landscape',
        id_usuario,
        filtros
      })
    }

    return documentosInventarioData
  }
  return (
    <ReportForm
      title="Inventário de Documentos"
      handleReportSubmit={handleSubmitForm}
      mostrarEndereco={true}
      mostrarPeriodo={false}
      mostrarEstado={true}
      mostrarTipoDocumento={true}
    />
  )
}
