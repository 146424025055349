import { Box, Chip } from '@mui/material'

interface TagProps {
  label?: string
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

const tagColors = [
  { label: 'ANDAMENTO', color: '#2196F3' },
  { label: 'CONCLUÍDO', color: '#4CAF50' },
  { label: 'REVISÃO', color: '#FFC107' },
  { label: 'APROVADO', color: '#FF9800' },
  { label: 'REJEITADO', color: '#F44336' },
  { label: 'CONFIDENCIAL', color: '#673AB7' },
  { label: 'INTERNO', color: '#9E9E9E' },
  { label: 'PÚBLICO', color: '#03A9F4' },
  { label: 'AUDITORIA', color: '#388E3C' },
  { label: 'TREINAMENTO', color: '#3F51B5' },
  { label: 'PROCEDIMENTO', color: '#009688' },
  { label: 'ANÁLISE', color: '#FFEB3B' },
  { label: 'AÇÃO REQUERIDA', color: '#FF5722' },
  { label: 'ASSINADO', color: '#CDDC39' },
  { label: 'ASSINAR', color: '#03A9F4' },
  { label: 'ARQUIVADO', color: '#795548' },
  { label: 'ARQUIVAR', color: '#BDBDBD' },
  { label: 'URGENTE', color: '#D32F2F' },
  { label: 'PRIORIDADE ALTA', color: '#FF9800' },
  { label: 'PRIORIDADE MÉDIA', color: '#FFEB3B' },
  { label: 'PRIORIDADE BAIXA', color: '#8BC34A' }
]

export function Tag({ label, onClick, ...rest }: TagProps) {
  const tagColor = tagColors.find((t) => t.label === label)?.color || '#fff'
  return (
    <Box
      component="span"
      sx={{
        display: 'inline-flex',
        position: 'relative',
        textTransform: 'uppercase',
        whiteSpace: 'nowrap'
      }}
      onClick={onClick}
    >
      {label && (
        <Chip
          label={label}
          variant="outlined"
          sx={{
            backgroundColor: tagColor,
            color: '#000',
            boxSizing: 'border-box',
            width: 'auto',
            height: 'auto',
            padding: '4px 8px',
            border: 'none',
            borderRadius: '6px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer',
            '&:hover': { backgroundColor: tagColor },
            '&:focus': { outline: 'none' }
          }}
          {...rest}
        />
      )}
    </Box>
  )
}
