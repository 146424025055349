import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ApiTipoDocumento } from '../../services/tipoDocumento'

const QUERY_KEY = 'qkTipoDocumento'

const FindAll = (
  page: number,
  selectedOrder?: string,
  q = '',
  id_contrato?: string
) => {
  return useQuery([QUERY_KEY, q, page, selectedOrder, id_contrato], () =>
    ApiTipoDocumento.findAllOrder(page, selectedOrder, q, id_contrato)
  )
}

const ListAll = () => {
  return useQuery([QUERY_KEY], ApiTipoDocumento.listAll)
}

const ListAllByIdSetor = (id_setor: string) => {
  return useQuery([QUERY_KEY, id_setor], () =>
    ApiTipoDocumento.listAllByIdSetor(id_setor)
  )
}

const Create = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiTipoDocumento.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Update = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiTipoDocumento.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Remove = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiTipoDocumento.remove, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

export const useTipoDocumento = {
  Create,
  Update,
  FindAll,
  ListAll,
  ListAllByIdSetor,
  Remove
}
