/**
 * Comparar dois objetos e dizer se seus conteúdos são iguais, se basenado nas propriedades
 * @param a Um dos objetos para comparação
 * @param b Outro objeto para comparação
 * @returns Flag indicando se os objetos são iguais
 */
export const isEqual = (a: any, b: any): boolean => {
  const aKeys = Object.keys(a)
  const bKeys = Object.keys(b)
  if (bKeys.length !== aKeys.length) return false
  let ret = true
  aKeys.forEach((akey) => {
    const vA = a[akey]
    const vB = b[akey]
    ret &&= !!vB && vA === vB
  })

  return ret
}
