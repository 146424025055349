import { format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'

export function formata(data: Date | string, mascara: string): string {
  const convertedData = new Date(data)
  /*const newData = new Date(
    convertedData.valueOf() + convertedData.getTimezoneOffset() * 60 * 1000
  )*/
  return format(convertedData, mascara, { locale: ptBR })
}

export function formataSomenteData(data: Date | string): string {
  const convertedData = new Date(data)
  const options = { timeZone: 'UTC' } // Define as opções de localidade para ignorar o fuso horário

  const formattedDate = convertedData.toLocaleString('pt-BR', options) // Formata a data
  return formattedDate.substring(0, 10) // Retorna apenas a data
}
