import { Container } from '../components/Layouts/Container'
import { useAuth } from '../hooks/auth'
import { PrivateRoutes } from './private.routes'
import { PublicRoutes } from './public.routes'

export function AppRoutes() {
  const { isAuthenticated, isFirstAccess } = useAuth()

  return (
    <>
      {!isAuthenticated ? (
        <PublicRoutes />
      ) : (
        <>
          <Container>
            <PrivateRoutes isFirstAccess={isFirstAccess} />
          </Container>
        </>
      )}
    </>
  )
}
