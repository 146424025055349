import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect
} from 'react'
import api from '../services/api'
import useToast from './toast/useToast'

interface SignInCredencials {
  username: string
  password: string
}

interface User {
  id_usuario: string
  username: string
  nome: string
}

interface AuthState {
  token: string
  user: User
}

interface AuthContextData {
  user: User
  signIn(credencials: SignInCredencials): Promise<void>
  signInNoAuth(credencials: SignInCredencials): Promise<void>
  signInWithGoogle(token: string): Promise<void>
  signInWithCertificate(token: string): Promise<void>
  signOut(): void
  isAuthenticated: boolean
  isFirstAccess: boolean
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

export const LOCAL_STORAGE_TOKEN = '@easydoc:token'
export const LOCAL_STORAGE_USER = '@easydoc:user'
export const LOCAL_STORAGE_EMAIL = '@easydoc:email'
export const LOCAL_STORAGE_REQ_USER = '@easydoc:req_user'
export const LOCAL_STORAGE_REQ_ID = '@easydoc:req_id'
export const LOCAL_STORAGE_INCOMPLETE_REQ = '@easydoc:onlyIncomplete'

interface IAuthProviderProps {
  children: React.ReactNode
}

interface IError {
  statusCode: number
  message: string
  error: string
}

function AuthProvider({ children }: IAuthProviderProps) {
  const [firstAccess, setFirstAccess] = useState(false)
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem(LOCAL_STORAGE_TOKEN)
    const user = localStorage.getItem(LOCAL_STORAGE_USER)

    if (token && user) {
      // @ts-ignore
      api.defaults.headers.authorization = `Bearer ${token}`

      return {
        token,
        user: JSON.parse(user)
      }
    }

    return {} as AuthState
  })

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem(LOCAL_STORAGE_TOKEN)
      const user = localStorage.getItem(LOCAL_STORAGE_USER)
      if (token && user) {
        try {
          // @ts-ignore
          api.defaults.headers.authorization = `Bearer ${token}`
          const response = await api.get('/usuario/can-login')
          if (response.status === 200) {
            // @ts-ignore
            api.defaults.headers.authorization = `Bearer ${token}`
            setData({
              token,
              user: JSON.parse(user)
            })
          } else {
            // Handle the case where the token is not valid
            setData({} as AuthState)
          }
        } catch (error) {
          console.error('Error fetching data', error)
          setData({} as AuthState)
        }
      }
    }

    fetchData()
  }, [])

  const signIn = useCallback(
    async ({ username, password }: SignInCredencials) => {
      const response = await api.post('/auth/login', { username, password })

      const { access_token: token, usuario: user } = response.data

      localStorage.setItem(LOCAL_STORAGE_TOKEN, token)
      localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(user))

      setFirstAccess(password.startsWith('NOVa@1'))

      // @ts-ignore
      api.defaults.headers.authorization = `Bearer ${token}`

      setData({
        token,
        user: user as User
      })
    },
    []
  )

  const signInNoAuth = useCallback(
    async ({ username, password }: SignInCredencials) => {
      const response = await api.post('/auth/login', { username, password })
      localStorage.setItem(
        LOCAL_STORAGE_EMAIL,
        response.data.usuario.pessoa.email
      )
    },
    []
  )

  const signInWithGoogle = useCallback(async (token: string) => {
    const { ToastError } = useToast()
    try {
      const response = await api.post('/auth/google', { token })
      const { access_token: newToken, usuario: user } = response.data

      localStorage.setItem(LOCAL_STORAGE_TOKEN, newToken)
      localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(user))

      // @ts-ignore
      api.defaults.headers.authorization = `Bearer ${newToken}`

      setData({
        token,
        user: user as User
      })
    } catch (error) {
      const typedError = error as IError
      ToastError('Erro ao logar com Google:' + typedError.message)
    }
  }, [])

  const signInWithCertificate = useCallback(async (token: string) => {
    const { ToastError } = useToast()
    try {
      const response = await api.post('/auth/certificate', { token })
      const { access_token: newToken, usuario: user } = response.data

      localStorage.setItem(LOCAL_STORAGE_TOKEN, newToken)
      localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(user))

      // @ts-ignore
      api.defaults.headers.authorization = `Bearer ${newToken}`

      setData({
        token,
        user: user as User
      })
    } catch (error) {
      const typedError = error as IError
      ToastError('Erro ao logar com Certificado:' + typedError.message)
    }
  }, [])

  const signOut = useCallback(() => {
    localStorage.removeItem(LOCAL_STORAGE_TOKEN)
    localStorage.removeItem(LOCAL_STORAGE_USER)
    localStorage.removeItem(LOCAL_STORAGE_REQ_USER)
    localStorage.removeItem(LOCAL_STORAGE_REQ_ID)
    localStorage.removeItem(LOCAL_STORAGE_INCOMPLETE_REQ)
    setData({} as AuthState)
  }, [])

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signInNoAuth,
        signIn,
        signOut,
        signInWithGoogle,
        signInWithCertificate,
        isAuthenticated: !!data.user,
        isFirstAccess: firstAccess
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

function useAuth(): AuthContextData {
  const context = useContext(AuthContext)
  return context
}

export { AuthProvider, useAuth }
