import { DefaultApi } from '../../utils/defaultApi'
import api from '../api'
import { IPessoa } from './types'

const endpoint = '/pessoa'
const resourceId = 'id_pessoa'

class PessoaApiService extends DefaultApi<IPessoa> {
  findByIdUsuario = async (id: string): Promise<IPessoa> => {
    const { data } = await api.get<IPessoa>(`${endpoint}/usuario/id/${id}`)
    return data
  }
}

export const ApiPessoa = new PessoaApiService(endpoint, resourceId)
