import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ApiDocumento } from '../../services/documento'
import { AdvancedFindAllDocumentosParams } from '../../services/documento/types'

const QUERY_KEY = 'qkDocumento'

const Sign = () => {
  const queryClient = useQueryClient()
  return useMutation(ApiDocumento.sign, {
    onSuccess: () => {
      queryClient.resetQueries()
      // queryClient.invalidateQueries([QUERY_KEY])
    }
  })
}

const SignatureStatus = (id: string) => {
  return useQuery([QUERY_KEY, 'SIGNATURE', id], () =>
    ApiDocumento.getSignatureStatus(id)
  )
}

const FindByIdContrato = (
  id: string,
  page: number,
  q = '',
  r = '',
  limit?: number
) => {
  return useQuery([QUERY_KEY, id, r, q, limit, page], () =>
    ApiDocumento.findByIdContrato(id, page, q, r, limit)
  )
}

const FindAllWithoutDriveByIdSetor = (id: string, page: number, q = '') => {
  return useQuery([QUERY_KEY, id, q, page], () =>
    ApiDocumento.findAllWithoutDriveByIdSetor(id, page, q)
  )
}

const FindByIdCaixa = (id: string) => {
  return useQuery([QUERY_KEY, id], () => ApiDocumento.findByIdCaixa(id))
}

const Create = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiDocumento.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const CreateDocumentoDigital = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiDocumento.createDocumentoDigital, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const ImportData = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiDocumento.importData, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Update = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiDocumento.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const UpdateTag = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiDocumento.updateTag, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const UpdateDocumentoDigital = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiDocumento.updateDocumentoDigital, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Remove = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiDocumento.remove, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Reactivate = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiDocumento.reactivate, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const RemoveDocumentoDiretorio = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiDocumento.removeDocumentoDiretorio, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const AddDocumentoDiretorio = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiDocumento.addDocumentoDiretorio, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const FindById = (id: string) => {
  return useQuery([QUERY_KEY, id], () => ApiDocumento.findOne(id))
}

const FindSharedById = (id: string) => {
  return useQuery([QUERY_KEY, 'shared', id], () =>
    ApiDocumento.findSharedOne(id)
  )
}

const FindCompartilhamentoById = (id_compartilhamento: string) => {
  return useQuery([QUERY_KEY, 'compartilhamento', id_compartilhamento], () =>
    ApiDocumento.findOneCompartilhamento(id_compartilhamento)
  )
}

const FindAllCompartilhamentos = (page: number, q = '') => {
  return useQuery([QUERY_KEY, 'allCompartilhamentos', q, page], () =>
    ApiDocumento.findAllCompartilhamentos(page, q)
  )
}

const AdvancedFindAll = (
  page: number,
  searchParam: AdvancedFindAllDocumentosParams,
  filter?: string
) => {
  return useQuery([QUERY_KEY, searchParam, page, filter], () =>
    ApiDocumento.advancedFindAll({ ...searchParam, page }, filter)
  )
}

const UploadDocumentoDigital = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiDocumento.uploadDigitalFile, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const UpdateStatistics = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiDocumento.updateStatistics, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

export const useDocumento = {
  AdvancedFindAll,
  Create,
  CreateDocumentoDigital,
  FindById,
  FindSharedById,
  FindCompartilhamentoById,
  FindAllCompartilhamentos,
  FindByIdCaixa,
  FindByIdContrato,
  ImportData,
  Remove,
  Reactivate,
  Sign,
  SignatureStatus,
  Update,
  UpdateTag,
  UpdateDocumentoDigital,
  UploadDocumentoDigital,
  UpdateStatistics,
  FindAllWithoutDriveByIdSetor,
  RemoveDocumentoDiretorio,
  AddDocumentoDiretorio
}
