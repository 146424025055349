import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material'
import axios from 'axios'
import PreviewIcon from '@mui/icons-material/DocumentScannerOutlined'
import ConnectedIcon from '@mui/icons-material/RssFeed'
import { useState } from 'react'
import { Input } from '../../../components/Form/Input'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import msg from '../../../utils/validationMessages'
import { FormTitle } from '../../../components/Form/FormTitle'
import { useContrato } from '../../../hooks/contrato/useContrato'
import { Select } from '../../../components/Form/Select'

interface IDados {
  nome: string
  dataUltimaModificacao: string
  tamanho: string
}

interface IForm {
  id_projeto: string
  id_contrato: string
  numero_lote: string
}

const schema = yup.object({
  id_contrato: yup.string().required(msg.REQUIRED).min(1, msg.MIN(1)),
  id_projeto: yup.string().required(msg.REQUIRED).min(1, msg.MIN(1)),
  numero_lote: yup.string().required(msg.REQUIRED).min(1, msg.MIN(1))
})

const apiSGD = axios.create({
  baseURL: process.env.REACT_APP_API_SGD_URL ?? 'https://localhost:3333'
})

export function ConsultaImagensSGD() {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors }
  } = useForm<IForm>({
    resolver: yupResolver(schema)
  })

  const [dados, setDados] = useState<IDados[]>([])
  const [tokenSGD, setTokenSGD] = useState<string>()
  const { data: listAllContrato } = useContrato.ListAll()

  async function conectarApi() {
    const { data } = await apiSGD.post('api/auth/login', {
      email: process.env.REACT_APP_API_SGD_USER,
      password: process.env.REACT_APP_API_SGD_PASS
    })
    setTokenSGD(data.token)
  }

  const onSubmit = async (formData: IForm) => {
    const { data } = await apiSGD.get(
      `/api/diretorio/${
        formData.id_projeto
      }/${formData.numero_lote.toUpperCase()}`,
      {
        headers: { Authorization: 'Bearer ' + tokenSGD }
      }
    )
    setDados(data ?? [])
  }

  async function getImagem(nomeArquivo: string) {
    const response = await apiSGD.get(
      `/api/diretorio/${getValues('id_projeto')}/${getValues(
        'numero_lote'
      )}/${nomeArquivo}`,
      {
        headers: { Authorization: 'Bearer ' + tokenSGD },
        responseType: 'blob'
      }
    )

    const blob = new Blob([response.data], {
      type: response.headers['content-type']
    })
    const fileUrl = window.URL.createObjectURL(blob)
    window.open(fileUrl, '_blank', 'popup=yes')
  }

  return (
    <Box bgcolor="#fff" p={4} borderRadius={2}>
      <FormTitle title="Consulta de Imagens" subtitle={'LIST'} />

      <Button
        variant="contained"
        sx={{ height: '40px', my: '16px' }}
        disabled={!!tokenSGD}
        onClick={() => conectarApi()}
      >
        <ConnectedIcon sx={{ mr: '8px' }} />
        Conectar API
      </Button>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} mt={4}>
        <Stack gap={2} direction={'row'}>
          <Select
            label="Contrato*"
            placeholder="Selecione o Contrato"
            data={listAllContrato}
            fields={['id_contrato', 'descricao']}
            {...register('id_contrato')}
            message={errors.id_contrato?.message}
            disabled={!tokenSGD}
            onChange={(e) => {
              const contrato = listAllContrato?.find(
                (c) => c.id_contrato == String(e.target.value)
              )
              setValue('id_projeto', contrato?.id_projeto_sgd ?? '0')
            }}
          />

          <Input
            label="Número do Lote*"
            {...register('numero_lote')}
            disabled={!tokenSGD}
            message={errors.numero_lote?.message}
          />

          <Button
            type="submit"
            variant="contained"
            sx={{ width: '600px', height: '40px' }}
            disabled={!tokenSGD}
          >
            Buscar imagens
          </Button>
        </Stack>
      </Box>

      <Typography sx={{ py: '16px' }} fontSize={16}>
        Total de arquivos: <strong>{dados.length}</strong>
      </Typography>

      <TableContainer
        component={Box}
        border={1}
        borderRadius={2}
        paddingY={4}
        borderColor="#f2f2f2"
      >
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Nome do arquivo
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Data da última modificação
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Tamanho
              </TableCell>
              <TableCell width="12px" />
            </TableRow>
          </TableHead>
          <TableBody>
            {dados &&
              dados?.map((item, i) => (
                <TableRow key={i} hover>
                  <TableCell>{item.nome}</TableCell>
                  <TableCell>{item.dataUltimaModificacao}</TableCell>
                  <TableCell>{item.tamanho}</TableCell>

                  <Tooltip title="Visualizar" arrow>
                    <Button
                      variant="outlined"
                      sx={{
                        minWidth: '24px',
                        padding: '6px',
                        marginLeft: '16px'
                      }}
                      onClick={() => getImagem(item.nome)}
                    >
                      <PreviewIcon />
                    </Button>
                  </Tooltip>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
