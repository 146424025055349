import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography
} from '@mui/material'

import logo from '../../assets/images/logo_easydoc.png'
import { ShowMessage } from '../../components/ShowMessage'
import api from '../../services/api'

export function PasswordRecover() {
  const navigate = useNavigate()
  const [message, setMessage] = useState<string>()
  const [code, setCode] = useState('')
  const [isDisable, setIsDisable] = useState(false)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const emailParam = queryParams.get('email')

  const handleNavigateToEmail = () => navigate('/email-recover')
  const handleNavigateToModify = () => navigate('/modify-password')

  async function submitVerificationCode(
    event: React.FormEvent<HTMLInputElement>
  ) {
    event.preventDefault()
    setIsDisable(true)
    const email = emailParam ?? localStorage.getItem('email')
    try {
      const response = await api.post('/auth/verifyCode', {
        email: email,
        code: code
      })
      localStorage.setItem('id', response.data)
      localStorage.setItem('email', email ?? '')
      handleNavigateToModify()
      setIsDisable(false)
    } catch (err: any) {
      setMessage(err?.message)
      setIsDisable(false)
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 16,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <img src={logo} width="60%" alt="logo X-Solution" />

        <Typography variant="h4" mt={4}>
          Código de recuperação
        </Typography>
        <Box component="form" onSubmit={submitVerificationCode} sx={{ mt: 1 }}>
          <TextField
            label="Código"
            required
            fullWidth
            margin="normal"
            autoFocus
            onChange={(e) => setCode(e.target.value)}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={isDisable}
            sx={{ mt: 3, mb: 2, fontSize: '14px' }}
          >
            Continuar
          </Button>

          <Grid container>
            <Grid item xs>
              <Link
                variant="body2"
                onClick={handleNavigateToEmail}
                sx={{ cursor: 'pointer' }}
              >
                {'Voltar'}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <ShowMessage
        hasError={!!message}
        message={message}
        onClose={() => setMessage(undefined)}
      />
    </Container>
  )
}
