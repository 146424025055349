import { useQuery } from 'react-query'
import { ApiDashboard } from '../../services/dashboard'
import { useAuth } from '../auth'

const QUERY_KEY = 'qkDashboard'

/*const ListAll = () => {
  const { user } = useAuth()
  return useQuery([QUERY_KEY, user.username], ApiDashboard.listAll)
}*/

const GetSumarioCaixas = () => {
  const { user } = useAuth()
  return useQuery(
    [QUERY_KEY, 'sumario_caixas', user.username],
    ApiDashboard.getSumarioCaixas
  )
}

const GetRequisicoes = () => {
  const { user } = useAuth()
  return useQuery(
    [QUERY_KEY, 'requisicoes', user.username],
    ApiDashboard.getRequisicoes
  )
}

const GetTiposAtividade = () => {
  const { user } = useAuth()
  return useQuery([QUERY_KEY, 'tipos-atividade', user.username], () =>
    ApiDashboard.getTiposAtividade()
  )
}

export const useDashboard = {
  //ListAll,
  GetSumarioCaixas,
  GetRequisicoes,
  GetTiposAtividade
}
