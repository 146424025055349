import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ApiSetor } from '../../services/setor'

const QUERY_KEY = 'qkSetor'

const FindAll = (page: number, q = '') => {
  return useQuery([QUERY_KEY, q, page], () => ApiSetor.findAll(page, q))
}

const FindAllByDrive = (page: number, q = '') => {
  return useQuery([QUERY_KEY, q, page], () => ApiSetor.findAllByDrive(page, q))
}

const ListAll = () => {
  return useQuery(QUERY_KEY, ApiSetor.listAll)
}

const FindOne = (id: string) => {
  return useQuery([QUERY_KEY, id], () => ApiSetor.findOne(id))
}

const Create = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiSetor.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Update = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiSetor.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const Remove = () => {
  const queryClient = useQueryClient()

  return useMutation(ApiSetor.inactivate, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY)
    }
  })
}

const FindByEmpresa = (id: string) => {
  return useQuery([QUERY_KEY, id], () => ApiSetor.findByEmpresa(id))
}

export const useSetor = {
  Create,
  FindAll,
  FindAllByDrive,
  FindByEmpresa,
  ListAll,
  Update,
  Remove,
  FindOne
}
